import * as React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    GroupProps,
    Validators
} from 'react-reactive-form';
import { IfacilityContact, Ioption, Iuser } from '../../models';
import {
    clearUsersThatMachedEmail,
    deleteFacilityFromContact,
    updateContactFromFacility,
    updateSelectedContact,
    getContactsByFacility
} from '../../actions/manageFacilityActions';
import { find, omit, pick, unionBy } from 'lodash';

import { AddContactsTableContainer } from './AddContactsTableContainer';
import { FormUtil } from './FormUtil';
import { IfacilityContactFormValues } from '../../modelsForms';
import { WithTranslation } from 'react-i18next';
import { constants } from '../../constants/constants';
import debounce from 'debounce-promise';
import { initialContact } from '../../reducers/initialState';
import { toastr } from 'react-redux-toastr';

interface Iprops {
    // reusable
    loading: boolean;
    show: boolean;
    toggleModal: () => void;
    colorButton: string;
    facilityID: string;
    selectedFacilityID: string;
    selectedContact: IfacilityContact;
    addContactToFacility: (
        contact: IfacilityContact,
        facilityID: string
    ) => Promise<IfacilityContact>;
    getContactsByFacility: typeof getContactsByFacility;
    findUserOrContactByEmail: (search: string) => void;
    updateContactFromFacility: typeof updateContactFromFacility;
    clearUsersThatMachedEmail: typeof clearUsersThatMachedEmail;
    foundUsersOrContactsByID: { [key: string]: Iuser | IfacilityContact };
    usersByID: { [key: string]: Iuser };
    updateSelectedContact: typeof updateSelectedContact;
    facilityContacts: { [key: string]: IfacilityContact };
    deleteFacilityFromContact: typeof deleteFacilityFromContact;
    addContactLead?: (contact: IfacilityContact) => void;
}

enum FormStatus {
    Add,
    Edit,
    AddAndEdit // adding a contact that is not related to a user (add and edit at the same time)
}

interface Istate {
    id?: string;
    fieldConfig: FieldConfig;
    formStatus: FormStatus;
}

interface DefaultProps {
    selectedContact: IfacilityContact;
}

class FacilityContactForm extends React.PureComponent<
    Iprops & WithTranslation,
    Istate
> {
    private formGroup: FormGroup | any;
    private searchUsersDebounced: (search: string) => void;
    private subscription: any;
    static defaultProps: DefaultProps = {
        selectedContact: initialContact
    };

    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.searchUsersDebounced = debounce(
            this.props.findUserOrContactByEmail,
            1000
        );
        this.state = {
            fieldConfig: this.buildFieldConfig(),
            formStatus: FormStatus.Add
        };
    }

    componentDidMount() {
        this.props.getContactsByFacility(this.props.facilityID);
        this.props.updateSelectedContact();
    }

    componentDidUpdate(prevProps: Iprops) {
        // update the form after selecting a contact
        if (this.props.selectedContact.id !== prevProps.selectedContact.id) {
            const facilityHasContact = this.props.facilityContacts[
                this.props.selectedContact.id
            ];

            this.setState({
                fieldConfig: this.buildFieldConfig(this.itemToFormValues()),
                formStatus: facilityHasContact
                    ? FormStatus.Edit
                    : FormStatus.AddAndEdit
            });
        }
        // update the form after selecting a user
        if (
            this.props.selectedContact?.userID !==
            prevProps.selectedContact?.userID
        ) {
            this.setState({
                fieldConfig: this.buildFieldConfig(this.itemToFormValues())
            });
        }

        // reset the form after adding a contact
        if (
            this.props.selectedContact.email.length === 0 &&
            prevProps.selectedContact.email.length > 0
        ) {
            this.setState({
                fieldConfig: this.buildFieldConfig(this.itemToFormValues()),
                formStatus: FormStatus.Add
            });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.updateSelectedContact();
        this.props.clearUsersThatMachedEmail();
    }

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (
        selectedContact = this.props.selectedContact
    ): IfacilityContactFormValues => {
        let {
            userID,
            email,
            firstName,
            lastName,
            title,
            phone,
            mobile
        } = selectedContact;
        if (userID !== undefined && userID?.length) {
            const user = find(this.props.foundUsersOrContactsByID, {
                id: userID
            });
            if (user && 'first' in user) {
                email = user.email;
                firstName = user.first;
                lastName = user.last;
                title = user.position;
                phone = user.phone;
            }
        }
        const cleanedContact = omit(selectedContact, [
            'user',
            'contactFacilities'
        ]);

        return {
            ...cleanedContact,
            email,
            firstName,
            lastName,
            title,
            phone,
            mobile
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (): IfacilityContact => {
        const newContactFacility = { facilityID: this.props.facilityID };
        let contactFacilities = [newContactFacility];
        if (this.props.selectedContact.id) {
            contactFacilities = unionBy(
                [
                    {
                        ...newContactFacility,
                        contactID: this.props.selectedContact.id
                    }
                ],
                this.props.selectedContact.contactFacilities
            );
        }
        return {
            ...this.props.selectedContact,
            ...FormUtil.getValues(this.formGroup.value),
            contactFacilities
        };
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };
    /*
     * (reusable)
     * set the values to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'email': {
                this.handleSelectedEmail(value);
                break;
            }
            default: {
                this.props.updateSelectedContact(this.formValuesToItem());
            }
        }
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
        } else {
            if (
                this.state.formStatus === FormStatus.Add ||
                this.state.formStatus === FormStatus.AddAndEdit
            ) {
                const contact = this.formValuesToItem();
                this.props
                    .addContactToFacility(contact, this.props.facilityID)
                    .then((newContact: IfacilityContact | string) => {
                        // if this is a contact not related to a user then also update the contact just in case they changed any contact info
                        // if adding a brand new contact for the first time, newContact comes back as an IfacilityContact. If it's an existing contact, it comes back as a string (aka the Guid of the contact)
                        if (contact.id !== undefined && contact.id !== '') {
                            //API call to update the values of the contact
                            this.props.updateContactFromFacility({
                                ...contact,
                                id: newContact as string
                            });

                            //Update selected contact in Redux
                            this.props.updateSelectedContact({
                                ...contact,
                                id: newContact as string
                            });

                            this.props.clearUsersThatMachedEmail();
                        } else {
                            //A new contact was added, so update the selected contact in Redux
                            this.props.updateSelectedContact(
                                newContact as IfacilityContact
                            );
                            this.props.clearUsersThatMachedEmail();
                        }
                    });
            } else {
                this.props.updateContactFromFacility(this.formValuesToItem());
            }
        }
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    /*
  * handleSelectedEmail
  Is it an ID for a contact or a user?
        contact - add it
        user - create a new contact from this user
  */
    handleSelectedEmail = (selectedOption: Ioption) => {
        const userInfo = this.props.foundUsersOrContactsByID[
            selectedOption.value
        ];
        if (userInfo && 'contactFacilities' in userInfo) {
            this.props.updateSelectedContact(userInfo);
        } else if (userInfo && 'isActive' in userInfo) {
            const userValues = pick(userInfo, [
                'email',
                'first',
                'last',
                'position',
                'phone'
            ]);
            const newContact: IfacilityContact = {
                id: '',
                isDeleted: false,
                email: userValues.email,
                firstName: userValues.first,
                lastName: userValues.last,
                phone: userValues.phone,
                mobile: '',
                source: 0, // MMG
                title: userValues.position,
                enableNotifications: false,
                smartlinkNotifications: false,
                contactFacilities: [],
                userID: userInfo.id
            };
            this.props.updateSelectedContact(newContact);
            this.setState({
                formStatus: FormStatus.Add
            });
        }
    };

    loadOptions = (value: string) => {
        return this.searchUsersDebounced(value);
    };

    /*
     * isTiedToUser
     * if this contact has a userID (edting an a contact that is related to a user)
     */
    isTiedToUser = (): boolean =>
        this.props.selectedContact?.userID !== undefined &&
        this.props.selectedContact?.userID?.length > 0;

    isSAPContact = (): boolean => this.props.selectedContact?.source === 1; // SAP

    /*
     * resetFormToOriginalContact
     * when adding and editing a contact, undo changes made to the contact
     */
    resetFormToOriginalContact = () => {
        const originalContact = this.props.foundUsersOrContactsByID[
            this.props.selectedContact.id
        ];
        if ('firstName' in originalContact) {
            this.props.updateSelectedContact(originalContact);
            this.setState({
                fieldConfig: this.buildFieldConfig(
                    this.itemToFormValues(originalContact)
                )
            });
        }
    };

    buildFieldConfig = (
        defaultValues: IfacilityContactFormValues = this.itemToFormValues()
    ) => {
        const tiedToUser = this.isTiedToUser();
        const sapContact = this.isSAPContact();
        const { t } = this.props;
        const fieldFormControls = {
            email: {
                render: FormUtil.AsyncCreatableSelect,
                meta: {
                    label: t('facilityContactForm.email'),
                    colWidth: 12,
                    rows: 2,
                    loadOptions: (value: string) => this.loadOptions(value),
                    name: 'email',
                    required: true,
                    isMulti: false,
                    handleCreate: (value: any) => {
                        const regex = new RegExp(
                            /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,8}|[0-9]{1,3})(\]?)$/ //eslint-disable-line
                        );
                        if (value.match(regex)) {
                            this.props.updateSelectedContact({
                                ...initialContact,
                                email: value
                            });
                            const newEmailOption = { value, label: value };
                            FormUtil.patchControl(
                                this.formGroup,
                                'email',
                                newEmailOption
                            );
                        } else {
                            toastr.warning(
                                t('toastMessage:warning'),
                                t('toastMessage:validEmail'),
                                constants.toastrWarning
                            );
                        }
                    }
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: {
                        value: defaultValues.email,
                        label: defaultValues.email
                    },
                    disabled: sapContact
                }
            },
            firstName: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('facilityContactForm.firstName'),
                    colWidth: 6,
                    rows: 2,
                    name: 'firstName',
                    initialContent: '',
                    required: true
                },
                options: {
                    validators: [
                        Validators.maxLength(250),
                        Validators.minLength(2),
                        FormUtil.validators.requiredWithTrim
                    ]
                },
                formState: {
                    value: defaultValues.firstName,
                    disabled: tiedToUser || sapContact
                }
            },
            lastName: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('facilityContactForm.lastName'),
                    colWidth: 6,
                    rows: 2,
                    name: 'lastName',
                    initialContent: '',
                    required: true
                },
                options: {
                    validators: [
                        Validators.maxLength(250),
                        Validators.minLength(2),
                        FormUtil.validators.requiredWithTrim
                    ]
                },
                formState: {
                    value: defaultValues.lastName,
                    disabled: tiedToUser || sapContact
                }
            },
            title: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('facilityContactForm.title'),
                    colWidth: 12,
                    rows: 2,
                    name: 'title',
                    initialContent: '',
                    required: true
                },
                options: {
                    validators: [
                        Validators.maxLength(250),
                        FormUtil.validators.requiredWithTrim
                    ]
                },
                formState: {
                    value: defaultValues.title,
                    disabled: tiedToUser || sapContact
                }
            },
            phone: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('facilityContactForm.phone'),
                    colWidth: 6,
                    rows: 2,
                    name: 'phone',
                    initialContent: '',
                    required: false
                },
                options: {
                    validators: [
                        Validators.maxLength(22),
                        Validators.minLength(7)
                    ]
                },
                formState: {
                    value: defaultValues.phone,
                    disabled: tiedToUser || sapContact
                }
            },
            mobile: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('facilityContactForm.mobile'),
                    colWidth: 6,
                    rows: 2,
                    name: 'mobile',
                    initialContent: '',
                    required: false
                },
                options: {
                    validators: [
                        Validators.maxLength(22),
                        Validators.minLength(7)
                    ]
                },
                formState: {
                    value: defaultValues.mobile,
                    disabled: tiedToUser || sapContact
                }
            },
            enableNotifications: {
                render: FormUtil.Toggle,
                meta: {
                    label: t('facilityContactForm.reportNotifications'),
                    colWidth: 12,
                    rows: 2,
                    name: 'enableNotifications',
                    initialContent: false,
                    required: true
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.enableNotifications,
                    disabled: false
                }
            },
            smartlinkNotifications: {
                render: FormUtil.Toggle,
                meta: {
                    label: t('facilityContactForm.smartlinkNotifications'),
                    colWidth: 12,
                    rows: 2,
                    name: 'smartlinkNotifications',
                    initialContent: false,
                    required: true
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.smartlinkNotifications,
                    disabled: false
                }
            }
        } as { [key: string]: GroupProps };

        const fieldConfig = {
            controls: { ...fieldFormControls }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    render() {
        const { t } = this.props;
        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;
        const { formStatus } = this.state;

        return (
            <form className={formClassName} onSubmit={this.handleSubmit}>
                <Row>
                    <Col xs={12} className="">
                        <FormGenerator
                            onMount={this.setForm}
                            fieldConfig={this.state.fieldConfig}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {formStatus === FormStatus.Edit && (
                            <Button
                                type="button"
                                bsStyle={this.props.colorButton}
                                className="pull-left"
                                style={{ marginLeft: '15px' }}
                                onClick={() =>
                                    this.props.updateSelectedContact()
                                }
                            >
                                Cancel
                            </Button>
                        )}
                        {formStatus === FormStatus.AddAndEdit && (
                            <Button
                                type="button"
                                bsStyle={this.props.colorButton}
                                className="pull-left"
                                style={{ marginLeft: '15px' }}
                                onClick={this.resetFormToOriginalContact}
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            type="submit"
                            bsStyle={this.props.colorButton}
                            className="pull-right"
                            style={{ marginRight: '15px' }}
                        >
                            {formStatus === FormStatus.Add ? 'Add' : 'Save'}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="">
                        <AddContactsTableContainer
                            colorButton={this.props.colorButton}
                            currentTile={constants.getTileByURL(
                                window.location.pathname
                            )}
                            facilityID={this.props.facilityID}
                            addContactLead={this.props.addContactLead}
                        />
                    </Col>
                </Row>
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={() => {
                            this.props.toggleModal();
                        }}
                    >
                        {t('close')}
                    </Button>
                </Col>
            </form>
        );
    }
}

export default FacilityContactForm;
