import * as types from '../actions/actionTypes';

import { filter, keyBy, map, pickBy } from 'lodash';
import { IjobPartPopulated, ImanagePartReducer, Ipart } from '../models';
import initialState, {
    initialJobPartPopulated,
    initialPart
} from './initialState';

import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { IinitialState } from '.';

/**
 *************************** NORMALIZERS
 */
export const cleanJobPart = (jobPart: any): IjobPartPopulated => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const { part, ...clanedJobPart } = jobPart; // TODO it will be better to clean this then re-populate the part in a selector
    return {
        ...initialJobPartPopulated,
        // ...pickBy(clanedJobPart, (property, key) => property !== null)
        ...pickBy(jobPart, (property, key) => property !== null)
    };
};

export const cleanPart = (part: any): Ipart => {
    return {
        ...initialPart,
        ...pickBy(part, (property, key) => property !== null)
    };
};

/**
 *************************** SELECTORS
 */

// Will likely want to add a seperate reselect selector that is responsible for populating the jobPart objects with part objects
// should also be sorting.  take a look at selectPartOptions() in the mobile app

const getJobParts = (state: IinitialState) => state.managePart.jobParts;
const getSelectedJob = (state: IinitialState) => state.manageJob.selectedJob;

export const selectJobPartsForJob = createSelector(
    [getJobParts, getSelectedJob],
    (jobParts, selectedJob): IjobPartPopulated[] => {
        return filter(jobParts, { isDeleted: false, jobID: selectedJob.id });
    }
);

/**
 *********************** REDUCERS
 */

const jobPartsReducer = (
    state: { [key: string]: IjobPartPopulated } = initialState.managePart
        .jobParts,
    action: any
): { [key: string]: IjobPartPopulated } => {
    switch (action.type) {
        case types.SAVE_JOB_PARTS_SUCCESS:
        case types.GET_JOB_PARTS_SUCCESS: {
            if (Array.isArray(action.payload)) {
                const cleanedJobParts = map(action.payload, cleanJobPart);
                return { ...state, ...keyBy(cleanedJobParts, 'id') };
            }
        }
        default:
            return state;
    }
};

const managePart = combineReducers<ImanagePartReducer>({
    jobParts: jobPartsReducer
});

export default managePart;
