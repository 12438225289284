/*
 * QuoteDetailsForm = Notes
 */

import * as React from 'react';

import moment from 'moment';
import { Button, Col } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    GroupProps
} from 'react-reactive-form';
import { WithTranslation } from 'react-i18next';
import { omit } from 'lodash';

import { FormUtil } from '../common/FormUtil';
import { Iquote, Ihistory, Ifacility } from '../../models';
import { IquoteFormValues } from '../../modelsForms';
import { constants } from '../../constants/constants';
import { getSecurityUsers } from '../../actions/manageUserActions';
import {
    initialQuote,
    initialFacility,
    initialUser
} from '../../reducers/initialState';
import { manageQuoteQueryParamsEnum } from './manageQuotes';
import { removeQuery } from '../common/OtherUtils';

interface Iprops {
    // reusable
    loading: boolean;
    selectedQuote: Iquote;
    originalQuote?: Iquote;
    show: boolean;
    toggleModal: () => void;
    onHide: () => void;
    // other
    facility: Ifacility;
    colorButton: string;
    history: Ihistory;
    getSecurityUsers: typeof getSecurityUsers;
    secondModal: boolean;
}

interface Istate {
    fieldConfig: FieldConfig;
}

class QuoteDetailsForm extends React.Component<
    Iprops & WithTranslation,
    Istate
> {
    private formGroup: FormGroup | any;
    private subscription: any;
    static defaultProps = {
        selectedQuote: initialQuote,
        facility: initialFacility,
        salesContact: initialUser
    };
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
        // this.handleChecked = this.handleChecked.bind(this);
    }
    componentDidMount() {
        this.props.getSecurityUsers([
            constants.securityFunctions.SalesManager.id
        ]);
    }

    componentDidUpdate(prevProps: Iprops & WithTranslation) {
        if (this.props.selectedQuote.id !== prevProps.selectedQuote.id) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
        if (
            this.props.selectedQuote.status !== prevProps.selectedQuote.status
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (!this.props.secondModal) {
            removeQuery(
                // Protects cascading issue when accessing query param on first modal. https://app.clickup.com/t/2341091/MMG-516
                manageQuoteQueryParamsEnum.selectedQuoteID,
                this.props.history
            );
        }
    }

    /*
     * itemToFormValues - take the selectedQuote and convert it to formValues
     */
    itemToFormValues = (): IquoteFormValues => {
        const { createDate } = this.props.selectedQuote;

        const cleanedQuote = omit(this.props.selectedQuote, [
            'authorizedName',
            'facility',
            'facilityID',
            'leads',
            'poFile',
            'poNumber',
            'quoteNumber',
            'revision',
            'salesUsers',
            'signature',
            'signatureUrl',
            'status',
            'tos'
        ]);
        const cleanNotes = this.props.selectedQuote.leads
            .map(leadQuote => leadQuote.lead.notes)
            .join('');

        return {
            ...cleanedQuote,
            notes: cleanNotes,
            createDate: moment
                .utc(createDate)
                .local(true)
                .format(constants.momentDisplayFormat),
            facilityName: this.props.facility.name
        };
    };

    buildFieldConfig = (
        defaultValues: IquoteFormValues = this.itemToFormValues()
    ) => {
        // Field config to configure form
        const fieldConfigControls = {
            $field_notes: {
                isStatic: false,
                render: () => (
                    <Col
                        xs={12}
                        style={{ marginBottom: '10px', marginTop: '10px' }}
                    >
                        {defaultValues.notes ? (
                            <div
                                className="quote-notes-text"
                                dangerouslySetInnerHTML={{
                                    __html: defaultValues.notes as string
                                }}
                            />
                        ) : (
                            <p className="quote-notes-text">
                                No notes available.
                            </p>
                        )}
                    </Col>
                )
            }
        } as { [key: string]: GroupProps };

        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };

        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
    };

    render() {
        const { t } = this.props;
        const formClassName = `clearfix beacon-form ${this.props.colorButton} quote-notes-form`;

        return (
            <form className={formClassName}>
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-right"
                        onClick={this.props.toggleModal}
                    >
                        {t('common:doneButton')}
                    </Button>
                </Col>
            </form>
        );
    }
}
export default QuoteDetailsForm;
