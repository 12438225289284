import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    GroupProps
} from 'react-reactive-form';
import { Button, Col } from 'react-bootstrap';
import { FormUtil } from '../common/FormUtil';
import {
    Ilead,
    Ifacility,
    Iproduct,
    IleadUser,
    Iuser,
    Ioption,
    Ihistory,
    IleadPopulated
} from '../../models';
import {
    leadRankingEnum,
    leadTypeEnum,
    leadStatusEnumSelect,
    leadStatusEnum
} from '../../models-enums';
import { IleadFormValues } from '../../modelsForms';
import moment from 'moment';
import {
    initialFacility,
    initialLeadPopulated
} from '../../reducers/initialState';
import { constants } from '../../constants/constants';
import { omit, map, debounce, find, filter } from 'lodash';
import { FacilityContactControl } from '../common/FacilityContactControl';
import { toggleFacilityContactModal } from '../../actions/manageFacilityActions';
import {
    updateLead,
    setSelectedLeadFromID,
    updateSelectedLead
} from '../../actions/manageLeadsActions';
import { toastr } from 'react-redux-toastr';
import { manageLeadQueryParamsEnum } from './ManageLeads';
import { getSecurityUsers } from '../../actions/manageUserActions';
import { removeQuery } from '../common/OtherUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCloudDocument } from '../../actions/manageDocumentActions';
const uuidv4 = require('uuid/v4');

interface Iprops {
    loading: boolean;
    show: boolean;
    toggleModal: () => void;
    colorButton: string;
    selectedLead: IleadPopulated;
    facility: Ifacility;
    product: Iproduct;
    salesManagerNames: string;
    salesPersons: { [key: string]: Iuser };
    toggleFacilityContactModal: typeof toggleFacilityContactModal;
    updateLead: typeof updateLead;
    queryParams: typeof manageLeadQueryParamsEnum;
    setSelectedLeadFromID: typeof setSelectedLeadFromID;
    updateSelectedLead: typeof updateSelectedLead;
    getSecurityUsers: typeof getSecurityUsers;
    history: Ihistory;
    getCloudDocument: typeof getCloudDocument;
}
interface Istate {
    fieldConfig: FieldConfig;
}

class EditLeadForm extends React.PureComponent<
    Iprops & WithTranslation,
    Istate
> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateSelectedLeadDebounced: (lead?: Ilead) => void;
    static defaultProps = {
        selectedLead: initialLeadPopulated,
        facility: initialFacility
    };
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
        this.updateSelectedLeadDebounced = debounce(
            this.props.updateSelectedLead,
            constants.formDebounceTime
        );
    }
    componentDidMount() {
        // get the sales persons for the sales persons select options
        this.props.getSecurityUsers([
            constants.securityFunctions.SalesService.id,
            constants.securityFunctions.SalesParts.id,
            constants.securityFunctions.SalesInstallation.id
        ]);
        this.initSelected();
    }
    componentDidUpdate(prevProps: Iprops) {
        if (this.props.selectedLead.id !== prevProps.selectedLead.id) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
        // TODO update the form when a new contact is added
        if (
            this.props.selectedLead.contactID !==
            prevProps.selectedLead.contactID
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
        if (this.props.salesManagerNames !== prevProps.salesManagerNames) {
            this.formGroup.meta = {
                salesManagerNames: this.props.salesManagerNames,
                loading: this.props.loading
            };
            this.formGroup.stateChanges.next();
        }

        // salesPersons will change because we finished downloading them
        if (
            JSON.stringify(this.props.salesPersons) !==
            JSON.stringify(prevProps.salesPersons)
        ) {
            FormUtil.patchControl(this.formGroup, 'leadUsers', null, {
                options: FormUtil.convertToOptions(
                    map(this.props.salesPersons, (salesPerson: any) => ({
                        id: salesPerson.id,
                        first: salesPerson.first,
                        last: salesPerson.last
                    }))
                )
            });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        removeQuery(
            manageLeadQueryParamsEnum.selectedLeadID,
            this.props.history
        );
        this.props.updateSelectedLead();
    }

    /*
     * initSelected
     * if there is a query param, make sure it matches what is active in redux
     * if no query param and there is a selectedLead.id, then reset what is in redux
     */
    initSelected = () => {
        if (
            this.props.queryParams.selectedLeadID &&
            this.props.queryParams.selectedLeadID !== this.props.selectedLead.id
        ) {
            this.props.setSelectedLeadFromID(
                this.props.queryParams.selectedLeadID
            );
        } else if (
            this.props.queryParams.selectedLeadID === undefined &&
            this.props.selectedLead.id.length
        ) {
            this.props.updateSelectedLead();
        }
    };

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IleadFormValues => {
        let {
            createDate,
            contact,
            ranking,
            leadUsers,
            status
        } = this.props.selectedLead;
        if (!createDate) {
            createDate = moment
                .utc()
                .format(constants.momentDisplayFormatWithTime);
        } else {
            createDate = moment(createDate).format(
                constants.momentDisplayFormatWithTime
            );
        }
        const selectedRankingOption: Ioption = {
            value: ranking.toLocaleString(),
            label: leadRankingEnum[ranking]
        };
        const selectedStatusOption: Ioption = {
            value: status.toLocaleString(),
            label: leadStatusEnum[status]
        };
        // filter out sales managers
        const filteredLeadUsers = filter(leadUsers, {
            assignedDueToMissingCoverage: false
        });
        const selectedLeadUserUsers = map(filteredLeadUsers, lu => {
            return {
                ...lu.user
            };
        });
        const cleanedLead = omit(this.props.selectedLead, [
            'facility',
            'product',
            'quote',
            'leadUsers',
            'leadInstallBases',
            'contact'
        ]);
        let contactName = '';
        if (contact) {
            contactName = `${
                contact.user ? contact.user.first : contact.firstName
            } ${contact.user ? contact.user.last : contact.lastName}`;
        }

        return {
            ...cleanedLead,
            createDate,
            contactName,
            selectedRankingOption,
            selectedStatusOption,
            selectedLeadUserUsers: FormUtil.convertToOptions(
                selectedLeadUserUsers
            )
        };
    };
    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (updatedValue?: { [key: string]: any }): Ilead => {
        let formValues = this.formGroup.value;
        if (updatedValue) {
            formValues = { ...formValues, ...updatedValue };
        }
        return {
            ...this.props.selectedLead,
            ...FormUtil.getValues(formValues),
            leadUsers: this.buildLeadUsers(formValues.leadUsers)
        };
    };

    copyToClipboard = () => {
        if (this.props.selectedLead.leadNumber) {
            navigator.clipboard.writeText(
                this.props.selectedLead.leadNumber.toString()
            );
            toastr.success(
                this.props.t('toastMessage:success'),
                this.props.t('toastMessage:copiedLeadNumber'),
                constants.toastrSuccess
            );
        }
    };

    buildFieldConfig = (
        defaultValues: IleadFormValues = this.itemToFormValues()
    ) => {
        const disabled = false;
        const { t } = this.props;
        const fieldFormControls = {
            $field_0: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editLeadForm.leadNumber'),
                            colWidth: 6,
                            required: false,
                            isClearable: true,
                            value: this.props.selectedLead.leadNumber,
                            buttonAction: this.copyToClipboard,
                            icon: <FontAwesomeIcon icon={['far', 'paste']} />
                        }}
                    />
                )
            },
            $field_type: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editLeadForm.type'),
                            colWidth: 6,
                            placeholder: 'common:searchPlaceholder',
                            name: 'lead-form-type',
                            required: false,
                            isClearable: true,
                            value: t(leadTypeEnum[defaultValues.type])
                        }}
                    />
                )
            },
            $field_1: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editLeadForm.createDate'),
                            colWidth: 6,
                            value: defaultValues.createDate
                        }}
                    />
                )
            },
            $field_4: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editLeadForm.region'),
                            colWidth: 6,
                            required: false,
                            isClearable: true,
                            value: this.props.facility.postalCode
                        }}
                    />
                )
            },
            $field_2: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editLeadForm.product'),
                            colWidth: 12,
                            required: false,
                            isClearable: true,
                            value: this.props.product
                                ? this.props.product.name
                                : 'no product'
                        }}
                    />
                )
            },
            $field_3: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editLeadForm.facility'),
                            colWidth: 12,
                            required: false,
                            isClearable: true,
                            value: this.props.facility.name
                        }}
                    />
                )
            },

            $field_salesManagerNames: {
                isStatic: false,
                render: ({ meta }) =>
                    this.props.salesManagerNames ? (
                        <FormUtil.TextLabelStatic
                            meta={{
                                label: t('editLeadForm.salesManager'),
                                colWidth: 12,
                                required: false,
                                isClearable: true,
                                value: meta.salesManagerNames
                            }}
                        />
                    ) : null
            },
            $field_download: {
                isStatic: false,
                render: () =>
                    this.props.selectedLead.quote?.cloudDocumentID ? (
                        <Col
                            xs={12}
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                        >
                            <Button
                                bsStyle="default"
                                onClick={() => {
                                    this.props.getCloudDocument(
                                        t,
                                        {
                                            ...this.props.selectedLead.quote
                                                ?.cloudDocument,
                                            version: this.props.selectedLead
                                                .quote?.cloudDocumentID,
                                            documentID: this.props.selectedLead
                                                .quote?.id
                                        },
                                        true
                                    );
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={['far', 'file-pdf']}
                                    size="sm"
                                />{' '}
                                &nbsp;
                                {t('editLeadForm.viewQuote')}
                            </Button>
                        </Col>
                    ) : null
            },
            $field_8: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editLeadForm.contact'),
                            colWidth: 6,
                            required: false,
                            isClearable: true,
                            value: defaultValues.contactName
                        }}
                    />
                )
            },
            contact: {
                render: FacilityContactControl,
                meta: {
                    buttonLabel: this.props.t(
                        'facility:facilityContactForm.manageContactsButton'
                    ),
                    colWidth: 6,
                    noMargin: true,
                    colorButton: this.props.colorButton,
                    toggleModal: this.props.toggleFacilityContactModal
                },
                formState: { value: defaultValues.contact, disabled }
            },
            ranking: {
                render: FormUtil.Select,
                meta: {
                    label: 'Ranking',
                    colWidth: 6,
                    required: false,
                    isClearable: false,
                    shouldTranslate: true,
                    disableSort: false,
                    options: FormUtil.convertEnumToOptions(leadRankingEnum)
                },
                formState: {
                    disabled,
                    value: defaultValues.selectedRankingOption
                }
            },
            status: {
                render: FormUtil.Select,
                meta: {
                    label: 'Status',
                    colWidth: 6,
                    required: false,
                    isClearable: true,
                    shouldTranslate: true,
                    options: FormUtil.convertEnumToOptions(leadStatusEnumSelect)
                },
                formState: {
                    disabled,
                    value: defaultValues.selectedStatusOption
                }
            },
            leadUsers: {
                render: FormUtil.Select,
                meta: {
                    label: 'Sales Person',
                    colWidth: 12,
                    required: true,
                    isClearable: true,
                    isMulti: true,
                    options: FormUtil.convertToOptions(
                        map(this.props.salesPersons, (salesPerson: any) => ({
                            id: salesPerson.id,
                            first: salesPerson.first,
                            last: salesPerson.last
                        }))
                    )
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    disabled,
                    value: defaultValues.selectedLeadUserUsers
                }
            },
            notes: {
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'Note',
                    colWidth: 12,
                    required: false,
                    isClearable: true,
                    initialContent: defaultValues.notes
                },
                formState: {
                    disabled,
                    value: defaultValues.notes
                }
            },
            $field_partHtml: {
                isStatic: false,
                render: () => (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.props.selectedLead.partHtml
                        }}
                    ></div>
                )
            },
            $field_internalComments: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: 'Internal Comments',
                            colWidth: 6,
                            required: false,
                            isClearable: true,
                            value: defaultValues.internalComments
                        }}
                    />
                )
            }
        } as { [key: string]: GroupProps };

        const fieldConfig = {
            controls: { ...fieldFormControls }
        };
        return FormUtil.translateForm(fieldConfig, t);
    };
    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };
    /*
     * (reusable)
     * set the values to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                this.updateSelectedLeadDebounced(
                    this.formValuesToItem({ [key]: value })
                );
                break;
        }
    };

    /*
     * buildLeadUsers
     * loop over the options, converting them to actual leadUsers
     */
    buildLeadUsers = (leadUserOptions: Ioption[]): IleadUser[] => {
        return map(leadUserOptions, luo => {
            // we can assume the luo.value is a user.id
            const foundLeadUser = find(this.props.selectedLead.leadUsers, {
                userID: luo.value
            });
            if (foundLeadUser) {
                return {
                    ...foundLeadUser,
                    assignedDueToMissingCoverage: false
                };
            } else {
                const foundUser = this.props.salesPersons[luo.value];
                if (foundUser) {
                    // create a new leadUser
                    return {
                        id: uuidv4(),
                        userID: luo.value,
                        leadID: this.props.selectedLead.id,
                        isDeleted: false,
                        assignedDueToMissingCoverage: false
                    };
                } else {
                    throw new Error('unable to find lead user or user');
                }
            }
        });
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading,
            salesManagerNames: this.props.salesManagerNames
        };
        this.formGroup.stateChanges.next(); // render the new meta values
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        this.props.updateLead(this.formValuesToItem());
    };

    render() {
        const { t } = this.props;
        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;
        return (
            <form onSubmit={this.handleSubmit} className={formClassName}>
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />

                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.props.toggleModal}
                    >
                        {t('close')}
                    </Button>
                    <Button
                        bsStyle={this.props.colorButton}
                        type="submit"
                        disabled={this.props.loading}
                    >
                        {t('save')}
                    </Button>
                </Col>
            </form>
        );
    }
}

export default withTranslation('manageLeads')(EditLeadForm);
