/*
 * Edit Management Point List Tab modal
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import {
    toggleEditMeasurementPointTabModal,
    updateMeasurementPointListTab
} from '../../actions/manageMeasurementPointListsActions';

import { EditMeasurementPointListTabForm } from './EditMeasurementPointListTabForm';
import { WithCommonModal } from '../common/WithCommonModal';
import { connect } from 'react-redux';
import { initialMeasurementPointTab } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
    isCustomerView: boolean;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedTab =
        state.manageMeasurementPointLists.selectedMeasurementPointList.measurementPointTabs.find(
            tab => tab.id === state.manageMeasurementPointLists.selectedTabID
        ) || initialMeasurementPointTab;
    return {
        title: ownProps.t('editTab'),
        className: 'edit-tab',
        secondModal: true,
        user: state.user,
        loading: selectIsLoading(state),
        show:
            state.manageMeasurementPointLists.showEditMeasurementPointTabModal,
        selectedMeasurementPointList:
            state.manageMeasurementPointLists.selectedMeasurementPointList,
        selectedTab
    };
};

export const EditMeasurementPointListTabModal = withTranslation(
    'manageMeasurementPointLists'
)(
    connect(mapStateToProps, {
        onHide: toggleEditMeasurementPointTabModal,
        toggleModal: toggleEditMeasurementPointTabModal,
        updateMeasurementPointListTab
    })(WithCommonModal(EditMeasurementPointListTabForm))
);
