import * as React from 'react';

/*
 * Manage Job Comment
 */
import { Col, Row } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    Ijob,
    IjobComment,
    ItableFiltersReducer,
    Itile,
    Iuser,
    Ioption,
    ItableFiltersParams
} from '../../models';
import ReactTable, { RowInfo, SortingRule } from 'react-table';
import { RouteComponentProps } from 'react-router-dom';
import { emptyTile, initialJob } from '../../reducers/initialState';
import {
    filterJobComments,
    getJobComments,
    setSelectedJobComment,
    setTableFilter,
    toggleEditJobCommentModal
} from '../../actions/manageJobCommentsActions';

import { Banner } from '../common/Banner';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { closeAllModals } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import moment from 'moment';
import { EditJobCommentContainer } from './EditJobCommentContainer';
import { getJobOptionsFromFilters } from '../../reducers/manageJobReducer';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { getCodes } from '../../actions/manageCodeActions';
import { getJobsByFacility, getFSEUsers } from '../../actions/manageJobActions';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { find } from 'lodash';

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    loading: boolean;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleEditJobCommentModal: typeof toggleEditJobCommentModal;
    getJobComments: () => Promise<void>;
    closeAllModals: typeof closeAllModals;
    showEditJobCommentModal: boolean;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    tableData: IjobComment[];
    setSelectedJobComment: typeof setSelectedJobComment;
    filterJobComments: typeof filterJobComments;
    jobCommentsByID: { [key: string]: IjobComment };
    fseUsersByID: { [key: string]: Iuser };
    selectedJob: Ijob;
    jobOptions: Ioption[];
    selectedFacilityID: string;
    getCodes: typeof getCodes;
    getJobsByFacility: () => Promise<void>;
    getFSEUsers: () => Promise<void>;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    searchFieldConfig: FieldConfig;
}

class ManageJobComment extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    public columns: any[];
    public buttonInAction = false;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: null,
            currentTile: emptyTile,
            searchFieldConfig: this.buildSearchControls()
        };
        this.columns = TableUtil.translateHeaders(
            [
                {
                    id: 'date',
                    Header: 'date',
                    accessor: ({ createDate }: IjobComment) => {
                        return createDate
                            ? moment
                                  .utc(createDate)
                                  .local(true)
                                  .format('DD-MMM-YY')
                            : 'n/a';
                    },
                    width: 100
                },
                {
                    Header: 'engineer',
                    id: 'engineer',
                    accessor: ({ userID }: IjobComment) => {
                        const foundUser = find(
                            this.props.fseUsersByID,
                            user => user.id === userID
                        );
                        return foundUser
                            ? `${foundUser.first} ${foundUser.last}`
                            : '';
                    }
                },
                {
                    id: 'comment',
                    Header: 'comment',
                    accessor: 'text',
                    minWidth: 150
                },
                // code will be used in P3
                // {
                //   id: 'code',
                //   Header: 'code',
                //   accessor: 'code',
                //   minWidth: 100
                // },
                {
                    Header: 'type',
                    id: 'type',
                    accessor: ({ isDeficiency }: IjobComment) => {
                        return isDeficiency
                            ? this.props.t('deficiency')
                            : this.props.t('info');
                    },
                    width: 120
                },
                {
                    Header: 'resolved',
                    id: 'resolved',
                    accessor: ({ isResolved }: IjobComment) => {
                        return isResolved
                            ? this.props.t('Yes')
                            : this.props.t('No');
                    },
                    width: 120
                }
            ],
            this.props.t
        );
    }
    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.initialize();
    }
    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditJobCommentModal !==
                this.props.showEditJobCommentModal &&
            !this.props.showEditJobCommentModal
        ) {
            this.setState({ selectedRow: null });
        }
        // automatically get inventory every time a fitler changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.filterJobComments();
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }

        // filter the rawData when new data received from the API
        if (
            JSON.stringify(prevProps.jobCommentsByID) !==
            JSON.stringify(this.props.jobCommentsByID)
        ) {
            console.info('raw data changed');
            this.props.filterJobComments();
        }
        if (this.props.selectedFacilityID !== prevProps.selectedFacilityID) {
            this.props.getJobComments();
            this.props.filterJobComments();
            this.props.getJobsByFacility().then(() => {
                this.props.filterJobComments();
            });
        }
        if (this.props.jobOptions.length !== prevProps.jobOptions.length) {
            this.setState({ searchFieldConfig: this.buildSearchControls() });
        }
    }
    componentWillUnmount() {
        this.props.closeAllModals();
    }

    initialize = () => {
        let initPromises: Array<Promise<any>> = [];
        this.props.getCodes();
        initPromises = [
            this.props.getJobsByFacility(),
            this.props.getFSEUsers(),
            this.props.getJobComments()
        ];
        Promise.all(initPromises).then(() => {
            this.props.filterJobComments();
        });
    };

    buildSearchControls = (): FieldConfig => {
        const {
            /* createDate, */
            commentType,
            jobID,
            isResolved
        } = this.props.tableFilters;
        const disabled = false;

        const defaultResolved = { label: 'No', value: false };
        return {
            controls: {
                jobID: {
                    render: FormUtil.SelectWithoutValidation,
                    meta: {
                        label: 'manageJobComments:labelJob',
                        options: this.props.jobOptions,
                        colWidth: 6,
                        colWidthLarge: 4,
                        type: 'select',
                        placeholder: 'placeholderUser',
                        isClearable: true
                    },
                    formState: {
                        value: jobID,
                        disabled
                    }
                },
                // jfbloom22 commented this out because it is not useful
                // createDate: {
                //   render: FormUtil.DatetimeWithoutValidation,
                //   meta: {
                //     label: 'manageJobComments:labelCreateDate',
                //     colWidth: 3,
                //     colWidthLarge: 2,
                //     showTime: false
                //   },
                //   formState: {
                //     value: createDate,
                //     disabled
                //   }
                // },
                // removed for now becuase we need to filter the FSE users
                // userID: {
                //   render: FormUtil.SelectWithoutValidation,
                //   meta: {
                //     label: 'manageJobComments:labelUser',
                //     options: FormUtil.convertToOptions(this.props.fseUsers),
                //     colWidth: 4,
                //     colWidthLarge: 3,
                //     type: 'select',
                //     placeholder: 'placeholderUser',
                //     isClearable: true
                //   },
                //   formState: {
                //     value: userID,
                //     disabled
                //   }
                // },
                commentType: {
                    render: FormUtil.SelectWithoutValidation,
                    meta: {
                        label: 'manageJobComments:labelType',
                        options: constants.jobCommentTypeOptions,
                        colWidth: 3,
                        colWidthLarge: 3,
                        type: 'select',
                        placeholder: 'placeholderType',
                        isClearable: true
                    },
                    formState: { value: commentType, disabled }
                },
                isResolved: {
                    render: FormUtil.SelectWithoutValidation,
                    meta: {
                        label: 'manageJobComments:labelResolved',
                        options: constants.isResolvedOptions,
                        colWidth: 3,
                        colWidthLarge: 3,
                        type: 'select',
                        placeholder: 'placeholderResolved',
                        isClearable: true
                    },
                    formState: {
                        value: isResolved || defaultResolved,
                        disabled
                    }
                }
            }
        };
    };

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (
        finalState: any,
        rowInfo?: RowInfo,
        column?: undefined,
        instance?: any
    ) => {
        // console.info("ROWINFO", rowInfo);
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    if (!this.buttonInAction) {
                        this.setState({
                            selectedRow: rowInfo.index
                        });
                        this.props.toggleEditJobCommentModal();
                        this.props.setSelectedJobComment(rowInfo.original);
                    }
                },
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };
    onPageChange = (page: number) => {
        this.props.setTableFilter({ page });
    };
    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                this.props.setTableFilter({ [key]: value, page: 0 });
                break;
        }
    };
    /*
     * (reusable)
     * set the sorted changes to redux
     */
    onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };
    onPageSizeChange = (rows: number) => {
        this.props.setTableFilter({ rows, page: 0 });
    };

    render() {
        const { t } = this.props;
        return (
            <div className="manage-job-comments">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                >
                    <SelectFacilityContainer
                        t={this.props.t}
                        classNameOverride={''}
                    />
                </Banner>
                <Row className="">
                    <Col xs={12}>
                        <SearchTableForm
                            fieldConfig={this.state.searchFieldConfig}
                            handleSubmit={() =>
                                console.info('search job table submit')
                            }
                            loading={this.props.loading}
                            colorButton={
                                constants.colors[
                                    `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                                ]
                            }
                            t={this.props.t}
                            subscribeValueChanges={true}
                            onValueChanges={this.onSearchValueChanges}
                            showSearchButton={false}
                        />
                    </Col>
                    {/* <Col xs={4} style={{ paddingTop: '25px' }}>
            <Button
              className="pull-right"
              bsStyle="link"
              onClick={() => {
                this.props.toggleEditJobCommentModal();
                this.props.setSelectedJobComment(initialJobComment);
              }}
            >
              {t('manageJobComments:newJobCommentButton')}
            </Button>
          </Col> */}
                </Row>
                <ReactTable
                    data={this.props.tableData}
                    onSortedChange={this.onSortedChanged}
                    columns={this.columns}
                    getTrProps={this.getTrProps}
                    defaultPageSize={
                        this.props.tableFilters.rows ||
                        constants.tablePageSizeDefault
                    }
                    onPageSizeChange={this.onPageSizeChange}
                    showPageSizeOptions={true}
                    pageSizeOptions={constants.tablePageSizeOptions}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    page={this.props.tableFilters.page}
                    sortable={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    loading={
                        this.props.loading && this.props.tableData.length === 0
                    }
                />

                <EditJobCommentContainer
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const { manageJob, selectedFacilityID, manageJobComment } = state;
    // const { id = '' } = manageJob.jobFormValues;
    // const selectedJob = manageJob.jobsByID[id] || initialJob;
    const selectedJob = initialJob;

    // only measurmentPointList based jobs have comments
    const jobFilters: ItableFiltersParams = {
        facilityID: state.selectedFacilityID,
        measurementBasedOnly: true
    };
    const jobOptions = getJobOptionsFromFilters(
        state,
        { filters: jobFilters },
        ownProps.t
    );

    return {
        user: state.user,
        loading: selectIsLoading(state),
        showEditJobCommentModal: manageJobComment.showEditJobCommentModal,
        tableData: manageJobComment.filteredJobComments,
        tableFilters: manageJobComment.tableFilters,
        jobCommentsByID: manageJobComment.jobCommentsByID,
        fseUsers: manageJob.fseUsersByID,
        selectedJob,
        jobOptions,
        selectedFacilityID
    };
};
export default withTranslation('manageJobComments')(
    connect(mapStateToProps, {
        getJobComments,
        toggleEditJobCommentModal,
        closeAllModals,
        setTableFilter,
        setSelectedJobComment,
        filterJobComments,
        getCodes,
        getJobsByFacility,
        getFSEUsers
    })(ManageJobComment)
);
