import { TFunction } from 'i18next';
import { map } from 'lodash';
import React, { Component } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { RouteComponentProps } from 'react-router-dom';
import {
    addView,
    setIsTutorialOpen,
    toggleTutorial
} from '../../actions/tutorialActions';
import { constants } from '../../constants/constants';

interface Iprops extends RouteComponentProps {
    t: TFunction;
    run: boolean;
    setIsTutorialOpen: typeof setIsTutorialOpen;
    toggleTutorial: typeof toggleTutorial;
    viewedTours: string[];
    steps: Step[];
    addView: typeof addView;
}

export default class Tutorial extends Component<Iprops> {
    public translateTitles = (steps: Step[], t: TFunction) => {
        return map(steps, step => {
            if (step.title && typeof step.title === 'string') {
                return { ...step, title: t(step.title) };
            }
            return step;
        });
    };

    private handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;

        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
            this.props.setIsTutorialOpen(false);
        }
        if (([STATUS.RUNNING] as string[]).includes(status)) {
            const view = this.props.location.pathname.slice(1);
            this.props.addView(view);
        }
    };

    public render() {
        const { steps } = this.props;

        return (
            <Joyride
                callback={this.handleJoyrideCallback}
                continuous={true}
                run={this.props.run}
                showSkipButton={false}
                steps={this.translateTitles(steps, this.props.t)}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        backgroundColor: constants.colors.blue,
                        overlayColor: 'rgba(50, 55, 59, 0.822)',
                        primaryColor: '#000',
                        textColor: 'white',
                        width: 800,
                        zIndex: 10000
                    },
                    buttonNext: {
                        display: 'none'
                    }
                }}
            />
        );
    }
}
