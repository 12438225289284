import * as React from 'react';
import ReactTable, {
    RowRenderProps,
    RowInfo,
    Column,
    FinalState
} from 'react-table';
import { TFunction } from 'i18next';

import { TableUtil } from '../common/TableUtil';
import { Button } from 'react-bootstrap';
import { IcloudDocument } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { constants } from '../../constants/constants';
import {
    getCloudDocument,
    deleteVersion,
    toggleEditDocumentModal,
    setSelectedDocumentID,
    clearSelectedDocumentID
} from '../../actions/manageDocumentActions';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

import NewVersionIcon from '../../images/add.svg';
import { getLastModifiedDate } from './utils/ManageDocumentUtils';
import { orderBy } from 'lodash';

interface ExpanderProps extends RowInfo {
    t: TFunction;
    addVersion: any;
    setSelectedVersionID: typeof setSelectedDocumentID;
    getCloudDocument: typeof getCloudDocument;
    deleteVersion: typeof deleteVersion;
    toggleEditVersion: typeof toggleEditDocumentModal;
    clearSelectedDocumentID: typeof clearSelectedDocumentID;
    canEditDocuments: boolean;
    canDeleteSystemDocuments: boolean;
}

interface RowInfoVersion extends RowInfo {
    original: IcloudDocument;
}

/*
 * The Manage Document Version Expander
 */
const ManageDocumentVersionExpander = (props: ExpanderProps) => {
    const ExpanderButtonBar = (eProps: RowRenderProps) => {
        return (
            <span
                className="expander-button-bar text-right"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
            >
                <Button
                    bsStyle="link"
                    onClick={() => {
                        props.clearSelectedDocumentID();
                        props.addVersion();
                    }}
                    title={props.t('manageDocument:newVersion')}
                    className="new-version-btn"
                >
                    <img
                        src={NewVersionIcon}
                        alt=""
                        aria-hidden="true"
                        className="new-version-icon"
                    />
                </Button>
            </span>
        );
    };

    const handleDelete = (deletedItem: IcloudDocument) => {
        const toastrConfirmOptions = {
            onOk: () => {
                deletedItem = {
                    ...deletedItem
                };
                props.deleteVersion(props.original.id, deletedItem);
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: props.t('common:delete'),
            cancelText: props.t('common:cancel')
        };
        toastr.confirm(props.t('deleteConfirm'), toastrConfirmOptions);
    };

    const [tableData, setTableData] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (props.original.versions) {
            const tableData = orderBy(
                props.original.versions,
                res => moment.utc(getLastModifiedDate(res)).unix(),
                'desc'
            );
            setTableData(tableData);
        } else {
            setTableData([props.original]);
        }
    }, [props.original]);

    /*
     * Handle user clicking on a location row
     * set the selected location to state and open the modal
     */
    const getTdProps = (
        state: FinalState,
        rowInfo: RowInfoVersion | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column && column.id && column.id === 'download-button') {
            return {
                onClick: () => {
                    // Find the correct version to download
                    if (rowInfo.original && props.original.versions) {
                        const document: IcloudDocument = {
                            ...rowInfo.original,
                            // ensure we have a document type; often missing from version
                            mimeType:
                                rowInfo.original.mimeType ||
                                props.original.mimeType, // if the version doesn't have a mimeType, use the parant doc's mimeType
                            version: rowInfo.original.id
                        };
                        props.getCloudDocument(props.t, document, true);
                    } else {
                        // Failed to find the version (shouldn't happen) so download the original
                        const document: IcloudDocument = {
                            ...rowInfo.original,
                            // ensure we have a document type; often missing from row original
                            mimeType:
                                rowInfo.original.mimeType ||
                                props.original.mimeType
                        };

                        props.getCloudDocument(props.t, document, true);
                    }
                }
            };
        } else if (
            rowInfo &&
            column &&
            column.id &&
            column.id === 'delete-button' &&
            props.canEditDocuments
        ) {
            return {
                onClick: () => {
                    handleDelete(rowInfo.original);
                }
            };
        } else if (rowInfo) {
            return {
                onClick: () => {
                    if (rowInfo && rowInfo.original && rowInfo.original.id) {
                        props.setSelectedVersionID(rowInfo.original.id);
                    }
                    props.toggleEditVersion();
                }
            };
        } else {
            return {};
        }
    };

    const expanderColumns = TableUtil.translateHeaders(
        [
            {
                Footer: ExpanderButtonBar,
                minWidth: 20,
                id: 'indent-column-button-bar'
            },
            {
                Header: 'name',
                accessor: 'name',
                minWidth: 200
            },
            {
                Header: 'manageDocument:modifiedDate',
                accessor: (doc: IcloudDocument) => {
                    const date = getLastModifiedDate(doc);
                    return moment(date)
                        .local(true)
                        .format('DD-MMM-YY');
                },
                id: 'modifiedDate',
                minWidth: 200
            },
            {
                Header: 'manageDocument:version',
                id: 'quantity',
                minWidth: 100,
                accessor: (doc: IcloudDocument) => {
                    return tableData.length - tableData.indexOf(doc);
                }
            },
            {
                Header: '',
                id: 'download-button',
                Cell: (
                    <span title={props.t('Download Version')}>
                        <FontAwesomeIcon icon={['far', 'download']} />
                    </span>
                ),
                minWidth: 20
            },
            {
                Header: '',
                id: 'delete-button',
                Cell: ({ original }: { original: IcloudDocument }) => {
                    if (
                        !props.canEditDocuments ||
                        (original.isSystem && !props.canDeleteSystemDocuments)
                    ) {
                        return null;
                    }

                    return (
                        <span
                            style={{ color: constants.colors.red }}
                            title={props.t('Delete Version')}
                        >
                            <FontAwesomeIcon icon={['far', 'times']} />
                        </span>
                    );
                },
                minWidth: 20
            }
        ],
        props.t
    ) as Column[];

    return (
        <div>
            <ReactTable
                className={'attempts-expander'}
                data={tableData}
                sortable={false}
                columns={expanderColumns}
                minRows={0}
                showPageSizeOptions={false}
                rowsText="facilities"
                key={
                    props.original.versions ? props.original.versions.length : 0
                }
                getTdProps={getTdProps}
                noDataText={props.t('noDataText')}
                resizable={false}
                showPagination={
                    props.original.versions
                        ? props.original.versions.length >= 10
                        : false
                }
            />
        </div>
    );
};

export default ManageDocumentVersionExpander;
