import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import {
    ItableFiltersReducer,
    Itile,
    Iproduct,
    ImanageInventoryReducer,
    IsuggestedPart
} from '../../models';
import ReactTable, { FinalState, RowInfo } from 'react-table';
import {
    toggleEditSuggestedPartModal,
    searchSuggestedParts,
    setTableFilter
} from '../../actions/manageSuggestedPartsActions';
import {
    toggleSearchNewProductsModal,
    setSearchNewProductsSelectedProduct
} from '../../actions/manageInventoryActions';
import { debounce, orderBy } from 'lodash';
import queryString from 'query-string';

import { Banner } from '../common/Banner';
import { Button, Col, Row } from 'react-bootstrap';
import EditSuggestedPartModal from './EditSuggestedPartModal';
import SearchNewProductsModal from '../manageInventory/SearchNewProductsModal';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { emptyTile, initialProduct } from '../../reducers/initialState';
import { closeAllModals } from '../../actions/commonActions';
import { IinitialState } from '../../reducers';
import { addQuery, removeQuery } from '../common/OtherUtils';
import { Iuser } from '../../models';

interface RowInfoSuggestedPart extends RowInfo {
    original: IsuggestedPart;
}
export enum manageSuggestedPartsQueryParamsEnum {
    productID = 'productID',
    suggestedPartID = 'suggestedPartID'
}

type Iprops = RouteComponentProps<any>;

interface IdispatchProps {
    manageInventory: ImanageInventoryReducer;
    tableData: any[];
    totalPages: number;
    showEditSuggestedPartModal: boolean;
    searchSuggestedParts: typeof searchSuggestedParts;
    toggleEditSuggestedPartModal: typeof toggleEditSuggestedPartModal;
    toggleSearchNewProductsModal: typeof toggleSearchNewProductsModal;
    setSearchNewProductsSelectedProduct: typeof setSearchNewProductsSelectedProduct;
    productFromQueryParam: Iproduct;
    searchNewProductsSelectedProduct: Iproduct;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    loading: boolean;
    closeAllModals: typeof closeAllModals;
    queryParams: typeof manageSuggestedPartsQueryParamsEnum;
    user: Iuser;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
    searchFieldConfig: FieldConfig;
}

class ManageSuggestedParts extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    private setTableFilterDebounced: (formValues: {
        [key: string]: any;
    }) => void;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.setTableFilterDebounced = debounce(
            this.props.setTableFilter,
            constants.formDebounceTime
        );

        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            columns: [],
            searchFieldConfig: this.buildSearchControls()
        };
    }

    componentDidMount(): void {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.setColumns();
        this.checkForProductIdQueryParam();
        this.props.searchSuggestedParts();
        this.shouldShowSelected();
    }
    /*
     * shouldShowSelected
     * if there is a selected item in the query param
     * show the edit modal for that item
     */
    shouldShowSelected = () => {
        const shouldShow = !!(
            this.props.queryParams.suggestedPartID &&
            this.props.queryParams.suggestedPartID.length > 0
        );
        this.props.toggleEditSuggestedPartModal(shouldShow);
    };

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditSuggestedPartModal !==
                this.props.showEditSuggestedPartModal &&
            !this.props.showEditSuggestedPartModal
        ) {
            this.setState({ selectedRow: null });
        }
        // automatically get inventory every time a fitler changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.searchSuggestedParts();
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
    }
    componentWillUnmount() {
        this.props.closeAllModals();
    }
    checkForProductIdQueryParam = () => {
        if (this.props.queryParams.productID) {
            this.props.setTableFilter({
                page: 1,
                partSearch: '',
                productSearch: '',
                productID: this.props.queryParams.productID
            });
        }
    };

    canManageSuggestedParts = () => {
        return constants.hasSecurityFunction(this.props.user, [
            constants.securityFunctions.ManageSuggestedParts.id
        ]);
    };

    buildSearchControls = (): FieldConfig => {
        const mainSearchControls = {
            partSearch: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'searchPart',
                    colWidth: 3,
                    type: 'text',
                    placeholder: 'Search...',
                    defaultValue: this.props.tableFilters.partSearch,
                    isClearable: true
                },
                formState: {
                    value: this.props.tableFilters.partSearch,
                    disabled: false
                }
            },
            productSearch: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'searchProduct',
                    colWidth: 5,
                    type: 'text',
                    placeholder: 'Search...',
                    defaultValue: this.props.tableFilters.productSearch,
                    isClearable: true
                },
                formState: {
                    value: this.props.tableFilters.productSearch,
                    disabled: false
                }
            }
        };

        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    };

    onSearchValueChanges = (value: any, key: string) => {
        this.setTableFilterDebounced({ [key]: value, page: 1 });
    };

    handleEdit(row: any) {
        this.setState({ selectedRow: row.index });
        this.props.toggleEditSuggestedPartModal();
        addQuery(
            manageSuggestedPartsQueryParamsEnum.suggestedPartID,
            row.original.id,
            this.props.history
        );
    }

    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };
    /*
     * Set Columns sets columns to state
     */
    setColumns = () => {
        const columns = TableUtil.translateHeaders(
            [
                {
                    Header: 'part',
                    id: 'part',
                    accessor: ({ part }: IsuggestedPart) =>
                        `${part.number} - ${part.description}`,
                    minWidth: 200
                },
                {
                    Header: 'product',
                    accessor: 'product.name',
                    minWidth: 200
                },
                {
                    Header: 'times used',
                    accessor: 'productQuantity',
                    width: 150
                },
                {
                    Header: 'comments',
                    accessor: 'adminComments',
                    minWidth: 200
                },
                {
                    Header: 'service time',
                    accessor: 'part.installationTime',
                    width: 150
                }
            ],
            this.props.t
        );
        this.setState({ columns });
    };

    /*
     * Handle user clicking on a location row
     * set the selected location to state and open the modal
     */
    getTrProps = (
        state: FinalState,
        rowInfo: RowInfoSuggestedPart | undefined
    ) => {
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    this.handleEdit(rowInfo);
                },
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };

    /*
     * Handle Product Select from the search new products modal
     */
    handleSearchNewProductSelect = (product: Iproduct) => {
        this.props.toggleEditSuggestedPartModal();
        this.props.setSearchNewProductsSelectedProduct(product);
    };
    handleClear = () => {
        removeQuery(
            manageSuggestedPartsQueryParamsEnum.productID,
            this.props.history
        );
        this.props.setTableFilter({
            page: 1,
            partSearch: '',
            productSearch: '',
            productID: ''
        });
    };

    handleAddSuggestedPart = () => {
        this.setState({ selectedRow: null }, () => {
            removeQuery(
                manageSuggestedPartsQueryParamsEnum.suggestedPartID,
                this.props.history
            );
            if (this.props.queryParams.productID?.length) {
                this.props.toggleEditSuggestedPartModal();
            } else {
                this.props.toggleSearchNewProductsModal();
            }
        });
    };

    render() {
        const { t, tableData = [], totalPages, manageInventory } = this.props;
        const colorButton =
            constants.colors[
                `${this.state.currentTile.color}Button` as keyof typeof constants.colors
            ];

        const selectedProductForEditInstall = manageInventory.showSearchNewProductsModal
            ? this.props.searchNewProductsSelectedProduct
            : this.props.productFromQueryParam;
        return (
            <div className="manage-suggested-parts">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                {this.props.queryParams.productID?.length > 0 === false && (
                    <SearchTableForm
                        fieldConfig={this.state.searchFieldConfig}
                        handleSubmit={this.props.searchSuggestedParts}
                        loading={this.props.loading}
                        colorButton={
                            constants.colors[
                                `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                            ]
                        }
                        subscribeValueChanges={true}
                        onValueChanges={this.onSearchValueChanges}
                        t={this.props.t}
                        showSearchButton={false}
                    />
                )}
                {this.props.queryParams.productID?.length > 0 && (
                    <Row className="search-table-form">
                        <Col xs={8}>
                            <p>
                                <b>{this.props.productFromQueryParam.name}</b>
                            </p>
                            <Button
                                bsStyle="default"
                                onClick={this.handleClear}
                            >
                                {t('clear')}
                            </Button>
                        </Col>
                    </Row>
                )}
                <div>
                    <Button
                        className="table-add-button"
                        bsStyle="link"
                        onClick={this.handleAddSuggestedPart}
                        disabled={!this.canManageSuggestedParts()}
                    >
                        {t(`manageSuggestedParts:newSuggestedParts`)}
                    </Button>
                </div>
                <ReactTable
                    data={tableData}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    pageSize={tableData.length >= 10 ? tableData.length : 10}
                    manual
                    pages={totalPages}
                    page={this.props.tableFilters.page - 1}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                />

                <EditSuggestedPartModal
                    colorButton={colorButton}
                    selectedProduct={selectedProductForEditInstall}
                    queryParams={this.props.queryParams}
                />

                <SearchNewProductsModal
                    selectedItem={initialProduct}
                    colorButton={colorButton}
                    handleProductSelect={this.handleSearchNewProductSelect}
                    handleMergeProduct={() =>
                        console.info('---handleMergeProduct---')
                    }
                    secondModal={false}
                    enableMergeProductMode={false}
                    wideModal={true}
                    isApproved={true}
                    disableCreateNew={true}
                />
            </div>
        );
    }
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & RouteComponentProps<{}>
) => {
    const {
        productsByID,
        searchNewProductsSelectedProduct
    } = state.manageInventory;
    const {
        suggestedParts,
        showEditSuggestedPartModal,
        totalPages,
        tableFilters
    } = state.manageSuggestedParts;
    const queryParams = queryString.parse(
        ownProps.location.search
    ) as typeof manageSuggestedPartsQueryParamsEnum;
    const productFromQueryParam =
        productsByID[queryParams.productID] ||
        state.manageProduct.productsByID[queryParams.productID] ||
        initialProduct;
    const tableData = orderBy(suggestedParts, res => res.part.number, 'asc');
    return {
        productFromQueryParam,
        searchNewProductsSelectedProduct,
        tableData,
        totalPages,
        showEditSuggestedPartModal,
        tableFilters,
        manageInventory: state.manageInventory,
        queryParams,
        user: state.user
    };
};

export default withTranslation('manageSuggestedParts')(
    connect(mapStateToProps, {
        toggleEditSuggestedPartModal,
        toggleSearchNewProductsModal,
        setSearchNewProductsSelectedProduct,
        searchSuggestedParts,
        setTableFilter,
        closeAllModals
    })(ManageSuggestedParts)
);
