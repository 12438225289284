import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { TrackJS } from 'trackjs';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import Masonry, { MasonryOptions } from 'react-masonry-component';
import queryString from 'query-string';
import { Oval } from 'react-loader-spinner';

import API from '../../constants/apiEndpoints';
import { Banner } from '../common/Banner';
import { DashboardAnalyticsContainer } from './DashboardAnalyticsContainer';
import { IdashboardAnalyticsData, Iuser } from '../../models';
import { IinitialState } from '../../reducers';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { beginAjaxCall, endAjaxCall } from '../../actions/ajaxStatusActions';
import { constants } from '../../constants/constants';
import { filterDashboardAnalytics } from '../../helpers/reactQuery';
import { langOption } from '../../i18n';
import { msalFetch } from '../auth/Auth-Utils';
import { svgIcons } from '../../constants/svgIcons';
import { initInventory } from '../../actions/manageInventoryActions';
import { getIsMobile } from '../../reducers/configReducer';
import { getDefaultReports } from '../../actions/manageReportActions';
import { use } from 'chai';
import { getDefaultReportsReducer } from '../../reducers/manageReportReducer';

const masonryOptions: MasonryOptions = {
    transitionDuration: 0
};
const { tiles } = constants;

interface Iprops extends RouteComponentProps<{}> {
    user: Iuser;
    selectedFacilityID: string;
    isDemoMode: boolean;
    beginAjaxCall: typeof beginAjaxCall;
    endAjaxCall: typeof endAjaxCall;
    initInventory: typeof initInventory;
    getDefaultReports: typeof getDefaultReports;
}

const Dashboard = (props: Iprops & WithTranslation) => {
    const isMobile = useSelector(getIsMobile);
    const reportDefaults = useSelector(getDefaultReportsReducer);
    const getDashboardAnalytics = async () => {
        // Facility ID is required for this API call
        if (
            props.selectedFacilityID === '' ||
            props.selectedFacilityID === undefined
        ) {
            return null;
        }

        props.beginAjaxCall();

        try {
            const response = await msalFetch(API.update.dashboard, {
                method: 'get',
                params: {
                    facilityID: props.selectedFacilityID
                }
            });

            if (!response.data) {
                throw new Error('missing data');
            } else {
                return response.data as IdashboardAnalyticsData;
            }
        } catch (error) {
            console.error('[Dashboard - getDashboardAnalytics]', error);
            // throw error;
            return null;
        } finally {
            props.endAjaxCall();
        }
    };
    const { data, error, isLoading, isError, refetch } = useQuery(
        ['analytics', props.selectedFacilityID],
        getDashboardAnalytics,
        {
            refetchOnWindowFocus: false
        }
    );
    const [dashboardAnalytics, setDashboardAnalytics] = React.useState<
        IdashboardAnalyticsData | undefined
    >(data || undefined);
    const availableTiles = tiles.filter(
        tile =>
            constants.hasSecurityFunction(props.user, tile.securityFunctions) &&
            tile.shouldHideOnDashboard !== true
    );
    const tileElements = availableTiles.map(tile => {
        const { width, height, title, url, color, src, svgKey } = tile;
        const styles = {
            width,
            height
        };

        if (isMobile) {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                    }}
                >
                    <Link to={url}>
                        <div className={`tile ${color}`}>
                            <img src={src} style={styles} alt="" />
                            <span className="title">
                                {svgKey !== undefined &&
                                    svgIcons[svgKey as keyof typeof svgIcons]}
                                {props.t(title)}
                            </span>
                        </div>
                    </Link>
                </div>
            );
        } else {
            return (
                <div key={title}>
                    <Link to={url}>
                        <div className={`tile ${color}`} style={styles}>
                            <img src={src} style={styles} alt="" />
                            <span className="title">
                                {svgKey !== undefined &&
                                    svgIcons[svgKey as keyof typeof svgIcons]}
                                {props.t(title)}
                            </span>
                        </div>
                    </Link>
                </div>
            );
        }
    });

    React.useEffect(() => {
        if (props.user.language !== undefined) {
            const userLang = langOption(props.user.language);

            if (userLang !== props.i18n.language) {
                props.i18n.changeLanguage(userLang);
            }
        }
    }, [props.user]);

    React.useEffect(() => {
        refetch();
    }, [props.user.tempUnits, props.user.pressureUnits]);

    React.useEffect(() => {
        if (
            data &&
            constants.hasSecurityFunction(props.user, [
                constants.securityFunctions.ViewInventory.id
            ])
        ) {
            props.initInventory('', () => {
                return;
            });
            const filteredData = filterDashboardAnalytics(
                data,
                props.user,
                props.isDemoMode
            );

            setDashboardAnalytics(filteredData);
        }
    }, [data]);

    React.useEffect(() => {
        // If there are no report defaults, then the Run Daily Reports button inside the AnalyticsExpandedCard will not work correctly
        if (
            reportDefaults === null ||
            reportDefaults === undefined ||
            Object.keys(reportDefaults).length === 0
        ) {
            props.getDefaultReports();
        }
    }, []);

    React.useEffect(() => {
        if (isError) {
            constants.handleError(error, 'get dashboard analytics');
            TrackJS.track(error as Error);
        }
    }, [isError, error]);

    return (
        <div className="dashboard-container">
            <Banner
                title={''}
                img={constants.tiles[0].srcBanner}
                color={'dark'}
            >
                <SelectFacilityContainer t={props.t} classNameOverride={''} />
            </Banner>
            <div className="dashboard-scrollable">
                <div className="dashboard-background" />
                {isLoading ? (
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '40px 20px',
                            width: '100%'
                        }}
                    >
                        <Oval
                            color="#ff9738"
                            secondaryColor="#ffcd9e"
                            height={80}
                            width={80}
                        />
                    </div>
                ) : dashboardAnalytics ? (
                    <DashboardAnalyticsContainer
                        t={props.t}
                        history={props.history}
                        match={props.match}
                        location={props.location}
                        dashboardAnalytics={dashboardAnalytics}
                    />
                ) : null}

                {isMobile === false &&
                    (!dashboardAnalytics?.smartlinks ||
                        dashboardAnalytics?.smartlinks?.length === 0) &&
                    !isLoading && (
                        <Masonry
                            className={'dashboard'} // default ''
                            elementType={'div'} // default 'div'
                            options={masonryOptions} // default {}
                            disableImagesLoaded={false} // default false
                            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                        >
                            {tileElements}
                        </Masonry>
                    )}
            </div>
        </div>
    );
};
const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const query = queryString.parse(ownProps.location.search);

    return {
        user: state.user,
        selectedFacilityID: state.selectedFacilityID,
        isDemoMode: query.demo === 'true'
    };
};

export default withTranslation('tiles')(
    connect(mapStateToProps, {
        beginAjaxCall,
        endAjaxCall,
        initInventory,
        getDefaultReports
    })(Dashboard)
);
