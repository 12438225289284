/*
 * Search New Products Modal
 */

import { connect } from 'react-redux';
import { values } from 'lodash';
import {
    Iproduct,
    IproductQueueObject,
    IsearchNewProductInstallBatchMode
} from '../../models';
import {
    saveProduct,
    toggleEditProductModal,
    toggleSearchNewProductsModal,
    getProducts,
    resetNewProducts,
    updateProductSearchFormValues,
    setProductSearchFormValues,
    setInstallBatchMode
} from '../../actions/manageInventoryActions';
import SearchNewProductsForm from './SearchNewProductsForm';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { getMergeableProducts } from '../../actions/manageProductQueueActions';

interface Iprops {
    selectedItem: Iproduct;
    selectedQueueObject?: IproductQueueObject;
    colorButton: string;
    handleProductSelect?: (
        product: Iproduct,
        installBatchMode: IsearchNewProductInstallBatchMode
    ) => void;
    handleMergeProduct: (product: Iproduct) => void;
    isApproved?: boolean;
    secondModal?: boolean;
    enableMergeProductMode: boolean;
    wideModal: boolean;
    disableCreateNew: boolean;
    isProductQueue?: boolean;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const installBatchMode: IsearchNewProductInstallBatchMode =
        state.manageInventory.searchNewProductInstallBatchMode;
    const newProducts = values(
        ownProps.isProductQueue
            ? state.manageProductQueue.mergeableProductsByID
            : state.manageInventory.newProducts
    ).filter(prod =>
        'name' in prod
            ? prod.id !== ownProps.selectedItem.id
            : prod.product.id !== ownProps.selectedItem.id
    );

    let title = ownProps.t('searchNewProductModalTitle');

    // Search and Replace
    if (ownProps.enableMergeProductMode) {
        title = ownProps.t('replaceProductModalTitle');
    }

    // Bulk change
    if (installBatchMode && installBatchMode.installBaseIDs.length > 0) {
        title = ownProps.t('searchNewProductModalTitleBulkChange', {
            count: installBatchMode.installBaseIDs.length
        });
    }

    return {
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageInventory.showSearchNewProductsModal,
        productInfo: state.productInfo,
        formValues: state.manageInventory.productSearchFormValues,
        newProducts: newProducts || [],
        className: 'search-products',
        title,
        secondModal: ownProps.secondModal,
        wideModal: ownProps.wideModal,
        disableCreateNew: ownProps.disableCreateNew,
        isProductQueue: ownProps.isProductQueue,
        installBatchMode
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        saveProduct,
        toggleEditProductModal,
        getProducts,
        getMergeableProducts,
        toggleModal: toggleSearchNewProductsModal,
        onHide: toggleSearchNewProductsModal,
        resetNewProducts,
        updateProductSearchFormValues,
        setProductSearchFormValues,
        setInstallBatchMode
    })(WithCommonModal(SearchNewProductsForm))
);
