import { beginAjaxCall } from './ajaxStatusActions';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../constants/apiEndpoints';
import { msalFetch } from '../components/auth/Auth-Utils';

import * as types from './actionTypes';
import { constants } from '../constants/constants';
import { ThunkResult } from '../models';

export function getDocumentTypes(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };
        const url = API.cloudDocument.getAll;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LOAD_DOCUMENT_TYPES_SUCCESS,
                        payload: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LOAD_DOCUMENT_TYPES_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get Document Types');
                console.error(error);
            });
    };
}

export const setSelectedDocumentTypeID = (id: string) => ({
    type: types.SET_SELECTED_DOCUMENT_TYPE_ID,
    id
});

export const clearSelectedDocumentTypeID = () => ({
    type: types.CLEAR_SELECTED_DOCUMENT_TYPE_ID
});
