import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { IAlert } from '../../models';
import {
    markAlertAsRead,
    markAllAlertAsRead,
    toggleUserAlertModal,
    getUserAlerts
} from '../../actions/manageUserAlertActions';
import { Modal, Panel, PanelGroup, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { constant, orderBy } from 'lodash';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { constants } from '../../constants/constants';

interface IdispatchProps {
    loading: boolean;
    showUserAlertModal: boolean;
    ajaxCallsInProgress: number;
    toggleUserAlertModal: typeof toggleUserAlertModal;
    userAlerts: any;
    markAlertAsRead: typeof markAlertAsRead;
    markAllAlertAsRead: typeof markAllAlertAsRead;
    getUserAlerts: typeof getUserAlerts;
}

interface State {
    classNameAnimation: string;
    alertsWereCleared: boolean;
}

const alert_icon_white = require('../../images/icons/alert_white.png');
const alert_icon_gold = require('../../images/icons/alert_gold.png');

class UserAlertModal extends React.Component<
    IdispatchProps & WithTranslation,
    State
> {
    constructor(props: IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            classNameAnimation: '',
            alertsWereCleared: false
        };
    }

    componentDidUpdate(
        prevProps: Readonly<IdispatchProps & WithTranslation>
    ): void {
        if (
            this.state.alertsWereCleared &&
            prevProps.ajaxCallsInProgress > this.props.ajaxCallsInProgress
        ) {
            this.props.getUserAlerts();
            this.setState({ alertsWereCleared: false });
        }
    }

    renderHeader() {
        const alertCounts = Object.keys(this.props.userAlerts).length;

        return (
            <div className="alert-modal-header">
                <div className="row">
                    <hr className="seperator-line" />
                    <div className="col-xs-5">
                        <span className="notification-number">
                            {`${alertCounts} ${this.props.t(
                                'alertNotification'
                            )}`}
                        </span>
                    </div>
                    <div className="col-xs-2 text-center">
                        <div className="bell-icon">
                            <img width="30" src={alert_icon_white} alt="icon" />
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        );
    }

    renderAlertHead(alert: IAlert) {
        if (alert.type === 'alert') {
            return (
                <Panel.Heading className="row alert-header">
                    <Panel.Title toggle>
                        <div className="col-md-3">
                            <img
                                src={alert_icon_white}
                                alt="icon"
                                style={{ marginTop: '-3px' }}
                            />
                            <span>{this.props.t('alert')}</span>
                        </div>
                        <div className="col-md-6 alert-title">
                            <span style={{ fontWeight: 'bold' }}>
                                {alert.title}
                            </span>
                        </div>
                        <div className="col-md-3 text-right">
                            <span>
                                {moment
                                    .utc(alert.createDate)
                                    .local(true)
                                    .format(
                                        constants.momentDisplayFormatWithTimeAndSeconds
                                    )}
                            </span>
                        </div>
                    </Panel.Title>
                </Panel.Heading>
            );
        }

        return (
            <Panel.Heading className="row alert-header">
                <Panel.Title toggle>
                    <div className="col-md-3">
                        <img
                            src={alert_icon_gold}
                            alt="icon"
                            style={{ marginTop: '-3px' }}
                        />
                        <span>{this.props.t(`notice`)}</span>
                    </div>
                    <div className="col-md-6 alert-title">
                        <span style={{ fontWeight: 'bold' }}>
                            {alert.title}
                        </span>
                    </div>
                    <div className="col-md-3 text-right">
                        <span>
                            {moment
                                .utc(alert.createDate)
                                .local(true)
                                .format(constants.momentDisplayFormatWithTime)}
                        </span>
                    </div>
                </Panel.Title>
            </Panel.Heading>
        );
    }

    renderBackgroundDiv(
        url: string,
        height: number | null,
        width: number | null
    ) {
        if (url) {
            return (
                <div
                    className="background-div"
                    style={{
                        backgroundImage: 'url(' + url + ')',
                        backgroundSize: 'cover',
                        height: height ? height + 'px' : '',
                        width: width ? width + 'px' : ''
                    }}
                />
            );
        }
    }

    renderAlertContent(title: string, text: string) {
        return (
            <Panel.Body collapsible className="alert-content">
                <div className="alert-title">
                    <span>{title}</span>
                </div>
                <div className="alert-description">
                    <div style={{ fontSize: '16px' }}>
                        {ReactHtmlParser(text)}
                    </div>
                </div>
            </Panel.Body>
        );
    }

    handleToggle(alert: IAlert) {
        if (alert.isViewed) {
            return;
        }
        this.props.markAlertAsRead(alert);
    }

    renderAlert(alert: IAlert) {
        return (
            <Panel
                onClick={() => {
                    this.handleToggle(alert);
                }}
                eventKey={alert.id}
                key={alert.id}
                className={
                    alert.type === 'alert'
                        ? 'alerts-notification'
                        : 'alerts-notification information'
                }
            >
                {this.renderAlertHead(alert)}
                {this.renderBackgroundDiv(alert.imageUrl, 200, null)}
                {this.renderAlertContent(alert.title, alert.text)}
            </Panel>
        );
    }

    renderBody() {
        const body: any = [];
        for (const key in this.props.userAlerts) {
            body.push(this.renderAlert(this.props.userAlerts[key]));
        }

        if (body.length > 0) {
            return <PanelGroup>{body}</PanelGroup>;
        }

        return <div className="no-content">{this.props.t('noContent')}</div>;
    }

    submit = () => {
        this.setState({ alertsWereCleared: true }, () => {
            this.props.markAllAlertAsRead();
            this.props.toggleUserAlertModal();
        });
    };

    render() {
        return (
            <Modal
                container={document.querySelector('.app')}
                className="alert-modal"
                bsSize="large"
                show={this.props.showUserAlertModal}
                onHide={this.props.toggleUserAlertModal}
            >
                {this.renderHeader()}
                <Modal.Body>
                    {this.renderBody()}
                    <Col xs={12} className="text-right form-buttons">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.props.toggleUserAlertModal}
                        >
                            {this.props.t(`close`)}
                        </Button>
                        <Button
                            bsStyle="primary"
                            type="submit"
                            onClick={this.submit}
                        >
                            {this.props.t(`markAll`)}
                        </Button>
                    </Col>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = (state: IinitialState) => {
    const sortedAlerts = orderBy(
        state.alertsByID,
        res => moment.utc(res.createDate).unix(),
        'desc'
    );

    return {
        userAlerts: sortedAlerts,
        loading: selectIsLoading(state),
        showUserAlertModal: state.showAlertModal,
        ajaxCallsInProgress: state.ajaxCallsInProgress
    };
};

export default withTranslation('manageUserAlert')(
    connect(mapStateToProps, {
        toggleUserAlertModal,
        markAlertAsRead,
        markAllAlertAsRead,
        getUserAlerts
    })(UserAlertModal)
);
