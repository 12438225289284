import { TFunction } from 'i18next';
import { filter, includes, map } from 'lodash';
import { constants } from '../../constants/constants';
import {
    Iuser,
    Ioption,
    IfacilityWithoutBuildings,
    IcountryBE
} from '../../models';
import { userTypeEnum } from '../../models-enums';
import { IgenericFormValues } from '../../modelsForms';
import { convertFacillitiesToOptionsWithAllProperties } from '../../reducers/facilitiesReducer';
import { FormUtil } from '../common/FormUtil';

/*
 * formValuesToItem - convert the formValues to the shape of the selectedObjec
 * Currently taking this literally. Could potentially optimize this but have the user object is nice.
 */
export const editUserFormValuesToItem = ({
    updatedValue,
    formValues,
    facilitiesByID,
    selectedUser
}: {
    updatedValue?: IgenericFormValues<Iuser>;
    formValues: any;
    facilitiesByID: { [key: string]: IfacilityWithoutBuildings };
    selectedUser: Iuser;
}): Iuser => {
    if (updatedValue) {
        formValues = { ...formValues, ...updatedValue };
    }
    let userType;
    if (formValues.type) {
        userType = formValues.type.value;
    } else {
        userType = userTypeEnum.Hospital;
    }

    const facilities: IfacilityWithoutBuildings[] = Object.values(
        facilitiesByID
    );
    let postalCodes;
    if (formValues.postalCodes) {
        postalCodes = formValues.postalCodes.map((pCode: Ioption) => {
            return {
                userID: selectedUser.id,
                postalCode: pCode.value
            };
        });
    }

    const convertedFormValues = FormUtil.getValues(formValues);
    return {
        ...selectedUser,
        ...convertedFormValues,
        securityFunctions:
            (convertedFormValues.securityFunctions as string[]) || [],
        facilities,
        postalCodes,
        type: userType
    };
};

/*
 * itemToFormValues - take the selectedItem and convert it to formValues
 */

export const editUserItemToFormValues = (
    {
        user,
        securityOptions,
        salesManagerOptions,
        t
    }: {
        user: Iuser;
        securityOptions?: Ioption[];
        salesManagerOptions?: Ioption[];
        t: TFunction;
    },
    allCountries: IcountryBE[] = []
): IgenericFormValues<Iuser> => {
    const {
        postalCodes,
        facilities,
        securityFunctions,
        countryID,
        salesManagerID,
        type,
        userCountries: countries
    } = user;

    const selectedUserType = {
        value: type,
        label: userTypeEnum[type]
    };
    const facilityOptionsWithAddress = convertFacillitiesToOptionsWithAllProperties(
        facilities
    );
    let selectedPostalCodes: any[] = [];
    if (postalCodes && postalCodes[0] !== null) {
        selectedPostalCodes = postalCodes.map(postalCode => ({
            value: postalCode.postalCode,
            label: postalCode.postalCode
        }));
    }
    let selectedSalesManager = { label: '', value: '' };
    if (salesManagerID && salesManagerOptions) {
        const foundManagerOption = salesManagerOptions.find(
            option => option.value === salesManagerID
        );
        if (foundManagerOption) {
            selectedSalesManager = {
                label: foundManagerOption.label,
                value: salesManagerID
            };
        }
    }
    const userSecurityFunctionsWithNames = filter(
        securityOptions,
        (sec: any) => {
            return includes(securityFunctions, sec.value);
        }
    );
    const securityfunctionsArrayTranslated = map(
        userSecurityFunctionsWithNames,
        option => ({
            value: option.value,
            label: t(option.label)
        })
    );

    const selectedCountries =
        !countries || countries.length === 0 ? [countryID] : countries;

    let countryOptions;
    if (allCountries.length) {
        countryOptions = selectedCountries.map(sc => {
            const fullCountry = allCountries.find(c => c.id === sc);
            return {
                label: fullCountry?.name || '',
                value: fullCountry?.id || ''
            };
        });
    } else {
        countryOptions = selectedCountries.map(c => constants.countries[c]); //todo: remove this else, everything should be using the countries from the BE, not the static json file
    }
    return {
        ...user,
        userCountries: countryOptions,
        type: selectedUserType,
        postalCodes: selectedPostalCodes,
        facilities: facilityOptionsWithAddress,
        securityFunctions: securityfunctionsArrayTranslated,
        countryID,
        salesManagerID: selectedSalesManager
    };
};
