import { toastr } from 'react-redux-toastr';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
    ItableFiltersParams,
    ImeasurementPoint,
    ImeasurementPointList,
    ImeasurementPointListTab,
    ThunkResult
} from '../models';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import * as types from './actionTypes';
// import * as moment from 'moment';
import { map, omit, values } from 'lodash';
import { initialMeasurementPointList } from '../reducers/initialState';
import { msalFetch } from '../components/auth/Auth-Utils';
import { Dispatch } from 'redux';
import { TFunction } from 'i18next';
import { IinitialState } from '../reducers';
import { selectMeasurementPointListTabs } from '../reducers/manageMeasurementPointListsReducer';

export function getAllMeasurementPointLists(
    isCustomerView: boolean
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const {
            page,
            type,
            mainCategory,
            standard
        } = getState().manageMeasurementPointLists.tableFilters;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                page,
                mainCategoryID: mainCategory && mainCategory.value,
                type: type && type.value,
                standardID: standard && standard.value
            }
        };

        const url = API.GET.measurementPoint.getall;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS,
                        measurementPointLists: data.data.result
                    });
                    dispatch({
                        type: types.MANAGE_MEASUREMENT_POINT_LISTS_TOTAL_PAGES,
                        pages: data.data.pages
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.MANAGE_MEASUREMENT_POINT_LISTS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get measurement point lists');
                console.error(error);
            });
    };
}

export const setSelectedMeasurementPointList = (
    measurementPointList: ImeasurementPointList
) => ({
    type: types.SELECT_MEASUREMENT_POINT_LIST,
    measurementPointList
});

/*
 * save (add) a new mpl
 */
export function addGlobalMeasurementPointList(
    mpl: ImeasurementPointList
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        // convert the measementPoints back to an array
        const measurementPointTabs = mpl.measurementPointTabs.map(tab => {
            // Let clean any potential bad data
            const cleanedMPs = map(values(tab.measurementPoints), mp => {
                // Sometimes, this value get's sent as a number, instead of a boolean
                if (mp.numericAllowDecimals !== undefined) {
                    let temp: any = mp.numericAllowDecimals;
                    if (temp === 0) {
                        temp = false;
                    } else if (temp === 1) {
                        temp = true;
                    }
                    mp.numericAllowDecimals = temp;
                }
                return mp;
            });

            return { ...tab, measurementPoints: cleanedMPs };
        });
        const listForAPI = { ...mpl, measurementPointTabs };

        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: listForAPI,
            timeout: 120000 // manually set a longer timeout since we know this is a large request
        };

        const url = API.POST.measurementPoint.addglobalmpl;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch(endAjaxCall());
                    dispatch({
                        type: types.MANAGE_MEASUREMENT_POINT_LIST_ADD,
                        measurementPointList: mpl
                    });
                    dispatch({
                        type: types.TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_LISTS
                    });
                    toastr.success(
                        'Success',
                        'Created new measurement point list.',
                        constants.toastrSuccess
                    );
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.MANAGE_MEASUREMENT_POINT_LIST_ADD_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'create measurement point list');
                console.error(error);
            });
    };
}

/*
 * update a tab
 */
export function updateMeasurementPointListTab(
    tab: ImeasurementPointListTab
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({
            type: types.MANAGE_MEASUREMENT_POINT_TAB_UPDATE,
            tab
        });
        if (tab.isDeleted === true) {
            dispatch({
                type: types.MANAGE_MEASUREMENT_POINT_SET_SELECTED_TAB,
                selectedTabID: ''
            });
        }
    };
}
/*
 * update a mpl
 */
export function updateMeasurementPointList(
    mpl: ImeasurementPointList,
    persistToAPI: boolean,
    isCustomer: boolean
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({
            type: types.MANAGE_MEASUREMENT_POINT_LIST_UPDATE,
            measurementPointList: mpl,
            persistToAPI
        });
        if (persistToAPI) {
            dispatch(beginAjaxCall());
            // convert the measementPoints back to an array
            const measurementPointTabs = mpl.measurementPointTabs.map(tab => {
                return {
                    ...tab,
                    measurementPoints: map(
                        values(tab.measurementPoints),
                        mp => {
                            let cleanedMP = { ...mp };

                            if (
                                Object.keys(cleanedMP).findIndex(
                                    key => key === 'numericMinValue'
                                ) !== -1
                            ) {
                                cleanedMP = {
                                    ...cleanedMP,
                                    numericMinValue:
                                        Number(cleanedMP?.numericMinValue) ??
                                        null
                                };
                            }

                            if (
                                Object.keys(cleanedMP).findIndex(
                                    key => key === 'numericMaxValue'
                                ) !== -1
                            ) {
                                cleanedMP = {
                                    ...cleanedMP,
                                    numericMaxValue:
                                        Number(cleanedMP?.numericMaxValue) ??
                                        null
                                };
                            }

                            if (
                                Object.keys(cleanedMP).findIndex(
                                    key => key === 'numericAllowDecimals'
                                ) !== -1
                            ) {
                                // Just silly, sometimes, this boolean is a number, instead of "true/false"
                                let answer: any =
                                    cleanedMP?.numericAllowDecimals;
                                if (answer === 1) {
                                    answer = true;
                                } else if (answer === 0) {
                                    answer = false;
                                }
                                cleanedMP = {
                                    ...cleanedMP,
                                    numericAllowDecimals: answer
                                };
                            }

                            return { ...cleanedMP };
                        }
                    )
                };
            });
            const listForAPI = { ...mpl, measurementPointTabs };

            // Set the correct URL dependent on if this is a customer or not
            let url = `${API.PUT.measurementPoint.updateglobalmpl}/${mpl.id}`;
            if (isCustomer) {
                url = `${API.PUT.measurementPoint.updatecustomermpl}/${mpl.id}`;
            }
            const axiosOptions: AxiosRequestConfig = {
                method: 'put',
                data: listForAPI,
                timeout: 120000 // manually set a longer timeout since we know this is a large request
            };

            return msalFetch(url, axiosOptions)
                .then((data: AxiosResponse<any>) => {
                    if (!data.data) {
                        throw new Error('missing data');
                    } else {
                        dispatch(endAjaxCall());
                        dispatch({
                            type:
                                types.TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_LISTS
                        });
                        dispatch({
                            type: types.SELECT_MEASUREMENT_POINT_LIST,
                            measurementPointList: initialMeasurementPointList
                        });
                        dispatch({
                            type:
                                types.MANAGE_MEASUREMENT_POINT_SET_SELECTED_TAB,
                            selectedTabID: ''
                        });
                        toastr.success(
                            'Success',
                            'Updated measurement point list.',
                            constants.toastrSuccess
                        );
                    }
                })
                .catch((error: any) => {
                    dispatch({
                        type: types.MANAGE_MEASUREMENT_POINT_LIST_UPDATE_FAILED,
                        error,
                        axiosOptions
                    });
                    constants.handleError(
                        error,
                        'update measurement point list'
                    );
                    throw error; // intentionally rethrow
                });
        } else {
            return Promise.resolve(true);
        }
    };
}

export const saveMeasurementPointToMeasurementPointListHelper = (
    listID: string,
    selectedTabID: string,
    measurementPoint: ImeasurementPoint,
    dispatch: Dispatch,
    getState: () => IinitialState
) => {
    dispatch({
        type: types.MANAGE_MEASUREMENT_POINT_SAVE_TO_LIST,
        listID,
        selectedTabID,
        measurementPoint
    });
};

export function deleteMeasurementPoint(
    measurementPointID: string,
    measurementPointListID: string,
    selectedTabID: string,
    t: TFunction
): ThunkResult<any> {
    return (dispatch, getState) => {
        const measurementPointListsByID = getState().manageMeasurementPointLists
            .measurementPointListsByID;
        const selectedMeasurementPointList =
            measurementPointListsByID[measurementPointListID];

        // If we didn't find a list, that means the list is being created right now and hasn't been saved yet
        // we need to remove the measurement point from redux beacuse it hasn't been sent to the api yet
        if (!selectedMeasurementPointList) {
            const toastrConfirmOptions = {
                onOk: () => {
                    const measurementPointList = getState()
                        .manageMeasurementPointLists
                        .selectedMeasurementPointList;

                    dispatch({
                        type: types.REMOVE_MEASUREMENT_POINT_FROM_TAB,
                        measurementPointList,
                        selectedTabID,
                        measurementPointID
                    });

                    dispatch({
                        type: types.TOGGLE_MODAL_EDIT_MEASUREMENT_POINT
                    });
                },
                onCancel: () => console.info('CANCEL: clicked'),
                okText: t('deleteMeasurementPointOk'),
                cancelText: t('common:cancel')
            };
            toastr.confirm(t('deleteConfirmMP'), toastrConfirmOptions);
        } else {
            const selectedTab = selectedMeasurementPointList.measurementPointTabs.find(
                tab => tab.id === selectedTabID
            );
            if (!selectedTab) {
                throw new Error('missing selected measurement point tab');
            }
            const selectedMeasurementPoint =
                selectedTab.measurementPoints[measurementPointID];

            const toastrConfirmOptions = {
                onOk: () => {
                    saveMeasurementPointToMeasurementPointListHelper(
                        measurementPointListID,
                        selectedTabID,
                        { ...selectedMeasurementPoint, isDeleted: true },
                        dispatch,
                        getState
                    );
                    dispatch({
                        type: types.TOGGLE_MODAL_EDIT_MEASUREMENT_POINT
                    });
                },
                onCancel: () => console.info('CANCEL: clicked'),
                okText: t('deleteMeasurementPointOk'),
                cancelText: t('common:cancel')
            };
            toastr.confirm(t('deleteConfirmMP'), toastrConfirmOptions);
        }
    };
}

export function deleteGlobalMeasurementPointList(
    MPlistID: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };

        const url = `${API.DELETE.measurementPoint.deleteglobalmpl}/${MPlistID}`;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.MANAGE_MEASUREMENT_POINT_LIST_DELETE_SUCCESS,
                    MPlistID
                });
                toastr.success(
                    'Success',
                    'Deleted measurement point list.',
                    constants.toastrSuccess
                );
            })
            .catch((error: any) => {
                dispatch({
                    type: types.MANAGE_MEASUREMENT_POINT_LIST_DELETE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'delete measurement point list');
                console.error(error);
            });
    };
}

/*
 * update the selected Measurement Point
 */
export const updateMeasurementPoint = (
    measurementPoint: ImeasurementPoint,
    selectedTabID: string
) => ({
    type: types.MANAGE_MEASUREMENT_POINT_UPDATE,
    measurementPoint,
    selectedTabID
});

export const saveMeasurementPointToMeasurementPointList = (
    listID: string,
    selectedTabID: string,
    measurementPoint: ImeasurementPoint
) => ({
    type: types.MANAGE_MEASUREMENT_POINT_SAVE_TO_LIST,
    listID,
    selectedTabID,
    measurementPoint
});

/*
 * Get a specific measurement point list
 * TODO verify we are receiving deleted MPs here
 */
export function getMeasurementPointList(MPlistID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        const url = `${API.GET.measurementPoint.getMeasurementPointList}/${MPlistID}`;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.MEASUREMENT_POINT_LIST_SUCCESS,
                        list: data.data
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.MEASUREMENT_POINT_LIST_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get a measurement point list');
                console.error(error);
            });
    };
}

export const toggleEditMeasurementPointListModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_LISTS
});
export const toggleEditMeasurementPointModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_MEASUREMENT_POINT
});
export const toggleEditMeasurementPointTabModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_TAB
});
export const toggleEditMeasurementPointListTestProceduresModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_LIST_TEST_PROCEDURES
});
export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_MEASUREMENT_POINT_LISTS,
    filters
});
export const setSelectedTabID = (selectedTabID: string) => ({
    type: types.MANAGE_MEASUREMENT_POINT_SET_SELECTED_TAB,
    selectedTabID
});
