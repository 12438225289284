import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../constants/apiEndpoints';
import { msalFetch } from '../components/auth/Auth-Utils';

import * as types from './actionTypes';
import { constants } from '../constants/constants';
import { toastr } from 'react-redux-toastr';
import { ItableFiltersParams, IdeficiencyCode, ThunkResult } from '../models';

export const getCodes = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        const { user } = getState();

        if (
            !constants.hasSecurityFunction(
                user,
                [
                    constants.securityFunctions.FSE.id,
                    constants.securityFunctions.ManageCodes.id
                ],
                { matchAll: true }
            )
        )
            return dispatch({
                type: types.LOAD_CODES_SUCCESS,
                photos: []
            });

        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { page: 1, pagingType: 'None' }
        };

        const url = API.code.search;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LOAD_CODES_SUCCESS,
                        payload: data.data.result
                    });
                    // dispatch({
                    //   type: types.CODE_MANAGE_TOTAL_PAGES,
                    //   pages: data.data.pages
                    // });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.LOAD_CODES_SUCCESS });
                constants.handleError(error, 'get codes');
            });
    };
};

export const saveCode = (
    code: Partial<IdeficiencyCode>
): ThunkResult<any> => dispatch => {
    dispatch(beginAjaxCall());

    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: code
    };

    const url = API.code.single + '/add';
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                const newCode = data.data;
                dispatch({
                    type: types.ADD_CODE_SUCCESS,
                    payload: newCode.value
                });
                toastr.success(
                    'Success',
                    `Created Standard Text`,
                    constants.toastrSuccess
                );
                dispatch({ type: types.TOGGLE_MODAL_EDIT_CODE });
            }
        })
        .catch((error: any) => {
            dispatch({ type: types.ADD_CODE_FAILED, error, axiosOptions });
            constants.handleError(error, 'ADD_CODE_FAILED');
        });
};

export const updateCode = (
    code: Partial<IdeficiencyCode>
): ThunkResult<any> => dispatch => {
    dispatch(beginAjaxCall());

    const axiosOptions: AxiosRequestConfig = {
        method: 'put',
        data: code
    };

    const url = `${API.code.single}/${code.id}`;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                dispatch({
                    type: types.EDIT_CODE_SUCCESS,
                    payload: data.data.value
                });
                toastr.success(
                    'Success',
                    `Updated Standard Text`,
                    constants.toastrSuccess
                );
                dispatch({ type: types.TOGGLE_MODAL_EDIT_CODE });
            }
        })
        .catch((error: any) => {
            dispatch({ type: types.EDIT_CODE_FAILED, error, axiosOptions });
            constants.handleError(error, 'EDIT_CODE_FAILED');
        })
        .finally(() => dispatch(endAjaxCall()));
};

export const deleteCode = (
    code: IdeficiencyCode
): ThunkResult<any> => dispatch => {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
        method: 'delete'
    };

    const url = `${API.code.single}/${code.id}`;

    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            dispatch({ type: types.REMOVE_CODE_SUCCESS, payload: code });
            toastr.success(
                'Success',
                `Deleted Standard Text`,
                constants.toastrSuccess
            );
        })
        .catch((error: any) => {
            dispatch({ type: types.REMOVE_CODE_FAILED, error, axiosOptions });
            constants.handleError(error, 'REMOVE_CODE_FAILED');
        });
};

export const setSelectedCodeID = (id: string) => ({
    type: types.SET_SELECTED_CODE_ID,
    id
});

export const clearSelectedCodeID = () => ({
    type: types.CLEAR_SELECTED_CODE_ID
});

export const toggleEditCodeModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_CODE
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_CODE,
    filters
});

export const setCodeFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_MANAGE_CODE,
    formValues
});

export const updateCodeFormValue = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_CODE,
    formValues
});
