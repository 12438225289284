/*
 * Training Breadcumb Container
 */

import { RouteComponentProps, withRouter } from 'react-router';
import {
    initialCourse,
    initialLesson,
    initialQuiz
} from '../../reducers/initialState';

import { TrainingBreadcrumb } from './TrainingBreadcrumb';
import { connect } from 'react-redux';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface RouterParams {
    courseID: string;
    lessonID: string;
    quizID: string;
}

type Iprops = RouteComponentProps<RouterParams>;

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const quiz =
        state.training.quizzesByID[ownProps.match.params.quizID] || initialQuiz;
    const lesson =
        state.training.lessonsByID[ownProps.match.params.lessonID] ||
        initialLesson;
    const selectedCourse =
        state.training.coursesByID[ownProps.match.params.courseID] ||
        initialCourse;

    return {
        quiz,
        lesson,
        selectedCourse,
        loading: selectIsLoading(state)
    };
};

export const TrainingBreadcrumbContainer = withRouter(
    connect(mapStateToProps, {})(TrainingBreadcrumb)
);
