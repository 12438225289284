import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { TFunction } from 'i18next';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { constants } from '../../constants/constants';
import {
    IanalyticsTAE,
    IinstallBase,
    IdataFormatPreference
} from '../../models';
import { dataFormatEnum } from '../../models-enums';
import { Banner } from '../common/Banner';
import { mockAnalyticsTaeManifold } from './mockAnalytics';
import {
    getBarChartOptionsDefault,
    getBarChartOptions,
    getGuageChartOptions,
    getGuageChartOptionsDefault
} from './Utilities';
import {
    setSelectedProductID,
    toggleInstallContactModal
} from '../../actions/manageInventoryActions';
import InstallContactModal from '../manageInventory/InstallContactModal';

interface Iprops {
    t: TFunction;
    analyticTAE: IanalyticsTAE;
    installBase: IinstallBase;
    isDemo: boolean;
    dataFormatPreference: IdataFormatPreference;
    setSelectedProductID: typeof setSelectedProductID;
    toggleInstallContactModal: typeof toggleInstallContactModal;
}
interface Istate {
    chartOption1: Partial<Highcharts.Options>;
    chartOption2: Partial<Highcharts.Options>;
    chartOption3: Partial<Highcharts.Options>;
    chartOption4: Partial<Highcharts.Options>;
    chartOption5: Partial<Highcharts.Options>;
}
export default class TaeDetails extends Component<Iprops, Istate> {
    static defaultProps = {
        analyticTAE: mockAnalyticsTaeManifold
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            chartOption1: getGuageChartOptionsDefault({ t: this.props.t }),
            chartOption2: getGuageChartOptionsDefault({ t: this.props.t }),
            chartOption3: getGuageChartOptionsDefault({ t: this.props.t }),
            chartOption4: getGuageChartOptionsDefault({ t: this.props.t }),
            chartOption5: getBarChartOptionsDefault({ t: this.props.t })
        };
    }
    componentDidMount() {
        this.handleUpdateDataDetails();
    }
    handleUpdateDataDetails = () => {
        const { analyticTAE, t, dataFormatPreference } = this.props;
        console.info('i ahve a dream', analyticTAE);
        this.setState({
            chartOption1: getGuageChartOptions(
                t,
                dataFormatPreference,
                analyticTAE.device?.guage01
            ),
            chartOption2: getGuageChartOptions(
                t,
                dataFormatPreference,
                analyticTAE.device?.guage02
            ),
            chartOption3: getGuageChartOptions(
                t,
                dataFormatPreference,
                analyticTAE.device?.guage03
            ),
            chartOption4: getGuageChartOptions(
                t,
                dataFormatPreference,
                analyticTAE.device?.guage04
            ),
            chartOption5: getBarChartOptions(
                t,
                dataFormatPreference,
                analyticTAE.device?.bar01
            )
        });
    };
    render() {
        const colorButton = 'primary';
        const {
            device,
            installBaseName,
            photoUrl,
            installBaseSerialNumber,
            installBaseLocation,
            gasType,
            date
        } = this.props.analyticTAE;
        const getGasType = gasType?.split('');
        const productID = this.props.installBase?.productID;
        const getAlarms = device?.alarms?.split(',') || [];
        const { t } = this.props;

        return (
            <div className="dashboard-container">
                <Banner
                    title={''}
                    img={constants.tiles[0].srcBanner}
                    color={'dark'}
                ></Banner>
                <div className="dashboard-scrollable">
                    <Col
                        xs={12}
                        lg={12}
                        style={{
                            width: '-webkit-fill-available',
                            margin: '15px',
                            padding: '24px'
                        }}
                        className="container-details form-group smart-links-analytics"
                    >
                        <Row>
                            <Col xs={12} lg={10}>
                                <h4 className="device-title">
                                    {installBaseName}
                                </h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="device-subTitle ">
                                {`Updated: ${moment(date || new Date()).format(
                                    'hh:mm a, MM/DD/YYYY'
                                )}`}
                            </Col>
                        </Row>
                        <Row style={{}}>
                            <Col xs={12} md={6} lg={3}>
                                <img
                                    style={{
                                        height: 'auto',
                                        maxHeight: '368px',
                                        maxWidth: '205px',
                                        width: '100%'
                                    }}
                                    src={photoUrl}
                                    alt="devices"
                                />
                                <Col
                                    xs={12}
                                    style={{
                                        marginTop: '24px',
                                        color: '#A2ACB4',
                                        fontSize: '16px'
                                    }}
                                >
                                    <p>{`Serial Number: ${installBaseSerialNumber}`}</p>
                                    <p
                                        style={{ marginTop: '16px' }}
                                    >{`Location Main: ${installBaseLocation}`}</p>
                                </Col>
                            </Col>
                            <Col
                                xs={12}
                                lg={device?.guage02 ? 5 : 3}
                                md={6}
                                style={{
                                    borderRight: '1px solid #EEEEEE'
                                }}
                            >
                                <Row
                                    style={{
                                        borderBottom: '1px solid #EEEEEE'
                                    }}
                                >
                                    {device?.guage01 && (
                                        <Col xs={12} lg={6}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    this.state.chartOption1
                                                }
                                                {...this.props}
                                            />
                                        </Col>
                                    )}
                                    {device?.guage02 && (
                                        <Col xs={12} lg={6}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    this.state.chartOption2
                                                }
                                                {...this.props}
                                            />
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    {device?.guage03 && (
                                        <Col xs={12} lg={6}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    this.state.chartOption3
                                                }
                                                {...this.props}
                                            />
                                        </Col>
                                    )}

                                    {device?.guage04 && (
                                        <Col xs={12} lg={6}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={
                                                    this.state.chartOption4
                                                }
                                                {...this.props}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                            <Col
                                xs={12}
                                lg={device?.bar01 ? 4 : 2}
                                style={{
                                    fontWeight: 700,
                                    textTransform: 'uppercase',
                                    textAlign: 'left',
                                    fontSize: '14px'
                                }}
                            >
                                {getGasType && (
                                    <>
                                        <p className="gas-type">Gas Type</p>
                                        <div style={{ display: 'flex' }}>
                                            <p style={{ fontSize: '135px' }}>
                                                {getGasType[0]}
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: '68px',
                                                    paddingTop: '20px'
                                                }}
                                            >
                                                {getGasType[1]}
                                            </p>
                                        </div>
                                    </>
                                )}
                                {device?.bar01 && (
                                    <Col xs={6}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={this.state.chartOption5}
                                            {...this.props}
                                        />
                                    </Col>
                                )}
                            </Col>
                            <Col
                                xs={12}
                                lg={2}
                                md={6}
                                style={{ padding: '0 14px' }}
                            >
                                <button
                                    type="button"
                                    className="request-service"
                                    onClick={() => {
                                        this.props.toggleInstallContactModal();
                                        this.props.setSelectedProductID(
                                            productID
                                        );
                                    }}
                                >
                                    Request Service
                                </button>
                                {getAlarms.map(
                                    (item: string, index: number) => {
                                        if (item) {
                                            return (
                                                <div
                                                    key={index}
                                                    className="alarms"
                                                >
                                                    {`Alarms: ${item}`}
                                                </div>
                                            );
                                        }

                                        return null;
                                    }
                                )}
                            </Col>
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <Col lg={9} xs={12} className="summary">
                                <Col
                                    xs={12}
                                    lg={4}
                                    style={{
                                        display: 'flex',
                                        margin: '11px 0'
                                    }}
                                >
                                    <p className="normal-text">System Hours:</p>
                                    <p className="number">
                                        {t(
                                            `common:format.${
                                                dataFormatEnum[
                                                    dataFormatEnum
                                                        .numberTruncate
                                                ]
                                            }`,
                                            {
                                                numberString:
                                                    device?.loadedHours
                                            }
                                        )}
                                    </p>
                                    <p className="hours">hours</p>
                                </Col>
                            </Col>
                            <Col
                                xs={12}
                                lg={3}
                                style={{
                                    marginLeft: 'auto',
                                    paddingTop: '60px'
                                }}
                            >
                                <Link
                                    to={`/dashboard?demo=${this.props.isDemo}`}
                                >
                                    <Button className="expand-button">
                                        Close Details
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </div>
                <InstallContactModal colorButton={colorButton} />
            </div>
        );
    }
}
