import {
    Ifacility,
    IfacilityContact,
    Ilead,
    IleadUser,
    Iproduct,
    ItableFiltersReducer,
    Itile,
    IleadActivity,
    ImanageLeadsReducer
} from '../../models';
import ReactTable, { Column, FinalState, RowInfo } from 'react-table';
import selectTableHOC, {
    SelectTableAdditionalProps
} from 'react-table/lib/hoc/selectTable';
import treeTableHOC from 'react-table/lib/hoc/treeTable';
import { Col, Row } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    dateRangeEnum,
    leadRankingEnum,
    leadStatusEnum,
    leadTypeEnum
} from '../../models-enums';
import {
    deleteLeadInstallBase,
    getLeads,
    setTableFilter,
    toggleLeadsModal,
    setSelectedLeadFromID,
    setSelectedLeadActivityFromID,
    updateSelectedLead,
    toggleLeadActivityModal,
    getLeadActivities,
    toggleEditLeadActivityModal,
    updateLeadSelection
} from '../../actions/manageLeadsActions';

import EditLeadModal from '../manageLeads/EditLeadModal';
import { Banner } from '../common/Banner';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import { LeadInstallBasesExpander } from './LeadInstallBasesExpander';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { ActionButton } from './ActionButton';
import { addQuery } from '../common/OtherUtils';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { debounce } from 'lodash';
import { emptyTile } from '../../reducers/initialState';
import moment from 'moment';
import { selectLeadsForTable } from '../../reducers/manageLeadsReducers';
import LeadActivityListModal from './LeadActivityListModal';
import EditLeadActivityModal from './EditLeadActivityModal';
import FacilityContactModal from '../common/FacilityContactModal';
import queryString from 'query-string';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { LeadBulkActionsButtonContainer } from './LeadsBulkActionsButtonContainer';

interface RowInfoLead extends RowInfo {
    original: Ilead;
}

export enum manageLeadQueryParamsEnum {
    selectedLeadID = 'selectedLeadID',
    selectedActivityID = 'selectedActivityID'
}

type Iprops = RouteComponentProps<any>;

const SelectTable = selectTableHOC(treeTableHOC(ReactTable));

interface IdispatchProps {
    getLeads: typeof getLeads;
    tableData: Ilead[];
    loading: boolean;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    facilities: { [key: string]: Ifacility };
    leadsByID: { [key: string]: Ilead };
    products: { [key: string]: Iproduct };
    toggleLeadActivityModal: typeof toggleLeadActivityModal;
    selectedLeadID: string;
    deleteLeadInstallBase: typeof deleteLeadInstallBase;
    setSelectedLeadFromID: typeof setSelectedLeadFromID;
    setSelectedLeadActivityFromID: typeof setSelectedLeadActivityFromID;
    selectedLead: Ilead;
    updateSelectedLead: typeof updateSelectedLead;
    queryParams: typeof manageLeadQueryParamsEnum;
    getLeadActivities: typeof getLeadActivities;
    toggleEditLeadActivityModal: typeof toggleEditLeadActivityModal;
    toggleLeadsModal: typeof toggleLeadsModal;
    leadActivitiesByID: { [key: string]: IleadActivity };
    selection: string[];
    updateLeadSelection: typeof updateLeadSelection;
    leadsManage: ImanageLeadsReducer;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    searchFieldConfig: FieldConfig;
    selectAll: boolean;
}

class ManageLeadsWithSelect extends React.Component<
    Iprops & IdispatchProps & WithTranslation & SelectTableAdditionalProps,
    Istate
> {
    public columns: any[];
    public buttonInAction = false;
    private checkboxTable: any;
    private setTableFilterDebounced: (formValues: {
        [key: string]: any;
    }) => void;

    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            searchFieldConfig: { controls: {} },
            selectAll: false
        };
        this.setTableFilterDebounced = debounce(
            this.props.setTableFilter,
            constants.formDebounceTime
        );

        this.columns = TableUtil.translateHeaders(
            [
                {
                    id: 'expander-toggle',
                    expander: true,
                    Expander: TableUtil.expanderToggle,
                    style: {
                        cursor: 'pointer',
                        textAlign: 'left',
                        userSelect: 'none'
                    }
                },
                {
                    id: 'created',
                    Header: 'created',
                    accessor: ({ createDate }: Ilead) => {
                        return moment
                            .utc(createDate)
                            .local(true)
                            .format(constants.momentDisplayFormatWithTime);
                    },
                    minWidth: 100
                },
                {
                    id: 'leadNumber',
                    Header: 'leadNumber',
                    accessor: ({ leadNumber }: Ilead) =>
                        leadNumber ? leadNumber : '',
                    minWidth: 70
                },
                {
                    id: 'bundleQuoteNumber',
                    Header: 'bundleQuoteNumber',
                    accessor: ({ quote }: Ilead) =>
                        quote ? quote.quoteNumber : '',
                    minWidth: 100
                },
                {
                    id: 'type',
                    Header: 'type',
                    accessor: ({ type }) =>
                        this.props.t(
                            `${leadTypeEnum[type as keyof typeof leadTypeEnum]}`
                        ),
                    minWidth: 90
                },
                {
                    id: 'product',
                    Header: 'product',
                    accessor: ({ productID }: Ilead) =>
                        this.props.products[productID]
                            ? this.props.products[productID].name
                            : '',
                    minWidth: 200
                },
                {
                    id: 'facility',
                    Header: 'facility',
                    accessor: ({ facilityID }: Ilead) =>
                        this.props.facilities[facilityID]
                            ? this.props.facilities[facilityID].name
                            : '',
                    minWidth: 100
                },
                {
                    id: 'salesName',
                    Header: 'salesName',
                    Cell: (row: RowInfoLead) => (
                        <ul
                            style={{ listStyle: 'none', padding: 0, margin: 0 }}
                        >
                            {row.original.leadUsers
                                ? row.original.leadUsers.map(
                                      (leadUser: IleadUser) => {
                                          if (leadUser.user) {
                                              const style = leadUser.assignedDueToMissingCoverage
                                                  ? { color: 'orange' }
                                                  : {};
                                              return (
                                                  <li
                                                      key={leadUser.user.id}
                                                      style={style}
                                                  >
                                                      {leadUser.user.first}{' '}
                                                      {leadUser.user.last}
                                                  </li>
                                              );
                                          } else {
                                              return '';
                                          }
                                      }
                                  )
                                : ''}
                        </ul>
                    ),
                    minWidth: 110
                },
                {
                    id: 'status',
                    Header: 'status',
                    accessor: ({ status }) =>
                        this.props.t(
                            `${
                                leadStatusEnum[
                                    status as keyof typeof leadStatusEnum
                                ]
                            }`
                        ),
                    width: 90
                },
                {
                    Header: '',
                    id: 'actions',
                    minWidth: 120,
                    // width: 100,
                    style: { overflow: 'visible' },
                    Cell: (row: RowInfoLead) => {
                        const colorButton =
                            constants.colors[
                                `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                            ];
                        return (
                            <ActionButton
                                colorButton={colorButton}
                                t={this.props.t}
                                rowInfo={row}
                                showLeadActivity={this.handleShowLeadActivity}
                                setSelectedLeadFromID={this.handleSelectLead}
                            />
                        );
                    }
                }
            ],
            this.props.t
        );
    }

    getTdProps = (
        state: FinalState,
        rowInfo: RowInfoLead | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column && column.id !== 'actions') {
            return {
                onClick: () => {
                    // this.handleSelectLead(rowInfo.original.id);
                    this.setState({
                        selectedRow: {
                            [rowInfo.viewIndex || 0]: !this.state.selectedRow[
                                rowInfo.viewIndex || 0
                            ]
                        }
                    });
                }
            };
        } else {
            return {};
        }
    };

    componentDidMount() {
        // if status is undefined initialize the default table filters
        if (this.props.tableFilters.status === undefined) {
            this.props.setTableFilter({
                status: this.defaultStatus()
            });
        } else {
            this.props.getLeads();
        }
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname),
            searchFieldConfig: this.buildSearchFieldConfig()
        });
        this.shouldShowSelected();
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.getLeads();

            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
    }

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    /**
     * Toggle a single checkbox for select table
     */
    toggleSelection = (key: string, shift: boolean, row: any) => {
        // start off with the existing state
        let selection = [...this.props.selection];
        const keyIndex = selection.indexOf(key);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(key);
        }
        // update the state
        this.props.updateLeadSelection(selection);
    };

    /**
     * Toggle all checkboxes for select table
     */
    toggleAll = () => {
        const { keyField = 'id' } = this.props;
        const selectAll = !this.state.selectAll;
        const selection: string[] = [];

        if (selectAll && keyField !== undefined) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords: any[] = wrappedInstance.getResolvedState()
                .sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(`select-${item._original[keyField]}`);
            });
        }
        this.setState({ selectAll });
        this.props.updateLeadSelection(selection);
    };

    /**
     * Whether or not a row is selected for select table
     */
    isSelected = (key: string) => {
        return this.props.selection.includes(`select-${key}`);
    };
    /*
     * shouldShowSelected
     * if there is a selected item in the query param
     * show the edit modal for that item
     */
    shouldShowSelected = () => {
        const shouldShowLeadActivity = !!(
            this.props.queryParams.selectedActivityID &&
            this.props.queryParams.selectedActivityID.length > 0
        );
        this.props.toggleLeadActivityModal(shouldShowLeadActivity);
        this.props.toggleEditLeadActivityModal(shouldShowLeadActivity);
        if (shouldShowLeadActivity) {
            this.props.setSelectedLeadFromID(
                this.props.leadActivitiesByID[
                    this.props.queryParams.selectedActivityID
                ].leadID
            );
        }

        const shouldShowLead = !!(
            this.props.queryParams.selectedLeadID &&
            this.props.queryParams.selectedLeadID.length > 0 &&
            shouldShowLeadActivity === false
        );
        this.props.toggleLeadsModal(shouldShowLead);
    };

    handleSelectLead = (id: string) => {
        addQuery(
            manageLeadQueryParamsEnum.selectedLeadID,
            id,
            this.props.history
        );
        this.props.setSelectedLeadFromID(id);
        this.props.toggleLeadsModal();
    };

    handleShowLeadActivity = (leadID: string) => {
        this.props.toggleLeadActivityModal();
        this.props.getLeadActivities(leadID);
        this.props.setSelectedLeadFromID(leadID);
    };

    handleSelectLeadActivity = (id: string) => {
        addQuery(
            manageLeadQueryParamsEnum.selectedActivityID,
            id,
            this.props.history
        );
        this.props.setSelectedLeadActivityFromID(id);
        this.props.toggleEditLeadActivityModal();
    };

    defaultStatus = () =>
        FormUtil.convertToSingleOption({
            id: leadStatusEnum.New.toString(),
            label: this.props.t(leadStatusEnum[leadStatusEnum.New])
        });

    defaultDateRange = () =>
        FormUtil.convertToSingleOption({
            id: dateRangeEnum.thisQuarter.toString(),
            label: this.props.t(dateRangeEnum[dateRangeEnum.thisQuarter])
        });

    buildSearchFieldConfig = (): FieldConfig => {
        const {
            search,
            status = this.defaultStatus(),
            ranking,
            dateRange = this.defaultDateRange()
        } = this.props.tableFilters;
        const disabled = false;
        const mainSearchControls = {
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:search',
                    colWidth: 4,
                    colWidthLarge: 3,
                    type: 'text',
                    placeholder: 'common:searchPlaceholder',
                    shouldTranslate: true
                },
                formState: {
                    value: search,
                    disabled
                }
            },
            type: {
                render: FormUtil.Select,
                meta: {
                    label: 'type',
                    options: FormUtil.convertEnumToOptions(leadTypeEnum),
                    colWidth: 4,
                    colWidthLarge: 3,
                    placeholder: 'common:selectPlaceholderFilter',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: {
                    value: this.props.tableFilters.type,
                    disabled
                }
            },
            status: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'status',
                    options: FormUtil.convertEnumToOptions(leadStatusEnum),
                    colWidth: 3,
                    colWidthLarge: 2,
                    placeholder: 'common:selectPlaceholderFilter',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: {
                    value: status,
                    disabled
                }
            },
            ranking: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'ranking',
                    options: FormUtil.convertEnumToOptions(leadRankingEnum),
                    colWidth: 3,
                    colWidthLarge: 2,
                    placeholder: 'common:selectPlaceholderFilter',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: {
                    value: ranking,
                    disabled
                }
            },
            dateRange: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'dateRange',
                    options: FormUtil.convertEnumToOptions(dateRangeEnum),
                    colWidth: 3,
                    colWidthLarge: 2,
                    placeholder: 'common:selectPlaceholderFilter',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: {
                    value: dateRange,
                    disabled
                }
            }
        };
        return { controls: { ...mainSearchControls } };
    };

    handleAddContactLead = (contact: IfacilityContact) => {
        this.props.updateSelectedLead({
            ...this.props.selectedLead,
            contactID: contact.id,
            contact
        });
    };

    onSearchValueChanges = (value: any, key: string) => {
        this.setTableFilterDebounced({ [key]: value, page: 1 });
    };
    /*
     * (reusable)
     * get the next or previous page of data.  the table is 0 indexed but the API is not
     */
    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };

    // TODO Finish table rendering
    // Getting errors from React Table requiring certain things.)
    render() {
        const colorButton =
            constants.colors[
                `${this.state.currentTile.color}Button` as keyof typeof constants.colors
            ];
        const { t } = this.props;
        const tableClassName = `beacon-table -highlight ${this.state.currentTile.color}`;
        return (
            <div className="manage-leads">
                <Banner
                    title={t('manageLeads:bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                <SearchTableForm
                    fieldConfig={this.state.searchFieldConfig}
                    t={this.props.t}
                    loading={this.props.loading}
                    handleSubmit={this.props.getLeads}
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                    colorButton={colorButton}
                    xs={12}
                    lg={12}
                ></SearchTableForm>
                {this.props.selection.length !== 0 && (
                    <Row>
                        <Col md={12}>
                            <LeadBulkActionsButtonContainer
                                colorButton={colorButton}
                                t={t}
                            />
                        </Col>
                    </Row>
                )}
                <SelectTable
                    data={this.props.tableData}
                    columns={this.columns}
                    className={tableClassName}
                    expanded={this.state.selectedRow}
                    getTdProps={this.getTdProps}
                    sortable={false}
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={this.props.leadsManage.totalPages}
                    showPageSizeOptions={false}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    SubComponent={(rowInfo: RowInfo) => {
                        return (
                            <LeadInstallBasesExpander
                                {...rowInfo}
                                t={this.props.t}
                                handleDeleteLeadInstallBase={
                                    this.props.deleteLeadInstallBase
                                }
                            />
                        );
                    }}
                    toggleSelection={this.toggleSelection}
                    selectAll={this.state.selectAll}
                    selectType="checkbox"
                    toggleAll={this.toggleAll}
                    isSelected={this.isSelected}
                    ref={ref => (this.checkboxTable = ref)}
                    keyField="id"
                />
                <EditLeadModal
                    colorButton={colorButton}
                    queryParams={this.props.queryParams}
                    history={this.props.history}
                />
                <FacilityContactModal
                    colorButton={colorButton}
                    secondModal={true}
                    facilityID={this.props.selectedLead.facilityID}
                    addContactLead={this.handleAddContactLead}
                />
                <LeadActivityListModal
                    handleSelectLeadActivity={this.handleSelectLeadActivity}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    history={this.props.history}
                    queryParams={this.props.queryParams}
                />
                <EditLeadActivityModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    history={this.props.history}
                    queryParams={this.props.queryParams}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const tableData = selectLeadsForTable(state);
    // TODO low priority - hide the expander when there are 0 install bases

    const queryParams = queryString.parse(
        ownProps.location.search
    ) as typeof manageLeadQueryParamsEnum;
    return {
        user: state.user,
        leadsManage: state.manageLeads,
        loading: selectIsLoading(state),
        tableData,
        tableFilters: state.manageLeads.tableFilters,
        facilities: state.facilities,
        products: state.manageInventory.productsByID,
        contacts: state.manageFacility.contacts,
        selectedLead: state.manageLeads.selectedLead,
        selection: state.manageLeads.selection,
        queryParams,
        leadActivitiesByID: state.manageLeads.leadActivitiesByID
    };
};

export default withTranslation('manageLeads')(
    connect(mapStateToProps, {
        getLeads,
        setTableFilter,
        deleteLeadInstallBase,
        toggleLeadsModal,
        toggleLeadActivityModal,
        setSelectedLeadFromID,
        setSelectedLeadActivityFromID,
        updateSelectedLead,
        getLeadActivities,
        toggleEditLeadActivityModal,
        updateLeadSelection
    })(ManageLeadsWithSelect)
);
