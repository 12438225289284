import { TFunction } from 'i18next';
import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IWorkOrder, Ipart, ItableFiltersParams, ThunkResult } from '../models';
import { workOrderStatusEnum, workOrderVendorsEnum } from '../models-enums';

import fileDownload from 'js-file-download';
import { forEach } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { msalFetch } from '../components/auth/Auth-Utils';
import { FormUtil } from '../components/common/FormUtil';
import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import { beginAjaxCall } from './ajaxStatusActions';

/*
 * Convert work order to form data
 */
const workOrderToFormData = (workOrder: IWorkOrder) => {
    const workOrderConvertedToFormData = FormUtil.toFormData(workOrder);

    if (workOrder.parts && workOrder.parts.length) {
        workOrderConvertedToFormData.delete('parts');
        workOrder.parts.forEach((part: Ipart, index: number) => {
            if (part.isDeleted) {
                return;
            }
            const newKey = `parts[${index}]`;
            forEach(part, (item, key) => {
                workOrderConvertedToFormData.append(
                    `${newKey}.${key}`,
                    `${item}`
                );
            });
        });
    }
    return workOrderConvertedToFormData;
};

/*
 * Get Work Orders
 * when getting work orders for Beacon, do not send a facility,
 * the facility name can be included in the string search
 */
export function getWorkOrders(): ThunkResult<any> {
    return (dispatch, getState) => {
        const { user } = getState();
        const {
            ViewInventory,
            ManageInventory,
            ManageCustomerWorkOrders,
            ManageWorkOrderPMP,
            ManageWorkOrderAdmin
        } = constants.securityFunctions;

        if (
            !constants.hasSecurityFunction(user, [
                ViewInventory.id,
                ManageInventory.id,
                ManageCustomerWorkOrders.id,
                ManageWorkOrderPMP.id,
                ManageWorkOrderAdmin.id
            ])
        )
            return dispatch({
                type: types.LOAD_WORKORDERS_SUCCESS,
                payload: []
            });

        dispatch({
            type: types.CLEAR_WORKORDERS
        });
        dispatch(beginAjaxCall());
        const {
            page,
            search,
            statusFilter,
            dateRange,
            workOrderType,
            vendor,
            assignedUser,
            isDeleted,
            countryID,
            sapWorkOrder
        } = getState().manageWorkOrder.tableFilters;

        const statusValue = statusFilter
            ? Number.parseInt(statusFilter.value)
            : 1;
        const dateRangeValue = dateRange ? dateRange.value : null;
        const typeValue = workOrderType || null;

        const { selectedFacilityID } = getState();
        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: {
                facilityID:
                    vendor === workOrderVendorsEnum.beacon
                        ? null
                        : selectedFacilityID,
                type: typeValue,
                search,
                statusFilter: statusValue,
                dateRange: dateRangeValue,
                vendor,
                populateFacility: true,
                userID: assignedUser ? assignedUser.value : null,
                isDeleted: isDeleted ? isDeleted : false,
                countryID: countryID ? countryID.value : null,
                sapWorkOrders: sapWorkOrder
            }
        };

        const url = `${API.workOrder.search}?page=${page}`;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LOAD_WORKORDERS_SUCCESS,
                        payload: data.data.result
                    });
                    dispatch({
                        type: types.WORKORDER_MANAGE_TOTAL_PAGES,
                        pages: data.data.pages
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LOAD_WORKORDERS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get workorders');
            });
    };
}

/*
 * Get work orders for the asset status history
 */
export const getWorkOrdersByFacility = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const { selectedFacilityID } = getState();
        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: {
                pagingType: 'none',
                facilityID: selectedFacilityID,
                status: workOrderStatusEnum.complete,
                populateFacility: true
            }
        };

        const url = API.workOrder.search;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_WORK_ORDERS_BY_FACILITY_SUCCESS,
                        payload: data.data.result
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_WORK_ORDERS_BY_FACILITY_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get workorders');
            });
    };
};

/*
 * Get work orders for the asset status history
 */
export const getWorkOrderByID = (id: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        const url = API.workOrder.single + '/' + id;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.ADD_WORKORDER_SUCCESS,
                        payload: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.ADD_WORKORDER_SUCCESS,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get workorders');
            });
    };
};

export const addWorkOrder = (workOrder: IWorkOrder): ThunkResult<any> => (
    dispatch,
    getState
) => {
    dispatch(beginAjaxCall());
    const headers = { 'content-type': 'multipart/form-data' };

    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: workOrderToFormData(workOrder),
        headers
    };

    const url = API.POST.workOrder.add;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                const newWorkOrder = data.data;
                dispatch({
                    type: types.ADD_WORKORDER_SUCCESS,
                    payload: newWorkOrder
                });
                // toastr.success(
                //   'Success',
                //   `Created Work Order`,
                //   constants.toastrSuccess
                // );
            }
        })
        .catch((error: any) => {
            dispatch({ type: types.ADD_WORKORDER_FAILED, error, axiosOptions });
            constants.handleError(error, 'ADD_ORDER_FAILED');
        });
};

export const updateWorkOrder = (
    workOrder: IWorkOrder
): ThunkResult<any> => dispatch => {
    dispatch(beginAjaxCall());
    const headers = { 'content-type': 'multipart/form-data' };

    const axiosOptions: AxiosRequestConfig = {
        method: 'put',
        data: workOrderToFormData(workOrder),
        headers
    };

    const url = `${API.workOrder.single}/update/${workOrder.id}`;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                dispatch({
                    type: types.EDIT_WORKORDER_SUCCESS,
                    payload: workOrder
                });
                toastr.success(
                    'Success',
                    `Updated Work Order`,
                    constants.toastrSuccess
                );
                // dispatch({ type: types.TOGGLE_MODAL_EDIT_WORKORDER });
                document.dispatchEvent(new CustomEvent('updatedWorkOrders'));
            }
        })
        .catch((error: any) => {
            dispatch({
                type: types.EDIT_WORKORDER_FAILED,
                error,
                axiosOptions
            });
            constants.handleError(error, 'EDIT_WORKORDER_FAILED');
        });
};

export const deleteWorkOrder = (
    workOrder: IWorkOrder
): ThunkResult<any> => dispatch => {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
        method: 'delete'
    };

    const url = `${API.workOrder.single}/${workOrder.id}`;

    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            dispatch({
                type: types.REMOVE_WORKORDER_SUCCESS,
                payload: workOrder
            });
            toastr.success(
                'Success',
                `Deleted Work Order`,
                constants.toastrSuccess
            );
        })
        .catch((error: any) => {
            dispatch({
                type: types.REMOVE_WORKORDER_FAILED,
                error,
                axiosOptions
            });
            constants.handleError(error, 'REMOVE_WORKORDER_FAILED');
        });
};

/*
 * when vendor === 3, we are on the beacon tab and we should not send the facility
 */
export const getTechnicians = (vendor = 1): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const { selectedFacilityID } = getState();

        const statusOption = getState().manageWorkOrder.tableFilters.status;
        const status = statusOption
            ? statusOption.value
            : workOrderStatusEnum.open;
        const params =
            vendor === 3
                ? { vendor, status }
                : {
                      facilityID: selectedFacilityID,
                      vendor,
                      status
                  };
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params
        };

        const url = API.workOrder.getTechnicians;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LOAD_WORKORDER_TECHNICIANS_SUCCESS,
                        payload: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LOAD_WORKORDER_TECHNICIANS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get workorders');
            });
    };
};

export const getWorkOrderCsv = (technician: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const { selectedFacilityID } = getState();
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                facilityID: selectedFacilityID,
                technician,
                vendor: 1
            }
        };

        const url = API.workOrder.getWorkOrderCsv;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({ type: types.LOAD_WORKORDER_CSV_SUCCESS });
                    const file = data.data;
                    fileDownload(file, `${technician}s task list.csv`);
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LOAD_WORKORDER_CSV_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get workorders csv');
            });
    };
};

export const getPreventativeMaintenancePointCSV = (
    workOrder: IWorkOrder
): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };
        if (!workOrder.preventativeMaintenanceChecklist) {
            console.error('missing preventative maintenance checklist');
            return;
        }
        const url = `${API.preventativeMaintenancePoint.getCSV}/${workOrder.preventativeMaintenanceChecklist.id}`;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({ type: types._SUCCESS });
                    const file = data.data;
                    fileDownload(
                        file,
                        `${workOrder.activityDescription} tasks.csv`
                    );
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types._FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get task list csv');
            });
    };
};

export const setSelectedWorkOrderID = (id: string) => ({
    type: types.SET_SELECTED_WORKORDER_ID,
    id
});

export const clearSelectedWorkOrderID = () => ({
    type: types.CLEAR_SELECTED_WORKORDER_ID
});
export const setSelectedFacilityIDForConfirmSelectJob = (id: string) => ({
    type: types.SET_SELECTED_MANAGE_WORKORDER_FACILITY_ID,
    id
});

export const clearSelectedFacilityIDForConfirmSelectJob = () => ({
    type: types.CLEAR_SELECTED_MANAGE_WORKORDER_FACILITY_ID
});

export const toggleEditWorkOrderModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_WORKORDER
});

export const toggleWorkOrderCloseModal = () => ({
    type: types.TOGGLE_MODAL_CLOSING_NOTES
});

// export const setWorkOrderTechnician = (technician: string) => ({
//   type: types.SET_WORKORDER_TECHNICIAN,
//   technician
// });

export const onWorkOrderFilterChange = (
    value: any,
    key: string
): ThunkResult<any> => {
    return (dispatch, getState) => {
        let filters: ItableFiltersParams = {};
        switch (key) {
            case 'search':
                filters = { search: value, page: 1 };
                break;
            case 'status':
                filters = {
                    status: value ? value : null,
                    page: 1
                };
                break;
            case 'dateRange':
                filters = {
                    dateRange: value ? value : null,
                    page: 1
                };
                break;
            default:
                break;
        }
        dispatch({
            type: types.SET_TABLE_FILTER_MANAGE_WORKORDER,
            filters
        });
    };
};

export const setTableFilter = (filters: ItableFiltersParams) => {
    return {
        type: types.SET_TABLE_FILTER_MANAGE_WORKORDER,
        filters
    };
};

export const setWorkOrderFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_MANAGE_WORKORDER,
    formValues
});

export const updateWorkOrderFormValue = (formValues: {
    [key: string]: any;
}) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_WORKORDER,
    formValues
});

export const toggleConfirmSelectJobModal = () => ({
    type: types.TOGGLE_MODAL_CONFIRM_SELECT_JOB
});

export const updateWorkOrderSelection = (selection: string[]) => ({
    type: types.WORKORDER_UPDATE_SELECTION,
    payload: selection
});

export const getFSEsWithWorkOrder = (): ThunkResult<any> => dispatch => {
    dispatch(beginAjaxCall());

    const axiosOptions: AxiosRequestConfig = { method: 'get' };
    const url = API.workOrder.getFSEsWithWorkOrders;

    return msalFetch(url, axiosOptions)
        .then(data => {
            dispatch({
                type: types.GET_FSES_WITH_WORK_ORDERS_SUCCESS,
                data: data.data
            });
        })
        .catch(error => {
            dispatch({ type: types.GET_FSES_WITH_WORK_ORDERS_FAILED });
            constants.handleError(error, 'GET_FSEs_WITH_WORK_ORDERS_FAILED');
        });
};

export const unlinkWorkOrders = (
    selection: IWorkOrder[],
    t: TFunction
): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: selection
        };

        const url = API.workOrder.unlinkWorkOrders;

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.UNLINK_WORKORDERS_SUCCESS,
                    selection
                });
                document.dispatchEvent(
                    new CustomEvent('clearSelectedWorkOrders')
                );
                toastr.success(
                    t('toastMessage:success'),
                    t('manageWorkOrder:unlinkWorkOrderSuccess'),
                    constants.toastrSuccess
                );
            })
            .catch((error: any) => {
                dispatch({
                    type: types.UNLINK_WORKORDERS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'unlink work orders');
            });
    };
};
