// import { toastr } from 'react-redux-toastr';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ItableFiltersParams, Iuser, IuserQueue, ThunkResult } from '../models';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import * as types from './actionTypes';
import { msalFetch } from '../components/auth/Auth-Utils';
import { initialUserQueue } from '../reducers/initialState';
import { saveUser } from './manageUserActions';

interface IuserModified extends Omit<Iuser, 'customers' | 'facilities'> {
    customers: Array<{ id: string; name: string }>;
    facilities: Array<{ id: string; name: string }>;
}

export function getUserQueue(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const {
            page,
            search,
            countryID
        } = getState().manageUserQueue.tableFilters;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                page,
                search,
                countryID: countryID?.value || '',
                sort: 'createDate,desc'
            }
        };

        const url = API.GET.user.getuserqueue;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.USER_QUEUE_SUCCESS,
                        queue: data.data.result
                    });
                    dispatch({
                        type: types.USER_QUEUE_TOTAL_PAGES,
                        pages: data.data.pages
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.USER_QUEUE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get user queue');
            });
    };
}

function handleApproveUser(userQueueID: string, dispatch: any) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: { id: userQueueID }
    };

    const url = API.POST.user.approve;
    return msalFetch(url, axiosOptions).then(
        (data: AxiosResponse<any>) => {
            dispatch(endAjaxCall());
            if (!data.data) {
                throw new Error('missing data');
            } else {
                dispatch({ type: types.USER_APPROVE_SUCCESS, userQueueID });
                return data;
            }
        },
        (error: any) => {
            dispatch(endAjaxCall());
            dispatch({ type: types.USER_APPROVE_FAILED, error, axiosOptions });
            constants.handleError(error, 'approve user');
            throw error;
        }
    );
}

export function approveUser(userQueueID: string): any {
    return (dispatch: any, getState: any) => {
        // dispatch(beginAjaxCall());
        return handleApproveUser(userQueueID, dispatch);
    };
}

// export function anotherThunkAction(): ThunkResult<Promise<boolean>> {
//   return (dispatch, getState) => {
//     return Promise.resolve(true);
//   }
// }
export function rejectUser(userQueueID: string) {
    return (dispatch: any, getState: any) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: { id: userQueueID }
        };

        const url = API.POST.user.reject;
        return msalFetch(url, axiosOptions)
            .then((response: AxiosResponse<any>) => {
                dispatch({ type: types.USER_REJECT_SUCCESS, userQueueID });
            })
            .catch((error: any) => {
                dispatch({
                    type: types.USER_REJECT_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'reject user');
                throw error; // throw again so we can handle it in manageUserQueue
            });
    };
}

export const updateSelectedUserQueue = (userQueue = initialUserQueue) => ({
    type: types.USER_QUEUE_UPDATE_SELECTED,
    payload: userQueue
});

export function saveQueueUser(
    userQueue: IuserQueue,
    shouldApprove: boolean
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const saveUserWithoutType = saveUser as any;
        const saveUserWithPromiseType = saveUserWithoutType as (
            user: Iuser
        ) => Promise<void>;
        dispatch(saveUserWithPromiseType(userQueue.user))
            .then(() => {
                dispatch({
                    type: types.USER_QUEUE_SAVE_SUCCESS,
                    user: userQueue.user,
                    queueID: userQueue.id
                });

                if (shouldApprove) {
                    handleApproveUser(userQueue.id, dispatch); // don't return this because if we do, we will see two errors
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.USER_QUEUE_SAVE_FAILED,
                    error
                });
                constants.handleError(error, 'update queue user');
            });
    };
}

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_USER_QUEUE,
    filters
});
