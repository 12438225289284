/*
 * An Action Button
 */
import { TFunction } from 'i18next';
import { connect } from 'react-redux';

import { IinitialState } from '../../reducers';
import { LeadBulkActionsButton } from './LeadBulkActionsButton';
import { selectIsLoading } from '../../reducers/commonReducers';
import { bulkUpdate } from '../../actions/manageLeadsActions';

interface Iprops {
    t: TFunction;
    colorButton: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        user: state.user,
        selection: state.manageLeads.selection,
        leadsByID: state.manageLeads.leadsByID,
        loading: selectIsLoading(state)
    };
};

export const LeadBulkActionsButtonContainer = connect(mapStateToProps, {
    bulkUpdate
})(LeadBulkActionsButton);
