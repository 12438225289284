/*
 * Edit Training Checkout Form
 */

import {
    Col,
    Button,
    FormGroup,
    ControlLabel,
    FormControl,
    Badge
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    FormGenerator,
    AbstractControl,
    FieldConfig,
    Validators
} from 'react-reactive-form';
import { mapValues, forEach } from 'lodash';
import { withTranslation, WithTranslation } from 'react-i18next';

import * as React from 'react';

import { FormUtil } from '../common/FormUtil';
import {
    Ioption,
    IshoppingCartProduct,
    IproductInfo,
    IshoppingCart,
    ItableFiltersReducer,
    Iuser
} from '../../models';
import {
    toggleShoppingCartModal,
    updateQuantityCart,
    deleteFromCart
} from '../../actions/shoppingCartActions';
import { requestQuote } from '../../actions/manageInventoryActions';
import Select, { components } from 'react-select';
import NumberFormat from 'react-number-format';
import { savePendingTraining } from '../../actions/manageTrainingActions';
import { constants } from '../../constants/constants';

// add the bootstrap form-control class to the react-select select component
const ControlComponent = (props: any) => (
    <div>
        <components.Control {...props} className="form-control" />
    </div>
);

/*
 * Input row with a button to delete the cart item
 */
const NumberInputWithButton = ({
    handler,
    meta,
    pristine,
    errors,
    submitted
}: AbstractControl) => (
    <FormGroup
        bsSize="sm"
        validationState={FormUtil.getValidationState(
            pristine,
            errors,
            submitted
        )}
    >
        <Col xs={8}>
            <ControlLabel>{meta.label}</ControlLabel>
        </Col>
        <Col
            xs={4}
            style={{ textAlign: 'center', paddingRight: '0', paddingLeft: '0' }}
        >
            <FormControl
                style={{ width: '50%', display: 'inline' }}
                placeholder={meta.placeholder}
                type="number"
                name={meta.id}
                {...handler()}
            />
            <Button
                bsStyle="link"
                style={{ fontSize: '1.6em' }}
                onClick={() => meta.buttonAction(meta.id)}
            >
                <FontAwesomeIcon icon={['far', 'times']} />
            </Button>
        </Col>
    </FormGroup>
);

/*
 * Card product with a button to delete it
 */
const CartProduct = ({
    handler,
    touched,
    hasError,
    meta,
    pristine,
    errors,
    submitted
}: AbstractControl) => (
    <FormGroup
        bsSize="sm"
        validationState={FormUtil.getValidationState(
            pristine,
            errors,
            submitted
        )}
    >
        <Col xs={8}>
            <ControlLabel>{meta.label}</ControlLabel>
        </Col>
        <Col
            xs={4}
            style={{ textAlign: 'center', paddingRight: '0', paddingLeft: '0' }}
        >
            <Badge>
                <NumberFormat
                    value={meta.cost / 100}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                />
            </Badge>
            <Button
                bsStyle="link"
                style={{ fontSize: '1.6em' }}
                onClick={() => meta.buttonAction(meta.id)}
            >
                <FontAwesomeIcon icon={['far', 'times']} />
            </Button>
        </Col>
    </FormGroup>
);

const buildFieldConfig = (
    products: { [key: string]: IshoppingCartProduct },
    deleteFromCartCB: typeof deleteFromCart,
    cartName: string,
    showCost = false
) => {
    const productControls = mapValues(products, prod => {
        return {
            render: showCost ? CartProduct : NumberInputWithButton,
            options: {
                validators: [
                    Validators.min(1),
                    Validators.max(1000),
                    FormUtil.validators.requiredWithTrim
                ]
            },
            meta: {
                label: prod.name,
                defaultValue: prod.quantity,
                buttonAction: (id: string) => deleteFromCartCB(id, cartName),
                id: prod.id,
                cost: prod.cost,
                name: prod.id
            }
        };
    });
    const fieldConfigControls = {
        memo: {
            render: FormUtil.TextInput,
            meta: {
                label: 'memo',
                colWidth: 12,
                componentClass: 'textarea',
                rows: 6,
                name: 'memo',
                required: false
            }
        }
    };
    return {
        controls: { ...productControls, ...fieldConfigControls }
    };
};

interface Iprops {
    checkout: typeof requestQuote;
    toggleShoppingCartModal: typeof toggleShoppingCartModal;
    loading: boolean;
    colorButton: string;
    productInfo: IproductInfo;
    facilityOptions: Ioption[];
    cart: IshoppingCart;
    tableFilters: ItableFiltersReducer;
    updateQuantityCart: typeof updateQuantityCart;
    deleteFromCart: typeof deleteFromCart;
    cartName: string;
    showCost?: boolean;
    user: Iuser;
}
interface Istate {
    fieldConfig: FieldConfig;
}

class EditQuoteForm extends React.Component<Iprops & WithTranslation, Istate> {
    private userForm: FormGroup | any;
    private subscription: any;
    private formRef: React.RefObject<HTMLFormElement>;
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            fieldConfig: { controls: {} }
        };
    }
    // TODO keep the message somewhere, patch the value
    componentDidMount() {
        this.setFormConfig();
    }
    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    componentDidUpdate(prevProps: Iprops) {
        if (
            prevProps.cart.addedIDs.length !== this.props.cart.addedIDs.length
        ) {
            console.info('products changed');
            this.setFormConfig();
        }
    }
    setFormConfig = () => {
        this.setState(
            {
                fieldConfig: FormUtil.translateForm(
                    buildFieldConfig(
                        this.props.cart.productsByID,
                        this.props.deleteFromCart,
                        this.props.cartName,
                        this.props.showCost
                    ),
                    this.props.t
                )
            },
            () => {
                forEach(this.state.fieldConfig.controls, (input: any, key) => {
                    if (
                        this.userForm &&
                        input.meta &&
                        input.meta.defaultValue
                    ) {
                        this.userForm.patchValue({
                            [key]: input.meta.defaultValue
                        });
                    }
                    if (
                        this.userForm &&
                        this.props.cart.addedIDs.length &&
                        key !== 'message'
                    ) {
                        this.subscription = this.userForm
                            .get(key)
                            .valueChanges.subscribe((value: any) => {
                                this.props.updateQuantityCart(
                                    parseInt(value, 10),
                                    key,
                                    this.props.cartName
                                );
                            });
                    }
                });
            }
        );
    };
    setForm = (form: AbstractControl) => {
        console.info('setting form');
        this.userForm = form;
        this.userForm.meta = {
            loading: this.props.loading
        };
    };

    calculateSubtotal = () => {
        let subtotal = 0;
        forEach(this.props.cart.productsByID, product => {
            if (product.cost) {
                // TODO why is memo in the array of productsByID???
                subtotal += product.cost;
            }
        });
        return subtotal;
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix beacon-form checkout-form ${this.props.colorButton}`;
        if (this.props.cart.addedIDs.length === 0) {
            return (
                <div>
                    <h4 style={{ padding: '15px' }}>
                        {this.props.t('emptyMessage')}
                    </h4>
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={() =>
                                this.props.toggleShoppingCartModal(
                                    this.props.cartName
                                )
                            }
                        >
                            {t('common:cancel')}
                        </Button>
                    </Col>
                </div>
            );
        }
        return (
            <form
                ref={this.formRef}
                action={process.env.REACT_APP_UTA}
                method="post"
                target="MyMedGas"
                name="obpauto"
                className={formClassName}
                onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();

                    try {
                        const response = await savePendingTraining(
                            this.props.cart.addedIDs
                        );

                        if (response.status !== 200)
                            throw 'Error saving pending purchases';

                        if (this.formRef.current) this.formRef.current.submit();
                    } catch (err) {
                        constants.handleError(
                            err,
                            'Error saving pending purchases'
                        );
                    }
                    // Call savePendingTraining, if it fails, do not submit the form
                }}
            >
                <input
                    hidden={true}
                    name="User"
                    id="1"
                    value={process.env.REACT_APP_UTA_USER}
                />
                <input
                    hidden={true}
                    name="Email"
                    id="Email"
                    value={this.props.user.email}
                />
                <input
                    hidden={true}
                    name="CustomerNo"
                    id="CustomerNo"
                    value={`${this.props.user.first} ${this.props.user.last}`}
                />

                <input
                    hidden={true}
                    name="merchantno"
                    id="merchantno"
                    value={process.env.REACT_APP_UTA_MERCH}
                />
                <input
                    hidden={true}
                    name="OWNER"
                    id="OWNER"
                    value={process.env.REACT_APP_UTA_OWNER}
                />

                <input
                    hidden={true}
                    name="password"
                    id="password"
                    value={process.env.REACT_APP_UTA_PASS}
                />

                <input
                    hidden={true}
                    name="AMOUNT"
                    id="AMOUNT"
                    value={this.calculateSubtotal() / 100}
                />
                <input
                    hidden={true}
                    name="INVOICE1"
                    id="INVOICE1"
                    value="0001"
                />
                <input
                    hidden={true}
                    name="AMOUNT1"
                    id="AMOUNT1"
                    value={this.calculateSubtotal() / 100}
                />
                <input hidden={true} name="QTY" id="QTY" value="1" />

                <input
                    hidden={true}
                    name="redirect"
                    id="redirect"
                    value={`${process.env.REACT_APP_SERVER_DOMAIN}/training/acceptutapayment`}
                />

                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12}>
                    <FormGroup bsSize="sm">
                        <ControlLabel>Payment Method</ControlLabel>
                        <Select
                            options={[
                                { label: 'Credit Card', value: 2 },
                                { label: 'ACH', value: 1 }
                            ]}
                            classNamePrefix="react-select"
                            defaultValue={{ label: 'Credit Card', value: 2 }}
                            name="paymenttype"
                            className="payment-select"
                            components={{ Control: ControlComponent }}
                        />
                    </FormGroup>
                </Col>
                <Col xs={12} className="cart-totals">
                    Subtotal:{' '}
                    <NumberFormat
                        value={this.calculateSubtotal() / 100}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                    />
                </Col>

                <Col lg={12}>
                    <p className="text-center sales-final-label">
                        All Sales Final
                    </p>
                </Col>

                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={() =>
                            this.props.toggleShoppingCartModal(
                                this.props.cartName
                            )
                        }
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        bsStyle={this.props.colorButton}
                        type="submit"
                        disabled={this.props.loading}
                    >
                        {t('checkout')}
                    </Button>
                </Col>
            </form>
        );
    }
}
export default withTranslation('training')(EditQuoteForm);
