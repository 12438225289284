import React from 'react';
import ManageUserChild from './ManageUserChild';
import { useDispatch } from 'react-redux';

const ManageUser: React.FC<any> = props => {
    const dispatch = useDispatch();

    return <ManageUserChild dispatch={dispatch} {...props} />;
};

export default ManageUser;
