import { initialBrand } from './initialState';
import * as types from '../actions/actionTypes';
import { Ibrand, ImanageBrandReducer } from '../models';
import { createTableFiltersWithName } from './commonReducers';
import { pickBy, map, keyBy, filter } from 'lodash';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { IinitialState } from '.';
import { FormUtil } from '../components/common/FormUtil';

export const getBrands = (state: IinitialState) =>
    state.manageBrand.manageBrandsByID;
export const getSelectedBrand = (state: IinitialState) =>
    state.manageBrand.selectedBrand;

export const selectBrands = createSelector([getBrands], brands =>
    keyBy(filter(brands, { isDeleted: false }), 'id')
);
export const selectBrand = createSelector(
    [getSelectedBrand],
    selectedBrand => selectedBrand
);

const cleanBrandObject = (brand: Ibrand) => {
    return {
        ...initialBrand,
        ...pickBy(brand, property => property !== null)
    } as Ibrand;
};

/*
 * stores the brand currently being edited
 */
const selectedBrandReducer = (state = initialBrand, action: any): Ibrand => {
    switch (action.type) {
        case types.UPDATE_SELECTED_BRAND: {
            return action.payload;
        }
        case types.REMOVE_BRAND_SUCCESS: {
            if (state.id === action.payload) {
                return initialBrand;
            } else {
                return state;
            }
        }
        case types.ADD_BRAND_SUCCESS: {
            return initialBrand;
        }
        default:
            return state;
    }
};
export function manageBrandsByID(
    state: { [key: string]: Ibrand } = {},
    action: any
) {
    switch (action.type) {
        case types.LOAD_BRANDS_SUCCESS: {
            const newBrands = map(action.payload.result, (brand: Ibrand) => {
                return cleanBrandObject(brand);
            });
            return keyBy(newBrands, 'id');
        }
        case types.ADD_BRAND_SUCCESS:
            return { ...state, [action.payload.id]: action.payload };
        case types.EDIT_BRAND_SUCCESS:
            return { ...state, [action.payload.id]: action.payload };
        case types.REMOVE_BRAND_SUCCESS: {
            const data = { ...state };
            delete data[action.payload.id];
            return data;
        }
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.BRAND_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

const brandManage = combineReducers<ImanageBrandReducer>({
    manageBrandsByID,
    selectedBrand: selectedBrandReducer,
    totalPages,
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_BRAND')
});

export default brandManage;
