/*
 * Edit Job Comment Form
 */

import * as React from 'react';

import {
    AbstractControl,
    FieldConfig,
    FormGenerator,
    FormGroup,
    GroupProps
} from 'react-reactive-form';

import { IjobComment, IdeficiencyCode, Iuser } from '../../models';
import {
    setSelectedJobComment,
    toggleEditJobCommentModal,
    resolveJobComment
} from '../../actions/manageJobCommentsActions';

import { Button, Col } from 'react-bootstrap';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps, withRouter } from 'react-router';
import { constants } from '../../constants/constants';
import { initialJobComment } from '../../reducers/initialState';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';

interface Iprops {
    selectedJobComment: IjobComment;
    loading: boolean;
    colorButton: string;
    t: TFunction;

    toggleEditJobCommentModal: typeof toggleEditJobCommentModal;
    user: Iuser;
    resolveJobComment: typeof resolveJobComment;
    setSelectedJobComment: typeof setSelectedJobComment;
    jobCommentsByID: { [key: string]: IjobComment };
    deficiencyCodesByID: { [key: string]: IdeficiencyCode };
}
interface Istate {
    fieldConfig: FieldConfig;
}

class EditJobCommentFormClass extends React.Component<
    Iprops & RouteComponentProps<{}>,
    Istate
> {
    private formGroup: FormGroup | any;
    private subscription: any;

    constructor(props: Iprops & RouteComponentProps<{}>) {
        super(props);
        this.state = {
            fieldConfig: FormUtil.translateForm(
                this.buildFieldConfig(this.props.selectedJobComment),
                this.props.t
            )
        };
    }

    componentDidMount() {
        if (!this.props.selectedJobComment) {
            console.error('missing job');
            return;
        }
    }
    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    subscribeToValueChanges = () => {
        this.subscription = this.formGroup
            .get('isDeficiency')
            .valueChanges.subscribe((value: boolean | null) => {
                this.setState({
                    fieldConfig: FormUtil.translateForm(
                        this.buildFieldConfig({
                            ...this.props.selectedJobComment,
                            ...this.formGroup.value,
                            isDeficiency: value,
                            codeID: this.formGroup.value.codeID
                                ? this.formGroup.value.codeID.value
                                : null
                        }),
                        this.props.t
                    )
                });
            });
    };

    buildFieldConfig = ({
        text,
        isDeficiency,
        codeID,
        isResolved
    }: IjobComment = initialJobComment) => {
        const { deficiencyCodesByID } = this.props;
        const disabled = false;

        const selectedCode = codeID
            ? FormUtil.convertToSingleOption(deficiencyCodesByID[codeID])
            : null;
        const selectedIsResolved = isResolved
            ? { value: 'true', label: 'Yes' }
            : { value: 'false', label: 'No' };

        let controls = {
            text: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'comment',
                    colWidth: 12,
                    componentClass: 'textarea',
                    type: 'input',
                    name: 'comment',
                    required: true,
                    rows: 10
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: text, disabled }
            },
            isDeficiency: {
                render: FormUtil.Toggle,
                meta: { label: 'labelIsDeficiency', colWidth: 12 },
                formState: { value: isDeficiency, disabled }
            }
        } as { [key: string]: GroupProps };

        if (isDeficiency) {
            controls = {
                ...controls,
                codeID: {
                    render: FormUtil.Select,
                    meta: {
                        label: 'code',
                        colWidth: 12,
                        isMulti: false,
                        name: 'code',
                        required: false,
                        options: FormUtil.convertToOptions(
                            this.props.deficiencyCodesByID
                        )
                    },
                    formState: { value: selectedCode, disabled }
                },
                $field_0: {
                    isStatic: false, // ensures a key is added
                    render: () => (
                        <Col xs={12}>
                            <hr />
                        </Col>
                    )
                },
                isResolved: {
                    render: FormUtil.Select,
                    meta: {
                        label: 'resolved',
                        colWidth: 12,
                        isMulti: false,
                        name: 'resolved',
                        required: true,
                        options: constants.isResolvedOptions
                    },
                    formState: { value: selectedIsResolved, disabled }
                }
            } as { [key: string]: GroupProps };
        }

        return {
            controls
        } as FieldConfig;
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        console.info(this.formGroup.value);

        this.props.resolveJobComment(this.formGroup.value.isResolved);
        this.props.toggleEditJobCommentModal();
        this.props.setSelectedJobComment(initialJobComment);
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        this.subscribeToValueChanges();
    };
    render() {
        const { t } = this.props;
        const { selectedJobComment } = this.props;
        if (!selectedJobComment) {
            return null;
        }
        return (
            <div className="job-details-content beacon-form">
                <form
                    onSubmit={this.handleSubmit}
                    className="clearfix beacon-form"
                >
                    <FormGenerator
                        onMount={this.setForm}
                        fieldConfig={this.state.fieldConfig}
                    />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.props.toggleEditJobCommentModal}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            bsStyle={this.props.colorButton}
                            type="submit"
                            disabled={this.props.loading}
                        >
                            {t('save')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}
export const EditJobCommentForm = withRouter(EditJobCommentFormClass);
