import { WithTranslation, withTranslation } from 'react-i18next';
import {
    addGlobalMeasurementPointList,
    saveMeasurementPointToMeasurementPointList,
    setSelectedMeasurementPointList,
    setSelectedTabID,
    toggleEditMeasurementPointListModal,
    toggleEditMeasurementPointListTestProceduresModal,
    toggleEditMeasurementPointModal,
    toggleEditMeasurementPointTabModal,
    updateMeasurementPoint,
    updateMeasurementPointList,
    updateMeasurementPointListTab
} from '../../actions/manageMeasurementPointListsActions';

import EditMeasurementPointListForm from './EditMeasurementPointListForm';
import { WithCommonModal } from '../common/WithCommonModal';
import { connect } from 'react-redux';
import { initialMeasurementPointTab } from '../../reducers/initialState';
import { selectMeasurementPointListTabs } from '../../reducers/manageMeasurementPointListsReducer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
    canEditGlobal: boolean;
    isCustomerView: boolean;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const listTabs = selectMeasurementPointListTabs(state);
    let selectedTab = listTabs.find(
        tab => tab.id === state.manageMeasurementPointLists.selectedTabID
    );
    selectedTab = selectedTab || listTabs[0] || initialMeasurementPointTab;

    let title = ownProps.t('EditMeasurementPointListModalTitle');
    if (
        state.manageMeasurementPointLists.selectedMeasurementPointList.forJob &&
        state.manageMeasurementPointLists.selectedMeasurementPointList
            .forJob === true
    ) {
        title = ownProps.t('EditJobnMeasurementPointListModalTitle');
    }

    return {
        title: title,
        className: 'measurements-edit',
        user: state.user,
        userManage: state.manageUser,
        loading: selectIsLoading(state),
        show:
            state.manageMeasurementPointLists.showEditMeasurementPointListModal,
        showEditFacilityModal: state.showEditFacilityModal,
        productInfo: state.productInfo,
        selectedTab,
        listTabs,
        forJob:
            state.manageMeasurementPointLists.selectedMeasurementPointList
                .forJob === true,
        selectedMeasurementPointList:
            state.manageMeasurementPointLists.selectedMeasurementPointList
    };
};

export default withTranslation('manageMeasurementPointLists')(
    connect(mapStateToProps, {
        onHide: toggleEditMeasurementPointListModal,
        toggleModal: toggleEditMeasurementPointListModal,
        toggleEditMeasurementPointModal,
        addGlobalMeasurementPointList,
        updateMeasurementPointList,
        setSelectedTabID,
        updateMeasurementPoint,
        saveMeasurementPointToMeasurementPointList,
        toggleEditMeasurementPointTabModal,
        setSelectedMeasurementPointList,
        toggleEditMeasurementPointListTestProceduresModal,
        updateMeasurementPointListTab
    })(WithCommonModal(EditMeasurementPointListForm))
);
