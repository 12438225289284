/*
 *
 */

import { withTranslation, WithTranslation } from 'react-i18next';
import CommonModal from '../../common/CommonModal';
import { Button, Col, Row } from 'react-bootstrap';
import React, { useEffect, useMemo } from 'react';
import { beginAjaxCall, endAjaxCall } from '../../../actions/ajaxStatusActions';
import { msalFetch } from '../../auth/Auth-Utils';
import API from '../../../constants/apiEndpoints';
import { IcloudDocument } from '../../../models';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import { TableUtil } from '../../common/TableUtil';

interface Iprops {
    showModal: boolean;
    hideModal: () => void;
    colorButton: string;
    document: IcloudDocument;
    specificVersion: boolean;
}

interface documentPreview {
    productName: string;
    closingNotes: string;
}

const PreviewDocumentModal: React.FC<Iprops & WithTranslation> = props => {
    const { t } = props;
    const [tableData, setTableData] = React.useState([] as documentPreview[]);

    const getDocumentPreview = async () => {
        beginAjaxCall();

        try {
            let url;
            if (props.specificVersion) {
                url = `${API.cloudDocument.preview}/${props.document.documentID}/version/${props.document.id}`;
            } else {
                url = `${API.cloudDocument.preview}/${props.document.documentID}`;
            }
            const response = await msalFetch(url, {
                method: 'get',
                params: {}
            });

            if (!response.data) {
                throw new Error('missing data');
            } else {
                const data = response.data as documentPreview[];
                setTableData(data);
            }
        } catch (error) {
            console.error('Failed to load preview', error);
            toastr.error(t('toastMessage:error'), 'Failed to load preview');
        } finally {
            endAjaxCall();
        }
    };

    useEffect(() => {
        if (props.showModal) {
            setTableData([]);
            getDocumentPreview();
        }
    }, [props.showModal]);

    const columns = useMemo(() => {
        const closingNotesColumn = {
            Header: 'Closing Notes',
            accessor: ({ closingNotes }: documentPreview) => {
                if (closingNotes === '' || closingNotes === undefined) {
                    return '';
                }
                const notesJsonObject = JSON.parse(closingNotes);

                if (notesJsonObject !== null && notesJsonObject.length > 0) {
                    return notesJsonObject[0].Text;
                }

                return '';
            },
            id: 'createdDate',
            minWidth: 200
        };

        const productNameColumn = {
            Header: 'Product',
            accessor: 'productName',
            minWidth: 200
        };

        return TableUtil.translateHeaders(
            [
                ...(tableData.length > 0 ? [productNameColumn] : []),
                ...(tableData.length > 0 ? [closingNotesColumn] : [])
            ],
            t
        );
    }, [tableData, t]);

    return (
        <>
            <CommonModal
                show={props.showModal}
                className="preview-modal"
                onHide={() => props.hideModal}
                title={t('manageDocument:previewTitle')}
                container={document.getElementById('two-pane-layout')}
                secondModal={false}
            >
                <Row>
                    <Col xs={12}>
                        <ReactTable
                            data={tableData}
                            columns={columns}
                            pageSize={tableData.length}
                            pages={1}
                            showPagination={false}
                            showPageSizeOptions={false}
                            className={`beacon-table -highlight blue auto-height`}
                            previousText={t('common:previous')}
                            nextText={t('common:next')}
                            sortable={false}
                            multiSort={false}
                            noDataText={t('common:noDataText')}
                            resizable={false}
                            key={'id'}
                        />
                    </Col>
                </Row>

                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-right"
                        onClick={() => props.hideModal()}
                    >
                        {t('common:doneButton')}
                    </Button>
                </Col>
            </CommonModal>
        </>
    );
};

export default withTranslation('manageDocument')(PreviewDocumentModal);
