/*
 * Facility Contract Modal and Redux Connector
 */

import { connect } from 'react-redux';

import { IfacilityContract } from '../../models';

import {
    initialFacilityContract,
    initialFacility
} from '../../reducers/initialState';
import FacilityContractForm from './FacilityContractForm';
import {
    toggleFacilityContractModal,
    updateFacilityContractFormValue,
    setFacilityContractFormValues,
    updateFacilityContract
} from '../../actions/manageFacilityActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from './WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

type Iprops = {
    colorButton: string;
    secondWideModal?: boolean;
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const { facilities } = state;
    const selectedFacility =
        facilities[state.manageFacility.selectedFacilityID] || initialFacility;
    const selectedItem =
        (state.manageFacility.facilityFormValues
            .contract as IfacilityContract) || initialFacilityContract;

    return {
        // reusable
        loading: selectIsLoading(state),
        show: state.manageFacility.showFacilityContractModal,
        formValues: state.manageFacility.facilityContractFormValues,
        selectedItem,
        // other
        brands: state.productInfo.brands,
        facilityID: selectedFacility.id,
        className: 'facility-contract-edit',
        title: ownProps.t('facilityContractForm.title')
    };
};

export default withTranslation('manageFacility')(
    connect(mapStateToProps, {
        // reusable
        handleSubmit: updateFacilityContract,
        toggleModal: toggleFacilityContractModal,
        updateFormValue: updateFacilityContractFormValue,
        setFormValues: setFacilityContractFormValues,
        onHide: toggleFacilityContractModal
        // other
    })(WithCommonModal(FacilityContractForm))
);
