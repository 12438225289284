import * as types from '../actions/actionTypes';

import { Iquote, ImanageQuotesReducer } from '../models';
import {
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import initialState, { initialQuote } from './initialState';

import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { keyBy, pickBy, map, filter } from 'lodash';
import { IinitialState } from '.';

export const cleanQuoteResultObject = (quoteResult: any): Iquote => {
    return {
        ...initialQuote,
        ...pickBy(quoteResult.quote, (property, key) => property !== null),
        facilityID: quoteResult.facility ? quoteResult.facility.id : undefined,
        salesUsers: quoteResult.users
    };
};

export const cleanQuoteObject = (quote: any): Iquote => {
    return {
        ...initialQuote,
        ...pickBy(quote, (property, key) => property !== null)
    };
};

/*
 * SELECTORS
 */

const getQuotes = (state: IinitialState) =>
    state.manageCustomerQuotes.quotesByID;
const getFacilities = (state: IinitialState) => state.facilities;

export const selectQuotes = createSelector(
    [getQuotes, getFacilities],
    (quotesByID, facilitiesByID) => {
        const filteredQuotes = filter(quotesByID, { isDeleted: false });
        return map(filteredQuotes, quote => {
            if (quote.facilityID) {
                return { ...quote, facility: facilitiesByID[quote.facilityID] };
            } else {
                return quote;
            }
        });
    }
);

/*
 * REDUCERS
 */
function quotesByIDReducer(
    state: { [key: string]: Iquote } = initialState.manageCustomerQuotes
        .quotesByID,
    action: any
): { [key: string]: Iquote } {
    switch (action.type) {
        case types.QUOTE_MANAGE_SUCCESS: {
            const quotes = map(action.payload, (quoteResult: any) =>
                cleanQuoteResultObject(quoteResult)
            );
            return keyBy(quotes, 'id');
        }
        case types.QUOTE_UPDATE_STATUS_SUCCESS: {
            return { ...state, [action.payload.id]: action.payload };
        }
        default:
            return state;
    }
}

function totalPages(
    state: number = initialState.manageCustomerQuotes.totalPages,
    action: any
): number {
    switch (action.type) {
        case types.QUOTE_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageCustomerQuotes.totalPages;
        default:
            return state;
    }
}

/*
 * stores the lead activity currently being edited
 */
const selectedQuoteReducer = (state = initialQuote, action: any): Iquote => {
    switch (action.type) {
        case types.UPDATE_SELECTED_QUOTE: {
            return action.payload;
        }
        case types.QUOTE_DELETE_SUCCESS: {
            if (state.id === action.payload) {
                return initialQuote;
            } else {
                return state;
            }
        }
        case types.QUOTE_ADD_SUCCESS: {
            return initialQuote;
        }
        default:
            return state;
    }
};

const manageQuotes = combineReducers<ImanageQuotesReducer>({
    quotesByID: quotesByIDReducer,
    selectedQuote: selectedQuoteReducer,
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_QUOTES'),
    showEditQuoteModal: (state, action) =>
        modalToggleWithName(state, action, 'QUOTES'),
    showSalesQuoteModal: (state, action) =>
        modalToggleWithName(state, action, 'SALES_QUOTES'),
    showQuoteDetailsModal: (state, action) =>
        modalToggleWithName(state, action, 'QUOTES_DETAILS'),
    totalPages
});

export default manageQuotes;
