import * as React from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import {
    IcloudDocument,
    Ioption,
    IproductInfo,
    ItableFiltersReducer,
    Itile
} from '../../models';
import ReactTable, { CellInfo, Column, FinalState, RowInfo } from 'react-table';
import {
    clearSelectedDocumentID,
    clearSelectedParentID,
    deleteVersion,
    getCloudDocument,
    getDocuments,
    setSelectedDocumentID,
    setSelectedParentID,
    setTableFilter,
    toggleEditDocumentModal
} from '../../actions/manageDocumentActions';
import { getDocumentTypes } from '../../actions/manageDocumentTypeActions';
import { emptyTile, initialDocument } from '../../reducers/initialState';
import { map } from 'lodash';

import { Banner } from '../common/Banner';
import { Button, Col } from 'react-bootstrap';
import EditDocumentLibraryModal from './dialogs/EditDocumentLibraryModal';
import { FieldConfig } from 'react-reactive-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormUtil } from '../common/FormUtil';
import { Iuser } from '../../models';
import ManageDocumentVersionExpander from './ManageDocumentVersionExpander';
import { RouteComponentProps } from 'react-router';
import SearchTableForm from '../common/SearchTableForm';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { TableUtil } from '../common/TableUtil';
import { connect, useSelector } from 'react-redux';
import {
    constants,
    DOCUMENT_TYPE_VISIT_REPORT,
    DocumentTypesEnum
} from '../../constants/constants';
import {
    getFacilitiesByCountry,
    closeAllModals
} from '../../actions/commonActions';
import { IinitialState } from '../../reducers';
import { TFunction } from 'i18next';
import { getIsMobile } from '../../reducers/configReducer';
import ManageDocumentVersions from './mobile/ManageDocumentVersions';
import { selectSelectedFacilityID } from '../../reducers/facilitiesReducer';
import {
    DocumentGroups,
    dateRangeEnum,
    jobTypesIdEnum
} from '../../models-enums';
import PreviewDocumentModal from './dialogs/PreviewDocumentModal';
import PreviewDocument from '../../images/icons/Preview.svg';
import { getLastModifiedDate } from './utils/ManageDocumentUtils';
import { getProductInfo } from '../../actions/manageInventoryActions';
import { documentTypeSelector } from '../../reducers/documentTypeReducer';
export interface RowInfoDocument extends RowInfo {
    original: IcloudDocument;
}

type Iprops = RouteComponentProps<any>;

interface IdispatchProps {
    tableData: IcloudDocument[];
    totalPages: number;
    showEditDocumentModal: boolean;
    getDocumentTypes: typeof getDocumentTypes;
    getDocuments: typeof getDocuments;
    toggleEditDocumentModal: typeof toggleEditDocumentModal;
    loading: boolean;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    setSelectedParentID: typeof setSelectedParentID;
    clearSelectedParentID: typeof clearSelectedParentID;
    setSelectedDocumentID: typeof setSelectedDocumentID;
    clearSelectedDocumentID: typeof clearSelectedDocumentID;
    getCloudDocument: typeof getCloudDocument;
    deleteVersion: typeof deleteVersion;
    selectedDocument: IcloudDocument;
    getFacilitiesByCountry: typeof getFacilitiesByCountry;
    getProductInfo: typeof getProductInfo;
    user: Iuser;
    selectedFacilityID: string;
    canEditDocuments: boolean;
    canAddDocuments: boolean;
    canDeleteSystemDocuments: boolean;
    mainCategoryOptions: Ioption[];
    productInfo: IproductInfo;
    closeAllModals: typeof closeAllModals;
    t: TFunction;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
    modalVersionVisible: boolean;
    selectedCloudDocument: IcloudDocument;
    filterLength: number;
    showPreviewDocumentModal: boolean;
    documentToPreview: IcloudDocument;
    previewSpecificVersion: boolean;
}

const isInstallBaseSearch = (): boolean => {
    const regex = /^\?installbaseId=.*$/i;
    const location = window.location.search;
    return location !== '' && location.match(regex) !== null;
};

const ManageDocumentLibrary: React.FC<IdispatchProps & Iprops> = (
    props: IdispatchProps,
    routeProps: Iprops
) => {
    const documentTypeOptions = useSelector(documentTypeSelector);
    const isMobile = useSelector(getIsMobile);
    const selectedFacilityID = useSelector(selectSelectedFacilityID);
    const prevSelectedFacilityID = React.useRef<string>(selectedFacilityID);
    const location = useLocation();
    const resetFilters = () => {
        props.setTableFilter({
            assetSearch: '',
            name: undefined,
            mainCategory: undefined,
            type: undefined,
            dateRange: undefined,
            isSystem: undefined,
            jobType: undefined
        });
    };

    React.useEffect(() => {
        prevSelectedFacilityID.current = selectedFacilityID;
    }, [selectedFacilityID]);

    const fieldConfig = React.useMemo(() => {
        if (selectedFacilityID !== prevSelectedFacilityID.current) {
            resetFilters();

            props.getDocuments(props.t, {});
        }

        const isInstallBase = isInstallBaseSearch();
        const mainSearchControls = {
            assetSearch: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'manageDocument:assetAndJob',
                    colWidth: isMobile ? 12 : 3,
                    colWidthLarge: 2,
                    type: 'text',
                    placeholder: isMobile
                        ? 'common:searchNamePlaceholder'
                        : 'common:searchPlaceholder',
                    defaultValue: isInstallBase
                        ? ''
                        : props.tableFilters.assetSearch,
                    isClearable: true
                },
                formState: { disabled: isInstallBase, value: '' }
            },
            mainCategory: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:mainCategory',
                    options: props.mainCategoryOptions,
                    colWidth: isMobile ? 12 : 3,
                    colWidthLarge: 2,
                    type: 'select',
                    placeholder: 'common:selectPlaceholder',
                    defaultValue: '',
                    isClearable: true
                }
            },
            type: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'manageDocument:type',
                    options: FormUtil.convertToOptions(documentTypeOptions),
                    colWidth: isMobile ? 12 : 3,
                    colWidthLarge: 2,
                    type: 'select',
                    placeholder: isMobile
                        ? 'common:selectTypePlaceholder'
                        : 'DocumentType',
                    defaultValue: isInstallBase ? '' : props.tableFilters.type,
                    isClearable: true
                }
            },
            jobType: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'manageDocument:jobType',
                    options: FormUtil.convertEnumToOptions(jobTypesIdEnum),
                    colWidth: isMobile ? 12 : 2,
                    colWidthMedium: isMobile ? 12 : 2,
                    colWidthLarge: 2,
                    type: 'select',
                    placeholder: isMobile
                        ? 'common:selectTypePlaceholder'
                        : 'DocumentType',
                    defaultValue: isInstallBase
                        ? ''
                        : props.tableFilters.jobType,
                    isClearable: true,
                    shouldTranslate: true
                }
            },
            dateRange: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'manageDocument:date',
                    options: FormUtil.convertEnumToOptions(dateRangeEnum),
                    colWidth: isMobile ? 12 : 2,
                    colWidthLarge: 2,
                    dataType: 'date-filter',
                    placeholder: 'manageDocument:quarterYearAllTime',
                    isClearable: true,
                    shouldTranslate: true,
                    type: 'select',
                    defaultValue: isInstallBase
                        ? ''
                        : props.tableFilters.dateRange
                }
            },
            isSystem: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'manageDocument:system',
                    options: [
                        {
                            value: 'true',
                            label: 'manageDocument:yes'
                        },
                        {
                            value: 'false',
                            label: 'manageDocument:no'
                        }
                    ],
                    colWidth: isMobile ? 12 : 2,
                    colWidthLarge: 2,
                    colWidthMedium: isMobile ? 12 : 2,
                    type: 'select',
                    placeholder: 'common:selectPlaceholder',
                    defaultValue: props.tableFilters.isSystem,
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: {
                    disabled: isInstallBase,
                    value: isInstallBase
                        ? {
                              value: 'true',
                              label: props.t('manageDocument:yes')
                          }
                        : ''
                }
            }
        };

        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    }, [
        documentTypeOptions,
        prevSelectedFacilityID,
        selectedFacilityID,
        location.search
    ]);

    const [state, setState] = React.useState<Istate>({
        selectedRow: {},
        currentTile: emptyTile,
        columns: [],
        modalVersionVisible: false,
        selectedCloudDocument: initialDocument,
        filterLength: 0,
        showPreviewDocumentModal: false,
        documentToPreview: initialDocument,
        previewSpecificVersion: false
    });
    let debounce: any;

    const onPageChange = (page: number) => {
        const newPage = page + 1;
        props.setTableFilter({ page: newPage });
    };

    /*
     * Set Columns sets columns to state
     */
    const setColumns = () => {
        if (isMobile) {
            const mobileColumns = TableUtil.translateHeaders(
                [
                    {
                        Header: 'name',
                        accessor: 'name',
                        // eslint-disable-next-line no-shadow
                        Cell: props => (
                            <div className="mngDoc-header-column">
                                <p className="mngDoc-table-mobile-header">
                                    Name
                                </p>{' '}
                                <p className="mngDoc-table-value first-row">
                                    {props.value}
                                </p>
                            </div>
                        )
                    },
                    {
                        Header: 'type',
                        accessor: ({ cloudDocumentTypeID }: IcloudDocument) => {
                            if (
                                documentTypeOptions &&
                                documentTypeOptions[cloudDocumentTypeID]
                            ) {
                                return documentTypeOptions[cloudDocumentTypeID]
                                    .name;
                            } else {
                                return '';
                            }
                        },
                        id: 'type',
                        // eslint-disable-next-line no-shadow
                        Cell: props => (
                            <div className="mngDoc-header-column">
                                <p className="mngDoc-table-mobile-header">
                                    Type
                                </p>{' '}
                                <p className="mngDoc-table-value first-row">
                                    {props.value}
                                </p>
                            </div>
                        )
                    }
                ],
                props.t
            );
            setState({ ...state, columns: mobileColumns });
        } else {
            // Desktop
            const columns = TableUtil.translateHeaders(
                [
                    {
                        id: 'expander-toggle',
                        expander: true,
                        Expander: TableUtil.expanderToggleFolder,
                        style: {
                            cursor: 'pointer',
                            textAlign: 'left',
                            userSelect: 'none'
                        }
                    },
                    {
                        Header: '',
                        accessor: 'name',
                        minWidth: 200,
                        Aggregated: <span></span>,
                        Expander: TableUtil.expanderToggleFolder
                    },
                    {
                        pivot: true,
                        id: 'groupType',
                        Expander: TableUtil.expanderToggleFolder,
                        Aggregated: <span></span>,
                        accessor: ({ groupType }: IcloudDocument) =>
                            props.t(
                                `manageDocument:${
                                    DocumentGroups[groupType || 0]
                                }`
                            ),
                        Header: 'Folder',
                        PivotValue: (cellInfo: CellInfo, column: any) => (
                            <span>{cellInfo.row._pivotVal}</span>
                        )
                    },
                    {
                        pivot: true,
                        Header: '',
                        Expander: TableUtil.expanderToggleFolder,
                        Aggregated: <span></span>,
                        accessor: ({
                            cloudDocumentTypeID,
                            otherGroup
                        }: IcloudDocument) => {
                            if (otherGroup) {
                                return props.t('manageDocument:other');
                            }
                            if (
                                documentTypeOptions &&
                                documentTypeOptions[cloudDocumentTypeID]
                            ) {
                                return documentTypeOptions[cloudDocumentTypeID]
                                    .name;
                            } else {
                                return '';
                            }
                        },
                        id: 'type',
                        PivotValue: (cellInfo: CellInfo, column: any) => (
                            <span>{cellInfo.row._pivotVal}</span>
                        )
                    },
                    {
                        id: 'updateDate',
                        Header: 'manageDocument:modifiedDate',
                        aggregate: (values, row) => {
                            if (values.length === 1) {
                                return values[0];
                            }
                            const dates = values.map((s: string) =>
                                moment(s, 'DD-MMM-YY')
                            );
                            return moment
                                .max(dates)
                                .local(true)
                                .format('DD-MMM-YY');
                        },
                        accessor: (doc: IcloudDocument) =>
                            moment(getLastModifiedDate(doc))
                                .local(true)
                                .format('DD-MMM-YY')
                    },
                    {
                        Header: 'files',
                        aggregate: (values, rows) => {
                            if (rows[0]._subRows) {
                                return rows.reduce(
                                    (acc: number, row: any) =>
                                        acc + row._subRows.length,
                                    0
                                );
                            } else {
                                return rows.length;
                            }
                        }
                    },
                    {
                        Header: '',
                        id: 'preview-button',
                        Cell: ({ original }: { original: IcloudDocument }) => {
                            if (
                                original.isSystem === true &&
                                original.cloudDocumentTypeID.toLowerCase() ===
                                    DOCUMENT_TYPE_VISIT_REPORT.toLowerCase()
                            ) {
                                return (
                                    <span title="Preview Latest">
                                        <img
                                            src={PreviewDocument}
                                            alt="Preview Latest"
                                        />
                                    </span>
                                );
                            }
                            return null;
                        },
                        minWidth: 30
                    },
                    {
                        Header: '',
                        id: 'download-latest',
                        Cell: (
                            <span>
                                <FontAwesomeIcon
                                    className="download-button"
                                    icon={['far', 'download']}
                                />
                            </span>
                        ),
                        minWidth: 30
                    }
                ],
                props.t
            );
            setState({ ...state, columns });
        }
    };

    /*
     * Collapse all table
     */
    const collapseTable = () => {
        setState({ ...state, selectedRow: {} });
    };

    const showLatestDocumentPreview = (doc: IcloudDocument) => {
        setState({
            ...state,
            showPreviewDocumentModal: true,
            documentToPreview: doc,
            previewSpecificVersion: false
        });
    };

    /*
     * Handle user clicking on a product row column
     * set the selected product to state and open the modal
     */
    const getTdProps = (
        finalState: FinalState,
        rowInfo: RowInfoDocument | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (isMobile) {
            if (rowInfo) {
                return {
                    onClick: () => {
                        setState({
                            ...state,
                            selectedRow: {
                                [rowInfo.viewIndex || 0]: !state.selectedRow[
                                    rowInfo.viewIndex || 0
                                ]
                            },
                            selectedCloudDocument:
                                props.tableData[rowInfo.viewIndex || 0]
                        });
                    }
                };
            }

            return {};
        } else {
            // Desktop
            if (rowInfo) {
                if (
                    rowInfo &&
                    column &&
                    column.id &&
                    column.id === 'download-latest'
                ) {
                    return {
                        onClick: () => {
                            if (rowInfo.original && rowInfo.original.versions) {
                                const latest = rowInfo.original.versions.reduce(
                                    (prev, current) => {
                                        return moment
                                            .utc(getLastModifiedDate(prev))
                                            .isAfter(
                                                moment.utc(
                                                    getLastModifiedDate(current)
                                                )
                                            )
                                            ? prev
                                            : current;
                                    }
                                );
                                const document: IcloudDocument = {
                                    ...latest,
                                    // ensure we have a document type; often missing from version
                                    mimeType:
                                        latest.mimeType ||
                                        rowInfo.original.mimeType
                                };

                                props.getCloudDocument(props.t, document, true);
                            }
                        }
                    };
                } else if (
                    rowInfo &&
                    column &&
                    column.id &&
                    column.id === 'preview-button'
                ) {
                    return {
                        onClick: () => {
                            if (rowInfo.original && rowInfo.original.versions) {
                                const index = rowInfo.original.versions.length;
                                const version =
                                    rowInfo.original.versions[index - 1];
                                const document: IcloudDocument = {
                                    ...version,
                                    // ensure we have a document type; often missing from version
                                    mimeType:
                                        version.mimeType ||
                                        rowInfo.original.mimeType
                                };
                                showLatestDocumentPreview(document);
                            }
                        }
                    };
                } else {
                    return {
                        onClick: (e: any, handleOriginal: any) => {
                            if (rowInfo.level === 0 || rowInfo.level === 1) {
                                handleOriginal();
                            } else {
                                handleOriginal();
                                if (
                                    !state.selectedRow[
                                        rowInfo.viewIndex || 0
                                    ] &&
                                    rowInfo.original
                                ) {
                                    props.setSelectedParentID(
                                        rowInfo.original.id
                                    );
                                } else {
                                    props.clearSelectedParentID();
                                }
                            }
                        }
                    };
                }
            } else {
                return {};
            }
        }
    };

    const onSearchValueChanges = (value: any, key: string) => {
        console.log('key', key);
        switch (key) {
            case 'assetSearch':
            case 'name':
                clearTimeout(debounce);
                debounce = setTimeout(() => {
                    props.setTableFilter({ [key]: value });
                    props.getDocuments(props.t, {
                        ...props.tableFilters,
                        [key]: value
                    });
                }, 1000);
                break;
            case 'mainCategory':
                props.setTableFilter({ mainCategory: value });
                props.getDocuments(props.t, {
                    ...props.tableFilters,
                    mainCategoryID: value?.value
                });
                break;
            case 'type':
                props.setTableFilter({
                    type: value,
                    page: 1
                });
                props.getDocuments(props.t, {
                    ...props.tableFilters,
                    type: value
                });
                break;
            case 'dateRange':
            case 'isSystem':
            case 'jobType':
                props.setTableFilter({
                    [key]: value
                });
                props.getDocuments(props.t, {
                    ...props.tableFilters,
                    [key]: value
                });
                break;
            default:
                break;
        }
    };

    const goBack = () => {
        props.clearSelectedParentID();
        props.clearSelectedDocumentID();
        setState({ ...state, selectedRow: {} });
    };

    const newVersion = (parentDocumentID: string) => {
        props.clearSelectedDocumentID();
        props.setSelectedParentID(parentDocumentID);
        props.toggleEditDocumentModal();
    };

    const editDoc = (documentID: string) => {
        props.setSelectedDocumentID(documentID);
        props.toggleEditDocumentModal();
    };

    React.useEffect(() => {
        props.getDocumentTypes();
        setColumns();

        if (!props.productInfo) {
            props.getProductInfo();
        }
    }, []);

    React.useEffect(() => {
        setColumns();
    }, [Object.keys(documentTypeOptions).length]);

    React.useEffect(() => {
        props.getDocumentTypes();
        setColumns();
    }, [selectedFacilityID]);

    React.useEffect(() => {
        if (location.search === '' && selectedFacilityID.length) {
            props.getDocuments(props.t, props.tableFilters);
        } else if (selectedFacilityID.length) {
            props.getDocuments(props.t, {});
        }
    }, [location.search]);

    const downloadMobileDocument = (selectedCloudDocument: IcloudDocument) => {
        props.getCloudDocument(props.t, selectedCloudDocument, true);
    };

    React.useEffect(() => {
        setState(currState => ({
            ...currState,
            filterLength: Object.keys(fieldConfig.controls).length
        }));
    }, [fieldConfig.controls]);

    const { t, tableData, tableFilters, totalPages } = props;

    // Mobile and a row has been selected
    if (
        isMobile &&
        state.selectedRow &&
        Object.keys(state.selectedRow).length > 0
    ) {
        return (
            <>
                <ManageDocumentVersions
                    t={props.t}
                    currentTile={state.currentTile}
                    selectedCloudDocument={state.selectedCloudDocument}
                    totalPages={totalPages}
                    setTableFilter={props.setTableFilter}
                    tableFilters={tableFilters}
                    documentTypeOptions={documentTypeOptions}
                    goBack={goBack}
                    newVersion={newVersion}
                    editDoc={editDoc}
                    downloadDocument={downloadMobileDocument}
                />

                <EditDocumentLibraryModal
                    selectedDocument={props.selectedDocument}
                    colorButton={
                        constants.colors[
                            `${state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                />
            </>
        );
    }
    // Mobile and no row selected
    else if (isMobile) {
        return (
            <div
                className="manage-document"
                style={{
                    ['--filter-size' as string]: state.filterLength
                }}
                data-btn="true"
            >
                <Banner
                    title={t('bannerTitle')}
                    img={state.currentTile.srcBanner}
                    color={state.currentTile.color}
                >
                    <SelectFacilityContainer
                        t={props.t}
                        classNameOverride={''}
                    />
                </Banner>
                <SearchTableForm
                    fieldConfig={fieldConfig}
                    handleSubmit={props.getDocuments}
                    loading={props.loading}
                    colorButton={
                        constants.colors[
                            `${state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    subscribeValueChanges={true}
                    onValueChanges={onSearchValueChanges}
                    t={props.t}
                    showSearchButton={false}
                />
                {props.canEditDocuments && (
                    <Button
                        className="table-add-button"
                        bsStyle="link"
                        onClick={() => {
                            collapseTable();
                            props.clearSelectedParentID();
                            props.clearSelectedDocumentID();
                            props.toggleEditDocumentModal();
                        }}
                    >
                        {t(`manageDocument:newDocument`)}
                    </Button>
                )}
                <ReactTable
                    data={tableData}
                    columns={state.columns}
                    getTdProps={getTdProps}
                    pageSize={tableData.length >= 10 ? tableData.length : 10}
                    pages={totalPages}
                    page={tableFilters.page - 1}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={onPageChange}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    key={'id'}
                />

                <EditDocumentLibraryModal
                    selectedDocument={props.selectedDocument}
                    colorButton={
                        constants.colors[
                            `${state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                />
            </div>
        );
    }

    // Desktop
    return (
        <div className="manage-document">
            <Banner
                title={''}
                img={state.currentTile.srcBanner}
                color={state.currentTile.color}
            >
                <SelectFacilityContainer t={props.t} classNameOverride={''} />
            </Banner>
            <SearchTableForm
                fieldConfig={fieldConfig}
                handleSubmit={props.getDocuments}
                loading={props.loading}
                colorButton={
                    constants.colors[
                        `${state.currentTile.color}Button` as keyof typeof constants.colors
                    ]
                }
                subscribeValueChanges={true}
                onValueChanges={onSearchValueChanges}
                t={props.t}
                showSearchButton={false}
                lg={10}
            >
                {props.canEditDocuments && (
                    <Col>
                        <Button
                            className="document-add-button"
                            bsStyle="link"
                            onClick={() => {
                                collapseTable();
                                props.clearSelectedParentID();
                                props.clearSelectedDocumentID();
                                props.toggleEditDocumentModal();
                            }}
                        >
                            {t(`manageDocument:newDocument`)}
                        </Button>
                    </Col>
                )}
            </SearchTableForm>
            {state.columns && state.columns.length > 0 && (
                <ReactTable
                    data={tableData}
                    columns={state.columns}
                    getTdProps={getTdProps}
                    pageSize={tableData.length >= 10 ? tableData.length : 10}
                    manual
                    pages={totalPages}
                    page={tableFilters.page - 1}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={onPageChange}
                    sortable={false}
                    multiSort={false}
                    pivotBy={['groupType', 'type']}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    onExpandedChange={e => {
                        setState({
                            ...state,
                            selectedRow: e
                        });
                    }}
                    SubComponent={(rowInfo: RowInfo) => (
                        <ManageDocumentVersionExpander
                            {...rowInfo}
                            addVersion={(): void => {
                                props.setSelectedParentID(rowInfo.original.id);
                                props.toggleEditDocumentModal();
                            }}
                            canEditDocuments={props.canEditDocuments}
                            canDeleteSystemDocuments={
                                props.canDeleteSystemDocuments
                            }
                            toggleEditVersion={props.toggleEditDocumentModal}
                            getCloudDocument={props.getCloudDocument}
                            deleteVersion={props.deleteVersion}
                            setSelectedVersionID={props.setSelectedDocumentID}
                            clearSelectedDocumentID={
                                props.clearSelectedDocumentID
                            }
                            t={props.t}
                        />
                    )}
                    expanded={state.selectedRow}
                />
            )}
            <EditDocumentLibraryModal
                selectedDocument={props.selectedDocument}
                colorButton={
                    constants.colors[
                        `${state.currentTile.color}Button` as keyof typeof constants.colors
                    ]
                }
            />

            <PreviewDocumentModal
                specificVersion={state.previewSpecificVersion}
                showModal={state.showPreviewDocumentModal}
                document={state.documentToPreview}
                hideModal={() => {
                    setState({ ...state, showPreviewDocumentModal: false });
                }}
                colorButton={
                    constants.colors[
                        `${state.currentTile.color}Button` as keyof typeof constants.colors
                    ]
                }
            />
        </div>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    let tableData = Object.values(state.manageDocument.documentsByID);

    // We need to move all AGSSSchematic to AGSReblancing
    tableData = tableData.map((document: IcloudDocument) => {
        // Set the document type to AGSReblancing
        if (
            document.cloudDocumentTypeID.toUpperCase() ===
            DocumentTypesEnum.AGSSSchematic
        ) {
            document.cloudDocumentTypeID = DocumentTypesEnum.AGSReblancing.toLocaleLowerCase();

            // Now set all the versions as well
            document.versions = document.versions?.map(
                (version: IcloudDocument) => {
                    version.cloudDocumentTypeID =
                        DocumentTypesEnum.AGSReblancing;
                    return version;
                }
            );
        }

        return document;
    });

    let selectedDocument =
        state.manageDocument.documentsByID[
            state.manageDocument.selectedDocumentID
        ];
    selectedDocument = selectedDocument ? selectedDocument : initialDocument;

    if (selectedDocument === initialDocument) {
        map(state.manageDocument.documentsByID, (document: any) => {
            map(document.versions, (version: IcloudDocument) => {
                if (version.id === state.manageDocument.selectedDocumentID) {
                    selectedDocument = version;
                }
            });
        });
    }
    const canEditDocuments = constants.hasSecurityFunction(state.user, [
        constants.securityFunctions.ManageDocumentLibrary.id
    ]);
    const canAddDocuments = constants.hasSecurityFunction(state.user, [
        constants.securityFunctions.ManageGeneralDocuments.id
    ]);
    const canDeleteSystemDocuments = constants.hasSecurityFunction(state.user, [
        constants.securityFunctions.ManageSystemDocuments.id
    ]);

    return {
        selectedDocument,
        tableData,
        totalPages: state.manageDocument.totalPages,
        showEditDocumentModal: state.manageDocument.showEditDocumentModal,
        tableFilters: state.manageDocument.tableFilters,
        user: state.user,
        canEditDocuments,
        canAddDocuments,
        canDeleteSystemDocuments,
        mainCategoryOptions: state?.productInfo?.mainCategoryOptions,
        productInfo: state?.productInfo
    };
};

export default withTranslation('manageDocument')(
    connect(mapStateToProps, {
        getDocumentTypes,
        getDocuments,
        toggleEditDocumentModal,
        setTableFilter,
        setSelectedParentID,
        clearSelectedParentID,
        setSelectedDocumentID,
        clearSelectedDocumentID,
        getCloudDocument,
        deleteVersion,
        getFacilitiesByCountry,
        closeAllModals,
        getProductInfo
    })(ManageDocumentLibrary)
);
