/*
 * EditMeasurementPointForm
 * Edit measurement point
 */

import { find, map } from 'lodash';
import { Button, Col } from 'react-bootstrap';
import {
    AbstractControl,
    FieldConfig,
    FormGenerator,
    FormGroup,
    GroupProps,
    Validators
} from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';

import * as React from 'react';
import InputList from './InputList';

import { TFunction } from 'i18next';
import {
    deleteMeasurementPoint,
    saveMeasurementPointToMeasurementPointList,
    toggleEditMeasurementPointListModal,
    updateMeasurementPoint
} from '../../actions/manageMeasurementPointListsActions';
import { caStandards, constants, usStandards } from '../../constants/constants';
import {
    IdefaultReport,
    // ImeasurementPointSelectOption
    ImeasurementPoint,
    ImeasurementPointList,
    ImeasurementPointListTab,
    ImeasurementPointSelectOption,
    Ioption,
    IoptionNumberValue,
    IsmartlinkDataPoint
} from '../../models';
import {
    jobDefaultTypeEnum,
    measurementPointListTypeEnum,
    measurementSystemEnum,
    reportTypeEnum
} from '../../models-enums';
import { FormUtil } from '../common/FormUtil';
const uuidv4 = require('uuid/v4');

interface IformSate {
    value: any;
    disabled: boolean;
}

const InputListAbstract = (props: AbstractControl) => <InputList {...props} />;

const trueFalseOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 }
];

const getTrueFalseOption = (value: boolean | null | number) => {
    if (value === true || value === 1) {
        return { label: 'Yes', value: 1 };
    } else if (value === false || value === 0) {
        return { label: 'No', value: 0 };
    } else {
        return null;
    }
};

const getSummaryPageDefaultText = (
    selectedMeasurementPointList: ImeasurementPointList,
    reportDefaults: { [key: string]: IdefaultReport }
) => {
    let coverLetterDefault: string | null = null;

    // Annual Inspection
    if (
        selectedMeasurementPointList.type ===
        measurementPointListTypeEnum.annual
    ) {
        const coverLetter = Object.values(reportDefaults).find(
            (report: IdefaultReport) =>
                report.reportType === reportTypeEnum.annualInspection
        );
        if (coverLetter) {
            coverLetterDefault = coverLetter.defaultCoverLetter;
        }
    }
    // Verification
    else if (
        selectedMeasurementPointList.type ===
        measurementPointListTypeEnum.verification
    ) {
        const coverLetter = Object.values(reportDefaults).find(
            (report: IdefaultReport) =>
                report.reportType === reportTypeEnum.verification
        );
        if (coverLetter) {
            coverLetterDefault = coverLetter.defaultCoverLetter;
        }
    }
    // Touchpoint (repair, maintenance, warranty)
    else if (
        selectedMeasurementPointList.type ===
        measurementPointListTypeEnum.touchpoint
    ) {
        const coverLetter = Object.values(reportDefaults).find(
            (report: IdefaultReport) =>
                report.reportType === reportTypeEnum.visit
        );
        if (coverLetter) {
            coverLetterDefault = coverLetter.defaultCoverLetter;
        }
    }
    // AGS Rebalancing
    else if (
        selectedMeasurementPointList.type ===
        measurementPointListTypeEnum.agsRebalancing
    ) {
        const coverLetter = Object.values(reportDefaults).find(
            (report: IdefaultReport) =>
                report.reportType === reportTypeEnum.agsRebalancing
        );
        if (coverLetter) {
            coverLetterDefault = coverLetter.defaultCoverLetter;
        }
    }
    // Audit
    else if (
        selectedMeasurementPointList.type === measurementPointListTypeEnum.audit
    ) {
        const coverLetter = Object.values(reportDefaults).find(
            (report: IdefaultReport) =>
                report.reportType === reportTypeEnum.audit
        );
        if (coverLetter) {
            coverLetterDefault = coverLetter.defaultCoverLetter;
        }
    }
    // Commissioning
    else if (
        selectedMeasurementPointList.type ===
        measurementPointListTypeEnum.Commissioning
    ) {
        const coverLetter = Object.values(reportDefaults).find(
            (report: IdefaultReport) =>
                report.reportType === reportTypeEnum.commissioning
        );
        if (coverLetter) {
            coverLetterDefault = coverLetter.defaultCoverLetter;
        }
    }
    // Daily Rounds
    else if (
        selectedMeasurementPointList.type ===
        measurementPointListTypeEnum.dailyRounds
    ) {
        const coverLetter = Object.values(reportDefaults).find(
            (report: IdefaultReport) =>
                report.reportType === reportTypeEnum.dailyRounds
        );
        if (coverLetter) {
            coverLetterDefault = coverLetter.defaultCoverLetter;
        }
    }

    return coverLetterDefault;
};

/*
 * The default or main fields for a Measurement Point
 */
const buildMainMeasurementPointControls = (
    measurementPoint: ImeasurementPoint,
    disabled: boolean,
    forJob: boolean | undefined,
    isDailyRounds: boolean,
    dailyRoundOptions: Ioption[],
    reportDefaults: { [key: string]: IdefaultReport },
    selectedMeasurementPointList: ImeasurementPointList
): { [key: string]: GroupProps } => {
    const {
        helpText = '',
        type,
        guideText = null,
        allowNotes = true,
        label,
        showInReport = true,
        //defaultText = null,
        dedicatedReportPage = false,
        smartlinkID = undefined,
        measurementSystem = undefined,
        generateLead = true
    } = measurementPoint;

    let { defaultText } = measurementPoint;

    const selectedType = type
        ? { label: constants.measurementPointTypeEnum[type], value: type }
        : null;
    // Long Text
    if (
        selectedType?.value ===
        constants.measurementPointTypes.MEASUREMENT_POINT_LONGTEXT
    ) {
        return {
            type: {
                render: FormUtil.Select,
                meta: {
                    options: constants.measurementPointTypeOptions,
                    label: 'type',
                    colWidth: 12,
                    placeholder: 'typePlaceholder',
                    isClearable: false
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: { value: selectedType, disabled }
            },
            label: {
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        Validators.maxLength(150)
                    ]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'measurementPointText',
                    colWidth: 12,
                    type: 'text'
                },
                formState: { value: label, disabled }
            },
            dedicatedReportPage: {
                render: FormUtil.Toggle,
                meta: {
                    label: 'dedicatedReportPage',
                    colWidth: 12
                },
                formState: { value: dedicatedReportPage }
            },
            defaultText: {
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'measurementPointDefaultText',
                    colWidth: 12,
                    type: 'text',
                    initialContent: defaultText ? defaultText : '',
                    charLimit: 1000,
                    toolbarConfig: {
                        options: ['link', 'inline', 'list', 'fontSize'],
                        inline: { options: ['bold', 'italic', 'underline'] },
                        list: { options: ['unordered', 'ordered'] },
                        fontSize: {
                            options: [
                                8,
                                9,
                                10,
                                11,
                                12,
                                14,
                                16,
                                18,
                                24,
                                30,
                                36,
                                48,
                                60,
                                72,
                                96
                            ]
                        }
                    }
                }
            },
            showInReport: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'showInReport',
                    colWidth: 12,
                    options: trueFalseOptions,
                    isClearable: false
                },
                formState: { value: getTrueFalseOption(showInReport), disabled }
            },

            helpText: {
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'helpText',
                    colWidth: 12,
                    type: 'text',
                    initialContent: helpText ? helpText : ''
                },
                formState: { value: helpText, disabled }
            }
        };
    }

    if (
        selectedType?.value ===
        constants.measurementPointTypes.MEASUREMENT_POINT_SUMMARYPAGE
    ) {
        // If we're creating a new MP, default the defaultText to the corresponding Report Default Cover Letter
        if (
            defaultText === '' ||
            defaultText === undefined ||
            defaultText === null
        ) {
            const newDefaultText = getSummaryPageDefaultText(
                selectedMeasurementPointList,
                reportDefaults
            );

            if (newDefaultText !== null) {
                defaultText = newDefaultText;
            }
        }

        return {
            type: {
                render: FormUtil.Select,
                meta: {
                    options: constants.measurementPointTypeOptions, // if Summary page needs to be limited to only Job Data, might neet two different enums
                    label: 'type',
                    colWidth: 12,
                    placeholder: 'typePlaceholder',
                    isClearable: false
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: { value: selectedType, disabled }
            },
            label: {
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        Validators.maxLength(150)
                    ]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'measurementPointText',
                    colWidth: 12,
                    type: 'text'
                },
                formState: { value: label, disabled }
            },
            defaultText: {
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'measurementPointDefaultText',
                    colWidth: 12,
                    type: 'text',
                    initialContent: defaultText ? defaultText : '',
                    charLimit: 1000,
                    toolbarConfig: {
                        options: ['link', 'inline', 'list', 'fontSize'],
                        inline: { options: ['bold', 'italic', 'underline'] },
                        list: { options: ['unordered', 'ordered'] },
                        fontSize: {
                            options: [
                                8,
                                9,
                                10,
                                11,
                                12,
                                14,
                                16,
                                18,
                                24,
                                30,
                                36,
                                48,
                                60,
                                72,
                                96
                            ]
                        }
                    }
                }
            },
            showInReport: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'showInReport',
                    colWidth: 12,
                    options: trueFalseOptions,
                    isClearable: false
                },
                formState: { value: getTrueFalseOption(showInReport), disabled }
            }
        };
    }

    // Field config to configure form
    let commonControls = {
        type: {
            render: FormUtil.Select,
            meta: {
                options: constants.measurementPointTypeOptions,
                label: 'type',
                colWidth: 12,
                placeholder: 'typePlaceholder',
                isClearable: false
            },
            options: {
                validators: [FormUtil.validators.requiredWithTrim]
            },
            formState: { value: selectedType, disabled }
        },
        label: {
            options: {
                validators: [
                    FormUtil.validators.requiredWithTrim,
                    Validators.maxLength(150)
                ]
            },
            render: FormUtil.TextInput,
            meta: {
                label: 'measurementPointText',
                colWidth: 12,
                type: 'text'
            },
            formState: { value: label, disabled }
        },
        generateLead: {
            render:
                selectedType?.value ===
                constants.measurementPointTypes.MEASUREMENT_POINT_PASSFAIL
                    ? FormUtil.Toggle
                    : () => <></>,
            meta: {
                label: 'generateLead',
                colWidth: 12
            },
            formState: { value: generateLead, disabled }
        },
        guideText: {
            render: FormUtil.TextInput,
            meta: {
                label: 'guideText',
                colWidth: 12,
                type: 'text'
            },
            formState: { value: guideText, disabled }
        },
        showInReport: {
            render: FormUtil.SelectWithoutValidation,
            meta: {
                label: 'showInReport',
                colWidth: 12,
                options: trueFalseOptions,
                isClearable: false
            },
            formState: { value: getTrueFalseOption(showInReport), disabled }
        },

        helpText: {
            render: FormUtil.RichTextEditor,
            meta: {
                label: 'helpText',
                colWidth: 12,
                type: 'text',
                initialContent: helpText ? helpText : ''
            },
            formState: { value: helpText, disabled }
        }
    };

    if (isDailyRounds) {
        let smartLinkIdOption = undefined;
        if (smartlinkID !== undefined) {
            smartLinkIdOption = dailyRoundOptions.find(
                (option: Ioption) => option.value === smartlinkID
            );
        }

        let measurementSystemOption = undefined;
        if (measurementSystem !== undefined) {
            measurementSystemOption = {
                label: measurementSystemEnum[measurementSystem],
                value: measurementSystem
            };
        }

        return {
            ...commonControls,
            smartlinkID: {
                render: FormUtil.Select,
                meta: {
                    label: 'smartlinkId',
                    colWidth: 12,
                    options: dailyRoundOptions,
                    placeholder: 'typePlaceholder',
                    isClearable: false
                },
                formState: { value: smartLinkIdOption, disabled }
            },
            measurementSystem: {
                render: FormUtil.Select,
                meta: {
                    label: 'measurementSystem',
                    colWidth: 12,
                    options: FormUtil.convertEnumToOptions(
                        measurementSystemEnum
                    ),
                    placeholder: 'typePlaceholder',
                    isClearable: false
                },
                formState: { value: measurementSystemOption, disabled }
            }
        };
    }

    if (!forJob) {
        return {
            ...commonControls,
            allowNotes: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'allowNotes',
                    colWidth: 12,
                    options: trueFalseOptions,
                    isClearable: false
                },
                formState: { value: getTrueFalseOption(allowNotes), disabled }
            }
        };
    }
    return commonControls;
};

const buildPassFailControl = (formState: IformSate) => {
    return {
        passFailDefault: {
            options: { validators: [FormUtil.validators.requiredWithTrim] },
            render: FormUtil.Select,
            meta: {
                label: 'passFailDefault',
                colWidth: 12,
                options: constants.measurementPointPassFailOptions,
                isClearable: false
            },
            formState
        } as GroupProps
    };
};

const buildNumericControl = (
    selectedNumericMinValue: number | null,
    selectedNumericMaxValue: number | null,
    selectedNumericAllowDecimals: boolean | null,
    selectedjobDefaultTypeOption: IoptionNumberValue | null,
    disabled: boolean,
    standard: string
) => {
    let constructedControls = {
        numericMinValue: {
            options: {},
            render: FormUtil.TextInput,
            meta: {
                label: 'numericMinValue',
                colWidth: 12,
                type: 'number',
                name: 'numericMinValue'
            },
            formState: { value: selectedNumericMinValue, disabled }
        },
        numericMaxValue: {
            options: {},
            render: FormUtil.TextInput,
            meta: {
                label: 'numericMaxValue',
                colWidth: 12,
                type: 'number',
                name: 'numericMaxValue'
            },
            formState: { value: selectedNumericMaxValue, disabled }
        },
        numericAllowDecimals: {
            options: { validators: [FormUtil.validators.requiredWithTrim] },
            render: FormUtil.Select,
            meta: {
                label: 'numericAllowDecimals',
                colWidth: 12,
                options: trueFalseOptions,
                isClearable: false
            },
            formState: {
                value: getTrueFalseOption(selectedNumericAllowDecimals),
                disabled
            }
        }
    } as { [key: string]: GroupProps };
    const stUpperCase = standard.toUpperCase();
    if (
        usStandards.includes(stUpperCase) ||
        caStandards.includes(stUpperCase)
    ) {
        constructedControls = {
            ...constructedControls,
            jobDefaultType: {
                render: FormUtil.Select,
                meta: {
                    label: 'jobDefaultType',
                    colWidth: 12,
                    options: FormUtil.convertEnumToOptions(jobDefaultTypeEnum),
                    shouldTranslate: true,
                    isClearable: true
                },
                formState: {
                    value: selectedjobDefaultTypeOption,
                    disabled
                }
            }
        };
    }

    return constructedControls;
};

const buildCommonNonPassFailControls = (
    measurementPoint: ImeasurementPoint,
    disabled: boolean,
    forJob: boolean | undefined
): { [key: string]: GroupProps } => {
    const {
        selectRememberBetweenDevice = null,
        selectRememberBetweenInspection = null,
        isRequired = true
    } = measurementPoint;

    if (forJob && forJob === true) {
        return {
            isRequired: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'isRequired',
                    colWidth: 12,
                    options: trueFalseOptions,
                    isClearable: false
                },
                formState: { value: getTrueFalseOption(isRequired), disabled }
            }
        };
    }

    return {
        selectRememberBetweenDevice: {
            options: { validators: [FormUtil.validators.requiredWithTrim] },
            render: FormUtil.Select,
            meta: {
                label: 'selectRememberBetweenDevice',
                colWidth: 12,
                options: trueFalseOptions,
                isClearable: false
            },
            formState: {
                value: getTrueFalseOption(selectRememberBetweenDevice),
                disabled
            }
        },
        selectRememberBetweenInspection: {
            options: { validators: [FormUtil.validators.requiredWithTrim] },
            render: FormUtil.Select,
            meta: {
                label: 'selectRememberBetweenInspection',
                colWidth: 12,
                options: trueFalseOptions,
                isClearable: false
            },
            formState: {
                value: getTrueFalseOption(selectRememberBetweenInspection),
                disabled
            }
        },
        isRequired: {
            render: FormUtil.SelectWithoutValidation,
            meta: {
                label: 'isRequired',
                colWidth: 12,
                options: trueFalseOptions,
                isClearable: false
            },
            formState: { value: getTrueFalseOption(isRequired), disabled }
        }
    };
};

const buildGroupFieldConfig = (formState: IformSate) => {
    return {
        controls: {
            label: {
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        Validators.maxLength(150)
                    ]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'groupLabel',
                    colWidth: 12,
                    type: 'text',
                    name: 'label'
                },
                formState
            }
        } as { [key: string]: GroupProps }
    };
};

interface Iprops extends React.Props<EditMeasurementPointForm> {
    selectedMeasurementPointList: ImeasurementPointList;
    selectedMeasurementPoint: ImeasurementPoint;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    userCountryCode: string;
    toggleEditMeasurementPointListModal: typeof toggleEditMeasurementPointListModal;
    toggleModal: () => void;
    saveMeasurementPointToMeasurementPointList: typeof saveMeasurementPointToMeasurementPointList;
    selectedTab: ImeasurementPointListTab;
    updateMeasurementPoint: typeof updateMeasurementPoint;
    canEditGlobal: boolean;
    deleteMeasurementPoint: typeof deleteMeasurementPoint;
    isCustomerView: boolean;
    forJob: boolean | undefined;
    isDailyRounds: boolean;
    smartlinkDataPoints: IsmartlinkDataPoint[];
    reportDefaults: { [key: string]: IdefaultReport };
}

interface Istate {
    fieldConfig: FieldConfig;
}
class EditMeasurementPointForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private persistTimeout: any;

    dailyRoundOptions: Ioption[] = this.props.smartlinkDataPoints.map(
        (dp: IsmartlinkDataPoint) => {
            return {
                label: `${dp.number} - ${dp.description}`,
                value: `${dp.id}`
            } as Ioption;
        }
    );

    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: FormUtil.translateForm(
                this.getFormConfig(this.props.selectedMeasurementPoint),
                this.props.t
            )
        };
    }

    componentDidMount() {
        if (
            !this.props.selectedMeasurementPointList ||
            !this.props.selectedMeasurementPoint
        ) {
            console.error('missing measurementPoint List or MeasurementPoint');
            this.props.toggleModal();
        }
    }
    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    /*
     * Subscribe to only some of the changes
     * "type" so that we can update the fieldConfig according to the type of measurement point
     * do not persist values that depend on the type of measurement point
     */

    subscribeToValueChanges = () => {
        let controls = [
            'type',
            'label',
            'guideText',
            'allowNotes',
            'helpText',
            'defaultText'
        ];
        if (
            this.props.selectedMeasurementPoint.type ===
            constants.measurementPointTypes.GROUP
        ) {
            controls = [];
        }
        controls.forEach((key: string) => {
            this.subscription = this.formGroup
                .get(key)
                ?.valueChanges.subscribe((value: Ioption | null) => {
                    this.handleValueChange(key, value);
                });
        });
    };

    handleValueChange = (key: string, value: null | Ioption) => {
        switch (key) {
            case 'type':
                if (value && value.value) {
                    const controls = this.getFormConfig({
                        ...this.props.selectedMeasurementPoint,
                        type: parseInt(value.value, 10)
                    });
                    this.setState({
                        fieldConfig: FormUtil.translateForm(
                            controls,
                            this.props.t
                        )
                    });
                }
            // eslint-disable-line no-fallthrough
            default:
                clearTimeout(this.persistTimeout);
                this.persistTimeout = setTimeout(() => {
                    const newValue =
                        value !== null && typeof value === 'object'
                            ? value.value
                            : value;
                    this.props.updateMeasurementPoint(
                        {
                            ...this.props.selectedMeasurementPoint,
                            [key]: newValue
                        },
                        this.props.selectedTab.id
                    );
                }, 200);
                break;
        }
    };
    /*
     * update which form controls based on the type of measurement point
     */
    getFormConfig = (measurementPoint: ImeasurementPoint) => {
        const {
            type,
            label,
            passFailDefault,
            numericMinValue = null,
            numericMaxValue = null,
            numericAllowDecimals = null,
            jobDefaultType
        } = measurementPoint;
        const { forJob, isDailyRounds } = this.props;

        let selectedPassFailDefault = null;
        if (passFailDefault) {
            selectedPassFailDefault =
                find(
                    constants.measurementPointPassFailOptions,
                    (tOpt: any) => tOpt.value === passFailDefault
                ) || null;
        }
        const mpTypes = constants.measurementPointTypes;
        const disabled = false;

        if (type === mpTypes.GROUP) {
            return buildGroupFieldConfig({ value: label, disabled });
        } else {
            let extraControls = {};
            if (type === mpTypes.MEASUREMENT_POINT_PASSFAIL) {
                extraControls = buildPassFailControl({
                    value: selectedPassFailDefault,
                    disabled
                });
            } else if (type === mpTypes.MEASUREMENT_POINT_NUMERIC) {
                const selectedJobDefaultTypeOption =
                    jobDefaultType !== undefined
                        ? {
                              value: jobDefaultType,
                              label: this.props.t(
                                  jobDefaultTypeEnum[jobDefaultType]
                              )
                          }
                        : null;
                extraControls = buildNumericControl(
                    numericMinValue,
                    numericMaxValue,
                    numericAllowDecimals,
                    selectedJobDefaultTypeOption,
                    disabled,
                    this.props.selectedMeasurementPointList.standardID
                );
            } else if (type === mpTypes.MEASUREMENT_POINT_SELECT) {
                extraControls = this.selectFieldConfig(
                    measurementPoint,
                    disabled
                );
            }
            if (type !== mpTypes.MEASUREMENT_POINT_PASSFAIL) {
                // if this is not a pass fail then add the isRequired select
                extraControls = {
                    ...extraControls,
                    ...buildCommonNonPassFailControls(
                        measurementPoint,
                        disabled,
                        forJob
                    )
                };
            }

            const mainControls = buildMainMeasurementPointControls(
                measurementPoint,
                disabled,
                forJob,
                isDailyRounds,
                this.dailyRoundOptions,
                this.props.reportDefaults,
                this.props.selectedMeasurementPointList
            );
            const fieldConfig = {
                controls: { ...mainControls, ...extraControls }
            };
            return fieldConfig as FieldConfig;
        }
    };

    selectFieldConfig = (
        measurementPoint: ImeasurementPoint,
        disabled: boolean
    ) => {
        const { selectOptions, selectDefaultOptionID = '' } = measurementPoint;
        let selectOptionsDefault = null;
        if (selectOptions) {
            selectOptionsDefault = map(selectOptions, mpo => {
                return {
                    ...mpo,
                    isDefault: selectDefaultOptionID === mpo.id,
                    isDeleted:
                        typeof mpo.isDeleted !== 'undefined'
                            ? mpo.isDeleted
                            : false
                };
            });
        }

        return {
            selectOptions: {
                options: {
                    validators: [
                        ({ value }: AbstractControl) => {
                            if (
                                value &&
                                value.filter(
                                    (val: ImeasurementPointSelectOption) =>
                                        val.isDeleted !== true
                                ).length
                            ) {
                                return null;
                            } else {
                                return {
                                    noValidSelectOptions: {
                                        message:
                                            'Please add at least one select option.'
                                    }
                                };
                            }
                        }
                    ]
                },
                render: InputListAbstract,
                meta: {
                    label: 'selectOptions',
                    buttonLabel: 'Add Option',
                    colWidth: 12,
                    placeholder: 'selectOptionsPlaceholder',
                    colorButton: 'info',
                    selectOptions: selectOptionsDefault
                },
                formState: { value: selectOptionsDefault, disabled }
            }
        } as { [key: string]: GroupProps };
    };

    formatSummaryText = (text: string) => {
        // Wrap each "variable" in a span with a style of blue text
        const defaultText = text.replace(
            /\{\{([^}]+)\}\}/g,
            (match: any, value: any) => {
                const placeholderSpan = `<span style="color:rgb(41, 105, 176)">${match}</span>`;
                const regex = new RegExp(
                    `<span[^>]*?style="color:rgb(41, 105, 176)">${match}</span>`,
                    'g'
                );

                // Check if the placeholder already has the specified style applied
                if (!text.match(regex)) {
                    return placeholderSpan;
                } else {
                    return match; // Placeholder already has the style, return original content
                }
            }
        );

        return defaultText;
    };

    handleSubmit = (evt: React.MouseEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        const {
            allowNotes,
            type,
            label,
            passFailDefault,
            numericAllowDecimals,
            selectOptions,
            selectRememberBetweenDevice,
            selectRememberBetweenInspection,
            guideText,
            helpText,
            numericMinValue,
            numericMaxValue,
            isRequired,
            showInReport,
            jobDefaultType,
            dedicatedReportPage,
            smartlinkID,
            measurementSystem,
            generateLead
        } = this.formGroup.value;

        let { defaultText } = this.formGroup.value;

        // If it's Summary Page, and we have default text
        if (
            type?.value &&
            type.value ===
                constants.measurementPointTypes.MEASUREMENT_POINT_SUMMARYPAGE &&
            defaultText
        ) {
            defaultText = this.formatSummaryText(defaultText);
        }
        // It's a summary page, but default text isn't showing up, check if there's a default text in the field config
        // This can happen if they are creating a new Summary page, and did not alter the default text
        else if (
            type?.value &&
            type.value ===
                constants.measurementPointTypes.MEASUREMENT_POINT_SUMMARYPAGE
        ) {
            const defaultTextIndex = Object.keys(
                this.state.fieldConfig.controls
            ).indexOf('defaultText');
            if (defaultTextIndex !== -1) {
                const defaultTextValue: any = Object.values(
                    this.state.fieldConfig.controls
                )[defaultTextIndex];
                if (
                    defaultTextValue &&
                    defaultTextValue.meta &&
                    defaultTextValue.meta.initialContent
                ) {
                    defaultText = defaultTextValue.meta.initialContent;
                    defaultText = this.formatSummaryText(defaultText);
                }
            }
        }

        let selectDefaultOptionID = '';
        if (selectOptions && selectOptions.length) {
            const defaultOption = selectOptions.find(
                (mpo: ImeasurementPointSelectOption) => {
                    return mpo.isDefault === true;
                }
            );
            if (defaultOption) {
                selectDefaultOptionID = defaultOption.id;
            }
        }

        // Notes are not allowed for long text or standard text
        let allowNotesValue = allowNotes ? allowNotes.value : true;
        if (
            this.props.forJob ||
            type?.value ===
                constants.measurementPointTypes.MEASUREMENT_POINT_LONGTEXT
        ) {
            allowNotesValue = false;
        }
        let dedicatedReportPageFinal;
        // toggle has some weird behavior, sometimes an object, sometimes a boolean
        if (typeof dedicatedReportPage === 'boolean') {
            dedicatedReportPageFinal = dedicatedReportPage;
        } else {
            dedicatedReportPageFinal =
                dedicatedReportPage?.value !== undefined
                    ? dedicatedReportPage?.value
                    : false;
        }

        const selectedType = type
            ? type.value
            : this.props.selectedMeasurementPoint.type;

        const cleanedIsRequired =
            isRequired && isRequired.value === 1 ? true : false;
        const cleanedShowInReport =
            showInReport && showInReport.value === 1 ? true : false;
        const cleanedAllowNotes =
            allowNotesValue && allowNotesValue === 1 ? true : false;

        const newQ: ImeasurementPoint = {
            ...this.props.selectedMeasurementPoint, // id, order, isDeleted, customerID
            selectRememberBetweenDevice: selectRememberBetweenDevice
                ? !!selectRememberBetweenDevice.value
                : undefined,
            selectRememberBetweenInspection: selectRememberBetweenInspection
                ? !!selectRememberBetweenInspection.value
                : undefined,
            selectOptions:
                selectOptions && selectOptions.length ? selectOptions : [],
            numericAllowDecimals: numericAllowDecimals
                ? numericAllowDecimals.value
                : false,
            passFailDefault: passFailDefault
                ? passFailDefault.value
                : undefined,
            allowNotes: cleanedAllowNotes,
            type: selectedType,
            isRequired: cleanedIsRequired,
            showInReport: cleanedShowInReport,
            selectDefaultOptionID:
                selectDefaultOptionID !== undefined &&
                selectDefaultOptionID !== ''
                    ? selectDefaultOptionID
                    : undefined,
            label,
            guideText,
            helpText,
            numericMinValue,
            numericMaxValue,
            defaultText,
            dedicatedReportPage: dedicatedReportPageFinal,
            id: this.props.selectedMeasurementPoint.id || uuidv4(),
            jobDefaultType: jobDefaultType ? jobDefaultType.value : undefined,
            smartlinkID: smartlinkID ? smartlinkID.value : undefined,
            measurementSystem: measurementSystem
                ? measurementSystem.value
                : undefined,
            generateLead:
                selectedType ===
                constants.measurementPointTypes.MEASUREMENT_POINT_PASSFAIL
                    ? generateLead
                    : undefined
        };

        this.props.saveMeasurementPointToMeasurementPointList(
            this.props.selectedMeasurementPointList.id,
            this.props.selectedTab.id,
            newQ
        );
        this.props.toggleModal();
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        this.subscribeToValueChanges();
    };

    deleteMP = () => {
        this.props.deleteMeasurementPoint(
            this.props.selectedMeasurementPoint.id,
            this.props.selectedMeasurementPointList.id,
            this.props.selectedTab.id,
            this.props.t
        );
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix beacon-form ${this.props.colorButton}`;
        const deleteButtonStyle =
            this.props.selectedMeasurementPoint.id.length === 0
                ? { marginRight: '15px', display: 'none' }
                : { marginRight: '15px' };

        return (
            <div>
                <form onSubmit={this.handleSubmit} className={formClassName}>
                    <FormGenerator
                        onMount={this.setForm}
                        fieldConfig={this.state.fieldConfig}
                    />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.props.toggleModal}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            type="button"
                            bsStyle="warning"
                            disabled={!this.props.canEditGlobal}
                            style={deleteButtonStyle}
                            onClick={this.deleteMP}
                        >
                            {t('common:delete')}
                        </Button>
                        <Button
                            bsStyle={this.props.colorButton}
                            type="submit"
                            disabled={this.props.loading}
                        >
                            {t('save')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}
export default EditMeasurementPointForm;
