import * as React from 'react';

import { IanalyticsQuote, Iuser } from '../../models';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import { CountUp } from 'countup.js';
import { mockAnalyticsQuote } from './mockAnalytics';

interface Iprops {
    t: TFunction;
    quote: IanalyticsQuote;
    isDemoMode: boolean;
    showUnquotedLeads: boolean;
    user: Iuser;
}

export default class QuoteAnalytics extends React.PureComponent<Iprops, {}> {
    private totalRef = React.createRef<HTMLHeadingElement>();
    private unquotedRef = React.createRef<HTMLHeadingElement>();

    constructor(props: Iprops) {
        super(props);
    }

    componentDidMount() {
        this.startCountUp();
    }

    getSelected = (): IanalyticsQuote => {
        const { quote, isDemoMode } = this.props;
        if (isDemoMode) {
            return mockAnalyticsQuote;
        }
        return quote;
    };

    startCountUp = () => {
        if (this.totalRef.current && this.unquotedRef.current) {
            const selectedAnalyticsQuote = this.getSelected();
            const options = {
                useEasing: true
            };
            const totalCountUp = new CountUp(
                this.totalRef.current,
                selectedAnalyticsQuote.total,
                options
            );
            const unquotedCountUp = new CountUp(
                this.unquotedRef.current,
                selectedAnalyticsQuote.unquotedLeads,
                options
            );
            setTimeout(() => {
                totalCountUp.start();
                unquotedCountUp.start();
            }, 750);
        }
    };

    // link to dashboard quotes if has customer quote SF.  If not then sales quotes
    getQuotesLink = () => {
        if (
            constants.hasSecurityFunction(this.props.user, [
                constants.securityFunctions.ManageCustomerQuotes.id
            ])
        ) {
            return '/quotes';
        } else {
            return '/quotes_sales';
        }
    };
    render() {
        const { t } = this.props;
        const selectedAnalyticsQuote = this.getSelected();
        return (
            <div className="form-group quote-analytics analytics-item">
                <div
                    className="analytics-inner"
                    data-section="title"
                    style={{ position: 'relative' }}
                >
                    <Link
                        to="/quotes"
                        style={{
                            fontSize: '1.7em',
                            marginRight: 'auto'
                        }}
                        title={t('analyticsWidget.moreInfo')}
                    >
                        <h4
                            style={{
                                color: '#000',
                                display: 'inline',
                                fontSize: '24px',
                                fontWeight: 'bold',
                                margin: '0 10px 0 0',
                                overflowWrap: 'break-word',
                                textTransform: 'uppercase',
                                wordWrap: 'break-word'
                            }}
                        >
                            {this.props.t('analyticsWidget.title')}
                        </h4>
                    </Link>
                    <Link
                        to="/quotes"
                        style={{
                            fontSize: '14px',
                            position: 'absolute',
                            right: 0,
                            zIndex: 4
                        }}
                        title={t('analyticsWidget.moreInfo')}
                        data-icon="more-info"
                        data-type="icon"
                    >
                        <FontAwesomeIcon
                            icon={['far', 'info-circle']}
                            color="#A1ACB3"
                            style={{
                                fontSize: '14px',
                                height: '14px',
                                width: '14px'
                            }}
                        />
                    </Link>
                </div>
                <div className="analytics-inner" data-section="numbers">
                    <div className="analytics-data">
                        <span
                            style={{
                                color: constants.colors.darkGreyText
                            }}
                        >
                            {t('analyticsWidget.totalQuotes')}
                        </span>
                        <h2 ref={this.totalRef}></h2>
                    </div>
                    <div
                        className="analytics-data"
                        style={{
                            display: this.props.showUnquotedLeads ? '' : 'none'
                        }}
                    >
                        <span
                            style={{
                                color: constants.colors.darkGreyText
                            }}
                        >
                            {t('analyticsWidget.totalUnquotedLeads')}
                        </span>
                        <h2 ref={this.unquotedRef}></h2>
                    </div>
                </div>
                <div className="analytics-inner" data-section="stats">
                    <div className="analytics-data" data-type="quote-new">
                        <p>{t('analyticsWidget.new')}</p>
                        <b>{selectedAnalyticsQuote.new}</b>
                    </div>
                    <div className="analytics-data" data-type="quote-approved">
                        <p>{t('analyticsWidget.approved')}</p>
                        <b>{selectedAnalyticsQuote.approved}</b>
                    </div>
                    <div
                        className="analytics-data"
                        data-type="quote-inprogress"
                    >
                        <p>{t('analyticsWidget.inProgress')}</p>
                        <b>{selectedAnalyticsQuote.inProgress}</b>
                    </div>
                    <div className="analytics-data" data-type="quote-rejected">
                        <p>{t('analyticsWidget.rejected')}</p>
                        <b>{selectedAnalyticsQuote.rejected}</b>
                    </div>
                </div>
            </div>
        );
    }
}
