import { initialDocument } from './initialState';
import * as types from '../actions/actionTypes';
import { IcloudDocument, ImanageManageDocumentReducer } from '../models';
import {
    createFormValuesWithName,
    createSelectedIDWithName,
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import { pickBy, map, keyBy, orderBy, filter } from 'lodash';
import * as moment from 'moment';
import { combineReducers } from 'redux';
import { documentTypeMapping } from '../constants/constants';
import { DocumentGroups } from '../models-enums';

const cleanDocumentObject = (document: IcloudDocument) => {
    return {
        ...initialDocument,
        ...pickBy(document, property => property !== null)
    } as IcloudDocument;
};

const groupDocumentObjects = (document: IcloudDocument): IcloudDocument => {
    let groupType =
        documentTypeMapping[document.cloudDocumentTypeID.toUpperCase()];
    let otherGroup = false;
    if (groupType === undefined) {
        otherGroup = true;
        groupType = DocumentGroups.reports;
    }
    return {
        ...document,
        otherGroup,
        groupType
    };
};

export const processDocumentData = (results: IcloudDocument[]) => {
    return map(results, (documents: IcloudDocument[]) => {
        const orderedDocuments = orderBy(
            documents,
            res => moment.utc(res.createDate).unix(),
            'asc'
        );
        if (orderedDocuments.length > 1) {
            orderedDocuments[0].isPrimary = true;
        }
        return { ...orderedDocuments[0], versions: orderedDocuments };
    }) as any;
};

export function documentsByID(
    state: { [key: string]: IcloudDocument } = {},
    action: any
): { [key: string]: IcloudDocument } {
    switch (action.type) {
        case types.LOAD_DOCUMENTS_SUCCESS: {
            let newDocuments = map(
                action.payload.result,
                (document: IcloudDocument) => {
                    return groupDocumentObjects(cleanDocumentObject(document));
                }
            ) as IcloudDocument[];
            return keyBy(newDocuments, 'id');
        }
        case types.ADD_DOCUMENT_SUCCESS:
            const doc = groupDocumentObjects(action.payload);
            return {
                ...state,
                [action.payload.id]: {
                    ...doc,
                    versions: [doc]
                }
            };
        case types.ADD_DOCUMENT_VERSION_SUCCESS: {
            const doc = groupDocumentObjects(action.payload);
            const parentDoc = { ...state[action.parentId] };
            parentDoc.versions = [...(parentDoc.versions || []), doc];
            return { ...state, [action.parentId]: parentDoc };
        }
        case types.EDIT_DOCUMENT_SUCCESS: {
            const doc = groupDocumentObjects(action.payload);
            const parentDocument = { ...state[action.parentId] };
            const versionDocuments = parentDocument.versions;
            // it is primary if it's id is the same as the parent.
            if (
                action.payload.id !== action.parentId &&
                versionDocuments &&
                versionDocuments.length > 0
            ) {
                // editing a version
                let versionIndex = -1;
                for (let i = 0; i < versionDocuments.length; i++) {
                    if (versionDocuments[i].id === action.payload.id) {
                        versionIndex = i;
                        break;
                    }
                }

                return {
                    ...state,
                    [action.parentId]: {
                        ...state[action.parentId],
                        versions: (
                            state[action.parentId].versions || []
                        ).map((version, i) =>
                            i === versionIndex ? doc : version
                        )
                    }
                };
            } else {
                // Editing the Primary doc
                const versions = state[action.parentId].versions;

                return {
                    ...state,
                    [action.parentId]: {
                        ...doc,
                        versions: (versions || []).map((version, i) =>
                            i === 0 ? { ...doc, isPrimary: true } : version
                        )
                    }
                };
            }
        }
        case types.REMOVE_DOCUMENT_SUCCESS: {
            const doc = { ...state[action.parentId] };
            doc.versions = filter(
                doc.versions,
                version => version.id !== action.payload.id
            );
            if (doc.versions.length === 0) {
                const data = { ...state };
                delete data[action.parentId];
                return data;
            }

            return { ...state, [action.parentId]: doc };
        }
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.DOCUMENT_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}
const documentManage = combineReducers<ImanageManageDocumentReducer>({
    documentsByID,
    totalPages,
    documentFormValues: (state, action) =>
        createFormValuesWithName(state, action, 'MANAGE_DOCUMENT'),
    versionFormValues: (state, action) =>
        createFormValuesWithName(state, action, 'MANAGE_DOCUMENT_VERSION'),
    selectedParentID: (state, action) =>
        createSelectedIDWithName(state, action, 'PARENT_ID'),
    selectedDocumentID: (state, action) =>
        createSelectedIDWithName(state, action, 'DOCUMENT_ID'),
    showEditDocumentModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_DOCUMENT'),
    showEditVersionModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_DOCUMENT_VERSION'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_DOCUMENT')
});

export default documentManage;
