import { constants } from '../../constants/constants';
import {
    IanalyticsInspection,
    IanalyticsSmartlink,
    IanalyticsTAE
} from '../../models';
import { dataFormatEnum, dataUnitEnum } from '../../models-enums';

// Dashboard Analytics
export const mockAnalyticsInspection: IanalyticsInspection = {
    mainCategoryID: '00000000-0000-0000-0000-000000000000',
    mainCategoryName: 'all',
    jobDate: '2019-10-07T15:39:43',
    data: [
        {
            name: 'pass',
            y: 40.0
        },
        {
            name: 'fail',
            y: 20.0,
            color: '#d00000'
        },
        {
            name: 'cannotComplete',
            y: 10.0
        },
        {
            name: 'repaired',
            y: 15.0
        },
        {
            name: 'notTested',
            y: 25.0
        }
    ],
    total: 375
};

export const mockAnalyticsPMP = {
    totalWorkOrders: 230,
    data: {
        open: [
            {
                color: constants.colors.lightTeal,
                radius: '100%',
                innerRadius: '70%',
                y: 79
            }
        ],
        closed: [
            {
                color: constants.colors.textBlue,
                radius: '70%',
                innerRadius: '40%',
                y: 22
            }
        ]
    }
};

export const mockAnalyticsSmartlinkAirSystem: IanalyticsSmartlink = {
    photoUrl:
        'https://acstadapp10000948.blob.core.windows.net/thumbnails/4585fda7-eb34-4baf-b234-a7141e7c3419.jpg',
    mainCategoryID: 'aec75f68-6836-4af1-89cf-75d9232a8d7b',
    mainCategoryName: 'Air System',
    updateDate: '2021-09-08T19:21:03.9534125',
    installBaseID: 'c258e296-f1ab-42a9-a5a9-e1308fe2b174',
    installBaseName:
        'Air System: Oil-Free Piston: Medical: 7.5 Hp / 5.5 KW: Duplex: BeaconMedaes: LPS-7D-D240-DCZ',
    installBaseLocation: 'Main Building : First Floor : Mechanical Room',
    installBaseSerialNumber: 'Air System Test',
    device: {
        guage01: {
            data: [657900.0],
            label: 'Compressor Outlet',
            unit: 7,
            format: 7,
            max: 200,
            min: 0,
            medium: 100,
            high: 350
        },
        guage02: {
            data: [457900],
            label: 'Net',
            unit: 7,
            format: 7,
            max: 100,
            min: 0,
            medium: 40,
            high: 80
        },
        guage03: {
            data: [342],
            label: 'Element Outlet',
            unit: 1,
            format: 3,
            max: 200,
            min: 0,
            medium: 40,
            high: 80
        },
        bar01: {
            // TODO: REmove the added data, it was just for testing
            data: [],
            label: 'Running Hours',
            unit: 9,
            format: 6,
            max: 107
        },
        line01: {
            data: [],
            label: 'Ambient Air Temperature',
            unit: dataUnitEnum.none,
            format: dataFormatEnum.countryTemperature
        },
        nextService: 2903.0,
        runningHours: 97.80083,
        loadedHours: 210.0,
        alarms: 'bad thing 1,bad thing 2,kaboom'
    },
    unit2Graph: [
        [1575316924000, 1500],
        [1575403324000, 1550],
        [1575489724000, 1590],
        [1575576124000, 1600],
        [1575662524000, 1610],
        [1575748924000, 1650]
    ],
    unit1Graph: [
        [1575316924000, 1200],
        [1575403324000, 1200],
        [1575489724000, 1210],
        [1575576124000, 1215],
        [1575662524000, 1220],
        [1575748924000, 1250]
    ],
    latestValues: []
};

const mockAnalyticssmartlinkAirSystemTwo: IanalyticsSmartlink = {
    photoUrl:
        'https://acstadapp10000948.blob.core.windows.net/thumbnails/4585fda7-eb34-4baf-b234-a7141e7c3419.jpg',
    mainCategoryID: 'aec75f68-6836-4af1-89cf-75d9232a8d7b',
    mainCategoryName: 'Air System Two',
    updateDate: '2021-09-08T19:21:03.9534125',
    installBaseID: 'c258e296-f1ab-42a9-a5a9-e1308fe2b185',
    installBaseName:
        'Air System Two: Oil-Free Piston: Medical: 7.5 Hp / 5.5 KW: Duplex: BeaconMedaes: LPS-7D-D240-DCZ',
    installBaseLocation: 'Main Building : First Floor : Mechanical Room',
    installBaseSerialNumber: 'Air System Test',
    device: {
        guage01: {
            data: [257900.0],
            label: 'Compressor Outlet',
            unit: 7,
            format: 7,
            max: 200,
            min: 0,
            medium: 100,
            high: 350
        },
        guage02: {
            data: [280300.0],
            label: 'Net',
            unit: 7,
            format: 7,
            max: 100,
            min: 0,
            medium: 40,
            high: 80
        },
        guage03: {
            data: [320],
            label: 'Element Outlet',
            unit: 1,
            format: 3,
            max: 200,
            min: 0,
            medium: 40,
            high: 80
        },
        bar01: {
            data: [],
            label: 'Running Hours',
            max: 107,
            unit: 9,
            format: 6
        },
        line01: {
            data: [],
            label: 'Ambient Air Temperature',
            unit: 0,
            format: dataFormatEnum.countryTemperature
        },
        nextService: 3903.0,
        runningHours: 97.80083,
        loadedHours: 20.0,
        alarms: null
    },
    unit2Graph: [
        [1575316924000, 1500],
        [1575403324000, 1550],
        [1575489724000, 1590],
        [1575576124000, 1600],
        [1575662524000, 1610],
        [1575748924000, 1650]
    ],
    unit1Graph: [
        [1575316924000, 1200],
        [1575403324000, 1200],
        [1575489724000, 1210],
        [1575576124000, 1215],
        [1575662524000, 1220],
        [1575748924000, 1250]
    ],
    latestValues: []
};

export const mockAnalyticsTaeManifold: IanalyticsTAE = {
    photoUrl:
        'https://acstadapp10000948.blob.core.windows.net/thumbnails/e0d3774d-8e2f-45ae-87fa-3ef51f88ab93.jpg',
    mainCategoryID: '55ec8036-f4cb-4fc9-8ed7-87eaaed8df13',
    mainCategoryName: 'Manifold',
    installBaseID: 'e840f5be-5703-4b8b-bf1c-2c0ff597baaa',
    installBaseName:
        'Manifold: Automatic: Nitrogen: HPxHP: BeaconMedaes: MNE-HH2x2-N2-CT NFPA',
    installBaseLocation: 'Main Building : First Floor',
    installBaseSerialNumber: 'DB TAE Manifold',
    systemRunHours: 0.0,
    unit1Hours: 0.0,
    unit2Hours: 0.0,
    dryer1Mode: '',
    dryer2Mode: '',
    dewpoint: 0,
    coLevel: 0,
    receiverPressure: 0,
    coAlarm: false,
    lagAlarm: false,
    dewpointAlarm: false,
    leftBankPressureBar: 0,
    rightBankPressureBar: 0,
    deliveryPressureBar: 0,
    reservePressureBar: 0,
    leftBankPressurePSI: 343,
    rightBankPressurePSI: 398,
    deliveryPressurePSI: 54,
    reservePressurePSI: 2695,
    leftBankPressureKPA: 0,
    rightBankPressureKPA: 0,
    deliveryPressureKPA: 0,
    reservePressureKPA: 0,
    leftBankEmpty: 0,
    rightBankEmpty: 0,
    leftBankSelectLatch: false,
    reserveInUseAlarm: false,
    reserveLowAlarm: false,
    secondaryLowAlarm: false,
    changeoverAlarm: true,
    gasType: 'O2',
    timeOnCurrentBank: 0,
    timeOnPreviousBank: 0,
    date: '2021-09-16T00:18:05.7535672Z',
    device: {
        guage01: {
            data: [217900.0],
            label: 'Left Bank',
            unit: 7,
            format: dataFormatEnum.pascalToPSITruncated,
            max: 100,
            min: 0,
            medium: 40,
            high: 80
        },
        guage02: {
            data: [157900.0],
            label: 'Right Bank',
            unit: 7,
            format: dataFormatEnum.pascalToPSITruncated,
            max: 100,
            min: 0,
            medium: 40,
            high: 80
        },
        guage03: {
            data: [297900.0],
            label: 'Reserve',
            unit: 7,
            format: dataFormatEnum.pascalToPSITruncated,
            max: 100,
            min: 0,
            medium: 40,
            high: 80
        },
        guage04: {
            data: [197900.0],
            label: 'Delivery',
            unit: 7,
            format: dataFormatEnum.pascalToPSITruncated,
            max: 100,
            min: 0,
            medium: 40,
            high: 80
        },
        gasType: 'O2',
        isServiceDue: true,
        runningHours: 0.0,
        loadedHours: 3320.0,
        alarms: 'Changeover'
    }
};

export const mockAnalyticsQuote = {
    total: 23,
    new: 2,
    approved: 5,
    inProgress: 10,
    rejected: 4,
    unquotedLeads: 85
};

export const mockAnalyticsData = {
    inspection: [mockAnalyticsInspection],
    pmp: mockAnalyticsPMP,
    tae: [mockAnalyticsTaeManifold],
    taeDashboard: [],
    quote: mockAnalyticsQuote,
    smartlinks: [
        mockAnalyticsSmartlinkAirSystem,
        mockAnalyticssmartlinkAirSystemTwo
    ]
};
