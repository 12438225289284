/*
 * Edit Code Modal and Container
 */

import { connect } from 'react-redux';

import {
    saveCode,
    updateCode,
    toggleEditCodeModal
} from '../../actions/manageCodeActions';
import EditCodeForm from './EditCodeForm';
import { initialDeficiencyCode } from '../../reducers/initialState';
import {
    clearSelectedCodeID,
    setCodeFormValues,
    updateCodeFormValue
} from '../../actions/manageCodeActions';
import { WithCommonModal } from '../common/WithCommonModal';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
}

const defaultProps = {
    className: 'edit-code'
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedCode =
        state.manageCode.deficiencyCodesByID[state.manageCode.selectedCodeID] ||
        initialDeficiencyCode;

    let title;
    if (selectedCode && selectedCode.id) {
        title = ownProps.t(`editStandardText`);
    } else {
        title = ownProps.t(`addStandardText`);
    }
    return {
        ...defaultProps,
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageCode.showEditCodeModal,
        selectedCode,
        formValues: state.manageCode.codeFormValues,
        productInfo: state.productInfo,
        title
    };
};

export const EditCodeModal = withTranslation('manageCode')(
    connect(mapStateToProps, {
        handleCancel: toggleEditCodeModal,
        handleSubmit: saveCode,
        handleEdit: updateCode,
        clearSelectedCodeID,
        updateFormValue: updateCodeFormValue,
        setFormValues: setCodeFormValues,
        onHide: toggleEditCodeModal
    })(WithCommonModal(EditCodeForm))
);
