import {
    EventType,
    InteractionRequiredAuthError,
    IPublicClientApplication
} from '@azure/msal-browser';
import { LogLevel } from '@azure/msal-common';
import Axios, { AxiosRequestConfig } from 'axios';

export const MSAL_SCOPES = {
    MMG: `https://${process.env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_MSAL_APP_ID_URI}/read`,
    OPENID: 'openid'
};

export const loginRequest = {
    scopes: [MSAL_SCOPES.MMG]
};

export const MSAL_LOGOUT_URL = `https://login.microsoftonline.com/common/oauth2/logout`;

export const forgotPasswordRequest = {
    authority: `https://${process.env.REACT_APP_MSAL_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_MSAL_FORGET_PASSWORD_POLICY}`,
    scopes: [
        `https://${process.env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/mmg-api/read`
    ]
};

export const msalRequest = {
    scopes: [MSAL_SCOPES.MMG],
    extraQueryParameters: {}
};

// Simple debug method for msal debugging, easily clean up console when you don't want to see 100 msal messages
export const debugMSAL = (error: any) => {
    const showLogs = false;

    if (showLogs) {
        console.info(error);
    }
};

// The single instance of msal for the whole app (hopefully)
export let msalApp: IPublicClientApplication;

// Setter method for the PCA - should be set from top level - App.tsx
export const setPCA = (_pca: IPublicClientApplication) => {
    debugMSAL(_pca);
    msalApp = _pca;

    /*
     *   After you complete a successful password reset, you recieve back a token, but
     *   this token cannot be used for sign-in. So check the tfp field in the payload for the
     *   name of the password reset policy, if you find it, logout and clear the token so the user
     *   won't get an error when trying to sign in after changing their password.
     */
    msalApp.addEventCallback((event: any) => {
        if (event.eventType === EventType.LOGIN_SUCCESS) {
            if (
                event.payload.idTokenClaims['tfp'] ===
                `${process.env.REACT_APP_MSAL_FORGET_PASSWORD_POLICY}`
            ) {
                msalApp.logout({
                    account: event.payload.account
                });
            }
        }
    });
};

// Config settings for the PCA - set in index.tsx
// Information about all of the config options and what they do can be found here
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
export const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_MSAL_CLIENT_ID}`,
        authority: `https://${process.env.REACT_APP_MSAL_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_MSAL_SIGNIN_POLICY}`,
        knownAuthorities: [
            `${process.env.REACT_APP_MSAL_TENANT_NAME}.b2clogin.com`
        ],
        postLogoutRedirectUri: `${process.env.REACT_APP_HOST_DOMAIN}`,
        redirectUri: `${process.env.REACT_APP_HOST_DOMAIN}`,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (
                level: LogLevel,
                message: string,
                containsPii: boolean
            ): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        debugMSAL(message);
                        return;
                    case LogLevel.Info:
                        debugMSAL(message);
                        return;
                    case LogLevel.Verbose:
                        debugMSAL(message);
                        return;
                    case LogLevel.Warning:
                        debugMSAL(message);
                        return;
                }
            },
            piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 9000,
        asyncPopups: false
    }
};

const delay = (t: number) => new Promise(resolve => setTimeout(resolve, t));

export const acquireToken = () => {
    debugMSAL('AcquireToken');
    // if(!msalApp.getActiveAccount())
    // {
    //     debugMSAL('NO active MSAL account');
    //     if(msalApp.getAllAccounts().length > 0){
    //         debugMSAL('Setting active MSAL account');
    //         msalApp.setActiveAccount(msalApp.getAllAccounts()[0]);
    //     }
    // }
    return msalApp.acquireTokenSilent(loginRequest).catch((error: any) => {
        // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
        // due to consent or interaction required ONLY
        debugMSAL(`AcquireTokenSilent: ${error}`);
        const noAccountError =
            error?.errorCode?.indexOf('no_account_error') > -1;

        if (noAccountError) {
            const msalAccounts = msalApp.getAllAccounts();
            if (msalAccounts.length > 0) {
                msalApp.setActiveAccount(msalAccounts[0]);
            } else {
                console.error(
                    'we have reached an unrecoverable error with msal'
                );
                // even redirecting the user to the logout url does not seem to resolve it.
                // Steps to replicate, login as username password.  then when asked to re-login login with Azure
                // window.location.replace(`https://login.microsoftonline.com/common/oauth2/logout`);
            }
        }
        if (error instanceof InteractionRequiredAuthError) {
            return msalApp
                .acquireTokenPopup(loginRequest)
                .catch((errorPopUp: any) => {
                    debugMSAL(`AcquireTokenPopup: ${errorPopUp}`);
                    return msalApp
                        .acquireTokenRedirect(loginRequest)
                        .catch((redirectError: any) => {
                            // if aquireTokenSilent, acquireTokenPop, and aquireTokenRedirect all fail, just log out
                            debugMSAL(`AcquireTokenRedirect: ${redirectError}`);
                            document.dispatchEvent(
                                new CustomEvent('startUserLogoutSessionOnly')
                            );
                        });
                });
        } else {
            // If there is ANY error from MSAL when getting a token, just logout and prevent any weird user experince.
            // Right now this would be because of the app registration migration
            debugMSAL(error);
            document.dispatchEvent(
                new CustomEvent('startUserLogoutSessionOnly')
            );
        }
    });
};

/*
 * check the token before every API call.
 * goal is to redirect to login when needed and avoid displaying errors unless it fails to redirect for some reason.
 * If it is invalid it will try again.  If it is still invalid, redirect to the login page
 * if it is currently redirecting to the login page (logininprogress()) then it will wait
 *
 */
// let userChangeCount = 0;
export const msalFetch = (
    url: string,
    options: AxiosRequestConfig,
    isRetry?: boolean
): Promise<any> => {
    // If msalApp is initialized then delayTime is 0, otherwise it's currently at 250 to wait for the constructor in App to finish running.
    const delayTime = msalApp === undefined ? 250 : 0;

    return delay(delayTime).then(() => {
        return acquireToken()
            .then((authResponse: any) => {
                if (
                    !authResponse ||
                    (authResponse && !authResponse.accessToken)
                ) {
                    console.error('msalFetch error', authResponse);
                    throw new Error(
                        'failed to retrieve token.  Likely because 3rd party cookies are disabled'
                    );
                }
                const headers = {
                    ...options,
                    headers: {
                        Authorization: `Bearer ${authResponse.accessToken}`
                    }
                };
                return Axios(url, headers);
            })
            .catch((error: any) => {
                console.error(
                    'msal fetch error: ' + url,
                    error,
                    JSON.stringify(options)
                );
                return delay(3000).then(() => {
                    // wait for the redirect before returning the error to the calling function.
                    throw error;
                });
            });
    });
};
