import * as React from 'react';
import { FormGroup, ControlLabel, Button, Col } from 'react-bootstrap';
import { IfacilityContract } from '../../models';
import { FormUtil } from '../common/FormUtil';
import {
    myMedGasContractEnum,
    inspectionContractEnum
} from '../../models-enums';

// interface Imeta extends IbaseFormMeta {
//   toggleModal: () => void;
//   t: TFunction;
//   buttonLabel: string;
//   colorButton: string;
// }
interface Iprops {
    meta: any;
    handler: any;
    pristine: boolean;
    errors: any;
    submitted: boolean;
}

export const FacilityContractControl = (props: Iprops) => {
    const addPart = () => {
        props.meta.toggleModal();
    };

    const { pristine, errors, submitted } = props;
    const { t } = props.meta;
    const contract = props.handler().value as IfacilityContract;

    const myMedGasContract = contract.myMedGasContract
        ? props.meta.t(myMedGasContractEnum[contract.myMedGasContract])
        : '';
    const inspectionContract = contract.inspectionContract
        ? props.meta.t(inspectionContractEnum[contract.inspectionContract])
        : '';
    return (
        <Col xs={props.meta.colWidth}>
            <FormGroup
                validationState={FormUtil.getValidationState(
                    pristine,
                    errors,
                    submitted
                )}
            >
                <ControlLabel>{props.meta.label}</ControlLabel>

                {contract && (
                    <div>
                        <p>
                            <b>MyMedGas: </b> {myMedGasContract}
                        </p>
                        <p>
                            <b>Inspection: </b> {inspectionContract}
                        </p>
                    </div>
                )}
                {!contract && <h5>{t('facilityForm.noContracts')}</h5>}
                <Button
                    onClick={addPart}
                    // bsStyle={props.meta.colorButton}
                    bsStyle="default"
                    className="pull-right"
                    disabled={props.handler().disabled}
                >
                    {props.meta.buttonLabel}
                </Button>
            </FormGroup>
        </Col>
    );
};
