/*
 * Measurement Point Result List - list the answers from the most recent result
 */

import * as React from 'react';
import { TFunction } from 'i18next';

import { ListGroup, ListGroupItem, Col, Button, Row } from 'react-bootstrap';
import {
    ImeasurementPointResult,
    ImeasurementPointAnswer,
    ImeasurementPoint
} from '../../models';
import { getMeasurementPointList } from '../../actions/manageMeasurementPointListsActions';
import { constants } from '../../constants/constants';
import { initialMeasurementPoint } from '../../reducers/initialState';
import { clearHistoricalResultID } from '../../actions/measurementPointResultsActions';
import moment from 'moment';

interface Props {
    selectedItem: ImeasurementPointResult;
    selectedMeasurementPointResultID: string;
    colorButton: string;
    t: TFunction;
    toggleModal: () => void;
    getMeasurementPointList: typeof getMeasurementPointList;
    measurementPointsByID: { [key: string]: ImeasurementPoint };
    locationString: string;
    clearHistoricalResultID: typeof clearHistoricalResultID;
    getMeasurementPointListResult: (
        id: string
    ) => Promise<ImeasurementPointResult>;
    loading: boolean;
}

const AnswerListItem = (
    mpAnswer: ImeasurementPointAnswer,
    measurementPoint: ImeasurementPoint,
    t: TFunction
) => {
    let AnswerElement = () => <div>no answer</div>;
    if (mpAnswer.numericValue !== undefined) {
        AnswerElement = () => <div>{mpAnswer.numericValue}</div>;
    }
    if (mpAnswer.pass !== undefined) {
        if (mpAnswer.pass === constants.measurementPointPassFailTypes.fail) {
            AnswerElement = () => (
                <div
                    style={{
                        textTransform: 'uppercase',
                        color: constants.colors.red
                    }}
                >
                    {t('fail')}
                </div>
            );
        } else if (
            mpAnswer.pass === constants.measurementPointPassFailTypes.pass
        ) {
            AnswerElement = () => (
                <div
                    style={{
                        textTransform: 'uppercase',
                        color: constants.colors.green
                    }}
                >
                    {t('pass')}
                </div>
            );
        } else {
            AnswerElement = () => (
                <div style={{ textTransform: 'uppercase' }}>{t('N/A')}</div>
            );
        }
    }
    if (mpAnswer.textValue !== undefined) {
        AnswerElement = () => <div>{mpAnswer.textValue}</div>;
    }
    if (mpAnswer.dateValue !== undefined) {
        AnswerElement = () => (
            <div>
                {moment
                    .utc(mpAnswer.dateValue)
                    .local(true)
                    .format('DD-MM-YY')}
            </div>
        );
    }

    if (
        mpAnswer.measurementPointSelectOptionID !== undefined &&
        measurementPoint.selectOptions
    ) {
        const { selectOptions } = measurementPoint;
        const selectedOption = selectOptions.find(
            option => option.id === mpAnswer.measurementPointSelectOptionID
        ) || { label: '' };

        AnswerElement = () => <div>{selectedOption.label}</div>;
    }

    return (
        <ListGroupItem key={mpAnswer.measurementPointID}>
            <Row>
                <Col xs={8}>
                    {measurementPoint.label} {measurementPoint.guideText}
                </Col>
                <Col
                    xs={4}
                    style={{ fontWeight: 'bolder', textAlign: 'right' }}
                >
                    <AnswerElement />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <small>{mpAnswer.notes}</small>
                </Col>
            </Row>
        </ListGroupItem>
    );
};

export class MPResultList extends React.Component<Props, {}> {
    componentDidMount() {
        if (this.props.selectedItem.manualOverride === true) {
            return; //  do not get mplist or the result
        }
        if (this.props.selectedItem.measurementPointAnswers.length === 0) {
            this.props
                .getMeasurementPointListResult(this.props.selectedItem.id)
                .then(result => {
                    this.props.getMeasurementPointList(
                        result.measurementPointListID
                    );
                });
        } else {
            this.props.getMeasurementPointList(
                this.props.selectedItem.measurementPointListID
            );
        }
    }

    render() {
        const { t, selectedItem } = this.props;
        if (selectedItem.manualOverride === true) {
            return (
                <div>
                    <h4 style={{ padding: '15px' }}>
                        {t(
                            'No measurement points.  Device status was manually added.'
                        )}
                    </h4>
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle={this.props.colorButton}
                            type="button"
                            onClick={this.props.toggleModal}
                        >
                            {t('common:done')}
                        </Button>
                    </Col>
                </div>
            );
        }
        if (this.props.loading) {
            return (
                <div>
                    <h4 style={{ padding: '15px' }}>{t('Loading...')}</h4>
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle={this.props.colorButton}
                            type="button"
                            onClick={this.props.toggleModal}
                        >
                            {t('common:done')}
                        </Button>
                    </Col>
                </div>
            );
        }
        return (
            <div>
                <h5 style={{ paddingLeft: '15px', paddingBottom: '15px' }}>
                    {this.props.locationString}
                </h5>
                <ListGroup>
                    {this.props.selectedItem.measurementPointAnswers.map(
                        mpAnswer => {
                            const measurementPoint =
                                this.props.measurementPointsByID[
                                    mpAnswer.measurementPointID
                                ] || initialMeasurementPoint;
                            if (measurementPoint.id.length === 0) {
                                // jfbloom22 removed this 5/2020 because it will be 0 on initial render.
                                // a better solution is to use a selector to grab all the MPs for this list
                                // and wait to render until we have some MPs, this requires two loops because
                                // each MP is related to a MeasurementPointTab
                                // console.error(
                                //   'missing measurement point',
                                //   mpAnswer.measurementPointID
                                // );
                                return null;
                            }
                            return AnswerListItem(
                                mpAnswer,
                                measurementPoint,
                                t
                            );
                        }
                    )}
                </ListGroup>
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle={this.props.colorButton}
                        type="button"
                        onClick={() => {
                            this.props.toggleModal();
                            this.props.clearHistoricalResultID();
                        }}
                    >
                        {t('common:done')}
                    </Button>
                </Col>
            </div>
        );
    }
}
