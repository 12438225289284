import * as types from '../actions/actionTypes';

import {
    IcontactFacility,
    IfacilityContact,
    Ioption,
    Iuser,
    ImanageFacilityReducer
} from '../models';
import {
    createFormValuesWithName,
    createSelectedIDWithName,
    createShowModalWithNamedType,
    createTableFiltersWithName
} from './commonReducers';
import { keyBy, map, omit, orderBy, pickBy } from 'lodash';

import { cleanUser } from './manageUserReducer';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { initialContact } from './initialState';
import { IinitialState } from '.';

const cleanContactFacilities = (contacts: Array<any>): IcontactFacility[] => {
    return map(contacts, (item: any) => {
        return {
            facilityID: '',
            ...pickBy(item, (property, key) => property !== null)
        };
    });
};

export const cleanFacilityContact = (
    facilityContact: any
): IfacilityContact => {
    const sanitized = omit(facilityContact, ['user']);
    return {
        ...initialContact,
        ...pickBy(sanitized, (property, key) => property !== null),
        contactFacilities: cleanContactFacilities(sanitized.contactFacilities)
    };
};

/*
 * MANAGE FACILITY SELECTORS
 */

export const selectUsersAndContactsToOptions = (
    usersAndContacts: any
): Ioption[] => {
    if (!usersAndContacts) {
        return [];
    }
    return map(usersAndContacts, (item: Iuser | IfacilityContact) => {
        return {
            value: item.id,
            label: item.email || 'unknown'
        };
    });
};

export const selectManageFacilityTableFilters = (state: IinitialState) =>
    state.manageFacility.tableFilters;

export const selectFacilities = (state: IinitialState) => state.facilities;
export const selectUser = (state: IinitialState) => state.user;

// Pre-filtered by CountryID on user's profile

const selectFacilityContactsByID = (state: IinitialState) =>
    state.manageFacility.contacts;
const selectUsersByID = (state: IinitialState) => state.manageUser.usersByID;

export const selectFacilityContactsForTable = createSelector(
    [selectFacilityContactsByID, selectUsersByID],
    (facilityContactsByID, usersByID) => {
        return orderBy(
            map(facilityContactsByID, contact => {
                if (contact.userID && contact.userID.length) {
                    const user = usersByID[contact.userID];
                    // if related to a user, use the properties from the user rather than the contact
                    if (user) {
                        return {
                            ...contact,
                            firstName: user.first,
                            lastName: user.last,
                            title: user.position,
                            email: user.email,
                            phone: user.phone
                        };
                    }
                }
                return contact;
            }),
            'lastName'
        );
    }
);

/*
 * ********** REDUCERS
 */

/**
 * MANAGE CONTACT
 */
const contacts = (
    state: { [key: string]: IfacilityContact } = {},
    action: any
): { [key: string]: IfacilityContact } => {
    switch (action.type) {
        case types.GET_CONTACTS_BY_FACILITY_SUCCESS:
            return {
                ...keyBy(
                    map(action.payload, contact =>
                        cleanFacilityContact(contact)
                    ),
                    'id'
                )
            };
        case types.LEADS_MANAGE_SUCCESS:
            return {
                ...keyBy(
                    map(action.payload, contact =>
                        cleanFacilityContact(contact)
                    ),
                    'id'
                )
            };
        case types.ADD_CONTACT_SUCCESS:
            return {
                ...state,
                [action.payload.id]: {
                    ...cleanFacilityContact(action.payload)
                }
            };
        case types.DELETE_CONTACT_FACILITY_SUCCESS: {
            return omit(state, action.payload);
        }
        case types.UPDATE_CONTACT_SUCCESS:
            return {
                ...state,
                [action.payload.id]: { ...action.payload }
            };
        default:
            return state;
    }
};

/*
 * stores the contact currently being edited
 */
const selectedContactReducer = (
    state: IfacilityContact = initialContact,
    action: any
): IfacilityContact => {
    switch (action.type) {
        case types.UPDATE_SELECTED_CONTACT: {
            return action.contact;
        }
        case types.DELETE_CONTACT_FACILITY_SUCCESS: {
            if (state.id === action.payload) {
                return initialContact;
            }
        }
        case types.ADD_CONTACT_SUCCESS: {
            return initialContact;
        }
        default:
            return state;
    }
};

const foundUsersOrContactsByID = (
    state: { [key: string]: Iuser | IfacilityContact } = {},
    action: any
): { [key: string]: Iuser | IfacilityContact } => {
    switch (action.type) {
        case types.FIND_USERS_OR_CONTACT_BY_EMAIL_SUCCESS: {
            const cleanedContactsAndFacilities = map(action.payload, item => {
                if (item.first) {
                    return cleanUser(item);
                }
                return cleanFacilityContact(item);
            });
            return keyBy(cleanedContactsAndFacilities, 'id');
        }
        case types.CLEAR_USERS_MATCHED_EMAIL:
            return {};
        default:
            return state;
    }
};

/**
 * MANAGE FACILITY REDUCERS
 */
const manageFacility = combineReducers<ImanageFacilityReducer>({
    contacts,
    selectedContactID: (state, action) =>
        createSelectedIDWithName(state, action, 'CONTACT_ID'),
    selectedContact: selectedContactReducer,
    foundUsersOrContactsByID,
    facilityFormValues: (state, action) =>
        createFormValuesWithName(state, action, 'MANAGE_FACILITY'),
    facilityContractFormValues: (state, action) =>
        createFormValuesWithName(state, action, 'FACILITY_CONTRACT'),
    showFacilityContractModal: createShowModalWithNamedType(
        'FACILITY_CONTRACT'
    ),
    selectedFacilityID: (state, action) =>
        createSelectedIDWithName(state, action, 'FACILITY_ID'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_FACILITY')
});

export default manageFacility;
