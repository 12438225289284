import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { TFunction } from 'i18next';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, match } from 'react-router-dom';
import { constants } from '../../constants/constants';
import { IdataFormatPreference, IinstallBase } from '../../models';
import { dataFormatEnum } from '../../models-enums';
import { Banner } from '../common/Banner';
import { mockAnalyticsSmartlinkAirSystem } from './mockAnalytics';
import {
    getBarChartOptionsDefault,
    getBarChartOptions,
    getGuageChartOptions,
    getLineChartOptions,
    getLineChartOptionsDefault,
    getGuageChartOptionsDefault
} from './Utilities';
import {
    setSelectedProductID,
    toggleInstallContactModal
} from '../../actions/manageInventoryActions';
import InstallContactModal from '../manageInventory/InstallContactModal';
import Placeholder from '../../images/Photo_Placeholder.png';

interface Iprops {
    t: TFunction;
    match: match;
    // this type (IanalyticsSmartLink | IanalyticsTAE) isn't working as
    // IanalyticsTAE doesn't have a device object
    smartlink: any;
    installBase: IinstallBase;
    isDemoMode: boolean;
    dataFormatPreference: IdataFormatPreference;
    setSelectedProductID: typeof setSelectedProductID;
    toggleInstallContactModal: typeof toggleInstallContactModal;
}
interface Istate {
    chartOptions: Partial<Highcharts.Options>;
    chartLineOptions: Partial<Highcharts.Options>;
    chartPressureOptions: Partial<Highcharts.Options>;
    chartPressureSecondOptions: Partial<Highcharts.Options>;
    chartTemperatureOptions: Partial<Highcharts.Options>;
}
export default class SmartLinkDetails extends Component<Iprops, Istate> {
    static defaultProps = {
        smartlink: mockAnalyticsSmartlinkAirSystem
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            chartOptions: getBarChartOptionsDefault({ t: this.props.t }),
            chartLineOptions: getLineChartOptionsDefault({ t: this.props.t }),
            chartPressureOptions: getGuageChartOptionsDefault({
                t: this.props.t
            }),
            chartPressureSecondOptions: getGuageChartOptionsDefault({
                t: this.props.t
            }),
            chartTemperatureOptions: getGuageChartOptionsDefault({
                t: this.props.t
            })
        };
    }
    componentDidMount() {
        this.handleUpdateDataDetails();
    }
    handleUpdateDataDetails = () => {
        const { smartlink, t, dataFormatPreference } = this.props;
        this.setState({
            chartOptions: getBarChartOptions(
                t,
                dataFormatPreference,
                smartlink.device?.bar01
            ),
            chartPressureOptions: getGuageChartOptions(
                t,
                dataFormatPreference,
                smartlink.device?.guage01
            ),
            chartLineOptions: getLineChartOptions(
                t,
                dataFormatPreference,
                smartlink.device?.line01
            ),
            chartPressureSecondOptions: getGuageChartOptions(
                t,
                dataFormatPreference,
                smartlink.device?.guage02
            ),
            // series: secondPressureData?.series,
            // yAxis: secondPressureData?.yAxis,
            // subtitle: secondPressureData?.subtitle,
            // title: secondPressureData?.title
            chartTemperatureOptions: getGuageChartOptions(
                t,
                dataFormatPreference,
                smartlink.device?.guage03
            )
        });
    };
    render() {
        const colorButton = 'primary';
        const {
            device,
            installBaseName,
            photoUrl,
            installBaseSerialNumber,
            installBaseLocation
        } = this.props.smartlink;
        const { t } = this.props;
        const getAlarms = device?.alarms?.split(',') || [];
        const productID = this.props.installBase?.productID;

        return (
            <div className="dashboard-container">
                <Banner
                    title={''}
                    img={constants.tiles[0].srcBanner}
                    color={'dark'}
                ></Banner>
                <div className="dashboard-scrollable">
                    <Col
                        xs={12}
                        lg={12}
                        style={{
                            width: '-webkit-fill-available',
                            margin: '15px'
                        }}
                        className="container-details form-group smart-links-analytics"
                    >
                        <Row>
                            <Col xs={12} lg={10}>
                                <h4 className="device-title">
                                    {installBaseName}
                                </h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="device-subTitle ">
                                {`Updated: ${moment(new Date()).format(
                                    'hh:mm a, MM/DD/YYYY'
                                )}`}
                            </Col>
                        </Row>
                        <Row style={{}}>
                            <Col
                                xs={12}
                                md={6}
                                lg={3}
                                style={{ padding: '22px', maxHeight: '368px' }}
                            >
                                <img
                                    style={{
                                        height: 'auto',
                                        maxHeight: '368px',
                                        maxWidth: '205px',
                                        width: '100%'
                                    }}
                                    src={photoUrl || Placeholder}
                                    alt="devices"
                                />
                                <Col
                                    xs={12}
                                    style={{
                                        marginTop: '24px',
                                        color: '#A2ACB4',
                                        fontSize: '16px'
                                    }}
                                >
                                    <p>{`Serial Number: ${installBaseSerialNumber}`}</p>
                                    <p
                                        style={{ marginTop: '16px' }}
                                    >{`Location Main: ${installBaseLocation}`}</p>
                                </Col>
                            </Col>
                            <Col xs={12} lg={7} md={6}>
                                <Row
                                    style={{
                                        borderBottom: '1px solid #EEEEEE'
                                    }}
                                >
                                    <Col xs={12} lg={4}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={
                                                this.state.chartPressureOptions
                                            }
                                            {...this.props}
                                        />
                                    </Col>
                                    <Col
                                        xs={12}
                                        lg={4}
                                        style={{
                                            borderRight: '1px solid #EEEEEE'
                                        }}
                                    >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={
                                                this.state
                                                    .chartPressureSecondOptions
                                            }
                                            {...this.props}
                                        />
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={
                                                this.state
                                                    .chartTemperatureOptions
                                            }
                                            {...this.props}
                                        />
                                    </Col>
                                </Row>
                                <Col xs={12} lg={6}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={this.state.chartOptions}
                                        {...this.props}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={this.state.chartLineOptions}
                                        {...this.props}
                                    />
                                </Col>
                            </Col>
                            <Col xs={12} lg={2} md={6}>
                                <button
                                    type="button"
                                    className="request-service"
                                    onClick={() => {
                                        this.props.toggleInstallContactModal();
                                        this.props.setSelectedProductID(
                                            productID
                                        );
                                    }}
                                >
                                    Request Service
                                </button>
                                {getAlarms.map(
                                    (item: string, index: number) => {
                                        if (item) {
                                            return (
                                                <div
                                                    key={index}
                                                    className="alarms"
                                                >
                                                    {`Alarms: ${item}`}
                                                </div>
                                            );
                                        }

                                        return null;
                                    }
                                )}
                            </Col>
                        </Row>
                        <Row style={{ display: 'flex' }}>
                            <Col lg={9} xs={12} className="summary">
                                <Col
                                    xs={12}
                                    lg={4}
                                    style={{
                                        display: 'flex',
                                        margin: '11px 0',
                                        borderRight: '1px solid #A1ACB3'
                                    }}
                                >
                                    <p className="normal-text">Loaded Hours:</p>
                                    <p className="number">
                                        {t(
                                            `common:format.${
                                                dataFormatEnum[
                                                    dataFormatEnum
                                                        .numberTruncate
                                                ]
                                            }`,
                                            {
                                                numberString:
                                                    device?.loadedHours
                                            }
                                        )}
                                    </p>
                                    <p className="hours">hours</p>
                                </Col>
                                <Col
                                    xs={12}
                                    lg={4}
                                    style={{
                                        display: 'flex',
                                        margin: '11px 0',
                                        borderRight: '1px solid #A1ACB3'
                                    }}
                                >
                                    <p className="normal-text">
                                        Current Runtime:
                                    </p>
                                    <p className="number">
                                        {t(
                                            `common:format.${
                                                dataFormatEnum[
                                                    dataFormatEnum
                                                        .numberTruncate
                                                ]
                                            }`,
                                            {
                                                numberString:
                                                    device?.runningHours
                                            }
                                        )}
                                    </p>
                                    <p className="hours">hours</p>
                                </Col>
                                <Col
                                    xs={12}
                                    lg={4}
                                    style={{
                                        display: 'flex',
                                        margin: '11px 0'
                                    }}
                                >
                                    <p className="normal-text">Next Service:</p>
                                    <p className="number">
                                        {t(
                                            `common:format.${
                                                dataFormatEnum[
                                                    dataFormatEnum
                                                        .numberTruncate
                                                ]
                                            }`,
                                            {
                                                numberString:
                                                    device?.nextService
                                            }
                                        )}
                                    </p>
                                    <p className="hours">hours</p>
                                </Col>
                            </Col>
                            <Col
                                xs={12}
                                lg={3}
                                style={{
                                    marginLeft: 'auto',
                                    paddingTop: '60px'
                                }}
                            >
                                <Link
                                    to={`/dashboard?demo=${this.props.isDemoMode}`}
                                >
                                    <Button className="expand-button">
                                        Close Details
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </div>
                <InstallContactModal colorButton={colorButton} />
            </div>
        );
    }
}
