/*
 * Install Base Analytics Table Container makes use of Manage Inventory
 */
import * as React from 'react';

import { IWorkOrder, IinstallBase } from '../../models';
import {
    clearSelectedInstallBaseID,
    clearSelectedProductID,
    getProductInfo,
    initInventory,
    requestQuote,
    setSearchNewProductsSelectedProduct,
    setSelectedInstallBaseID,
    setSelectedProductID,
    setTableFilter,
    toggleEditInstallModal,
    toggleEditProductModal,
    toggleImportInstallModal,
    toggleInstallContactModal,
    toggleSearchNewProductsModal
} from '../../actions/manageInventoryActions';
import {
    getCanEditInstalls,
    getCanRequestQuote
} from '../../reducers/userReducer';
import {
    initialFacility,
    initialInstallBase,
    initialProduct
} from '../../reducers/initialState';

import { InstallBaseActionButtonContainer } from './InstallBaseActionButtonContainer';
import ManageInventory from '../manageInventory/ManageInventory';
import { TableUtil } from '../common/TableUtil';
import { TFunction } from 'i18next';
import { closeAllModals } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { getFacility } from '../../actions/manageLocationActions';
import { getTotal } from '../../reducers/cartReducer';
import { measurementPointResultStatusTypesEnum } from '../../models-enums';
import { toggleShoppingCartModal } from '../../actions/shoppingCartActions';
import { withTranslation, WithTranslation } from 'react-i18next';

import { selectVisibleInstallBases } from '../../reducers/manageInventoryReducer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

const columns = (t: TFunction, colorButton: string) =>
    TableUtil.translateHeaders(
        [
            {
                id: 'expander-toggle',
                expander: true,
                Expander: TableUtil.expanderToggle,
                style: {
                    cursor: 'pointer',
                    textAlign: 'left',
                    userSelect: 'none'
                }
            },
            {
                Header: 'asset',
                accessor: 'product.name',
                minWidth: 200
            },
            {
                Header: 'location',
                id: 'location',
                minWidth: 140,
                accessor: 'locationString'
            },
            {
                Header: 'status',
                id: 'status',
                accessor: ({
                    measurementPointListResultStatus
                }: IinstallBase) => {
                    const statusString =
                        measurementPointResultStatusTypesEnum[
                            measurementPointListResultStatus
                        ];
                    return (
                        <span className={`status ${statusString}`}>{`${t(
                            'manageMeasurementPointLists:' + statusString
                        )}`}</span>
                    );
                },
                width: 180
            },
            {
                Header: 'dashboardAnalytics:#WO',
                id: 'wos',
                width: 60,
                accessor: ({ workOrders }: { workOrders: IWorkOrder[] }) => {
                    return workOrders.length; // using this rather than data.quantity because when we add new installs, we don't want to update the quantity on the product
                }
            },
            {
                Header: '',
                id: 'actions',
                Cell: rowInfo => (
                    <InstallBaseActionButtonContainer
                        t={t}
                        rowInfo={rowInfo}
                        colorButton={colorButton}
                        openSearchFacilityModal={() => {
                            return;
                        }}
                        forMobile={false}
                    />
                ),
                minWidth: 90,
                style: { overflow: 'visible' }
            }
        ],
        t
    );

interface Iprops {
    colorButton: string;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedFacility =
        state.facilities[state.selectedFacilityID] || initialFacility;
    const {
        selectedInstallBaseID,
        productsByID,
        selectedProductID,
        searchNewProductsSelectedProduct
    } = state.manageInventory;
    const { measurementPointResultsByID } = state.measurementPointResults;
    const selectedProduct = productsByID[selectedProductID] || initialProduct;
    const selectedInstallBase =
        state.manageInventory.installBasesByID[selectedInstallBaseID] ||
        initialInstallBase;
    const canEditInstalls = getCanEditInstalls(state.user);
    const canRequestQuote = getCanRequestQuote(state.user);

    const installBases = selectVisibleInstallBases(
        state.manageInventory,
        measurementPointResultsByID,
        selectedFacility,
        state.manageWorkOrder,
        state.productInfo
    );

    return {
        user: state.user,
        loading: selectIsLoading(state),
        productInfo: state.productInfo,
        cartTotal: getTotal(state.manageInventory.cart),
        tableData: installBases,
        selectedProduct,
        searchNewProductsSelectedProduct,
        selectedInstallBase,
        selectedFacility,
        canEditInstalls,
        canRequestQuote,
        bannerTitle: 'dashboardAnalytics:assetStatusTitle',
        columns: columns(ownProps.t, ownProps.colorButton),
        className: 'install-base-analytics',
        manageInventory: state.manageInventory
    };
};

export const InstallBaseAnalyticsTableContainer = withTranslation(
    'manageInventory'
)(
    connect(mapStateToProps, {
        initInventory,
        toggleEditProductModal,
        toggleEditInstallModal,
        toggleShoppingCartModal,
        toggleSearchNewProductsModal,
        closeAllModals,
        getProductInfo,
        getFacility,
        setTableFilter,
        toggleInstallContactModal,
        toggleImportInstallModal,
        requestQuote,
        setSelectedProductID,
        clearSelectedProductID,
        setSelectedInstallBaseID,
        clearSelectedInstallBaseID,
        setSearchNewProductsSelectedProduct
    })(ManageInventory)
);
