/*
 *
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import {
    addWorkOrder,
    clearSelectedWorkOrderID,
    deleteWorkOrder,
    setWorkOrderFormValues,
    toggleEditWorkOrderModal,
    updateWorkOrder,
    updateWorkOrderFormValue
} from '../../actions/manageWorkOrderActions';

import { connect } from 'react-redux';
import { clearSelectedProductID } from '../../actions/manageInventoryActions';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { getIsMobile } from '../../reducers/configReducer';
import { initialProduct, initialWorkOrder } from '../../reducers/initialState';
import EditWorkOrderForm from './EditWorkOrderForm';

interface Iprops {
    colorButton: string;
    disabled?: boolean;
    locationString?: string;
    shouldDisableVendorSelect?: boolean;
    shouldHideTechnicianSelect?: boolean;
    shouldHideDelete?: boolean;
    secondModal?: boolean;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const {
        productsByID,
        selectedProductID,
        selectedInstallBaseID
    } = state.manageInventory;
    const selectedProduct =
        selectedProductID && productsByID[selectedProductID]
            ? productsByID[selectedProductID]
            : initialProduct;

    const selectedWorkOrderId = state.manageWorkOrder.selectedWorkOrderID;

    const selectedWorkOrder =
        state.manageWorkOrder.workOrdersByID[selectedWorkOrderId] ||
        initialWorkOrder;

    let title;
    if (selectedWorkOrder?.id?.length) {
        title = ownProps.t(`manageWorkOrder:editWorkOrder`);
    } else {
        title = ownProps.t(`manageWorkOrder:newWorkOrder`);
    }
    return {
        title,
        className: 'work-order-modal',
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageWorkOrder.showEditWorkOrderModal,
        selectedWorkOrder,
        formValues: state.manageWorkOrder.workOrderFormValues,
        standardOptions: state.productInfo.standardOptions,
        selectedProduct,
        selectedInstallBaseID,
        isMobile: getIsMobile(state),
        secondModal: ownProps.secondModal
    };
};

export const EditWorkOrderModal = withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        onHide: toggleEditWorkOrderModal,
        toggleEditWorkOrderModal,
        addWorkOrder,
        updateWorkOrder,
        deleteWorkOrder,
        clearSelectedWorkOrderID,
        clearSelectedProductID,
        updateFormValue: updateWorkOrderFormValue,
        setFormValues: setWorkOrderFormValues
    })(EditWorkOrderForm)
);
