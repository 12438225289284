/*
 * Manage Report Form
 */

import { Col, Button } from 'react-bootstrap';
import {
    FormGenerator,
    AbstractControl,
    FieldConfig,
    GroupProps,
    FormGroup
} from 'react-reactive-form';
import { forEach } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import * as React from 'react';

import { FormUtil } from '../common/FormUtil';
import { Ireport, IdefaultReport, Ioption } from '../../models';
import { constants } from '../../constants/constants';
import {
    runReport,
    updateReport,
    runDailyRoundsReport
} from '../../actions/manageReportActions';
import { reportTypeEnum } from '../../models-enums';

interface Iprops {
    selectedItem: Ireport;
    selectedDefaultReport: IdefaultReport;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    toggleModal: () => void;
    runReport: typeof runReport;
    runDailyRoundsReport: typeof runDailyRoundsReport;
    updateReport: typeof updateReport;
    jobOptions: Ioption[];
    selectedFacilityID: string;
}
interface Istate {
    fieldConfig: FieldConfig;
}

export class EditReportForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: FormUtil.translateForm(
                this.buildFieldConfig(),
                this.props.t
            )
        };
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    buildFieldConfig = () => {
        const disabled = false;
        // commented out selectedItem until we decide to add support for persisting the selectedItem to redux
        // const { coverLetter, jobID = null } = this.props.selectedItem;
        // const coverLetterValue = coverLetter
        //   ? coverLetter
        //   : this.props.selectedDefaultReport.defaultCoverLetter;
        const jobID = null;
        const coverLetterValue = this.props.selectedDefaultReport
            .defaultCoverLetter;

        // Field config to configure form
        const jobSelectControl = {
            jobID: {
                render: FormUtil.Select,
                meta: {
                    options: this.props.jobOptions,
                    label: 'common:job',
                    colWidth: 12,
                    placeholder: 'jobPlaceholder',
                    name: 'job',
                    disableSort: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: jobID,
                    disabled
                }
            }
        } as { [key: string]: GroupProps };
        const coverLetterControl = {
            coverLetter: {
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'coverLetter',
                    colWidth: 12,
                    name: 'coverLetter',
                    type: 'text',
                    initialContent: coverLetterValue ? coverLetterValue : ''
                },
                options: {
                    validators: FormUtil.validators.requiredRichText
                },
                formState: {
                    value: coverLetterValue,
                    disabled
                }
            }
        } as { [key: string]: GroupProps };
        if (
            this.props.selectedDefaultReport.reportType ===
            reportTypeEnum.dailyRounds
        ) {
            return {
                controls: {
                    startDate: {
                        render: FormUtil.Datetime,
                        meta: {
                            label: 'startDate',
                            colWidth: 12,
                            name: 'startDate'
                        },
                        options: {
                            validators: [
                                FormUtil.validators.requiredWithTrim,
                                FormUtil.validators.isValidMoment
                            ]
                        },
                        formState: {
                            value: '',
                            disabled
                        }
                    },
                    endDate: {
                        render: FormUtil.Datetime,
                        meta: {
                            label: 'endDate',
                            colWidth: 12,
                            name: 'endDate'
                        },
                        options: {
                            validators: [
                                FormUtil.validators.requiredWithTrim,
                                FormUtil.validators.isValidMoment
                            ]
                        },
                        formState: {
                            value: '',
                            disabled
                        }
                    }
                }
            };
        }
        if (
            this.props.selectedDefaultReport.reportType ===
                reportTypeEnum.jobNotes ||
            this.props.selectedDefaultReport.reportType === reportTypeEnum.visit
        ) {
            return { controls: { ...jobSelectControl } };
        } else {
            return { controls: { ...jobSelectControl, ...coverLetterControl } };
        }
    };

    subscribeToValueChanges = () => {
        forEach(this.state.fieldConfig.controls, (input: any, key) => {
            this.subscription = this.formGroup
                .get(key)
                .valueChanges.subscribe((value: any) => {
                    this.onValueChanges(value, key);
                });
        });
    };
    /*
     * (reusable)
     * save to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                this.props.updateReport(this.props.selectedDefaultReport, {
                    [key]: value
                });
                break;
        }
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        if (
            this.props.selectedDefaultReport.reportType ===
            reportTypeEnum.dailyRounds
        ) {
            this.props.runDailyRoundsReport(
                this.formGroup.value,
                this.props.selectedFacilityID,
                false
            );
        } else {
            this.props.runReport(
                this.formGroup.value,
                this.props.selectedDefaultReport.reportType,
                false
            );
        }
    };

    handleRunAndSendExternal = (e: any) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        if (
            this.props.selectedDefaultReport.reportType ===
            reportTypeEnum.dailyRounds
        ) {
            this.props.runDailyRoundsReport(
                this.formGroup.value,
                this.props.selectedFacilityID,
                true
            );
        } else {
            this.props.runReport(
                this.formGroup.value,
                this.props.selectedDefaultReport.reportType,
                true
            );
        }
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        // this.subscribeToValueChanges();
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;

        return (
            <form className={formClassName}>
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.props.toggleModal}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        bsStyle={this.props.colorButton}
                        disabled={this.props.loading}
                        style={{ marginRight: '5px', marginLeft: '5px' }}
                        onClick={(e: React.MouseEvent<Button>) =>
                            this.handleRunAndSendExternal(e)
                        }
                    >
                        {t('runAndSendExternal')}
                    </Button>
                    <Button
                        bsStyle={this.props.colorButton}
                        disabled={this.props.loading}
                        onClick={(e: React.MouseEvent<Button>) =>
                            this.handleSubmit(e)
                        }
                    >
                        {t('save')}
                    </Button>
                </Col>
            </form>
        );
    }
}
