/*
 * Preventative Maintenance Table Container
 */

import { Itile } from '../../models';

import { PreventativeMaintenanceTable } from './PreventativeMaintenanceTable';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import {
    toggleEditWorkOrderModal,
    setSelectedWorkOrderID,
    setTableFilter
} from '../../actions/manageWorkOrderActions';
import { orderBy } from 'lodash';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    t: TFunction;
    colorButton: string;
    currentTile: Itile;
    overrideColumns?: any[];
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const {
        visibleWorkOrdersByID,
        totalPages,
        tableFilters
    } = state.manageWorkOrder;

    return {
        loading: selectIsLoading(state),
        tableData: orderBy(visibleWorkOrdersByID, res => res),
        totalPages,
        tableFilters
    };
};

export const PreventativeMaintenanceTableContainer = connect(mapStateToProps, {
    toggleEditWorkOrderModal,
    setSelectedWorkOrderID,
    setTableFilter
})(PreventativeMaintenanceTable);
