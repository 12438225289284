import * as types from '../actions/actionTypes';

import {
    IWorkOrder,
    Ijob,
    ImanageWorkOrderReducer,
    IpreventativeMaintenanceChecklist,
    Iuser
} from '../models';
import {
    createFormValuesWithName,
    createSelectedIDWithName,
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import { filter, keyBy, map, omit, orderBy, pickBy } from 'lodash';
import initialState, {
    initialPreventativeMaintenanceChecklist,
    initialWorkOrder
} from './initialState';

import { combineReducers } from 'redux';
import { workOrderStatusEnum } from '../models-enums';
import { createSelector } from 'reselect';
import { IinitialState } from '.';

const cleanWorkOrderObject = (workOrder: any): IWorkOrder => {
    // todo remove facility
    const normalizedWorkOrder = omit(workOrder, 'jobWorkOrders');
    const assignedUser = workOrder.jobWorkOrders?.[0]?.job?.assignedUser;
    return {
        ...initialWorkOrder,
        ...pickBy(normalizedWorkOrder, property => property !== null),
        jobNumber: workOrder.jobWorkOrders?.[0]?.job?.jobNumber,
        assignedUser: assignedUser
            ? `${assignedUser.first} ${assignedUser.last}`
            : undefined
    };
};
const cleanPreventativeMaintenanceChecklist = (
    checklist: IpreventativeMaintenanceChecklist
) => {
    return {
        ...initialPreventativeMaintenanceChecklist,
        ...pickBy(checklist, property => property !== null)
    } as IpreventativeMaintenanceChecklist;
};

/*
 * SELECTORS
 */
export const selectWorkOrdersByID = (state: IinitialState) =>
    state.manageWorkOrder.workOrdersByID;

export const getVisibleWorkOrdersByID = (state: IinitialState) =>
    state.manageWorkOrder.visibleWorkOrdersByID;

export const selectWorkOrdersByInstallBaseID = (
    state: ImanageWorkOrderReducer,
    installBaseID: string
) => {
    return filter(state.workOrdersByID, { installBaseID, isDeleted: false });
};

export const selectCompleteWorkOrdersByInstallBaseID = (
    state: ImanageWorkOrderReducer,
    installBaseID: string
) => {
    return filter(state.workOrdersByID, {
        installBaseID,
        isDeleted: false,
        status: workOrderStatusEnum.complete
    });
};

const selectJobWorkOrders = (state: IinitialState) =>
    state.manageJob.jobWorkOrdersByID;

// this selector also lives in manageJobReducer. for some reason
// when importing it from there we get a "before initialization error"
const selectJobWorkOrdersWithWorkOrders = createSelector(
    [selectJobWorkOrders, selectWorkOrdersByID],
    (jobWorkOrdersByID, workOrdersByID) => {
        const withWorkOrders = map(jobWorkOrdersByID, jwo => {
            return {
                ...jwo,
                workOrder: workOrdersByID[jwo.workOrderID] || initialWorkOrder
            };
        });
        return keyBy(withWorkOrders, 'id');
    }
);

// TODO
export const selectVisibleWorkOrdersPopulated = createSelector(
    [getVisibleWorkOrdersByID, selectJobWorkOrdersWithWorkOrders],
    (workOrders, jobWorkOrders) => {
        return map(workOrders, workOrder => {
            const foundJobWorkOrders = filter(jobWorkOrders, {
                isDeleted: false,
                workOrderID: workOrder.id
            });
            return { ...workOrder, jobWorkOrders: foundJobWorkOrders };
        });
    }
);

export const selectVisibleWorkOrdersByID = createSelector(
    [getVisibleWorkOrdersByID, selectJobWorkOrders],
    (workOrders, jobWorkOrders) => {
        let orderedWOs = orderBy(workOrders, wo => wo);
        orderedWOs = orderedWOs.map(wo => {
            const foundJobWorkOrders = filter(jobWorkOrders, {
                isDeleted: false,
                workOrderID: wo.id
            });

            wo.jobWorkOrders = foundJobWorkOrders;
            return wo;
        });
        return orderedWOs;
    }
);

export const selectVisibleWorkOrdersUsers = createSelector(
    [getVisibleWorkOrdersByID],
    workOrders => {
        // return a list of assignedUser and filter out duplicates
        const woList = Object.values(workOrders).filter(wo => wo.assignedUser);
        const woUsers = woList.map(wo => wo.assignedUser as string);
        const cleanUsers = woUsers.filter(
            (user, index) => woUsers.indexOf(user) === index
        );

        return cleanUsers;
    }
);

/*
 * REDUCERS
 */

function workOrdersByIDReducer(
    state: { [key: string]: IWorkOrder } = initialState.manageWorkOrder
        .workOrdersByID,
    action: any
): { [key: string]: IWorkOrder } {
    switch (action.type) {
        case types.LOAD_WORKORDERS_SUCCESS: {
            const newOrders = map(action.payload, (order: IWorkOrder) => {
                return cleanWorkOrderObject(order);
            });
            return { ...state, ...keyBy(newOrders, 'id') };
        }
        case types.GET_INVENTORY_SUCCESS: {
            let newWorkOrders: IWorkOrder[] = [];
            action.inventory.forEach(({ product }: any) => {
                product.installBases.forEach(({ workOrders }: any) => {
                    if (workOrders && workOrders.length) {
                        newWorkOrders = [...newWorkOrders, ...workOrders];
                    }
                });
            });
            return { ...state, ...keyBy(newWorkOrders, 'id') };
        }
        case types.GET_WORK_ORDERS_BY_FACILITY_SUCCESS:
            if (action.payload && action.payload.length) {
                const newFacilityWOs = map(action.payload, wo => {
                    return cleanWorkOrderObject(wo);
                });
                return { ...state, ...keyBy(newFacilityWOs, 'id') };
            }
        case types.ADD_WORKORDER_SUCCESS: {
            return {
                ...state,
                [action.payload.id]: cleanWorkOrderObject(action.payload)
            };
        }
        case types.EDIT_WORKORDER_SUCCESS:
            return { ...state, [action.payload.id]: action.payload };
        case types.REMOVE_WORKORDER_SUCCESS:
            return {
                ...state,
                [action.payload.id]: { ...action.payload, isDeleted: true }
            };
        case types.JOB_MANAGE_SUCCESS: {
            let newWOrders: IWorkOrder[] = [];
            action.jobs.forEach((job: Ijob) => {
                if (job.jobWorkOrders && job.jobWorkOrders.length) {
                    job.jobWorkOrders.forEach(jobWorkOrder => {
                        if (jobWorkOrder.workOrder) {
                            newWOrders = [
                                ...newWOrders,
                                cleanWorkOrderObject(jobWorkOrder.workOrder)
                            ];
                        }
                    });
                }
            });
            return { ...state, ...keyBy(newWOrders, 'id') };
        }
        case types.UNLINK_WORKORDERS_SUCCESS: {
            // remove the jobWorkOrders from the workOrders that were unlinked
            let updatedWorkOrders: IWorkOrder[] = [];
            let currentState = { ...state };

            if (action && action.selection && action.selection.length > 0) {
                updatedWorkOrders = action.selection.map(
                    (workOrder: IWorkOrder) => {
                        currentState[workOrder.id].jobWorkOrders = [];
                    }
                );
            }
            return { ...currentState };
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageWorkOrder.workOrdersByID;
        case types.CLEAR_WORKORDERS:
            return {};
        default:
            return state;
    }
}

function visibleWorkOrdersByID(
    state: { [key: string]: IWorkOrder } = initialState.manageWorkOrder
        .workOrdersByID,
    action: any
): { [key: string]: IWorkOrder } {
    switch (action.type) {
        case types.LOAD_WORKORDERS_SUCCESS: {
            const newOrders = map(action.payload, (order: IWorkOrder) => {
                return cleanWorkOrderObject(order);
            });
            return keyBy(newOrders, 'id');
        }
        case types.EDIT_WORKORDER_SUCCESS: {
            return { ...state, [action.payload.id]: action.payload };
        }
        case types.REMOVE_WORKORDER_SUCCESS: {
            const data = { ...state };
            delete data[action.payload.id];
            return data;
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageWorkOrder.workOrdersByID;
        default:
            return state;
    }
}

function preventativeMaintenanceChecklistsByID(
    state: { [key: string]: IpreventativeMaintenanceChecklist } = initialState
        .manageWorkOrder.preventativeMaintenanceChecklistsByID,
    action: any
): { [key: string]: IpreventativeMaintenanceChecklist } {
    switch (action.type) {
        case types.LOAD_WORKORDERS_SUCCESS: {
            let pmcs: IpreventativeMaintenanceChecklist[] = [];
            action.payload.forEach((workOrder: IWorkOrder) => {
                if (workOrder.preventativeMaintenanceChecklist) {
                    pmcs = [
                        ...pmcs,
                        cleanPreventativeMaintenanceChecklist(
                            workOrder.preventativeMaintenanceChecklist
                        )
                    ];
                }
            });
            return keyBy(pmcs, 'id');
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageWorkOrder
                .preventativeMaintenanceChecklistsByID;
        default:
            return state;
    }
}

function technicians(
    state: string[] = initialState.manageWorkOrder.technicians,
    action: any
) {
    switch (action.type) {
        case types.LOAD_WORKORDER_TECHNICIANS_SUCCESS:
            return action.payload;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageWorkOrder.technicians;
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.WORKORDER_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

function selection(
    state: string[] = initialState.manageWorkOrder.selection,
    action: any
): string[] {
    switch (action.type) {
        case types.WORKORDER_UPDATE_SELECTION:
            return action.payload;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageWorkOrder.selection;
        default:
            return state;
    }
}

const fsesWithWorkOrders = (state: Iuser[] = [], action: any): Iuser[] => {
    switch (action.type) {
        case types.GET_FSES_WITH_WORK_ORDERS_SUCCESS:
            return action.data;
        default:
            return state;
    }
};

const manageWorkOrder = combineReducers<ImanageWorkOrderReducer>({
    workOrdersByID: workOrdersByIDReducer,
    visibleWorkOrdersByID,
    preventativeMaintenanceChecklistsByID,
    technicians,
    totalPages,
    selectedWorkOrderID: (state, action) =>
        createSelectedIDWithName(state, action, 'WORKORDER_ID'),
    selectedPartID: (state, action) =>
        createSelectedIDWithName(state, action, 'PART_ID'),
    showEditWorkOrderModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_WORKORDER'),
    showEditPartModalForm: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_PART'),
    showWorkOrderCloseModal: (state, action) =>
        modalToggleWithName(state, action, 'CLOSING_NOTES'),
    showConfirmSelectJobModal: (state, action) =>
        modalToggleWithName(state, action, 'CONFIRM_SELECT_JOB'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_WORKORDER'),
    workOrderFormValues: (state, action) =>
        createFormValuesWithName(state, action, 'MANAGE_WORKORDER'),
    partFormValues: (state, action) =>
        createFormValuesWithName(state, action, 'MANAGE_WORKORDER_PART'),
    selection,
    selectedFacilityIDForConfirmSelectJob: (state, action) =>
        createSelectedIDWithName(state, action, 'MANAGE_WORKORDER_FACILITY_ID'),
    fsesWithWorkOrders
});

export default manageWorkOrder;
