/*
 * The Preventative Maintenance Checklist Expander
 */
import * as React from 'react';

import ReactTable, { Column, RowInfo, RowRenderProps } from 'react-table';
import { Button } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { orderBy } from 'lodash';
import { useLocation } from 'react-router-dom';

import { IWorkOrder } from '../../models';
import { TableUtil } from '../common/TableUtil';
import {
    toggleEditWorkOrderModal,
    setSelectedWorkOrderID,
    toggleWorkOrderCloseModal,
    getPreventativeMaintenancePointCSV
} from '../../actions/manageWorkOrderActions';
import { workOrderStatusEnum } from '../../models-enums';

interface ExpanderProps extends RowInfo {
    t: TFunction;
    original: IWorkOrder;
    toggleEditWorkOrderModal: typeof toggleEditWorkOrderModal;
    setSelectedWorkOrderID: typeof setSelectedWorkOrderID;
    toggleWorkOrderCloseModal: typeof toggleWorkOrderCloseModal;
    getPreventativeMaintenancePointCSV: typeof getPreventativeMaintenancePointCSV;
}

interface IlocationState {
    readOnly: boolean;
}

export const PreventativeMaintenancePointExpander: React.SFC<ExpanderProps> = (
    props: ExpanderProps
) => {
    const { state } = useLocation<IlocationState>();
    const sortedData = props.original.preventativeMaintenanceChecklist
        ? orderBy(
              props.original.preventativeMaintenanceChecklist
                  .preventativeMaintenancePoints,
              'label'
          )
        : [];
    const ExpanderButtonBar = (eProps: RowRenderProps) => {
        return (
            <span
                className="expander-button-bar text-right"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
                data-readonly={`${state?.readOnly}`}
            >
                <Button
                    bsStyle="link"
                    onClick={() => {
                        if (props.original.preventativeMaintenanceChecklist) {
                            props.getPreventativeMaintenancePointCSV(
                                props.original
                            );
                        } else {
                            console.error(
                                'missing checklist, unable to download pmp checklist csv'
                            );
                        }
                    }}
                >
                    {props.t('downloadTasks')}
                </Button>
                <Button
                    bsStyle="link"
                    onClick={() => {
                        props.setSelectedWorkOrderID(props.original.id);
                        props.toggleEditWorkOrderModal();
                    }}
                >
                    {props.t('viewWorkOrder')}
                </Button>
                {props.original.status !== workOrderStatusEnum.complete && (
                    <Button
                        bsStyle="link"
                        onClick={() => {
                            props.setSelectedWorkOrderID(props.original.id);
                            props.toggleWorkOrderCloseModal();
                        }}
                    >
                        {props.t('closeWorkOrder')}
                    </Button>
                )}
            </span>
        );
    };
    const expanderColumns = TableUtil.translateHeaders(
        [
            {
                Footer: ExpanderButtonBar,
                minWidth: 20,
                id: 'indent-column-button-bar'
            },
            {
                Header: 'manageWorkOrder:task',
                accessor: 'label'
            }
        ],
        props.t
    ) as Column[];

    return (
        <div>
            <ReactTable
                className={'work-order-expander attempts-expander'}
                data={sortedData}
                sortable={false}
                columns={expanderColumns}
                minRows={0}
                showPageSizeOptions={false}
                rowsText="workOrders"
                noDataText={props.t('manageWorkOrder:noDataTextTasks')}
                resizable={false}
                showPagination={sortedData ? sortedData.length >= 10 : false}
            />
        </div>
    );
};
