import * as localForage from 'localforage';
import * as types from './actionTypes';

import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ItempUser, Iuser, ThunkResult } from '../models';
import {
    msalRequest,
    acquireToken,
    msalApp,
    msalFetch,
    forgotPasswordRequest
} from '../components/auth/Auth-Utils';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { differenceBy } from 'lodash';

import API from '../constants/apiEndpoints';
import { Dispatch } from 'redux';
import { IregisterUserFormValues } from '../modelsForms';
import { TrackJS } from 'trackjs';
import { TFunction } from 'i18next';
import { constants } from '../constants/constants';
import { toastr } from 'react-redux-toastr';
import initialState from '../reducers/initialState';
import { checkForLoggedInUser } from './manageUserActions';
import Mixpanel from '../helpers/Mixpanel';
import { getUserAlerts } from './manageUserAlertActions';
import { replaceEmptyStringsWithNull } from '../helpers/cleanObjEmptyStrings';

export const userLogoutHelper = (dispatch: Dispatch) => {
    console.info('logging out');
    // dispatch({ type: types.USER_LOGOUT_SUCCESS });
    // dispatch({ type: 'Offline/RESET_STATE' }); // reset the redux-offline outbox
    // dispatch({ type: '@ReduxToastr/toastr/CLEAN_TOASTR' }); // reset the toastr
    document.dispatchEvent(new Event('userLogout')); // pause the redux persist
    // remove persisted state then do the MSAL logout which causes a redirect to the MSAL logout then to our login screen

    localForage
        .setItem('persist:state-core-care-web', JSON.stringify(initialState))
        .then(message => {
            msalApp.logout();
            Mixpanel.track('User Logout');
            // window.location.replace(MSAL_LOGOUT_URL);
        })
        .catch(error => {
            Mixpanel.track('User Logout Error');
            console.error('error logging out', error);
        });
};

export function userLogin(t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        // return acquireToken().then((authResponse: AuthResponse) => {
        //   const token = authResponse.accessToken;
        //   dispatch({ type: types.AAD_LOGIN, token });

        const axiosOptions: AxiosRequestConfig = {
            method: 'post'
        };
        const url = API.POST.user.login;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    const user = data.data as Iuser;
                    dispatch({ type: types.USER_LOGIN_SUCCESS, user });
                    TrackJS.configure({
                        userId: user.email,
                        version: process.env.REACT_APP_VERSION
                    });

                    // make sure they have at least one Facility (this happens when a user's one facility has been deleted from the system)
                    if (user.facilities.length === 0) {
                        toastr.warning(
                            t('toastMessage:warning'),
                            t('toastMessage:loginMissingFacility'),
                            constants.toastrWarning
                        );
                    } else {
                        // default the the selected customer and facility if they are not already set
                        const defaultFacility = user.facilities[0];

                        if (defaultFacility && defaultFacility.id.length) {
                            dispatch({
                                type: types.GLOBAL_SET_SELECTED_FACILITY_ID,
                                id: defaultFacility.id
                            });
                        } else {
                            console.error('missing facility', defaultFacility);
                        }
                    }

                    // if user has access to all customers and we have not already retrieved them
                    // wait for MSAL (otherwise when logging out, this fires before MSAL loggs out)
                    // if (getCanAccessAllCustomers(user)) {
                    //     setTimeout(() => {
                    //         dispatch(getCustomersWithFacilities());
                    //     }, 500);
                    // }
                    dispatch(getUserAlerts());
                    return data;
                }
            })
            .catch((error: any) => {
                console.error('failed to login', error);
                dispatch({
                    type: types.USER_LOGIN_FAILED,
                    error,
                    axiosOptions
                });
                userLogoutHelper(dispatch);
                // to avoid getting stuck, go ahead and log the user out after a longer pause
                constants.handleError(error, 'login');
                throw error; // intentionally re-throw
            });
        // });
    };
}
export function MSALlogin(t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        return msalApp
            .loginPopup(msalRequest)
            .then(result => {
                msalApp.setActiveAccount(result.account);
                return dispatch(userLogin(t));
            })
            .catch(error => {
                console.info(error);
                // User requested a forgot password
                if (error && error.errorMessage?.indexOf('AADB2C90118') > -1) {
                    return msalApp
                        .loginPopup(forgotPasswordRequest)
                        .catch(e => {
                            console.info(e);
                            // Maybe check for BrowserAuthError: popup_window_error - which means pop ups are blocked, or just try redirect regardless
                            return msalApp
                                .loginRedirect(forgotPasswordRequest)
                                .catch(fprError => {
                                    console.info(fprError);
                                    throw error();
                                });
                        });
                }
            });
    };
}

/*
 * reauthenticate in the background if possible
 * April 24th - this can likely be removed now that we are doing login() right from the error hanndling function in constants.tsx
 */
export const adalReauth = () => {
    acquireToken();
};

export function userLogout(t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        const toastrConfirmOptions = {
            onOk: () => {
                userLogoutHelper(dispatch);
            },
            OnCancel: () => console.info('CANCEL: clicked'),
            okText: t('common:logout'),
            cancelText: t('common:cancel')
        };
        toastr.confirm(t('common:logoutConfirm'), toastrConfirmOptions);
    };
}

export function userLogoutSessionOnly(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({ type: types.USER_LOGOUT_SESSION_ONLY_SUCCESS });
        const event = new CustomEvent('userLogout');
        document.dispatchEvent(event);
        setTimeout(() => {
            msalApp.logout();
        }, 2000);
    };
}

/*
 * Logout dispatches an event to pause persisting redux state
 * then we replace the entire persisted state.  This works better than dispatching USER_LOGOUT_SUCCESS because
 * dispatching that will trigger API calls which try to renew the token and results in a nasty race condition.
 */

export function signUpDirect(
    formValues: IregisterUserFormValues
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const countryID = formValues.countryID.value;
        const phoneCountryCode = formValues.phoneCountryCode?.value;

        const tempUser: ItempUser = {
            ...formValues,
            countryID,
            phoneCountryCode
        };

        return Axios.post(API.POST.user.signup, tempUser)
            .then(data => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.USER_SIGNUP_SUCCESS,
                        user: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                console.log(error);
                dispatch({ type: types.USER_SIGNUP_FAILED, error, tempUser });
                constants.handleError(error, 'sign up');
                throw error; // intentionally re-throw
            });
    };
}

export function adSignup({
    first,
    last,
    email
}: {
    [key: string]: any;
}): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const body = {
            first: first || '',
            last: last || '',
            email: email || ''
        };
        return Axios.post(API.POST.user.adSignup, body)
            .then(data => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.USER_SIGNUP_SUCCESS,
                        user: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.USER_SIGNUP_FAILED, error, body });
                constants.handleError(error, 'sign up');
                throw error; // intentionally re-throw
            });
    };
}

export const toggleEditProfileModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_PROFILE
});

export function updateUserProfile(updatedUser: Iuser): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        dispatch({ type: types.TOGGLE_MODAL_EDIT_PROFILE });
        const { user } = getState();
        // change all empty strings to null
        const cleanData = replaceEmptyStringsWithNull<Iuser>(updatedUser);
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: cleanData
        };

        const url = API.POST.user.updateprofile;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.USER_UPDATE_PROFILE_SUCCESS,
                        user: updatedUser
                    });

                    // if changing country we need to log the user out
                    if (updatedUser.countryID !== user.countryID) {
                        checkForLoggedInUser(user.id, user.id, dispatch);
                    }

                    // if the user changed their facilities we need to log them out
                    if (
                        differenceBy(
                            user.facilities,
                            data.data.facilities,
                            'id'
                        ).length > 0 ||
                        differenceBy(
                            data.data.facilities,
                            user.facilities,
                            'id'
                        ).length > 0
                    ) {
                        checkForLoggedInUser(user.id, user.id, dispatch);
                    } else {
                        toastr.success(
                            'Success',
                            'Saved profile',
                            constants.toastrSuccess
                        );
                    }

                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.USER_UPDATE_PROFILE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'update profile');
                console.error(error);
            });
    };
}

export function deleteUserAccount(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        dispatch({ type: types.TOGGLE_MODAL_EDIT_PROFILE });
        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };

        const url = API.DELETE.user;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                userLogoutHelper(dispatch);
            })
            .catch((error: any) => {
                dispatch(endAjaxCall());
                constants.handleError(error, 'delete profile');
                console.error(error);
            });
    };
}
