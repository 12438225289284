import * as React from 'react';

interface Iprops {
    ifOff: boolean;
    label: string;
    value: string;
    unitMeasurement: string;
}

const UnitCard = ({ ifOff, label, value, unitMeasurement }: Iprops) => (
    <div className="unit-graph" data-off={ifOff}>
        <div className="unit-wrap">
            <div className="unit-dot" aria-hidden="true" data-off={ifOff} />
            <p className="unit-label">{label}</p>
        </div>
        <p className="unit-value">{`${value} ${unitMeasurement}`}</p>
    </div>
);

export default UnitCard;
