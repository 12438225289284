import * as moment from 'moment';
import * as types from '../actions/actionTypes';

import { filter, forEach, keyBy, map, omit, orderBy, pickBy } from 'lodash';
import {
    IWorkOrder,
    Ifacility,
    Ijob,
    IjobWorkOrder,
    ImanageJobReducer,
    ItableFiltersParams,
    Iuser
} from '../models';
import {
    jobStatusEnum,
    jobTypesIdEnum,
    jobTypesIdEnumInverse,
    reportTypeEnum
} from '../models-enums';
import { createTableFiltersWithName } from './commonReducers';
import initialState, {
    initialJob,
    initialJobWorkOrder,
    initialWorkOrder
} from './initialState';

import { TFunction } from 'i18next';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { IinitialState } from '.';
import { FormUtil } from '../components/common/FormUtil';
import { selectWorkOrdersByID } from './manageWorkOrderReducer';

export const isMeasurementBasedJob = (job: Ijob) => {
    if (
        job.jobTypeID === jobTypesIdEnum.Inspection ||
        job.jobTypeID === jobTypesIdEnum.Audit ||
        job.jobTypeID === jobTypesIdEnum.Verification
    ) {
        return true;
    } else {
        return false;
    }
};

export const cleanJobWorkOrder = (jobWorkOrder: any): IjobWorkOrder => {
    const normalizedJobWorkOrder = omit(jobWorkOrder, 'workOrder');
    return {
        ...initialJobWorkOrder,
        ...pickBy(normalizedJobWorkOrder, (property, key) => property !== null)
    };
};

export const cleanJobObject = (job: any): Ijob => {
    return {
        ...initialJob,
        ...pickBy(job, (property, key) => property !== null)
    };
};

/*
 * MANAGE JOB SELECTORS
 */
const selectJobWorkOrders = (state: IinitialState) =>
    state.manageJob.jobWorkOrdersByID;
const selectJobID = (state: IinitialState, props: { jobID: string }) =>
    props.jobID;
const getFSEUsersByID = (state: IinitialState) => state.manageJob.fseUsersByID;
const getJobTableFilters = (state: IinitialState) =>
    state.manageJob.tableFilters;
const getOpenJobsForWorkOrdersByID = (state: IinitialState) =>
    state.manageJob.openJobsForWorkOrdersByID;
const getFacilitiesByID = (state: IinitialState) => state.facilities;

export const selectJobWorkOrdersWithWorkOrders = createSelector(
    [selectJobWorkOrders, selectWorkOrdersByID],
    (jobWorkOrdersByID, workOrdersByID) => {
        const withWorkOrders = map(jobWorkOrdersByID, jwo => {
            return {
                ...jwo,
                workOrder: workOrdersByID[jwo.workOrderID] || initialWorkOrder
            };
        });
        return keyBy(withWorkOrders, 'id');
    }
);

export const selectJobWorkOrdersForJobID = createSelector(
    [selectJobWorkOrdersWithWorkOrders, selectJobID],
    (jobWorkOrdersByID, jobID) => {
        return filter(jobWorkOrdersByID, { jobID });
    }
);

export const selectJobTableFilters = createSelector(
    [getJobTableFilters],
    jobTableFilters => jobTableFilters
);

export const selectWorkOrdersForJobID = createSelector(
    [selectJobWorkOrdersWithWorkOrders, selectJobID],
    (jobWorkOrdersByID, jobID) => {
        const filteredJobWorkOrders = filter(jobWorkOrdersByID, { jobID });
        if (filteredJobWorkOrders) {
            let workOrders: IWorkOrder[] = [];
            forEach(filteredJobWorkOrders, jobWO => {
                if (jobWO.isDeleted === false) {
                    workOrders = [...workOrders, jobWO.workOrder];
                }
            });
            return workOrders;
        } else return [];
    }
);

const getNextJobNumber = (state: IinitialState) =>
    state.manageJob.nextJobNumber;
const selectJobsByID = (state: IinitialState) => state.manageJob.jobsByID;
// const selectJobTableFilters = (state: IinitialState) => state.manageJob.tableFilters
const selectJobTableFiltersProps = (
    state: IinitialState,
    props: { filters: ItableFiltersParams }
) => props.filters;
const selectWOTableJobFiltersProps = (
    state: IinitialState,
    props: {
        filters: {
            IDs: string[];
        };
    }
) => props.filters;

export const selectNextJobNumber = createSelector(
    [getNextJobNumber],
    jobNumber => jobNumber
);

export const selectJobTypeForJobID = createSelector(
    [selectJobsByID, selectJobID],
    (jobsByID, jobID) => {
        const job = jobsByID[jobID];
        if (job) {
            return job.jobTypeID;
        } else {
            return jobTypesIdEnum.Inspection; // temporarily return inspection type while the jobs are loading
        }
    }
);

export const selectFilteredJobs = createSelector(
    [selectJobsByID, selectJobTableFiltersProps],
    (jobsByID, filters) => {
        return filter(jobsByID, item => {
            let shouldInclude = true;
            if (filters.jobTypeID && item.jobTypeID !== filters.jobTypeID) {
                shouldInclude = false;
            }
            if (filters.reportType) {
                if (
                    filters.reportType === reportTypeEnum.agsRebalancing &&
                    item.jobTypeID !== jobTypesIdEnum.agsRebalancing
                ) {
                    shouldInclude = false;
                }
                if (
                    filters.reportType === reportTypeEnum.visit &&
                    item.jobTypeID !== jobTypesIdEnum.Repair &&
                    item.jobTypeID !== jobTypesIdEnum.Maintenance &&
                    item.jobTypeID !== jobTypesIdEnum.warrantyBM &&
                    item.jobTypeID !== jobTypesIdEnum.servicePlan
                ) {
                    shouldInclude = false;
                }
                if (
                    filters.reportType === reportTypeEnum.audit &&
                    item.jobTypeID !== jobTypesIdEnum.Audit
                ) {
                    shouldInclude = false;
                }
                if (
                    filters.reportType === reportTypeEnum.verification &&
                    item.jobTypeID !== jobTypesIdEnum.Verification
                ) {
                    shouldInclude = false;
                }
                if (
                    filters.reportType === reportTypeEnum.commissioning &&
                    item.jobTypeID !== jobTypesIdEnum.Commissioning
                ) {
                    shouldInclude = false;
                }
            }
            if (filters.facilityID && item.facilityID !== filters.facilityID) {
                shouldInclude = false;
            }
            if (
                filters.measurementBasedOnly !== undefined &&
                filters.measurementBasedOnly === true &&
                isMeasurementBasedJob(item) === false
            ) {
                shouldInclude = false;
            }
            if (
                filters.measurementBasedOnly !== undefined &&
                filters.measurementBasedOnly === false &&
                isMeasurementBasedJob(item) === true
            ) {
                shouldInclude = false;
            }
            if (filters.open && item.status === jobStatusEnum.completed) {
                shouldInclude = false;
            }
            if (item.isDeleted === true) {
                shouldInclude = false;
            }

            return shouldInclude;
        });
    }
);

export const selectFilteredFacilitiesForWO = createSelector(
    [getFacilitiesByID, selectWOTableJobFiltersProps],
    (facilities, filters) => {
        // filter facilities by jobIDs
        const filteredFacilities = Object.values(facilities).filter(facility =>
            filters.IDs.includes(facility.id)
        );
        const keyedById = keyBy(filteredFacilities, 'id');

        return keyedById;
    }
);

export const getJobOptionsFromFilters = (
    state: IinitialState,
    { filters }: { filters: ItableFiltersParams },
    t: TFunction
) => {
    const filteredJobs = selectFilteredJobs(state, { filters });
    const jobsWithName = map(filteredJobs, job => {
        const startDate = moment
            .utc(job.startDate)
            .local(true)
            .format('DD-MMM-YY');
        const jobType = t(
            `nsJob:${
                jobTypesIdEnumInverse[
                    job.jobTypeID as keyof typeof jobTypesIdEnumInverse
                ]
            }`
        );
        const name = `${startDate} ${jobType} ${job.jobNumber} (${job.status})`;

        return { ...job, name };
    });

    return FormUtil.convertToOptions(
        orderBy(jobsWithName, res => moment.utc(res.startDate).unix(), 'desc')
    );
};

export const prepJobsOpenRepairAndMaintenanceForOptions = (
    state: IinitialState,
    preFilteredJobs: Ijob[],
    t: TFunction
) => {
    const facilitiesByJobs: {
        [key: string]: Ifacility;
    } = selectFilteredFacilitiesForWO(state, {
        filters: { IDs: preFilteredJobs.map(({ facilityID }) => facilityID) }
    });
    const jobsWithName = map(preFilteredJobs, job => {
        const startDate = moment
            .utc(job.startDate)
            .local(true)
            .format('DD-MMM-YY');
        const facility = () => {
            if (job.facilityID) {
                const selectedFcility = facilitiesByJobs[job.facilityID];

                if (selectedFcility) {
                    return `${selectedFcility?.name} - `;
                }

                return '';
            }

            return '';
        };
        const number = job.jobNumber ? `${job.jobNumber} - ` : '';

        const name = `[${job.status}] ${facility()}${number}${startDate}`;

        return { ...job, name };
    });

    return FormUtil.convertToOptions(
        orderBy(jobsWithName, res => moment.utc(res.startDate).unix(), 'desc')
    );
};

export const selectActiveFseUsersByID = createSelector(
    [getFSEUsersByID],
    fseUsers => {
        const filteredFSE = filter(fseUsers, {
            isDeleted: false,
            isActive: true
        });
        return keyBy(filteredFSE, 'id');
    }
);

export const selectFseUserOptions = createSelector(
    [selectActiveFseUsersByID],
    activeFSEUsers => {
        return FormUtil.convertToOptions(activeFSEUsers);
    }
);

export const selectOpenJobsForWorkOrders = createSelector(
    [getOpenJobsForWorkOrdersByID],
    jobs => Object.values(jobs)
);

// export const fseUserOptions =

/*
 * MANAGE JOB REDUCERS
 */

/*
 * stores the job currently being edited
 */
const selectedJobReducer = (state = initialJob, action: any): Ijob => {
    switch (action.type) {
        case types.UPDATE_SELECTED_JOB: {
            return action.payload;
        }
        case types.JOB_DELETE_SUCCESS: {
            if (state.id === action.payload) {
                return initialJob;
            } else {
                return state;
            }
        }
        case types.JOB_SAVE_SUCCESS: {
            return initialJob;
        }
        default:
            return state;
    }
};
const jobsOpenRepairAndMaintenanceByIDReducer = (
    state: { [key: string]: Ijob } = initialState.manageJob
        .openJobsForWorkOrdersByID,
    action: any
): { [key: string]: Ijob } => {
    switch (action.type) {
        case types.JOBS_REPAIR_MAINTENANCE_SUCCESS: {
            const cleanJobs = map(action.jobs, (job: any) => {
                return { ...state[job.id], ...cleanJobObject(job) };
            });
            const keyedJobs = keyBy(cleanJobs, 'id');

            return { ...state, ...keyedJobs };
        }
        default:
            return state;
    }
};
function jobsByIDReducer(
    state: { [key: string]: Ijob } = initialState.manageJob.jobsByID,
    action: any
): { [key: string]: Ijob } {
    switch (action.type) {
        case types.JOB_MANAGE_SUCCESS: {
            const newJobs = map(action.jobs, (job: Ijob) => {
                return cleanJobObject(job);
            });
            return keyBy(newJobs, 'id');
        }
        case types.JOBS_BY_FACILITY_SUCCESS: {
            const cleanJobs = map(action.jobs, (job: any) => {
                return { ...state[job.id], ...cleanJobObject(job) };
            });
            const keyedJobs = keyBy(cleanJobs, 'id');

            return { ...state, ...keyedJobs };
        }
        case types.JOB_GET_SUCCESS:
        case types.JOB_SAVE_SUCCESS:
        case types.JOB_UPDATE_SUCCESS:
            return { ...state, [action.job.id]: cleanJobObject(action.job) };
        case types.JOB_DELETE_SUCCESS:
            // TODO switch to FE filtering
            // return {...state, [action.payload]: {...state[action.payload], isDeleted: true}}
            return omit(state, action.payload);
        case types.JOB_UPDATE_WORKORDERS_SUCCESS: {
            const udpatedJ = {
                ...state[action.payload.jobID],
                jobWorkOrders: action.payload.jobWorkOrders
            };
            return { ...state, [action.payload.jobID]: udpatedJ };
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageJob.jobsByID;
        default:
            return state;
    }
}

/*
 * jobWorkOrders relates jobs to work orders
 */
function jobWorkOrdersByIDReducer(
    state: { [key: string]: IjobWorkOrder } = initialState.manageJob
        .jobWorkOrdersByID,
    action: any
) {
    switch (action.type) {
        case types.LOAD_WORKORDERS_SUCCESS: {
            let newJobWorkOrders: IjobWorkOrder[] = [];
            forEach(action.payload, (order: IWorkOrder) => {
                if (order.jobWorkOrders) {
                    newJobWorkOrders = [
                        ...newJobWorkOrders,
                        ...map(order.jobWorkOrders, cleanJobWorkOrder)
                    ];
                }
            });
            return { ...state, ...keyBy(newJobWorkOrders, 'id') };
        }
        case types.JOB_MANAGE_SUCCESS: {
            let newJobWorkOrdersB: IjobWorkOrder[] = [];
            forEach(action.jobs, (job: Ijob) => {
                if (job.jobWorkOrders) {
                    newJobWorkOrdersB = [
                        ...newJobWorkOrdersB,
                        ...map(job.jobWorkOrders, cleanJobWorkOrder)
                    ];
                }
            });
            return { ...keyBy(newJobWorkOrdersB, 'id') };
        }
        case types.JOB_UPDATE_WORKORDERS_SUCCESS: {
            if (action.payload.jobWorkOrders) {
                let updatedJobWorkOrders: IjobWorkOrder[] = [];
                forEach(
                    action.payload.jobWorkOrders,
                    (jobWO: IjobWorkOrder) => {
                        updatedJobWorkOrders = [...updatedJobWorkOrders, jobWO];
                    }
                );
                return { ...state, ...keyBy(updatedJobWorkOrders, 'id') };
            } else {
                return state;
            }
        }
        case types.UNLINK_WORKORDERS_SUCCESS: {
            if (action && action.selection && action.selection.length > 0) {
                let currentState = { ...state };
                forEach(action.selection, (wo: IWorkOrder) => {
                    forEach(wo.jobWorkOrders, (jwo: IjobWorkOrder) => {
                        currentState = omit(currentState, jwo.id);
                    });
                });

                return currentState;
            } else {
                return state;
            }
        }
        case types.USER_LOGOUT_SUCCESS: {
            return initialState.manageJob.jobWorkOrdersByID;
        }
        case types.CLEAR_WORKORDERS:
            return {};
        default:
            return state;
    }
}

function totalPages(
    state: number = initialState.manageJob.totalPages,
    action: any
): number {
    switch (action.type) {
        case types.JOB_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageJob.totalPages;
        default:
            return state;
    }
}

function nextJobNumber(
    state: string = initialState.manageJob.nextJobNumber,
    action: any
): string {
    switch (action.type) {
        case types.GET_NEXT_JOB_NUMBER_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
// { [key: string]: Iuser }

function fseUsersByID(
    state: { [key: string]: Iuser } = initialState.manageJob.fseUsersByID,
    action: any
): { [key: string]: Iuser } {
    switch (action.type) {
        case types.GET_FSE_SUCCESS: {
            const keyedFSEUsers = keyBy(action.users, 'id');
            return { ...state, ...keyedFSEUsers };
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageJob.fseUsersByID;
        default:
            return state;
    }
}

const jobManage = combineReducers<ImanageJobReducer>({
    jobsByID: jobsByIDReducer,
    openJobsForWorkOrdersByID: jobsOpenRepairAndMaintenanceByIDReducer,
    fseUsersByID,
    totalPages,
    nextJobNumber,
    selectedJob: selectedJobReducer,
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_JOB'),
    jobWorkOrdersByID: jobWorkOrdersByIDReducer
});

export default jobManage;
