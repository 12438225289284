import * as React from 'react';

import { constants } from '../../constants/constants';

type Iprops = {
    title: string;
    img: any;
    subtitle?: string;
    color: string;
};

export const Banner: React.FunctionComponent<Iprops> = ({
    title,
    img,
    color,
    subtitle,
    children
}) => {
    const colorIndex = `${color}Banner`;

    return (
        <div
            className="banner purple soso"
            style={{
                backgroundColor: color,
                background: `linear-gradient(${
                    constants.colors[
                        colorIndex as keyof typeof constants.colors
                    ]
                },
      ${
          constants.colors[colorIndex as keyof typeof constants.colors]
      }), url(${img}) no-repeat left/cover`
            }}
        >
            <span className="title">{title}</span>
            {subtitle && <span className="subtitle">{subtitle}</span>}
            {children}
        </div>
    );
};
