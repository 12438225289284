/*
 * EditProfileModal
 */

import { connect } from 'react-redux';

import { WithTranslation, withTranslation } from 'react-i18next';
import {
    getFacilitiesByCountry,
    searchFacilitiesForAsyncSelect
} from '../../actions/commonActions';
import { getCodes } from '../../actions/manageCodeActions';
import {
    deleteUserAccount,
    toggleEditProfileModal,
    updateUserProfile
} from '../../actions/userActions';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { initialFacility } from '../../reducers/initialState';
import { FormUtil } from '../common/FormUtil';
import { WithCommonModal } from '../common/WithCommonModal';
import UserProfileForm from './UserProfileForm';
import { getProductInfo } from '../../actions/manageInventoryActions';

interface Iprops {
    colorButton: string;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    // get the most up to date facilities from the facilities reducer
    const facilities = state.user.facilities.map(facility => {
        const foundFacility = state.facilities[facility.id];

        if (!foundFacility) {
            console.error('missing facility', facility);
            return initialFacility;
        } else {
            return foundFacility;
        }
    });

    return {
        className: 'profile-edit',
        title: ownProps.t('editProfileModalTitle'),
        user: state.user,
        loading: selectIsLoading(state),
        facilityOptions: FormUtil.convertToOptions(facilities),
        show: state.showEditProfileModal,
        standards: state.productInfo.standardOptions
    };
};

export default withTranslation('user')(
    connect(mapStateToProps, {
        toggleModal: toggleEditProfileModal,
        onHide: toggleEditProfileModal,
        getFacilitiesByCountry,
        updateUserProfile,
        deleteUserAccount,
        searchFacilitiesForAsyncSelect,
        getCodes,
        getProductInfo
    })(WithCommonModal(UserProfileForm))
);
