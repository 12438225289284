/*
 * Edit Job Redux Connector
 */

import { connect } from 'react-redux';

import { keyBy } from 'lodash';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    getFacilitiesByCountry,
    searchFacilitiesForAsyncSelect
} from '../../actions/commonActions';
import {
    deleteJob,
    getAllOpenJobsForWorkOrders,
    getNextJobNumber,
    saveJob,
    updateSelectedJob
} from '../../actions/manageJobActions';
import { getJobParts } from '../../actions/managePartActions';
import { Ifacility, Ihistory } from '../../models';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import {
    selectActiveFseUsersByID,
    selectFseUserOptions,
    selectNextJobNumber
} from '../../reducers/manageJobReducer';
import { selectJobPartsForJob } from '../../reducers/managePartReducer';
import { FormUtil } from '../common/FormUtil';
import { removeQuery } from '../common/OtherUtils';
import { WithCommonModal } from '../common/WithCommonModal';
import EditJobForm from './EditJobForm';
import { manageJobQueryParamsEnum } from './ManageJob';

interface Iprops {
    colorButton: string;
    queryParams: typeof manageJobQueryParamsEnum;
    history: Ihistory;
    facilities: Ifacility[];
    showCondensedFields?: boolean;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    // job currently being edited, will be defined when adding or editing
    const selectedJob = state.manageJob.selectedJob;
    // will be defined when editing an exisiting job
    const originalJob =
        state.manageJob.jobsByID[ownProps.queryParams.selectedJobID];
    const fseOptions = selectFseUserOptions(state);
    const isEditMode = originalJob?.id.length > 0;
    const nextJobNumber = selectNextJobNumber(state);

    let activeCountry = state.user.countryID;
    if (state.manageUser.activeLocalCountry !== '') {
        activeCountry = state.manageUser.activeLocalCountry;
    }

    let modalTitle;
    if (isEditMode) {
        modalTitle = ownProps.t('nsJob:editModalTitle');
    } else {
        modalTitle = ownProps.t('nsJob:saveModalTitle');
    }
    // define the closeModal function here in mapState so that we can pass it to both the main component and
    // to the onHide which is a WithCommonModal HOC prop
    // yes it is unusual to pass a function in mapState, but it is ok since it does not need dispatch
    const closeModal = () => {
        removeQuery(manageJobQueryParamsEnum.selectedJobID, ownProps.history);
    };

    return {
        // reusable
        user: state.user,
        showCondensedFields: ownProps?.showCondensedFields || false,
        loading: selectIsLoading(state),
        show: ownProps.queryParams.selectedJobID?.length > 0,
        selectedJob,
        originalJob,
        isEditMode,
        className: 'job-edit',
        title: modalTitle,
        closeModal,
        onHide: closeModal,
        // other
        facilityOptions: FormUtil.convertToOptions(ownProps.facilities),
        fseUsersByID: selectActiveFseUsersByID(state),
        fseOptions,
        facilities: keyBy(ownProps.facilities, 'id'),
        jobParts: selectJobPartsForJob(state),
        activeLocalCountry: activeCountry,
        nextJobNumber
    };
};

export default withTranslation('nsJob')(
    connect(mapStateToProps, {
        // reusable
        onSubmit: saveJob,
        delete: deleteJob,
        onChange: updateSelectedJob,
        // other
        getFacilitiesByCountry,
        getJobParts,
        searchFacilitiesForAsyncSelect,
        getNextJobNumber,
        getAllOpenJobsForWorkOrders
    })(WithCommonModal(EditJobForm))
);
