import * as types from './actionTypes';

import { Dispatch } from 'redux';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
    IjobComment,
    ItableFiltersParams,
    Ioption,
    ThunkResult
} from '../models';
import { ThunkDispatch } from 'redux-thunk';
import { filter, orderBy } from 'lodash';

import API from '../constants/apiEndpoints';
import { beginAjaxCall } from './ajaxStatusActions';
import { constants } from '../constants/constants';
import moment from 'moment';
import { msalFetch } from '../components/auth/Auth-Utils';
import { IinitialState } from '../reducers';

// const uuidv4 = require('uuid/v4');

export function getJobCommentsHelper(
    dispatch: ThunkDispatch<IinitialState, undefined, any>,
    getState: () => IinitialState
) {
    dispatch(beginAjaxCall());
    const { selectedFacilityID } = getState();
    const axiosOptions: AxiosRequestConfig = {
        method: 'get',
        params: { pagingType: 'none', facilityID: selectedFacilityID }
    };
    const url = API.jobComment.search;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            if (!data.data) {
                throw new Error('unable to get job comments');
            } else {
                const jobComments = data.data.result;
                dispatch({
                    type: types.MANAGE_JOB_COMMENTS_SUCCESS,
                    jobComments
                });
            }
        })
        .catch((error: any) => {
            dispatch({ type: types.MANAGE_JOB_COMMENTS_FAILED });
            constants.handleError(error, 'get job comments');
            console.error('get job comments', error);
            throw error;
        });
}

export function getJobComments(): ThunkResult<any> {
    return (dispatch, getState) => {
        return getJobCommentsHelper(dispatch, getState);
    };
}

export const receiveUpdatedJobComments = (
    jobComments: IjobComment[],
    dispatch: Dispatch
) => {
    jobComments.forEach(jobComment => {
        dispatch({
            type: types.MANAGE_JOB_COMMENTS_UPDATE,
            jobComment
        });
    });
};

export function resolveJobComment(isResolved: Ioption): ThunkResult<any> {
    return (dispatch, getState) => {
        if (!isResolved) {
            throw new Error('missing resolved selection');
        }
        const originalComment = getState().manageJobComment.selectedJobComment;
        const data: IjobComment = {
            ...originalComment,
            isResolved: isResolved.value === 'true' ? true : false
        };
        const axiosOptions = {
            url: `${API.jobComment.resolve}/${originalComment.id}`,
            method: 'post',
            params: { isResolved: isResolved.value === 'true' ? true : false }
        };
        dispatch({
            type: types.MANAGE_JOB_COMMENTS_UPDATE,
            jobComment: data,
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'resolve job comment' },
                    rollback: {
                        type: types.MANAGE_JOB_COMMENTS_UPDATE,
                        jobComment: originalComment
                    }
                }
            }
        });
    };
}

export const filterJobComments = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        const { manageJobComment, selectedFacilityID, manageJob } = getState();
        const {
            commentType,
            createDate,
            userID,
            jobID,
            isResolved
        } = manageJobComment.tableFilters;
        const { jobCommentsByID } = manageJobComment;
        const { jobsByID } = manageJob;
        // const jobID = getState().manageJob.selectedJob.id;
        const createDateMoment = moment.isMoment(createDate)
            ? createDate
            : moment(createDate);

        const filteredJobComments = filter(jobCommentsByID, jobComment => {
            let shouldInclude = true;
            const commentJob = jobsByID[jobComment.jobID];
            if (!commentJob) {
                console.error('missing job', jobComment.jobID, jobsByID);
                return false;
            }

            if (jobID && jobID.value && jobComment.jobID !== jobID.value) {
                shouldInclude = false;
            }
            if (commentJob.facilityID !== selectedFacilityID) {
                shouldInclude = false;
            }
            if (
                isResolved &&
                isResolved.value === 'true' &&
                jobComment.isResolved === false
            ) {
                shouldInclude = false;
            }
            // isResolved is undefined when it initially loads. null after it has been cleared
            if (
                (isResolved === undefined && jobComment.isResolved === true) ||
                (isResolved &&
                    isResolved.value === 'false' &&
                    jobComment.isResolved === true)
            ) {
                shouldInclude = false;
            }
            if (
                commentType &&
                commentType.value === 'false' &&
                jobComment.isDeficiency === true
            ) {
                // only show dificiencies
                shouldInclude = false;
            }
            if (
                commentType &&
                commentType.value === 'true' &&
                jobComment.isDeficiency === false
            ) {
                // only show non-dificiencis
                shouldInclude = false;
            }
            if (
                createDate &&
                !createDateMoment.isSame(
                    moment.utc(jobComment.createDate),
                    'day'
                )
            ) {
                shouldInclude = false;
            }
            if (userID && userID.value !== jobComment.userID) {
                shouldInclude = false;
            }

            return shouldInclude;
        });
        dispatch({
            type: types.MANAGE_JOB_COMMENTS_FILTER_VISIBLE,
            filteredJobComments: orderBy(
                filteredJobComments,
                res => moment.utc(res.createDate).unix(),
                'desc'
            )
        });
    };
};

export const toggleEditJobCommentModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_JOB_COMMENT
});
export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_JOB_COMMENTS,
    filters
});

export const setSelectedJobComment = (jobComment: IjobComment) => ({
    type: types.MANAGE_JOB_COMMENTS_SELECT,
    jobComment
});
