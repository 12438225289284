import { IdashboardAnalyticsData, Iuser } from '../models';

export const filterDashboardAnalytics = (
    dashboardAnalyticsData: IdashboardAnalyticsData,
    user: Iuser,
    isDemoMode: boolean
): IdashboardAnalyticsData => {
    const updatedDashboardAnalyticsData: IdashboardAnalyticsData = dashboardAnalyticsData;

    return {
        ...updatedDashboardAnalyticsData,
        // the value for gas type comes back with surrounding spaces, cleaning up before handing off to the component
        tae: dashboardAnalyticsData?.tae?.map(taeItem => ({
            ...taeItem,
            gasType: taeItem.gasType?.replace(/^\s([a-zA-Z0-9]+)\s$/g, '$1')
        }))
    };
};
