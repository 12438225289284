import * as types from '../actions/actionTypes';
import { IAlert, ImanageAlertReducer } from '../models';
import {
    createSelectedIDWithName,
    createTableFiltersWithName,
    modalToggleWithName,
    createFormValuesWithName
} from './commonReducers';
import { combineReducers } from 'redux';
import { pickBy, map, keyBy } from 'lodash';

const cleanAlertObject = (alert: IAlert) => {
    return {
        ...pickBy(alert, property => property !== null)
    } as IAlert;
};

export function alertsByID(state: { [key: string]: IAlert } = {}, action: any) {
    switch (action.type) {
        case types.LOAD_ALERTS_SUCCESS: {
            const newAlerts = map(action.payload, (alert: IAlert) => {
                return cleanAlertObject(alert);
            });
            return keyBy(newAlerts, 'id');
        }
        case types.ADD_ALERT_SUCCESS:
            return { ...state, [action.payload.id]: action.payload };
        case types.EDIT_ALERT_SUCCESS:
            return { ...state, [action.payload.id]: action.payload };
        case types.REMOVE_ALERT_SUCCESS: {
            const data = { ...state };
            delete data[action.payload.id];
            return data;
        }
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.ALERT_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

const manageAlert = combineReducers<ImanageAlertReducer>({
    alertsByID,
    totalPages,
    selectedAlertID: (state, action) =>
        createSelectedIDWithName(state, action, 'ALERT_ID'),
    showEditAlertModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_ALERT'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_ALERT'),
    alertFormValues: (state, action) =>
        createFormValuesWithName(state, action, 'MANAGE_ALERT')
});

export default manageAlert;
