/*
 * Manage Brand Modal
 */

import { connect } from 'react-redux';

import { updateQueueProduct } from '../../actions/manageProductQueueActions';
import EditBrandForm from './EditBrandForm';
import { Ihistory } from '../../models';
import {
    saveBrand,
    updateBrand,
    toggleEditBrandModal,
    clearSelectedBrandID,
    updateSelectedBrand
} from '../../actions/manageBrandActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { manageBrandQueryParamsEnum } from './ManageBrand';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { removeQuery } from '../common/OtherUtils';
import { selectBrand, selectBrands } from '../../reducers/manageBrandReducer';

interface Iprops {
    colorButton: string;
    queryParams: typeof manageBrandQueryParamsEnum;
    history: Ihistory;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    // brand currently being edited, will be defined when adding or editing
    const selectedBrand = selectBrand(state);
    // will be defined when editing an exisiting brand
    const brands = selectBrands(state);
    const originalBrand = brands[ownProps.queryParams.selectedBrandID];
    const isEditMode = originalBrand?.id.length > 0;

    let title;
    if (isEditMode) {
        title = ownProps.t(`manageBrand:editBrand`);
    } else {
        title = ownProps.t(`manageBrand:newBrand`);
    }
    // define the closeModal function here in mapState so that we can pass it to both the main component and
    // to the onHide which is a WithCommonModal HOC prop
    // yes it is unusual to pass a function in mapState, but it is ok since it does not need dispatch
    const closeModal = () => {
        removeQuery(
            manageBrandQueryParamsEnum.selectedBrandID,
            ownProps.history
        );
    };

    return {
        user: state.user,
        loading: selectIsLoading(state),
        show: ownProps.queryParams.selectedBrandID?.length > 0,
        tableFilters: state.manageBrand.tableFilters,
        selectedBrand,
        originalBrand,
        isEditMode,
        closeModal,
        onHide: closeModal,
        title,
        className: 'brand-edit'
    };
};

export default withTranslation('manageBrand')(
    connect(mapStateToProps, {
        saveBrand,
        updateBrand,
        toggleModal: toggleEditBrandModal,
        onHide: toggleEditBrandModal,
        onChange: updateSelectedBrand,
        updateQueueProduct,
        clearSelectedID: clearSelectedBrandID
    })(WithCommonModal(EditBrandForm))
);
