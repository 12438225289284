import * as types from './actionTypes';
import { Dispatch } from 'redux';

export function beginAjaxCall() {
    return { type: types.BEGIN_AJAX_CALL };
}

export function endAjaxCall() {
    return { type: types.END_AJAX_CALL };
}

export function manualAjaxStart() {
    return (dispatch: Dispatch) => {
        dispatch(beginAjaxCall());
    };
}

export function manualAjaxEnd() {
    return (dispatch: Dispatch) => {
        dispatch(endAjaxCall());
    };
}
