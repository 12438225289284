/*
 * Edit alert Form
 * Add and Edit alerts
 *
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup
} from 'react-reactive-form';
import { IAlert, Ioption } from '../../models';
import { WithTranslation } from 'react-i18next';
import {
    clearSelectedAlertID,
    saveAlert,
    toggleEditAlertModal,
    updateAlert
} from '../../actions/manageAlertActions';

import { FormUtil } from '../common/FormUtil';
import { constants } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';

// add the bootstrap form-control class to the react-select select component

interface Iprops {
    handleSubmit: typeof saveAlert;
    handleEdit: typeof updateAlert;
    handleCancel: any;
    loading: boolean;
    colorButton: string;
    selectedAlert: IAlert;
    clearSelectedAlertID: typeof clearSelectedAlertID;
    updateFormValue: (formValue: { [key: string]: any }) => void;
    setFormValues: (formValues: { [key: string]: any }) => void;
    formValues: { [key: string]: any };
    toggleModal: typeof toggleEditAlertModal;
    standardOptions: Ioption[];
}

interface State {
    file: any;
    fieldConfig: FieldConfig;
}

class EditAlertForm extends React.Component<Iprops & WithTranslation, State> {
    private formGroup: FormGroup | any;
    private subscription: any;
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.state = {
            file: '',
            fieldConfig: this.buildFieldConfig(this.onFileChange)
        };
    }

    componentDidUpdate(prevProps: Iprops, prevState: State) {
        if (prevProps.formValues.imageUrl !== this.props.formValues.imageUrl) {
            this.setState({
                fieldConfig: this.buildFieldConfig(this.onFileChange)
            });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.clearSelectedAlertID();
        this.props.setFormValues({});
    }

    buildFieldConfig = (onFileChange: any) => {
        const { selectedAlert, formValues } = this.props;
        let {
            type,
            title,
            text,
            imageUrl,
            expirationDate,
            standards
        } = selectedAlert;

        type = formValues.type
            ? formValues.type.value
                ? formValues.type.value
                : formValues.type
            : type;
        title = formValues.title ? formValues.title : title;
        text = formValues.text ? formValues.text : text;
        imageUrl = formValues.imageUrl ? formValues.imageUrl : imageUrl;
        expirationDate = formValues.expirationDate
            ? formValues.expirationDate
            : expirationDate;

        let selectedStandards: Ioption[] = [];
        if (
            (!formValues.standards || formValues.standards.length === 0) &&
            standards &&
            Object.keys(standards).length > 0
        ) {
            Object.keys(standards).forEach((key: string) => {
                for (let i = 0; i < this.props.standardOptions.length; i++) {
                    if (
                        this.props.standardOptions[i].value ===
                        standards[key].id
                    ) {
                        selectedStandards.push(this.props.standardOptions[i]);
                        return;
                    }
                }
            });
        } else if (formValues.standards && formValues.standards.length > 0) {
            selectedStandards = formValues.standards;
        }

        const selectedType =
            constants.alertTypes.find(t => t.value === type) || null;

        const fieldConfigControls = {
            title: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'alertForm.title',
                    colWidth: 12,
                    autoFocus: true,
                    name: 'alert-title'
                },
                formState: title
            },
            type: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.Select,
                meta: {
                    options: constants.alertTypes,
                    label: 'alertForm.type',
                    colWidth: 12,
                    name: 'alert-type',
                    isClearable: false
                },
                formState: {
                    value: selectedType,
                    disabled: false
                }
            },
            standards: {
                render: FormUtil.Select,
                meta: {
                    options: this.props.standardOptions,
                    label: 'alertForm.standards',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: true,
                    name: 'standards',
                    required: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: selectedStandards,
                    disabled: false
                }
            },
            expirationDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'alertForm.expirationDate',
                    colWidth: 12,
                    showTime: false,
                    name: 'expiration-date',
                    placeholder: 'dd-mmm-yy'
                },
                formState: {
                    value: expirationDate,
                    disabled: false
                },
                options: {
                    validators: FormUtil.validators.isValidMoment
                }
            },
            text: {
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'alertForm.text',
                    colWidth: 12,
                    name: 'alert-text',
                    required: false,
                    initialContent: text
                },
                formState: text
            },
            file: {
                render: FormUtil.FileInput,
                meta: {
                    type: 'file',
                    label: 'alertForm.file',
                    colWidth: 12,
                    accept: 'image/png, image/jpeg',
                    name: 'alert-file',
                    required: false,
                    onChange: onFileChange,
                    imageUrl,
                    hasPreview: true
                }
            },
            imageSizeLabel: {
                render: FormUtil.TextLabel,
                meta: {
                    label: 'alertForm.imageSizeLabel',
                    colWidth: 12
                }
            }
        };
        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    onFileChange = (key: string, file: File) => {
        this.setState({ file });
        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.onValueChanges(e.target.result, 'imageUrl');
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        this.props.updateFormValue({ [key]: value });
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        const formData = {
            ...this.formGroup.value,
            type: this.formGroup.value.type.value
        };
        if (this.state.file) {
            formData['file'] = this.state.file;
        }
        delete formData.standards;

        if (
            this.formGroup.value.standards &&
            this.formGroup.value.standards.length > 0
        ) {
            this.formGroup.value.standards.forEach(
                (standard: Ioption, index: number) => {
                    const newKey = `standards[${index}].id`;
                    formData[newKey] = standard.value;
                }
            );
        }

        if (!this.props.selectedAlert.id) {
            this.props.handleSubmit(formData);
        } else {
            this.props.handleEdit(formData, this.props.selectedAlert);
        }
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t } = this.props;

        return (
            <form onSubmit={this.handleSubmit} className="clearfix beacon-form">
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.props.handleCancel}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        bsStyle={this.props.colorButton}
                        type="common:submit"
                        disabled={this.props.loading}
                    >
                        {t('common:save')}
                    </Button>
                </Col>
            </form>
        );
    }
}
export default EditAlertForm;
