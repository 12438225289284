import { debounce } from 'lodash';
import * as React from 'react';
import { MutableRefObject, useRef } from 'react';
import { Button, DropdownButton, MenuItem } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup
} from 'react-reactive-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { RouteComponentProps } from 'react-router-dom';
import ReactTable, { Column, FinalState, RowInfo } from 'react-table';
import selectTableHOC, {
    SelectTableAdditionalProps
} from 'react-table/lib/hoc/selectTable';
import treeTableHOC from 'react-table/lib/hoc/treeTable';
import * as types from '../../actions/actionTypes';
import { beginAjaxCall, endAjaxCall } from '../../actions/ajaxStatusActions';
import {
    FacilitiesSearchParams,
    closeAllModals,
    getFacilitiesByCountry,
    searchFacilitiesForAsyncSelect,
    toggleEditFacilityModal
} from '../../actions/commonActions';
import { loadCountries } from '../../actions/countriesActions';
import {
    mergeFacilitiesAPI,
    setSelectedFacilityID,
    setTableFilter
} from '../../actions/manageFacilityActions';
import { getProductInfo } from '../../actions/manageInventoryActions';
import { constants } from '../../constants/constants';
import { Ifacility, Ioption, ItableFiltersReducer, Itile } from '../../models';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { getCountries } from '../../reducers/countriesReducer';
import { selectFilteredFacilities } from '../../reducers/facilitiesReducer';
import { emptyTile, initialFacility } from '../../reducers/initialState';
import { selectManageFacilityTableFilters } from '../../reducers/manageCustomerAndFacilityReducer';
import { getUsersCountries } from '../../reducers/userReducer';
import { Banner } from '../common/Banner';
import EditFacilityModal from '../common/EditFacilityModal';
import FacilityContactModal from '../common/FacilityContactModal';
import FacilityContractModal from '../common/FacilityContractModal';
import { FormUtil } from '../common/FormUtil';
import MergeFacilitiesModal from './mergeFacilitiesModal';

interface RowInfoFacility extends RowInfo {
    original: Ifacility;
}

type Iprops = RouteComponentProps<any>;

const SelectTable = selectTableHOC(treeTableHOC(ReactTable));

interface IdispatchProps {
    totalPages: number;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    loading: boolean;
    setSelectedFacilityID: typeof setSelectedFacilityID;
    toggleEditFacilityModal: typeof toggleEditFacilityModal;
    facilities: { [key: string]: Ifacility };
    getProductInfo: typeof getProductInfo;
    c: number;
    getFacilitiesByCountry: typeof getFacilitiesByCountry;
    filteredFacilities: Ifacility[];
    closeAllModals: typeof closeAllModals;
    searchFacilitiesForAsyncSelect: typeof searchFacilitiesForAsyncSelect;
    beginAjaxCall: typeof beginAjaxCall;
    endAjaxCall: typeof endAjaxCall;
}

interface Istate {
    selectedRow: any;
    selectAll: boolean;
    tableData: Ifacility[];
    currentTile: Itile;
    filter: FacilitiesSearchParams;
    selectedFacility: Ifacility;
    checkboxTable: any;
    selection: string[];
    showMergeFacilityModal: boolean;
    searchText: string;
}

const ManageFacility: React.FC<Iprops &
    IdispatchProps &
    SelectTableAdditionalProps &
    WithTranslation &
    Istate> = props => {
    const { t } = props;
    const dispatch = useDispatch();
    const countries = useSelector(getCountries);
    const usersCountries = useSelector(getUsersCountries);

    React.useEffect(() => {
        loadCountries(dispatch, countries);
        refreshData();
    }, []);

    const [config, setConfig] = React.useState<FieldConfig>();
    const [state, setState] = React.useState<Istate>({
        selectedRow: {},
        selectAll: false,
        tableData: [],
        currentTile: emptyTile,
        filter: {
            name: '',
            address: '',
            postalCode: ''
        },
        selectedFacility: initialFacility,
        checkboxTable: {},
        selection: [],
        showMergeFacilityModal: false,
        searchText: ''
    });
    const [data, setData] = React.useState<{
        result: any[];
        count: number;
        pages: number;
    }>({ result: [], count: 0, pages: 0 });
    const [formValues, setFormValues] = React.useState<{
        country: Ioption | null;
        facilityName: string | undefined;
        quantityOfAssets: number | undefined;
    }>({ country: null, quantityOfAssets: undefined, facilityName: undefined });

    React.useEffect(() => {
        const countriesOptions: Ioption[] = countries
            .filter(c => usersCountries?.includes(c.id))
            .map(c => ({ label: c.name, value: c.id }));
        const mainSearchControls = {
            country: {
                render: FormUtil.Select,
                meta: {
                    options: countriesOptions,
                    label: 'manageFacility:Country',
                    colWidth: 4,
                    colWidthMedium: 4,
                    colWidthLarge: 4,
                    placeholder: 'common:searchPlaceholder',
                    name: 'country',
                    defaultValue: '',
                    isClearable: true
                }
            },
            quantityOfAssets: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'manageFacility:quantityOfAssets',
                    colWidth: 4,
                    type: 'number',
                    placeholder: 'manageFacility:quantityOfAssets',
                    defaultValue: '',
                    isClearable: true
                }
            },
            facilityName: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:facility',
                    colWidth: 4,
                    type: 'text',
                    placeholder: 'common:facilitySearchPlaceholder',
                    defaultValue: '',
                    isClearable: true
                }
            }
        };

        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        setConfig(FormUtil.translateForm(searchFieldConfig, props.t));
    }, [countries, usersCountries]);

    const colorButton =
        constants.colors[
            `${state.currentTile.color}Button` as keyof typeof constants.colors
        ];

    const columns: any[] = [
        {
            Header: 'name',
            id: 'name',
            accessor: 'name',
            minWidth: 200
        },
        {
            Header: 'sap Facility Number',
            id: 'sapFacilityNumber',
            accessor: 'sapFacilityNumber',
            minWidth: 150
        },
        {
            Header: 'QTY OF ASSETS',
            id: 'quantityOfAssets',
            accessor: 'quantityOfAssets',
            minwidth: 70
        },
        {
            Header: 'country',
            id: 'country',
            accessor: ({ countryID }: Ifacility) => {
                const country = constants.countries[countryID];
                return country ? country.label : '';
            },
            minWidth: 120
        },
        {
            Header: 'address',
            id: 'address',
            accessor: ({
                address,
                address2,
                city,
                state: facilityState,
                postalCode
            }: Ifacility) => {
                return (
                    `${address ? address : ''}` +
                    ` ${address2 ? address2 : ''},` +
                    ` ${city ? city : ''},` +
                    ` ${facilityState ? facilityState : ''},` +
                    ` ${postalCode ? postalCode : ''}`
                );
            },
            minWidth: 300
        }
    ];

    /**
     * Toggle all checkboxes for select table
     */
    const toggleAll = () => {
        const { keyField = 'id' } = props;
        const selectAll = !state.selectAll;
        const selection: string[] = [];

        if (selectAll && keyField !== undefined) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = state.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords: any[] = wrappedInstance.getResolvedState()
                .sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(`select-${item._original[keyField]}`);
            });
        }

        setState({ ...state, selectAll, selection });
    };

    /**
     * Whether or not a row is selected for select table
     */
    const isSelected = (key: string) => {
        return state.selection.includes(`select-${key}`);
    };

    const toggleSelection = (key: string, shift: boolean, row: any) => {
        // start off with the existing state
        let selection = [...state.selection];
        const keyIndex = selection.indexOf(key);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(key);
        }

        setState({ ...state, selection });
    };

    // Since this page does not automatically save facilities into Redux
    // Soon as they take an action against a facility (select or try to merge)
    // We need to save it so other screens work correctly. Since everything usually
    // looks up a facility by ID in redux
    const saveFacility = () => {
        let facilitiesToSave: Ifacility[] = [];

        state.selection.forEach(selection => {
            const id = selection.slice(7);
            const tempFacility = state.tableData.find(x => x.id === id);
            if (tempFacility !== undefined) {
                facilitiesToSave.push(tempFacility);
            }
        });

        if (facilitiesToSave.length > 0) {
            dispatch({
                type: types.GET_FACILITIES_SUCCESS,
                facilities: facilitiesToSave
            });
        }
    };

    const getTdProps = (
        finalState: FinalState,
        rowInfo: RowInfoFacility | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo) {
            return {
                onClick: () => {
                    saveFacility();
                    props.setSelectedFacilityID(rowInfo.original.id);
                    setState({ ...state, selectedFacility: rowInfo.original });
                    props.toggleEditFacilityModal();
                }
            };
        }
        return {};
    };

    const onPageChange = (page: number) => {
        props.setTableFilter({ page });
        setState({ ...state, selectedRow: {} });

        if (formValues) {
            const { country, quantityOfAssets, facilityName } = formValues;
            props.beginAjaxCall();
            if (facilityName === undefined || facilityName.length === 0) {
                props.searchFacilitiesForAsyncSelect(
                    undefined,
                    country?.value !== undefined
                        ? country.value
                        : usersCountries,
                    quantityOfAssets !== undefined ? quantityOfAssets : null,
                    () => {
                        return;
                    },
                    saveFacilities,
                    page + 1,
                    (data: any) => {
                        setData(data);
                    }
                );
                return;
            } else if (
                formValues.facilityName &&
                formValues.facilityName.length >= 3
            ) {
                props.searchFacilitiesForAsyncSelect(
                    facilityName,
                    country?.value !== undefined
                        ? country.value
                        : usersCountries,
                    quantityOfAssets !== undefined ? quantityOfAssets : null,
                    () => {
                        return;
                    },
                    saveFacilities,
                    page + 1,
                    (data: any) => {
                        setData(data);
                    }
                );
                return;
            }
        }
    };

    const onPageSizeChange = (rows: number) => {
        props.setTableFilter({ rows, page: 0 });
        setState({ ...state, selectedRow: {} });
    };

    const addFacility = (row: any) => {
        props.setSelectedFacilityID(initialFacility.id);
        setState({ ...state, selectedFacility: initialFacility });
        props.toggleEditFacilityModal();
    };

    const hideMergeFacilitiesModal = () => {
        setState({ ...state, showMergeFacilityModal: false });
    };

    const mergeFacilities = async (primary: string, secondary: string[]) => {
        const response = await mergeFacilitiesAPI(primary, secondary);
        if (!response) {
            toastr.warning(
                t('Error'),
                t('Failed to merge Facilities'),
                constants.toastrError
            );
            return;
        }

        // Remove the now merged facilities from redux if they're there
        await dispatch({
            type: types.MERGE_FACILITIES_SUCCESS,
            primaryId: primary,
            secondaryIds: secondary
        });

        // Remove the now merged facilities from state
        const updatedTableData = state.tableData.filter(tempFacility => {
            if (secondary.includes(tempFacility.id)) {
                return false;
            }

            return true;
        });

        setState({
            ...state,
            selectAll: false,
            selection: [],
            showMergeFacilityModal: false,
            tableData: updatedTableData
        });

        toastr.success(
            t('Success'),
            t('Facilities successfully merged'),
            constants.toastrSuccess
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const searchForFacility = (facilityID: string) => {};

    const saveFacilities = (facilities: Ifacility[]) => {
        setState({ ...state, tableData: facilities });
        props.endAjaxCall();
    };

    const debouncedSearch = React.useRef(
        debounce(async (searchTerms, countries, quantityOfAssets, page) => {
            props.beginAjaxCall();
            props.searchFacilitiesForAsyncSelect(
                searchTerms,
                countries !== undefined ? countries : usersCountries,
                quantityOfAssets !== '' ? quantityOfAssets : null,
                () => {
                    return;
                },
                saveFacilities,
                page + 1,
                (data: any) => {
                    setData(data);
                }
            );
        }, 200)
    ).current;

    React.useEffect(() => {
        props.beginAjaxCall();
        props.searchFacilitiesForAsyncSelect(
            undefined,
            usersCountries,
            null,
            () => {
                return;
            },
            saveFacilities,
            props.tableFilters.page,
            (data: any) => {
                setData(data);
            }
        );
    }, [usersCountries]);

    const ref = useRef() as MutableRefObject<HTMLInputElement>;

    const refreshData = () => {
        if (ref.current !== undefined) {
            ref.current.value = '';
        }

        const countriesOptions: string[] = countries
            .filter(c => usersCountries?.includes(c.id))
            .map(c => c.id);

        debouncedSearch(
            undefined,
            countriesOptions,
            null,
            props.tableFilters.page
        );
    };

    const setForm = (form: FormGroup | FormArray) => {
        form.valueChanges.subscribe(
            (formValue: {
                country: Ioption;
                facilityName: string;
                quantityOfAssets: number;
            }) => {
                setFormValues(formValue);
                if (
                    formValue.facilityName === undefined ||
                    formValue.facilityName.length === 0
                ) {
                    debouncedSearch(
                        undefined,
                        formValue.country?.value,
                        formValue.quantityOfAssets,
                        props.tableFilters.page
                    );
                    return;
                } else if (formValue.facilityName.length >= 3) {
                    debouncedSearch(
                        formValue.facilityName,
                        formValue.country?.value,
                        formValue.quantityOfAssets,
                        props.tableFilters.page
                    );
                    return;
                }
            }
        );
    };

    return (
        <div className={`manage-facility`}>
            <Banner
                title={t('manageFacility:bannerTitle')}
                img={state.currentTile.srcBanner}
                color={state.currentTile.color}
                subtitle={props.selectedFacility?.name}
            />
            <div
                style={{
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
                className="form-group form-group-sm"
            >
                {config && (
                    <FormGenerator onMount={setForm} fieldConfig={config} />
                )}
                <div>
                    <Button
                        bsStyle="link"
                        onClick={addFacility}
                        style={{ paddingTop: '30px', float: 'right' }}
                    >
                        {props.t('facility:newFacility')}
                    </Button>
                </div>
            </div>

            {state.selection.length >= 2 && (
                <DropdownButton
                    bsStyle={colorButton}
                    style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        marginLeft: '30px'
                    }}
                    title={t('manageLeads:bulkActions')}
                    id="table-action-button"
                    onSelect={(eventKey: any) => {
                        switch (eventKey) {
                            case 1:
                                if (state.selection.length >= 2) {
                                    saveFacility();
                                    setState({
                                        ...state,
                                        showMergeFacilityModal: true
                                    });
                                }
                                break;
                        }
                    }}
                >
                    <MenuItem eventKey={1}>{t('mergeFacilities')}</MenuItem>
                </DropdownButton>
            )}

            <SelectTable
                data={data.result}
                manual
                columns={columns}
                getTdProps={getTdProps}
                defaultPageSize={100}
                onPageSizeChange={onPageSizeChange}
                showPageSizeOptions={false}
                className={`beacon-table -highlight ${state.currentTile.color}`}
                previousText={t('common:previous')}
                nextText={t('common:next')}
                onPageChange={onPageChange}
                sortable={false}
                multiSort={false}
                noDataText={t('common:noDataSearch')}
                resizable={false}
                toggleSelection={toggleSelection}
                selectType="checkbox"
                toggleAll={toggleAll}
                isSelected={isSelected}
                ref={r => (state.checkboxTable = r)}
                keyField="id"
                loading={data.result.length === 0 && props.loading}
                page={props.tableFilters.page}
                pages={data.pages}
            />

            <EditFacilityModal
                modalClass=""
                colorButton={colorButton}
                selectedFacility={state.selectedFacility}
                disabled={false}
                approveMode={false}
                searchForFacility={searchForFacility}
                refreshTable={refreshData}
            />

            <FacilityContractModal
                colorButton={colorButton}
                secondWideModal={true}
            />
            <FacilityContactModal
                colorButton={colorButton}
                secondWideModal={true}
                facilityID={state.selectedFacility.id}
            />

            <MergeFacilitiesModal
                showModal={state.showMergeFacilityModal}
                hideModal={hideMergeFacilitiesModal}
                selection={state.selection}
                colorButton={colorButton}
                mergeFacilities={mergeFacilities}
            />
        </div>
    );
};

const mapStateToProps = (state: IinitialState) => {
    const selectedFacility: Ifacility = initialFacility;

    const filteredFacilities = selectFilteredFacilities(
        state.facilities,
        state.manageFacility.tableFilters
    );

    return {
        tableFilters: selectManageFacilityTableFilters(state),
        facilities: state.facilities,
        selectedFacility,
        filteredFacilities,
        loading: selectIsLoading(state)
    };
};

export default withTranslation('manageFacility')(
    connect(mapStateToProps, {
        getFacilitiesByCountry,
        setTableFilter,
        setSelectedFacilityID,
        toggleEditFacilityModal,
        searchFacilitiesForAsyncSelect,
        beginAjaxCall,
        endAjaxCall
    })(ManageFacility)
);
