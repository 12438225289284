import * as React from 'react';

import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ParentItem from './ParentItem';
import { IinitialState } from '../../reducers';
import { Itile, Iuser } from '../../models';
import { NavSkeleton } from './NavSkeleton';
import { TFunction } from 'i18next';
import { svgIcons } from '../../constants/svgIcons';
import { toggleSideMenu } from '../../actions/commonActions';

interface ItemProps extends Omit<Itile, 'parentKey'> {
    t: TFunction;
    tiles?: Itile[];
    isChild?: boolean;
    parentKey?: number;
    collapse?: boolean;
}

export const Item = (props: ItemProps) => {
    const [collapsed, setCollapsed] = React.useState<boolean>(false);

    const { url, title, t, tiles, isChild, svgKey } = props;
    let classNames = 'kerning';

    if (isChild) {
        classNames += ' child-item';
    }

    React.useEffect(() => {
        setCollapsed(Boolean(props.collapse));
    }, [props.collapse]);

    if (!tiles) {
        return (
            <LinkContainer to={url} title={t(title)}>
                <ListGroupItem className={classNames}>
                    <span className="menu-item-icon">
                        {svgKey !== undefined &&
                            svgIcons[svgKey as keyof typeof svgIcons]}
                    </span>
                    <span className="menu-text">{t(title)}</span>
                </ListGroupItem>
            </LinkContainer>
        );
    }

    return (
        <ParentItem
            url={url}
            title={title}
            svgKey={svgKey}
            t={t}
            tiles={tiles}
            collapsed={collapsed}
        />
    );
};

interface Iprops extends RouteComponentProps<{}> {
    user: Iuser;
    showSideMenu: boolean;
    toggleSideMenu: typeof toggleSideMenu;
}

class SideMenu extends React.Component<Iprops & WithTranslation, {}> {
    render() {
        const t = this.props.t;
        const menuClass = this.props.showSideMenu ? 'open' : 'closed';
        const Items = NavSkeleton(this.props.user);

        return (
            <div className={`side-menu ${menuClass}`}>
                <Button
                    className="sidemenu-toggle-icon"
                    bsStyle="link"
                    aria-label="Toggle Side Menu"
                    onClick={() => {
                        this.props.toggleSideMenu();
                    }}
                >
                    <FontAwesomeIcon
                        icon={['far', 'bars']}
                        size="lg"
                        style={{ color: '#fff' }}
                    />
                </Button>
                <ListGroup>
                    {Items.map(item => (
                        <Item
                            {...item}
                            key={item.title}
                            t={t}
                            collapse={this.props.showSideMenu}
                        />
                    ))}
                </ListGroup>
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        user: state.user
    };
};

export default withTranslation('tiles')(connect(mapStateToProps, {})(SideMenu));
