/*
 * Analytics Card Container
 */

import queryString from 'query-string';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';

import AnalyticsExpandedCard from './AnalyticsExpandedCard';
import { IanalyticsTAEDashboard } from '../../../models';
import { IinitialState } from '../../../reducers';
import { selectDataFormatPreference } from '../../../reducers/dashboardAnalyticsReducer';
import { selectIsLoading } from '../../../reducers/commonReducers';
import {
    setSelectedInstallBaseID,
    setSelectedProductID,
    toggleInstallContactModal
} from '../../../actions/manageInventoryActions';
import {
    runReport,
    setSelectedDefaultReport,
    toggleEditReportModal
} from '../../../actions/manageReportActions';
import { beginAjaxCall, endAjaxCall } from '../../../actions/ajaxStatusActions';

const mapStateToProps = (
    state: IinitialState,
    ownProps: RouteComponentProps<{}> & WithTranslation
) => {
    const location = ownProps.location;
    const { selectedFacilityID, user } = state;
    const { securityFunctions } = user;
    const query = queryString.parse(location.search);
    const dataFormatPreference = selectDataFormatPreference(state);
    const locationState = location?.state as {
        data: IanalyticsTAEDashboard;
    };

    return {
        data: locationState?.data,
        selectedFacilityID,
        securityFunctions,
        loading: selectIsLoading(state),
        isDemoMode: query.demo === 'true',
        dataFormatPreference
    };
};

export const AnalyticsExpandedCardContainer = withTranslation(
    'dashboardAnalytics'
)(
    withRouter(
        connect(mapStateToProps, {
            setSelectedProductID,
            runReport,
            setSelectedInstallBaseID,
            toggleInstallContactModal,
            beginAjaxCall,
            endAjaxCall,
            setSelectedDefaultReport,
            toggleEditReportModal
        })(AnalyticsExpandedCard)
    )
);
