import { IparentTile, Itile, Iuser } from '../../models';

import { constants } from '../../constants/constants';
import { find, orderBy } from 'lodash';
import { menuParentKeysEnum } from '../../models-enums';

// Constructs the top-level navItem using the menuParentKeysEnum
const buildParentContainer = (menuSkeleton: any[], tile: Itile) => {
    const parentContainer = find(menuSkeleton, { key: tile.parentKey });
    if (parentContainer === undefined) {
        const parentItem = find(constants.menuItemsWithChildren, {
            key: tile.parentKey
        });
        if (parentItem) {
            const newParentContainer: IparentTile = {
                ...parentItem,
                tiles: [tile]
            };
            return menuSkeleton.push(newParentContainer);
        }
    } else {
        parentContainer.tiles.push(tile);
    }
};

// Constructs the top-level item,
//  then adds corresponding tiles to that top-level item

export const NavSkeleton = (user: Iuser) => {
    const availableTiles = constants.tiles.filter(tile =>
        constants.hasSecurityFunction(user, tile.securityFunctions, {
            matchAll: ['/assetStatus'].includes(tile.url)
        })
    );
    let menuSkeleton = [] as any[];
    availableTiles.forEach(tile =>
        tile.parentKey === menuParentKeysEnum.default
            ? menuSkeleton.push(tile)
            : buildParentContainer(menuSkeleton, tile)
    );
    menuSkeleton = orderBy(menuSkeleton, ['order', 'title'], ['asc', 'asc']);
    return menuSkeleton;
};
