import React from 'react';
import { Step } from 'react-joyride';
import { VideoPlayer } from '../components/tutorial/Video';

// interface Itutorial {
//   [key: string]: {steps: Step[]};
// }

export const tutorial = {
    // tae: {
    //     steps: [
    //         {
    //             title: 'taeTutorialTitle',
    //             content: <VideoPlayer vimeoId={265559539}></VideoPlayer>,
    //             target: 'body',
    //             placement: 'center',
    //             disableBeacon: true
    //         }
    //     ] as Step[]
    // },
    documents: {
        steps: [
            {
                title: 'documentsTutorialTitle',
                content: <VideoPlayer vimeoId={842441123}></VideoPlayer>,
                target: 'body',
                placement: 'center',
                disableBeacon: true
            }
        ] as Step[]
    },
    inventory: {
        steps: [
            {
                title: 'inventoryTutorialTitle',
                content: <VideoPlayer vimeoId={775731797}></VideoPlayer>,
                target: 'body',
                placement: 'center',
                disableBeacon: true
            }
        ] as Step[]
    }
    // training: {
    //     steps: [
    //         {
    //             title: 'trainingTutorialTitle',
    //             content: <VideoPlayer vimeoId={265559539}></VideoPlayer>,
    //             target: 'body',
    //             placement: 'center',
    //             disableBeacon: true
    //         }
    //     ] as Step[]
    // },
    // workorders: {
    //     steps: [
    //         {
    //             title: 'workOrdersTutorialTitle',
    //             content: <VideoPlayer vimeoId={265559539}></VideoPlayer>,
    //             target: 'body',
    //             placement: 'center',
    //             disableBeacon: true
    //         }
    //     ] as Step[]
    // },
    // pmp: {
    //     steps: [
    //         {
    //             title: 'pmpTutorialTitle',
    //             content: <VideoPlayer vimeoId={265559539}></VideoPlayer>,
    //             target: 'body',
    //             placement: 'center',
    //             disableBeacon: true
    //         }
    //     ] as Step[]
    // }
};
