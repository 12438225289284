/*
 * UserForm
 * User signs up directly to the platform
 */

import * as React from 'react';
import {
    Validators,
    FormGenerator,
    AbstractControl,
    FieldConfig,
    FormGroup
} from 'react-reactive-form';
import { Col, Button } from 'react-bootstrap';
import { forEach } from 'lodash';
import {
    constants,
    postalAndStateRequiredCountries
} from '../../constants/constants';
import { toastr } from 'react-redux-toastr';
import { FormUtil } from '../common/FormUtil';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IregisterUserFormValues } from '../../modelsForms';
import { userBaseConfigControls } from '../common/UserBaseConfigControls';
import { Icountry } from '../../models';

const passwordRegex = new RegExp(
    '(?=^.{6,15}$)((?=.*d)(?=.*[A-Z])(?=.*[a-z])|(?=.*d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*'
);

const passwordMatchValidator = (ger: any) => {
    if (!ger._parent) {
        return;
    }
    if (!ger._parent.get('tempPassword')) {
        return null;
    }
    return ger._parent.get('tempPassword').value ===
        ger._parent.get('passwordConfirm').value
        ? null
        : { mismatch: true };
};

const getIsRequiredFromSelectedCountry = (selectedCountryID?: string) => {
    if (
        selectedCountryID === '' ||
        selectedCountryID === undefined ||
        selectedCountryID === null
    ) {
        return {
            state: false,
            postalCode: false
        };
    }

    return postalAndStateRequiredCountries[selectedCountryID];
};

// Field config to configure form
const fieldConfigControls = {
    tempPassword: {
        options: {
            // validators: [FormUtil.validators.requiredWithTrim]
            validators: [
                FormUtil.validators.requiredWithTrim,
                Validators.pattern(passwordRegex)
            ]
        },

        render: FormUtil.TextInput,
        meta: {
            label: 'password',
            colWidth: 12,
            type: 'password',
            name: 'password'
        }
    },
    passwordConfirm: {
        options: {
            // validators: [FormUtil.validators.requiredWithTrim]
            validators: [
                FormUtil.validators.requiredWithTrim,
                Validators.pattern(passwordRegex),
                passwordMatchValidator
            ]
        },
        render: FormUtil.TextInput,
        meta: {
            label: 'password confirm',
            colWidth: 12,
            type: 'password',
            name: 'password confirm'
        }
    },
    $field_3: {
        isStatic: false, // ensures a key is added
        render: () => (
            <Col xs={12} style={{ color: '#ffff', marginBottom: '10px' }}>
                <small>
                    Password must be between 8 - 15 characters and requires 3
                    out of 4 of the following: Lowercase letters, Uppercase
                    letters, Numbers (0-9), and Symbols (@#$!&).
                </small>
            </Col>
        )
    },
    tempCompany: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: { label: 'company', colWidth: 12, type: 'text', name: 'company' }
    },
    tempAddress: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: {
            label: 'address',
            colWidth: 8,
            type: 'text',
            name: 'temp-address'
        }
    },
    tempAddress2: {
        render: FormUtil.TextInput,
        meta: {
            label: 'address2',
            colWidth: 4,
            type: 'text',
            name: 'temp-address2'
        }
    },
    tempCity: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: { label: 'city', colWidth: 5, type: 'text', name: 'temp-city' }
    },
    tempState: {
        options: {
            validators: [FormUtil.validators.requiredWithTrim]
        },
        render: FormUtil.TextInput,
        meta: { label: 'state', colWidth: 3, type: 'text', name: 'temp-state' }
    },
    tempZip: {
        options: {
            validators: [
                FormUtil.validators.requiredWithTrim,
                Validators.pattern(
                    /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/
                )
            ]
        },
        render: FormUtil.TextInput,
        meta: { label: 'zip', colWidth: 4, type: 'tel', name: 'temp-zip' }
    },
    countryID: {
        options: {
            validators: FormUtil.validators.requiredWithTrim
        },
        render: FormUtil.Select,
        meta: {
            options: constants.countries,
            label: 'user:country',
            colWidth: 12,
            placeholder: 'manageUserQueue:countrySearchPlaceholder',
            name: 'country'
        }
    }
};
const fieldConfig = {
    controls: {
        ...userBaseConfigControls(undefined, undefined, false),
        ...fieldConfigControls
    }
};

const testUser: IregisterUserFormValues = {
    first: 'Little',
    last: 'Pixel',
    email: 'a@test.com',
    position: 'president',
    tempAddress: '12 street',
    tempAddress2: '2 street',
    tempCity: 'mycity',
    tempZip: '77080',
    tempState: 'TX',
    tempCompany: 'BigPixel',
    phone: '888-333-1121',
    tempPassword: '',
    passwordConfirm: '',
    countryID: {
        value: constants.usCountryID,
        label: 'United States of America'
    }
};
interface Iprops extends React.Props<UserForm> {
    handleSubmit: any;
    handleCancel: any;
    loading: boolean;
}

class UserForm extends React.Component<Iprops & WithTranslation, {}> {
    public userForm: FormGroup | any;
    public fieldConfig: FieldConfig;
    private subscription: any;
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.fieldConfig = FormUtil.translateForm(fieldConfig, this.props.t);
    }

    componentDidMount() {
        if (process.env.NODE_ENV !== 'production') {
            forEach(testUser, (value, key) => {
                this.userForm.patchValue({ [key]: value });
            });
        }
        this.subscription = this.userForm
            .get('countryID')
            .valueChanges.subscribe((value: any) => {
                if (value && value.value) {
                    this.onCountryChanges(value.value);
                }
            });

        this.userForm.patchValue({
            countryID: {
                value: constants.usCountryID,
                label: 'United States of America'
            }
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onCountryChanges = (value: string) => {
        const stateFormControl = this.userForm.get('tempState');
        const tempZipFormControl = this.userForm.get('tempZip');

        const isStateRequired = getIsRequiredFromSelectedCountry(value)
            ? getIsRequiredFromSelectedCountry(value).state
            : false;
        const isPostalCodeRequired = getIsRequiredFromSelectedCountry(value)
            ? getIsRequiredFromSelectedCountry(value).postalCode
            : false;

        stateFormControl.clearValidators();
        tempZipFormControl.clearValidators();

        if (isStateRequired) {
            stateFormControl.setValidators([
                FormUtil.validators.requiredWithTrim
            ]);
            stateFormControl.patchValue(null);
        } else {
            stateFormControl.patchValue(null);
        }

        if (isPostalCodeRequired) {
            tempZipFormControl.setValidators([
                FormUtil.validators.requiredWithTrim,
                Validators.pattern(
                    /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/
                )
            ]);
            tempZipFormControl.patchValue(null);
        } else {
            tempZipFormControl.patchValue(null);
        }
    };

    handleSubmit = (evt: React.MouseEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (this.userForm.status === 'INVALID') {
            this.userForm.markAsSubmitted();
            toastr.error(
                this.props.t('validationError'),
                '',
                constants.toastrError
            );
            return;
        }
        // this.props.handleSubmit({
        //     ...this.userForm.value,
        //     position: this.userForm.value.userType.label
        // });
        this.props.handleSubmit(this.userForm.value);
    };
    setForm = (form: AbstractControl) => {
        this.userForm = form;
        this.userForm.meta = {
            handleCancel: this.props.handleCancel,
            cancelText: 'Cancel',
            submitText: 'Sign Up',
            loading: this.props.loading
        };
    };
    render() {
        const { t } = this.props;
        return (
            <form
                onSubmit={this.handleSubmit}
                className="clearfix beacon-form login-form"
            >
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.fieldConfig}
                />
                <Col xs={12} className="user-form-buttons">
                    <Button
                        bsStyle="link"
                        type="button"
                        onClick={this.props.handleCancel}
                        style={{ color: 'white' }}
                        disabled={this.props.loading}
                        className="left-side"
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        bsStyle="primary"
                        type="submit"
                        disabled={this.props.loading}
                        className="pull-right"
                    >
                        {t('signUp')}
                    </Button>
                </Col>
            </form>
        );
    }
}
export default withTranslation('user')(UserForm);
