import * as LanguageDetector from 'i18next-browser-languagedetector';

import moment from 'moment';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend, {
    I18NextLocalStorageBackend
} from 'i18next-localstorage-backend';
import I18NextLocizeBackend from 'i18next-locize-backend';
// import common from './translations/en-US/common.json';
import { forEach } from 'lodash';
import { dataFormatEnum, userLanguageEnum } from './models-enums';
import { constants } from './constants/constants';

const ns = [
    'auth',
    'common',
    'dashboardAnalytics',
    'securityF',
    'facility',
    'nsJob',
    'manageAssetPhotos',
    'manageAlert',
    'manageBrand',
    'manageCode',
    'manageLeads',
    'manageFacility',
    'manageDocument',
    'manageInventory',
    'manageJobComments',
    'manageLocation',
    'manageMeasurementPointLists',
    'manageProduct',
    'manageProductQueue',
    'manageTae',
    'manageTraining',
    'manageWorkOrder',
    'manageReport',
    'manageTeam',
    'manageUserAlert',
    'manageUser',
    'manageUserQueue',
    'tiles',
    'toastMessage',
    'training',
    'units',
    'user',
    'nsTutorial',
    'manageQuotes',
    'manageSuggestedParts'
];

export const languageLables: { [key: string]: string } = {
    English: 'en-US',
    French: 'fr',
    German: 'de',
    Spanish: 'es',
    Thai: 'th-TH',
    Chinese: 'zh'
};

export const langOption = (value: number): string => {
    const langName = userLanguageEnum[value];
    const langKey = languageLables[langName];

    return langKey;
};

i18next.on('onInitialized', lng => {
    console.info('setting moment lng', lng);
    moment.locale(lng);
});

const instance = i18next
    .use(Backend)
    .use(initReactI18next)

    .use(LanguageDetector)

    .init({
        preload: ['en-US'],
        lng: 'en-US',
        ns,
        fallbackLng: 'en-US',
        defaultNS: 'translation',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react!!
            format: (value, format, lng) => {
                if (format === 'number') {
                    if (!value) {
                        return value;
                    }
                    return new Intl.NumberFormat(lng).format(parseFloat(value));
                }
                if (format === dataFormatEnum[dataFormatEnum.numberTruncate]) {
                    if (!value) {
                        return value;
                    }
                    return new Intl.NumberFormat(lng).format(
                        Math.trunc(parseFloat(value) * 100) / 100
                    ); // intentionally tuncating
                }
                if (
                    format ===
                    dataFormatEnum[dataFormatEnum.secondsToHoursTruncated]
                ) {
                    if (!value) {
                        return value;
                    }
                    value = value / 3600;
                    return new Intl.NumberFormat(lng).format(
                        Math.trunc(parseFloat(value) * 100) / 100
                    ); // intentionally tuncating
                }
                if (
                    format ===
                    dataFormatEnum[dataFormatEnum.pascalToPSITruncated]
                ) {
                    if (!value) {
                        return value;
                    }
                    value = value * 0.0001450377;
                    return new Intl.NumberFormat(lng).format(
                        Math.trunc(parseFloat(value) * 100) / 100
                    ); // intentionally tuncating
                }
                if (format === dataFormatEnum[dataFormatEnum.celcius]) {
                    if (!value) {
                        return value;
                    }
                    value = value - 273.15;
                    return new Intl.NumberFormat(lng).format(
                        Math.trunc(parseFloat(value) * 100) / 100
                    ); // intentionally tuncating
                }
                if (format === dataFormatEnum[dataFormatEnum.fahrenheit]) {
                    if (!value) {
                        return value;
                    }
                    value = value * 1.8 - 459.67;
                    return new Intl.NumberFormat(lng).format(
                        Math.trunc(parseFloat(value) * 100) / 100
                    ); // intentionally tuncating
                }
                if (format === dataFormatEnum[dataFormatEnum.date]) {
                    if (!value) {
                        return value;
                    }
                    return moment
                        .utc(value)
                        .local(true)
                        .format(constants.momentDisplayFormat);
                }
                return value;
            }
        },
        react: {
            wait: true,
            useSuspense: true
        },
        detection: {
            caches:
                process.env.NODE_ENV === 'development' ? [] : ['localStorage']
        },
        backend: {
            backends: [LocalStorageBackend, I18NextLocizeBackend],
            backendOptions: [
                {
                    expirationTime: 24 * 60 * 60 * 1000,
                    defaultVersion: `${process.env.REACT_APP_LOCIZE_VERSION}`
                } as I18NextLocalStorageBackend.BackendOptions,
                {
                    projectId: `${process.env.REACT_APP_LOCIZE_PROJECT_ID}`,
                    apiKey: `${process.env.REACT_APP_LOCIZE_API_KEY}`,
                    version: `${process.env.REACT_APP_LOCIZE_VERSION}`,
                    referenceLng: 'en-US'
                } as I18NextLocizeBackend.BackendOptions
            ]
        }
    });

// while developing load translations locally from json files
if (process.env.NODE_ENV === 'development') {
    forEach(ns, key => {
        i18next.addResourceBundle(
            'en-US',
            key,
            require(`./translations/en-US/${key}.json`)
        );
    });
}
export default instance;
