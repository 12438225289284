/*
 * Measurement Point Result Notes Modal
 */

import * as React from 'react';

import {
    toggleMPResultModal,
    toggleMPResultNotes
} from '../../actions/manageInventoryActions';
import { connect } from 'react-redux';
import { IinstallBaseHistory } from '../../models';
import { RichTextView } from '../common/RichTextView';
import { Col, Button } from 'react-bootstrap';
import { WithCommonModal } from '../common/WithCommonModal';
import { WithTranslation, withTranslation } from 'react-i18next';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: any;
    secondModal: boolean;
}
interface IdispatchProps {
    toggleModal: typeof toggleMPResultModal;
    selectedItem: IinstallBaseHistory;
}
const defaultProps = {
    className: 'notes-modal'
};

const MPResultListNotesModalClass = (
    props: Iprops & IdispatchProps & WithTranslation
) => {
    if (props.selectedItem.notes && props.selectedItem.notes.length !== 0) {
        return (
            <RichTextView {...props} HTMLcontent={props.selectedItem.notes} />
        );
    } else if (!props.selectedItem.notes) {
        return (
            <div>
                <Col xs={12}>{props.selectedItem.notes}</Col>
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle={props.colorButton}
                        type="button"
                        onClick={props.toggleModal}
                    >
                        {props.t('common:done')}
                    </Button>
                </Col>
            </div>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    return {
        ...defaultProps,
        title: ownProps.t('MPResultNotesModalTitle'),
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageInventory.showMPResultNotesModal,
        selectedItem: state.measurementPointResults.installBaseHistory.find(
            x => x.id === state.measurementPointResults.historicalResultID
        )!
    };
};

export const MPResultListNotesModal = withTranslation('manageInventory')(
    connect(mapStateToProps, {
        toggleModal: toggleMPResultNotes,
        onHide: toggleMPResultNotes
    })(WithCommonModal(MPResultListNotesModalClass))
);
