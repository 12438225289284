/*
 * EditJobForm
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    GroupProps
} from 'react-reactive-form';
import { debounce, omit } from 'lodash';
import { leadActivityTypesEnum } from '../../models-enums';

import { FormUtil } from '../common/FormUtil';
import { IeditLeadActivityFormValues } from '../../modelsForms';
import {
    IleadActivity,
    Ilead,
    Ihistory,
    IoptionNumberValue
} from '../../models';
import { constants } from '../../constants/constants';
import { initialLeadActivity } from '../../reducers/initialState';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { WithTranslation } from 'react-i18next';
import { removeQuery } from '../common/OtherUtils';
import {
    saveLeadActivity,
    updateSelectedLeadActivity,
    setSelectedLeadActivityFromID,
    updateLeadActivity
} from '../../actions/manageLeadsActions';
import { manageLeadQueryParamsEnum } from './ManageLeads';
import { TFunction } from 'i18next';

interface Iprops {
    // reusable
    loading: boolean;
    selectedLead: Ilead;
    selectedLeadActivity: IleadActivity;
    show: boolean;
    toggleModal: () => void;
    onHide: () => void;
    colorButton: string;
    create: typeof saveLeadActivity;
    delete: (id: string, t: TFunction) => void;
    updateLeadActivity: typeof updateLeadActivity;
    history: Ihistory;
    queryParams: typeof manageLeadQueryParamsEnum;
    updateSelectedLeadActivity: typeof updateSelectedLeadActivity;
    setSelectedLeadActivityFromID: typeof setSelectedLeadActivityFromID;
}

interface Istate {
    fieldConfig: FieldConfig;
}

class EditLeadActivityForm extends React.Component<
    Iprops & WithTranslation,
    Istate
> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateSelectedLeadActivityDebounced: (
        leadActivity?: IleadActivity
    ) =>
        | {
              type: string;
              leadActivity: IleadActivity;
          }
        | undefined;
    static defaultProps = {
        selectedLeadActivity: initialLeadActivity
    };
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.updateSelectedLeadActivityDebounced = debounce(
            this.props.updateSelectedLeadActivity,
            constants.formDebounceTime
        );
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }
    componentDidMount() {
        // if (
        //   (this.props.queryParams.selectedLeadID &&
        //     this.props.queryParams.selectedActivityID &&
        //     this.props.queryParams.selectedActivityID !==
        //       this.props.selectedLeadActivity.id) ||
        //   this.props.queryParams.selectedLeadID !== this.props.selectedLead.id
        // ) {
        //   this.props.setSelectedLeadActivityFromID(
        //     this.props.queryParams.selectedActivityID
        //   );
        // }
        this.initSelected();
    }

    componentDidUpdate(prevProps: Iprops & WithTranslation) {
        if (
            prevProps.selectedLeadActivity.followUpScheduled !==
            this.props.selectedLeadActivity.followUpScheduled
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
        if (
            this.props.selectedLeadActivity.id !==
            prevProps.selectedLeadActivity.id
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
        if (
            this.props.selectedLeadActivity.type !==
            prevProps.selectedLeadActivity.type
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }
    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        removeQuery(
            manageLeadQueryParamsEnum.selectedActivityID,
            this.props.history
        );
        this.props.updateSelectedLeadActivity();
    }

    /*
     * initSelected
     * if there is a query param, make sure it matches what is active in redux
     * if no query param and there is a selected.id, then reset what is in redux
     */
    initSelected = () => {
        if (
            this.props.queryParams.selectedActivityID &&
            this.props.queryParams.selectedActivityID !==
                this.props.selectedLeadActivity.id
        ) {
            this.props.setSelectedLeadActivityFromID(
                this.props.queryParams.selectedActivityID
            );
        } else if (
            this.props.queryParams.selectedActivityID === undefined &&
            this.props.selectedLeadActivity.id.length
        ) {
            this.props.updateSelectedLeadActivity();
        }
    };

    /*
     * itemToFormValues - take the selectedLeadActivity and convert it to formValues
     */
    itemToFormValues = (): IeditLeadActivityFormValues => {
        let {
            type,
            activityDate,
            followUpScheduled
        } = this.props.selectedLeadActivity;

        const selectedActivityType: IoptionNumberValue = {
            value: type,
            label: this.props.t(leadActivityTypesEnum[type])
        };

        if (!activityDate) {
            activityDate = moment.utc().format('DD-MMM-YY');
        }

        return {
            ...this.props.selectedLeadActivity,
            type: selectedActivityType,
            activityDate,
            followUpScheduled
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedLeadActivity
     */
    formValuesToItem = (updatedValue?: {
        [key: string]: any;
    }): IleadActivity => {
        let formValues = this.formGroup.value;
        if (updatedValue) {
            formValues = { ...formValues, ...updatedValue };
        }

        const cleanedFormValues = omit(
            FormUtil.getValues(formValues),
            'shouldFollowUp'
        );
        return {
            // ...initialLeadActivity,
            ...this.props.selectedLeadActivity,
            leadID: this.props.selectedLead.id,
            ...cleanedFormValues
        };
    };

    buildFieldConfig = (
        defaultValues: IeditLeadActivityFormValues = this.itemToFormValues()
    ) => {
        console.info(
            'defaultValues',
            defaultValues,
            defaultValues.followUpScheduled &&
                defaultValues.followUpScheduled.length === 0
        );
        const disabled = false;
        // Field config to configure form
        const fieldConfigControls = {
            type: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        leadActivityTypesEnum
                    ),
                    label: 'manageLeads:type',
                    colWidth: 12,
                    placeholder: 'manageUser:customerSearchPlaceholder',
                    name: 'activityType',
                    shouldTranslate: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: defaultValues.type,
                    disabled
                }
            },
            otherType: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'leadActivityForm.other',
                    colWidth: 12,
                    name: 'other',
                    placeholder: 'leadActivityForm.otherPlaceholder'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: defaultValues.otherType,
                    disabled
                }
            },
            activityDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'leadActivityForm.activityDate',
                    colWidth: 12,
                    showTime: false,
                    name: 'date',
                    placeholder: 'common:datePlaceholder'
                    // isValidDate: (current: any) => {
                    //   return (
                    //     moment.isMoment(current) &&
                    //     current.isAfter(moment().subtract(1, 'day'))
                    //   );
                    // }
                },
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        FormUtil.validators.isValidMoment
                    ]
                },
                formState: {
                    value: defaultValues.activityDate,
                    disabled
                }
            },
            notes: {
                options: {
                    validators: FormUtil.validators.requiredRichText
                },
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'leadActivityForm.notes',
                    colWidth: 12,
                    name: 'notes',
                    required: false,
                    rows: 6,
                    initialContent: defaultValues.notes
                },
                formState: { value: defaultValues.notes, disabled }
            },
            shouldFollowUp: {
                render: FormUtil.Toggle,
                meta: {
                    label: 'leadActivityForm.shouldFollowUp',
                    colWidth: 6,
                    required: true
                },
                formState: {
                    value:
                        defaultValues.followUpScheduled &&
                        defaultValues.followUpScheduled.length > 0,
                    disabled
                }
            },
            followUpScheduled: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'leadActivityForm.followUpScheduled',
                    colWidth: 6,
                    showTime: false,
                    alignRight: true,
                    name: 'follow-up-date',
                    placeholder: 'common:datePlaceholder',
                    required: true,
                    isValidDate: (current: any) => {
                        return (
                            moment.isMoment(current) &&
                            current.isAfter(moment().subtract(1, 'day'))
                        );
                    }
                },
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        FormUtil.validators.isValidMoment
                    ]
                },
                formState: {
                    value: defaultValues.followUpScheduled,
                    disabled
                }
            },
            followUpNote: {
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'leadActivityForm.followUpNote',
                    colWidth: 12,
                    name: 'note',
                    required: false,
                    initialContent: defaultValues.followUpNote
                },
                formState: { value: defaultValues.followUpNote, disabled }
            }
        } as { [key: string]: GroupProps };

        let cleanedFieldConfigControls = fieldConfigControls;

        if (!defaultValues.followUpScheduled) {
            cleanedFieldConfigControls = omit(fieldConfigControls, [
                'followUpScheduled',
                'followUpNote'
            ]);
        }
        if (defaultValues.type.value !== leadActivityTypesEnum.other) {
            cleanedFieldConfigControls = omit(
                cleanedFieldConfigControls,
                'otherType'
            );
        }

        const fieldConfig = {
            controls: { ...cleanedFieldConfigControls }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the values to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'shouldFollowUp': {
                if (value) {
                    const defaultFollowUp = moment
                        .utc()
                        .add(1, 'week')
                        .format(constants.momentSQLFormat);
                    this.updateSelectedLeadActivityDebounced(
                        this.formValuesToItem({
                            [key]: value,
                            followUpScheduled: defaultFollowUp
                        })
                    );
                } else {
                    this.updateSelectedLeadActivityDebounced(
                        this.formValuesToItem({
                            [key]: value,
                            followUpScheduled: ''
                        })
                    );
                }
                break;
            }
            //   case 'followUpScheduled': {
            //     this.checkIfStartDateBeforeEndDate({ endDate: value });
            //     if (moment.isMoment(value)) {
            //       value = value.format(constants.momentSQLFormat);
            //     }
            //   }
            default:
                this.updateSelectedLeadActivityDebounced(
                    this.formValuesToItem({ [key]: value })
                );
                break;
        }
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        if (this.props.selectedLeadActivity.id.length) {
            console.info(
                'form submit',
                this.formGroup.value,
                this.formValuesToItem()
            );
            this.props.updateLeadActivity(this.formValuesToItem());
        } else {
            this.props.create(this.formValuesToItem());
        }
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix lead-activity-form beacon-form ${this.props.colorButton}`;
        const deleteButtonStyle =
            this.props.selectedLeadActivity.id.length === 0
                ? { marginRight: '15px', display: 'none' }
                : { marginRight: '15px' };

        return (
            <form onSubmit={this.handleSubmit} className={formClassName}>
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={() => {
                            this.props.toggleModal();
                            //   this.props.updateSelectedJob();
                            //   removeQuery(
                            //     manageJobQueryParamsEnum.selectedJobID,
                            //     this.props.history
                            //   );
                        }}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        bsStyle="warning"
                        style={deleteButtonStyle}
                        type="button"
                        className=""
                        disabled={this.props.loading}
                        onClick={() =>
                            this.props.delete(
                                this.props.selectedLeadActivity.id,
                                t
                            )
                        }
                    >
                        {t('common:delete')}
                    </Button>
                    <Button
                        bsStyle={this.props.colorButton}
                        type="submit"
                        disabled={this.props.loading}
                    >
                        {t('common:save')}
                    </Button>
                </Col>
            </form>
        );
    }
}
export default EditLeadActivityForm;
