/*
 * Measurement Point Results Reducer
 * This reducer was copied from the mobile app
 */

import {
    ImeasurementPointResultsReducer,
    ImeasurementPointResult,
    ImeasurementPointAnswer,
    IinstallBaseHistory
} from '../models';
import {
    initialMeasurmentPointResult,
    initialMeasurementPointResultAnswer,
    initialInstallBaseHistory
} from './initialState';
import * as types from '../actions/actionTypes';
import { keyBy, pickBy, filter } from 'lodash';
import { combineReducers } from 'redux';

const cleanResult = (
    result: ImeasurementPointResult
): ImeasurementPointResult => {
    let cleanedAnswers: ImeasurementPointAnswer[] = [];
    if (
        result.measurementPointAnswers &&
        result.measurementPointAnswers.length
    ) {
        cleanedAnswers = result.measurementPointAnswers.map(answer => {
            return {
                ...initialMeasurementPointResultAnswer,
                ...pickBy(answer, (property, key) => property !== null)
            };
        });
    }
    return {
        ...initialMeasurmentPointResult,
        ...pickBy(result, (property, key) => property !== null),
        measurementPointAnswers: cleanedAnswers
    };
};

const measurementPointResultsByID = (
    state: { [key: string]: ImeasurementPointResult } = {},
    action: any
): { [key: string]: ImeasurementPointResult } => {
    switch (action.type) {
        case types.ADD_MEASUREMENT_POINT_RESULT: {
            return { ...state, [action.result.id]: cleanResult(action.result) };
        }
        case types.UPDATE_MEASUREMENT_POINT_RESULT: {
            return { ...state, [action.result.id]: cleanResult(action.result) };
        }
        case types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS: {
            const newResults = keyBy(
                action.results.map((res: ImeasurementPointResult) =>
                    cleanResult(res)
                ),
                'id'
            );
            return { ...state, ...newResults };
        }
        case types.GET_INVENTORY_SUCCESS: {
            let measurementPointResults: ImeasurementPointResult[] = [];
            action.inventory.forEach(({ product }: any) => {
                // these objects do not include the measurement point result answers
                product.installBases.forEach(
                    ({ latestMeasurementPointListResult }: any) => {
                        if (latestMeasurementPointListResult) {
                            measurementPointResults = [
                                ...measurementPointResults,
                                latestMeasurementPointListResult
                            ];
                        }
                    }
                );
            });
            return { ...state, ...keyBy(measurementPointResults, 'id') };
        }
        case types.GET_MEASUREMENT_POINT_RESULT_SUCCESS: {
            return {
                ...state,
                [action.payload.id]: cleanResult(action.payload)
            };
        }
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
};

export const selectInstallBaseHistory = (
    state: ImeasurementPointResultsReducer
) => {
    return state.installBaseHistory;
};

const installBaseHistory = (
    state: IinstallBaseHistory[] = initialInstallBaseHistory,
    action: any
): IinstallBaseHistory[] => {
    if (action !== undefined) {
        switch (action.type) {
            case types.GET_MEASUREMENT_POINT_HISTORY_BY_INSTALLBASE_SUCCESS: {
                const history: IinstallBaseHistory[] = [];

                action.results.forEach((item: any) => {
                    // Create model with camelCase propteries
                    const mapped: IinstallBaseHistory = {
                        id: item.Id,
                        date: item.Date,
                        notes: item.Notes,
                        jobType: item.JobType,
                        status: item.Status,
                        workOrderId: item.WorkOrderID
                    };

                    // id acts as key for the View and when user clicks on a record (SET_HISTORICAL_RESULT_ID), i.e. we really need to have an id
                    if (mapped.id === undefined) {
                        mapped.id = mapped.workOrderId;
                    }

                    history.push(mapped);
                });

                // Sort by date
                history.sort(function(a, b) {
                    if (a.date > b.date) return -1;
                    if (a.date < b.date) return 1;
                    return 0;
                });

                return history;
            }
            default:
                return state;
        }
    }
    return state;
};

const selectedResult = (
    state: ImeasurementPointResult = initialMeasurmentPointResult,
    action: any
): ImeasurementPointResult => {
    switch (action.type) {
        case types.ADD_MEASUREMENT_POINT_RESULT:
            return action.result;
        case types.UPDATE_MEASUREMENT_POINT_RESULT:
            // if this has been submitted to the server, it is no longer temporary and can no longer be edited.  Thus it should not be in the currentRestult reducer
            if (action.result.temporary === false) {
                console.info(
                    'submitting a result, should we update selectedResultReducer?'
                );
                // return state;
            }
            if (state.id === action.result.id) {
                return { ...state, ...cleanResult(action.result) };
            } else {
                return cleanResult(action.result);
            }
        case types.RESET_MEASUREMENT_POINT_RESULT:
            return initialMeasurmentPointResult;
        case types.USER_LOGOUT_SUCCESS:
            return initialMeasurmentPointResult;
        default:
            return state;
    }
};

const previousResult = (
    state: ImeasurementPointResult = initialMeasurmentPointResult,
    action: any
): ImeasurementPointResult => {
    switch (action.type) {
        case types.SET_PREVIOUS_RESULT:
            return action.result;
        case types.RESET_PREVIOUS_RESULT:
            return initialMeasurmentPointResult;
        case types.USER_LOGOUT_SUCCESS:
            return initialMeasurmentPointResult;
        default:
            return state;
    }
};

/*
 * enable viewing of historical results
 */
const historicalResultID = (state = '', action: any): string => {
    switch (action.type) {
        case types.SET_HISTORICAL_RESULT_ID:
            console.info(action);
            return action.resultID;
        case types.CLEAR_HISTORICAL_RESULT_ID:
            return '';
        case types.USER_LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
};

const measurementPointResultsReducer = combineReducers<
    ImeasurementPointResultsReducer
>({
    measurementPointResultsByID,
    historicalResultID,
    selectedResult,
    previousResult,
    installBaseHistory
});

export default measurementPointResultsReducer;

/*
 * Selectors
 */

// export const selectMeasurementPointResultsByInstallBaseID = (
//   state: { [key: string]: ImeasurementPointResult },
//   installBaseID: string
// ) => {
//   const filteredResults = filter(
//     state,
//     result =>
//       result.temporary !== true && result.installBaseID === installBaseID
//   );
//   return orderBy(
//     filteredResults,
//     res => moment.utc(res.updateDate).unix(),
//     'desc'
//   );
// };

export const selectMeasurementPointListResultsByInstallBaseID = (
    state: ImeasurementPointResultsReducer,
    installBaseID: string
) => {
    return filter(
        state.measurementPointResultsByID,
        result =>
            result.installBaseID === installBaseID &&
            result.isDeleted === false &&
            result.temporary !== true
    );
};
