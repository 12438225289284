/*
 * Edit Job Comment Connector
 */

import { connect } from 'react-redux';

import {
    toggleEditJobCommentModal,
    setSelectedJobComment,
    resolveJobComment
} from '../../actions/manageJobCommentsActions';
import { EditJobCommentForm } from './EditJobCommentForm';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const {
        showEditJobCommentModal,
        selectedJobComment,
        jobCommentsByID
    } = state.manageJobComment;
    return {
        title: ownProps.t('titleJobCommentModal'),
        className: 'edit-comment',
        user: state.user,
        loading: selectIsLoading(state),
        show: showEditJobCommentModal,
        selectedJobComment,
        jobCommentsByID,
        deficiencyCodesByID: state.manageCode.deficiencyCodesByID
    };
};

export const EditJobCommentContainer = withTranslation('manageJobComments')(
    connect(mapStateToProps, {
        toggleEditJobCommentModal,
        setSelectedJobComment,
        resolveJobComment,
        onHide: toggleEditJobCommentModal
    })(WithCommonModal(EditJobCommentForm))
);
