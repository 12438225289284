import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { forEach, map } from 'lodash';
import * as React from 'react';
import {
    Button,
    Col,
    ControlLabel,
    Dropdown,
    FormGroup,
    ListGroup,
    ListGroupItem,
    MenuItem
} from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup as ReactiveFormGroup
} from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';
import { constants } from '../../constants/constants';
import { IbaseFormMeta } from '../../modelsForms';
import { FormUtil } from '../common/FormUtil';
import CommonModal from './CommonModal';
const uuidv4 = require('uuid/v4');

const initialItem = {
    isDeleted: false,
    id: ''
};
export interface ImultiControlMeta extends IbaseFormMeta {
    t: TFunction;
    buttonLabel: string;
    colorButton: string;
    modalTitle: string;
    getItemTitle: (item: { id: string } | any) => string;
    // actions - for passing in labels and functions to show in the dropdown
    actions: Array<{
        onClick: (item: { id: string }) => void;
        label: string;
        getTitle?: (item: typeof initialItem | any) => string;
    }>;
    disableEdit?: boolean;
    disableDelete?: boolean;
    itemEditFieldConfig: FieldConfig;
    onValueChanges?: (
        item: typeof initialItem | any,
        formGroup?: FormGroup
    ) => void; // callback with the changes to the item and the multiControlFormGroup (for use when you need to patch)
    readonly?: boolean;
}
interface Iprops {
    meta: ImultiControlMeta;
    handler: any;
    pristine: boolean;
    errors: any;
    submitted: boolean;
}

interface Istate {
    showModal: boolean;
    selectedItem: typeof initialItem;
    itemEditFieldConfig: FieldConfig;
}

interface ImultiDropdownProps extends Iprops {
    item: typeof initialItem;
    index: number;
    handleSelect: (eventKey: any, item: any) => void;
}

/*
 * MultiDropdown
 * dropdown for multiple actions
 */
const MultiDropdown = (props: ImultiDropdownProps) => (
    <li className="list-group-item" style={{ padding: '0' }} key={props.index}>
        <Dropdown
            title={props.meta.getItemTitle(props.item)}
            id={`dropdown-${props.index}`}
            key={props.index}
            onSelect={(eventKey: any) => {
                props.handleSelect(eventKey, props.item);
            }}
            style={{
                textAlign: 'left'
            }}
        >
            <Dropdown.Toggle
                noCaret
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    textAlign: 'left',
                    width: '100%',
                    border: 'none',
                    borderRadius: '4px',
                    fontSize: '1.1em',
                    fontWeight: 300
                }}
                bsStyle="default"
            >
                <span
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {props.meta.getItemTitle(props.item)}
                </span>
                <FontAwesomeIcon
                    icon="chevron-circle-down"
                    className="pull-right"
                />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {props.meta.disableEdit !== true && (
                    <MenuItem eventKey={10}>
                        {props.meta.t('common:edit')}
                    </MenuItem>
                )}
                {props.meta.disableDelete !== true && (
                    <MenuItem eventKey={11}>
                        {props.meta.t('common:delete')}
                    </MenuItem>
                )}
                {props.meta.actions &&
                    props.meta.actions.map((actionItem, actionIndex) => {
                        return (
                            <MenuItem
                                eventKey={actionIndex}
                                key={actionIndex}
                                title={
                                    actionItem.getTitle
                                        ? actionItem.getTitle(props.item)
                                        : ''
                                }
                            >
                                {props.meta.t(actionItem.label)}
                            </MenuItem>
                        );
                    })}
            </Dropdown.Menu>
        </Dropdown>
    </li>
);

/*
 * SingleButton Component
 * when there is only a single action
 */
const SingleButton = (props: ImultiDropdownProps) => (
    <ListGroupItem
        key={props.index}
        style={{
            textAlign: 'left',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            fontSize: '1.1em'
        }}
        onClick={() => props.handleSelect(0, props.item)}
    >
        <span
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}
        >
            {props.meta.getItemTitle(props.item)}
        </span>
        <FontAwesomeIcon icon="chevron-circle-right" className="pull-right" />
    </ListGroupItem>
);

class AddMultiControlClass extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    constructor(props: Iprops) {
        super(props);
        this.state = {
            showModal: false,
            selectedItem: initialItem,
            itemEditFieldConfig: FormUtil.translateForm(
                this.props.meta.itemEditFieldConfig,
                props.meta.t
            )
        };
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    // @jfbloom22 this does not work because patching the multiControl does not trigger didUpdate.
    // componentDidUpdate(prevProps: Iprops) {
    //     if (
    //         prevProps.meta.itemEditFieldConfig !==
    //         this.props.meta.itemEditFieldConfig
    //     ) {
    //         console.info('we are in addMultiControl settings fieldConfig');
    //         this.setState({
    //             itemEditFieldConfig: this.props.meta.itemEditFieldConfig
    //         });
    //     }
    // }

    componentDidUpdate(prevProps: Iprops, prevState: Istate) {
        if (
            this.props.meta.onValueChanges &&
            prevState.showModal !== this.state.showModal
        ) {
            this.props.meta.onValueChanges(this.state.selectedItem);
        }
    }

    handleAdd = () => {
        this.setState({
            showModal: true,
            selectedItem: { ...initialItem }
        });
    };

    handleDelete = (item: typeof initialItem) => {
        const toastrConfirmOptions = {
            onOk: () => {
                const filteredItems = this.props
                    .handler()
                    .value.map((i: typeof initialItem) => {
                        if (i.id === item.id) {
                            return { ...i, isDeleted: true };
                        } else {
                            return i;
                        }
                    });
                this.props.handler().onChange(filteredItems);
                this.setState({ showModal: false });
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.meta.t('common:delete'),
            cancelText: this.props.meta.t('common:cancel')
        };
        toastr.confirm(
            this.props.meta.t('common:deleteConfirm'),
            toastrConfirmOptions
        );
    };

    handleSelect = (eventKey: any, item: any) => {
        switch (eventKey) {
            case 10:
                this.setState({ showModal: true, selectedItem: item });
                if (this.props.meta.onValueChanges) {
                    this.props.meta.onValueChanges(item, this.formGroup);
                }
                break;
            case 11: {
                this.handleDelete(item);
            }
            default:
                // check the actions
                if (
                    this.props.meta.actions &&
                    this.props.meta.actions.length &&
                    this.props.meta.actions[eventKey]
                ) {
                    this.props.meta.actions[eventKey].onClick(item);
                }
                break;
        }
    };
    setForm = (form: ReactiveFormGroup | FormArray) => {
        this.formGroup = form;
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
        this.patchFormValues();
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        const item = {
                            ...this.formGroup.value,
                            [key]: value
                        } as typeof initialItem | any;
                        if (this.props.meta.onValueChanges) {
                            this.props.meta.onValueChanges(
                                item,
                                this.formGroup
                            );
                        }
                    });
            }
        }
    };

    patchFormValues = () => {
        forEach(this.props.meta.itemEditFieldConfig.controls, (field, key) => {
            if (key in this.state.selectedItem) {
                // remove type because typescript does not think a string can be uses as an index type
                const untypedSelectedItem = this.state.selectedItem as any;
                FormUtil.patchControl(
                    this.formGroup,
                    key,
                    untypedSelectedItem[key]
                );
            }
        });
    };

    isSingleAction = () => {
        if (
            this.props.meta.actions.length === 1 &&
            this.props.meta.disableDelete &&
            this.props.meta.disableEdit
        ) {
            return true;
        } else {
            return false;
        }
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        console.info(this.formGroup.value);
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.meta.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        // update the array
        const originalItems: Array<{
            id: string;
        }> = this.props.handler().value || [];
        const filteredItems = originalItems.filter(
            item => item.id !== this.state.selectedItem.id
        );
        const updatedItem = this.state.selectedItem.id
            ? this.state.selectedItem
            : { ...this.state.selectedItem, id: uuidv4() };
        const updatedItems = [
            ...filteredItems,
            { ...updatedItem, ...this.formGroup.value }
        ];
        this.props.handler().onChange(updatedItems);
        this.setState({ showModal: false });
    };

    render() {
        const { pristine, errors, submitted } = this.props;
        const { t, colorButton } = this.props.meta;
        const deleteButtonStyle =
            this.state.selectedItem && this.state.selectedItem.id.length === 0
                ? { marginRight: '15px', display: 'none' }
                : { marginRight: '15px' };
        return (
            <Col xs={this.props.meta.colWidth} className="add-multi-control">
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                >
                    <ControlLabel
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        {this.props.meta.label}
                    </ControlLabel>
                    <ListGroup>
                        {this.props.handler().value.length > 0 &&
                            map(
                                this.props.handler().value,
                                (item: any, index: number) => {
                                    if (item.isDeleted === true) {
                                        return '';
                                    }
                                    if (this.isSingleAction() === false) {
                                        return (
                                            <MultiDropdown
                                                {...this.props}
                                                handleSelect={this.handleSelect}
                                                item={item}
                                                index={index}
                                            />
                                        );
                                    } else {
                                        return (
                                            <SingleButton
                                                {...this.props}
                                                handleSelect={this.handleSelect}
                                                item={item}
                                                index={index}
                                            />
                                        );
                                    }
                                }
                            )}
                    </ListGroup>
                    {this.props.meta.readonly !== true && (
                        <Button
                            onClick={this.handleAdd}
                            // bsStyle={this.props.meta.colorButton}
                            bsStyle="default"
                            className="pull-right"
                            disabled={this.props.handler().disabled}
                        >
                            <FontAwesomeIcon icon={['far', 'plus']} />{' '}
                            {this.props.meta.buttonLabel}
                        </Button>
                    )}
                </FormGroup>
                <CommonModal
                    show={this.state.showModal}
                    onHide={() => console.info('hide modal')}
                    className="add-multi-modal"
                    secondModal={true}
                    title={t(this.props.meta.modalTitle)}
                >
                    <form onSubmit={this.handleSubmit}>
                        <FormGenerator
                            onMount={this.setForm}
                            fieldConfig={this.state.itemEditFieldConfig}
                        />
                        <Col xs={12} className="form-buttons text-right">
                            <Button
                                bsStyle="default"
                                type="button"
                                className="pull-left"
                                onClick={() =>
                                    this.setState({
                                        showModal: false,
                                        selectedItem: initialItem
                                    })
                                }
                            >
                                {t('common:cancel')}
                            </Button>
                            <Button
                                bsStyle="danger"
                                style={deleteButtonStyle}
                                type="button"
                                onClick={() =>
                                    this.handleDelete(this.state.selectedItem)
                                }
                            >
                                {t('common:delete')}
                            </Button>

                            <Button bsStyle={colorButton} type="submit">
                                {t('save')}
                            </Button>
                        </Col>
                    </form>
                </CommonModal>
            </Col>
        );
    }
}

export const AddMultiControl = (props: Iprops) => {
    return <AddMultiControlClass {...props} />;
};
