import { debounce } from 'lodash';
import * as React from 'react';
import { Button, Col } from 'react-bootstrap';
import { WithTranslation } from 'react-i18next';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    ValidatorFn,
    Validators
} from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';

import {
    FacilitiesSearchParams,
    searchFacilitiesForAsyncSelect,
    toggleEditFacilityModal
} from '../../actions/commonActions';
import {
    saveQueueUser,
    updateSelectedUserQueue
} from '../../actions/manageUserQueueActions';
import { constants } from '../../constants/constants';
import { Icountry, Ifacility, Ioption, Iuser, IuserQueue } from '../../models';
import { userTypeEnum } from '../../models-enums';
import { IgenericFormValues } from '../../modelsForms';
import EditFacilityModal from '../common/EditFacilityModal';
import {
    FormUtil,
    MultiValueLabel,
    MultiValueOption
} from '../common/FormUtil';
import { userBaseConfigControls } from '../common/UserBaseConfigControls';
import {
    editUserFormValuesToItem,
    editUserItemToFormValues
} from '../manageUser/EditUserUtils';
import { manageUserQueueQueryParamsEnum } from './ManageUserQueue';

interface Iprops {
    loading: boolean;
    colorButton: string;
    getFacilitiesByCountry: (params: FacilitiesSearchParams) => Promise<void>;
    facilityOptionsWithAddress: Ioption[];
    toggleEditFacilityModal: typeof toggleEditFacilityModal;
    onSubmit: typeof saveQueueUser;
    approveUser: (userQueueID: string) => void;
    selectedUserQueue: IuserQueue;
    facilitiesByID: { [key: string]: Ifacility };
    closeModal: () => void;
    onChange: typeof updateSelectedUserQueue;
    queryParams: typeof manageUserQueueQueryParamsEnum;
    originalUserQueue: IuserQueue;
    searchFacilitiesForAsyncSelect: typeof searchFacilitiesForAsyncSelect;
}

const buildAddress = (user: Iuser) => {
    const { tempAddress, tempAddress2, tempZip, tempState, tempCity } = user;
    if (!tempAddress || (tempAddress && !tempAddress.length)) {
        return '';
    }
    if (tempAddress2) {
        return `${tempAddress} ${tempAddress2} ${tempCity} ${tempState} ${tempZip}`;
    }
    return `${tempAddress} ${tempCity} ${tempState} ${tempZip}`;
};

const UserQueueForm = (props: Iprops & WithTranslation) => {
    const { t, selectedUserQueue } = props;
    const [form, setForm] = React.useState<FormGroup | FormArray>();

    const itemToFormValues = React.useCallback(() => {
        const { user } = selectedUserQueue;
        return editUserItemToFormValues({ user, t });
    }, []);

    const [formValues, setFormValues] = React.useState<
        IgenericFormValues<Iuser>
    >(itemToFormValues());
    const { facilities, engineerVanID, type, countryID } = formValues;

    React.useEffect(() => {
        if (!form) {
            return;
        }

        form.valueChanges.subscribe((values: any) => {
            // If the country changed, we need to remove all facilities.
            if (values?.countryID?.value !== countryID) {
                values.facilities = [];
            }

            setFormValues({
                ...values,
                countryID: values?.countryID?.value
            });
        });

        return () => {
            form.valueChanges.unsubscribe(() => {});
        };
    }, [form]);

    const getEngineerValidators = (value: any): ValidatorFn[] => {
        // For non US - 2 digit number
        // for US - 4 characters alphanumeric
        if (value?.value === constants.ukCountryID) {
            return [
                Validators.pattern('^[0-9]+$'),
                Validators.maxLength(3),
                Validators.minLength(3)
            ];
        } else if (value?.value !== constants.usCountryID) {
            return [
                Validators.pattern('^[0-9]+$'),
                Validators.maxLength(2),
                Validators.minLength(2)
            ];
        } else {
            return [
                Validators.pattern('^[0-9A-Za-z]+$'),
                Validators.maxLength(4),
                Validators.minLength(4)
            ];
        }
    };
    const loadOptions = debounce((searchTerm, callback) => {
        if (searchTerm.length < 3) {
            callback([]);
            return;
        }

        const countryID = formValues.countryID?.toString() || '';

        props.searchFacilitiesForAsyncSelect(
            searchTerm,
            countryID,
            null,
            callback,
            () => {}
        );
    }, 100);

    const fieldConfig = React.useMemo(() => {
        const disabled = false;
        const providedAddress = buildAddress(selectedUserQueue.user);
        const { tempCompany } = selectedUserQueue.user;
        // If the user has been saved and this modal is re-opened
        const selectedUserType = selectedUserQueue.user.type;
        // This allows placeholder to show up if userType has not been selected

        let typeU = type;
        if (selectedUserType) {
            typeU = {
                value: selectedUserType,
                label: userTypeEnum[selectedUserType]
            };
        } else {
            typeU = undefined;
        }

        const engineerValidation = getEngineerValidators(countryID);
        const selectedCountry: Icountry | null = countryID
            ? constants.countries[countryID.toString()]
            : null;

        // Field config to configure form
        const fieldConfigControls = {
            countryID: {
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                render: FormUtil.Select,
                meta: {
                    options: constants.countries,
                    label: 'user:country',
                    colWidth: 12,
                    placeholder: 'manageUserQueue:countrySearchPlaceholder',
                    name: 'country'
                },
                formState: { value: selectedCountry, disabled }
            },
            facilities: {
                render: FormUtil.AsyncSelect,
                meta: {
                    label: 'common:facility',
                    loadOptions,
                    colWidth: 12,
                    placeholder: 'facilitySearchPlaceholder',
                    buttonName: 'facilityButton',
                    buttonAction: props.toggleEditFacilityModal,
                    isMulti: true,
                    multiValueLabel: MultiValueLabel,
                    multiValueOption: MultiValueOption
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: facilities,
                    disabled
                }
            },
            type: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(userTypeEnum),
                    label: 'userType',
                    colWidth: 12,
                    placeholder: 'facilitySearchPlaceholder',
                    name: 'type',
                    shouldTranslate: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: typeU,
                    disabled
                }
            },
            engineerVanID: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'manageUser:engineerVanID',
                    colWidth: 6,
                    name: 'engineerVanID',
                    shouldTranslate: true,
                    required: false
                },
                options: {
                    validators: engineerValidation
                },
                formState: {
                    value: engineerVanID,
                    disabled
                }
            },
            providedCompany: {
                render: FormUtil.TextLabel,
                meta: { label: 'providedCompany', colWidth: 12 },
                formState: { value: tempCompany, disabled }
            },
            providedAddress: {
                render: FormUtil.TextLabel,
                meta: { label: 'providedAddress', colWidth: 12 },
                formState: { value: providedAddress, disabled }
            }
        };
        const fieldConf: FieldConfig = {
            controls: {
                ...userBaseConfigControls(itemToFormValues()),
                ...fieldConfigControls
            }
        };

        return FormUtil.translateForm(fieldConf, props.t);
    }, [
        props.facilityOptionsWithAddress,
        formValues.countryID,
        selectedUserQueue
    ]);

    const formValuesToItem = (
        updatedValue?: IgenericFormValues<Iuser>
    ): IuserQueue => {
        const updatedUser = editUserFormValuesToItem({
            updatedValue: form?.value,
            formValues,
            facilitiesByID: props.facilitiesByID,
            selectedUser: selectedUserQueue.user
        });

        const facilities = form?.value?.facilities?.reduce(
            (carry: any, facilityOption: any) => {
                carry.push({
                    id: facilityOption.value,
                    name: facilityOption.label
                });
                return carry;
            },
            []
        );
        return {
            ...selectedUserQueue,
            user: {
                ...updatedUser,
                countryID: form?.value?.countryID?.value,
                facilities
            }
        };
    };

    const handleSubmit = (
        e: React.MouseEvent<HTMLFormElement>,
        shouldApprove = false
    ) => {
        e.preventDefault();
        if (form?.status === 'INVALID') {
            form?.markAsSubmitted();
            toastr.error(
                props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        let toSubmit = formValuesToItem();

        (Object.keys(toSubmit.user) as (keyof Iuser)[]).forEach(key => {
            if (key.includes('ID') && toSubmit.user[key] == '') {
                toSubmit.user = {
                    ...toSubmit.user,
                    [key]: null
                };
            }
        });

        props.onSubmit(toSubmit, shouldApprove);
        props.closeModal();
    };

    const manager = selectedUserQueue.user.manager || {
        first: '',
        last: ''
    };

    const formClassName = `clearfix beacon-form queue-form ${props.colorButton}`;

    return (
        <div>
            <form onSubmit={handleSubmit} className={formClassName}>
                {selectedUserQueue && selectedUserQueue.user.managerID && (
                    <Col xs={12}>
                        <div className="form-group form-group-sm">
                            <label className="control-label">Manager:</label>
                            <h5 className="queue-form-label">
                                {manager.first}&nbsp;
                                {manager.last}
                            </h5>
                        </div>
                    </Col>
                )}
                <FormGenerator onMount={setForm} fieldConfig={fieldConfig} />

                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={props.closeModal}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        bsStyle={props.colorButton}
                        type="submit"
                        disabled={props.loading}
                        style={{ marginRight: '20px' }}
                    >
                        {t('common:save')}
                    </Button>
                    <Button
                        bsStyle={props.colorButton}
                        type="button"
                        disabled={props.loading}
                        onClick={(e: any) => handleSubmit(e, true)}
                    >
                        {t('saveApprove')}
                    </Button>
                </Col>
            </form>
            <EditFacilityModal
                colorButton={props.colorButton}
                secondModal={true}
                approveMode={false}
                searchForFacility={() => {
                    return;
                }}
                refreshTable={() => {
                    return;
                }}
            />
        </div>
    );
};

export default UserQueueForm;
