/*
 * Manage Location Modal
 */

import { connect } from 'react-redux';

import {
    saveAnyLocation,
    updateAnyLocation,
    toggleEditLocationModal
} from '../../actions/manageLocationActions';
import { updateQueueProduct } from '../../actions/manageProductQueueActions';
import EditLocationForm from './EditLocationForm';
import {
    initialLoc,
    initialFloor,
    initialBuilding
} from '../../reducers/initialState';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import {
    selectSelectedFacilityID,
    selectSelectedFacilityWithBuildings
} from '../../reducers/facilitiesReducer';

interface Iprops {
    selectedItem: any;
    selectedType: 'Building' | 'Floor' | 'Location' | 'Room';
    colorButton: any;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedFacilityID = selectSelectedFacilityID(state);
    const facility = selectSelectedFacilityWithBuildings(state);
    const selectedBuilding =
        facility.buildings.find(
            building =>
                building.id === state.manageLocation.tableFilters.buildingID
        ) || initialBuilding;
    const selectedFloor =
        selectedBuilding.floors.find(
            floor => floor.id === state.manageLocation.tableFilters.floorID
        ) || initialFloor;
    const selectedLocation =
        selectedFloor.locations.find(
            location =>
                location.id === state.manageLocation.tableFilters.locationID
        ) || initialLoc;
    const modalTitle =
        ownProps.selectedItem && ownProps.selectedItem.id
            ? ownProps.t(`manageLocation:edit${ownProps.selectedType}`)
            : ownProps.t(`manageLocation:new${ownProps.selectedType}`);

    return {
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageLocation.showEditLocationModal,
        tableFilters: state.manageLocation.tableFilters,
        facility,
        selectedFacilityID,
        selectedBuilding,
        selectedFloor,
        selectedLocation,
        className: 'user-edit',
        title: modalTitle
    };
};

export default withTranslation('manageLocation')(
    connect(mapStateToProps, {
        saveAnyLocation,
        updateAnyLocation,
        toggleModal: toggleEditLocationModal,
        onHide: toggleEditLocationModal,
        onCancel: toggleEditLocationModal,
        updateQueueProduct
    })(WithCommonModal(EditLocationForm))
);
