import { connect } from 'react-redux';
import { WithCommonModal } from '../common/WithCommonModal';
import QuoteSalesForm from './QuoteSalesForm';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Ihistory } from '../../models';
import { getSecurityUsers } from '../../actions/manageUserActions';
import { manageQuoteQueryParamsEnum } from './manageQuotes';
import {
    toggleSalesQuotesModal,
    updateSelectedQuote,
    saveQuoteFiles,
    updateQuoteStatus,
    toggleQuotesDetailsModal
} from '../../actions/manageCustomerQuotesActions';
import { initialFacility } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { getCloudDocument } from '../../actions/manageDocumentActions';

type Iprops = {
    colorButton: string;
    queryParams: typeof manageQuoteQueryParamsEnum;
    history: Ihistory;
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const { selectedQuote } = state.manageCustomerQuotes;
    const facility = selectedQuote.facilityID
        ? state.facilities[selectedQuote.facilityID]
        : initialFacility;

    return {
        show: state.manageCustomerQuotes.showSalesQuoteModal,
        loading: selectIsLoading(state),
        selectedQuote,
        originalQuote:
            state.manageCustomerQuotes.quotesByID[
                ownProps.queryParams.selectedQuoteID
            ],
        className: 'facility-contact-edit',
        title: ownProps.t('editCustomerQuoteForm.modalTitle'),
        facility
    };
};

export default withTranslation('manageQuotes')(
    connect(mapStateToProps, {
        onCancel: toggleSalesQuotesModal,
        onHide: toggleSalesQuotesModal,
        toggleModal: toggleSalesQuotesModal,
        toggleQuotesDetailsModal,
        onChange: updateSelectedQuote,
        onSubmit: updateQuoteStatus,
        getSecurityUsers,
        saveQuoteFiles,
        getCloudDocument
    })(WithCommonModal(QuoteSalesForm))
);
