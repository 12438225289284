import * as types from './actionTypes';
import { beginAjaxCall } from './ajaxStatusActions';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ItableFiltersParams, ThunkResult } from '../models';

import { msalFetch } from '../components/auth/Auth-Utils';

import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import { replaceEmptyStringsWithNull } from '../helpers/cleanObjEmptyStrings';

export function getProducts(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const {
            page,
            search,
            mainCategory,
            subCategory,
            brand,
            origin
        } = getState().manageProduct.tableFilters;

        const payload = replaceEmptyStringsWithNull({
            page,
            search,
            mainCategoryID: mainCategory ? mainCategory.value : '',
            subCategoryID: subCategory ? subCategory.value : '',
            brandID: brand ? brand.value : '',
            origin: origin ? origin.value : '',
            isApproved: true,
            standards: []
        });

        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: payload
        };

        const url = API.GET.inventory.products;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_MANAGE_PRODUCT_SUCCESS,
                        products: data.data.result
                    });
                    dispatch({
                        type: types.PRODUCT_MANAGE_TOTAL_PAGES,
                        pages: data.data.pages
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_MANAGE_PRODUCT_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get products');
                console.error(error);
            });
    };
}

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_PRODUCT,
    filters
});

export const toggleEditProductModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_PRODUCT
});
