import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import {
    IfacilityWithoutBuildings,
    ImanageUserReducer,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import ReactTable, { FinalState, RowInfo, SortingRule } from 'react-table';
import { closeAllModals } from '../../actions/commonActions';
import {
    getUserManage,
    setTableFilter,
    toggleSecurityFunctionsModal
} from '../../actions/manageUserActions';
import { emptyTile } from '../../reducers/initialState';
import { addQuery, hasQuery } from '../common/OtherUtils';

import { Banner } from '../common/Banner';
import CommonModal from '../common/CommonModal';
import EditUserModal from './EditUserModal';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import queryString from 'query-string';

/*
 * The New User Manage
 */
import { RouteComponentProps } from 'react-router-dom';
import SearchTableForm from '../common/SearchTableForm';
import SecurityFunctionsList from './SecurityFunctionsList';
import { TableUtil } from '../common/TableUtil';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { values } from 'lodash';
import { setSelectedUserID } from '../../actions/manageUserActions';
import moment from 'moment';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { getAllBrands } from '../../actions/manageBrandActions';
import { getCountries } from '../../reducers/countriesReducer';
import { loadCountries } from '../../actions/countriesActions';
import { getUsersCountries } from '../../reducers/userReducer';

interface RowInfoEdited extends RowInfo {
    original: Iuser;
}

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    loading: boolean;
    countries: any[];
    usersCountries: any[];
    dispatch: Function;
}

export enum manageUserQueryParamsEnum {
    selectedUserID = 'selectedUserID'
}

export enum UserType {
    Hospital = 1,
    Contractor,
    Plumber,
    Engineer,
    Internal,
    SurgeryCenter
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleSecurityFunctionsModal: typeof toggleSecurityFunctionsModal;
    getUserManage: typeof getUserManage;
    closeAllModals: typeof closeAllModals;
    userManage: ImanageUserReducer;
    showEditUserModal: boolean;
    showEditFacilityModal: boolean;
    showSecurityFunctionsModal: boolean;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    tableData: Iuser[];
    setSelectedUserID: typeof setSelectedUserID;
    queryParams: typeof manageUserQueryParamsEnum;
    getAllBrands: any;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
}

class UserManageChild extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    public searchFieldConfig: FieldConfig;
    public buttonInAction = false;
    private setTableFilterTimeout: any;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: null,
            currentTile: emptyTile,
            columns: []
        };
        this.searchFieldConfig = this.buildSearchControls();
    }
    componentDidMount() {
        const { countries } = this.props;
        loadCountries(this.props.dispatch, countries);
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname),
            columns: this.setColumns()
        });

        // refresh the userManage every time the component mounts
        this.props.getUserManage();
        this.props.getAllBrands();
    }
    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditUserModal !== this.props.showEditUserModal &&
            !this.props.showEditUserModal
        ) {
            this.setState({ selectedRow: null });
        }
        // automatically get inventory every time a fitler changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            console.info(
                'user manage filters changed',
                prevProps.tableFilters,
                this.props.tableFilters
            );
            this.props.getUserManage();
            this.setState({ selectedRow: null });
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
        // if (
        //   JSON.stringify(prevProps.customers) !==
        //   JSON.stringify(this.props.customers)
        // ) {
        //   this.setState({ columns: this.setColumns() });
        // }
        this.searchFieldConfig = this.buildSearchControls();
    }
    componentWillUnmount() {
        this.props.closeAllModals();
    }
    /*
     * Set Columns sets columns to state
     * setting columns here in order to reset them if and after we receive customers
     */
    setColumns = () => {
        return TableUtil.translateHeaders(
            [
                {
                    id: 'name',
                    Header: 'name',
                    // accessor: "user",
                    Cell: (row: any) => (
                        <span>
                            {row.original.first} {row.original.last}
                        </span>
                    )
                },
                {
                    Header: 'email',
                    accessor: 'email',
                    minWidth: 190
                },
                {
                    Header: 'manager',
                    accessor: ({ hasTeamMembers }: Iuser) => {
                        return hasTeamMembers ? 'Yes' : 'No';
                    },
                    id: 'manager',
                    width: 100
                },
                {
                    Header: 'login',
                    accessor: ({ lastLoginDate }: Iuser) => {
                        return lastLoginDate
                            ? moment
                                  .utc(lastLoginDate)
                                  .local(true)
                                  .format('DD-MMM-YY LT')
                            : 'n/a';
                    },
                    id: 'login'
                }
            ],
            this.props.t
        );
    };

    handleSelectUser = (id: string) => {
        addQuery(
            manageUserQueryParamsEnum.selectedUserID,
            id,
            this.props.history
        );
    };

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfoEdited | undefined) => {
        // console.info("ROWINFO", rowInfo);
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    if (!this.buttonInAction) {
                        this.setState({
                            selectedRow: rowInfo.index
                        });
                        this.handleSelectUser(rowInfo.original.id);
                    }
                },
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : '',
                    color: rowInfo.original.isActive
                        ? ''
                        : constants.colors.greyText
                }
            };
        } else {
            return {};
        }
    };
    /*
     * (reusable)
     * get the next or previous page of data.  the table is 0 indexed but the API is not
     */
    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };
    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'search':
                clearTimeout(this.setTableFilterTimeout);
                this.setTableFilterTimeout = setTimeout(() => {
                    this.props.setTableFilter({ search: value, page: 1 }); // this causes performance issues so we use a rudamentary debounce
                }, constants.tableSearchDebounceTime);
                break;
            case 'countryID':
                clearTimeout(this.setTableFilterTimeout);
                this.setTableFilterTimeout = setTimeout(() => {
                    this.props.setTableFilter({
                        countryID: value ?? '',
                        page: 1
                    });
                }, constants.tableSearchDebounceTime);
                break;
            case 'userType':
                clearTimeout(this.setTableFilterTimeout);
                this.setTableFilterTimeout = setTimeout(() => {
                    this.props.setTableFilter({
                        userType: value ?? '',
                        page: 1
                    });
                }, constants.tableSearchDebounceTime);
                break;
            default:
                break;
        }
    };
    /*
     * (reusable)
     * set the sorted changes to redux
     */
    onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };

    getFacilities = (tableData: Iuser[]) => {
        const data = tableData.reduce(
            (
                carry: { [key: string]: IfacilityWithoutBuildings },
                user: Iuser
            ) => {
                if (!user.facilities) {
                    return carry;
                }

                const facilities: {
                    [key: string]: IfacilityWithoutBuildings;
                } = user.facilities.reduce((
                    // eslint-disable-next-line no-shadow
                    carry: { [key: string]: IfacilityWithoutBuildings },
                    facility: IfacilityWithoutBuildings
                ) => {
                    return {
                        ...carry,
                        [facility.id]: facility
                    };
                }, {});

                return {
                    ...carry,
                    ...facilities
                };
            },
            {}
        );

        return data;
    };

    buildSearchControls = (): FieldConfig => {
        const { countries, usersCountries } = this.props;

        const countriesOptions = countries
            .filter(c => usersCountries?.includes(c.id))
            .map(c => ({ label: c.name, value: c.id }));

        const mainSearchControls = {
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:search',
                    colWidth: 4,
                    type: 'text',
                    placeholder: 'searchPlaceholder',
                    defaultValue: this.props.tableFilters.search
                }
            },
            userType: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'manageUser:userType',
                    options: FormUtil.convertEnumToOptions(UserType),
                    colWidth: 2,
                    type: 'select',
                    placeholder: 'manageUser:userTypePlaceholder',
                    defaultValue: this.props.tableFilters.userType,
                    isClearable: true
                }
            },
            countryID: {
                render: FormUtil.Select,
                meta: {
                    options: countriesOptions,
                    label: 'manageUser:country',
                    colWidth: 4,
                    colWidthMedium: 4,
                    colWidthLarge: 4,
                    placeholder: 'manageUser:countryPlaceholder',
                    name: 'country',
                    defaultValue: this.props.tableFilters.countryID,
                    isClearable: true
                }
            }
        };
        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    };

    render() {
        const { t, tableData } = this.props;
        const facilities = this.getFacilities(tableData);

        return (
            <div className="user-manage">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                <SearchTableForm
                    fieldConfig={this.searchFieldConfig}
                    handleSubmit={this.props.getUserManage}
                    loading={this.props.loading}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    t={this.props.t}
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                />
                <ReactTable
                    data={this.props.tableData}
                    onSortedChange={this.onSortedChanged}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    pageSize={
                        this.props.tableData.length >= 10
                            ? this.props.tableData.length
                            : 10
                    }
                    page={this.props.tableFilters.page - 1}
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={this.props.userManage.totalPages}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                />
                <EditUserModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    facilitiesByID={facilities}
                    history={this.props.history}
                    queryParams={this.props.queryParams}
                    queryKey={manageUserQueryParamsEnum.selectedUserID}
                />

                <CommonModal
                    show={this.props.showSecurityFunctionsModal}
                    className="security-modal second-modal"
                    onHide={this.props.toggleSecurityFunctionsModal}
                    title={t('securityFunctionsModalTitle')}
                    container={document.getElementById('two-pane-layout')}
                    backdrop={true}
                >
                    <SecurityFunctionsList
                        t={this.props.t}
                        toggleSecurityFunctionsModal={
                            this.props.toggleSecurityFunctionsModal
                        }
                    />
                </CommonModal>
            </div>
        );
    }
}

/*
 * AddCustomerModal will connect to redux, impliment CommonModal, as well as AddCustomerForm
 */

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const queryParams = queryString.parse(
        ownProps.location.search
    ) as typeof manageUserQueryParamsEnum;

    return {
        user: state.user,
        userManage: state.manageUser,
        loading: selectIsLoading(state),
        showEditUserModal: hasQuery(
            manageUserQueryParamsEnum.selectedUserID,
            ownProps.history
        ),
        queryParams,
        showEditFacilityModal: state.showEditFacilityModal,
        showSecurityFunctionsModal: state.showSecurityFunctionsModal,
        tableData: values(state.manageUser.usersByID),
        tableFilters: state.manageUser.tableFilters,
        countries: getCountries(state),
        usersCountries: getUsersCountries(state)
    };
};

export default withTranslation('manageUser')(
    connect(mapStateToProps, {
        getUserManage,
        toggleSecurityFunctionsModal,
        closeAllModals,
        setTableFilter,
        setSelectedUserID,
        getAllBrands
    })(UserManageChild)
);
