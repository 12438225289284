/*
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup
} from 'react-reactive-form';

import { TFunction } from 'i18next';
import { toastr } from 'react-redux-toastr';
import { updateSelectedJob } from '../../actions/manageJobActions';
import { getWorkOrders } from '../../actions/manageWorkOrderActions';
import { constants } from '../../constants/constants';
import { Ioption } from '../../models';
import { FormUtil } from '../common/FormUtil';

const uuidv4 = require('uuid/v4');

interface Iprops {
    loading: boolean;
    colorButton: string;
    t: TFunction;
    jobOptions: Ioption[];
    toggleModal: () => void;
    addWorkOrdersToJob: (
        selection: string[],
        jobID: string,
        t: TFunction
    ) => Promise<void>;
    selection: string[];
    getWorkOrders: typeof getWorkOrders;
    updateSelectedJob: typeof updateSelectedJob;
    // getAllOpenJobsForWorkOrders: typeof getAllOpenJobsForWorkOrders;
    history: any;
    showNewJobModal: () => void;
}

interface State {
    fieldConfig: FieldConfig;
}

export class ConfirmSelectJobForm extends React.Component<Iprops, State> {
    private formGroup: FormGroup | any;
    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        if (!this.formGroup) {
            return;
        }
        this.setState({ fieldConfig: this.buildFieldConfig() });
        // this.props.getAllOpenJobsForWorkOrders();
    }

    buildFieldConfig = () => {
        const fieldConfigControls = {
            jobID: {
                render: FormUtil.Select,
                meta: {
                    options: this.props.jobOptions,
                    label: 'common:job',
                    colWidth: 12,
                    placeholder: 'jobPlaceholder',
                    name: 'job'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                }
            }
        };

        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        const { jobID } = this.formGroup.value;
        this.props.toggleModal();
        this.props
            .addWorkOrdersToJob(this.props.selection, jobID.value, this.props.t)
            .then(() => {
                this.props.getWorkOrders();
            });
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
    };

    cleanForm = () => {
        this.formGroup.reset();
    };

    newJob = () => {
        this.props.showNewJobModal();
    };

    render() {
        const { t } = this.props;

        return (
            <form onSubmit={this.handleSubmit} className="clearfix beacon-form">
                <Col xs={12} style={{ marginBottom: '10px' }}>
                    {t('adding')} <b>{this.props.selection.length}</b>{' '}
                    {t('addingMessage')}
                </Col>

                <FormGenerator
                    onUnmount={this.cleanForm}
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                {this.props.jobOptions.length === 0 && (
                    <Col xs={12}>
                        <h5>{t('toastMessage:noJobsFound')}</h5>
                    </Col>
                )}
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.props.toggleModal}
                    >
                        {t('common:cancel')}
                    </Button>

                    {
                        <Button
                            style={{ marginRight: '15px' }}
                            bsStyle="default"
                            type="button"
                            onClick={this.newJob}
                        >
                            {t('nsJob:saveModalTitle')}
                        </Button>
                    }
                    <Button
                        bsStyle={this.props.colorButton}
                        type="submit"
                        disabled={this.props.loading}
                    >
                        {t('addToJobButton')}
                    </Button>
                </Col>
            </form>
        );
    }
}
