import * as React from 'react';
import { Col, DropdownButton, MenuItem } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { toastr } from 'react-redux-toastr';

import { Iuser, Ilead } from '../../models';
import { constants } from '../../constants/constants';
import { bulkUpdate } from '../../actions/manageLeadsActions';

interface Iprops {
    t: TFunction;
    colorButton: string;
    user: Iuser;
    selection: string[];
    leadsByID: { [key: string]: Ilead };
    bulkUpdate: typeof bulkUpdate;
}

export const LeadBulkActionsButton = (props: Iprops) => {
    const { t } = props;

    const checkSelectionLimit = () => {
        if (props.selection.length >= constants.bulkSelectionLimit) {
            toastr.warning(
                t('toastMessage:warning'),
                t('toastMessage:bulkSelectionLimit', {
                    limit: constants.bulkSelectionLimit
                }),
                constants.toastrWarning
            );
            return Promise.reject(false);
        } else if (props.selection.length === 0) {
            toastr.warning(
                t('toastMessage:warning'),
                t('toastMessage:bulkSelectionMissing', {
                    limit: constants.bulkSelectionLimit
                }),
                constants.toastrWarning
            );
            return Promise.reject(false);
        } else {
            return Promise.resolve(true);
        }
    };

    const copySelection = () => {
        if (props.selection) {
            const selectedLeads = props.selection.map(item => {
                const leadID = item.split('select-')[1];

                return props.leadsByID[leadID].leadNumber;
            });

            navigator.clipboard.writeText(selectedLeads.join(','));

            toastr.success(
                t('toastMessage:success'),
                t('toastMessage:copiedLeads'),
                constants.toastrSuccess
            );
        } else {
            toastr.warning(
                t('toastMessage:warning'),
                t('toastMessage:noLeadsCopied'),
                constants.toastrWarning
            );
        }
    };

    return (
        <Col
            xs={4}
            lg={2}
            className="table-action-button"
            style={{
                marginTop: '10px',
                marginBottom: '10px',
                marginLeft: '15px'
            }}
        >
            <DropdownButton
                bsStyle={props.colorButton}
                title={t('manageLeads:bulkActions')}
                id="table-action-button"
                onSelect={(eventKey: any) => {
                    switch (eventKey) {
                        case 1:
                            copySelection();
                            break;
                        case 2:
                            checkSelectionLimit().then(() => {
                                props.bulkUpdate(5, t);
                            });
                            break;
                    }
                }}
            >
                <MenuItem eventKey={1}>{t('addToClipboardButton')}</MenuItem>
                <MenuItem eventKey={2}>{t('archiveButton')}</MenuItem>
            </DropdownButton>
        </Col>
    );
};
