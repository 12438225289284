import {
    ImanageLocationReducer,
    Ibuilding,
    Ifloor,
    Ilocation,
    Iroom
} from '../models';
import {
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import * as types from '../actions/actionTypes';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { IinitialState } from '.';

const getVisibleLocations = (state: IinitialState) =>
    state.manageLocation.visibleLocations;

export const selectVisibleLocations = createSelector(
    [getVisibleLocations],
    locations => locations
);

function visibleLocations(
    state: Array<Ibuilding | Ifloor | Ilocation | Iroom> = [],
    action: any
): Array<Ibuilding | Ifloor | Ilocation | Iroom> {
    switch (action.type) {
        case types.SET_VISIBLE_LOCATIONS:
            return action.locations || [];
        case types.USER_LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}

function locationManageTotalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.LOCATION_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

const locationManage = combineReducers<ImanageLocationReducer>({
    visibleLocations,
    totalPages: (state, action) => locationManageTotalPages(state, action),
    showEditLocationModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_LOCATION'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_LOCATION')
});

export default locationManage;
