/*
Job Work Order Expander
*/

import * as React from 'react';

import { IWorkOrder, Ijob, IjobWorkOrder, Iuser } from '../../models';
import ReactTable, { Column, RowInfo, RowRenderProps } from 'react-table';

import { Button } from 'react-bootstrap';
import { TFunction } from 'i18next';
import { TableUtil } from '../common/TableUtil';
import moment from 'moment';
import { orderBy } from 'lodash';
import { workOrderStatusEnum } from '../../models-enums';

interface RowInfoEdited extends RowInfo {
    original: IjobWorkOrder;
}
interface ExpanderProps extends RowInfo {
    t: TFunction;
    original: Ijob;
    handleSelectJob: (id: string) => void;
    workOrders: IWorkOrder[];
    fseUsersByID: { [key: string]: Iuser };
}

/*
 * The Job Work Order Expander
 */
export const JobWorkOrderExpander: React.SFC<ExpanderProps> = (
    props: ExpanderProps
) => {
    const ExpanderButtonBar = (eProps: RowRenderProps) => {
        return (
            <span
                className="expander-button-bar text-right"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
            >
                <Button
                    bsStyle="link"
                    onClick={() => {
                        props.handleSelectJob(props.original.id);
                    }}
                >
                    {props.t('editJob')}
                </Button>
            </span>
        );
    };
    const sortedData = orderBy(
        props.workOrders,
        res => moment.utc(res.dueDate).unix(),
        'desc'
    );

    const expanderColumns = TableUtil.translateHeaders(
        [
            {
                Footer: ExpanderButtonBar,
                minWidth: 20,
                id: 'indent-column-button-bar'
            },
            {
                Header: 'manageWorkOrder:number',
                id: 'woNumber',
                accessor: (workOrder: IWorkOrder) => {
                    return workOrder.number;
                },
                minWidth: 100
            },
            {
                Header: 'manageWorkOrder:activity',
                id: 'activityDescription',
                accessor: (workOrder: IWorkOrder) => {
                    return workOrder.activityDescription;
                },
                minWidth: 220
            },
            {
                Header: 'manageWorkOrder:technician',
                id: 'technician',
                accessor: (workOrder: IWorkOrder) => {
                    const userID = workOrder.technician || workOrder.userID;
                    const user =
                        props.fseUsersByID[userID] ||
                        props.original.assignedUser;
                    return `${user?.first} ${user?.last}`;
                },
                minWidth: 150
            },
            {
                Header: 'manageWorkOrder:dueDate',
                id: 'dueDate',
                accessor: (workOrder: IWorkOrder) => {
                    return moment
                        .utc(workOrder.dueDate)
                        .local(true)
                        .format('DD-MMM-YY');
                }
            },
            {
                Header: 'status',
                id: 'status',
                accessor: (workOrder: IWorkOrder) => {
                    const statusString = workOrderStatusEnum[workOrder.status];

                    return (
                        <span className={`status ${statusString}`}>
                            {statusString
                                ? `${props.t(
                                      'manageWorkOrder:' + statusString
                                  )}`
                                : ''}
                        </span>
                    );
                },
                minWidth: 100
            }
        ],
        props.t
    ) as Column[];

    return (
        <div>
            <ReactTable
                className={'expander'}
                data={sortedData}
                sortable={false}
                columns={expanderColumns}
                minRows={0}
                showPageSizeOptions={false}
                rowsText="workOrders"
                noDataText={props.t('dashboardAnalytics:noDataText')}
                resizable={false}
                showPagination={
                    props.workOrders ? props.workOrders.length >= 10 : false
                }
            />
        </div>
    );
    // } else {
    // return null;
    // }
};
