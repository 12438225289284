import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../constants/apiEndpoints';
import { msalFetch } from '../components/auth/Auth-Utils';

import * as types from './actionTypes';
import { constants } from '../constants/constants';
import { IAlert, ThunkResult } from '../models';

export const addUserAlert = (alert: IAlert) => ({
    type: types.ADD_USER_ALERT,
    payload: alert
});

export const getUserAlerts = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        const url = API.GET.alert.user;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LOAD_USER_ALERTS_SUCCESS,
                        payload: data.data
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LOAD_USER_ALERTS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get alerts');
            })
            .finally(() => endAjaxCall());
    };
};

export const markAlertAsRead = (alert: IAlert): ThunkResult<any> => (
    dispatch,
    getState
) => {
    dispatch(beginAjaxCall());

    const axiosOptions: AxiosRequestConfig = {
        method: 'post'
    };

    const url = API.POST.alert.markAsRead.replace('{alertId}', alert.id);
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            dispatch({
                type: types.READ_USER_ALERT_SUCCESS,
                payload: alert.id
            });
        })
        .catch((error: any) => {
            dispatch({
                type: types.READ_USER_ALERT_FAILED,
                error,
                axiosOptions
            });
            constants.handleError(error, 'READ_USER_ALERT_FAILED');
        });
};

export const markAllAlertAsRead = (): ThunkResult<any> => (
    dispatch,
    getState
) => {
    dispatch(beginAjaxCall());

    const axiosOptions: AxiosRequestConfig = {
        method: 'post'
    };

    const url = API.POST.alert.markAllAsRead;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            dispatch({
                type: types.READ_ALL_USER_ALERTS_SUCCESS
            });
        })
        .catch((error: any) => {
            dispatch({
                type: types.READ_ALL_USER_ALERTS_FAILED,
                error,
                axiosOptions
            });
            constants.handleError(error, 'READ_ALL_USER_ALERT_FAILED');
        });
};

export const toggleUserAlertModal = () => ({
    type: types.TOGGLE_MODAL_USER_ALERT
});
