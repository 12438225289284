/*
 * This component has 2 primary responsibilities
 * 1)  a successful login into Azure
 * If it is an existing user, route to the dashboard or whatever private route they were trying to access before
 * being redirected to the login screen.
 * If it is a new user, route to the signup page
 * 2) provide a plane for the user to begin logging in
 */

import { Button, Col, Grid, Row } from 'react-bootstrap';
import * as localForage from 'localforage';
import initialState from '../../reducers/initialState';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as React from 'react';
import { TFunction } from 'i18next';

import { Iuser, Iredirect } from '../../models';
import { MSALlogin, userLogin, userLogout } from '../../actions/userActions';
import {
    setLoginRedirect,
    removeLoginRedirect,
    setRedirectPathname
} from '../../actions/redirectToReferrerAction';
import { getUserAlerts } from '../../actions/manageUserAlertActions';
import { msalApp } from './Auth-Utils';
import { manualAjaxStart } from '../../actions/ajaxStatusActions';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import Mixpanel from '../../helpers/Mixpanel';

interface Iprops extends RouteComponentProps<{}> {
    userLogin: (t: TFunction) => Promise<any>;
    MSALlogin: typeof MSALlogin;
    userLogout: typeof userLogout;
    setLoginRedirect: () => Promise<any>;
    setRedirectPathname: typeof setRedirectPathname;
    removeLoginRedirect: typeof removeLoginRedirect;
    user: Iuser;
    redirect: Iredirect;
    loading: boolean;
    getUserAlerts: typeof getUserAlerts;
    manualAjaxStart: () => void;
}

interface Istate {
    userLoginFailed: boolean;
    reduxPersistDelay: boolean;
}

const azure = require('../../images/Azure.png');

class Login extends React.Component<Iprops & WithTranslation, Istate> {
    constructor(props: Iprops & WithTranslation) {
        super(props);

        this.state = {
            userLoginFailed: false,
            // delay redirecting to MSAL to give the redirect time to persist to indexDB.
            // this can likely be removed when we switch to the faster redux-persist v4
            reduxPersistDelay: true
        };
    }

    componentDidMount() {
        this.initApp();
        localForage.setItem(
            'persist:state-core-care-web',
            JSON.stringify(initialState)
        );
    }

    componentWillUnmount() {
        document.removeEventListener(
            'missingUser',
            this.handleRedirectToSignup,
            false
        );
    }

    initApp = () => {
        document.addEventListener(
            'missingUser',
            this.handleRedirectToSignup,
            false
        );

        setTimeout(() => {
            this.setState({ reduxPersistDelay: false });
        }, 4000);

        // store the referring loation in redux
        const locationState: any = this.props.location.state;
        const { from } = locationState || { from: { pathname: '/' } };

        // if we are not already redirecting, then set it
        if (
            !this.props.redirect.redirectToReferrer &&
            from &&
            from.pathname &&
            from.pathname !== '/'
        ) {
            this.props.setRedirectPathname(
                `${from.pathname}${from.search}`,
                true
            );
        }

        const msalAccount = msalApp?.getAllAccounts();

        if (msalAccount?.length > 0) {
            Mixpanel.track('Login Successful');

            if (!this.props.user.id.length) {
                // wait for MSAL to cache the token
                setTimeout(() => {
                    this.props
                        .userLogin(this.props.t)
                        .then(() => {
                            this.props.getUserAlerts();
                        })
                        .catch((error: any) => {
                            console.error('login failed in login.tsx', error);
                            Mixpanel.track('Login Unsuccessful');
                            this.setState({ userLoginFailed: true });
                        });
                }, 200);
            }
        } else {
            console.info('no msal account');
            if (this.props.user.id.length) {
                console.error('token is expired but user is authenticated');
            }
        }
    };

    handleRedirectToSignup = () => {
        this.props.history.push('/azure_signup');
    };

    handleLogin = () => {
        if (this.props.loading === false) {
            this.props.manualAjaxStart();
        }

        if (this.state.reduxPersistDelay) {
            setTimeout(() => {
                this.handleLogin();
            }, 500);
        } else {
            this.props.userLogin(this.props.t).catch(error => {
                this.setState({ userLoginFailed: true });
                console.info(error);
            });
        }
    };

    handleMsalLogin = () => {
        this.props.MSALlogin(this.props.t);
    };

    render() {
        const { t } = this.props;
        // handle potential redirects
        const { from } = {
            from: { pathname: this.props.redirect.pathname }
        } || {
            from: { pathname: '/' }
        };
        const { redirectToReferrer } = this.props.redirect;

        // if user is authenticated and exists in the backend
        // redirect to the redirect.pathname or the dashboard
        if (msalApp?.getAllAccounts().length > 0 && this.props.user.id.length) {
            const loggedInPath: string = redirectToReferrer
                ? from.pathname
                : '/dashboard';

            Mixpanel.identify(this.props.user.id);
            Mixpanel.people.set({
                $email: this.props.user.email,
                USER_ID: this.props.user.id
            });

            this.props.removeLoginRedirect();

            return <Redirect to={loggedInPath} />;
        }

        return (
            <div className="loginlayout">
                <Grid>
                    <Row>
                        <Col>
                            <div className="login-form login">
                                <span className="loginTitle">
                                    {t('welcome')}
                                </span>
                                <Button
                                    bsStyle="default"
                                    className="loginBtn"
                                    onClick={this.handleMsalLogin}
                                    disabled={this.props.loading}
                                >
                                    <img
                                        width="20"
                                        height="20"
                                        src={azure}
                                        alt="icon"
                                    />
                                    {t('loginButton')}
                                </Button>
                                <LinkContainer to={'/signup'}>
                                    <Button
                                        bsStyle="link"
                                        className="signupBtn"
                                        bsSize="large"
                                        disabled={this.props.loading}
                                    >
                                        {t('signUp')}
                                    </Button>
                                </LinkContainer>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: any) => {
    return {
        user: state.user,
        redirect: state.redirect,
        loading: selectIsLoading(state)
    };
};

export default withTranslation('auth')(
    connect(mapStateToProps, {
        userLogin,
        MSALlogin,
        userLogout,
        setLoginRedirect,
        removeLoginRedirect,
        setRedirectPathname,
        getUserAlerts,
        manualAjaxStart
    })(Login)
);
