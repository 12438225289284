import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
    IpostalCode,
    ItableFiltersParams,
    Iuser,
    ThunkResult
} from '../models';
import { isEqual, map } from 'lodash';

import API from '../constants/apiEndpoints';
import { beginAjaxCall } from './ajaxStatusActions';
import { constants } from '../constants/constants';
import { initialUser } from '../reducers/initialState';
import { msalFetch } from '../components/auth/Auth-Utils';
import { toastr } from 'react-redux-toastr';
import { userLogoutHelper } from './userActions';
import { FormUtil } from '../components/common/FormUtil';

interface IuserModified extends Omit<Iuser, 'customers' | 'facilities'> {
    customers: Array<{ id: string; name: string }>;
    facilities: Array<{ id: string; name: string }>;
}

export function getUserManage(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const {
            page,
            search,
            countryID,
            userType
        } = getState().manageUser.tableFilters;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                page,
                search,
                countryID: countryID?.value ?? '',
                userType: userType?.value ?? ''
            }
        };

        const url = API.GET.user.getusersearch;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.USER_MANAGE_SUCCESS,
                        users: data.data.result
                    });
                    dispatch({
                        type: types.USER_MANAGE_TOTAL_PAGES,
                        pages: data.data.pages
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.USER_MANAGE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get users');
                console.error(error);
            });
    };
}
/*
 * If the user updates themselves, log them out so that the changes are actually reflected
 */
export const checkForLoggedInUser = (
    userID: string,
    updatedUserID: string,
    dispatch: any
) => {
    if (userID === updatedUserID) {
        toastr.warning(
            'Logging out',
            'Updating currently logged in user requires logging out.',
            constants.toastrSuccess
        );
        setTimeout(() => {
            dispatch(userLogoutHelper);
        }, 5000);
    }
};

export function getSecurityUsers(
    securityFunctionIds: string[]
): ThunkResult<any> {
    return dispatch => {
        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: FormUtil.toUrlSearchParams({
                securityfunctions: securityFunctionIds
            })
        };

        const url = API.GET.user.getusersbysecurityfunction;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_SECURITY_SUCCESS,
                        users: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_SECURITY_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get security users');
                console.error(error);
            });
    };
}

export function updatePostalCodes(
    postalCodes: string[],
    selectedUserId: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        const params = FormUtil.toUrlSearchParams({
            userId: selectedUserId,
            postalCodes
        });
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            params
        };
        const url = API.POST.user.updatePostalCodes;

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.USER_UPDATE_POSTAL_CODES_SUCCESS,
                        id: selectedUserId,
                        postalCodes: data.data.filter(
                            (postalCode: IpostalCode) => !postalCode.isDeleted
                        )
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.USER_UPDATE_POSTAL_CODES_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'add postal codes');
                console.error(error);
            });
    };
}

export const updateSelectedUser = (user = initialUser) => ({
    type: types.UPDATE_SELECTED_USER,
    payload: user
});

/*
 * saveUser
 * persist the user to the Server
 */
export function saveUser(user: Iuser, originalUser?: Iuser): ThunkResult<any> {
    return (dispatch, getState) => {
        const { postalCodes, ...cleanedUser } = user;
        const postalCodeArray = map(postalCodes, (postalCode: IpostalCode) => {
            return postalCode.postalCode;
        });
        let initialPostalCodes;

        if (originalUser?.postalCodes) {
            initialPostalCodes = originalUser.postalCodes.map(
                postalCode => postalCode.postalCode
            );
        }

        const shouldUpdatePostalCodes = !isEqual(
            postalCodeArray,
            initialPostalCodes
        );
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: cleanedUser
        };

        const url = API.POST.user.update;

        dispatch(beginAjaxCall());
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data?.data) {
                    throw new Error('missing data');
                } else {
                    if (shouldUpdatePostalCodes) {
                        dispatch(updatePostalCodes(postalCodeArray, user.id));
                    }
                    dispatch({
                        type: types.USER_SAVE_SUCCESS,
                        user: data.data
                    });

                    // toastr.success('Success', 'Saved user', constants.toastrSuccess);
                    checkForLoggedInUser(user.id, getState().user.id, dispatch);
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.USER_SAVE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'update user');
                console.error(error);
            });
    };
}

export const toggleSecurityFunctionsModal = () => ({
    type: types.TOGGLE_MODAL_SECURITY_FUNCTIONS
});
export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_USER,
    filters
});

export const setEditUserFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_MANAGE_USER,
    formValues
});
export const updateEditUserFormValues = (formValues: {
    [key: string]: any;
}) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_USER,
    formValues
});

export const setSelectedUserID = (id: string) => ({
    type: types.SET_SELECTED_MANAGE_USER,
    id
});
