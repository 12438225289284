import Player, { Options } from '@vimeo/player';
import React from 'react';
import { toastr } from 'react-redux-toastr';
import { constants } from '../../constants/constants';

interface IvimeoProps {
    vimeoId: number;
}

export class VideoPlayer extends React.Component<IvimeoProps> {
    private player: Player | undefined;
    private pElem: HTMLDivElement | null;

    constructor(props: IvimeoProps) {
        super(props);
        this.player = undefined;
        this.pElem = null;
    }

    componentDidMount() {
        this.setUpPlayer();
    }
    componentWillUnmount() {
        if (typeof this.player !== 'undefined') {
            this.player.off('play');
            this.player.off('pause');
        }
    }

    setUpPlayer = () => {
        if (this.pElem === null) {
            throw new Error('missing pElem');
        }
        const options: Options = {
            id: this.props.vimeoId,
            width: 723, // > 723 ? this.pElem.offsetWidth : 723,
            loop: false
        };

        this.player = new Player('lessonPlayer', options);
        this.player
            .ready()
            .then(() => {
                if (this.player === undefined) {
                    throw new Error('missing vimeo player DOM element');
                }
            })
            .catch(error => {
                console.error('Vimeo player failed to initialize.', error);
                toastr.error(
                    'Error',
                    'Video failed to load. Please try again or contact support.',
                    constants.toastrError
                );
            });
    };
    render() {
        return (
            <div
                id="lessonPlayer"
                className="vimeo-player"
                ref={ref => (this.pElem = ref)}
                style={{ width: '100%', padding: 0, margin: 0 }}
            />
        );
    }
}
