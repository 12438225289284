import { Itile } from '../models';
import { securityFunctions } from './securityFunctions';
import { menuParentKeysEnum } from '../models-enums';

export const tiles: Itile[] = [
    {
        svgKey: 'quotes',
        title: 'quotes',
        src: require('../images/quotesTile.jpg'),
        srcBanner: require('../images/quotesHeader.png'),
        color: 'green',
        width: 270,
        height: 300,
        url: '/quotes',
        securityFunctions: [securityFunctions.ManageCustomerQuotes.id],
        description: '',
        parentKey: menuParentKeysEnum.dashboard,
        order: 105
    },
    {
        svgKey: 'quotes',
        title: 'quotes_sales',
        src: require('../images/quotesTile.jpg'),
        srcBanner: require('../images/quotesHeader.png'),
        color: 'purple',
        width: 270,
        height: 300,
        url: '/quotes_sales',
        securityFunctions: [
            securityFunctions.SalesParts.id,
            securityFunctions.SalesInstallation.id,
            securityFunctions.SalesService.id,
            securityFunctions.SalesManager.id,
            securityFunctions.SalesAdmin.id
        ],
        description: '',
        parentKey: menuParentKeysEnum.sales,
        order: 100
    },
    {
        svgKey: 'training',
        title: 'training',
        src: require('../images/beaconTraining.jpg'),
        srcBanner: require('../images/beaconTrainingHeader.jpg'),
        color: 'purple',
        width: 270,
        height: 300,
        url: '/training',
        securityFunctions: [securityFunctions.ManageIndividualTraining.id],
        parentKey: menuParentKeysEnum.default,
        description: '',
        order: 100
    },
    // TODO Figure out the Tile for this guy. Should hold parent tile as well
    {
        svgKey: 'leads',
        title: 'leads',
        src: require('../images/beaconTraining.jpg'),
        srcBanner: require('../images/beaconTrainingHeader.jpg'),
        color: 'dark',
        width: 270,
        height: 300,
        url: '/leads',
        securityFunctions: [
            securityFunctions.SalesAdmin.id,
            securityFunctions.SalesInstallation.id,
            securityFunctions.SalesManager.id,
            securityFunctions.SalesParts.id,
            securityFunctions.SalesService.id
        ],
        description: '',
        parentKey: menuParentKeysEnum.sales,
        order: 100
    },

    {
        svgKey: 'inventory',
        title: 'inventory',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'orange',
        width: 270,
        height: 300,
        url: '/inventory',
        securityFunctions: [
            securityFunctions.ViewInventory.id,
            securityFunctions.ManageInventory.id
        ],
        parentKey: menuParentKeysEnum.default,
        description: '',
        order: 100
    },
    {
        svgKey: 'manageUsers',
        title: 'manageUser',
        src: require('../images/beaconManageUsers.jpg'),
        srcBanner: require('../images/beaconManageUsersHeader.jpg'),
        color: 'blue',
        width: 270,
        height: 300,
        url: '/users',
        securityFunctions: [securityFunctions.ManageAllUsers.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 20
    },
    {
        svgKey: 'newUserQueue',
        title: 'manageUserQueue',
        src: require('../images/beaconQueue.jpg'),
        srcBanner: require('../images/beaconQueueHeader.jpg'),
        color: 'grey',
        width: 270,
        height: 300,
        url: '/queue',
        securityFunctions: [securityFunctions.ManageAllUsers.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 30
    },
    {
        svgKey: 'documents',
        title: 'Documents',
        src: require('../images/documentTile.jpg'),
        srcBanner: require('../images/documentHeader.jpg'),
        color: 'blue',
        width: 270,
        height: 300,
        url: '/documents',
        securityFunctions: [
            securityFunctions.ViewDocumentLibrary.id,
            securityFunctions.ManageDocumentLibrary.id,
            securityFunctions.ManageGeneralDocuments.id
        ],
        description: '',
        parentKey: menuParentKeysEnum.facility,
        order: 100
    },
    {
        svgKey: 'reports',
        title: 'manageReports',
        src: require('../images/reportTile.jpg'),
        srcBanner: require('../images/beaconManageUsersHeader.jpg'),
        color: 'orange',
        width: 270,
        height: 300,
        url: '/reports',
        securityFunctions: [
            securityFunctions.ViewReports.id,
            securityFunctions.RunAnnualInspectionReport.id,
            securityFunctions.RunJobCommentsReport.id,
            securityFunctions.RunHTMReport.id
        ],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 50
    },
    {
        svgKey: 'customers',
        title: 'facilities',
        src: require('../images/customerTile.jpg'),
        srcBanner: require('../images/customerHeader.jpg'),
        color: 'grey',
        width: 270,
        height: 300,
        url: '/facility',
        securityFunctions: [
            securityFunctions.ManageFacilities.id,
            securityFunctions.ViewCustomers.id
        ],
        description: '',
        parentKey: menuParentKeysEnum.default,
        order: 100
    },
    {
        svgKey: 'locations',
        title: 'locations',
        src: require('../images/LocationTile.jpg'),
        srcBanner: require('../images/locationHeader.jpg'),
        color: 'dark',
        width: 270,
        height: 300,
        url: '/locations',
        securityFunctions: [securityFunctions.ManageLocations.id],
        description: '',
        parentKey: menuParentKeysEnum.facility,
        order: 100
    },
    {
        svgKey: 'jobs',
        title: 'manageJobs',
        src: require('../images/beaconManageJobs.jpg'),
        srcBanner: require('../images/beaconManageJobsHeader.jpg'),
        color: 'green',
        width: 270,
        height: 300,
        url: '/managejobs',
        securityFunctions: [securityFunctions.ManageJobs.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 40
    },
    {
        svgKey: 'alerts',
        title: 'alerts',
        src: require('../images/alertTile.jpg'),
        srcBanner: require('../images/alertHeader.jpg'),
        color: 'purple',
        width: 270,
        height: 300,
        url: '/alerts',
        securityFunctions: [securityFunctions.ManageAlerts.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 100
    },
    {
        svgKey: 'standardText',
        title: 'standardTextLibrary',
        src: require('../images/codeTile.jpg'),
        srcBanner: require('../images/codeHeader.jpg'),
        color: 'grey',
        width: 270,
        height: 300,
        url: '/codes',
        securityFunctions: [securityFunctions.ManageCodes.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 100
    },
    {
        svgKey: 'workOrders',
        title: 'workorders',
        src: require('../images/workOrderTile.jpg'),
        srcBanner: require('../images/workOrderHeader.jpg'),
        color: 'green',
        width: 270,
        height: 300,
        url: '/workorders',
        securityFunctions: [securityFunctions.ManageCustomerWorkOrders.id],
        description: '',
        parentKey: menuParentKeysEnum.default,
        order: 100
    },
    {
        svgKey: 'productQueue',
        title: 'facilityqueue',
        src: require('../images/beaconManageProductQueue.jpg'),
        srcBanner: require('../images/beaconManageProductQueueHeader.jpg'),
        color: 'blue',
        width: 270,
        height: 300,
        url: '/facilityqueue',
        securityFunctions: [securityFunctions.ManageFacilities.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 100
    },
    {
        svgKey: 'productQueue',
        title: 'productqueue',
        src: require('../images/beaconManageProductQueue.jpg'),
        srcBanner: require('../images/beaconManageProductQueueHeader.jpg'),
        color: 'blue',
        width: 270,
        height: 300,
        url: '/productqueue',
        securityFunctions: [securityFunctions.ManageProducts.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 100
    },
    {
        svgKey: 'products',
        title: 'products',
        src: require('../images/beaconManageProducts.jpeg'),
        srcBanner: require('../images/workOrderHeader.jpg'),
        color: 'green',
        width: 270,
        height: 300,
        url: '/products',
        securityFunctions: [securityFunctions.ManageProducts.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 10
    },

    {
        svgKey: 'measurement',
        title: 'manageMeasurementPointList',
        src: require('../images/measurementTile.jpg'),
        srcBanner: require('../images/beaconManageUsersHeader.jpg'),
        color: 'purple',
        width: 270,
        height: 300,
        url: '/measurements',
        securityFunctions: [securityFunctions.ManageAllMeasurementPoints.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 60
    },

    {
        svgKey: 'brands',
        title: 'brands',
        src: require('../images/beaconManageInventory.jpg'),
        srcBanner: require('../images/beaconManageInventoryHeader.jpg'),
        color: 'orange',
        width: 270,
        height: 300,
        url: '/brands',
        securityFunctions: [securityFunctions.ManageBrands.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 100
    },
    {
        svgKey: 'trainingProgress',
        title: 'manageAllTraining',
        src: require('../images/trainingProgressTile.jpg'),
        srcBanner: require('../images/trainingProgressHeader.jpg'),
        color: 'dark',
        width: 270,
        height: 300,
        url: '/manageTraining',
        securityFunctions: [
            securityFunctions.ManageAllTraining.id,
            securityFunctions.ManageIndividualTraining.id
        ],
        description: '',
        parentKey: menuParentKeysEnum.default,
        order: 100
    },
    {
        svgKey: 'manageTeam',
        title: 'manageTeam',
        src: require('../images/beaconManageTeam.jpg'),
        srcBanner: require('../images/beaconManageTeamHeader.jpg'),
        color: 'blue',
        width: 270,
        height: 300,
        url: '/team',
        // securityFunctions: [securityFunctions.ManageTeamMembers.id],
        securityFunctions: ['temporarily disabled'],
        description: '',
        parentKey: menuParentKeysEnum.default,
        order: 100
    },
    {
        svgKey: 'jobComments',
        title: 'jobComments',
        src: require('../images/jobCommentsTile.jpg'),
        srcBanner: require('../images/jobCommentsHeader.jpg'),
        color: 'purple',
        width: 270,
        height: 300,
        url: '/job_comments',
        securityFunctions: [securityFunctions.ManageJobComments.id],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 100
    },
    {
        svgKey: 'pmp',
        title: 'pmp',
        src: require('../images/beaconManageTeam.jpg'),
        srcBanner: require('../images/beaconManageTeamHeader.jpg'),
        color: 'blue',
        width: 270,
        height: 300,
        url: '/pmp',
        securityFunctions: [securityFunctions.DashboardPMP.id],
        description: 'tile for the PMP dashboard analytics',
        shouldHideOnDashboard: true,
        shouldHideOnMenu: false,
        parentKey: menuParentKeysEnum.dashboard,
        order: 100
    },
    {
        svgKey: 'inspectionStatus',
        title: 'inspection',
        src: require('../images/beaconManageTeam.jpg'),
        srcBanner: require('../images/beaconManageTeamHeader.jpg'),
        color: 'green',
        width: 270,
        height: 300,
        url: '/assetStatus',
        securityFunctions: [
            securityFunctions.DashboardInspection.id,
            securityFunctions.ViewInventory.id
        ],
        description: 'tile for the Inspection dashboard analytics',
        shouldHideOnDashboard: true,
        shouldHideOnMenu: false,
        parentKey: menuParentKeysEnum.dashboard,
        order: 100
    },
    {
        svgKey: 'home',
        title: 'home',
        color: 'green',
        width: 270,
        height: 300,
        url: '/dashboard',
        securityFunctions: [securityFunctions.ManageIndividualTraining.id],
        shouldHideOnDashboard: true,
        shouldHideOnMenu: false,
        description: '',
        parentKey: menuParentKeysEnum.default,
        order: 0
    },
    // new-route: require new image assets
    {
        svgKey: 'trainingProgress',
        title: 'assetPhotos',
        src: require('../images/trainingProgressTile.jpg'),
        srcBanner: require('../images/trainingProgressHeader.jpg'),
        color: 'dark',
        width: 270,
        height: 300,
        url: '/asset_Photos',
        securityFunctions: [
            securityFunctions.ManageIndividualTraining.id,
            securityFunctions.ManageAllTraining.id,
            securityFunctions.ManageEmployeeTraining.id
        ],
        description: '',
        parentKey: menuParentKeysEnum.default,
        order: 100
    },
    {
        svgKey: 'trainingProgress',
        title: 'suggestedParts',
        src: require('../images/trainingProgressTile.jpg'),
        srcBanner: require('../images/trainingProgressHeader.jpg'),
        color: 'dark',
        width: 270,
        height: 300,
        url: '/suggested_parts',
        securityFunctions: [
            securityFunctions.ManageSuggestedParts.id,
            securityFunctions.ViewSuggestedParts.id
        ],
        description: '',
        parentKey: menuParentKeysEnum.admin,
        order: 100
    }
];
