/*
 * Preventative Maintenance Expander Container connects the PreventativeMaintenancePointExpander to redux
 */

import { IWorkOrder } from '../../models';

import { PreventativeMaintenancePointExpander } from './PreventativeMaintenancePointExpander';
import { RowInfo } from 'react-table';
import { connect } from 'react-redux';
import {
    toggleEditWorkOrderModal,
    setSelectedWorkOrderID,
    toggleWorkOrderCloseModal,
    getPreventativeMaintenancePointCSV
} from '../../actions/manageWorkOrderActions';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops extends RowInfo {
    original: IWorkOrder;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        loading: selectIsLoading(state)
    };
};

export const PreventativeMaintenanceExpanderContainer = connect(
    mapStateToProps,
    {
        toggleEditWorkOrderModal,
        setSelectedWorkOrderID,
        toggleWorkOrderCloseModal,
        getPreventativeMaintenancePointCSV
    }
)(PreventativeMaintenancePointExpander);
