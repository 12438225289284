import * as React from 'react';

import ReactTable, {
    Column,
    FinalState,
    RowInfo,
    RowRenderProps
} from 'react-table';
import {
    clearSelectedInstallBaseID,
    setInstallBatchMode,
    setSelectedInstallBaseID,
    setSelectedProductID,
    toggleEditInstallModal,
    toggleInstallContactModal,
    toggleMPResultModal,
    toggleSearchNewProductsModal
} from '../../actions/manageInventoryActions';
import {
    getWorkOrdersByFacility,
    setSelectedWorkOrderID,
    toggleEditWorkOrderModal
} from '../../actions/manageWorkOrderActions';
import { Ifacility, IinstallBase } from '../../models';

import { TFunction } from 'i18next';
import { orderBy } from 'lodash';
import { Button, DropdownButton, MenuItem } from 'react-bootstrap';
import selectTableHOC, {
    SelectTableAdditionalProps
} from 'react-table/lib/hoc/selectTable';
import treeTableHOC from 'react-table/lib/hoc/treeTable';
import {
    getFacilityMeasurementPointResults,
    getMeasurementPointResultsHistoryByInstallBase
} from '../../actions/measurementPointResultsActions';
import { addToCart } from '../../actions/shoppingCartActions';
import { measurementPointResultStatusTypesEnum } from '../../models-enums';
import { TableUtil } from '../common/TableUtil';
import { InstallBaseActionButtonContainer } from '../dashboard/InstallBaseActionButtonContainer';

const SelectTable = selectTableHOC(treeTableHOC(ReactTable));

interface RowInfoInstallBase extends RowInfo {
    original: IinstallBase;
}
interface ExpanderProps extends RowInfo {
    addToCart: typeof addToCart;
    t: TFunction;
    showAddInstallation: boolean;
    showRequestQuote: boolean;
    showEditProduct: boolean;
    showMoveFacility: boolean;
    setInstallBatchMode: typeof setInstallBatchMode;
    facility: Ifacility;
    setSelectedInstallBaseID: typeof setSelectedInstallBaseID;
    clearSelectedInstallBaseID: typeof clearSelectedInstallBaseID;
    toggleEditWorkOrderModal: typeof toggleEditWorkOrderModal;
    toggleMPResultModal: typeof toggleMPResultModal;
    toggleEditProductModal: () => any;
    toggleEditInstallModal: typeof toggleEditInstallModal;
    setSelectedProductID: typeof setSelectedProductID;
    toggleInstallContactModal: typeof toggleInstallContactModal;
    toggleSearchNewProductsModal: typeof toggleSearchNewProductsModal;
    setSelectedWorkOrderID: typeof setSelectedWorkOrderID;
    getFacilityMeasurementPointResults: typeof getFacilityMeasurementPointResults;
    getMeasurementPointResultsHistoryByInstallBase: typeof getMeasurementPointResultsHistoryByInstallBase;
    getWorkOrdersByFacility: typeof getWorkOrdersByFacility;
    colorButton: string;
    openSearchFacilityModal: (ids: string[]) => void;
}
interface Istate {
    selectAll: boolean;
    checkboxTable: any;
    selection: string[];
}

/*
 * The Installations Expander
 */
export const InstallBasesExpander: React.FC<ExpanderProps &
    SelectTableAdditionalProps> = props => {
    const [state, setState] = React.useState<Istate>({
        selectAll: false,
        checkboxTable: {},
        selection: []
    });

    const installBasesWithLocationString = props.original.installBases.map(
        (install: IinstallBase) => {
            return {
                ...install,
                locationString: TableUtil.buildLocation(install, props.facility)
            };
        }
    );
    const sortedInstallBases = orderBy(
        installBasesWithLocationString,
        'installString'
    );

    const getTdProps = (
        fState: FinalState,
        rowInfo: RowInfoInstallBase | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column) {
            if (column.id && column.id === 'actions') {
                return {};
            } else {
                return {
                    onClick: () => {
                        props.setSelectedInstallBaseID(rowInfo.original.id);
                        props.setSelectedProductID(rowInfo.original.productID);
                        props.toggleEditInstallModal();
                    }
                };
            }
        } else {
            return {};
        }
    };

    const toggleAll = () => {
        const { keyField = 'id' } = props;
        const selectAll = !state.selectAll;
        const selection: string[] = [];

        if (selectAll && keyField !== undefined) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = state.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords: any[] = wrappedInstance.getResolvedState()
                .sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(`select-${item._original[keyField]}`);
            });
        }

        setState({ ...state, selectAll, selection });
    };

    /**
     * Whether or not a row is selected for select table
     */
    const isSelected = (key: string) => {
        return state.selection.includes(`select-${key}`);
    };

    const toggleSelection = (key: string, shift: boolean, row: any) => {
        // start off with the existing state
        let selection = [...state.selection];
        const keyIndex = selection.indexOf(key);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(key);
        }

        setState({ ...state, selection });
    };

    const bulkChangeFacility = () => {
        let ids: string[] = [];
        state.selection.forEach(item => {
            ids.push(item.slice(7));
        });

        props.openSearchFacilityModal(ids);
    };

    const bulkChangeProduct = async () => {
        const installBaseIDs: string[] = state.selection.reduce(
            (carry, item) => {
                carry.push(item.slice(7) as string);
                return carry;
            },
            [] as string[]
        );

        await props.toggleSearchNewProductsModal();
        props.setInstallBatchMode({
            installBaseIDs,
            isBatchMode: true
        });
    };

    const ExpanderButtonBar = (eProps: RowRenderProps) => {
        return (
            <span
                className="expander-button-bar text-right"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
            >
                {/* bulk action button */}
                {state.selection.length > 0 && props.showMoveFacility && (
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 1,
                            paddingLeft: '25px'
                        }}
                    >
                        <DropdownButton
                            bsStyle={props.colorButton}
                            title={props.t('manageWorkOrder:bulkActions')}
                            id="table-action-button"
                            onSelect={(eventKey: any) => {
                                switch (eventKey) {
                                    case 1:
                                        bulkChangeFacility();
                                        break;
                                    case 2:
                                        bulkChangeProduct();
                                        break;
                                }
                            }}
                        >
                            <MenuItem eventKey={1}>
                                {props.t('changeFacility')}
                            </MenuItem>
                            <MenuItem eventKey={2}>
                                {props.t('editSelectionProduct') !==
                                'editSelectionProduct'
                                    ? props.t('editSelectionProduct')
                                    : 'Change Product'}
                            </MenuItem>
                        </DropdownButton>
                    </div>
                )}
                {props.showMoveFacility && (
                    <Button
                        bsStyle="link"
                        onClick={() => {
                            const installIds: string[] = props.original.installBases.map(
                                (install: IinstallBase) => {
                                    return install.id;
                                }
                            );
                            props.openSearchFacilityModal(installIds);
                        }}
                    >
                        {props.t('moveAllAssets')}
                    </Button>
                )}
                {props.showEditProduct && (
                    <Button
                        bsStyle="link"
                        onClick={() => {
                            props.toggleEditProductModal();
                            props.setSelectedProductID(props.original.id);
                        }}
                    >
                        {props.t('editProduct')}
                    </Button>
                )}
                {props.showEditProduct === false && (
                    <Button
                        bsStyle="link"
                        onClick={() => {
                            props.toggleEditProductModal();
                            props.setSelectedProductID(props.original.id);
                        }}
                    >
                        {props.t('viewProduct')}
                    </Button>
                )}
                {props.showRequestQuote && (
                    <Button
                        bsStyle="link"
                        onClick={() =>
                            props.addToCart(
                                props.original,
                                'INVENTORY',
                                props.t
                            )
                        }
                    >
                        {props.t('addToQuote')}
                    </Button>
                )}

                {props.showAddInstallation && (
                    <Button
                        bsStyle="link"
                        onClick={() => {
                            props.clearSelectedInstallBaseID();
                            props.toggleEditInstallModal();
                        }}
                    >
                        {props.t('addInstallation')}
                    </Button>
                )}
            </span>
        );
    };

    const expanderColumns = TableUtil.translateHeaders(
        [
            {
                Footer: ExpanderButtonBar,
                minWidth: 20,
                id: 'indent-column-button-bar'
            },
            {
                Header: 'serialNumber',
                accessor: 'serialNumber',
                minWidth: 100
            },
            {
                Header: 'rfid',
                id: 'rfid',
                accessor: ({ rfid, sapEquipmentNumber }) => {
                    return (
                        <span className={sapEquipmentNumber ? 'blue-bold' : ''}>
                            {rfid}
                        </span>
                    );
                },
                minWidth: 100
            },
            {
                Header: 'Location',
                id: 'location',
                accessor: 'locationString',
                minWidth: 220
            },
            {
                Header: 'status',
                id: 'status',
                accessor: ({ visibleStatus }: IinstallBase) => {
                    const statusString =
                        measurementPointResultStatusTypesEnum[visibleStatus];

                    return (
                        <span className={`status ${statusString}`}>{`${props.t(
                            'manageMeasurementPointLists:' + statusString
                        )}`}</span>
                    );
                },
                minWidth: 100
            },
            {
                Header: '',
                id: 'actions',
                Cell: rowInfo => (
                    <InstallBaseActionButtonContainer
                        t={props.t}
                        rowInfo={rowInfo}
                        colorButton={props.colorButton}
                        openSearchFacilityModal={props.openSearchFacilityModal}
                        forMobile={false}
                    />
                ),
                minWidth: 90,
                style: { overflow: 'visible' }
            }
        ],
        props.t
    ) as Column[];

    return (
        <div>
            <SelectTable
                className={'attempts-expander'}
                data={sortedInstallBases}
                sortable={false}
                columns={expanderColumns}
                minRows={0}
                showPageSizeOptions={false}
                rowsText="installs"
                key={props.original.installBases.length}
                getTdProps={getTdProps}
                noDataText={props.t('noDataText')}
                resizable={false}
                toggleSelection={toggleSelection}
                selectType="checkbox"
                toggleAll={toggleAll}
                isSelected={isSelected}
                ref={r => (state.checkboxTable = r)}
                keyField="id"
                showPagination={props.original.installBases.length >= 10}
            />
        </div>
    );
};
