/**
 * Checks if the given string value is a float.
 * @function
 *
 * @param val - possible float
 * @returns {boolean} - true if value is float
 */
export const isStrFloat = (val: string): boolean => {
    let newVal: any = val;
    newVal = parseFloat(val);

    return !isNaN(newVal);
};

/**
 * Checks if the given value is a float or integer.
 * @function
 *
 * @param val - possible float
 * @returns {boolean} - true if value is float
 */
export const isFloat = (num: number) => Number(num) === num && num % 1 !== 0;
