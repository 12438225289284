import * as React from 'react';

import { Badge, Button, Col, Row } from 'react-bootstrap';
import { GFCourse, IshoppingCart, Itile, Iuser } from '../../models';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Route, RouteComponentProps, Switch } from 'react-router';
import {
    addCourseToCart,
    addLessonToCart,
    getAllLessons,
    getPurchasedTraining,
    getQuizResults,
    loadCourses,
    setLesson,
    trainingCheckout
} from '../../actions/trainingActions';

import CourseLessons from './CourseLessons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lesson from './Lesson';
import Quiz from './Quiz';
import ShoppingCartModal from '../shoppingCart/ShoppingCartModal';
import { TrainingBannerContainer } from './TrainingBannerContainer';
import { TrainingBreadcrumbContainer } from './TrainingBreadcrumbContainer';
import TrainingCheckoutForm from './TrainingCheckoutForm';
import { TrainingCourse } from './TrainingCourse';
import { closeAllModals } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { emptyTile } from '../../reducers/initialState';
import { getTotal } from '../../reducers/cartReducer';
import { map } from 'lodash';
import queryString from 'query-string';
import { toggleShoppingCartModal } from '../../actions/shoppingCartActions';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface RouterParams {
    courseID: string;
    lessonID: string;
    quizID: string;
}

interface Props extends RouteComponentProps<RouterParams> {
    user: Iuser;
    courses: { [key: string]: GFCourse };
    loadCourses: any;
    setLesson: any;
    loading: boolean;
    getAllLessons: typeof getAllLessons;
    // getAllLessonProgress: typeof getAllLessonProgress;
    toggleShoppingCartModal: typeof toggleShoppingCartModal;
    addCourseToCart: typeof addCourseToCart;
    addLessonToCart: typeof addLessonToCart;
    cartTotal: number;

    cart: IshoppingCart;
    trainingCheckout: any;
    getPurchasedTraining: typeof getPurchasedTraining;
    closeAllModals: typeof closeAllModals;
    purchasedTraining: string[];
    getQuizResults: typeof getQuizResults;
}

interface State {
    currentTile: Itile;
}

class Courses extends React.Component<Props & WithTranslation, State> {
    constructor(props: Props & WithTranslation) {
        super(props);
        this.state = {
            currentTile: emptyTile
        };
    }

    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        // TODO get updated courses based on time like we are doing in App.tsx in GrammarFlip.  for now we will get them every time.
        // if (!this.props.courses.length) {
        this.props.loadCourses(this.props.user);
        this.props.getAllLessons(this.props.user);
        // this.props.getAllLessonProgress();
        this.props.getPurchasedTraining();
        this.props.getQuizResults();
        const query = queryString.parse(this.props.location.search);

        // if we receive a transation number, that means we were recently redirected from a UTA transaction.  Now we need to actually checkout.
        if (
            query &&
            query.transactionNumber &&
            query.transactionNumber.length
        ) {
            this.props.trainingCheckout(query.transactionNumber).then(() => {
                this.props.history.replace('/training');
            });
        }
    }

    componentWillUnmount() {
        this.props.closeAllModals();
    }

    printStudentCourses = () => {
        return (
            <Col
                xs={12}
                key="courses"
                className="main-content content-without-sidebar courses animated fadeIn"
            >
                <Col xs={12} sm={12}>
                    <div className="courses-tiles text-center">
                        {map(this.props.courses, gfCourse => (
                            <TrainingCourse
                                course={gfCourse}
                                purchasedTraining={this.props.purchasedTraining}
                                addCourseToCartCallback={
                                    this.props.addCourseToCart
                                }
                            />
                        ))}
                    </div>
                </Col>
            </Col>
        );
    };

    render() {
        return (
            <Row className="training">
                <Col xs={12}>
                    <TrainingBannerContainer
                        img={this.state.currentTile.srcBanner}
                        color={this.state.currentTile.color}
                    />
                    <Button
                        className="request-for-quote-cart-button"
                        bsStyle="primary"
                        onClick={() =>
                            this.props.toggleShoppingCartModal('TRAINING')
                        }
                    >
                        <FontAwesomeIcon icon="shopping-cart" />
                        <Badge>{this.props.cartTotal} </Badge>
                    </Button>
                    <TrainingBreadcrumbContainer />
                    <Switch>
                        <Route
                            exact
                            path={`/training/:courseID/:lessonID/:quizID`}
                            component={Quiz}
                        />
                        <Route
                            exact
                            path={`/training/:courseID/:lessonID`}
                            component={Lesson}
                        />
                        <Route
                            exact
                            path={`/training/:courseID`}
                            component={CourseLessons}
                        />
                        <Route
                            exact
                            path={`/training`}
                            render={() => this.printStudentCourses()}
                        />
                    </Switch>
                </Col>
                <ShoppingCartModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    t={this.props.t}
                    cart={this.props.cart}
                    title={this.props.t('training:shoppingCartTitle')}
                    cartName="TRAINING"
                    showCost={true}
                    ShoppingCartForm={TrainingCheckoutForm}
                />
            </Row>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Props) => {
    return {
        user: state.user,
        courses: state.training.coursesByID,
        lesson: state.training.lesson,
        purchasedTraining: state.training.purchasedTraining,
        lessonProgress: state.training.lessonProgress,
        loading: selectIsLoading(state),
        cartTotal: getTotal(state.training.cart),
        cart: state.training.cart
    };
};

export default withTranslation('training')(
    connect(mapStateToProps, {
        loadCourses,
        setLesson,
        getAllLessons,
        // getAllLessonProgress,
        toggleShoppingCartModal,
        addCourseToCart,
        addLessonToCart,
        trainingCheckout,
        getPurchasedTraining,
        closeAllModals,
        getQuizResults
    })(Courses)
);
