/*
 * Import Instal Modal
 */

import { connect } from 'react-redux';

import {
    importInstall,
    toggleImportInstallModal
} from '../../actions/manageInventoryActions';
import ImportInstallForm from './ImportInstallForm';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: any;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const title = ownProps.t('importInstallModalTitle');
    return {
        user: state.user,
        title,
        loading: selectIsLoading(state),
        show: state.manageInventory.showImportInstallModal,
        className: 'user-edit'
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        importInstall,
        toggleModal: toggleImportInstallModal,
        onHide: toggleImportInstallModal,
        onCancel: toggleImportInstallModal
    })(WithCommonModal(ImportInstallForm))
);
