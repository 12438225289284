import { connect } from 'react-redux';
import { WithCommonModal } from '../common/WithCommonModal';
import EditLeadForm from './EditLeadForm';
import {
    toggleLeadsModal,
    updateLead,
    setSelectedLeadFromID,
    updateSelectedLead
} from '../../actions/manageLeadsActions';
import { toggleFacilityContactModal } from '../../actions/manageFacilityActions';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IleadUser, Iuser, Ihistory } from '../../models';
import { initialUser } from '../../reducers/initialState';
import { map, reduce } from 'lodash';
import { manageLeadQueryParamsEnum } from './ManageLeads';
import { getSecurityUsers } from '../../actions/manageUserActions';
import { selectSecurityUsers } from '../../reducers/manageUserReducer';
import { selectSelectedLead } from '../../reducers/manageLeadsReducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { getCloudDocument } from '../../actions/manageDocumentActions';

type Iprops = {
    colorButton: string;
    queryParams: typeof manageLeadQueryParamsEnum;
    history: Ihistory;
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedLead = selectSelectedLead(state, {
        leadID: ownProps.queryParams.selectedLeadID
    });
    const { facilityID, productID, quoteID, leadUsers } = selectedLead;
    const facility = state.facilities[facilityID];
    const product = state.manageInventory.productsByID[productID];
    const quote = state.manageCustomerQuotes.quotesByID[quoteID];

    // collect the sales managers and build the sales manager names
    const managersById = reduce(
        leadUsers,
        (managers: { [key: string]: Iuser }, leadUser: IleadUser) => {
            if (leadUser.user) {
                const { salesManagerID } = leadUser.user;
                const salesManager = leadUser.user.salesManager || initialUser;
                if (salesManagerID && !managers[salesManagerID]) {
                    managers[salesManagerID] = salesManager;
                }
            }
            return managers;
        },
        {}
    );
    const salesManagerNames = map(managersById, (manager: Iuser) => {
        return `${manager.first} ${manager.last}`;
    }).join(', ');

    return {
        show: state.manageLeads && state.manageLeads.showEditLeadModal,
        selectedLead,
        loading: selectIsLoading(state),
        className: 'facility-contact-edit',
        title: ownProps.t('editLeadForm.formTitle'),
        facility,
        product,
        quote,
        salesManagerNames,
        salesPersons: selectSecurityUsers(state)
    };
};

export default withTranslation('manageLeads')(
    connect(mapStateToProps, {
        onCancel: toggleLeadsModal,
        onHide: toggleLeadsModal,
        toggleModal: toggleLeadsModal,
        toggleFacilityContactModal,
        updateLead,
        updateSelectedLead,
        setSelectedLeadFromID,
        getSecurityUsers,
        getCloudDocument
    })(WithCommonModal(EditLeadForm))
);
