import * as types from '../actions/actionTypes';

import { Iuser } from '../models';
import { constants } from '../constants/constants';
import initialState from './initialState';
import { cleanUser } from './manageUserReducer';
import { IinitialState } from '.';

export default function user(state: Iuser = initialState.user, action: any) {
    switch (action.type) {
        case types.USER_LOGIN_SUCCESS: {
            return {
                ...state,
                ...cleanUser(action.user)
            };
        }
        case types.UPDATE_CURRENT_USER: {
            return {
                ...state,
                ...cleanUser(action.user)
            };
        }

        case types.USER_UPDATE_PROFILE_SUCCESS: {
            const cleanedUser = cleanUser(action.user);
            return {
                ...state,
                ...cleanedUser
            };
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.user;
        default:
            return state;
    }
}
export const getUsersCountries = (state: IinitialState) =>
    state.user.userCountries;
export const getUsersActiveCountry = (state: IinitialState) =>
    state.user.countryID;

/*
 * Getters and Setters
 */

export const getCanEditInstalls = (state: Iuser) => {
    return constants.hasSecurityFunction(state, [
        constants.securityFunctions.ManageInventory.id,
        constants.securityFunctions.FSE.id
    ]);
};

export const getCanEditProducts = (state: Iuser) => {
    return constants.hasSecurityFunction(state, [
        constants.securityFunctions.ManageProducts.id
    ]);
};

export const getCanCreateProducts = (state: Iuser) => {
    const canCreateAsManager = constants.hasSecurityFunction(state, [
        constants.securityFunctions.ManageInventory.id
    ]);
    const canCreateAsFSE = constants.hasSecurityFunction(state, [
        constants.securityFunctions.FSE.id
    ]);
    const canCreateAsAdmin = constants.hasSecurityFunction(state, [
        constants.securityFunctions.ManageProducts.id
    ]);

    return canCreateAsFSE || canCreateAsManager || canCreateAsAdmin;
};

export const getCanRequestQuote = (state: Iuser) => {
    return constants.hasSecurityFunction(state, [
        constants.securityFunctions.QuoteForInvoice.id
    ]);
};

export const getCanAccessAllCustomers = (state: Iuser) => {
    const allowAllCustomers = constants.hasSecurityFunction(state, [
        constants.securityFunctions.AllowAllCustomers.id
    ]);
    return allowAllCustomers;
};

export const getCanCreateAsManager = (state: Iuser) => {
    const canCreateAsManager = constants.hasSecurityFunction(state, [
        constants.securityFunctions.ManageInventory.id
    ]);
    return canCreateAsManager;
};
