import { Reducer, combineReducers } from 'redux';
import {
    createSelectedIDWithNameRoot,
    createShowModalWithNamedType,
    rawUsersByID,
    securityUsersByID
} from './commonReducers';

import SyncStatusReducer from './syncStatusReducer';
import { ToastrState } from '../typings/react-redux-toastr';
import ajaxCallsInProgress from './ajaxStatusReducer';
import brandManage from './manageBrandReducer';
import dashboardAnalyticsReducer from './dashboardAnalyticsReducer';
import documentManage from './manageDocumentReducer';
import documentTypeManage from './documentTypeReducer';
import facilities from './facilitiesReducer';
import leadsManage from './manageLeadsReducers';
import manageAlert from './manageAlertReducer';
import manageCode from './manageCodeReducer';
import manageFacility from './manageCustomerAndFacilityReducer';
import manageInventory from './manageInventoryReducer';
import manageJob from './manageJobReducer';
import manageJobComment from './manageJobCommentsReducer';
import manageLocation from './manageLocationReducer';
import manageMeasurementPointLists from './manageMeasurementPointListsReducer';
import manageProductQueue from './manageProductQueueReducer';
import manageQuotes from './manageCustomerQuotesReducer';
import { manageReportReducer } from './manageReportReducer';
import manageTae from './manageTaeReducer';
import manageTeam from './manageTeamReducer';
import manageTraining from './manageTrainingReducer';
import manageUser from './manageUserReducer';
import { manageUserAlertReducer } from './manageUserAlertReducer';
import manageUserQueue from './manageUserQueueReducer';
import manageWorkOrder from './manageWorkOrderReducer';
import measurementPointResults from './measurementPointResultsReducer';
import { productInfoReducer } from './productInfoReducer';
import redirect from './redirectToReferrerReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import training from './trainingReducer';
import user from './userReducer';
import { AppState } from '@redux-offline/redux-offline/lib/types';
import tutorial from './tutorialReducer';
import manageProduct from './manageProductReducer';
import manageAssetPhotos from './manageAssetPhotosReducer';
import managePart from './managePartReducer';
import manageSuggestedParts from './manageSuggestedPartsReducer';
import configReducer from './configReducer';
import countriesReducer from './countriesReducer';
import sapJobMappingReducer from './sapJobMappingReducer';

const rootReducer = combineReducers({
    ajaxCallsInProgress,
    countries: countriesReducer,
    dashboardAnalytics: dashboardAnalyticsReducer,
    config: configReducer,
    facilities,
    manageAlert,
    manageBrand: brandManage,
    manageCode,
    manageFacility,
    manageDocument: documentManage,
    manageDocumentType: documentTypeManage,
    manageInventory,
    manageJob,
    manageJobComment,
    manageLocation,
    manageMeasurementPointLists,
    manageProduct,
    manageProductQueue,
    manageReport: manageReportReducer,
    manageTae,
    manageTeam,
    manageTraining,
    manageLeads: leadsManage,
    manageCustomerQuotes: manageQuotes,
    manageUser,
    manageUserQueue,
    manageWorkOrder,
    measurementPointResults,
    productInfo: productInfoReducer,
    redirect,
    rawUsersByID,
    securityUsersByID,
    selectedFacilityID: createSelectedIDWithNameRoot('FACILITY_ID'),
    showSideMenu: createShowModalWithNamedType('SIDE_MENU'),
    showEditFacilityModal: createShowModalWithNamedType('EDIT_FACILITY'),
    showEditProfileModal: createShowModalWithNamedType('EDIT_PROFILE'),
    showFacilityContactModal: createShowModalWithNamedType('FACILITY_CONTACT'),
    showFacilityContractModal: createShowModalWithNamedType(
        'FACILITY_CONTRACT'
    ),
    showSecurityFunctionsModal: createShowModalWithNamedType(
        'SECURITY_FUNCTIONS'
    ),
    showAlertModal: createShowModalWithNamedType('USER_ALERT'),
    alertsByID: manageUserAlertReducer,
    syncStatus: SyncStatusReducer,
    toastr: toastrReducer as Reducer<ToastrState>,
    training,
    user,
    tutorial,
    manageAssetPhotos,
    managePart,
    manageSuggestedParts,
    sapJobMappings: sapJobMappingReducer
});

// TODO refactor to this IinitialState interface for coolness points.  This depends on typing the individual reducers
export type IinitialState = ReturnType<typeof rootReducer> &
    AppState & { toastr: ToastrState };

export default rootReducer;
