import React from 'react';

import SignatureCanvas from 'react-signature-canvas';

interface Iprops {
    onChange: any;
}
class SignatureControl extends React.Component<Iprops, {}> {
    private customerSignaturePad: any = {};

    constructor(props: any) {
        super(props);
        this.customerSignaturePad = React.createRef();
    }

    onSubmit = () => {
        this.customerSignaturePad.getTrimmedCanvas().toBlob((blob: Blob) => {
            this.props.onChange(blob);
        });
    };

    clear = () => {
        this.customerSignaturePad.clear();
    };

    render() {
        return (
            <div className="signaturePad">
                <SignatureCanvas
                    onEnd={this.onSubmit}
                    ref={(ref: any) => {
                        this.customerSignaturePad = ref;
                    }}
                    penColor="#333333"
                    canvasProps={{
                        width: 368,
                        height: 200
                    }}
                />
            </div>
        );
    }
}

export default SignatureControl;
