/*
 * Work Order Beacon Table Container
 */

import { Itile } from '../../models';

import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import {
    toggleEditWorkOrderModal,
    setSelectedWorkOrderID,
    setTableFilter,
    updateWorkOrderSelection
} from '../../actions/manageWorkOrderActions';
import { WorkOrderBeaconTable } from './WorkOrderBeaconTable';
import { selectJobWorkOrdersWithWorkOrders } from '../../reducers/manageJobReducer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { selectVisibleWorkOrdersByID } from '../../reducers/manageWorkOrderReducer';

interface Iprops {
    t: TFunction;
    colorButton: string;
    currentTile: Itile;
    overrideColumns?: any[];
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const { totalPages, tableFilters } = state.manageWorkOrder;
    const jobWorkOrdersByID = selectJobWorkOrdersWithWorkOrders(state);
    const tableData = selectVisibleWorkOrdersByID(state);
    const fseUsersByID = state.manageJob.fseUsersByID;

    tableData.forEach(td => {
        if (!td.assignedUser && td.userID) {
            const user = fseUsersByID[td.userID];
            td.assignedUser = user ? `${user.first} ${user.last}` : '';
        }
    });

    return {
        loading: selectIsLoading(state),
        tableData,
        totalPages,
        tableFilters,
        user: state.user,
        selection: state.manageWorkOrder.selection,
        jobWorkOrdersByID,
        usersByID: state.manageUser.usersByID
    };
};

export const WorkOrderBeaconTableContainer = connect(mapStateToProps, {
    toggleEditWorkOrderModal,
    setSelectedWorkOrderID,
    setTableFilter,
    updateWorkOrderSelection
})(WorkOrderBeaconTable);
