import { beginAjaxCall } from './ajaxStatusActions';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../constants/apiEndpoints';
import { msalFetch } from '../components/auth/Auth-Utils';

import * as types from './actionTypes';
import { constants } from '../constants/constants';
import { IsuggestedPart, ItableFiltersParams, ThunkResult } from '../models';
import { initialSuggestedPart } from '../reducers/initialState';
import { omit, map } from 'lodash';
const uuidv4 = require('uuid/v4');

export const searchSuggestedParts = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const {
            page,
            partSearch,
            productSearch,
            productID
        } = getState().manageSuggestedParts.tableFilters;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { page, partSearch, productSearch, productID }
        };
        const url = API.suggestedParts.search;
        return msalFetch(url, axiosOptions)
            .then(data => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.SEARCH_SUGGESTED_PARTS_SUCCESS,
                        payload: data.data
                    });
                    dispatch({
                        type: types.SUGGESTED_PARTS_MANAGE_TOTAL_PAGES,
                        pages: data.data.pages
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.SEARCH_SUGGESTED_PARTS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'searchSuggestedParts');
                console.error(error);
            });
    };
};

export function saveSuggestedPart(
    suggestedParts: IsuggestedPart[]
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        dispatch({ type: types.CLOSE_ALL_MODALS });

        const suggestedPartsForAPI = map(suggestedParts, part => {
            const cleanedSuggestedPart = omit(part, [
                'part',
                'product',
                'installBaseID',
                'installBaseQuantity',
                'createDate',
                'updateDate'
            ]);
            return {
                ...cleanedSuggestedPart,
                id: part.id || uuidv4()
            };
        });

        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: suggestedPartsForAPI
        };

        const url = API.suggestedParts.save;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                }
                dispatch({
                    type: types.SAVE_SUGGESTED_PARTS_SUCCESS,
                    part: suggestedParts[0]
                });
                dispatch(searchSuggestedParts());
                return data;
            })
            .catch((error: any) => {
                dispatch({
                    type: types.SAVE_SUGGESTED_PARTS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get brands');
                console.error(error);
            });
    };
}

/*
 * updateSelectedSuggestedPart
 * update the selected suggestedPart
 */
export const updateSelectedSuggestedPart = (
    suggestedPart = initialSuggestedPart
) => ({
    type: types.UPDATE_SELECTED_SUGGESTED_PART,
    payload: suggestedPart
});

export const toggleEditSuggestedPartModal = (show?: boolean) => ({
    type: types.TOGGLE_MODAL_EDIT_SUGGESTED_PART,
    show
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_SUGGESTED_PARTS,
    filters
});
