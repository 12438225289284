import * as React from 'react';
import { useEffect } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Ifacility } from '../../models';
import { IinitialState } from '../../reducers';
import CommonModal from '../common/CommonModal';
import { getFacilities } from '../../reducers/facilitiesReducer';
import { constants } from '../../constants/constants';
import { getProductInfo } from '../../reducers/productInfoReducer';
import { toastr } from 'react-redux-toastr';
interface Istate {
    facilities: Ifacility[];
    primaryFacility: string;
}

interface Iprops {
    showModal: boolean;
    hideModal: () => void;
    selection: string[];
    colorButton: string;
    mergeFacilities: (primary: string, secondary: string[]) => void;
}

const MergeFacilityModal: React.FC<Iprops & WithTranslation> = props => {
    const { t } = props;
    const allFacilities = useSelector(getFacilities);
    const productInfo = useSelector(getProductInfo);
    const [state, setState] = React.useState<Istate>({
        facilities: [],
        primaryFacility: ''
    });

    useEffect(() => {
        if (props.showModal) {
            //Make sure facilities is empty before adding

            let facilitiesToAdd: Ifacility[] = [];

            props.selection.forEach(value => {
                const facilityId = value.slice(7);
                facilitiesToAdd.push(allFacilities[facilityId]);
            });

            let defaultId = '';
            if (facilitiesToAdd.length > 0) {
                defaultId = facilitiesToAdd[0].id;
            }

            setState({
                facilities: facilitiesToAdd,
                primaryFacility: defaultId
            });
        }
    }, [props.showModal]);

    const onValueChange = (event: any) => {
        setState({ ...state, primaryFacility: event.target.value });
    };

    const merge = () => {
        if (state.primaryFacility === '') {
            toastr.warning(
                t('warning'),
                t('Select the primary facility'),
                constants.toastrWarning
            );
        } else {
            //You cannot merge facilities if they do not have the same Standard
            const primary = state.facilities.filter(
                x => x.id === state.primaryFacility
            )[0];
            let anyErrors = false;

            const secondaryFacilities = state.facilities.filter(
                x => x.id !== state.primaryFacility
            );

            secondaryFacilities.forEach(temp => {
                if (temp.standardID !== primary.standardID) {
                    anyErrors = true;
                }
            });

            if (anyErrors) {
                toastr.warning(
                    t('warning'),
                    t('All facilities must have the same standard'),
                    constants.toastrWarning
                );
            } else {
                const ids = secondaryFacilities.map(value => {
                    return value.id;
                });

                props.mergeFacilities(state.primaryFacility, ids);
            }
        }
    };

    return (
        <>
            <CommonModal
                show={props.showModal}
                className="user-edit"
                onHide={() => props.hideModal}
                title={t('mergeFacilities')}
                container={document.getElementById('two-pane-layout')}
            >
                <div>
                    {state.facilities.map((facility: Ifacility, index) => {
                        return (
                            <div
                                key={facility.id}
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                    marginLeft: '15px',
                                    fontSize: '1.2em'
                                }}
                            >
                                <label
                                    htmlFor="primary"
                                    style={{ verticalAlign: 'middle' }}
                                >
                                    {props.t('makePrimary')}
                                </label>

                                {/* First radio should be checked */}
                                {index === 0 && (
                                    <input
                                        type="radio"
                                        defaultChecked
                                        onChange={onValueChange}
                                        value={facility.id}
                                        name="primary"
                                        style={{ marginLeft: '10px' }}
                                    />
                                )}
                                {index > 0 && (
                                    <input
                                        type="radio"
                                        onChange={onValueChange}
                                        value={facility.id}
                                        name="primary"
                                        style={{ marginLeft: '10px' }}
                                    />
                                )}

                                <p style={{ fontWeight: 'bold' }}>
                                    {facility.name}
                                </p>
                                <p
                                    style={{ color: constants.colors.textBlue }}
                                >{`${t('sapFacilityNumber')} - ${
                                    facility.sapFacilityNumber
                                }`}</p>
                                <p>
                                    {
                                        productInfo.standards[
                                            facility.standardID
                                        ].name
                                    }
                                </p>
                                <p>
                                    {facility.address} {facility.address2}
                                </p>
                                <p>
                                    {facility.city} {facility.state}{' '}
                                    {facility.postalCode}
                                </p>
                                <p>
                                    {
                                        constants.countries[facility.countryID]
                                            .label
                                    }
                                </p>
                            </div>
                        );
                    })}

                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={props.hideModal}
                        >
                            {t('close')}
                        </Button>
                        <Button bsStyle={props.colorButton} onClick={merge}>
                            {t('merge')}
                        </Button>
                    </Col>
                </div>
            </CommonModal>
        </>
    );
};

export default withTranslation('manageFacility')(MergeFacilityModal);
