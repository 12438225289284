import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import {
    ItableFiltersReducer,
    Itile,
    IdeficiencyCode,
    IproductInfo,
    Ioption
} from '../../models';
import ReactTable, { Column, FinalState, RowInfo } from 'react-table';
import {
    clearSelectedCodeID,
    deleteCode,
    getCodes,
    setSelectedCodeID,
    setTableFilter,
    toggleEditCodeModal
} from '../../actions/manageCodeActions';

import { Banner } from '../common/Banner';
import { Button } from 'react-bootstrap';
import { FieldConfig } from 'react-reactive-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { emptyTile, initialDeficiencyCode } from '../../reducers/initialState';
import { debounce } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { EditCodeModal } from './EditCodeModal';
import { selectVisibleDeficiencyCodes } from '../../reducers/manageCodeReducer';
import { closeAllModals } from '../../actions/commonActions';
import { IinitialState } from '../../reducers';

interface RowInfoCode extends RowInfo {
    original: IdeficiencyCode;
}

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    loading: boolean;
}

interface IdispatchProps {
    tableData: IdeficiencyCode[];
    totalPages: number;
    showEditCodeModal: boolean;
    getCodes: typeof getCodes;
    toggleEditCodeModal: typeof toggleEditCodeModal;
    deleteCode: typeof deleteCode;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    loading: boolean;
    setSelectedCodeID: typeof setSelectedCodeID;
    clearSelectedCodeID: typeof clearSelectedCodeID;
    selectedCode: IdeficiencyCode;
    productInfo: IproductInfo;
    closeAllModals: typeof closeAllModals;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
    searchFieldConfig: FieldConfig;
}

class ManageCode extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    private setTableFilterDebounced: (formValues: {
        [key: string]: any;
    }) => void;

    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            columns: [],
            searchFieldConfig: this.buildSearchControls()
        };
        this.setTableFilterDebounced = debounce(
            this.props.setTableFilter,
            constants.formDebounceTime
        );
    }

    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.setColumns();
        this.props.getCodes();
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        // If the edit modal was used, call api to refresh data
        if (
            prevProps.showEditCodeModal !== this.props.showEditCodeModal &&
            !this.props.showEditCodeModal
        ) {
            this.props.getCodes();
            this.setState({ selectedRow: null });
        }
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
    }
    componentWillUnmount() {
        this.props.closeAllModals();
    }

    buildSearchControls = (): FieldConfig => {
        const { search, standard } = this.props.tableFilters;

        const mainSearchControls = {
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'standardTextLibrary',
                    colWidth: 3,
                    type: 'text',
                    placeholder: 'Search by name',
                    isClearable: true
                },
                formState: { value: search, disabled: false }
            },
            standard: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: this.props.productInfo.standardOptions,
                    label: 'standard',
                    colWidth: 3,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'standard',
                    isClearable: true
                },
                formState: {
                    value: standard,
                    disabled: false
                }
            }
        };

        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    };

    onSearchValueChanges = (value: any, key: string) => {
        this.setTableFilterDebounced({ [key]: value, page: 0 });
    };

    handleEdit(row: any) {
        this.setState({ selectedRow: row.index });
        this.props.toggleEditCodeModal();
        this.props.setSelectedCodeID(row.original.id);
    }

    handleDelete(deletedItem: any) {
        const toastrConfirmOptions = {
            onOk: () => {
                deletedItem = {
                    ...deletedItem
                };
                this.props.deleteCode(deletedItem);
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('deleteOk'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deleteConfirm'), toastrConfirmOptions);
    }

    onPageChange = (page: number) => {
        const newPage = page;
        this.props.setTableFilter({ page: newPage });
    };
    /*
     * Set Columns sets columns to state
     */
    setColumns = () => {
        const columns = TableUtil.translateHeaders(
            [
                {
                    Header: 'name',
                    accessor: 'name',
                    minWidth: 300
                },
                {
                    id: 'standard',
                    Header: 'standard',
                    accessor: ({ standardID }: IdeficiencyCode) => {
                        const item = this.props.productInfo.standardOptions.filter(
                            (opt: Ioption) => {
                                return opt.value === standardID;
                            }
                        )[0];
                        if (item) {
                            return standardID ? item.label : '';
                        } else {
                            return null;
                        }
                    },
                    minWidth: 300
                },
                {
                    Header: '',
                    id: 'delete',
                    minWidth: 25,
                    Cell: row => {
                        return (
                            <Button
                                bsStyle="link"
                                style={{
                                    float: 'right',
                                    color: constants.colors.red
                                }}
                            >
                                <FontAwesomeIcon icon={['far', 'times']} />
                            </Button>
                        );
                    }
                }
            ],
            this.props.t
        );
        this.setState({ columns });
    };

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };

    getTdProps = (
        fState: FinalState,
        rowInfo: RowInfoCode | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column && column.id && column.id === 'delete') {
            return {
                onClick: () => this.handleDelete(rowInfo.original)
            };
        } else if (rowInfo) {
            return {
                onClick: () => {
                    this.setState({
                        selectedRow: rowInfo.index
                    });
                    this.handleEdit(rowInfo);
                }
            };
        } else {
            return {};
        }
    };
    onPageSizeChange = (rows: number) => {
        this.props.setTableFilter({ rows, page: 0 });
    };

    render() {
        const { t, tableData = [] } = this.props;
        return (
            <div className="manage-alert">
                <Banner
                    title={t('standardTextLibrary')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                <SearchTableForm
                    fieldConfig={this.state.searchFieldConfig}
                    handleSubmit={this.props.getCodes}
                    loading={this.props.loading}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                    t={this.props.t}
                    showSearchButton={false}
                />
                <div>
                    <Button
                        className="table-add-button"
                        bsStyle="link"
                        onClick={() => {
                            this.setState({ selectedRow: null }, () => {
                                this.props.toggleEditCodeModal();
                            });
                        }}
                    >
                        {t(`manageCode:addStandardText`)}
                    </Button>
                </div>
                <ReactTable
                    data={tableData}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    getTdProps={this.getTdProps}
                    defaultPageSize={
                        this.props.tableFilters.rows ||
                        constants.tablePageSizeDefault
                    }
                    page={this.props.tableFilters.page}
                    showPageSizeOptions={true}
                    onPageSizeChange={this.onPageSizeChange}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    loading={this.props.loading && tableData.length === 0}
                />

                <EditCodeModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState) => {
    const tableData = selectVisibleDeficiencyCodes(
        state.manageCode.deficiencyCodesByID,
        state.manageCode.tableFilters
    );
    const selectedCode =
        state.manageCode.deficiencyCodesByID[state.manageCode.selectedCodeID] ||
        initialDeficiencyCode;
    return {
        tableData,
        selectedCode,
        totalPages: state.manageCode.totalPages,
        showEditCodeModal: state.manageCode.showEditCodeModal,
        tableFilters: state.manageCode.tableFilters,
        productInfo: state.productInfo
    };
};

export default withTranslation('manageCode')(
    connect(mapStateToProps, {
        getCodes,
        toggleEditCodeModal,
        deleteCode,
        setTableFilter,
        setSelectedCodeID,
        clearSelectedCodeID,
        closeAllModals
    })(ManageCode)
);
