import * as React from 'react';
import * as moment from 'moment';

import {
    ImanageUserQueueReducer,
    IuserQueue,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import ReactTable, { FinalState, RowInfo, SortingRule } from 'react-table';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    approveUser,
    getUserQueue,
    rejectUser,
    setTableFilter
} from '../../actions/manageUserQueueActions';
import { emptyTile } from '../../reducers/initialState';
import queryString from 'query-string';

import { Banner } from '../common/Banner';
/*
 * The New User Queue
 */
import { Button } from 'react-bootstrap';
import EditQueueUserModal from './EditQueueUserModal';
import { FieldConfig } from 'react-reactive-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router-dom';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { closeAllModals } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { values } from 'lodash';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { addQuery } from '../common/OtherUtils';

export enum manageUserQueueQueryParamsEnum {
    selectedUserQueueID = 'selectedUserQueueID'
}

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    showEditQueueUserModal: boolean;
    showEditFacilityModal: boolean;
    loading: boolean;
    userQueue: ImanageUserQueueReducer;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    queryParams: typeof manageUserQueueQueryParamsEnum;
    setQueueSearch: (value: string) => Promise<void>;
    approveUser: typeof approveUser;
    rejectUser: (value: string) => Promise<void>;
    getUserQueue: typeof getUserQueue;
    closeAllModals: typeof closeAllModals;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    tableData: IuserQueue[];
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
}

class ManageUserQueue extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    public columns: any[];
    public buttonInAction = false;
    private setTableFilterTimeout: any;
    private searchFieldConfig: FieldConfig;

    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: null,
            currentTile: emptyTile
        };
        this.columns = TableUtil.translateHeaders(
            [
                {
                    id: 'name',
                    Header: 'name',
                    accessor: 'user',
                    Cell: (row: any) => (
                        <span>
                            {row.value.first} {row.value.last}
                        </span>
                    )
                },
                {
                    Header: 'email',
                    accessor: 'user.email',
                    minWidth: 110
                },
                {
                    Header: 'country',
                    id: 'country',
                    accessor: ({ user }: IuserQueue) => {
                        if (user.countryID) {
                            return (
                                constants.countries[user.countryID].label || ''
                            );
                        } else {
                            return '';
                        }
                    }
                },
                {
                    Header: 'created',
                    accessor: ({ user }: { user: Iuser }) => {
                        return moment
                            .utc(user.createDate)
                            .local(true)
                            .format('DD-MMM-YY LT');
                    },
                    id: 'created'
                },
                {
                    Header: 'approve',
                    accessor: 'id',
                    Cell: this.ApproveCell,
                    maxWidth: 90
                }
            ],
            this.props.t
        );
        this.searchFieldConfig = {
            controls: {
                search: {
                    render: FormUtil.TextInputWithoutValidation,
                    meta: {
                        label: 'common:search',
                        colWidth: 4,
                        type: 'text',
                        placeholder: 'searchPlaceholder',
                        defaultValue: this.props.tableFilters.search
                    }
                },
                country: {
                    render: FormUtil.SelectWithoutValidation,
                    meta: {
                        label: 'common:country',
                        options: FormUtil.convertToOptions(
                            Object.values(constants.countries)
                        ),
                        colWidth: 3,
                        type: 'select',
                        placeholder: 'countrySearchPlaceholder',
                        defaultValue: this.props.tableFilters.countryID,
                        isClearable: true
                    }
                }
            }
        };
    }

    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.props.getUserQueue();
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditQueueUserModal !==
                this.props.showEditQueueUserModal &&
            !this.props.showEditQueueUserModal
        ) {
            this.setState({ selectedRow: null });
        }
        // automatically get inventory every time a fitler changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.getUserQueue();
            this.setState({ selectedRow: null });
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
    }

    componentWillUnmount() {
        this.props.closeAllModals();
    }

    // handleTableProps(state: any, rowInfo: any, column: any, instance: any) {

    // }
    ApproveCell = (row: any) => {
        return (
            <div className="text-right approve-buttons">
                <Button
                    bsStyle="link"
                    className=""
                    bsSize="sm"
                    disabled={this.props.loading}
                    onClick={(e: React.MouseEvent<Button>) => {
                        this.buttonInAction = true;
                        this.props
                            .approveUser(row.value)
                            .then((blah: any) => {
                                this.buttonInAction = false;
                            })
                            .catch((err: any) => {
                                this.buttonInAction = false;
                            });
                    }}
                >
                    <FontAwesomeIcon icon={['far', 'check']} />
                </Button>
                <Button
                    bsStyle="link"
                    className=""
                    bsSize="sm"
                    disabled={this.props.loading}
                    onClick={(e: React.MouseEvent<Button>) => {
                        this.buttonInAction = true;
                        this.props
                            .rejectUser(row.value)
                            .then((blah: any) => {
                                this.buttonInAction = false;
                            })
                            .catch((err: any) => {
                                this.buttonInAction = false;
                            });
                    }}
                >
                    <FontAwesomeIcon icon={['far', 'times']} />
                </Button>
            </div>
        );
    };

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        // console.info("ROWINFO", rowInfo);
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    if (!this.buttonInAction) {
                        this.setState({
                            selectedRow: rowInfo.index
                        });
                        addQuery(
                            manageUserQueueQueryParamsEnum.selectedUserQueueID,
                            rowInfo.original.id,
                            this.props.history
                        );
                    }
                },
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };

    /*
     * (reusable)
     * get the next or previous page of data.  the table is 0 indexed but the API is not
     */
    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'search':
                clearTimeout(this.setTableFilterTimeout);
                this.setTableFilterTimeout = setTimeout(() => {
                    this.props.setTableFilter({ search: value, page: 1 }); // this causes performance issues so we use a rudamentary debounce
                }, constants.tableSearchDebounceTime);
                break;
            case 'country':
                clearTimeout(this.setTableFilterTimeout);
                this.setTableFilterTimeout = setTimeout(() => {
                    this.props.setTableFilter({ countryID: value, page: 1 }); // this causes performance issues so we use a rudamentary debounce
                }, constants.tableSearchDebounceTime);
            default:
                break;
        }
    };

    /*
     * (reusable)
     * set the sorted changes to redux
     */
    onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };

    render(): JSX.Element {
        const { t } = this.props;

        return (
            <div className="user-queue">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                <SearchTableForm
                    fieldConfig={this.searchFieldConfig}
                    handleSubmit={this.props.getUserQueue}
                    loading={this.props.loading}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    t={this.props.t}
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                />
                <ReactTable
                    data={this.props.tableData}
                    onSortedChange={this.onSortedChanged}
                    columns={this.columns}
                    getTrProps={this.getTrProps}
                    pageSize={
                        this.props.tableData.length >= 10
                            ? this.props.tableData.length
                            : 10
                    }
                    page={this.props.tableFilters.page - 1}
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={this.props.userQueue.totalPages}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                />
                <EditQueueUserModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    queryParams={this.props.queryParams}
                    history={this.props.history}
                />

                {/* the EditFacility Modal is rendered inside the UserQueueForm because we need to pass the selected customer */}
            </div>
        );
    }
}

/*
 * AddCustomerModal will connect to redux, impliment CommonModal, as well as AddCustomerForm
 */

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const queryParams = queryString.parse(
        ownProps.location.search
    ) as typeof manageUserQueueQueryParamsEnum;

    return {
        user: state.user,
        userQueue: state.manageUserQueue,
        loading: selectIsLoading(state),
        showEditFacilityModal: state.showEditFacilityModal,
        tableData: values(state.manageUserQueue.userQueuesByID),
        tableFilters: state.manageUserQueue.tableFilters,
        queryParams
    };
};

export default withTranslation('manageUserQueue')(
    connect(mapStateToProps, {
        getUserQueue,
        approveUser,
        rejectUser,
        closeAllModals,
        setTableFilter
    })(ManageUserQueue)
);
