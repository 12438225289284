import * as React from 'react';
import * as types from '../../../actions/actionTypes';
import {
    AbstractControl,
    FieldConfig,
    FormGenerator
} from 'react-reactive-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FormGroup } from 'react-bootstrap';
import { debounce, forEach } from 'lodash';
import { Ifacility, Iuser } from '../../../models';
import { searchFacilitiesForAsyncSelect } from '../../../actions/commonActions';
import { FormUtil } from '../../common/FormUtil';
import { constants } from '../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { initialFacility } from '../../../reducers/initialState';
import {
    getFacilities,
    getSelectedFacilityID
} from '../../../reducers/facilitiesReducer';

interface Iprops {
    loading: boolean;
    user: Iuser;
    classNameOverride: string;
    searchFacilitiesForAsyncSelect: typeof searchFacilitiesForAsyncSelect;
    facilitySelected: (facilityID: string) => void;
}

interface Istate {
    fieldConfig: FieldConfig;
}

const SearchFacilityForm: React.FC<Iprops & WithTranslation> = props => {
    const dispatch = useDispatch();
    const allFacilities = useSelector(getFacilities);
    const selectedFacilityID = useSelector(getSelectedFacilityID);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedFacility, setSelectedFacility] = React.useState(
        selectedFacilityID ? allFacilities[selectedFacilityID] : initialFacility
    );

    React.useEffect(() => {
        if (selectedFacilityID !== '') {
            const facility = allFacilities[selectedFacilityID];
            setSelectedFacility(facility);
            props.facilitySelected(facility.id);
        }
    }, [selectedFacilityID]);

    const saveFacilities = (facilities: Ifacility[]) => {
        dispatch({
            type: types.GET_FACILITIES_SUCCESS,
            facilities
        });
    };

    const loadOptions = debounce((searchTerm, callback) => {
        if (searchTerm.length >= 3) {
            props.searchFacilitiesForAsyncSelect(
                searchTerm,
                null,
                null,
                callback,
                saveFacilities
            );
            return;
        }

        callback();
    }, 200);

    const buildFieldConfig = () => {
        let nameWithAddress = `${selectedFacility.name} | ${
            selectedFacility.address
        }${selectedFacility.address2 ? ' ' + selectedFacility.address2 : ''} ${
            selectedFacility.city
        } ${selectedFacility.state} ${selectedFacility.postalCode}`;
        const facilityOption = {
            value: selectedFacility.id,
            label: nameWithAddress
        };
        let facilityClass = 'banner-input';

        if (props.classNameOverride !== '') {
            facilityClass = props.classNameOverride;
        }

        const facility = {
            render: FormUtil.AsyncSelect,
            meta: {
                label: 'Facility',
                loadOptions,
                colWidth: 4,
                colWidthMedium: 4,
                colWidthLarge: 4,
                type: 'select',
                placeholder: 'facilityPlaceholder',
                className: facilityClass
            },
            formState: {
                value: facilityOption,
                disabled: false
            }
        };

        return {
            controls: { facility }
        };
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [state, setState] = React.useState<Istate>({
        fieldConfig: buildFieldConfig()
    });

    let formGroup: FormGroup | any;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let subscription: any;
    let setFacilitySearchTimeout: any;

    const handleSetFacility = (value: any) => {
        // causes performance issues so we use a rudamentary debounce
        if (value && value.value) {
            clearTimeout(setFacilitySearchTimeout);

            setFacilitySearchTimeout = setTimeout(() => {
                props.facilitySelected(value.value);
            }, constants.tableSearchDebounceTime);
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    const onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'facility':
                handleSetFacility(value);
                break;
            default:
                console.error('unknown value change detected.');
        }
    };

    const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const subscribeToValueChanges = () => {
        forEach(buildFieldConfig().controls, (input: any, key) => {
            subscription = formGroup
                .get(key)
                .valueChanges.subscribe((value: any) => {
                    onValueChanges(value, key);
                });
        });
    };

    const setForm = (form: AbstractControl) => {
        formGroup = form;
        formGroup.meta = {
            loading: props.loading
        };
        subscribeToValueChanges();
    };

    const formClassName = `beacon-form`;

    return (
        <div>
            <form onSubmit={handleSubmit} className={formClassName}>
                <FormGenerator
                    onMount={setForm}
                    fieldConfig={state.fieldConfig}
                />
            </form>
        </div>
    );
};
export default withTranslation('manageInventory')(SearchFacilityForm);
