import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import {
    ImanageProductQueueReducer,
    Ioption,
    Iproduct,
    IproductInfo,
    Isubcategory,
    IproductQueueObject,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import ReactTable, { FinalState, RowInfo, SortingRule } from 'react-table';
import {
    getProductInfo,
    mergeProduct,
    toggleEditProductModal
} from '../../actions/manageInventoryActions';
import {
    getProductQueue,
    setTableFilter
} from '../../actions/manageProductQueueActions';

import { Banner } from '../common/Banner';
/*
 * hopsital Managers manage their products
 * Note: did minimal renaming from the ManageInventory component
 */
import { Col } from 'react-bootstrap';
import EditProductModal from '../manageInventory/EditProductModal';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router-dom';
import SearchNewProductsModal from '../manageInventory/SearchNewProductsModal';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { closeAllModals } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import {
    emptyTile,
    initialProductQueueObject
} from '../../reducers/initialState';
import { toastr } from 'react-redux-toastr';
import { values } from 'lodash';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    showApproveProductModal: boolean;
    loading: boolean;
    userManage: ImanageProductQueueReducer;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleEditProductModal: typeof toggleEditProductModal;
    getProductInfo: typeof getProductInfo;
    toggleSecurityFunctionsModal: () => void;
    getProductQueue: typeof getProductQueue;
    closeAllModals: typeof closeAllModals;
    productInfo: IproductInfo;
    user: Iuser;
    tableData: IproductQueueObject[];
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    // mergeProduct: typeof mergeProduct;
    mergeProduct: (sprod: string, dproduct: string) => Promise<void>;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any;
    selectedQueueObject: IproductQueueObject;
    selectedMainCategoryId: string | null;
}

class ManageProductQueue extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    // public searchFieldConfig: any;
    public searchFieldConfigBanner: any;
    public buttonInAction = false;
    private setTableFilterTimeout: any;
    private searchFieldConfig: FieldConfig;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            columns: [],
            selectedQueueObject: initialProductQueueObject,
            selectedMainCategoryId: null
        };
        this.searchFieldConfig = this.buildSearchControls();
    }

    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.setColumns();
        this.props.getProductInfo();
        this.props.getProductQueue();
    }
    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showApproveProductModal !==
                this.props.showApproveProductModal &&
            !this.props.showApproveProductModal
        ) {
            this.setState({ selectedQueueObject: initialProductQueueObject });
        }

        // we only need to check the mainCategory options because both brands and mainCategory options are received in the same API response
        // and before they are received, there will not be any length.
        if (
            prevProps.productInfo.mainCategoryOptions.length !==
                this.props.productInfo.mainCategoryOptions.length ||
            prevProps.productInfo.brandOptions.length !==
                this.props.productInfo.brandOptions.length
        ) {
            this.setColumns();
        }

        // automatically get inventory every time a fitler changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.getProductQueue();
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }

        this.searchFieldConfig = this.buildSearchControls();
    }
    componentWillUnmount() {
        this.props.closeAllModals();
    }

    /*
     * Reset the table filters (not used currently)
     */
    resetTableFilters = () => {
        this.props.setTableFilter({
            search: '',
            page: 1
        });
    };

    /*
     * Set Columns sets columns to state
     * setting columns here in order to reset them if and after we receive mainCategory and manufacturer options
     */
    setColumns = () => {
        const columns = TableUtil.translateHeaders(
            [
                {
                    Header: 'sku',
                    accessor: 'product.sku'
                },
                {
                    Header: 'name',
                    accessor: 'product.name',
                    minWidth: 400
                }
            ],
            this.props.t
        );
        this.setState({ columns });
    };

    buildSearchControls = (): FieldConfig => {
        const { tableFilters, productInfo } = this.props;
        const { selectedMainCategoryId } = this.state;
        const mainSearchControls = {
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:search',
                    colWidth: 3,
                    type: 'text',
                    placeholder: 'searchPlaceholder',
                    defaultValue: tableFilters.search
                }
            },
            mainCategory: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:mainCategory',
                    options: productInfo.mainCategoryOptions,
                    colWidth: 2,
                    type: 'select',
                    placeholder: 'manageInventory:mainCategoryPlaceholder',
                    defaultValue: tableFilters.mainCategory,
                    isClearable: true
                }
            },
            subCategory: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:subCategory',
                    options: Object.values(productInfo.subcategories).reduce(
                        (carry: Ioption[], subcategory: Isubcategory) => {
                            if (!selectedMainCategoryId) {
                                return carry;
                            }

                            if (
                                subcategory.mainCategoryID !==
                                selectedMainCategoryId
                            ) {
                                return carry;
                            }

                            const option: Ioption = {
                                label: subcategory.name,
                                value: subcategory.id
                            };
                            carry.push(option);
                            return carry;
                        },
                        []
                    ),
                    colWidth: 2,
                    type: 'select',
                    defaultValue: tableFilters.subCategory,
                    isClearable: true
                }
            },
            brand: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:brand',
                    options: productInfo.brandOptions,
                    colWidth: 2,
                    type: 'select',
                    defaultValue: tableFilters.brand,
                    isClearable: true
                }
            }
        };
        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    };

    /*
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    if (!this.buttonInAction) {
                        this.setState({
                            selectedQueueObject: rowInfo.original
                        });
                        this.props.toggleEditProductModal();
                    }
                },
                style: {
                    background: this.state.selectedRow[rowInfo.viewIndex]
                        ? constants.colors[
                              `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                          ]
                        : ''
                }
            };
        } else {
            return {};
        }
    };

    // get the next or previous page of data.  the table is 0 indexed but the API is not
    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'search':
                clearTimeout(this.setTableFilterTimeout);
                this.setTableFilterTimeout = setTimeout(() => {
                    this.props.setTableFilter({ search: value, page: 1 }); // this causes performance issues so we use a rudamentary debounce
                }, constants.tableSearchDebounceTime);
                break;
            case 'mainCategory':
                this.props.setTableFilter({
                    mainCategory: value,
                    page: 0
                });
                this.props.setTableFilter({
                    subCategory: undefined,
                    page: 0
                });
                break;
            case 'subCategory':
                this.props.setTableFilter({
                    subCategory: value,
                    page: 0
                });
                break;
            case 'brand':
                this.props.setTableFilter({
                    brand: value,
                    page: 0
                });
                break;
            case 'origin':
                this.props.setTableFilter({
                    origin: value,
                    page: 0
                });
            default:
                break;
        }
    };
    onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };

    /*
     * Handle Merge Product
     */
    handleMergeProduct = (product: Iproduct) => {
        toastr.confirm(
            `${this.props.t('mergeConfirmPart_01')} \n ${
                this.state.selectedQueueObject.product.name
            } ${this.props.t('mergeConfirmPart_02')} ${product.name}?`,
            {
                onOk: () => {
                    this.props
                        .mergeProduct(
                            this.state.selectedQueueObject.product.id,
                            product.id
                        )
                        .then(() => {
                            this.props.getProductQueue();
                        })
                        .catch((error: any) => console.error(error));
                }
            }
        );
    };
    render() {
        if (this.props.productInfo.mainCategoryOptions.length === 0) {
            return (
                <Col xs={12}>
                    <h4> loading... </h4>
                </Col>
            );
        }
        const { t } = this.props;

        return (
            <div className="manage-inventory">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                <SearchTableForm
                    fieldConfig={this.searchFieldConfig}
                    handleSubmit={this.props.getProductQueue}
                    loading={this.props.loading}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    subscribeValueChanges={true}
                    onValueChanges={(value: any, key: any) => {
                        if (key === 'mainCategory') {
                            this.setState({
                                selectedMainCategoryId: value?.value
                            });
                        }
                        this.onSearchValueChanges(value, key);
                    }}
                    t={this.props.t}
                />
                <ReactTable
                    data={this.props.tableData}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    pageSize={
                        this.props.tableData.length >= 10
                            ? this.props.tableData.length
                            : 10
                    }
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={this.props.userManage.totalPages}
                    page={this.props.tableFilters.page - 1}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    onSortedChange={this.onSortedChanged}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    expanded={this.state.selectedRow}
                />
                <EditProductModal
                    selectedItem={this.state.selectedQueueObject.product}
                    selectedQueueObject={this.state.selectedQueueObject}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    secondModal={false}
                />
                <SearchNewProductsModal
                    selectedItem={this.state.selectedQueueObject.product}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    secondModal={true}
                    handleMergeProduct={this.handleMergeProduct}
                    selectedQueueObject={this.state.selectedQueueObject}
                    isApproved={true}
                    enableMergeProductMode={true}
                    wideModal={true}
                    disableCreateNew={false}
                    isProductQueue={true}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        user: state.user,
        userManage: state.manageProductQueue,
        loading: selectIsLoading(state),
        showApproveProductModal:
            state.manageProductQueue.showApproveProductModal,
        productInfo: state.productInfo,
        tableData: values(state.manageProductQueue.productQueueByID).filter(
            item => !item.isDeleted
        ),
        tableFilters: state.manageProductQueue.tableFilters
    };
};
export default withTranslation('manageProductQueue')(
    connect(mapStateToProps, {
        getProductQueue,
        toggleEditProductModal,
        closeAllModals,
        getProductInfo,
        setTableFilter,
        mergeProduct
    })(ManageProductQueue)
);
