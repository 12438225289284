import { AxiosRequestConfig, AxiosResponse } from "axios";
import { FormUtil } from "../../common/FormUtil";
import API from "../../../constants/apiEndpoints";
import { msalFetch } from "../../auth/Auth-Utils";
import { ADD_DOCUMENT_FAILED, ADD_DOCUMENT_SUCCESS, ADD_DOCUMENT_VERSION_SUCCESS, EDIT_DOCUMENT_FAILED, EDIT_DOCUMENT_SUCCESS, TOGGLE_MODAL_EDIT_DOCUMENT } from "../../../actions/actionTypes";
import { constants } from "../../../constants/constants";
import { beginAjaxCall } from "../../../actions/ajaxStatusActions";
import { endAjaxCall } from "../../../actions/ajaxStatusActions";
import { toastr } from 'react-redux-toastr';
import { IcloudDocument } from "../../../models";

export function create(formValues: {
  [key: string]: any;
}, dispatch: Function, parentId: string): Promise<any> {
      dispatch(beginAjaxCall());
      const headers = { 'content-type': 'multipart/form-data' };
      const axiosOptions: AxiosRequestConfig = {
          method: 'post',
          data: FormUtil.toFormData(formValues),
          headers
      };
      const url = API.cloudDocument.add;
      return msalFetch(url, axiosOptions)
          .then((data: AxiosResponse<any>) => {
              if (!data.data) {
                  throw new Error('Missing Data');
              } else {
                  if (parentId) {
                      dispatch({
                          type: ADD_DOCUMENT_VERSION_SUCCESS,
                          payload: data.data,
                          parentId
                      });
                  } else {
                      dispatch({
                          type: ADD_DOCUMENT_SUCCESS,
                          payload: data.data
                      });
                  }
                  dispatch({ type: TOGGLE_MODAL_EDIT_DOCUMENT });
                  toastr.success(
                      'Success',
                      `Created Document${parentId ? ' Version' : ''}`,
                      constants.toastrSuccess
                  );
                  return true;
              }
          })
          .catch(error => {
              dispatch({
                  type: ADD_DOCUMENT_FAILED,
                  error,
                  axiosOptions
              });
              constants.handleError(error, 'Create Document');
              console.error(error);
              throw error;
          })
          .finally(() => dispatch(endAjaxCall()));
  };

  export function edit(
    formValues: { [key: string]: any },
    document: IcloudDocument,
    parentId: string, 
    dispatch: Function
): Promise<any> {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: { ...formValues, id: document.id }
        };
        const url = `${API.cloudDocument.single}/update/${document.id}`;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (data.status !== 200) {
                    throw new Error('Missing Data');
                } else {
                    dispatch({
                        type: EDIT_DOCUMENT_SUCCESS,
                        payload: { ...data.data },
                        parentId
                    });
                    dispatch({ type: TOGGLE_MODAL_EDIT_DOCUMENT });
                    toastr.success(
                        'Success',
                        `Update Document.`,
                        constants.toastrSuccess
                    );
                }
                return true;
            })
            .catch(error => {
                dispatch({
                    type: EDIT_DOCUMENT_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'Update Document');
                console.error(error);
                throw error;
            })
            .finally(() => dispatch(endAjaxCall()));
    };

export const getLastModifiedDate = ({updateDate, createDate}: IcloudDocument) => updateDate ? updateDate : createDate;