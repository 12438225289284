/*
 * Edit Management Point List Test Procedures Modal
 */

import { connect } from 'react-redux';

import {
    updateMeasurementPointList,
    toggleEditMeasurementPointListTestProceduresModal
} from '../../actions/manageMeasurementPointListsActions';
import { initialMeasurementPointTab } from '../../reducers/initialState';
import { EditMeasurementPointListTestProceduresForm } from './EditMeasurementPointListTestProceduresForm';
import { WithCommonModal } from '../common/WithCommonModal';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedTab =
        state.manageMeasurementPointLists.selectedMeasurementPointList.measurementPointTabs.find(
            tab => tab.id === state.manageMeasurementPointLists.selectedTabID
        ) || initialMeasurementPointTab;
    return {
        title: ownProps.t('editTestProcedures'),
        className: 'edit-tab',
        secondModal: true,
        user: state.user,
        loading: selectIsLoading(state),
        show:
            state.manageMeasurementPointLists
                .showEditMeasurementPointListTestProceduresModal,
        selectedMeasurementPointList:
            state.manageMeasurementPointLists.selectedMeasurementPointList,
        selectedTab
    };
};

export const EditMeasurementPointListTestProceduresModal = withTranslation(
    'manageMeasurementPointLists'
)(
    connect(mapStateToProps, {
        onHide: toggleEditMeasurementPointListTestProceduresModal,
        toggleModal: toggleEditMeasurementPointListTestProceduresModal,
        updateMeasurementPointList
    })(WithCommonModal(EditMeasurementPointListTestProceduresForm))
);
