/*
 * Measurement Point Result History
 */

import * as React from 'react';
import * as moment from 'moment';

import { Button, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';

import { IinstallBaseHistory } from '../../models';
import { TFunction } from 'i18next';
import { measurementPointResultStatusTypesEnum } from '../../models-enums';

/*
 * MeasurementPointListItem
 *
 */
const MeasurementPointListItem = ({
    result,
    setHistoricalResultID,
    toggleMPResultNotes,
    setSelectedWorkOrderID,
    toggleEditWorkOrderModal,
    t,
    getWorkOrderByID
}: {
    result: IinstallBaseHistory;
    setHistoricalResultID: (id: string) => void;
    toggleMPResultNotes: () => void;
    setSelectedWorkOrderID: (id: string) => void;
    toggleEditWorkOrderModal: () => void;
    t: TFunction;
    getWorkOrderByID: (id: string) => any;
}) => {
    return (
        <ListGroupItem
            key={result.id}
            onClick={() => {
                // If there is not a work order, show the notes, else show the work order
                if (result.workOrderId === undefined) {
                    setHistoricalResultID(result.id);
                    toggleMPResultNotes();
                } else {
                    setSelectedWorkOrderID(result.workOrderId);
                    getWorkOrderByID(result.workOrderId).then(() => {
                        toggleEditWorkOrderModal();
                    });
                }
            }}
        >
            <Row>
                <Col md={4} style={{ paddingLeft: '4px', paddingRight: '2px' }}>
                    {moment
                        .utc(result.date)
                        .local(true)
                        .format('DD-MMM-YY LT')}
                </Col>
                <Col
                    md={3}
                    className="notes-column truncate"
                    style={{ paddingLeft: '4px', paddingRight: '2px' }}
                >
                    {result.jobType}
                </Col>
                <Col
                    md={5}
                    className={`status ${
                        measurementPointResultStatusTypesEnum[result.status]
                    }`}
                    style={{ paddingLeft: '4px', paddingRight: '10px' }}
                >
                    {t(
                        'manageMeasurementPointLists:' +
                            measurementPointResultStatusTypesEnum[result.status]
                    )}
                </Col>
            </Row>
        </ListGroupItem>
    );
};

interface Iprops {
    isLoading: boolean;
    installBaseHistory: IinstallBaseHistory[];
    t: TFunction;
    locationString: string;
    colorButton: any;
    toggleModal: () => void;
    toggleMPResultNotes: () => void;
    toggleWorkOrderCloseModal: () => void;
    setHistoricalResultID: (id: string) => void;
    setSelectedWorkOrderID: (id: string) => void;
    toggleEditWorkOrderModal: () => void;
    getWorkOrderByID: (id: string) => any;
}
export const MPResultHistory = (props: Iprops) => {
    const { t, installBaseHistory, isLoading } = props;
    if (installBaseHistory.length) {
        return (
            <div className="result-history">
                <h5 style={{ paddingLeft: '15px', paddingBottom: '15px' }}>
                    {props.locationString}
                </h5>
                <ListGroup>
                    {installBaseHistory.map(result => {
                        return (
                            <MeasurementPointListItem
                                key={result.id}
                                {...props}
                                result={result}
                            />
                        );
                    })}
                </ListGroup>
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle={props.colorButton}
                        type="button"
                        onClick={props.toggleModal}
                    >
                        {t('common:done')}
                    </Button>
                </Col>
            </div>
        );
    } else {
        return (
            <div className="result-history">
                <Col md={12}>
                    {isLoading ? (
                        <h4>{t('common:loading')}</h4>
                    ) : (
                        <h4>{t('No history for this asset.')}</h4>
                    )}
                </Col>
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle={props.colorButton}
                        type="button"
                        onClick={props.toggleModal}
                    >
                        {t('common:done')}
                    </Button>
                </Col>
            </div>
        );
    }
};
