/*
 * Manage Suggested Part Form
 */

import { Col, Button } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    Validators
} from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import { debounce } from 'lodash';

import * as React from 'react';

import { FormUtil } from '../common/FormUtil';
import { ItableFiltersReducer, Iproduct, IsuggestedPart } from '../../models';
import { IgenericFormValues } from '../../modelsForms';
import { constants } from '../../constants/constants';
import {
    saveSuggestedPart,
    updateSelectedSuggestedPart
} from '../../actions/manageSuggestedPartsActions';
import { searchPartsForAsyncSelect } from '../../actions/managePartActions';
import { manageSuggestedPartsQueryParamsEnum } from './ManageSuggestedParts';
import { Iuser } from '../../models';

interface Iprops {
    toggleModal: () => void;
    selectedSuggestedPart?: any;
    originalSuggestedPart?: any;
    selectedProduct: Iproduct;
    loading: boolean;
    colorButton: string;
    queryParams: typeof manageSuggestedPartsQueryParamsEnum;
    t: TFunction;
    tableFilters: ItableFiltersReducer;
    saveSuggestedPart: typeof saveSuggestedPart;
    onChange: typeof updateSelectedSuggestedPart;
    user: Iuser;
}

interface Istate {
    fieldConfig: FieldConfig;
}

class ManageSuggestedPartForm extends React.Component<Iprops, Istate> {
    public formGroup: FormGroup | any;
    private subscription: any;
    private onChangeDebounced: (part?: IsuggestedPart) => void;
    constructor(props: Iprops) {
        super(props);
        this.onChangeDebounced = debounce(
            this.props.onChange,
            constants.formDebounceTime
        );
        this.state = {
            fieldConfig: { controls: {} }
        };
    }

    componentDidMount() {
        this.initSelected();
        this.setState({
            fieldConfig: this.buildFieldConfig()
        });
    }

    componentDidUpdate(prevProps: Iprops) {
        if (
            this.props.selectedSuggestedPart.id !==
            prevProps.selectedSuggestedPart.id
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.onChange();
    }

    /*
     * initSelected
     * if there is a query param, make sure it matches what is active in redux
     * if no query param and there is a selectedJob.id, then reset what is in redux
     */
    initSelected = () => {
        if (
            this.props.queryParams.suggestedPartID &&
            this.props.queryParams.suggestedPartID !==
                this.props.selectedSuggestedPart.id
        ) {
            // set the original job to the selectedJob
            this.props.onChange(this.props.originalSuggestedPart);
        } else if (
            this.props.queryParams.suggestedPartID === undefined &&
            this.props.selectedSuggestedPart.id.length
        ) {
            // set the selectedJob to a blank job
            this.props.onChange();
        }
    };

    loadOptions = debounce((searchTerm, callback) => {
        searchPartsForAsyncSelect(searchTerm).then(results =>
            callback(results)
        );
    }, 1000);

    itemToFormValues = (): IgenericFormValues<IsuggestedPart> => {
        const { selectedSuggestedPart } = this.props;
        const {
            partID,
            productQuantity,
            adminComments
        } = selectedSuggestedPart;
        return {
            partID,
            productQuantity,
            adminComments
        };
    };

    formValuesToItem = (): any => {
        const formValues = FormUtil.getValues(this.formGroup.value);
        const { selectedSuggestedPart, selectedProduct } = this.props;

        // const { partID, productQuantity, adminComments } = this.formGroup.value;
        // const suggestedPart = {
        //     id: selectedSuggestedPart.id || uuidv4(),
        //     productID: selectedProduct.id,
        //     partID,
        //     productQuantity: Number(productQuantity),
        //     adminComments,
        //     isDeleted: false
        // };
        return {
            ...selectedSuggestedPart,
            ...formValues,
            productID: selectedProduct.id
        };
    };

    buildFieldConfig = (defaultValues = this.itemToFormValues()) => {
        const { productQuantity, adminComments } = defaultValues;
        const { t, selectedSuggestedPart, selectedProduct } = this.props;

        const partOption = {
            value: selectedSuggestedPart.part.id,
            label: selectedSuggestedPart.part.number
        };

        const fieldConfigControls = {
            $field_0: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormUtil.TextLabelStatic
                            meta={{
                                label: t('productInfo'),
                                colWidth: 12,
                                value: selectedProduct.name
                            }}
                        />
                    </div>
                )
            },
            partID: {
                options: {
                    validators: [Validators.required]
                },
                render: FormUtil.AsyncSelect,
                meta: {
                    label: 'part',
                    colWidth: 12,
                    rows: 2,
                    loadOptions: this.loadOptions,
                    name: 'partID',
                    required: true,
                    isMulti: false
                },
                formState: {
                    value: partOption,
                    disabled: selectedSuggestedPart.id
                }
            },
            productQuantity: {
                options: {
                    validators: [
                        Validators.min(1),
                        Validators.max(1000),
                        Validators.pattern('[0-9]+'),
                        FormUtil.validators.requiredWithTrim
                    ]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'timesUsed',
                    type: 'number',
                    colWidth: 6,
                    required: true,
                    name: 'productQuantity'
                },
                formState: {
                    value: productQuantity,
                    disabled: !this.canManageSuggestedParts()
                }
            },
            adminComments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'adminComments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 5,
                    name: 'adminComments',
                    required: true
                },
                options: {
                    validators: [Validators.maxLength(1000)]
                },
                formState: {
                    value: adminComments,
                    disabled: !this.canManageSuggestedParts()
                }
            },
            serverTimeLabel: {
                render: FormUtil.TextLabel,
                meta: {
                    label: 'serverTimeLabel',
                    colWidth: 12
                }
            }
        };
        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            t
        );
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes after a short delay that allows the initial form values to load
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the values to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                this.onChangeDebounced(this.formValuesToItem());
                break;
        }
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        this.props.saveSuggestedPart([this.formValuesToItem()]);
    };
    handleDelete = () => {
        const toastrConfirmOptions = {
            onOk: () => {
                const suggestedPart = {
                    ...this.props.selectedSuggestedPart,
                    isDeleted: true
                };
                this.props.saveSuggestedPart([suggestedPart]);
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('deleteOk'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deleteConfirm'), toastrConfirmOptions);
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    canManageSuggestedParts = () => {
        return constants.hasSecurityFunction(this.props.user, [
            constants.securityFunctions.ManageSuggestedParts.id
        ]);
    };

    render() {
        const { t } = this.props;

        const deleteButtonStyle =
            this.props.selectedSuggestedPart.id.length === 0
                ? { marginRight: '15px', display: 'none' }
                : { marginRight: '15px' };

        const formClassName = `beacon-form location-form ${this.props.colorButton}`;

        return (
            <div>
                <form onSubmit={this.handleSubmit} className={formClassName}>
                    <FormGenerator
                        onMount={this.setForm}
                        fieldConfig={this.state.fieldConfig}
                    />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.props.toggleModal}
                        >
                            {t('common:cancel')}
                        </Button>
                        <Button
                            bsStyle="warning"
                            style={deleteButtonStyle}
                            type="button"
                            className=""
                            disabled={
                                this.props.loading ||
                                !this.canManageSuggestedParts()
                            }
                            onClick={this.handleDelete}
                        >
                            {t('common:delete')}
                        </Button>
                        <Button
                            bsStyle={this.props.colorButton}
                            type="submit"
                            disabled={
                                this.props.loading ||
                                !this.canManageSuggestedParts()
                            }
                        >
                            {t('common:save')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}
export default ManageSuggestedPartForm;
