import * as React from 'react';

interface LinkProps {
    id: string;
}

export const SkipNavLink = ({ id }: LinkProps) => {
    return (
        <a href={`#${id}`} className="skip-nav-link">
            Skip to content
        </a>
    );
};

interface ContentProps {
    id: string;
    children: React.ReactNode;
}

export const SkipNavContent = ({ id, children }: ContentProps) => {
    return <div id={id}>{children}</div>;
};
