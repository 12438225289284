import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    ImanageMeasurementPointListsReducer,
    ImeasurementPointList,
    Ioption,
    IsmartlinkDataPoint,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import ReactTable, {
    Column,
    FinalState,
    RowInfo,
    SortingRule
} from 'react-table';
import {
    deleteGlobalMeasurementPointList,
    getAllMeasurementPointLists,
    setSelectedMeasurementPointList,
    setTableFilter,
    toggleEditMeasurementPointListModal,
    toggleEditMeasurementPointModal
} from '../../actions/manageMeasurementPointListsActions';
import {
    emptyTile,
    initialMeasurementPointList
} from '../../reducers/initialState';
import { filter, size, values } from 'lodash';

import { Banner } from '../common/Banner';
// import CommonModal from '../common/CommonModal';
import EditMeasurementPointListModal from './EditMeasurementPointListModal';
import { EditMeasurementPointListTabModal } from './EditMeasurementPointListTabModal';
import { EditMeasurementPointListTestProceduresModal } from './EditMeasurementPointListTestProceduresModal';
import EditMeasurementPointModal from './EditMeasurementPointModal';
import { FieldConfig } from 'react-reactive-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormUtil } from '../common/FormUtil';
/*
 * Measurement Point Lists
 */
import { RouteComponentProps } from 'react-router-dom';
import SearchTableForm from '../common/SearchTableForm';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { TableUtil } from '../common/TableUtil';
import { closeAllModals } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { constants, mainCategories } from '../../constants/constants';
import { getProductInfo } from '../../actions/manageInventoryActions';
import { toastr } from 'react-redux-toastr';
import { measurementPointListTypeEnum } from '../../models-enums';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../../constants/apiEndpoints';
import { msalFetch } from '../auth/Auth-Utils';

const uuidv4 = require('uuid/v4');

interface RowInfoMPL extends RowInfo {
    original: ImeasurementPointList;
}

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    loading: boolean;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    user: Iuser;
    getAllMeasurementPointLists: typeof getAllMeasurementPointLists;
    toggleEditMeasurementPointListModal: typeof toggleEditMeasurementPointListModal;
    toggleEditMeasurementPointModal: typeof toggleEditMeasurementPointModal;
    setSelectedMeasurementPointList: typeof setSelectedMeasurementPointList;
    getProductInfo: typeof getProductInfo;
    mainCategoryOptions: Ioption[];
    standardOptions: Ioption[];
    closeAllModals: typeof closeAllModals;
    manageMeasurementPointLists: ImanageMeasurementPointListsReducer;
    showEditMeasurementPointListModal: boolean;
    showEditMeasurementPointModal: boolean;
    showEditProcedureModal: boolean;
    showEditGroupModal: boolean;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    tableData: ImeasurementPointList[];
    deleteGlobalMeasurementPointList: typeof deleteGlobalMeasurementPointList;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
    smartlinkDataPoints: IsmartlinkDataPoint[];
}

class ManageMeasurementPointList extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    public searchFieldConfig: FieldConfig;
    public buttonInAction = false;
    public deleteAction = false;
    static defaultProps = {
        tableData: []
    };
    // private setTableFilterTimeout: any;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.getTrProps = this.getTrProps.bind(this);
        this.state = {
            selectedRow: null,
            currentTile: emptyTile,
            columns: [],
            smartlinkDataPoints: []
        };
        this.searchFieldConfig = {
            controls: {
                type: {
                    render: FormUtil.SelectWithoutValidation,
                    meta: {
                        label: 'manageMeasurementPointLists:type',
                        options: FormUtil.convertEnumToOptions(
                            measurementPointListTypeEnum
                        ),
                        colWidth: 3,
                        type: 'select',
                        placeholder: 'typePlaceholder',
                        defaultValue: this.props.tableFilters.type,
                        isClearable: true,
                        shouldTranslate: true
                    }
                },
                equipmentType: {
                    render: FormUtil.SelectWithoutValidation,
                    meta: {
                        label: 'manageMeasurementPointLists:equipmentType',
                        options: this.props.mainCategoryOptions,
                        colWidth: 3,
                        type: 'select',
                        placeholder: 'equipmentTypePlaceholder',
                        defaultValue: this.props.tableFilters.mainCategory,
                        isClearable: true
                    }
                },
                standard: {
                    render: FormUtil.SelectWithoutValidation,
                    meta: {
                        label: 'manageMeasurementPointLists:standard',
                        options: this.props.standardOptions,
                        colWidth: 3,
                        type: 'select',
                        placeholder: 'standardPlaceholder',
                        defaultValue: this.props.tableFilters.standard,
                        isClearable: true
                    }
                }
            }
        };
    }

    getSmartlinkDataPoints = async () => {
        try {
            const axiosOptions: AxiosRequestConfig = {
                method: 'get',
                params: {}
            };

            const url = API.GET.smartlinkDataPoint.getAll;
            const response: AxiosResponse<any> = await msalFetch(
                url,
                axiosOptions
            );

            if (!response.data) {
                constants.handleError(
                    'Failed to load smartlink data points',
                    ''
                );
                console.error(response);
            } else {
                this.setState({ smartlinkDataPoints: response.data });
            }
        } catch (error) {
            constants.handleError(
                error,
                'Failed to load smartlink data points'
            );
            console.error(error);
        }
    };

    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname),
            columns: this.setColumns()
        });
        // Get measurement point lists
        this.handleGetAllMeasurementPointLists();
        // Get product info when this component mounts
        this.props.getProductInfo();
        this.getSmartlinkDataPoints();
        // TODO: set initial filters from redux
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditMeasurementPointListModal !==
                this.props.showEditMeasurementPointListModal &&
            !this.props.showEditMeasurementPointListModal
        ) {
            this.setState({ selectedRow: null });
        }
        // automatically get measurement point lists every time a filter changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.handleGetAllMeasurementPointLists();
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }

        if (this.props.match.path !== prevProps.match.path) {
            this.handleGetAllMeasurementPointLists();
        }
    }
    componentWillUnmount() {
        this.props.closeAllModals();
    }
    /*
     * Set Columns sets columns to state
     * setting columns here
     */
    setColumns = () => {
        return TableUtil.translateHeaders(
            [
                {
                    id: 'type',
                    Header: 'Type',
                    Cell: (row: any) => {
                        const listType = this.props.t(
                            measurementPointListTypeEnum[row.original.type]
                        );
                        if (listType) {
                            return <span>{listType}</span>;
                        } else {
                            return null;
                        }
                    }
                },
                {
                    id: 'equipmentType',
                    Header: 'equipment type',
                    accessor: ({ mainCategoryID }: ImeasurementPointList) => {
                        const item = this.props.mainCategoryOptions.filter(
                            (opt: Ioption) => {
                                return opt.value === mainCategoryID;
                            }
                        )[0];
                        if (item) {
                            return mainCategoryID ? item.label : '';
                        } else {
                            return null;
                        }
                    }
                },
                {
                    id: 'standard',
                    Header: 'standard',
                    accessor: ({ standardID }: ImeasurementPointList) => {
                        const item = this.props.standardOptions.filter(
                            (opt: Ioption) => {
                                return opt.value === standardID;
                            }
                        )[0];
                        if (item) {
                            return standardID ? item.label : '';
                        } else {
                            return null;
                        }
                    }
                },
                {
                    id: 'numTests',
                    Header: 'Tests',
                    width: 60,
                    accessor: ({
                        measurementPointTabs
                    }: ImeasurementPointList) => {
                        let count = 0;
                        measurementPointTabs.forEach(tab => {
                            if (size(tab.measurementPoints)) {
                                count += size(
                                    filter(
                                        tab.measurementPoints,
                                        mp => mp.isDeleted === false
                                    )
                                );
                            }
                        });
                        return count;
                    }
                },
                {
                    Header: '',
                    id: 'edit',
                    minWidth: 25,
                    Cell: row => (
                        <div>
                            <Button bsStyle="link" style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={['far', 'edit']} />
                            </Button>
                        </div>
                    )
                },
                {
                    Header: '',
                    id: 'delete',
                    minWidth: 25,
                    Cell: row => {
                        const color = this.isAdmin()
                            ? constants.colors.red
                            : constants.colors.greyText;
                        return (
                            <Button
                                bsStyle="link"
                                style={{ float: 'right', color }}
                            >
                                <FontAwesomeIcon icon={['far', 'times']} />
                            </Button>
                        );
                    }
                }
            ],
            this.props.t
        );
    };

    handleEdit(MPList: ImeasurementPointList) {
        if (MPList.mainCategoryID === mainCategories.job) {
            this.props.setSelectedMeasurementPointList({
                ...MPList,
                forJob: true
            });
        } else {
            this.props.setSelectedMeasurementPointList({
                ...MPList,
                forJob: false
            });
        }
        this.props.toggleEditMeasurementPointListModal();
    }

    handleDelete(deletedItem: ImeasurementPointList) {
        const toastrConfirmOptions = {
            onOk: () => {
                deletedItem = {
                    ...deletedItem
                };
                this.props.deleteGlobalMeasurementPointList(deletedItem.id);
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('deleteMPLButton'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deleteConfirm'), toastrConfirmOptions);
    }

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };
    /*
     * (reusable)
     * get the next or previous page of data.  the table is 0 indexed but the API is not
     */
    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };
    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'type':
                this.props.setTableFilter({ type: value, page: 1 });
                break;
            case 'equipmentType':
                this.props.setTableFilter({ mainCategory: value, page: 1 });
                break;
            case 'standard':
                this.props.setTableFilter({ standard: value, page: 1 });
                break;
            default:
                break;
        }
    };
    /*
     * (reusable)
     * set the sorted changes to redux
     */
    onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };

    getTdProps = (
        fState: FinalState,
        rowInfo: RowInfoMPL | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column && column.id && column.id === 'delete') {
            return {
                onClick: () => this.handleDelete(rowInfo.original)
            };
        } else if (rowInfo) {
            return {
                onClick: () => {
                    this.setState({
                        selectedRow: rowInfo.index
                    });
                    this.handleEdit(rowInfo.original);
                }
            };
        } else {
            return {};
        }
    };
    handleNewJobMeasurementList = () => {
        this.props.setSelectedMeasurementPointList({
            ...initialMeasurementPointList,
            id: uuidv4(),
            measurementPointTabs: [],
            temporary: true,
            forJob: true
        });
        this.props.toggleEditMeasurementPointListModal();
    };
    handleNewMeasurementList = () => {
        this.props.setSelectedMeasurementPointList({
            ...initialMeasurementPointList,
            id: uuidv4(),
            measurementPointTabs: [],
            temporary: true,
            forJob: false
        });
        this.props.toggleEditMeasurementPointListModal();
    };
    isAdmin = () =>
        constants.hasSecurityFunction(this.props.user, [
            constants.securityFunctions.ManageAllMeasurementPoints.id
        ]);

    canEditGlobal = () => {
        return constants.hasSecurityFunction(this.props.user, [
            constants.securityFunctions.ManageAllMeasurementPoints.id
        ]);
    };

    // update if this is the customer view and get the measurement point lists
    handleGetAllMeasurementPointLists = () => {
        const isCustomerView =
            this.props.match.path === '/customermeasurements';
        this.props.getAllMeasurementPointLists(isCustomerView);
    };

    render() {
        if (this.props.mainCategoryOptions.length === 0) {
            return (
                <Col xs={12}>
                    <h4> loading... </h4>
                </Col>
            );
        }
        const isCustomerView =
            this.props.match.path === '/customermeasurements';

        const { t } = this.props;
        return (
            <div className="measurement-points-manage">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                >
                    {isCustomerView && (
                        <SelectFacilityContainer
                            t={this.props.t}
                            classNameOverride={''}
                        />
                    )}
                </Banner>
                <SearchTableForm
                    fieldConfig={this.searchFieldConfig}
                    handleSubmit={this.handleGetAllMeasurementPointLists}
                    loading={this.props.loading}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    t={this.props.t}
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                    showSearchButton={false}
                />
                {this.isAdmin() && (
                    <Button
                        className="add-job-data-button"
                        bsStyle="link"
                        onClick={this.handleNewJobMeasurementList}
                    >
                        {t('manageMeasurementPointLists:newJobData')}
                    </Button>
                )}
                {this.isAdmin() && (
                    <Button
                        className="table-add-button"
                        bsStyle="link"
                        onClick={this.handleNewMeasurementList}
                    >
                        {t('manageMeasurementPointLists:newMeasurement')}
                    </Button>
                )}
                <ReactTable
                    data={this.props.tableData}
                    onSortedChange={this.onSortedChanged}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    getTdProps={this.getTdProps}
                    pageSize={
                        this.props.tableData.length >= 10
                            ? this.props.tableData.length
                            : 10
                    }
                    page={this.props.tableFilters.page - 1}
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={this.props.manageMeasurementPointLists.totalPages}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                />
                <EditMeasurementPointListModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    canEditGlobal={this.canEditGlobal()}
                    isCustomerView={isCustomerView}
                />
                <EditMeasurementPointListTabModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    isCustomerView={isCustomerView}
                />
                <EditMeasurementPointListTestProceduresModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    isCustomerView={isCustomerView}
                />
                <EditMeasurementPointModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    canEditGlobal={this.canEditGlobal()}
                    isCustomerView={isCustomerView}
                    smartlinkDataPoints={this.state.smartlinkDataPoints}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        user: state.user,
        manageMeasurementPointLists: state.manageMeasurementPointLists,
        loading: selectIsLoading(state),
        showEditMeasurementPointListModal:
            state.manageMeasurementPointLists.showEditMeasurementPointListModal,
        showEditMeasurementPointModal:
            state.manageMeasurementPointLists.showEditMeasurementPointModal,
        tableData: values(
            state.manageMeasurementPointLists.measurementPointListsByID
        ),
        tableFilters: state.manageMeasurementPointLists.tableFilters,
        standardOptions: state.productInfo.standardOptions,
        mainCategoryOptions: state.productInfo.mainCategoryOptions
    };
};
export default withTranslation('manageMeasurementPointLists')(
    connect(mapStateToProps, {
        getAllMeasurementPointLists,
        toggleEditMeasurementPointListModal,
        toggleEditMeasurementPointModal,
        setSelectedMeasurementPointList,
        closeAllModals,
        setTableFilter,
        getProductInfo,
        deleteGlobalMeasurementPointList
    })(ManageMeasurementPointList)
);
