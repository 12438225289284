/*
 * Training Banner Container
 */

import { RouteComponentProps, withRouter } from 'react-router';
import {
    initialCourse,
    initialLesson,
    initialQuiz
} from '../../reducers/initialState';

import { Banner } from '../common/Banner';
import { connect } from 'react-redux';
import { IinitialState } from '../../reducers';

interface RouterParams {
    courseID: string;
    lessonID: string;
    quizID: string;
}

interface Iprops extends RouteComponentProps<RouterParams> {
    img: any;
    color: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const quiz =
        state.training.quizzesByID[ownProps.match.params.quizID] || initialQuiz;
    const lesson =
        state.training.lessonsByID[ownProps.match.params.lessonID] ||
        initialLesson;
    const selectedCourse =
        state.training.coursesByID[ownProps.match.params.courseID] ||
        initialCourse;
    const title = (): string => {
        if (quiz.name.length) {
            return quiz.name;
        }
        if (lesson.name?.length) {
            return lesson.name;
        }
        if (selectedCourse.name.length) {
            return selectedCourse.name;
        }
        return 'Training';
    };
    return {
        title: title()
    };
};

export const TrainingBannerContainer = withRouter(
    connect(mapStateToProps, {})(Banner)
);
