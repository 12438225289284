import { Ilead, IleadInstallBase } from '../../models';
import ReactTable, { Column, FinalState, RowInfo } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TFunction } from 'i18next';
import { TableUtil } from '../common/TableUtil';
import { constants } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';

interface RowInfoInstallBase extends RowInfo {
    original: IleadInstallBase;
}

interface ExpanderProps extends RowInfo {
    t: TFunction;
    original: Ilead;
    handleDeleteLeadInstallBase: (id: string, t: TFunction) => void;
}

export const LeadInstallBasesExpander: React.SFC<ExpanderProps> = (
    props: ExpanderProps
) => {
    const handleDelete = (deletedItem: IleadInstallBase) => {
        const toastrConfirmOptions = {
            onOk: () => {
                props.handleDeleteLeadInstallBase(deletedItem.id, props.t);
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: props.t('common:delete'),
            cancelText: props.t('common:cancel')
        };
        toastr.confirm(props.t('common:deleteConfirm'), toastrConfirmOptions);
    };

    const getTdProps = (
        state: FinalState,
        rowInfo: RowInfoInstallBase | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column && column.id && column.id === 'delete-button') {
            return {
                onClick: () => {
                    handleDelete(rowInfo.original);
                },
                style: { cursor: 'pointer' }
            };
        } else {
            return {};
        }
    };

    const expanderColumns = TableUtil.translateHeaders(
        [
            {
                minWidth: 20,
                id: 'indent-column-button-bar'
            },
            {
                Header: 'serialNumber',
                accessor: 'installBase.serialNumber',
                minWidth: 100
            },
            {
                Header: 'assetNumber',
                accessor: 'installBase.rfid',
                minWidth: 100
            },
            {
                Header: 'location',
                accessor: 'installBase.fullLocation',
                minWidth: 220
            },
            {
                Header: '',
                id: 'delete-button',
                Cell: ({ original }: { original: Ilead }) => {
                    return (
                        <span
                            style={{ color: constants.colors.red }}
                            title={props.t('Delete Install Base')}
                        >
                            <FontAwesomeIcon icon={['far', 'times']} />
                        </span>
                    );
                },
                minWidth: 20
            }
        ],
        props.t
    );

    return (
        <div>
            <ReactTable
                className={'expander'}
                columns={expanderColumns}
                data={props.original.leadInstallBases}
                sortable={false}
                minRows={0}
                rowsText={props.t('leadInstallBases')}
                noDataText={props.t('noDataText')}
                getTdProps={getTdProps}
                showPageSizeOptions={false}
                resizable={false}
            />
        </div>
    );
};
