import * as React from 'react';

import { Col, DropdownButton, MenuItem } from 'react-bootstrap';

import { TFunction } from 'i18next';
import { filter, forEach, map } from 'lodash';
import { toastr } from 'react-redux-toastr';
import {
    getWorkOrders,
    setSelectedFacilityIDForConfirmSelectJob
} from '../../actions/manageWorkOrderActions';
import { constants } from '../../constants/constants';
import { IWorkOrder, IjobWorkOrder, Iuser } from '../../models';

interface Iprops {
    t: TFunction;
    colorButton: string;
    toggleConfirmSelectJobModal: () => void;
    user: Iuser;
    selection: string[];
    workOrdersByID: { [key: string]: IWorkOrder };
    setSelectedFacilityIDForConfirmSelectJob: typeof setSelectedFacilityIDForConfirmSelectJob;
    unlinkWorkOrders: (selection: IWorkOrder[], t: TFunction) => Promise<void>;
    getWorkOrders: typeof getWorkOrders;
    jobWorkOrdersByID: { [key: string]: IjobWorkOrder };
}

export const WorkOrderBulkActionsButton = (props: Iprops) => {
    const { t } = props;
    const showAddToJob = constants.hasSecurityFunction(props.user, [
        constants.securityFunctions.ManageJobs.id
    ]);

    const checkSelection = () => {
        let hasSameFacility = 1;
        let workOrderAlreadyAssigned = false;

        const selectedWorkOrders = map(props.selection, item => {
            const woID = item.split('select-')[1];
            return props.workOrdersByID[woID];
        });

        selectedWorkOrders.forEach(wo => {
            if (wo.jobWorkOrders && wo.jobWorkOrders?.length > 0) {
                workOrderAlreadyAssigned = true;
            } else {
                // Double check if there are any jobWorkOrders for this work order
                // This can happen when adding new work orders to a job, and the work order doesn't have the newly created jobWorkOrders attached yet, kinda hacky
                const jobWorkOrders = filter(
                    props.jobWorkOrdersByID,
                    jwo => jwo.workOrderID === wo.id && jwo.isDeleted === false
                );
                if (jobWorkOrders && jobWorkOrders.length > 0) {
                    workOrderAlreadyAssigned = true;
                }
            }
        });

        if (workOrderAlreadyAssigned) {
            toastr.warning(
                t('toastMessage:warning'),
                t('toastMessage:addWorkOrderAlreadyAssignedWarning'),
                constants.toastrWarning
            );
            return Promise.reject();
        }

        const firstFacilityID = selectedWorkOrders[0].facility
            ? selectedWorkOrders[0].facility.id
            : '';
        forEach(selectedWorkOrders, wo => {
            if (wo.facility && wo.facility.id !== firstFacilityID) {
                hasSameFacility = 0;
            }
        });
        if (hasSameFacility === 0) {
            toastr.warning(
                t('toastMessage:warning'),
                t('toastMessage:addWorkOrderSameFacilityWarning'),
                constants.toastrWarning
            );
            return Promise.reject();
        } else {
            props.setSelectedFacilityIDForConfirmSelectJob(firstFacilityID);
            return Promise.resolve();
        }
    };

    const unlinkWorkOrders = () => {
        const selectedWorkOrders: IWorkOrder[] = map(props.selection, item => {
            const woID = item.split('select-')[1];
            return {
                ...props.workOrdersByID[woID],
                userID:
                    props.workOrdersByID[woID].userID == ''
                        ? null
                        : props.workOrdersByID[woID].userID
            } as any;
        });

        if (
            selectedWorkOrders.findIndex(
                wo => !wo?.jobWorkOrders || wo.jobWorkOrders.length === 0
            ) !== -1
        ) {
            toastr.warning(
                t('toastMessage:warning'),
                t('manageWorkOrder:unlinkWorkOrdersValidation'),
                constants.toastrWarning
            );
            return;
        }

        const toastrConfirmOptions = {
            onOk: () => {
                props.unlinkWorkOrders(selectedWorkOrders, props.t).then(() => {
                    props.getWorkOrders();
                });
            },
            onCancel: () => console.info('CANCEL clicked'),
            okText: t('Ok'),
            cancelText: t('common:cancel')
        };
        toastr.confirm(
            t('manageWorkOrder:unlinkWorkOrdersConfirmTitle'),
            toastrConfirmOptions
        );
    };

    return (
        <Col
            xs={4}
            lg={2}
            className="table-action-button"
            style={{
                marginTop: '10px',
                marginBottom: '10px',
                marginLeft: '15px'
            }}
        >
            <DropdownButton
                bsStyle={props.colorButton}
                title={t('manageWorkOrder:bulkActions')}
                id="table-action-button"
                onSelect={(eventKey: any) => {
                    switch (eventKey) {
                        case 1:
                            checkSelection()
                                .then(() => {
                                    props.toggleConfirmSelectJobModal();
                                })
                                .catch(() =>
                                    console.info(
                                        'did not select same facilities'
                                    )
                                );
                            break;
                        case 2:
                            unlinkWorkOrders();
                            break;
                    }
                }}
            >
                {showAddToJob && (
                    <MenuItem eventKey={1}>{t('addToJobButton')}</MenuItem>
                )}
                <MenuItem eventKey={2}>{t('unlinkWorkOrders')}</MenuItem>
                {/* {showAddToJob === false &&
          <MenuItem eventKey={1} disabled title={props.t('missingManageJobMessage')}>{t('addToJob')}</MenuItem>
        } */}
            </DropdownButton>
        </Col>
    );
};
