import * as React from 'react';

import { Breadcrumb, BreadcrumbItem, Button, Col, Row } from 'react-bootstrap';
// import { find } from 'lodash';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    Ibuilding,
    Ifacility,
    Ifloor,
    Ilocation,
    ImanageLocationReducer,
    Iroom,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import ReactTable, { FinalState, RowInfo, SortingRule } from 'react-table';
import {
    closeAllModals,
    getFacilitiesByCountry
} from '../../actions/commonActions';
import {
    deleteAnyLocation,
    filterLocations,
    saveAnyLocation,
    setTableFilter,
    toggleEditLocationModal,
    updateAnyLocation
} from '../../actions/manageLocationActions';
import {
    emptyTile,
    initialBuilding,
    initialFacility,
    initialFloor,
    initialLoc
} from '../../reducers/initialState';

import { Banner } from '../common/Banner';
import EditLocationModal from './EditLocationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*
 * hopsital Managers manage their locations
 */
import { RouteComponentProps } from 'react-router-dom';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { TableUtil } from '../common/TableUtil';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { selectVisibleLocations } from '../../reducers/manageLocationReducer';
import { selectSelectedFacilityID } from '../../reducers/facilitiesReducer';

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    showEditLocationModal: boolean;
    loading: boolean;
    userManage: ImanageLocationReducer;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleEditLocationModal: typeof toggleEditLocationModal;
    saveAnyLocation: typeof saveAnyLocation;
    updateAnyLocation: typeof updateAnyLocation;
    deleteAnyLocation: typeof deleteAnyLocation;
    closeAllModals: typeof closeAllModals;
    selectedFacilityID: string;
    user: Iuser;
    tableData: any[];
    selectedFacility: Ifacility;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    selectedBuilding: Ibuilding;
    selectedFloor: Ifloor;
    selectedLocation: Ilocation;
    filterLocations: typeof filterLocations;
    getFacilitiesByCountry: typeof getFacilitiesByCountry;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
    selectedItem: any;
}

class ManageLocation extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    // public searchFieldConfig: any;
    public searchFieldConfigBanner: any;
    public buttonInAction = false;
    public deleteAction = false;
    private setTableFilterTimeout: any;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            columns: [],
            selectedItem: {}
        };
    }

    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.setColumns();

        this.props.filterLocations(this.props.selectedFacilityID);
        this.props.getFacilitiesByCountry({
            name: this.props.selectedFacility.name
        });
    }
    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.filterLocations(this.props.selectedFacilityID);
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }

        // update the table with new or edited locationObjets when the facility changes
        if (
            JSON.stringify(prevProps.selectedFacility) !==
            JSON.stringify(this.props.selectedFacility)
        ) {
            this.props.filterLocations(this.props.selectedFacilityID);
        }

        // if (prevProps.facility.id !== this.props.facility.id) {
        //   this.props.setSelectedFacility(this.props.facility);
        // }
    }

    componentWillUnmount() {
        this.props.closeAllModals();
    }

    handleEdit(selectedItem: any) {
        this.setState({ selectedItem });
        // console.info("EDIT:", item);
    }

    handleDelete(deletedItem: Ilocation | Ibuilding | Ifloor | Iroom) {
        const toastrConfirmOptions = {
            onOk: () => {
                deletedItem = {
                    ...deletedItem,
                    buildingID: this.props.selectedBuilding.id,
                    floorID: this.props.selectedFloor.id,
                    locationID: this.props.selectedLocation.id
                };
                this.props.deleteAnyLocation(
                    deletedItem,
                    this.props.selectedFacilityID
                );
                console.info('deleted', deletedItem);
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('deleteOk'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deleteConfirm'), toastrConfirmOptions);
    }

    /*
     * Reset the table filters (not used currently)
     */
    resetTableFilters = () => {
        this.props.setTableFilter({
            search: '',
            page: 1
        });
    };

    /*
     * Set Columns sets columns to state
     */
    setColumns = () => {
        const columns = TableUtil.translateHeaders(
            [
                {
                    Header: 'name',
                    accessor: 'name',
                    minWidth: 300
                },
                {
                    Header: '',
                    Cell: row => (
                        <div>
                            <Button
                                bsStyle="link"
                                style={{ float: 'right', marginRight: 11 }}
                                onClick={() => {
                                    this.buttonInAction = true;
                                    this.handleEdit(row.original);
                                }}
                            >
                                <FontAwesomeIcon icon={['far', 'edit']} />
                            </Button>
                            <Button
                                bsStyle="link"
                                style={{ float: 'right', color: 'red' }}
                                onClick={() => {
                                    this.buttonInAction = true;
                                    this.deleteAction = true;
                                    this.handleDelete(row.original);
                                }}
                            >
                                <FontAwesomeIcon icon={['far', 'times']} />
                            </Button>
                        </div>
                    )
                }
            ],
            this.props.t
        );
        this.setState({ columns });
    };

    /*
     * Handle user clicking on a location row
     * set the selected location to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        if (rowInfo) {
            return {
                onClick: (evt: React.MouseEvent<HTMLFormElement>) => {
                    const locationObject = rowInfo.original as
                        | Ilocation
                        | Ibuilding
                        | Ifloor
                        | Iroom;
                    if (this.buttonInAction && this.deleteAction === false) {
                        this.props.toggleEditLocationModal();
                    } else if (this.deleteAction === false) {
                        if ('facilityID' in locationObject) {
                            // BUILDING
                            this.props.setTableFilter({
                                buildingID: locationObject.id,
                                locationID: undefined,
                                floorID: undefined
                            });
                        } else if ('buildingID' in locationObject) {
                            // FLOOR
                            this.props.setTableFilter({
                                locationID: undefined,
                                floorID: locationObject.id
                            });
                        } else if ('floorID' in locationObject) {
                            // LOCATION
                            this.props.setTableFilter({
                                locationID: locationObject.id
                            });
                        } else {
                            // ROOM - we don't do anthything when they select a room...
                        }
                    }
                    this.buttonInAction = false;
                    this.deleteAction = false;
                },
                style: {
                    background: this.state.selectedRow[rowInfo.viewIndex]
                        ? constants.colors[
                              `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                          ]
                        : ''
                }
            };
        } else {
            return {};
        }
    };

    // get location type
    getLocationType = () => {
        if (this.props.selectedLocation.id.length) {
            return 'Room';
        } else if (this.props.selectedFloor.id.length) {
            return 'Location';
        } else if (this.props.selectedBuilding.id.length) {
            return 'Floor';
        } else {
            return 'Building';
        }
    };

    handleBCClick = (
        item: any,
        lType: string,
        e: React.MouseEvent<HTMLAnchorElement>
    ) => {
        e.preventDefault();
        switch (lType) {
            case 'Facility':
                this.props.setTableFilter({
                    buildingID: undefined,
                    locationID: undefined,
                    floorID: undefined
                });
                break;
            case 'Building':
                this.props.setTableFilter({
                    buildingID: item.id,
                    locationID: undefined,
                    floorID: undefined
                });
                break;
            case 'Floor':
                this.props.setTableFilter({
                    locationID: undefined,
                    floorID: item.id
                });
                break;
            case 'Location':
                this.props.setTableFilter({ locationID: item.id });
                break;
            default:
                break;
        }
    };

    // Ignoring this issue at the moment because it requires CSS
    makeSandwhich = (label: string, handler?: any) => {
        if (typeof handler !== 'undefined') {
            return (
                <BreadcrumbItem active>
                    <a href="#" onClick={handler}>
                        {label}
                    </a>
                </BreadcrumbItem>
            );
        } else {
            return <BreadcrumbItem active>{label}</BreadcrumbItem>;
        }
    };

    // ^ Ignoring this issue at the moment because it requires CSS

    // get breadcrumb path
    getBreadcrumbs = () => {
        return (
            <Breadcrumb>
                {this.props.tableFilters.buildingID ? (
                    <BreadcrumbItem active>
                        {/* es */}
                        <a
                            href="#"
                            onClick={(
                                evt: React.MouseEvent<HTMLAnchorElement>
                            ) =>
                                this.handleBCClick(
                                    initialBuilding,
                                    'Facility',
                                    evt
                                )
                            }
                        >
                            Buildings
                        </a>
                    </BreadcrumbItem>
                ) : (
                    ''
                )}
                {/* building crumbs */}
                {this.getLocationType() === 'Floor' &&
                this.props.tableFilters.buildingID
                    ? this.makeSandwhich(this.props.selectedBuilding.name)
                    : this.props.tableFilters.buildingID
                    ? this.makeSandwhich(
                          this.props.selectedBuilding.name,
                          (evt: React.MouseEvent<HTMLAnchorElement>) =>
                              this.handleBCClick(
                                  this.props.selectedBuilding,
                                  'Building',
                                  evt
                              )
                      )
                    : ''}
                {/* Floor crumbs */}
                {this.getLocationType() === 'Location' &&
                this.props.selectedFloor.id.length > 0
                    ? this.makeSandwhich(this.props.selectedFloor.name)
                    : this.props.selectedFloor.id
                    ? this.makeSandwhich(
                          this.props.selectedFloor.name,
                          (evt: React.MouseEvent<HTMLAnchorElement>) =>
                              this.handleBCClick(
                                  this.props.selectedFloor,
                                  'Floor',
                                  evt
                              )
                      )
                    : ''}
                {/* Location crumbs */}
                {this.getLocationType() === 'Room' &&
                this.props.selectedLocation.id.length > 0
                    ? this.makeSandwhich(this.props.selectedLocation.name)
                    : this.props.selectedLocation.id
                    ? this.makeSandwhich(
                          this.props.selectedLocation.name,
                          (evt: React.MouseEvent<HTMLAnchorElement>) =>
                              this.handleBCClick(
                                  this.props.selectedLocation,
                                  'Location',
                                  evt
                              )
                      )
                    : ''}
            </Breadcrumb>
        );
    };

    // get the next or previous page of data.  the table is 0 indexed but the API is not
    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'search':
                clearTimeout(this.setTableFilterTimeout);
                this.setTableFilterTimeout = setTimeout(() => {
                    this.props.setTableFilter({ search: value, page: 1 }); // this causes performance issues so we use a rudamentary debounce
                }, constants.tableSearchDebounceTime);
                break;
            default:
                break;
        }
    };
    onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };

    render() {
        console.info('rendering locations table');
        const { t } = this.props;

        return (
            <div className="manage-location">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                >
                    <SelectFacilityContainer
                        t={this.props.t}
                        classNameOverride={''}
                    />
                </Banner>
                <Row>
                    <Col md={10}>{this.getBreadcrumbs()}</Col>
                    <Col md={2}>
                        <Button
                            bsStyle="link"
                            style={{ marginTop: '8px' }}
                            onClick={() => {
                                this.setState({ selectedItem: {} }, () => {
                                    this.props.toggleEditLocationModal();
                                });
                            }}
                        >
                            {t(`manageLocation:new${this.getLocationType()}`)}
                        </Button>
                    </Col>
                </Row>
                <ReactTable
                    data={this.props.tableData}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    // pageSize={this.props.tableData.length}
                    // manual
                    // pages={this.props.userManage.totalPages}
                    // page={this.props.tableFilters.page - 1}
                    // showPageSizeOptions={false}
                    defaultPageSize={10}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    // onPageChange={this.onPageChange}
                    // onSortedChange={this.onSortedChanged}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    expanded={this.state.selectedRow}
                />
                <EditLocationModal
                    selectedItem={this.state.selectedItem}
                    selectedType={this.getLocationType()}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const selectedFacilityID = selectSelectedFacilityID(state);
    const selectedFacility =
        state.facilities[selectedFacilityID] &&
        state.facilities[selectedFacilityID].buildings
            ? state.facilities[selectedFacilityID]
            : initialFacility;

    const selectedBuilding =
        selectedFacility.buildings.find(
            building =>
                building.id === state.manageLocation.tableFilters.buildingID
        ) || initialBuilding;
    const selectedFloor =
        selectedBuilding.floors.find(
            floor => floor.id === state.manageLocation.tableFilters.floorID
        ) || initialFloor;
    const selectedLocation =
        selectedFloor.locations.find(
            location =>
                location.id === state.manageLocation.tableFilters.locationID
        ) || initialLoc;
    return {
        selectedFacilityID,
        user: state.user,
        userManage: state.manageLocation,
        loading: selectIsLoading(state),
        showEditLocationModal: state.manageLocation.showEditLocationModal,
        tableData: selectVisibleLocations(state),
        selectedFacility,
        tableFilters: state.manageLocation.tableFilters,
        selectedBuilding,
        selectedFloor,
        selectedLocation
    };
};
export default withTranslation('manageLocation')(
    connect(mapStateToProps, {
        saveAnyLocation,
        updateAnyLocation,
        deleteAnyLocation,
        toggleEditLocationModal,
        closeAllModals,
        setTableFilter,
        filterLocations,
        getFacilitiesByCountry
    })(ManageLocation)
);
