/*
 * Manage Install Modal - Container
 */

import { Iproduct } from '../../models';
import {
    deleteInstall,
    saveInstall,
    toggleEditInstallModal,
    updateInstall,
    setInstallFormValues,
    updateInstallFormValues
} from '../../actions/manageInventoryActions';
import {
    initialFacility,
    initialInstallBase,
    initialProduct
} from '../../reducers/initialState';

import { WithCommonModal } from '../common/WithCommonModal';
import EditInstallForm from './EditInstallForm';
import { connect } from 'react-redux';
import { getCanEditInstalls } from '../../reducers/userReducer';
import { saveAnyLocation } from '../../actions/manageLocationActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
    selectedProduct: Iproduct;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const {
        selectedFacilityID,
        productInfo,
        manageJob: { selectedJob }
    } = state;
    const {
        showSearchNewProductsModal,
        tableFilters,
        installBasesByID,
        selectedInstallBaseID,
        showEditInstallModal
    } = state.manageInventory;

    const facility = state.facilities[selectedFacilityID] || initialFacility;
    const selectedProduct = ownProps.selectedProduct || initialProduct;

    const { subcategoryID } = selectedProduct;
    const mainCategoryID = (productInfo.subcategories[subcategoryID] || {})
        .mainCategoryID;

    const selectedItem =
        installBasesByID[selectedInstallBaseID] || initialInstallBase;

    const canEditInstalls = getCanEditInstalls(state.user);
    const modalTitle = selectedItem.id
        ? ownProps.t('manageInventory:editInstallModalTitle')
        : ownProps.t('manageInventory:saveInstallModalTitle');
    return {
        user: state.user,
        loading: selectIsLoading(state),
        facility,
        show: showEditInstallModal,
        productInfo: state.productInfo,
        tableFilters,
        secondWideModal: showSearchNewProductsModal,
        selectedProduct,
        mainCategoryID,
        selectedItem,
        canEditInstalls,
        formValues: state.manageInventory.installFormValues,
        className: 'install-edit',
        title: modalTitle,
        selectedJob
    };
};

export default withTranslation('manageInventory')(
    connect(mapStateToProps, {
        updateInstall,
        saveInstall,
        toggleModal: toggleEditInstallModal,
        onHide: toggleEditInstallModal,
        onCancel: toggleEditInstallModal,
        deleteInstall,
        saveAnyLocation,
        setFormValues: setInstallFormValues,
        updateFormValues: updateInstallFormValues
    })(WithCommonModal(EditInstallForm))
);
