/*
 * An Action Button
 */

import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import {
    getWorkOrders,
    setSelectedFacilityIDForConfirmSelectJob,
    toggleConfirmSelectJobModal,
    unlinkWorkOrders
} from '../../actions/manageWorkOrderActions';
import { IWorkOrder } from '../../models';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { selectVisibleWorkOrdersByID } from '../../reducers/manageWorkOrderReducer';
import { WorkOrderBulkActionsButton } from './WorkOrderBulkActionsButton';

interface Iprops {
    t: TFunction;
    colorButton: string;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const tableData = selectVisibleWorkOrdersByID(state);
    const workOrdersByID: { [key: string]: IWorkOrder } =
        state.manageWorkOrder.workOrdersByID;
    tableData.forEach(wo => {
        workOrdersByID[wo.id] = {
            ...workOrdersByID[wo.id],
            jobWorkOrders: wo?.jobWorkOrders
        };
    });
    const jobWorkOrdersByID = state.manageJob.jobWorkOrdersByID;

    return {
        user: state.user,
        selection: state.manageWorkOrder.selection,
        workOrdersByID: state.manageWorkOrder.workOrdersByID,
        loading: selectIsLoading(state),
        jobWorkOrdersByID
    };
};

export const WorkOrderBulkActionsButtonContainer = connect(mapStateToProps, {
    toggleConfirmSelectJobModal,
    setSelectedFacilityIDForConfirmSelectJob,
    unlinkWorkOrders,
    getWorkOrders
})(WorkOrderBulkActionsButton);
