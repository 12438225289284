import * as React from 'react';

import { Col, DropdownButton, MenuItem } from 'react-bootstrap';
import {
    getFacilityMeasurementPointResults,
    getMeasurementPointResultsHistoryByInstallBase
} from '../../actions/measurementPointResultsActions';
import {
    setSelectedInstallBaseID,
    setSelectedProductID,
    toggleInstallContactModal,
    toggleMPResultHistory,
    toggleMPResultModal
} from '../../actions/manageInventoryActions';

import { IinstallBase } from '../../models';
import { TFunction } from 'i18next';
import { measurementPointResultStatusTypesEnum } from '../../models-enums';
import {
    toggleEditWorkOrderModal,
    setSelectedWorkOrderID,
    getWorkOrdersByFacility
} from '../../actions/manageWorkOrderActions';
import { getJobsByFacility } from '../../actions/manageJobActions';
import { LinkContainer } from 'react-router-bootstrap';
import { clearTableFilters } from '../../actions/manageDocumentActions';
interface Iprops {
    t: TFunction;
    selectedInstallBase: IinstallBase;
    setSelectedProductID: typeof setSelectedProductID;
    setSelectedInstallBaseID: typeof setSelectedInstallBaseID;
    toggleEditWorkOrderModal: typeof toggleEditWorkOrderModal;
    toggleInstallContactModal: typeof toggleInstallContactModal;
    toggleMPResultModal: typeof toggleMPResultModal;
    toggleMPResultHistory: typeof toggleMPResultHistory;
    colorButton: string;
    getFacilityMeasurementPointResults: typeof getFacilityMeasurementPointResults;
    getMeasurementPointResultsHistoryByInstallBase: typeof getMeasurementPointResultsHistoryByInstallBase;
    toggleWorkOrderCloseModal: () => any;
    setSelectedWorkOrderID: typeof setSelectedWorkOrderID;
    getWorkOrdersByFacility: typeof getWorkOrdersByFacility;
    getJobsByFacility: typeof getJobsByFacility;
    openSearchFacilityModal: (ids: string[]) => void;
    showMoveFacility: boolean;
    forMobile: boolean;
    clearTableFilters: typeof clearTableFilters;
}

export const InstallBaseActionButton = (props: Iprops) => {
    const { t, selectedInstallBase } = props;
    const notTested =
        selectedInstallBase.measurementPointListResultStatus ===
        measurementPointResultStatusTypesEnum.resultStatusNotTested;

    // Mobile!
    if (props.forMobile) {
        return (
            <Col
                xs={4}
                lg={2}
                className="install-base-action-button"
                style={{}}
            >
                <DropdownButton
                    pullRight
                    bsStyle={props.colorButton}
                    bsSize="small"
                    title={t('manageInventory:actions')}
                    id="inventory-action-button"
                    onSelect={(eventKey: any) => {
                        switch (eventKey) {
                            case 1: // history
                                /*
                                 *   1/11/21 - this used to get all MPR for a facility,
                                 *   now only gets them for a specific InstallBase.
                                 *   This was also getting all jobs for facility, removed that, should already have jobs
                                 *   A good TODO is to only get 1 WorkOrder by ID instead of all for Facility
                                 */
                                props.getWorkOrdersByFacility();
                                props.getMeasurementPointResultsHistoryByInstallBase(
                                    selectedInstallBase.id
                                );

                                props.setSelectedProductID(
                                    selectedInstallBase.productID
                                );
                                props.setSelectedInstallBaseID(
                                    selectedInstallBase.id
                                );

                                props.toggleMPResultHistory();
                                break;
                            case 2: // last inspection
                                props.setSelectedProductID(
                                    selectedInstallBase.productID
                                );
                                props.setSelectedInstallBaseID(
                                    selectedInstallBase.id
                                );
                                props.toggleMPResultModal();
                                break;
                            case 5: // contact support
                                props.setSelectedProductID(
                                    selectedInstallBase.productID
                                );
                                props.setSelectedInstallBaseID(
                                    selectedInstallBase.id
                                );
                                props.toggleInstallContactModal();
                                break;
                            case 6:
                                props.clearTableFilters();
                                break;
                            default:
                                break;
                        }
                    }}
                >
                    <MenuItem eventKey={1}>
                        {t('dashboardAnalytics:inspectionHistory')}
                    </MenuItem>
                    <MenuItem eventKey={2} disabled={notTested}>
                        {t('dashboardAnalytics:lastInspection')}
                    </MenuItem>
                    <MenuItem eventKey={5}>
                        {t('dashboardAnalytics:contactSupport')}
                    </MenuItem>
                    <LinkContainer
                        to={`/documents?installbaseId=${selectedInstallBase.id}`}
                    >
                        <MenuItem eventKey={6}>
                            {t('dashboardAnalytics:viewInReports')}
                        </MenuItem>
                    </LinkContainer>
                </DropdownButton>
            </Col>
        );
    }

    // Desktop!
    return (
        <Col xs={4} lg={2} className="install-base-action-button" style={{}}>
            <DropdownButton
                pullRight
                bsStyle={props.colorButton}
                bsSize="small"
                title={t('manageInventory:actions')}
                id="inventory-action-button"
                onSelect={(eventKey: any) => {
                    switch (eventKey) {
                        case 1: // history
                            /*
                             *   1/11/21 - this used to get all MPR for a facility,
                             *   now only gets them for a specific InstallBase.
                             *   This was also getting all jobs for facility, removed that, should already have jobs
                             *   A good TODO is to only get 1 WorkOrder by ID instead of all for Facility
                             */
                            props.getWorkOrdersByFacility();
                            props.getMeasurementPointResultsHistoryByInstallBase(
                                selectedInstallBase.id
                            );

                            props.setSelectedProductID(
                                selectedInstallBase.productID
                            );
                            props.setSelectedInstallBaseID(
                                selectedInstallBase.id
                            );

                            props.toggleMPResultHistory();
                            break;
                        case 2: // last inspection
                            props.setSelectedProductID(
                                selectedInstallBase.productID
                            );
                            props.setSelectedInstallBaseID(
                                selectedInstallBase.id
                            );
                            props.toggleMPResultModal();
                            break;
                        // case 3: // repair (work order close)
                        //   props.setSelectedProductID(selectedInstallBase.productID);
                        //   props.setSelectedInstallBaseID(selectedInstallBase.id);
                        //   props.toggleWorkOrderCloseModal();
                        //   break;
                        case 4: // add work order
                            props.setSelectedWorkOrderID('');
                            props.setSelectedProductID(
                                selectedInstallBase.productID
                            );
                            props.setSelectedInstallBaseID(
                                selectedInstallBase.id
                            );
                            props.toggleEditWorkOrderModal();
                            break;
                        case 5: // contact support
                            props.setSelectedProductID(
                                selectedInstallBase.productID
                            );
                            props.setSelectedInstallBaseID(
                                selectedInstallBase.id
                            );
                            props.toggleInstallContactModal();
                            break;
                        case 6: //Change Facility
                            props.openSearchFacilityModal([
                                selectedInstallBase.id
                            ]);
                            break;
                        case 7:
                            props.clearTableFilters();
                            break;
                        default:
                            break;
                    }
                }}
            >
                <MenuItem eventKey={1}>
                    {t('dashboardAnalytics:inspectionHistory')}
                </MenuItem>
                <MenuItem eventKey={2} disabled={notTested}>
                    {t('dashboardAnalytics:lastInspection')}
                </MenuItem>
                {/* <MenuItem eventKey={3}>{t('dashboardAnalytics:repairAsset')}</MenuItem> */}
                <MenuItem eventKey={4}>
                    {t('dashboardAnalytics:addWorkOrder')}
                </MenuItem>
                <MenuItem eventKey={5}>
                    {t('dashboardAnalytics:contactSupport')}
                </MenuItem>
                <LinkContainer
                    to={`/documents?installbaseId=${selectedInstallBase.id}`}
                >
                    <MenuItem eventKey={7}>
                        {t('dashboardAnalytics:viewInReports')}
                    </MenuItem>
                </LinkContainer>
                {props.showMoveFacility && (
                    <MenuItem eventKey={6}>
                        {t('dashboardAnalytics:changeFacility')}
                    </MenuItem>
                )}
            </DropdownButton>
        </Col>
    );
};
