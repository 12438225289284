/*
 * Edit Document Modal
 * responsible for editing a facility
 * this modal is rendered inside the UserQueueForm component because we pass the selectedCustomer
 */

import { connect } from 'react-redux';

import EditDocumentLibraryForm from '../forms/EditDocumentLibraryForm';
import { IcloudDocument } from '../../../models';
import {
    toggleEditDocumentModal,
    updateDocumentFormValue,
    setDocumentFormValues,
    clearSelectedDocumentID
} from '../../../actions/manageDocumentActions';
import { setSelectedDocumentTypeID } from '../../../actions/manageDocumentTypeActions';
import { FormUtil } from '../../common/FormUtil';
import { constants } from '../../../constants/constants';
import { filter } from 'lodash';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../../reducers/commonReducers';
import { IinitialState } from '../../../reducers';
import { getIsMobile } from '../../../reducers/configReducer';
import { bindActionCreators } from 'redux';

interface Iprops {
    colorButton: string;
    selectedDocument: IcloudDocument;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const parentId = state.manageDocument.selectedParentID;
    const parentName = parentId
        ? state.manageDocument.documentsByID[parentId]
            ? state.manageDocument.documentsByID[parentId].name
            : ''
        : '';
    const canEditDocuments = constants.hasSecurityFunction(state.user, [
        constants.securityFunctions.ManageDocumentLibrary.id
    ]);
    const canAddDocuments = constants.hasSecurityFunction(state.user, [
        constants.securityFunctions.ManageGeneralDocuments.id
    ]);
    const documentTypeOptions = FormUtil.convertToOptions(
        filter(state.manageDocumentType.documentTypesByID, type => {
            return true;
        })
    );
    let title;
    let isVersionForm = false;
    let isNewForm = false;
    let isEditForm = false;
    let documentID;
    if (ownProps.selectedDocument && ownProps.selectedDocument.name) {
        title = ownProps.t('manageDocument:editDocument');
        isEditForm = true;
    } else if (parentId) {
        isVersionForm = true;
        title = ownProps.t(`manageDocument:newVersion`);
        documentID = state.manageDocument.documentsByID[parentId]?.documentID;
    } else {
        isNewForm = true;
        title = ownProps.t(`manageDocument:newDocument`);
    }
    return {
        isEditForm,
        isNewForm,
        isVersionForm,
        documentID,
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageDocument.showEditDocumentModal,
        formValues: state.manageDocument.documentFormValues,
        parentId,
        parentName,
        documentTypeOptions,
        selectedFacilityID: state.selectedFacilityID,
        canEditDocuments,
        canAddDocuments,
        title,
        className: 'edit-document',
        isMobile: getIsMobile(state)
    };
};

const mapDispatchToProps = (
    dispatch: any,
    ownProps: Iprops & WithTranslation
) => {
    const boundActions = bindActionCreators(
        {
            toggleModal: toggleEditDocumentModal,
            updateFormValue: updateDocumentFormValue,
            setFormValues: setDocumentFormValues,
            clearSelectedDocumentID,
            onHide: toggleEditDocumentModal,
            handleCancel: toggleEditDocumentModal,
            setSelectedDocumentTypeID
        },
        dispatch
    );
    return {
        ...boundActions,
        dispatch
    };
};

export default withTranslation('manageDocument')(
    connect(mapStateToProps, mapDispatchToProps)(EditDocumentLibraryForm)
);
