import { RouteComponentProps, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Item } from './SideMenu.tsx';
import { Itile } from '../../models';
import { ListGroupItem } from 'react-bootstrap';
import React from 'react';
import { TFunction } from 'i18next';
import { map, orderBy } from 'lodash';
import { svgIcons } from '../../constants/svgIcons';

interface ItemClassProps extends RouteComponentProps {
    tiles: Itile[];
    t: TFunction;
    url: string;
    title: string;
    svgKey?: string;
    collapsed?: boolean;
}

class ParentItem extends React.Component<ItemClassProps, { isOpen: boolean }> {
    constructor(props: ItemClassProps) {
        super(props);
        if (!props.collapsed) {
            this.state = { isOpen: false };
        } else if (
            this.props.tiles.find(
                tile => tile.url === this.props.location.pathname
            )
        ) {
            this.state = { isOpen: true };
        } else {
            this.state = { isOpen: false };
        }
    }

    componentDidUpdate(prevProps: ItemClassProps) {
        const active = this.props.tiles.find(
            tile => tile.url === this.props.location.pathname
        );
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (active) {
                this.setState({ isOpen: true });
            } else {
                this.setState({ isOpen: false });
            }
        }
        if (this.props.collapsed !== prevProps.collapsed) {
            this.setState({
                isOpen: this.props.collapsed
                    ? Boolean(active)
                    : Boolean(this.props.collapsed)
            });
        }
    }

    handleClick = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    isParentOfSelectedChild = (childTiles: Itile[]): boolean => {
        let isParentOfSelectedChild = false;
        childTiles.forEach(childTile => {
            if (childTile.url === this.props.location.pathname) {
                isParentOfSelectedChild = true;
            }
        });
        return isParentOfSelectedChild;
    };
    render() {
        const { title, svgKey, t, tiles } = this.props;
        const childTiles = orderBy(tiles, ['order', 'title'], ['asc', 'asc']);

        let iconStyle = {
            transform: 'rotate(0)',
            fontSize: '15px',
            color: 'white'
        };
        if (this.state.isOpen) {
            iconStyle = {
                ...iconStyle,
                transform: 'rotate(90deg)'
                // color: constants.colors.darkerBlue
            };
        }
        let parentItemStyle = { height: '40px' };
        const listGroupStyle = {};
        let parentItemClassName = 'parent-item-container';
        if (this.state.isOpen) {
            parentItemStyle = { height: `${tiles.length * 40 + 40}px` };
            // listGroupStyle = { backgroundColor: constants.colors.teal };
            parentItemClassName += ' open';
        }
        if (!this.state.isOpen) {
            if (this.isParentOfSelectedChild(childTiles)) {
                parentItemClassName += ' open';
            }
        }
        return (
            <div
                className={parentItemClassName}
                style={parentItemStyle}
                title={t(title)}
            >
                <ListGroupItem
                    className="kerning parent-item"
                    onClick={this.handleClick}
                    style={listGroupStyle}
                >
                    <span className="menu-item-icon">
                        {svgKey !== undefined &&
                            svgIcons[svgKey as keyof typeof svgIcons]}
                    </span>
                    <span className="menu-text">{t(title)}</span>
                    <FontAwesomeIcon
                        icon="caret-right"
                        style={iconStyle}
                        className="beacon-expander-toggle icon-right"
                    />
                </ListGroupItem>
                {map(childTiles, cTile => {
                    return (
                        <Item
                            key={cTile.title}
                            isChild={true}
                            {...cTile}
                            t={t}
                        />
                    );
                })}
            </div>
        );
    }
}

export default withRouter(ParentItem);
