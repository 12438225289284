import React from 'react';
import CommonModal from '../common/CommonModal';
import { useTranslation } from 'react-i18next';
import { FormUtil } from '../common/FormUtil';
import moment from 'moment';
import { Col, Button } from 'react-bootstrap';

interface Iprops {
    show: boolean;
    onHide: () => void;
    userData: any;
}

const TrainingDetailModal = (props: Iprops) => {
    const { show, onHide, userData } = props;

    const { t } = useTranslation();

    return (
        <CommonModal
            show={show}
            title={t('manageTraining:courseDetail')}
            onHide={onHide}
            className=""
        >
            {userData && (
                <Col>
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('manageTraining:userName'),
                            colWidth: 12,
                            value: userData.userName
                        }}
                    />
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('manageTraining:courseName'),
                            colWidth: 12,
                            value: userData.courseName
                        }}
                    />
                    {userData.certificateUrl ? (
                        <>
                            <FormUtil.TextLabelStatic
                                meta={{
                                    label: t(
                                        'manageTraining:credentialIssuedOn'
                                    ),
                                    colWidth: 6,
                                    value: moment(
                                        userData.credentialIssueDate,
                                        'YYYY-MM-DD'
                                    ).format('DD-MMM-YY')
                                }}
                            />
                            <FormUtil.TextLabelStatic
                                meta={{
                                    label: t(
                                        'manageTraining:credentialValidUntil'
                                    ),
                                    colWidth: 6,
                                    value: moment(
                                        userData.credentialValidUntil,
                                        'YYYY-MM-DD'
                                    ).format('DD-MMM-YY')
                                }}
                            />
                            <FormUtil.TextLabelStatic
                                meta={{
                                    label: t('manageTraining:result'),
                                    colWidth: 12,
                                    value: `${userData.score}%`
                                }}
                            />
                            <Col xs={12}>
                                <a
                                    href={userData.certificateUrl}
                                    target="_blank"
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {t('manageTraining:viewCredential')}
                                </a>
                            </Col>
                        </>
                    ) : (
                        <>
                            <FormUtil.TextLabelStatic
                                meta={{
                                    label: t('manageTraining:progress'),
                                    colwidth: 12,
                                    value: userData.progress
                                }}
                            />
                        </>
                    )}
                </Col>
            )}
            <Col xs={12} className="form-buttons text-right">
                <Button
                    bsStyle="default"
                    type="button"
                    className="pull-left"
                    onClick={onHide}
                >
                    {t('manageTraining:close')}
                </Button>
            </Col>
        </CommonModal>
    );
};

export default TrainingDetailModal;
