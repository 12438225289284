import * as React from 'react';
import * as moment from 'moment';

import { withTranslation, WithTranslation } from 'react-i18next';
import { IAlert, ItableFiltersReducer, Itile } from '../../models';
import ReactTable, { Column, FinalState, RowInfo } from 'react-table';
import {
    clearSelectedAlertID,
    deleteAlert,
    getAlerts,
    setSelectedAlertID,
    setTableFilter,
    toggleEditAlertModal
} from '../../actions/manageAlertActions';

import { Banner } from '../common/Banner';
import { Button } from 'react-bootstrap';
import EditAlertModal from './EditAlertModal';
import { FieldConfig } from 'react-reactive-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { emptyTile } from '../../reducers/initialState';
import { getProductInfo } from '../../actions/manageInventoryActions';
import { orderBy } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { closeAllModals } from '../../actions/commonActions';
import { IinitialState } from '../../reducers';

interface RowInfoAlert extends RowInfo {
    original: IAlert;
}

type Iprops = RouteComponentProps<any>;

interface IdispatchProps {
    tableData: IAlert[];
    totalPages: number;
    showEditAlertModal: boolean;
    getAlerts: typeof getAlerts;
    toggleEditAlertModal: typeof toggleEditAlertModal;
    deleteAlert: typeof deleteAlert;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    loading: boolean;
    setSelectedAlertID: typeof setSelectedAlertID;
    clearSelectedAlertID: typeof clearSelectedAlertID;
    getProductInfo: typeof getProductInfo;
    closeAllModals: typeof closeAllModals;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
    searchFieldConfig: FieldConfig;
}

class ManageAlert extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    private debounce: any;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            columns: [],
            searchFieldConfig: this.buildSearchControls()
        };
    }

    componentDidMount(): void {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.setColumns();
        this.props.getAlerts();
        this.props.getProductInfo();
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditAlertModal !== this.props.showEditAlertModal &&
            !this.props.showEditAlertModal
        ) {
            this.setState({ selectedRow: null });
        }
        // automatically get inventory every time a fitler changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.getAlerts();
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
    }
    componentWillUnmount() {
        this.props.closeAllModals();
    }

    buildSearchControls = (): FieldConfig => {
        const mainSearchControls = {
            name: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:Alert',
                    colWidth: 3,
                    type: 'text',
                    placeholder: 'Search by title',
                    defaultValue: this.props.tableFilters.title,
                    isClearable: true
                }
            },
            type: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: constants.alertTypes,
                    label: 'manageAlert:alertTypeLabel',
                    colWidth: 3,
                    placeholder: 'Select type',
                    defaultValue: this.props.tableFilters.type,
                    isClearable: true
                }
            }
        };

        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    };

    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'name':
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.props.setTableFilter({ title: value, page: 1 });
                }, 500);
                break;
            case 'type':
                this.props.setTableFilter({
                    type: value ? value.value : null,
                    page: 1
                });
                break;
            default:
                break;
        }
    };

    handleEdit(row: any) {
        this.setState({ selectedRow: row.index });
        this.props.toggleEditAlertModal();
        this.props.setSelectedAlertID(row.original.id);
    }

    handleDelete(deletedItem: any) {
        const toastrConfirmOptions = {
            onOk: () => {
                deletedItem = {
                    ...deletedItem
                };
                this.props.deleteAlert(deletedItem);
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('deleteOk'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deleteConfirm'), toastrConfirmOptions);
    }

    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };
    /*
     * Set Columns sets columns to state
     */
    setColumns = () => {
        const columns = TableUtil.translateHeaders(
            [
                {
                    Header: 'title',
                    accessor: 'title',
                    minWidth: 300
                },
                {
                    Header: 'type',
                    accessor: 'type'
                },
                {
                    Header: '',
                    id: 'delete',
                    minWidth: 25,
                    Cell: row => {
                        return (
                            <Button
                                bsStyle="link"
                                style={{
                                    float: 'right',
                                    color: constants.colors.red
                                }}
                            >
                                <FontAwesomeIcon icon={['far', 'times']} />
                            </Button>
                        );
                    }
                }
            ],
            this.props.t
        );
        this.setState({ columns });
    };

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };

    getTdProps = (
        fState: FinalState,
        rowInfo: RowInfoAlert | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column && column.id && column.id === 'delete') {
            return {
                onClick: () => this.handleDelete(rowInfo.original)
            };
        } else if (rowInfo) {
            return {
                onClick: () => {
                    this.setState({
                        selectedRow: rowInfo.index
                    });
                    this.handleEdit(rowInfo);
                }
            };
        } else {
            return {};
        }
    };

    render() {
        const { t, tableData = [], totalPages } = this.props;
        return (
            <div className="manage-alert">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                <SearchTableForm
                    fieldConfig={this.state.searchFieldConfig}
                    handleSubmit={this.props.getAlerts}
                    loading={this.props.loading}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                    t={this.props.t}
                    showSearchButton={false}
                />
                <div>
                    <Button
                        className="table-add-button"
                        bsStyle="link"
                        onClick={() => {
                            this.setState({ selectedRow: null }, () => {
                                this.props.toggleEditAlertModal();
                            });
                        }}
                    >
                        {t(`manageAlert:newAlert`)}
                    </Button>
                </div>
                <ReactTable
                    data={tableData}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    getTdProps={this.getTdProps}
                    pageSize={tableData.length >= 10 ? tableData.length : 10}
                    manual
                    pages={totalPages}
                    page={this.props.tableFilters.page - 1}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    // onSortedChange={this.onSortedChanged}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                />

                <EditAlertModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState) => {
    const tableData = orderBy(
        state.manageAlert.alertsByID,
        res => moment.utc(res.createDate).unix(),
        'desc'
    );
    return {
        tableData,
        totalPages: state.manageAlert.totalPages,
        showEditAlertModal: state.manageAlert.showEditAlertModal,
        tableFilters: state.manageAlert.tableFilters
    };
};

export default withTranslation('manageAlert')(
    connect(mapStateToProps, {
        getAlerts,
        toggleEditAlertModal,
        deleteAlert,
        setTableFilter,
        setSelectedAlertID,
        clearSelectedAlertID,
        getProductInfo,
        closeAllModals
    })(ManageAlert)
);
