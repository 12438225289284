/*
 * Manage Brand Form
 */

import { Col, Button, FormGroup } from 'react-bootstrap';
import {
    FormGenerator,
    AbstractControl,
    FieldConfig,
    Validators
} from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';
import { WithTranslation } from 'react-i18next';

import * as React from 'react';

import { FormUtil } from '../common/FormUtil';
import { Ibrand, Ioption, ItableFiltersReducer } from '../../models';
import { constants } from '../../constants/constants';
import {
    clearSelectedBrandID,
    saveBrand,
    updateBrand,
    updateSelectedBrand
} from '../../actions/manageBrandActions';
import { manageBrandQueryParamsEnum } from './ManageBrand';
import { IgenericFormValues } from '../../modelsForms';

interface Istate {
    fieldConfig: FieldConfig;
    fileName: string;
    file: string;
}

interface Iprops {
    closeModal: () => void;
    loading: boolean;
    colorButton: string;
    tableFilters: ItableFiltersReducer;
    saveBrand: typeof saveBrand;
    updateBrand: typeof updateBrand;
    clearSelectedID: typeof clearSelectedBrandID;
    queryParams: typeof manageBrandQueryParamsEnum;
    onChange: typeof updateSelectedBrand;
    selectedBrand: Ibrand;
    originalBrand?: Ibrand;
    isEditMode: boolean;
}

class ManageBrandForm extends React.Component<
    Iprops & WithTranslation,
    Istate
> {
    public form: FormGroup | any;
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.state = {
            fileName: '',
            file: '',
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        this.initSelected();
    }

    componentDidUpdate(prevProps: Iprops & WithTranslation) {
        if (this.props.selectedBrand !== prevProps.selectedBrand) {
            this.setState({
                fieldConfig: this.buildFieldConfig()
            });
        }

        if (this.props.originalBrand !== prevProps.originalBrand) {
            this.setState({
                fieldConfig: this.buildFieldConfig(this.props.originalBrand)
            });
        }
    }

    componentWillUnmount() {
        this.props.clearSelectedID();
    }

    buildFieldConfig = (value = this.itemToFormValues()) => {
        const { t } = this.props;
        const disabled = false;
        const imageUrl = value.logo || value.logoPath || this.state?.file || '';
        const countryID = value.countryID as string | undefined;
        const country = countryID ? constants.countries[countryID] : undefined;

        let countryValue = { value: '', label: '' };
        if (country != undefined) {
            countryValue = {
                value: countryID,
                label: country.label
            } as Ioption;
        }

        const controls = {
            name: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: t('manageBrandForm.name'),
                    colWidth: 12,
                    name: 'brand:name'
                },
                formState: {
                    value: value.name,
                    disabled
                }
            },
            url: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: t('manageBrandForm.url'),
                    colWidth: 12,
                    name: 'brand:url',
                    placeholder: t('manageBrandForm.urlPlaceholder')
                },
                formState: {
                    value: value.url,
                    disabled
                }
            },
            comments: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: t('manageBrandForm.comments'),
                    colWidth: 12,
                    name: 'brand:comments',
                    rows: 5,
                    componentClass: 'textarea'
                },
                formState: {
                    value: value.comments,
                    disabled
                }
            },
            $field_0: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <>
                        <Col xs={12}>
                            <hr
                                style={{
                                    height: '1px',
                                    backgroundColor: '#000'
                                }}
                            />
                        </Col>
                        <FormUtil.TextLabelStatic
                            meta={{
                                label: t(
                                    'manageBrandForm.fulfillOnlyForPartners'
                                )
                            }}
                        />
                        <FormUtil.TextLabelStatic
                            meta={{
                                label: t(
                                    'manageBrandForm.reportOptionsDescription'
                                ),
                                fontWeight: 'normal'
                            }}
                        />
                    </>
                )
            },
            address: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: t('manageBrandForm.address'),
                    colWidth: 6,
                    type: 'text',
                    name: 'address'
                },
                formState: {
                    value: value.address,
                    disabled
                }
            },
            address2: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('manageBrandForm.address2'),
                    colWidth: 6,
                    type: 'text',
                    name: 'address2',
                    required: false
                },
                formState: {
                    value: value.address2,
                    disabled
                }
            },
            city: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: t('manageBrandForm.city'),
                    colWidth: 5,
                    type: 'text',
                    name: 'city'
                },
                formState: {
                    value: value.city,
                    disabled
                }
            },
            state: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: t('manageBrandForm.state'),
                    colWidth: 3,
                    type: 'text',
                    name: 'state'
                },
                formState: {
                    value: value.state,
                    disabled
                }
            },
            postalCode: {
                options: {
                    validators: [
                        Validators.pattern(
                            /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/
                        )
                    ]
                },
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: t('manageBrandForm.postalCode'),
                    colWidth: 4,
                    name: 'postal-code'
                },
                formState: {
                    value: value.postalCode,
                    disabled
                }
            },
            countryID: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:country',
                    options: FormUtil.convertToOptions(
                        Object.values(constants.countries)
                    ),
                    colWidth: 12,
                    type: 'select',
                    placeholder: 'manageUserQueue:countrySearchPlaceholder',
                    defaultValue: countryValue,
                    isClearable: true
                },
                formState: {
                    value: countryValue,
                    disabled
                }
            },
            phoneNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('manageBrandForm.phoneNumber'),
                    colWidth: 12,
                    type: 'text',
                    name: 'phoneNumber'
                },
                options: {
                    validators: [Validators.maxLength(50)]
                },
                formState: {
                    value: value.phoneNumber,
                    disabled
                }
            },
            isManufacturer: {
                render: FormUtil.Toggle,
                meta: {
                    colWidth: 12,
                    label: t('manageBrandForm.isManufacturer')
                },
                formState: {
                    value: value.isManufacturer,
                    disabled
                }
            },
            file: {
                render: FormUtil.FileInput,
                meta: {
                    type: 'file',
                    label: t('manageBrandForm.logo'),
                    name: 'brand-logo',
                    icon: 'plus',
                    colWidth: 12,
                    accept: 'image/png, image/jpeg',
                    required: false,
                    onChange: this.onFileChange,
                    uploadButton: true,
                    style: { textAlign: 'left' },
                    imageUrl,
                    hasPreview: true
                }
            },
            $field_1: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <>
                        <FormUtil.TextLabelStatic
                            meta={{
                                label: t(
                                    'manageBrandForm.reportSendingSettings'
                                )
                            }}
                        />
                    </>
                )
            },
            reportEmail: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: t('manageBrandForm.reportEmail'),
                    colWidth: 12,
                    name: 'brand:reportEmail',
                    placeholder: t('manageBrandForm.reportEmailPlaceholder')
                },
                options: {
                    validators: [FormUtil.validators.isValidEmail]
                },
                formState: {
                    value: value.reportEmail,
                    disabled
                }
            },
            $field_2: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <>
                        <FormUtil.TextLabelStatic
                            meta={{
                                label: t(
                                    'manageBrandForm.sendReportToCustomers'
                                ),
                                fontWeight: 'normal'
                            }}
                        />
                    </>
                )
            },
            sendReportToCustomers: {
                render: FormUtil.Toggle,
                meta: {
                    colWidth: 12
                },
                formState: {
                    value: value.sendReportToCustomers,
                    disabled
                }
            },
            replyToEmail: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: t('manageBrandForm.replyToEamil'),
                    colWidth: 12,
                    name: 'brand:replyToEamil',
                    placeholder: t('manageBrandForm.replyToEamilPlaceholder')
                },
                options: {
                    validators: [FormUtil.validators.isValidEmail]
                },
                formState: {
                    value: value.replyToEmail,
                    disabled
                }
            }
        };

        return FormUtil.translateForm(
            {
                controls
            },
            this.props.t
        );
    };

    onValueChanges = (value: any, key: string) => {
        this.form.patchValue({ [key]: value });
    };

    onFileChange = (key: string, file: File) => {
        const reader = new FileReader();

        reader.onload = (e: any) => {
            this.setState(
                { fileName: file.name, file: e.target.result } as any,
                () => {
                    this.setState({
                        fieldConfig: this.buildFieldConfig(
                            this.formValuesToItem()
                        )
                    });
                }
            );
            this.onValueChanges(e.target.result, 'logo');
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    /*
     * initSelected
     * if there is a query param, check if it matches the selectedItem in redux
     * if there is a brand we are editing download any dependencies
     */
    initSelected = () => {
        if (
            this.props.queryParams.selectedBrandID &&
            this.props.queryParams.selectedBrandID !==
                this.props.selectedBrand.id
        ) {
            const { originalBrand, isEditMode } = this.props;
            // is there an existing item from redux
            if (originalBrand && isEditMode) {
                this.props.onChange(originalBrand);
                return;
            }

            // the item we are trying to edit has not been loaded.  TODO download this item
            // since we do not support downloading a single item yet, close the modal
            this.props.closeModal();
        }
    };

    /*
     * itemToFormValues - take the selectedObject and convert it to formValues
     */
    itemToFormValues = (): IgenericFormValues<Ibrand> => {
        return this.props.originalBrand ?? this.props.selectedBrand;
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedObject
     */
    formValuesToItem = (): Ibrand => {
        const formValues = FormUtil.getValues(this.form.value);
        const brand = this.props.originalBrand ?? this.props.selectedBrand;
        return {
            ...brand,
            ...formValues,
            logo: this.state.file
        };
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.form.status === 'INVALID') {
            this.form.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        const updatedBrand = this.formValuesToItem();
        // create or update brand
        this.props.saveBrand(updatedBrand, this.props.isEditMode);
        this.props.closeModal();
    };

    setForm = (form: AbstractControl) => {
        this.form = form;
        this.form.meta = {
            loading: this.props.loading
        };
    };

    render() {
        const { t } = this.props;
        const formClassName = `beacon-form location-form ${this.props.colorButton}`;

        return (
            <div>
                <form onSubmit={this.handleSubmit} className={formClassName}>
                    <FormGenerator
                        onMount={this.setForm}
                        fieldConfig={this.state.fieldConfig}
                    />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.props.closeModal}
                        >
                            {t('common:cancel')}
                        </Button>
                        <Button
                            bsStyle={this.props.colorButton}
                            type="submit"
                            disabled={this.props.loading}
                        >
                            {t('common:save')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}
export default ManageBrandForm;
