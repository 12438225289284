import { Col, DropdownButton, MenuItem } from 'react-bootstrap';

import { Ilead } from '../../models';
import React from 'react';
import { RowInfo } from 'react-table';
import { TFunction } from 'i18next';

interface RowInfoLead extends RowInfo {
    original: Ilead;
}
interface Iprops {
    t: TFunction;
    rowInfo: RowInfoLead;
    colorButton: string;
    setSelectedLeadFromID: (id: string) => void;
    showLeadActivity: (leadID: string) => void;
}

export const ActionButton = (props: Iprops) => {
    const { t } = props;

    return (
        <Col xs={4} lg={2} className="work-order-action-button" style={{}}>
            <DropdownButton
                pullRight
                bsStyle={props.colorButton}
                bsSize="small"
                title={t('manageLeads:actions')}
                id="inventory-action-button"
                onSelect={(eventKey: any) => {
                    switch (eventKey) {
                        case 1:
                            props.setSelectedLeadFromID(
                                props.rowInfo.original.id
                            );
                            break;
                        case 2:
                            console.info('eh');
                            break;
                        case 3:
                            props.showLeadActivity(props.rowInfo.original.id);
                            break;
                    }
                }}
            >
                <MenuItem eventKey={1}>{t('editLead')}</MenuItem>
                {/* <MenuItem eventKey={2}>{t('manageContact')}</MenuItem> commented out by jfbloom22 because it is not a priority */}
                <MenuItem eventKey={3}>{t('viewActivity')}</MenuItem>
            </DropdownButton>
        </Col>
    );
};
