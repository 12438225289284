/*
 * Edit Facility Modal
 * responsible for editing a facility
 * this modal is rendered inside the UserQueueForm component because we pass the selectedCustomer
 */

import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from './WithCommonModal';
import { IinitialState } from '../../reducers';
import UserTrainingDataListView from './UserTrainingDataListView';

export interface IUserTrainingDataProps {
    modalClass?: string;
    secondModal?: boolean;
    userId: string;
}

const defaultProps = {
    className: 'edit-faciity',
    wideModal: true
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: IUserTrainingDataProps & WithTranslation
) => {
    let title = ownProps.t('trainingHistory');

    return {
        ...defaultProps,
        ...ownProps,
        title
    };
};

export default withTranslation('user')(
    connect(mapStateToProps, {})(WithCommonModal(UserTrainingDataListView))
);
