/*
 * Manage Install Form
 * Edit Install items
 */

import * as React from 'react';

import { Button, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import {
    AbstractControl,
    FieldConfig,
    FormGenerator,
    GroupProps,
    ValidatorFn,
    Validators
} from 'react-reactive-form';
import {
    deleteInstall,
    saveInstall,
    setInstallFormValues,
    updateInstall,
    updateInstallFormValues
} from '../../actions/manageInventoryActions';
import {
    Ibuilding,
    Ifacility,
    IinstallBase,
    Ijob,
    Iproduct,
    IproductInfo,
    ItableFiltersReducer,
    Iuser
} from '../../models';
import {
    initialInstallBase,
    initialProduct
} from '../../reducers/initialState';

import { TFunction } from 'i18next';
import { debounce, find, forEach, omit } from 'lodash';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import {
    capitalEquipmentCategories,
    constants
} from '../../constants/constants';
import { IinstallFormValues } from '../../modelsForms';
import AssetImage from '../common/AssetImage';
import { FormUtil } from '../common/FormUtil';
import { msalFetch } from '../auth/Auth-Utils';
import API from '../../constants/apiEndpoints';

interface Iprops {
    updateInstall: typeof updateInstall;
    saveInstall: typeof saveInstall;
    toggleModal: () => void;
    selectedItem: IinstallBase;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    tableFilters: ItableFiltersReducer;
    selectedProduct: Iproduct;
    mainCategoryID: string;
    facility: Ifacility;
    deleteInstall: typeof deleteInstall;
    saveAnyLocation: (
        name: string,
        facilityID: string,
        installBaseLocations?: {
            buildingID?: string;
            floorID?: string;
            locationID?: string;
        }
    ) => { id: string } | any;
    user: Iuser;
    showCancel?: boolean;
    productInfo: IproductInfo;
    setFormValues: typeof setInstallFormValues;
    updateFormValues: typeof updateInstallFormValues;
    formValues: IinstallFormValues;
    canEditInstalls: boolean;
    selectedJob: Ijob;
}

interface Istate {
    fieldConfig: FieldConfig;
    isGeneratingSerialNumber: boolean;
}

class ManageInstallForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValueDebounced: any;

    static defaultProps = {
        selectedItem: initialInstallBase,
        selectedProduct: initialProduct
    };
    constructor(props: Iprops) {
        super(props);
        this.updateFormValueDebounced = debounce(
            this.props.updateFormValues,
            constants.formDebounceTime
        );
        this.state = {
            fieldConfig: { controls: {} },
            isGeneratingSerialNumber: false
        };
    }
    componentDidMount() {
        // we should have a product if adding new, or should have an id if editing.
        if (
            (!this.props.selectedProduct ||
                (this.props.selectedProduct &&
                    this.props.selectedProduct.id.length === 0)) &&
            (!this.props.selectedItem ||
                (this.props.selectedItem && !this.props.selectedItem.id.length))
        ) {
            console.error('missing product and install');
        }

        this.props.setFormValues(this.itemToFormValues());
        this.setState({
            fieldConfig: this.buildFieldConfig(this.itemToFormValues())
        });
    }
    componentDidUpdate(prevProps: Iprops) {
        if (
            JSON.stringify(prevProps.selectedItem) !==
                JSON.stringify(this.props.selectedItem) &&
            this.props.selectedItem.id.length
        ) {
            this.setState({
                fieldConfig: this.buildFieldConfig(this.itemToFormValues())
            });
            this.props.setFormValues(this.itemToFormValues());
        }

        // since the date-time controls manage their own state, we cannot patch them
        if (prevProps.formValues.prodDate !== this.props.formValues.prodDate) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }

        if (
            JSON.stringify(prevProps.formValues.floorID) !==
                JSON.stringify(this.props.formValues.floorID) ||
            JSON.stringify(prevProps.formValues.locationID) !==
                JSON.stringify(this.props.formValues.locationID) ||
            JSON.stringify(prevProps.formValues.roomID) !==
                JSON.stringify(this.props.formValues.roomID) ||
            JSON.stringify(prevProps.formValues.buildingID) !==
                JSON.stringify(this.props.formValues.buildingID)
        ) {
            // this.setState({ fieldConfig: this.buildFieldConfig() });
            this.updateFormGroup();
        }
        if (
            JSON.stringify(this.props.facility) !==
            JSON.stringify(prevProps.facility)
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    updateFormGroup = (
        defaultValues: IinstallFormValues = this.prepValuesFromRedux()
    ) => {
        forEach(defaultValues, (value, key) => {
            FormUtil.patchControl(this.formGroup, key, value);
        });
        // patch the options
        FormUtil.patchControl(
            this.formGroup,
            'buildingID',
            defaultValues.buildingID,
            { options: defaultValues.buildingOptions }
        );
        FormUtil.patchControl(
            this.formGroup,
            'floorID',
            defaultValues.floorID,
            {
                options: defaultValues.floorOptions
            }
        );
        FormUtil.patchControl(
            this.formGroup,
            'locationID',
            defaultValues.locationID,
            { options: defaultValues.locationOptions }
        );
        FormUtil.patchControl(this.formGroup, 'roomID', defaultValues.roomID, {
            options: defaultValues.roomOptions
        });
    };

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IinstallFormValues => {
        const {
            buildingID,
            floorID,
            locationID,
            roomID,
            system
        } = this.props.selectedItem;

        let selectedSystemsOption;
        if (system) {
            selectedSystemsOption = {
                value: system,
                label: this.props.t('systemsOptionLabel', {
                    count: system
                })
            };
        }

        const cleanedItem = omit(this.props.selectedItem, [
            'workOrders',
            'latestMeasurementPointListResult',
            'latestWorkOrder'
        ]);
        return {
            ...cleanedItem,
            system: selectedSystemsOption,
            ...this.getAllFilteredLocations(
                buildingID,
                floorID,
                locationID,
                roomID
            )
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (): IinstallBase => {
        const formValues = FormUtil.getValues(this.formGroup.value);
        const cleanedItem = omit(this.props.selectedItem, [
            'workOrders',
            'latestMeasurementPointListResult',
            'latestWorkOrder'
        ]);

        // verify the facilities match
        if (
            this.props.selectedItem.facilityID &&
            this.props.selectedItem.facilityID.length &&
            this.props.selectedItem.facilityID !== this.props.facility.id
        ) {
            console.error(
                'facilities do not match',
                this.props.selectedItem,
                this.props.facility.id
            );
            toastr.error(
                this.props.t('toastMessage:error'),
                this.props.t('toastMessage:errorMatchingFacilities'),
                constants.toastrError
            );
            throw new Error('facilities do not match');
        }

        let ib: any = {
            ...cleanedItem,
            ...formValues,
            productID:
                this.props.selectedItem.productID ||
                this.props.selectedProduct.id,
            facilityID: this.props.facility.id,
            quantity: parseInt(`${formValues.quantity}`, 10)
        };

        ib.lastFiveYearMaintenanceDate =
            ib.lastFiveYearMaintenanceDate === ''
                ? null
                : ib.lastFiveYearMaintenanceDate;
        ib.lastThreeYearMaintenanceDate =
            ib.lastThreeYearMaintenanceDate === ''
                ? null
                : ib.lastThreeYearMaintenanceDate;
        ib.lastTwoYearMaintenanceDate =
            ib.lastTwoYearMaintenanceDate === ''
                ? null
                : ib.lastTwoYearMaintenanceDate;
        ib.lastYearlyMaintenanceDate =
            ib.lastYearlyMaintenanceDate === ''
                ? null
                : ib.lastYearlyMaintenanceDate;
        ib.lastSixMonthMaintenanceDate =
            ib.lastSixMonthMaintenanceDate === ''
                ? null
                : ib.lastSixMonthMaintenanceDate;
        ib.lastQuarterlyMaintenanceDate =
            ib.lastQuarterlyMaintenanceDate === ''
                ? null
                : ib.lastQuarterlyMaintenanceDate;

        ib.latestAGSMeasurementPointListResultID =
            ib.latestAGSMeasurementPointListResultID === ''
                ? null
                : ib.latestAGSMeasurementPointListResultID;
        ib.latestAuditMeasurementPointListResultID =
            ib.latestAuditMeasurementPointListResultID === ''
                ? null
                : ib.latestAuditMeasurementPointListResultID;
        ib.latestCommissioningMeasurementPointListResultID =
            ib.latestCommissioningMeasurementPointListResultID === ''
                ? null
                : ib.latestCommissioningMeasurementPointListResultID;
        ib.latestMeasurementPointListResultID =
            ib.latestMeasurementPointListResultID === ''
                ? null
                : ib.latestMeasurementPointListResultID;
        ib.latestVerificationMeasurementPointListResultID =
            ib.latestVerificationMeasurementPointListResultID === ''
                ? null
                : ib.latestVerificationMeasurementPointListResultID;
        ib.latestWorkOrderID =
            ib.latestWorkOrderID === '' ? null : ib.latestWorkOrderID;

        return ib;
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };
    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'prodDate':
                this.handleNewManufactureDate(value);
                break;
            default:
                if (moment.isMoment(value)) {
                    value = value.format(constants.momentSQLFormat);
                }
                this.updateFormValueDebounced({ [key]: value });
                break;
        }
    };

    /*
     * prepValuesFromRedux
     * help filter and build the selected locations, and options
     */
    prepValuesFromRedux = () => {
        const formValues = FormUtil.getValues(this.props.formValues) as {
            [key: string]: any;
        };

        return {
            ...this.props.formValues,
            ...this.getAllFilteredLocations(
                formValues.buildingID,
                formValues.floorID,
                formValues.locationID,
                formValues.roomID
            )
        };
    };

    generateSerialNumber = () => {
        if (!this.state.isGeneratingSerialNumber) {
            this.setState({ isGeneratingSerialNumber: true });
            msalFetch(API.GET.inventory.getNextSerialNumber, { method: 'GET' })
                .then(result => {
                    const serialNumber = result.data;
                    this.formGroup.patchValue({ serialNumber });
                })
                .catch(error =>
                    constants.handleError(error, 'generate serial number')
                )
                .finally(() => {
                    this.setState({ isGeneratingSerialNumber: false });
                });
        }
    };

    /*
  * buildFieldConfig
  accepts defaultValues and returns a formConfig object
  */
    buildFieldConfig = (
        defaultValues: IinstallFormValues = this.prepValuesFromRedux()
    ) => {
        const disabled = this.props.canEditInstalls === false;
        const isCapitalEquipmentType = Object.values(
            capitalEquipmentCategories
        ).includes(this.props.mainCategoryID);

        let quantityValidators: ValidatorFn[] = [];
        const shouldRequireQuantity = this.props.selectedItem.id.length
            ? false
            : true;
        if (shouldRequireQuantity) {
            quantityValidators = [
                Validators.min(1),
                Validators.max(1000),
                FormUtil.validators.requiredWithTrim
            ];
        }

        const fieldConfigControls = {
            $field_0: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormUtil.TextLabelStatic
                            meta={{
                                label: this.props.t('productInfo'),
                                colWidth: 12,
                                value: this.props.selectedProduct.name
                            }}
                        />
                        <AssetImage
                            style={{
                                marginRight: 15,
                                background: 'white',
                                width: '50',
                                height: '50'
                            }}
                            mainCategoryID={this.props.mainCategoryID}
                            imagePath={this.props.selectedProduct.imagePath}
                        />
                    </div>
                )
            },
            nickname: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'nickname',
                    colWidth: 12,
                    type: 'input',
                    name: 'nickname',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(40)
                },
                formState: { value: defaultValues.nickname, disabled }
            },
            serialNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'serialNumber',
                    colWidth: 6,
                    type: 'input',
                    name: 'serial-number',
                    required: isCapitalEquipmentType
                },
                options: {
                    validators: isCapitalEquipmentType
                        ? [
                              Validators.maxLength(20),
                              Validators.required,
                              Validators.pattern(/^[\w-/]+$/)
                          ]
                        : [
                              Validators.maxLength(20),
                              Validators.pattern(/^[\w-/]+$/)
                          ]
                },
                formState: { value: defaultValues.serialNumber, disabled }
            },
            generateSNButton: {
                render: ({ meta }) => (
                    <Col style={{ marginTop: '19px' }} xs={meta.colWidth}>
                        <Button
                            bsStyle={meta.style}
                            onClick={meta.buttonAction}
                            disabled={meta.disabled}
                        >
                            {meta.buttonName}
                        </Button>
                    </Col>
                ),
                meta: {
                    buttonName: 'Generate S/N',
                    style: 'primary',
                    colWidth: 6,
                    buttonAction: () => this.generateSerialNumber(),
                    disabled
                }
            },
            rfid: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'rfid',
                    colWidth: 12,
                    type: 'input',
                    name: 'rfid',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(20)
                },
                formState: { value: defaultValues.rfid, disabled }
            },
            sapEquipmentNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'sapEquipmentNumber',
                    colWidth: 6,
                    type: 'input',
                    name: 'sapEquipmentNumber',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(20)
                },
                formState: {
                    value: defaultValues.sapEquipmentNumber,
                    disabled: true
                }
            },
            sapMaterialNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'sapMaterialNumber',
                    colWidth: 6,
                    type: 'input',
                    name: 'sapMaterialNumber',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(20)
                },
                formState: {
                    value: this.props.selectedProduct.sapMaterialNumber,
                    disabled: true
                }
            },
            remarks: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'remarks',
                    colWidth: 12,
                    componentClass: 'textarea',
                    name: 'remarks',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(1000)
                },
                formState: { value: defaultValues.remarks, disabled }
            },
            quantity: {
                options: {
                    validators: quantityValidators
                },
                render: FormUtil.TextInput,
                inputType: 'number',
                meta: {
                    label: 'quantity',
                    colWidth: 6,
                    type: 'number',
                    defaultValue: 1,
                    name: 'quantity',
                    style: shouldRequireQuantity
                        ? undefined
                        : { display: 'none' }
                },
                formState: {
                    value: defaultValues.quantity || 1,
                    disabled: isCapitalEquipmentType
                }
            },
            $field_2: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: this.props.t('locationLabel'),
                            colWidth: 12,
                            style: { marginTop: '15px' }
                        }}
                    />
                )
            },
            $field_3: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <Col
                        xs={12}
                        style={{
                            display: `${
                                defaultValues.importedLocation
                                    ? 'block'
                                    : 'none'
                            }`
                        }}
                    >
                        <FormGroup bsSize="sm">
                            <ControlLabel>
                                {this.props.t('imported location')}
                            </ControlLabel>
                            <h5 className="queue-form-label">
                                {defaultValues.importedLocation}
                            </h5>
                        </FormGroup>
                    </Col>
                )
            },
            buildingID: {
                render: FormUtil.CreatableSelect,
                meta: {
                    options: defaultValues.buildingOptions,
                    label: 'building',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    handleCreate: this.handleCreateBuilding,
                    name: 'building',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.buildingID,
                    disabled
                }
            },
            floorID: {
                render: FormUtil.CreatableSelect,
                meta: {
                    options: defaultValues.floorOptions,
                    label: 'floor',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    handleCreate: this.handleCreateFloor,
                    name: 'floor',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.floorID,
                    disabled
                }
            },
            locationID: {
                render: FormUtil.CreatableSelect,
                meta: {
                    options: defaultValues.locationOptions,
                    label: 'location',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    handleCreate: this.handleCreateLocation,
                    name: 'location',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.locationID,
                    disabled
                }
            },
            roomID: {
                render: FormUtil.CreatableSelect,
                meta: {
                    options: defaultValues.roomOptions,
                    label: 'room',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    handleCreate: this.handleCreateRoom,
                    name: 'room',
                    required: false,
                    isClearable: true
                },
                options: {
                    validators: Validators.maxLength(250)
                },
                formState: {
                    value: defaultValues.roomID,
                    disabled
                }
            },
            position: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'position',
                    colWidth: 12,
                    name: 'position',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(145)
                },
                formState: { value: defaultValues.position, disabled }
            },
            system: {
                render: FormUtil.Select,
                meta: {
                    options: constants.constructSystems(this.props.t),
                    label: 'system',
                    colWidth: 12,
                    placeholder: 'nsJob:typePlaceholder',
                    name: 'system',
                    disableSort: true,
                    required: false
                },
                formState: { value: defaultValues.system, disabled }
            },
            prodDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'manufacture date',
                    colWidth: 12,
                    showTime: false,
                    name: 'manufacture-date',
                    required: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: { value: defaultValues.prodDate, disabled }
            },
            $field_4: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: this.props.t('labelOtherDates'),
                            colWidth: 12,
                            style: { marginTop: '15px' }
                        }}
                    />
                )
            },
            installDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'install date',
                    colWidth: 12,
                    showTime: false,
                    name: 'install-date',
                    required: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: { value: defaultValues.installDate, disabled }
            },
            lastQuarterlyMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastQuarterlyMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'quarterly-date',
                    required: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastQuarterlyMaintenanceDate,
                    disabled
                }
            },
            lastSixMonthMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastSixMonthMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'six-month-date',
                    required: false,
                    alignRight: true,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastSixMonthMaintenanceDate,
                    disabled
                }
            },
            lastYearlyMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastYearlyMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'yearly-date',
                    required: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastYearlyMaintenanceDate,
                    disabled
                }
            },
            lastTwoYearMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastTwoYearMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'two-year-date',
                    required: false,
                    alignRight: true,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastTwoYearMaintenanceDate,
                    disabled
                }
            },
            lastThreeYearMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastThreeYearMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'three-year-date',
                    required: false,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastThreeYearMaintenanceDate,
                    disabled
                }
            },
            lastFiveYearMaintenanceDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'lastFiveYearMaintenanceDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'five-year-date',
                    required: false,
                    alignRight: true,
                    placeholder: 'DD-MMM-YY'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.lastFiveYearMaintenanceDate,
                    disabled
                }
            }
        } as { [key: string]: GroupProps };

        // REFACTOR: rather than changing the validation and hiding, omit the quantity control here
        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    handleNewManufactureDate = (value: string | null) => {
        if (moment.isMoment(value) === false) {
            return;
        }
        const newDate = moment.isMoment(value)
            ? value.format(constants.momentSQLFormat)
            : value;
        let newDateFieldValues: { [key: string]: any } = { prodDate: newDate };

        if (newDate) {
            const dates = [
                'installDate',
                'lastQuarterlyMaintenanceDate',
                'lastThreeYearMaintenanceDate',
                'lastFiveYearMaintenanceDate',
                'lastTwoYearMaintenanceDate',
                'lastYearlyMaintenanceDate',
                'lastSixMonthMaintenanceDate'
            ];
            dates.forEach(date => {
                const dateValue = this.props.formValues[date];
                if (!dateValue) {
                    newDateFieldValues = {
                        ...newDateFieldValues,
                        [date]: newDate
                    };
                    // this.formGroup.patchValue({ [date]: moment.utc(value) });
                }
            });
            this.props.updateFormValues(newDateFieldValues);
        }
    };

    handleFieldLengthError = (name: string) => {
        if (name.length > 250) {
            toastr.error('Field too long', '', constants.toastrError);
            return true;
        }
    };

    handleCreateBuilding = (name: string) => {
        if (this.handleFieldLengthError(name)) {
            return;
        }

        const locationObject = this.props.saveAnyLocation(
            name,
            this.props.facility.id,
            {}
        );
        this.props.updateFormValues({
            buildingID: { label: name, value: locationObject.id }
        });
    };

    handleCreateFloor = (name: string) => {
        const { buildingID } = this.formGroup.value;
        if (!buildingID) {
            toastr.error(
                'Please select a building first.',
                '',
                constants.toastrError
            );
            return;
        }
        if (this.handleFieldLengthError(name)) {
            return;
        }
        const locationObject = this.props.saveAnyLocation(
            name,
            this.props.facility.id,
            { buildingID: buildingID.value }
        );
        this.props.updateFormValues({
            floorID: { label: name, value: locationObject.id }
        });
    };
    handleCreateLocation = (name: string) => {
        const { buildingID, floorID } = this.formGroup.value;
        if (!buildingID || !floorID) {
            toastr.error(
                'Please select a building and a floor first.',
                '',
                constants.toastrError
            );
            return;
        }
        if (this.handleFieldLengthError(name)) {
            return;
        }
        const locationObject = this.props.saveAnyLocation(
            name,
            this.props.facility.id,
            { buildingID: buildingID.value, floorID: floorID.value }
        );
        this.props.updateFormValues({
            locationID: { label: name, value: locationObject.id }
        });
    };
    handleCreateRoom = (name: string) => {
        const { buildingID, floorID, locationID } = this.formGroup.value;
        if (!buildingID || !floorID || !locationID) {
            toastr.error(
                'Please select a building, floor, and location first.',
                '',
                constants.toastrError
            );
            return;
        }
        if (this.handleFieldLengthError(name)) {
            return;
        }
        const locationObject = this.props.saveAnyLocation(
            name,
            this.props.facility.id,
            {
                buildingID: buildingID.value,
                floorID: floorID.value,
                locationID: locationID.value
            }
        );
        this.props.updateFormValues({
            roomID: { label: name, value: locationObject.id }
        });
    };

    getAllFilteredLocations = (
        buildingID?: string,
        floorID?: string,
        locationID?: string,
        roomID?: string
    ) => {
        let selectedBuilding, selectedFloor, selectedLocation, selectedRoom;

        if (buildingID) {
            selectedBuilding = find(this.props.facility.buildings, {
                id: buildingID
            }) as Ibuilding;
            if (selectedBuilding) {
                selectedFloor = find(selectedBuilding.floors, {
                    id: floorID
                });
                if (selectedFloor) {
                    selectedLocation = find(selectedFloor.locations, {
                        id: locationID
                    });
                    if (selectedLocation) {
                        selectedRoom = find(selectedLocation.rooms, {
                            id: roomID
                        });
                    }
                }
            }
        }

        const buildingOptions = FormUtil.convertToOptions(
            this.props.facility.buildings
        );
        const floorOptions = FormUtil.convertToOptions(
            selectedBuilding ? selectedBuilding.floors : []
        );
        const locationOptions = FormUtil.convertToOptions(
            selectedFloor ? selectedFloor.locations : []
        );
        const roomOptions = FormUtil.convertToOptions(
            selectedLocation ? selectedLocation.rooms : []
        );

        return {
            buildingOptions,
            floorOptions,
            locationOptions,
            roomOptions,
            buildingID: FormUtil.convertToSingleOption(selectedBuilding),
            floorID: FormUtil.convertToSingleOption(selectedFloor),
            locationID: FormUtil.convertToSingleOption(selectedLocation),
            roomID: FormUtil.convertToSingleOption(selectedRoom)
        };
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.info('form submit', this.formGroup);
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        if (this.props.facility) {
            if (this.props.selectedItem.id.length) {
                // EDIT
                this.props.updateInstall(
                    this.formValuesToItem(),
                    this.props.selectedItem.productID
                );
            } else if (
                this.props.selectedProduct &&
                this.props.selectedProduct.id.length
            ) {
                // SAVE NEW
                this.props.saveInstall(
                    this.formValuesToItem(),
                    this.props.selectedProduct,
                    this.props.selectedJob
                );
            } else {
                console.error('missing product when saving a new install.');
            }
        } else {
            console.error('missing facility, unable to save install');
            toastr.error(
                'Error',
                'Missing facility, please try again or contact support',
                constants.toastrError
            );
        }
    };
    handleDelete = () => {
        const toastrConfirmOptions = {
            onOk: () => {
                this.props.deleteInstall(this.props.selectedItem.id);
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('installDeleteOk'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(
            this.props.t('installDeleteConfirm'),
            toastrConfirmOptions
        );
    };
    setForm = (form: AbstractControl) => {
        if (!form) {
            console.error('missing form for setForm');
            return;
        }
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t } = this.props;

        const deleteButtonStyle =
            this.props.selectedItem.id.length === 0
                ? { marginRight: '15px', display: 'none' }
                : { marginRight: '15px' };
        return (
            <div className={this.props.colorButton}>
                <form
                    onSubmit={this.handleSubmit}
                    className="clearfix beacon-form"
                >
                    <FormGenerator
                        onMount={this.setForm}
                        fieldConfig={this.state.fieldConfig}
                    />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.props.toggleModal}
                        >
                            {t('common:cancel')}
                        </Button>
                        {this.props.canEditInstalls && (
                            <div>
                                <Button
                                    bsStyle={
                                        !!this.props.formValues
                                            .sapEquipmentNumber
                                            ? 'grey'
                                            : 'warning'
                                    }
                                    style={deleteButtonStyle}
                                    type="button"
                                    className=""
                                    disabled={
                                        this.props.loading ||
                                        !!this.props.formValues
                                            .sapEquipmentNumber
                                    }
                                    onClick={this.handleDelete}
                                >
                                    {t('common:delete')}
                                </Button>
                                <Button
                                    bsStyle={this.props.colorButton}
                                    type="submit"
                                    disabled={this.props.loading}
                                >
                                    {t('common:save')}
                                </Button>
                            </div>
                        )}
                    </Col>
                </form>
            </div>
        );
    }
}
export default ManageInstallForm;
