import * as types from './actionTypes';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
    ItableFiltersParams,
    IleadActivity,
    Ilead,
    ThunkResult
} from '../models';

import API from '../constants/apiEndpoints';
import { TFunction } from 'i18next';
import { beginAjaxCall } from './ajaxStatusActions';
import { FormUtil } from '../components/common/FormUtil';
import { constants } from '../constants/constants';
import { omit, map } from 'lodash';
import moment from 'moment';
import { msalFetch } from '../components/auth/Auth-Utils';
import { toastr } from 'react-redux-toastr';
import { initialLeadActivity, initialLead } from '../reducers/initialState';
import { dateRangeEnum } from '../models-enums';
const uuidv4 = require('uuid/v4');

// prettier-ignore
const convertDateRangeToInterval = (dateRange: dateRangeEnum): {startDate: string; endDate: string} => {
  switch (dateRange) {
    case dateRangeEnum.lastYear:
      { 
        return( 
        { 
          startDate:
            moment.utc(
              moment().subtract('year', 1).startOf('year')
            ).format(constants.momentSQLFormat),
          endDate: 
            moment.utc(
              moment().subtract('year', 1).endOf('year')
            ).format(constants.momentSQLFormat)
        }
      )}
    case dateRangeEnum.lastQuarter:
      { 
        return( 
        { 
          startDate:
            moment.utc(
                moment().quarter(                                                       // format quarter: number into moment
                    moment().subtract('quarter', 1).quarter()                           // subtract one quarter-length from the current quarter
                  ).startOf('quarter')                                                  // Grab the date of the beginning of the quarter
              ).format(constants.momentSQLFormat),                                      // format in sql
            endDate: 
              moment.utc(
                moment().quarter(
                  moment().subtract('quarter', 1).quarter()
                ).endOf('quarter')
              ).format(constants.momentSQLFormat)
        }
      )}
    case dateRangeEnum.thisYear:
      { 
        return( 
        { 
          startDate:
            moment.utc(moment().startOf('year')).format(constants.momentSQLFormat),
          endDate: 
            moment.utc(moment().endOf('year')).format(constants.momentSQLFormat)
        }
      )}
    case dateRangeEnum.thisQuarter:
      { 
        return(
        {
          startDate:
            moment.utc(
              moment().startOf('quarter')
            ).format(constants.momentSQLFormat),
          endDate: 
            moment.utc(
              moment().endOf('quarter')
            ).format(constants.momentSQLFormat)
        }
      )}
    default:
      return {startDate: '', endDate: ''}  // It expects this return type
  }
};

export function getLeads(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const {
            ranking,
            status,
            search,
            dateRange,
            type,
            page
        } = getState().manageLeads.tableFilters;

        const { startDate, endDate } = convertDateRangeToInterval(
            dateRange
                ? (dateRange.value as dateRangeEnum)
                : dateRangeEnum.thisQuarter
        );

        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                page: page ? page : 1,
                ranking: ranking ? ranking.value : false,
                search,
                status: status ? status.value : false,
                startDate: dateRange ? startDate : false,
                endDate: dateRange ? endDate : false,
                leadType: type ? type.value : false,
                sort: `createdate,desc`
            }
        };

        const url = API.lead.search;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LEADS_MANAGE_SUCCESS,
                        leads: resp.data.result
                    });
                    dispatch({
                        type: types.LEADS_MANAGE_TOTAL_PAGES,
                        pages: resp.data.pages
                    });
                    return resp;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LEADS_MANAGE_FAILED
                });
                constants.handleError(error, 'get leads');
                console.error('get leads', error);
            });
    };
}
export function bulkUpdate(status: number, t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        const onOk = () => {
            dispatch(beginAjaxCall());
            const leadIDs = getState().manageLeads.selection.map(
                item => item.split('select-')[1]
            );

            const axiosOptions: AxiosRequestConfig = {
                method: 'POST',
                data: leadIDs,
                params: FormUtil.toUrlSearchParams({
                    leadStatus: status.toString()
                })
            };

            const url = API.lead.bulkUpdateStatus;
            return msalFetch(url, axiosOptions)
                .then((resp: AxiosResponse<any>) => {
                    dispatch({
                        type: types.LEADS_BULK_UPDATE_STATUS_SUCCESS,
                        leadIDs,
                        leadStatus: status
                    });
                    dispatch(getLeads());
                })
                .catch((error: any) => {
                    dispatch({
                        type: types.LEADS_BULK_UPDATE_STATUS_FAILED
                    });
                    constants.handleError(error, 'bulk update leads');
                    console.error('bulk update leads', error);
                });
        };

        const toastrConfirmOptions = {
            onOk,
            onCancel: () => console.info('CANCEL clicked'),
            okText: t('bulkUpdate.tableOkButton'),
            cancelText: t('bulkUpdate.tableCancelButton')
        };
        toastr.confirm(
            t('bulkUpdate.deleteContactsConfirmTitle'),
            toastrConfirmOptions
        );
    };
}

export function deleteLeadInstallBase(
    installBaseID: string,
    t: TFunction
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };
        const url = API.leadInstallBase.single + `/${installBaseID}`;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.LEAD_INSTALL_DELETE_SUCCESS,
                    payload: { id: installBaseID }
                });
                toastr.success(
                    t('toastMessage:success'),
                    t('toastMessage:deletedLeadInstallBase'),
                    constants.toastrError
                );
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LEAD_INSTALL_DELETE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'delete install base from lead');
            });
    };
}

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_LEADS,
    filters
});

export const toggleLeadsModal = (show?: boolean) => ({
    type: types.TOGGLE_MODAL_LEADS,
    show
});

export const setSelectedLeadFromID = (leadID: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        const lead = getState().manageLeads.leadsByID[leadID];
        if (lead) {
            dispatch({
                type: types.SET_SELECTED_LEAD,
                payload: lead
            });
        } else {
            console.info('trying to set selected, but unable to find', leadID);
            dispatch({ type: types.TOGGLE_MODAL_LEADS });
        }
    };
};

export const updateSelectedLead = (lead = initialLead) => ({
    type: types.UPDATE_SELECTED_LEAD,
    payload: lead
});

export const updateLead = (lead: Ilead): ThunkResult<any> => {
    return (dispatch, getState) => {
        const prepLeadForApi = (plead: Ilead): Ilead => {
            return {
                ...omit(plead, [
                    'product',
                    'facility',
                    'contact',
                    'leadInstallBases'
                ]),
                leadUsers: map(plead.leadUsers, lu => omit(lu, ['user']))
            };
        };
        const leadForAPI = prepLeadForApi(lead);

        dispatch(beginAjaxCall());
        const url = `${API.lead.single}/${lead.id}`;
        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: leadForAPI
        };

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                }
                dispatch(toggleLeadsModal());
                dispatch({
                    type: types.LEAD_UPDATE_SUCCESS,
                    payload: lead
                });
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LEAD_UPDATE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'failed updating lead');
                throw error;
            });
    };
};

/*
 * LEAD ACTIVITY
 */

export function getLeadActivities(leadID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                id: leadID
            }
        };
        const url = API.leadActivity.getAll;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LEAD_ACTIVITIES_MANAGE_SUCCESS,
                        leadActivities: resp.data
                    });
                    return resp;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LEAD_ACTIVITIES_MANAGE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get lead activities');
                console.error('get lead activities', error);
            });
    };
}

export const updateSelectedLeadActivity = (
    leadActivity = initialLeadActivity
) => ({
    type: types.UPDATE_SELECTED_LEAD_ACTIVITY,
    leadActivity
});

export const toggleLeadActivityModal = (show?: boolean) => ({
    type: types.TOGGLE_MODAL_LEAD_ACTIVITIES,
    show
});

export const toggleEditLeadActivityModal = (show?: boolean) => ({
    type: types.TOGGLE_MODAL_EDIT_LEAD_ACTIVITY,
    show
});

export function deleteLeadActivity(id: string, t: TFunction): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const url = `${API.leadActivity.single}/${id}`;
        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                dispatch({
                    type: types.LEAD_ACTIVITY_DELETE_SUCCESS,
                    payload: id
                });
                dispatch({ type: types.TOGGLE_MODAL_EDIT_LEAD_ACTIVITY });
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LEAD_ACTIVITY_DELETE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'deleted leadActivity');
                console.error(error);
            });
    };
}
export function saveLeadActivity(
    leadActivity: IleadActivity
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const cleanedLeadActivity = omit(leadActivity, ['user']);

        const uuid = uuidv4();
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: {
                ...cleanedLeadActivity,
                userID: getState().user.id,
                id: uuid
            }
        };
        const url = API.leadActivity.single;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LEAD_ACTIVITY_ADD_SUCCESS,
                        leadActivity: {
                            ...leadActivity,
                            id: uuid,
                            user: getState().user
                        }
                    });
                    toastr.success(
                        'Success',
                        'Created new lead activity.',
                        constants.toastrSuccess
                    );
                    dispatch({ type: types.TOGGLE_MODAL_EDIT_LEAD_ACTIVITY });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LEAD_ACTIVITY_ADD_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'create leadActivity');
                console.error(error);
            });
    };
}
export function updateLeadActivity(
    leadActivity: IleadActivity
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const cleanedLeadActivity = omit(leadActivity, ['userID', 'user']);

        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: {
                ...cleanedLeadActivity
            }
        };
        const url = `${API.leadActivity.single}/${leadActivity.id}`;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LEAD_ACTIVITY_UPDATE_SUCCESS,
                        leadActivity: { ...leadActivity }
                    });
                    toastr.success(
                        'Success',
                        'Updated lead activity.',
                        constants.toastrSuccess
                    );
                    dispatch({ type: types.TOGGLE_MODAL_EDIT_LEAD_ACTIVITY });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.LEAD_ACTIVITY_UPDATE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'update leadActivity');
                console.error(error);
            });
    };
}

/*
 * setSelectedLeadActivityFromID
 * select the leadActivity and set it as the selectedLeadActivity
 */
export function setSelectedLeadActivityFromID(id: string): ThunkResult<any> {
    return (dispatch, getState) => {
        const leadActivity = getState().manageLeads.leadActivitiesByID[id];
        if (leadActivity) {
            dispatch({
                type: types.SET_SELECTED_LEAD_ACTIVITY,
                leadActivity
            });
        } else {
            // TODO this can be improved by adding an API call to get this specific leadActivity
            console.info(
                'trying to set selected leadActivity from id, but unable to find leadActivity',
                id
            );
            dispatch({
                type: types.TOGGLE_MODAL_EDIT_LEAD_ACTIVITY,
                show: false
            });
        }
    };
}

export const updateLeadSelection = (selection: string[]) => ({
    type: types.LEAD_UPDATE_SELECTION,
    payload: selection
});
