import * as types from './actionTypes';

export const addView = (route: string) => ({
    type: types.ADD_VIEWED_TOUR,
    payload: route
});

export const resetViews = () => ({
    type: types.RESET_VIEWED
});

export const setIsTutorialOpen = (run: boolean) => ({
    type: types.SET_IS_TUTORIAL_OPEN,
    payload: run
});

export const toggleTutorial = () => ({
    type: types.TOGGLE_TUTORIAL
});
