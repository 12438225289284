import React from 'react';

import { Button, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';

import moment from 'moment';
import { IleadActivity } from '../../models';
import { TFunction } from 'i18next';
import { leadActivityTypesEnum } from '../../models-enums';
import { WithTranslation } from 'react-i18next';
import { map } from 'lodash';
import { updateSelectedLeadActivity } from '../../actions/manageLeadsActions';

const LeadActivityItem = ({
    leadActivity,
    handleSelectLeadActivity,
    t
}: {
    leadActivity: IleadActivity;
    handleSelectLeadActivity: (id: string) => void;
    toggleEditLeadActivityModal: () => void;
    t: TFunction;
}) => (
    <ListGroupItem
        key={leadActivity.id}
        onClick={() => handleSelectLeadActivity(leadActivity.id)}
    >
        {' '}
        <Row>
            <Col md={4}>
                {moment
                    .utc(leadActivity.activityDate)
                    .local(true)
                    .format('DD-MMM-YY LT')}
            </Col>
            <Col md={5} className="notes-column truncate">
                {leadActivity.user.first} {leadActivity.user.last}
            </Col>
            <Col
                md={3}
                className={`type ${leadActivityTypesEnum[leadActivity.type]}`}
            >
                {t('common:' + leadActivityTypesEnum[leadActivity.type])}
            </Col>
        </Row>
    </ListGroupItem>
);

interface Iprops {
    leadActivities: IleadActivity[];
    colorButton: string;
    toggleModal: () => void;
    handleSelectLeadActivity: (id: string) => void;
    toggleEditLeadActivityModal: () => void;
    updateSelectedLeadActivity: typeof updateSelectedLeadActivity;
}

class LeadActivityList extends React.PureComponent<Iprops & WithTranslation> {
    constructor(props: Iprops & WithTranslation) {
        super(props);
    }
    render() {
        const { t, leadActivities } = this.props;
        if (leadActivities.length) {
            return (
                <div className="lead-activities">
                    <h5
                        style={{ paddingLeft: '15px', paddingBottom: '15px' }}
                    ></h5>
                    <ListGroup>
                        {map(leadActivities, (leadActivity: IleadActivity) => {
                            return (
                                <LeadActivityItem
                                    key={leadActivity.id}
                                    {...this.props}
                                    leadActivity={leadActivity}
                                />
                            );
                        })}
                    </ListGroup>
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={() => {
                                this.props.toggleModal();
                                //   this.props.updateSelectedJob();
                                //   removeQuery(
                                //     manageJobQueryParamsEnum.selectedJobID,
                                //     this.props.history
                                //   );
                            }}
                        >
                            {t('done')}
                        </Button>
                        <Button
                            bsStyle={this.props.colorButton}
                            type="button"
                            onClick={() => {
                                this.props.toggleEditLeadActivityModal();
                                this.props.updateSelectedLeadActivity();
                            }}
                        >
                            {t('manageLeads:addButton')}
                        </Button>
                    </Col>
                </div>
            );
        } else {
            return (
                <div className="result-history">
                    <Col md={12}>
                        <h4>{t('No lead activities for this lead.')}</h4>
                    </Col>
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={() => {
                                this.props.toggleModal();
                                //   this.props.updateSelectedJob();
                                //   removeQuery(
                                //     manageJobQueryParamsEnum.selectedJobID,
                                //     this.props.history
                                //   );
                            }}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            bsStyle={this.props.colorButton}
                            type="button"
                            onClick={() => {
                                this.props.toggleEditLeadActivityModal();
                                this.props.updateSelectedLeadActivity();
                            }}
                        >
                            {t('manageLeads:addButton')}
                        </Button>
                    </Col>
                </div>
            );
        }
    }
}

export default LeadActivityList;
