import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import {
    IdefaultReport,
    Ifacility,
    Ioption,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import ReactTable, {
    Column,
    FinalState,
    RowInfo,
    SortingRule
} from 'react-table';
import { emptyTile, initialFacility } from '../../reducers/initialState';
import { values } from 'lodash';
import {
    getDefaultReports,
    setSelectedDefaultReport,
    setSelectedReport,
    setTableFilter,
    toggleEditReportModal
} from '../../actions/manageReportActions';

import { Banner } from '../common/Banner';
/*
 * Manage Reports
 */
import { Col } from 'react-bootstrap';
import { FormUtil } from '../common/FormUtil';
import ManageReportModal from './ManageReportModal';
import { RouteComponentProps } from 'react-router-dom';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { TableUtil } from '../common/TableUtil';
import { closeAllModals } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { getJobsByFacility } from '../../actions/manageJobActions';
import { getCanAccessAllCustomers } from '../../reducers/userReducer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface RowInfoReport extends RowInfo {
    original: IdefaultReport;
}

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    loading: boolean;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleEditReportModal: typeof toggleEditReportModal;
    getDefaultReports: typeof getDefaultReports;
    closeAllModals: typeof closeAllModals;
    showEditReportModal: boolean;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    setSelectedReport: typeof setSelectedReport;
    setSelectedDefaultReport: typeof setSelectedDefaultReport;
    tableData: IdefaultReport[];
    fseUsers: Iuser[];
    initComplete: boolean;
    getJobsByFacility: typeof getJobsByFacility;
    facilityOptions: Ioption[];
    customerOptions: Ioption[];
    selectedFacility: Ifacility;
    canAccessAllCustomers: boolean;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
}

class ManageReport extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    public columns: any[];
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: null,
            currentTile: emptyTile
        };
        this.columns = TableUtil.translateHeaders(
            [
                {
                    id: 'name',
                    Header: 'name',
                    accessor: 'name',
                    // accessor: ({ reportType }: Ireport) => {
                    //   return this.props.t(constants.reportTypeEnumInverse[reportType]);
                    // },
                    width: 500
                }
            ],
            this.props.t
        );
    }
    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.props.getDefaultReports();
        this.props.getJobsByFacility();
    }
    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditReportModal !== this.props.showEditReportModal &&
            !this.props.showEditReportModal
        ) {
            this.setState({ selectedRow: null });
        }
        if (prevProps.selectedFacility.id !== this.props.selectedFacility.id) {
            this.props.getJobsByFacility();
        }
    }
    componentWillUnmount() {
        this.props.closeAllModals();
    }

    /*
     * (reusable)
     * set the row color after a user selects it
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };
    /*
     * (reusable)
     * get the next or previous page of data.  the table is 0 indexed but the API is not
     */
    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };
    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                this.props.setTableFilter({ [key]: value });
                break;
        }
    };
    /*
     * (reusable)
     * set the sorted changes to redux
     */
    onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };

    getTdProps = (
        fState: FinalState,
        rowInfo: RowInfoReport | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo) {
            return {
                onClick: () => {
                    this.setState({
                        selectedRow: rowInfo.index
                    });
                    this.props.setSelectedDefaultReport(rowInfo.original.id);
                    this.props.toggleEditReportModal();
                }
            };
        } else {
            return {};
        }
    };

    render() {
        if (this.props.initComplete === false) {
            return (
                <Col xs={12}>
                    <h4> loading... </h4>
                </Col>
            );
        }
        const { t } = this.props;
        return (
            <div className="manage-job">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                >
                    <SelectFacilityContainer
                        t={this.props.t}
                        classNameOverride={''}
                    />
                </Banner>
                <ReactTable
                    data={this.props.tableData}
                    onSortedChange={this.onSortedChanged}
                    columns={this.columns}
                    getTrProps={this.getTrProps}
                    pageSize={
                        this.props.tableData.length >= 10
                            ? this.props.tableData.length
                            : 10
                    }
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    getTdProps={this.getTdProps}
                />
                <ManageReportModal
                    {...this.props}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const selectedFacility =
        state.facilities[state.selectedFacilityID] || initialFacility;
    const facilityOptions = FormUtil.convertToOptions(state.facilities);
    const canAccessAllCustomers = getCanAccessAllCustomers(state.user);

    return {
        user: state.user,
        loading: selectIsLoading(state),
        showEditReportModal: state.manageReport.showEditReportModal,
        tableFilters: state.manageReport.tableFilters,
        facilityOptions,
        selectedFacility,
        tableData: values(state.manageReport.defaultReportsByID),
        canAccessAllCustomers
    };
};
export default withTranslation('manageReport')(
    connect(mapStateToProps, {
        getDefaultReports,
        toggleEditReportModal,
        closeAllModals,
        setTableFilter,
        setSelectedReport,
        setSelectedDefaultReport,
        getJobsByFacility
    })(ManageReport)
);
