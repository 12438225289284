/*
 * Manage Suggested Part Modal
 */

import { connect } from 'react-redux';
import { Iproduct } from '../../models';
import EditSuggestedPartForm from './EditSuggestedPartForm';
import { initialProduct } from '../../reducers/initialState';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import {
    saveSuggestedPart,
    toggleEditSuggestedPartModal,
    updateSelectedSuggestedPart
} from '../../actions/manageSuggestedPartsActions';
import { manageSuggestedPartsQueryParamsEnum } from './ManageSuggestedParts';

interface Iprops {
    colorButton: string;
    selectedProduct: Iproduct;
    queryParams: typeof manageSuggestedPartsQueryParamsEnum;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedSuggestedPart =
        state.manageSuggestedParts.selectedSuggestedPart;

    const { showSearchNewProductsModal } = state.manageInventory;
    let selectedProduct = ownProps.selectedProduct || initialProduct;

    let title;
    if (selectedSuggestedPart && selectedSuggestedPart.id) {
        title = ownProps.t(`manageSuggestedParts:editSuggestedPart`);
        selectedProduct = selectedSuggestedPart.product;
    } else {
        title = ownProps.t(`manageSuggestedParts:newSuggestedPart`);
    }

    return {
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageSuggestedParts.showEditSuggestedPartModal,
        secondWideModal: showSearchNewProductsModal,
        tableFilters: state.manageSuggestedParts.tableFilters,
        selectedSuggestedPart,
        originalSuggestedPart:
            state.manageSuggestedParts.suggestedParts[
                ownProps.queryParams.suggestedPartID
            ],
        selectedProduct,
        title,
        className: 'suggested-part-edit'
    };
};

export default withTranslation('manageSuggestedParts')(
    connect(mapStateToProps, {
        saveSuggestedPart,
        toggleModal: toggleEditSuggestedPartModal,
        onHide: toggleEditSuggestedPartModal,
        onChange: updateSelectedSuggestedPart
    })(WithCommonModal(EditSuggestedPartForm))
);
