import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ItableFiltersParams, ThunkResult } from '../models';
import { beginAjaxCall } from './ajaxStatusActions';
import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import * as types from './actionTypes';
import { msalFetch } from '../components/auth/Auth-Utils';

export async function savePendingTraining(
    trainingIDs: string[]
): Promise<AxiosResponse<any>> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: { trainingIDs }
    };
    const url = API.GET.training.savePendingTraining;
    return msalFetch(url, axiosOptions);
}

export function getManageTraining(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const {
            manageTraining: { tableFilters }
        } = getState();
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                ...tableFilters,
                isCourseCompleted: tableFilters.isCourseCompleted || null
            }
        };

        const url = API.GET.training.getAdminProgress;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.MANAGE_TRAINING_SUCCESS,
                        trainingProgress: data.data.result
                    });
                    dispatch({
                        type: types.MANAGE_TRAINING_TOTAL_PAGES,
                        pages: data.data.pages
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.MANAGE_TRAINING_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get user training progress');
                console.error(error);
            });
    };
}

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_TRAINING,
    filters
});
