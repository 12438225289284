import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../constants/apiEndpoints';
import { msalFetch } from '../components/auth/Auth-Utils';
import * as types from './actionTypes';
import { constants } from '../constants/constants';
import {
    IcloudDocument,
    ItableFiltersParams,
    ItableFiltersReducer,
    ThunkResult
} from '../models';
import { toastr } from 'react-redux-toastr';
import fileDownload from 'js-file-download';
import { TFunction } from 'i18next';
import { convertDateRangeToInterval } from './manageAssetPhotosActions';
import { dateRangeEnum } from '../models-enums';

export function getDocuments(
    t: TFunction,
    tableFilters: Partial<ItableFiltersReducer>
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const { selectedFacilityID } = getState();

        const {
            assetSearch,
            mainCategoryID,
            type,
            jobType,
            dateRange,
            isSystem
        } = tableFilters;
        const { startDate, endDate } = dateRange
            ? convertDateRangeToInterval(dateRange?.value as dateRangeEnum)
            : { startDate: undefined, endDate: undefined };
        if (!selectedFacilityID.length) {
            toastr.warning(
                t('warning'),
                t('Must select a facility.'),
                constants.toastrWarning
            );
            dispatch(endAjaxCall());
            return Promise.resolve();
        }
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                facilityID: selectedFacilityID,
                search: assetSearch,
                mainCategoryID,
                start: startDate,
                end: endDate,
                isSystem: isSystem ? JSON.parse(isSystem.value) : undefined,
                documentTypeID: type ? type.value : undefined,
                jobTypeID: jobType ? jobType.value : undefined
            }
        };
        const regex = /^\?installbaseId=.*$/i;
        const location = window.location.search;
        const installBaseSearch =
            location && location.match(regex) ? location : '';
        let url = installBaseSearch
            ? `${API.cloudDocument.installSearch}${installBaseSearch}`
            : API.cloudDocument.search;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('Missing Data');
                } else {
                    dispatch({
                        type: types.LOAD_DOCUMENTS_SUCCESS,
                        payload: data.data
                    });
                    dispatch({
                        type: types.BRAND_MANAGE_TOTAL_PAGES,
                        pages: data.data.pages
                    });
                    return data;
                }
            })
            .catch(error => {
                dispatch({
                    type: types.LOAD_DOCUMENTS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get Documents');
                console.error(error);
            });
    };
}

/**
 *
 * @param cloudDocument
 * @param shouldDownload
 * @returns objectURL to the downloaded image
 */
export function getCloudDocument(
    t: TFunction,
    cloudDocument?: Partial<IcloudDocument>,
    shouldDownload = false
): ThunkResult<any> {
    const ensureDocType = (
        name: string | undefined,
        mimeType: string
    ): string | null => {
        const pattern = new RegExp(
            '(xlsx|xls|doc|docx|ppt|pptx|txt|pdf|zip|png|jpg|jpeg|gif|avif|webp|x-zip-compressed)$',
            'gi'
        );
        let type = mimeType.match(pattern)?.[0] ?? 'pdf';

        if (type === 'x-zip-compressed') {
            type = 'zip';
        }
        return name
            ? name.match(pattern) !== null
                ? name
                : `${name}.${type}`
            : null;
    };

    return (dispatch, getState) => {
        if (!cloudDocument?.documentID) {
            toastr.warning('Missig document', '', constants.toastrWarning);
            console.error('missing cloud doc', cloudDocument);
            return;
        }

        toastr.success(
            'Success',
            t('manageDocument:downloading'),
            constants.toastrSuccess
        );

        dispatch(beginAjaxCall());

        let url = API.cloudDocument.downloadVersion
            .replace('{documentID}', cloudDocument?.documentID || '')
            .replace('{versionID}', cloudDocument?.version || '');
        if (!cloudDocument?.version?.length) {
            url = API.cloudDocument.download.replace(
                '{documentID}',
                cloudDocument?.documentID || ''
            );
        }
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {},
            responseType: 'blob',
            timeout: constants.httpTimeoutDownload
        };
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('Missing Data');
                } else {
                    dispatch({ type: types.DOWNLOAD_DOCUMENT_SUCCESS });

                    if (shouldDownload) {
                        const { filename, name } = cloudDocument;
                        const mimeType =
                            cloudDocument?.mimeType || 'application/pdf';
                        const safeName =
                            ensureDocType(filename, mimeType) ||
                            ensureDocType(name, mimeType) ||
                            'document.pdf';

                        fileDownload(data.data, safeName, mimeType);
                    }
                    return window.URL.createObjectURL(data.data);
                }
            })
            .catch(error => {
                dispatch({
                    type: types.DOWNLOAD_DOCUMENT_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'Download Documents');
                console.error(error);
            });
    };
}

export function deleteVersion(
    parentId: string,
    version: IcloudDocument
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };
        const url = `${API.cloudDocument.delete}/${version.id}`;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (data.status !== 200) {
                    throw new Error('Missing Data');
                } else {
                    dispatch({
                        type: types.REMOVE_DOCUMENT_SUCCESS,
                        payload: version,
                        parentId
                    });
                    toastr.success(
                        'Success',
                        `Version successfully deleted`,
                        constants.toastrSuccess
                    );
                }
            })
            .catch(error => {
                dispatch({
                    type: types.LOAD_DOCUMENTS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'Delete version');
                console.error(error);
            });
    };
}

export const updateDocumentFormValue = (formValues: {
    [key: string]: any;
}) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_DOCUMENT,
    formValues
});

export const setDocumentFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_MANAGE_DOCUMENT,
    formValues
});

export const updateVersionFormValue = (formValues: { [key: string]: any }) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_DOCUMENT_VERSION,
    formValues
});

export const setVersionFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_MANAGE_DOCUMENT_VERSION,
    formValues
});

export const setSelectedParentID = (id: string) => ({
    type: types.SET_SELECTED_PARENT_ID,
    id
});

export const clearSelectedParentID = () => ({
    type: types.CLEAR_SELECTED_PARENT_ID
});

export const setSelectedDocumentID = (id: string) => ({
    type: types.SET_SELECTED_DOCUMENT_ID,
    id
});

export const clearSelectedDocumentID = () => ({
    type: types.CLEAR_SELECTED_DOCUMENT_ID
});

export const toggleEditDocumentModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_DOCUMENT
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_DOCUMENT,
    filters
});
export const clearTableFilters = () => ({
    type: types.CLEAR_TABLE_FILTERS_MANAGE_DOCUMENT
});
