import { CSSObject } from 'highcharts';
import { TFunction } from 'i18next';
import moment from 'moment';

import {
    IchartBar,
    IchartGuage,
    IchartLine,
    IdataFormatPreference
} from '../../models';
import { constants } from '../../constants/constants';
import { dataUnitEnum } from '../../models-enums';
import { floatTrim } from './Analytics/AnalyticsCard';
import { initialChart } from '../../reducers/initialState';

const barLineLabelStyle: CSSObject = {
    color: '#a1acb3',
    fill: '#a1acb3'
};

export const trimmedData = (
    data: (number | [string | number, number | null] | null)[]
) =>
    data.map(value => {
        if (Array.isArray(value)) {
            return value.map(val =>
                typeof val === 'number' ? floatTrim(val) : value
            );
        }

        return value ? floatTrim(value) : value;
    });

export const getLastWeekDays = (data: Date) => {
    const lastWeekDays = [];
    const lastWeek = moment(data).subtract(7, 'days');

    for (let i = 0; i < 7; i++) {
        lastWeekDays.push(lastWeek.add(1, 'days').format('ddd'));
    }

    return lastWeekDays;
};

/**
 *
 ********************** GET UPDATED CHART DATA
 * used for setting chart options that will change based on chart data
 */

export const getBarChartOptions = (
    t: TFunction,
    dataFormatPreference: IdataFormatPreference,
    chart: IchartBar = initialChart,
    categories: string[] = []
): Highcharts.Options => {
    const { data, unit } = chart;

    return {
        series: [{ data, type: 'column', name: '1' }],
        title: {
            text: ''
        },
        yAxis: {
            labels: {
                style: barLineLabelStyle
            },
            title: {
                text: `Temperature in ${t(`units:${dataUnitEnum[unit]}`)}`,
                style: barLineLabelStyle
            }
        },
        xAxis: {
            categories
        }
    };
};

export const getLineChartOptions = (
    t: TFunction,
    dataFormatPreference: IdataFormatPreference,
    chart: IchartLine = initialChart
): Highcharts.Options => {
    const { data, unit } = chart;
    const date = new Date();
    const categories = getLastWeekDays(date);
    const fmtData = (data as number[]).map((value, index) => [
        categories[index],
        value
    ]);

    return {
        series: [{ data: fmtData, type: 'spline' }],
        title: {
            text: ''
        },
        tooltip: {
            formatter() {
                return `${this.point.y}`;
            }
        },
        legend: {
            enabled: false
        },
        yAxis: {
            labels: {
                style: barLineLabelStyle
            },
            title: {
                text: `Temperature in ${t(`units:${dataUnitEnum[unit]}`)}`,
                style: barLineLabelStyle
            }
        },
        xAxis: {
            categories
        }
    };
};

export const getGuageChartOptions = (
    t: TFunction,
    dataFormatPreference: IdataFormatPreference,
    guage: IchartGuage | undefined = initialChart
): Highcharts.Options => {
    const { data, unit } = guage;
    const label = t(
        `dashboardAnalytics:unitsMeasurement:${dataUnitEnum[unit]}`
    );
    const isDegree =
        unit === dataUnitEnum.Celsius || unit === dataUnitEnum.Fahrenheit;
    const degree = isDegree ? '°' : '';

    return {
        series: [
            {
                data: trimmedData(
                    unit === dataUnitEnum.PPM
                        ? data.map((value: any) => value * 1000000)
                        : data
                ),
                type: 'solidgauge',
                dataLabels: {
                    formatter() {
                        return `
                            <div class="guage-data-label" style="text-align:center">
                                <span style="font-size:14px;transform:translateY(-15px);" data-type="y-label">${this.y}${degree}</span>
                                <span style="font-size:12px;opacity:0.4;">${label}</span>
                            </div>
                        `;
                    }
                }
            }
        ],
        yAxis: {
            min: floatTrim(guage.min!),
            max: floatTrim(guage.max!),
            labels: {
                style: {
                    color: 'rgba(0, 0, 0, 0.6)',
                    fill: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    opacity: 0.6
                },
                y: 0
            }
        },
        title: {
            text: guage.label
        }
    };
};

/**
 *
 ********************* GET DEFAULT CHART DATA
 * Used for setting chart options that will not change
 */

export const getBarChartOptionsDefault = ({
    t,
    fill = '#7cb5ec'
}: {
    t: TFunction;
    fill?: string;
}): Highcharts.Options => {
    return {
        title: {
            text: 'Running Hrs',
            align: 'left',
            style: {
                fontWeight: '700',
                textTransform: 'uppercase',
                fontSize: '14px'
            }
        },
        xAxis: {
            labels: {
                style: barLineLabelStyle
            }
        },
        yAxis: {
            min: 0,
            labels: {
                overflow: 'justify'
            },
            title: {
                align: 'high'
            }
        },
        tooltip: {
            formatter() {
                return `${this.point.y}`;
            }
        },
        chart: {
            type: 'column',
            backgroundColor: '',
            height: '300px'
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        series: [
            {
                type: 'column',
                dataLabels: { enabled: false },
                maxPointWidth: 24,
                data: [] // pass an empty array initially because we use component state in order to build the data
            }
        ]
    };
};

export const getLineChartOptionsDefault = ({
    t
}: {
    t: TFunction;
}): Highcharts.Options => {
    return {
        title: {
            text: '',
            style: {
                fontWeight: '700',
                textTransform: 'uppercase',
                fontSize: '14px'
            }
        },
        subtitle: {
            text: '',
            style: {
                color: constants.colors.grey
            }
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%b %e'
            },
            labels: {
                style: barLineLabelStyle
            }
        },
        yAxis: {
            min: 0,
            title: { align: 'high' },
            labels: {
                overflow: 'justify'
            }
        },
        tooltip: {
            formatter() {
                return `${this.point.y} on ${moment(this.x, 'x').format(
                    'DD-MMM-YY'
                )}`;
            }
        },
        chart: {
            type: 'spline',
            backgroundColor: '',
            height: '250px'
        },
        credits: {
            enabled: false
        },
        legend: {
            itemStyle: {
                color: constants.colors.darkGreyText,
                fontSize: '.9em'
            }
        },
        series: [
            {
                type: 'spline',
                dataLabels: { enabled: false },
                color: constants.colors.orange,
                data: [] // pass an empty array initially because we use component state in order to build the data
            }
        ],
        plotOptions: {
            // series: {
            //     marker: {
            //         radius: 4,
            //         enabled: true
            //     }
            // }
            spline: {
                marker: {
                    radius: 4,
                    // lineColor: constants.colors.orange,
                    lineWidth: 1
                }
            }
        }
    };
};

export const getGuageChartOptionsDefault = ({
    t,
    fill = '#7cb5ec'
}: {
    t: TFunction;
    fill?: string;
}): Highcharts.Options => {
    return {
        colors: [fill],
        title: {
            text: '',
            align: 'left',
            style: {
                fontWeight: '700',
                textTransform: 'uppercase',
                fontSize: '12px'
            }
        },
        chart: {
            type: 'solidgauge',
            height: '170px'
        },
        pane: {
            center: ['50%', '50%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: [
                {
                    backgroundColor: '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            ]
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        // the value axis
        yAxis: {
            lineWidth: 0,
            min: 0,
            max: 100,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            labels: {
                distance: '80%',
                y: 16
            }
        },
        plotOptions: {
            solidgauge: {
                color: fill,
                dataLabels: {
                    y: 0,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        },
        credits: {
            enabled: false
        },
        series: [
            {
                type: 'solidgauge',
                data: []
            }
        ]
    };
};
