import * as React from 'react';
import { connect } from 'react-redux';
import { filter, isEmpty } from 'lodash';
import {
    GFQuizItem,
    GFLesson,
    GFCourse,
    LessonProgress,
    Iuser
} from '../../models';

import { saveLessonProgress } from '../../actions/trainingActions';

import {
    ListGroup,
    ListGroupItem,
    Media,
    Row,
    Col
    // Breadcrumb
} from 'react-bootstrap';
// const FontAwesome = require("react-fontawesome");
// const mixpanel = require("mixpanel-browser");

import { RouteComponentProps } from 'react-router';
import Player, { Options } from '@vimeo/player';
import * as moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { constants } from '../../constants/constants';
import { getQuizzesByLessonID } from '../../actions/trainingQuizActions';
import { getQuizLink } from '../../actions/trainingActions';
import { initialLesson } from '../../reducers/initialState';
import { throttle } from 'lodash';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface RouterParams {
    courseID: string;
    lessonID: string;
    quizID: string;
}

interface IdispatchProps {
    user: Iuser;
    courses: { [key: string]: GFCourse };
    lesson: GFLesson;
    lessons: { [key: string]: GFLesson };
    lessonQuizzes: GFQuizItem[];
    saveLessonProgress: typeof saveLessonProgress;
    getQuizzesByLessonID: typeof getQuizzesByLessonID;
    getQuizLink: typeof getQuizLink;
    loading: boolean;
    progress: LessonProgress;
    quizLink: { link: string; failedToLoad?: boolean };
}

type Props = RouteComponentProps<RouterParams>;

interface State {
    primaryVideo: string;
    waitingCount: number;
}

class Lesson extends React.Component<Props & IdispatchProps, State> {
    private player: Player | undefined;
    private pElem: HTMLDivElement | null;
    private timeSpent: number;
    private lastUpdate: number;
    private handlePlayerTimeUpdateThrottled: any;
    constructor(props: Props & IdispatchProps) {
        super(props);
        this.state = {
            primaryVideo: `https://player.vimeo.com/video/${this.props.lesson.primaryVideoPath}`,
            waitingCount: 0
        };
        this.timeSpent = constants.trainingVideoProgressSaveDelay; // set to the delay milliseconds for saving
        this.player = undefined;
        this.pElem = null;
        this.lastUpdate = 0;
        this.handlePlayerTimeUpdateThrottled = throttle(
            this.handlePlayerTimeUpdate,
            constants.trainingVideoProgressSaveDelay
        );
    }

    componentDidMount() {
        if (
            !this.props.match.params.lessonID ||
            !this.props.match.params.courseID
        ) {
            // no lesson id and no coursID is not allowed
            console.info('no lesson id or no coursID');
            this.props.history.replace(`/training`);
            return;
        }

        // Check to make sure we have already loaded courses, lessons and quizzes in redux
        if (isEmpty(this.props.courses) || isEmpty(this.props.lessons)) {
            console.info('have not loaded courses, lessons');
            this.props.history.push(`/training`);
            return;
        }
        this.props.getQuizzesByLessonID(
            this.props.match.params.lessonID,
            this.props.user
        );

        if (
            this.props?.lesson?.description
                ?.toLocaleLowerCase()
                .includes('{{test_link}}')
        )
            this.props.getQuizLink(this.props.match.params.courseID);

        if (this.props.lesson.primaryVideoPath.length > 0) {
            this.setUpPlayer();
        }
    }

    componentWillUnmount() {
        if (typeof this.player !== 'undefined') {
            this.player.off('play');
            this.player.off('pause');
            this.player.off('timeupdate');
            this.player.off('seeked');
        }
    }

    showTimedQuizMessage = (quiz: GFQuizItem) => {
        const toastrConfirmOptions = {
            onOk: () => {
                this.startQuiz(quiz, true);
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: 'start test',
            cancelText: 'cancel'
        };
        toastr.confirm(
            'This is a timed test. You have total of 3 attempts during 2 hours to complete/retake the test. Navigating away after starting the test counts as an attempt.',
            toastrConfirmOptions
        );
    };

    setUpPlayer = () => {
        if (this.pElem === null) {
            throw new Error('missing pElem');
        }
        const options: Options = {
            id: parseInt(this.props.lesson.primaryVideoPath, 10),
            width: this.pElem.offsetWidth, // > 723 ? this.pElem.offsetWidth : 723,
            loop: false
        };

        this.player = new Player('lessonPlayer', options);
        // this.player = new Player("lessonPlayer");
        this.player
            .ready()
            .then(() => {
                if (this.player === undefined) {
                    throw new Error('missing vimeo player DOM element');
                }
                // set up event listeners
                this.player.on('play', data => {
                    // console.info('PLAY:', data);
                    this.lastUpdate = 0;
                    this.handlePlayerTimeUpdate(data);
                });
                this.player.on('pause', data => {
                    // console.info('PAUSE:', data);
                    this.handlePlayerTimeUpdate(data);
                    this.lastUpdate = 0;
                });
                this.player.on('ended', data => {
                    this.handlePlayerEnded(data);
                });
                this.player.on('timeupdate', data => {
                    this.handlePlayerTimeUpdateThrottled(data);
                });

                if (
                    this.props.progress &&
                    this.props.progress.timeSpent &&
                    this.props.progress.currentTime
                ) {
                    console.info(this.props.progress);
                    // set timeSpent from previous progress
                    this.timeSpent = this.props.progress.timeSpent * 1000;
                    // seek to last played time
                    this.player.setCurrentTime(this.props.progress.currentTime);
                }
            })
            .catch(error => {
                console.error('Vimeo player failed to initialize.', error);
                toastr.error(
                    'Error',
                    'Video failed to load. Please try again or contact support.',
                    constants.toastrError
                );
            });
    };

    /*
     * start a quiz
     */
    startQuiz = (gfQuiz: GFQuizItem, skipTimedCheck = false) => {
        // set the current active quiz item
        // mixpanel.track("Practice Exercise started", {
        //   quiz: gfQuiz.id,
        //   name: gfQuiz.name
        // });

        if (gfQuiz.isTimed && !skipTimedCheck) {
            this.showTimedQuizMessage(gfQuiz);
            return;
        }

        const path = `/training/${this.props.match.params.courseID}/${this.props.match.params.lessonID}/${gfQuiz.id}`;
        this.props.history.push(path);
    };

    handlePlayerTimeUpdate = (data: any) => {
        console.info(
            'player time update received: ',
            this.lastUpdate,
            this.timeSpent
        );
        // increment our TimeSpent variable on each timeupdate event.
        // this will track total time spent playing the video, regardless of seeks, etc
        const now = moment.now();
        if (this.lastUpdate === 0) {
            this.lastUpdate = now;
        } else {
            this.timeSpent += now - this.lastUpdate;
            this.lastUpdate = now;
        }

        console.info(
            'saving progress: ',
            this.timeSpent * 0.001,
            this.lastUpdate
        );
        this.props.saveLessonProgress(
            data,
            this.props.match.params.lessonID,
            this.timeSpent
        );
    };

    handlePlayerEnded = (data: any) => {
        const now = moment.now();
        this.lastUpdate = now;
        this.props.saveLessonProgress(
            data,
            this.props.match.params.lessonID,
            this.timeSpent
        );
    };

    createLinkElement = () => {
        const { description } = this.props.lesson;
        const { link, failedToLoad } = this.props.quizLink;
        let replacementText = 'Loading link to quiz...';

        if (link)
            replacementText = `<a href="${this.props.quizLink.link}">Click here to take your exam</a>`;
        else if (!link && failedToLoad)
            replacementText = '<span>Your quiz failed to load.</span>';

        return description.replace('{{test_link}}', replacementText);
    };

    render() {
        return (
            <div className="main-content content-without-sidebar lesson animated fadeIn row">
                <Col xs={12}>
                    <Row className="lesson-description">
                        <Col
                            md={12}
                            sm={12}
                            xs={12}
                            dangerouslySetInnerHTML={{
                                __html: this.createLinkElement()
                            }}
                        />
                    </Row>
                    <Row className="lesson-list">
                        <Col
                            md={12}
                            sm={12}
                            xs={12}
                            className="firstVideo text-center"
                        >
                            <div
                                id="lessonPlayer"
                                className="vimeo-player"
                                ref={ref => (this.pElem = ref)}
                            />
                        </Col>
                    </Row>
                    <ListGroup className="lesson-list">
                        {this.props.lessonQuizzes.map((gfQuiz, index) => {
                            let gfImage = gfQuiz.imagePath;
                            if (
                                gfQuiz.imagePath === null ||
                                gfQuiz.imagePath === ''
                            ) {
                                gfImage = require('../../images/Azure.png');
                            }
                            return (
                                <ListGroupItem
                                    key={gfQuiz.id}
                                    onClick={() => {
                                        this.startQuiz(gfQuiz);
                                    }}
                                >
                                    <Media>
                                        <Media.Left>
                                            <img
                                                width={32}
                                                height={32}
                                                src={gfImage}
                                                alt="quiz-img"
                                            />
                                        </Media.Left>
                                        <Media.Body>
                                            <Media.Heading>
                                                {gfQuiz.name}
                                            </Media.Heading>
                                        </Media.Body>
                                        {gfQuiz.isComplete === true && (
                                            <Media.Right>
                                                {/* <FontAwesome name="circle 2x blue" /> */}
                                            </Media.Right>
                                        )}
                                    </Media>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                </Col>
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Props) => {
    const lesson =
        state.training.lessonsByID[ownProps.match.params.lessonID] ||
        initialLesson;
    const lessonQuizzes =
        filter(state.training.quizzesByID, {
            lessonID: ownProps.match.params.lessonID
        }) || [];

    return {
        user: state.user,
        lessons: state.training.lessonsByID,
        courses: state.training.coursesByID,
        lesson,
        lessonQuizzes,
        progress: state.training.lessonProgress[ownProps.match.params.lessonID],
        loading: selectIsLoading(state),
        quizLink: state.training.quizLink
    };
};

export default connect(mapStateToProps, {
    saveLessonProgress,
    getQuizzesByLessonID,
    getQuizLink
})(Lesson);
