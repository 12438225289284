/*
 * The Manage Team user modal - Container
 */

import { connect } from 'react-redux';

import {
    deleteTeamUser,
    saveTeamUser,
    toggleEditTeamUserModal,
    updateTeamUser,
    setEditUserFormValues,
    updateEditUserFormValues
} from '../../actions/manageTeamActions';
import {
    getFacilitiesByCountry,
    searchFacilitiesForAsyncSelect
} from '../../actions/commonActions';
import EditTeamMemberForm from './EditTeamMemberForm';
import { selectFacilityOptionsWithAddress } from '../../reducers/facilitiesReducer';
import { IeditTeamMemberFormValues } from '../../modelsForms';
import { initialUser } from '../../reducers/initialState';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: any;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const formValues = state.manageTeam
        .editUserFormValues as IeditTeamMemberFormValues;
    const selectedUser =
        state.manageTeam.teamMembersByID[state.manageTeam.selectedUserID] ||
        initialUser;

    const modalTitle = selectedUser
        ? ownProps.t('manageTeam:editTeamModalTitle')
        : ownProps.t('manageTeam:saveTeamModalTitle');

    return {
        user: state.user,
        userManage: state.manageTeam,
        loading: selectIsLoading(state),
        facilityOptionsWithAddress: selectFacilityOptionsWithAddress(state),
        show: state.manageTeam.showEditTeamModal,
        formValues,
        selectedUser,
        className: 'user-edit',
        title: modalTitle
    };
};

export default withTranslation('user')(
    connect(mapStateToProps, {
        updateTeamUser,
        saveTeamUser,
        toggleModal: toggleEditTeamUserModal,
        onHide: toggleEditTeamUserModal,
        onCancel: toggleEditTeamUserModal,
        getFacilitiesByCountry,
        deleteTeamUser,
        setFormValues: setEditUserFormValues,
        updateFormValues: updateEditUserFormValues,
        searchFacilitiesForAsyncSelect
    })(WithCommonModal(EditTeamMemberForm))
);
