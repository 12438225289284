/*
 * Edit alert Form
 * Add and Edit alerts
 *
 */

import * as moment from 'moment';
import * as React from 'react';

import { debounce, omit } from 'lodash';
import { Button, Col } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    Validators
} from 'react-reactive-form';
import {
    addWorkOrder,
    clearSelectedWorkOrderID,
    deleteWorkOrder,
    toggleEditWorkOrderModal,
    updateWorkOrder,
    updateWorkOrderFormValue
} from '../../actions/manageWorkOrderActions';
import { IWorkOrder, Ioption } from '../../models';
import {
    workOrderPrioritiesEnum,
    workOrderStatusEnum,
    workOrderVendorsEnum
} from '../../models-enums';

import { TFunction } from 'i18next';
import { toastr } from 'react-redux-toastr';
import { constants } from '../../constants/constants';
import { Iproduct } from '../../models';
import { IworkOrderFormValues } from '../../modelsForms';
import { initialWorkOrder } from '../../reducers/initialState';
import { AddMultiControl, ImultiControlMeta } from '../common/AddMultiControl';
import CommonModal from '../common/CommonModal';
import { FormUtil } from '../common/FormUtil';
import CommonMobileModal, {
    MobileButtonGroup
} from '../common/mobile/CommonMobileModal';

const uuidv4 = require('uuid/v4');

interface Iprops {
    addWorkOrder: typeof addWorkOrder;
    updateWorkOrder: typeof updateWorkOrder;
    deleteWorkOrder: typeof deleteWorkOrder;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    selectedWorkOrder: IWorkOrder;
    clearSelectedWorkOrderID: typeof clearSelectedWorkOrderID;
    updateFormValue: (formValue: { [key: string]: any }) => any;
    setFormValues: (formValues: { [key: string]: any }) => any;
    formValues: IworkOrderFormValues;
    toggleEditWorkOrderModal: typeof toggleEditWorkOrderModal;
    selectedProduct: Iproduct;
    standardOptions: Ioption[];
    selectedInstallBaseID: string;
    locationString?: string;
    disabled?: boolean;
    shouldDisableVendorSelect?: boolean;
    shouldHideTechnicianSelect?: boolean;
    shouldHideDelete?: boolean;
    isMobile: boolean;
    title: string;
    show: boolean;
    secondModal?: boolean;
}

interface State {
    file1: any;
    file2: any;
    file3: any;
    fieldConfig: FieldConfig;
}

class EditWorkOrderForm extends React.Component<Iprops, State> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValueDebounced: typeof updateWorkOrderFormValue;
    static defaultProps = {
        selectedWorkOrder: initialWorkOrder
    };
    constructor(props: Iprops) {
        super(props);
        this.state = {
            file1: '',
            file2: '',
            file3: '',
            fieldConfig: this.buildFieldConfig()
        };
        this.updateFormValueDebounced = debounce(
            this.props.updateFormValue,
            constants.formDebounceTime
        );
    }

    componentDidMount() {
        this.setState({
            fieldConfig: this.buildFieldConfig(this.itemToFormValues())
        });
        this.props.setFormValues(this.itemToFormValues());
    }

    componentDidUpdate(prevProps: Iprops, prevState: State) {
        if (prevProps.formValues.vendor !== this.props.formValues.vendor) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
        if (prevProps.formValues.parts !== this.props.formValues.parts) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }

        if (
            prevProps.selectedWorkOrder.id !== this.props.selectedWorkOrder.id
        ) {
            this.props.setFormValues(this.itemToFormValues());
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.setFormValues({});
        this.props.clearSelectedWorkOrderID();
    }

    onFileChange = (key: 'file1' | 'file2' | 'file3', file: File) => {
        this.setState({ [key]: file } as any, () => {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        });
    };

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IworkOrderFormValues => {
        const { t } = this.props;
        const { vendor, priority, status } = this.props.selectedWorkOrder;
        const selectedVendor = {
            value: vendor,
            label: t(workOrderVendorsEnum[vendor])
        };
        const selectedPriority = {
            value: priority,
            label: t(workOrderPrioritiesEnum[priority])
        };
        const selectedStatus = {
            value: status,
            label: t(workOrderStatusEnum[status])
        };
        const cleanedWorkOrder = omit(this.props.selectedWorkOrder, [
            'preventativeMaintenanceChecklist',
            'jobWorkOrders',
            'facility'
        ]);

        // Closing notes are saved as a JSON object (not sure why), so convert it to a JSON object, then pull out the actual Text value
        const workOrderWithCleanedNotes = {
            ...cleanedWorkOrder,
            closingNotes: cleanedWorkOrder.closingNotes
                ? JSON.parse(cleanedWorkOrder.closingNotes)[0]?.Text
                : ''
        };

        return {
            ...workOrderWithCleanedNotes,
            vendor: selectedVendor,
            priority: selectedPriority,
            status: selectedStatus,
            product: undefined
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (): IWorkOrder => {
        const cleanedWorkOrder = omit(this.props.selectedWorkOrder, [
            'facility',
            'preventativeMaintenanceChecklist',
            'jobWorkOrders'
        ]);
        const formValues = FormUtil.getValues(this.formGroup.value);
        return {
            ...cleanedWorkOrder,
            ...formValues,
            parts: this.formGroup.value.parts,
            installBaseID:
                this.props.selectedWorkOrder.installBaseID ||
                this.props.selectedInstallBaseID,
            file1: this.state.file1,
            file2: this.state.file2,
            file3: this.state.file3
        };
    };

    buildMultiControlConfig = () => {
        const itemEditFieldConfig = {
            controls: {}
        };

        return itemEditFieldConfig;
    };

    buildFieldConfig = (
        defaultValues: IworkOrderFormValues = this.props.formValues
    ) => {
        // disable if prop is disabled or if the work order is complete
        const disabled =
            this.props.disabled === true ||
            this.props.selectedWorkOrder.isDeleted === true ||
            this.props.selectedWorkOrder.status ===
                workOrderStatusEnum.complete;

        const shouldHideTechnician =
            defaultValues.vendor &&
            (defaultValues.vendor.value === workOrderVendorsEnum.beacon ||
                defaultValues.vendor.value === workOrderVendorsEnum.other) &&
            this.props.shouldHideTechnicianSelect !== false;

        let due_date = defaultValues.dueDate as string | moment.Moment;
        // if the dueDate is not a moment and there is no id, we are creating an untouched new work order and default to a month from today
        if (
            moment.isMoment(defaultValues.dueDate) === false &&
            this.props.selectedWorkOrder.id.length === 0 &&
            typeof defaultValues.dueDate === 'string'
        ) {
            due_date = moment.utc(defaultValues.dueDate).add(1, 'month');
        }

        const itemEditFieldConfig = this.buildMultiControlConfig();

        const fieldConfigControls = {
            productInformation: {
                render: FormUtil.TextLabel,
                meta: {
                    label: 'productInfo',
                    colWidth: 12,
                    name: 'information'
                },
                formState: {
                    value:
                        defaultValues.productName ||
                        this.props.selectedProduct.name,
                    disabled
                }
            },
            $field_1: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: this.props.t('location'),
                            colWidth: 12,
                            value:
                                defaultValues.installBaseLocation ||
                                this.props.locationString,
                            style: { marginTop: '15px' }
                        }}
                    />
                )
            },
            number: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'woNumber',
                    colWidth: 12,
                    // autoFocus: true,
                    required: false,
                    name: 'number'
                },
                formState: { value: defaultValues.number, disabled }
            },
            /*
             * vendor is disabled when on the beacon tab.  In other words the Vendor select is only enabled on the Repair tab.
             */
            vendor: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        workOrderVendorsEnum
                    ),
                    label: 'vendor',
                    colWidth: 12,
                    name: 'vendor',
                    required: true,
                    isClearable: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.vendor,
                    disabled: disabled || this.props.shouldDisableVendorSelect
                }
            },
            /*
             * TODO Technician should show when on Beacon tab. And on the Repair tab when the selected vendor is Internal.  Need to double check this logic
             */
            technician: {
                // options: {
                //   validators: [FormUtil.validators.requiredWithTrim, Validators.maxLength(25)]
                // },
                render: FormUtil.TextInput,
                meta: {
                    label: 'tehnician',
                    colWidth: 12,
                    // autoFocus: true,
                    required: false,
                    name: 'tehnician',
                    style: shouldHideTechnician ? { display: 'none' } : {}
                },
                formState: {
                    value: defaultValues.technician,
                    disabled
                }
            },
            closingNotes: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'closingNote',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 5,
                    name: 'closingNote',
                    initialContent: defaultValues.closingNotes,
                    style:
                        defaultValues.status &&
                        defaultValues.status.value ===
                            workOrderStatusEnum.complete
                            ? {}
                            : { display: 'none' }
                },
                formState: { value: defaultValues.closingNotes, disabled }
            },
            activityDescription: {
                render: FormUtil.TextInput,
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                meta: {
                    label: 'activityDescription',
                    colWidth: 12,
                    name: 'notes',
                    componentClass: 'textarea',
                    rows: 5,
                    initialContent: defaultValues.activityDescription,
                    required: true
                },
                formState: {
                    value: defaultValues.activityDescription,
                    disabled
                }
            },
            notes: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'notes',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 5,
                    name: 'notes',
                    initialContent: defaultValues.notes,
                    required: true
                },
                formState: { value: defaultValues.notes, disabled }
            },
            manageParts: {
                render: AddMultiControl,
                meta: {
                    label: 'labelParts',
                    buttonLabel: '',
                    colWidth: 12,
                    colorButton: this.props.colorButton,
                    t: this.props.t,
                    modalTitle: '',
                    getItemTitle: item =>
                        `(${item.estimated}) ${item.part?.number} - ${item.part?.description}`,
                    disableDelete: true,
                    disableEdit: true,
                    actions: [],
                    itemEditFieldConfig,
                    readonly: true
                } as ImultiControlMeta,
                formState: { value: defaultValues.parts, disabled }
            },
            contactInfo: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'contactinfo',
                    colWidth: 12,
                    name: 'contactInfo'
                },
                formState: { value: defaultValues.contactInfo, disabled },
                options: {
                    validators: Validators.pattern(
                        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/ //eslint-disable-line
                    )
                }
            },
            dueDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'dueDate',
                    colWidth: 6,
                    showTime: false,
                    name: 'expiration-date',
                    placeholder: 'dd-mmm-yy'
                },
                formState: {
                    value: due_date,
                    disabled
                },
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        FormUtil.validators.isValidMoment
                    ]
                }
            },
            priority: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        workOrderPrioritiesEnum
                    ),
                    label: 'priority',
                    colWidth: 6,
                    name: 'priority',
                    shouldTranslate: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: defaultValues.priority,
                    disabled
                }
            },

            status: {
                render: FormUtil.Select,
                meta: {
                    options: this.buildStatusOptions(),
                    label: 'status',
                    colWidth: 12,
                    name: 'status',
                    shouldTranslate: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: defaultValues.status,
                    disabled: this.shouldDisableStatusSelect()
                }
            },
            file1: {
                render: FormUtil.FileInput,
                meta: {
                    accept:
                        '.xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip',
                    type: 'file',
                    label: 'manageWorkOrder:addFile',
                    otherLabels: {
                        labelReplace: 'manageWorkOrder:replaceFile',
                        labelDownload: 'manageWorkOrder:labelDownload'
                    },
                    colWidth: 12,
                    name: 'file1',
                    required: true,
                    onChange: this.onFileChange,
                    imageUrl: defaultValues.attachmentUrl1,
                    fileName: this.state ? this.state.file1.name : '',
                    hasDownload: true
                }
            },
            file2: {
                render: FormUtil.FileInput,
                meta: {
                    accept:
                        '.xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip',
                    type: 'file',
                    label: 'manageWorkOrder:addFile',
                    otherLabels: {
                        labelReplace: 'manageWorkOrder:replaceFile',
                        labelDownload: 'manageWorkOrder:labelDownload'
                    },
                    colWidth: 12,
                    name: 'file2',
                    required: true,
                    onChange: this.onFileChange,
                    imageUrl: defaultValues.attachmentUrl2,
                    fileName: this.state ? this.state.file2.name : '',
                    hasDownload: true
                }
            },
            file3: {
                render: FormUtil.FileInput,
                meta: {
                    accept:
                        '.xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip',
                    type: 'file',
                    label: 'manageWorkOrder:addFile',
                    otherLabels: {
                        labelReplace: 'manageWorkOrder:replaceFile',
                        labelDownload: 'manageWorkOrder:labelDownload'
                    },
                    colWidth: 12,
                    name: 'file3',
                    required: true,
                    onChange: this.onFileChange,
                    imageUrl: defaultValues.attachmentUrl3,
                    fileName: this.state ? this.state.file3.name : '',
                    hasDownload: true
                }
            }
        };

        // hide the status when creating new
        if (
            !(
                this.props.selectedWorkOrder &&
                this.props.selectedWorkOrder.id.length
            )
        ) {
            delete fieldConfigControls.status;
        }

        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    /*
     * status = completed: the only selectable option should be "reopened"
     * status = reopened: empty
     * status = new: empty
     */
    buildStatusOptions = () => {
        const { status } = this.props.selectedWorkOrder;
        if (status === workOrderStatusEnum.complete) {
            return [
                {
                    value: `${workOrderStatusEnum.reopened}`,
                    label: this.props.t('reopened')
                }
            ];
        } else {
            return [];
        }
    };

    /*
     * status select should only be enabled if creating new or if the WO is completed
     */
    shouldDisableStatusSelect = () => {
        if (this.props.selectedWorkOrder.id.length === 0) {
            return false;
        } else if (
            this.props.selectedWorkOrder.status === workOrderStatusEnum.complete
        ) {
            return false;
        } else {
            return true;
        }
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        this.updateFormValueDebounced({
            ...this.props.formValues,
            [key]: value
        });
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.info(this.formGroup.value);
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        // TODO if the vendor is Beacon or Other, clear out the technician

        const tempWorkOrder = this.formValuesToItem();

        if (!this.props.selectedWorkOrder.id.length) {
            // NEW
            const workOrderID = uuidv4();
            this.props.addWorkOrder({
                ...tempWorkOrder,
                id: workOrderID
            });
            this.props.toggleEditWorkOrderModal();
        } else {
            // EDIT
            this.props.updateWorkOrder({ ...tempWorkOrder });
            this.props.toggleEditWorkOrderModal();
        }
    };

    handleDelete = () => {
        let deletedItem = this.props.selectedWorkOrder;

        const toastrConfirmOptions = {
            onOk: () => {
                deletedItem = {
                    ...deletedItem
                };
                this.props.deleteWorkOrder(deletedItem);
                this.props.toggleEditWorkOrderModal();
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('deleteOk'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deleteConfirm'), toastrConfirmOptions);
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    cleanForm = () => {
        this.formGroup.reset();
    };

    shouldShowDeleteButton = () => {
        const { selectedWorkOrder } = this.props;
        return (
            selectedWorkOrder &&
            selectedWorkOrder.id &&
            selectedWorkOrder.isDeleted !== true &&
            selectedWorkOrder.status !== workOrderStatusEnum.complete &&
            this.props.disabled !== true &&
            this.props.shouldHideDelete !== true
        );
    };

    render() {
        const { t, secondModal, isMobile, title, show } = this.props;
        if (isMobile) {
            return (
                <form
                    onSubmit={this.handleSubmit}
                    className="clearfix beacon-form"
                >
                    <CommonMobileModal
                        show={show}
                        onHide={this.props.toggleEditWorkOrderModal}
                        className={''}
                        title={title}
                        footer={
                            <MobileButtonGroup>
                                <Button
                                    bsStyle="default"
                                    type="button"
                                    className="pull-left"
                                    onClick={
                                        this.props.toggleEditWorkOrderModal
                                    }
                                >
                                    {t('common:cancel')}
                                </Button>
                                {this.shouldShowDeleteButton() && (
                                    <Button
                                        bsStyle="danger"
                                        type="button"
                                        onClick={this.handleDelete}
                                    >
                                        {t('common:delete')}
                                    </Button>
                                )}
                                {this.props.selectedWorkOrder.isDeleted ===
                                    false && (
                                    <Button
                                        bsStyle={this.props.colorButton}
                                        type="submit"
                                        disabled={this.props.loading}
                                    >
                                        {t('save')}
                                    </Button>
                                )}
                            </MobileButtonGroup>
                        }
                    >
                        <FormGenerator
                            onUnmount={this.cleanForm}
                            onMount={this.setForm}
                            fieldConfig={this.state.fieldConfig}
                        />
                    </CommonMobileModal>
                </form>
            );
        }

        return (
            <CommonModal
                secondModal={secondModal}
                show={show}
                onHide={this.props.toggleEditWorkOrderModal}
                className={''}
                title={title}
            >
                <form
                    onSubmit={this.handleSubmit}
                    className="clearfix beacon-form"
                >
                    <FormGenerator
                        onUnmount={this.cleanForm}
                        onMount={this.setForm}
                        fieldConfig={this.state.fieldConfig}
                    />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.props.toggleEditWorkOrderModal}
                        >
                            {t('common:cancel')}
                        </Button>
                        {this.shouldShowDeleteButton() && (
                            <Button
                                bsStyle="danger"
                                type="button"
                                style={{ marginRight: '20px' }}
                                onClick={this.handleDelete}
                            >
                                {t('common:delete')}
                            </Button>
                        )}

                        {this.props.selectedWorkOrder.isDeleted === false && (
                            <Button
                                bsStyle={this.props.colorButton}
                                type="submit"
                                disabled={this.props.loading}
                            >
                                {t('save')}
                            </Button>
                        )}
                    </Col>
                </form>
            </CommonModal>
        );
    }
}
export default EditWorkOrderForm;
