import * as React from 'react';

import { Column, RowRenderProps } from 'react-table';
import { Ifacility, IinstallBase } from '../../models';
import { find, map } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';

import FolderIcon from '../../images/folder.svg';

export const TableUtil = {
    translateHeaders: (columns: Column[], t: TFunction) => {
        return map(columns, column => {
            if (column.Header && typeof column.Header === 'string') {
                return { ...column, Header: t(column.Header) };
            }
            return column;
        });
    },
    /*
     * take the install and find the names for the location id's.  building, floor, locations, rooms, position
     */
    buildLocation: (install: IinstallBase, facility: Ifacility) => {
        let locationString = '';
        const building = find(facility.buildings, {
            id: install.buildingID
        });

        if (building) {
            locationString += building.name;
            const floor = find(building.floors, { id: install.floorID });
            if (floor) {
                locationString += `: ${floor.name}`;
                const location = find(floor.locations, {
                    id: install.locationID
                });
                if (location) {
                    locationString += `: ${location.name}`;
                    const room = find(location.rooms, { id: install.roomID });
                    if (room) {
                        locationString += `: ${room.name}`;
                    }
                }
            }
        }
        if (install.position) {
            locationString += `: ${install.position}`;
        }
        return locationString;
    },

    expanderToggleFolder: (props: RowRenderProps) => {
        let style = { transform: 'rotate(-90deg)' };

        if (props.isExpanded) {
            style = { transform: 'rotate(0)' };
        }

        const folderStyle: React.CSSProperties = {
            marginRight: 5,
            marginBottom: 5
        };

        return (
            <span>
                <FontAwesomeIcon
                    icon="chevron-down"
                    style={style}
                    className="beacon-expander-toggle"
                />
                <img
                    src={FolderIcon}
                    alt=""
                    style={folderStyle}
                    aria-hidden="true"
                    className="expander-folder"
                />
            </span>
        );
    },

    expanderToggle: (props: RowRenderProps) => {
        let style = { transform: 'rotate(-90deg)' };

        if (props.isExpanded) {
            style = { transform: 'rotate(0)' };
        }

        return (
            <span>
                <FontAwesomeIcon
                    icon="chevron-down"
                    style={style}
                    className="beacon-expander-toggle"
                />
            </span>
        );
    }
};
