import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import {
    FormArray,
    FormGenerator,
    FormGroup,
    GroupProps
} from 'react-reactive-form';
import { IcloudDocument } from '../../../models';
import { clearSelectedDocumentID } from '../../../actions/manageDocumentActions';
import { setSelectedDocumentTypeID } from '../../../actions/manageDocumentTypeActions';

import { FormUtil } from '../../common/FormUtil';
import { constants } from '../../../constants/constants';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { TFunction } from 'i18next';
import CommonModal from '../../common/CommonModal';
import CommonMobileModal, {
    MobileButtonGroup
} from '../../common/mobile/CommonMobileModal';
import { create, edit } from '../utils/ManageDocumentUtils';

const uuid4 = require('uuid/v4');

interface Iprops {
    handleCancel: any;
    loading: boolean;
    colorButton: string;
    selectedDocument: IcloudDocument;
    parentId: string;
    parentName: string;
    clearSelectedDocumentID: typeof clearSelectedDocumentID;
    updateFormValue: (formValue: { [key: string]: any }) => void;
    setFormValues: (formValues: { [key: string]: any }) => void;
    formValues: { [key: string]: any };
    documentTypeOptions: any[];
    isVersionForm?: boolean;
    setSelectedDocumentTypeID: typeof setSelectedDocumentTypeID;
    selectedFacilityID: string;
    canEditDocuments: boolean;
    canAddDocuments: boolean;
    t: TFunction;
    show: boolean;
    title: string;
    className: string;
    isMobile: boolean;
    dispatch: Function;
    isEditForm: boolean;
    isNewForm: boolean;
    documentID?: string;
}

interface IState {
    file: any;
}

const EditDocumentLibraryForm: React.FC<Iprops> = (props: Iprops) => {
    const [form, setForm] = React.useState<FormGroup | FormArray>();
    const [state, setState] = React.useState<IState>({
        file: ''
    });

    const clearFile = () => setState({ file: '' });

    const onFileChange = (key: string, file: File) => {
        if (file.size > 100000000) {
            toastr.error(
                props.t('manageDocument:fileSizeWarning'),
                '',
                constants.toastrError
            );
            return;
        }

        setState({ ...state, file });
    };

    const fieldConfig = React.useMemo(() => {
        const formValues = props.formValues;

        const disabled = props.canEditDocuments === false;
        const countryDisabled = props.canAddDocuments === false;
        let {
            name,
            cloudDocumentTypeID,
            documentDate,
            filename,
            countryID
        } = props.selectedDocument;

        name = formValues.name || name || props.parentName;
        cloudDocumentTypeID = formValues.cloudDocumentTypeID
            ? formValues.cloudDocumentTypeID.value
            : cloudDocumentTypeID;
        filename = formValues.filename ? formValues.filename : filename;
        documentDate = formValues.documentDate || documentDate;
        countryID = formValues.countryID || countryID;
        const selectedType =
            props.documentTypeOptions.find(
                option =>
                    option.value === cloudDocumentTypeID ||
                    option.label === cloudDocumentTypeID
            ) || '';

        const fieldConfigControls = {
            name: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: props.parentId
                        ? 'manageDocument:versionName'
                        : 'manageDocument:name',
                    colWidth: 12,
                    type: 'input',
                    autoFocus: true,
                    name: 'document-name'
                },
                formState: { value: name, disabled }
            },
            cloudDocumentTypeID: {
                render: FormUtil.Select,
                meta: {
                    options: props.documentTypeOptions,
                    label: 'manageDocument:type',
                    colWidth: 12,
                    placeholder: 'manageDocument:selectTypePlaceholder',
                    isMulti: false,
                    required: true
                },
                options: { validators: [FormUtil.validators.requiredWithTrim] },
                formState: { value: selectedType, disabled }
            },
            documentDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'manageDocument:date',
                    colWidth: 12,
                    showTime: false,
                    name: 'document-date',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        FormUtil.validators.isValidMoment
                    ]
                },
                formState: {
                    value: documentDate,
                    disabled
                }
            },
            countryID: {
                render: FormUtil.Select,
                meta: {
                    options: constants.countries,
                    label: 'manageDocument:countryWithHelper',
                    colWidth: 12,
                    placeholder: 'manageDocument:countrySearchPlaceholder',
                    name: 'country',
                    className: 'doc-country-select'
                },
                formState: { value: countryID, disabled: countryDisabled }
            },
            file: {
                render: FormUtil.FileInput,
                meta: {
                    accept:
                        '.xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip',
                    type: 'file',
                    label: 'manageDocument:upload',
                    colWidth: 12,
                    name: 'document-file',
                    required: true,
                    onChange: onFileChange,
                    fileName: filename
                }
            }
        } as { [key: string]: GroupProps };

        let fieldsConfig = {
            controls: { ...fieldConfigControls }
        };

        // don't show the file control when editing.  Users must add a new version in order to add a new file.
        if (props.isEditForm) {
            fieldsConfig = {
                controls: {
                    name: fieldConfigControls.name,
                    cloudDocumentTypeID: fieldConfigControls.cloudDocumentTypeID
                }
            };
        } else if (props.isVersionForm) {
            fieldsConfig = {
                controls: {
                    name: fieldConfigControls.name,
                    documentDate: fieldConfigControls.documentDate,
                    file: fieldConfigControls.file
                }
            };
        }

        return FormUtil.translateForm(fieldsConfig, props.t);
    }, [
        props.selectedDocument,
        props.documentTypeOptions.length,
        props.isVersionForm,
        props.isEditForm,
        props.isNewForm,
        props.parentId
    ]);

    React.useEffect(() => {
        if (props.selectedDocument.documentID !== '') {
            props.setFormValues({
                documentID: props.selectedDocument.documentID
            });
        }
    }, [props.selectedDocument]);

    const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!form) {
            return;
        }

        if (form.status === 'INVALID') {
            form.markAsSubmitted();
            toastr.error(
                props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        let formData = {
            ...form.value,
            facilityID: props.selectedFacilityID,
            cloudDocumentTypeID: form.value.cloudDocumentTypeID
                ? form.value.cloudDocumentTypeID.value
                : '',
            documentDate: form.value.documentDate
                ? moment.utc(form.value.documentDate).toISOString()
                : ''
        };

        if (props.selectedDocument.documentID) {
            formData = {
                ...form.value,
                cloudDocumentTypeID: form.value.cloudDocumentTypeID.value
            };
        }

        if (state.file) {
            formData['file'] = state.file;
        } else if (!props.selectedDocument.documentID) {
            toastr.error(
                'Error',
                'Please select a file.',
                constants.toastrError
            );
            return;
        }

        if (props.isVersionForm) {
            formData['documentID'] = props.documentID;
        } else {
            formData['id'] = props.selectedDocument.id;
        }

        if (formData.id) {
            formData.file = undefined;
            edit(
                formData,
                props.selectedDocument,
                props.parentId,
                props.dispatch
            )
                .then(e => {
                    clearFile();
                })
                .catch(e => {
                    console.error('error creating doc', e);
                });
        } else {
            create(formData, props.dispatch, props.parentId)
                .then(e => {
                    clearFile();
                })
                .catch(e => {
                    console.error('error creating doc', e);
                });
        }
    };

    const setFormContent = (formGroup: FormGroup | FormArray) => {
        formGroup.meta = {
            loading: props.loading
        };
        setForm(formGroup);
    };

    const handleClose = () => {
        clearFile();
        props.handleCancel();
        props.setFormValues({});
    };

    const Buttons = () => {
        return (
            <>
                {' '}
                <Button
                    bsStyle="default"
                    type="button"
                    className="pull-left"
                    onClick={() => {
                        props.handleCancel();
                        props.setFormValues({});
                    }}
                >
                    {props.t('common:cancel')}
                </Button>
                <Button
                    bsStyle={props.colorButton}
                    type="submit"
                    disabled={props.loading || props.canEditDocuments === false}
                >
                    {props.t('common:save')}
                </Button>
            </>
        );
    };

    if (props.isMobile) {
        return (
            <form onSubmit={handleSubmit} className="clearfix beacon-form">
                <CommonMobileModal
                    footer={
                        <MobileButtonGroup>
                            <Buttons />
                        </MobileButtonGroup>
                    }
                    show={props.show}
                    onHide={handleClose}
                    className={''}
                    title={props.title}
                >
                    <FormGenerator
                        onMount={setFormContent}
                        fieldConfig={fieldConfig}
                    />
                </CommonMobileModal>
            </form>
        );
    }

    return (
        <CommonModal
            show={props.show}
            onHide={handleClose}
            className={props.className}
            title={props.title}
        >
            <form onSubmit={handleSubmit} className="clearfix beacon-form">
                <FormGenerator
                    onMount={setFormContent}
                    fieldConfig={fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Buttons />
                </Col>
            </form>
        </CommonModal>
    );
};

export default EditDocumentLibraryForm;
