import React, { useEffect, useState } from 'react';
import { IUserTrainingDataProps } from './UserTrainingDataModal';
import { Button, Col, ListGroup, Row, Well } from 'react-bootstrap';
import { WithTranslation } from 'react-i18next';
import { IPropsCommonModal } from './CommonModal';
import { isEmpty } from 'lodash';
import { AxiosRequestConfig } from 'axios';
import API from '../../constants/apiEndpoints';
import { msalFetch } from '../auth/Auth-Utils';
import { ITrainingItem } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserTrainingDataListView: React.FC<IUserTrainingDataProps &
    WithTranslation &
    IPropsCommonModal> = ({ onHide, userId, t }) => {
    const [tableData, setTableData] = useState<ITrainingItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        // Perform the API call
        const fetchData = async () => {
            try {
                const url = API.GET.training.classMarker(userId);
                const response = await msalFetch(url, {
                    method: 'get'
                } as AxiosRequestConfig);
                const data = response.data;

                // Update the tableData state with the received data
                setTableData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [userId]); // Run the effect whenever the userId prop changes

    const TrainingListItem = ({ entity }: { entity: ITrainingItem }) => {
        const className = 'list-group-item new-product-item';
        return (
            <li className={className}>
                <h4>
                    <a
                        href={entity.certificateUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {entity.testName}
                    </a>
                </h4>
                <h4>Score: {entity.score}</h4>
                <h4>
                    Passed:{' '}
                    <FontAwesomeIcon
                        color={entity.passed ? 'green' : 'red'}
                        icon={['far', entity.passed ? 'check' : 'times']}
                    />
                </h4>
            </li>
        );
    };

    return (
        <div>
            <p
                style={{
                    marginLeft: '15px',
                    marginRight: '15px',
                    lineHeight: '1.5rem'
                }}
            >
                {t('trainingHistory')}
            </p>
            <Row>
                <Col xs={12}></Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <ListGroup className="beacon-list-group">
                        {tableData.map((entity: ITrainingItem) => (
                            <TrainingListItem
                                key={entity.testName}
                                entity={entity}
                            />
                        ))}
                    </ListGroup>
                    {isEmpty(tableData) && (
                        <Col xs={12}>
                            <Well className="text-center">
                                {t('noUserTrainingFound')}
                            </Well>
                        </Col>
                    )}
                </Col>
            </Row>
            <Col xs={12} className="form-buttons text-right">
                <Button
                    bsStyle="default"
                    type="button"
                    className="pull-left"
                    onClick={onHide}
                >
                    {t('common:cancel')}
                </Button>
                <button type="submit" style={{ display: 'none' }} />
            </Col>
        </div>
    );
};

export default UserTrainingDataListView;
