/*
 * Header simply displays the header on all pages
 */

import * as React from 'react';
import HeaderMenu from './HeaderMenu';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsMobile } from '../../reducers/configReducer';
import HeaderMenuMobile from './mobile/HeaderMenuMobile';

const headerImg = require('../../images/BeaconLogo@2x.png');

const Header = (props: any) => {
    const isMobile = useSelector(getIsMobile);

    return (
        <div className="header">
            <Link to={'/dashboard'}>
                <img src={headerImg} alt="logo" />
            </Link>

            {/* Desktop */}
            {!isMobile && <HeaderMenu {...props} />}

            {/* Mobile */}
            {isMobile && <HeaderMenuMobile {...props} />}
        </div>
    );
};

export default Header;
