import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import ReactTable, { FinalState, RowInfo, SortingRule } from 'react-table';
import {
    getProductInfo,
    mergeProduct
} from '../../actions/manageInventoryActions';
import {
    ImanageProductReducer,
    Ioption,
    Iproduct,
    IproductInfo,
    Isubcategory,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';

import {
    getProducts,
    setTableFilter,
    toggleEditProductModal
} from '../../actions/manageProductActions';

import { Banner } from '../common/Banner';
/*
 * hopsital Managers manage their products
 * Note: did minimal renaming from the ManageInventory component
 */
import { values } from 'lodash';
import { FieldConfig } from 'react-reactive-form';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { RouteComponentProps } from 'react-router-dom';
import { closeAllModals } from '../../actions/commonActions';
import { constants } from '../../constants/constants';
import { IinitialState } from '../../reducers';
import {
    selectIsLoading,
    selectProductInfoSubCategories
} from '../../reducers/commonReducers';
import {
    emptyTile,
    initialProduct,
    initialProductQueueObject
} from '../../reducers/initialState';
import { FormUtil } from '../common/FormUtil';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import EditProductModal from '../manageInventory/EditProductModal';
import SearchNewProductsModal from '../manageInventory/SearchNewProductsModal';
interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    showApproveProductModal: boolean;
    loading: boolean;
    productManage: ImanageProductReducer;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleEditProductModal: typeof toggleEditProductModal;
    getProductInfo: typeof getProductInfo;
    toggleSecurityFunctionsModal: () => void;
    getProducts: typeof getProducts;
    closeAllModals: typeof closeAllModals;
    productInfo: IproductInfo;
    user: Iuser;
    tableData: Iproduct[];
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    mergeProduct: (sprod: string, dproduct: string) => Promise<void>;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    selectedProduct: Iproduct;
    selectedMainCategoryId: string | null;
}

class ManageProduct extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    public columns: any[];
    public searchFieldConfigBanner: any;
    public buttonInAction = false;
    private setProductTableFilterTimeout: any;
    private searchFieldConfig: FieldConfig;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            selectedProduct: initialProduct,
            selectedMainCategoryId: null
        };
        this.searchFieldConfig = this.buildSearchControls();
        this.columns = TableUtil.translateHeaders(
            [
                {
                    Header: 'sku',
                    accessor: 'sku'
                },
                {
                    Header: 'name',
                    id: 'name',
                    accessor: 'name',
                    minWidth: 400
                }
            ],
            this.props.t
        );
    }

    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.props.getProductInfo();
        this.props.getProducts();
    }
    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        // automatically get products every time a hitler changes

        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.getProducts();
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }

        this.searchFieldConfig = this.buildSearchControls();
    }
    componentWillUnmount() {
        this.props.closeAllModals();
    }

    /*
     * Reset the table filters (not used currently)
     */
    resetProductTableFilters = () => {
        this.props.setTableFilter({
            search: '',
            page: 1
        });
    };

    buildSearchControls = (): FieldConfig => {
        const { tableFilters, productInfo, t } = this.props;
        const { selectedMainCategoryId } = this.state;
        const originOptions: Ioption[] = [
            { value: 'USN', label: 'USN' },
            { value: 'GBN', label: 'GBN' },
            { value: 'CAN', label: 'CAN' }
        ];
        const mainSearchControls = {
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:search',
                    colWidth: 3,
                    type: 'text',
                    placeholder: 'searchPlaceholder',
                    defaultValue: tableFilters.search
                }
            },
            mainCategory: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:mainCategory',
                    options: productInfo.mainCategoryOptions,
                    colWidth: 2,
                    type: 'select',
                    placeholder: 'manageInventory:mainCategoryPlaceholder',
                    defaultValue: tableFilters.mainCategory,
                    isClearable: true
                }
            },
            subCategory: {
                render: FormUtil.SelectWithoutValidation,
                placeholder: t('common:selectSubcategory'),
                meta: {
                    label: 'common:subCategory',
                    options: Object.values(productInfo.subcategories).reduce(
                        (carry: Ioption[], subcategory: Isubcategory) => {
                            if (!selectedMainCategoryId) {
                                return carry;
                            }

                            if (
                                subcategory.mainCategoryID !==
                                selectedMainCategoryId
                            ) {
                                return carry;
                            }

                            const option: Ioption = {
                                label: subcategory.name,
                                value: subcategory.id
                            };
                            carry.push(option);
                            return carry;
                        },
                        []
                    ),
                    colWidth: 2,
                    type: 'select',
                    defaultValue: tableFilters.subCategory,
                    isClearable: true
                }
            },
            brand: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:brand',
                    options: productInfo.brandOptions,
                    colWidth: 2,
                    type: 'select',
                    defaultValue: tableFilters.brand,
                    isClearable: true
                }
            }
            // origin: {
            //     render: FormUtil.SelectWithoutValidation,
            //     meta: {
            //         label: 'common:origin',
            //         options: originOptions,
            //         colWidth: 2,
            //         type: 'select',
            //         defaultValue: tableFilters.origin,
            //         isClearable: true
            //     }
            // }
        };
        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    };

    /*
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    if (!this.buttonInAction) {
                        this.setState({
                            selectedProduct: rowInfo.original
                        });
                        this.props.toggleEditProductModal();
                    }
                },
                style: {
                    background: this.state.selectedRow[rowInfo.viewIndex]
                        ? constants.colors[
                              `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                          ]
                        : ''
                }
            };
        } else {
            return {};
        }
    };

    handleMergeProduct = (product: Iproduct) => {
        if (product.id === this.state.selectedProduct.id) {
            toastr.error('Error', this.props.t('mergeError'));
            return;
        }

        toastr.confirm(
            `${this.props.t('mergeConfirmPart_01')} \n ${
                this.state.selectedProduct.name
            } ${this.props.t('mergeConfirmPart_02')} ${product.name}?`,
            {
                onOk: () => {
                    this.props
                        .mergeProduct(this.state.selectedProduct.id, product.id)
                        .then(() => {
                            this.props.getProducts();
                        })
                        .catch((error: any) => console.error(error));
                }
            }
        );
    };

    // get the next or previous page of data.  the table is 0 indexed but the API is not
    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'search':
                clearTimeout(this.setProductTableFilterTimeout);
                this.setProductTableFilterTimeout = setTimeout(() => {
                    this.props.setTableFilter({
                        search: value,
                        page: 1
                    }); // this causes performance issues so we use a rudamentary debounce
                }, constants.tableSearchDebounceTime);
                break;
            case 'mainCategory':
                this.props.setTableFilter({
                    mainCategory: value,
                    page: 0
                });
                this.props.setTableFilter({
                    subCategory: undefined,
                    page: 0
                });
                break;
            case 'subCategory':
                this.props.setTableFilter({
                    subCategory: value,
                    page: 0
                });
                break;
            case 'brand':
                this.props.setTableFilter({
                    brand: value,
                    page: 0
                });
                break;
            case 'origin':
                this.props.setTableFilter({
                    origin: value,
                    page: 0
                });
                break;
            default:
                break;
        }
    };
    onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };

    render() {
        const tableClassName = this.props.tableFilters.showAdditionalFilters
            ? `beacon-table -highlight ${this.state.currentTile.color} additional-filters`
            : `beacon-table -highlight ${this.state.currentTile.color}`;

        const { t } = this.props;

        return (
            <div className="manage-product">
                <Banner
                    title={t('manageProduct:bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                <SearchTableForm
                    fieldConfig={this.searchFieldConfig}
                    handleSubmit={this.props.getProducts}
                    loading={this.props.loading}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    subscribeValueChanges={true}
                    onValueChanges={(value: any, key: any) => {
                        if (key === 'mainCategory') {
                            this.setState({
                                selectedMainCategoryId: value?.value
                            });
                        }
                        this.onSearchValueChanges(value, key);
                    }}
                    t={this.props.t}
                />
                <ReactTable
                    data={this.props.tableData}
                    columns={this.columns}
                    getTrProps={this.getTrProps}
                    pageSize={
                        this.props.tableData.length >= 10
                            ? this.props.tableData.length
                            : 10
                    }
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={this.props.productManage.totalPages}
                    page={this.props.tableFilters.page - 1}
                    showPageSizeOptions={false}
                    className={tableClassName}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    onSortedChange={this.onSortedChanged}
                    sortable={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    expanded={this.state.selectedRow}
                />
                <EditProductModal
                    selectedQueueObject={initialProductQueueObject}
                    selectedItem={this.state.selectedProduct}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    secondModal={false}
                />
                <SearchNewProductsModal
                    selectedItem={this.state.selectedProduct}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    secondModal={true}
                    handleMergeProduct={this.handleMergeProduct}
                    selectedQueueObject={initialProductQueueObject}
                    isApproved={true}
                    enableMergeProductMode={true}
                    wideModal={true}
                    disableCreateNew={true}
                />
            </div>
        );
    }
}

/*
 * AddCustomerModal will connect to redux, impliment CommonModal, as well as AddCustomerForm
 */

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        user: state.user,
        productManage: state.manageProduct,
        loading: selectIsLoading(state),
        productInfo: state.productInfo,
        subCategories: selectProductInfoSubCategories(state),
        tableData: values(state.manageProduct.productsByID),
        tableFilters: state.manageProduct.tableFilters
    };
};
export default withTranslation('manageProductQueue')(
    connect(mapStateToProps, {
        getProducts,
        toggleEditProductModal,
        closeAllModals,
        getProductInfo,
        setTableFilter,
        mergeProduct
    })(ManageProduct)
);
