import { pickBy, map, keyBy, omit } from 'lodash';

import { IproductQueueObject, ImanageProductQueueReducer } from '../models';
import {
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import initialState, { initialProductQueueObject } from './initialState';
import * as types from '../actions/actionTypes';

import { combineReducers } from 'redux';
import { cleanAndFilterProductObject } from './productsReducer';

export const cleanProductQueueObject = (
    productQueueObject: IproductQueueObject
) => {
    return {
        ...initialProductQueueObject,
        ...pickBy(productQueueObject, (property, key) => property !== null),
        product: cleanAndFilterProductObject(productQueueObject.product)
    } as IproductQueueObject;
};

function productQueueByID(
    state: { [key: string]: IproductQueueObject } = initialState
        .manageProductQueue.productQueueByID,
    action: any
): { [key: string]: IproductQueueObject } {
    switch (action.type) {
        case types.PRODUCT_QUEUE_SUCCESS: {
            const newProductQueueObjects = map(
                action.payload.productQueueObjects,
                (productQueueObject: IproductQueueObject) => {
                    return cleanProductQueueObject(productQueueObject);
                }
            );
            return keyBy(newProductQueueObjects, 'id');
        }
        case types.PRODUCT_QUEUE_PRODUCT_UPDATE_SUCCESS: {
            const updatedProductQueueProduct = {
                ...state[action.payload.queueID],
                product: action.payload.product
            };
            return {
                ...state,
                [action.payload.queueID]: cleanProductQueueObject(
                    updatedProductQueueProduct
                )
            };
        }
        case types.PRODUCT_APPROVE_SUCCESS:
            return omit(state, action.payload.queueID);
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageProductQueue.productQueueByID;
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.PRODUCT_QUEUE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

function mergeableProductsByID(
    state: { [key: string]: IproductQueueObject } = initialState
        .manageProductQueue.productQueueByID,
    action: any
): { [key: string]: IproductQueueObject } {
    switch (action.type) {
        case types.MERGEABLE_PRODUCTS_SUCCESS: {
            const newProductQueueObjects = map(
                action.payload.productQueueObjects,
                (productQueueObject: IproductQueueObject) => {
                    return cleanProductQueueObject(productQueueObject);
                }
            );
            return keyBy(newProductQueueObjects, 'id');
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageProductQueue.mergeableProductsByID;
        default:
            return state;
    }
}

const manageProductQueue = combineReducers<ImanageProductQueueReducer>({
    productQueueByID,
    mergeableProductsByID,
    totalPages,
    showApproveProductModal: (state, action) =>
        modalToggleWithName(state, action, 'APPROVE_PRODUCT'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_PRODUCT_QUEUE')
    // quoteRequestItems: (state, action) => quoteRequestReducer(state, action)
});

export default manageProductQueue;
