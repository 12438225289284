import * as types from '../actions/actionTypes';

import {
    Ibuilding,
    Ifacility,
    IfacilityContract,
    IfacilityWithoutBuildings,
    Ifloor,
    Ilead,
    Ilocation,
    Ioption,
    Iroom,
    ItableFiltersParams,
    Iuser
} from '../models';
import { filter, keyBy, map, omit, orderBy, pickBy, forEach } from 'lodash';
import initialState, { initialFacility } from './initialState';

import { FormUtil } from '../components/common/FormUtil';
import { createSelector } from 'reselect';
import { IinitialState } from '.';

export const cleanFacilityWithoutBuildings = (
    facility: IfacilityWithoutBuildings
) => {
    const initialFacilityWithoutBuildings = omit(initialFacility, [
        'buildings'
    ]);

    return {
        ...initialFacilityWithoutBuildings,
        ...pickBy(facility, (property, key) => property !== null)
    };
};

export const cleanFacility = (facility: Ifacility = initialFacility) => {
    let buildings = facility?.buildings;

    if (buildings && buildings.length) {
        buildings = buildings.filter(building => building.isDeleted === false);

        if (buildings.length) {
            buildings = buildings.map(building => {
                if (building?.floors?.length) {
                    const filteredFloors = building.floors.filter(
                        floor => floor.isDeleted === false
                    );

                    if (filteredFloors.length) {
                        const floors = filteredFloors.map(floor => {
                            const filteredLocations = floor.locations.filter(
                                location => location.isDeleted === false
                            );

                            if (filteredLocations.length) {
                                const locations = filteredLocations.map(
                                    location => {
                                        if (location.rooms.length) {
                                            return {
                                                ...location,
                                                rooms: location.rooms.filter(
                                                    room =>
                                                        room.isDeleted === false
                                                )
                                            };
                                        } else {
                                            return location;
                                        }
                                    }
                                );
                                return { ...floor, locations };
                            } else {
                                return {
                                    ...floor,
                                    locations: filteredLocations
                                };
                            }
                        });
                        return { ...building, floors };
                    }

                    return { ...building, floors: filteredFloors };
                }

                return building;
            });
        }
    }

    return {
        ...initialFacility,
        ...pickBy(facility, (property, key) => property !== null),
        buildings
    };
};

export default function facilitiesReducer(
    state: { [key: string]: Ifacility } = initialState.facilities,
    action: any
): { [key: string]: Ifacility } {
    switch (action.type) {
        case types.GET_FACILITIES_SUCCESS: {
            const newFacilities = map(action.facilities, facility => {
                return cleanFacility(facility);
            });

            return {
                ...state,
                ...keyBy(newFacilities, 'id')
            };
        }
        case types.MERGE_FACILITIES_SUCCESS: {
            const newState = state;
            action.secondaryIds.map((secondaryId: string) => {
                delete newState[secondaryId];
            });
            return { ...newState };
        }
        case types.ADD_JOB_FACILITY_SUCCESS: {
            // filter new facilities from existing facilities
            const newFacilities = (action.facilities as Ifacility[]).filter(
                ({ id }) => !state[id]
            );

            return {
                ...state,
                ...keyBy(newFacilities, 'id')
            };
        }
        case types.USER_LOGIN_SUCCESS: {
            const newFacilitiesG = map(action.user.facilities, facility => {
                const foundFacility = state[facility.id] || initialFacility;
                return {
                    ...foundFacility,
                    ...cleanFacilityWithoutBuildings(facility)
                };
            });
            return { ...state, ...keyBy(newFacilitiesG, 'id') };
        }
        case types.USER_MANAGE_SUCCESS: {
            let newFacilitiesH: Ifacility[] = [];

            forEach(action.users, (user: Iuser) => {
                forEach(user.facilities, facility => {
                    const foundFacility = state[facility.id] || initialFacility;
                    newFacilitiesH = [
                        {
                            ...foundFacility,
                            ...cleanFacilityWithoutBuildings(facility)
                        },
                        ...newFacilitiesH
                    ];
                });
            });
            return { ...state, ...keyBy(newFacilitiesH, 'id') };
        }

        case types.LEADS_MANAGE_SUCCESS: {
            return {
                ...state,
                ...keyBy(
                    action.leads
                        .filter((lead: Ilead) => lead.facility)
                        .map((lead: Ilead) => {
                            if (lead.facility) {
                                const originalFacilityF =
                                    state[lead.facility.id] || initialFacility;
                                return {
                                    ...originalFacilityF,
                                    ...cleanFacilityWithoutBuildings(
                                        lead.facility
                                    )
                                };
                            } else {
                                // this should never happen since we are filtering out leads that do not have a facility
                                return initialFacility;
                            }
                        }),
                    'id'
                )
            };
        }

        case types.FACILITY_UPDATE_SUCCESS:
            return {
                ...state,
                [action.facility.id]: {
                    ...state[action.facility.id],
                    ...cleanFacilityWithoutBuildings(action.facility)
                }
            };
        case types.QUOTE_MANAGE_SUCCESS: {
            let quoteFacilities: { [key: string]: Ifacility } = {};
            forEach(action.payload, (quoteResult: any) => {
                if (quoteResult.facility) {
                    const originalFacilityD =
                        state[quoteResult.facility.id] || initialFacility;
                    quoteFacilities = {
                        ...quoteFacilities,
                        [quoteResult.facility.id]: {
                            ...originalFacilityD,
                            ...cleanFacilityWithoutBuildings(
                                quoteResult.facility
                            )
                        }
                    };
                }
            });
            return { ...state, ...quoteFacilities };
        }
        case types.FACILITY_DELETE_SUCCESS:
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    isDeleted: true
                }
            };
        case types.LOCATION_MANAGE_SUCCESS:
            return {
                ...state,
                [action.facility.id]: cleanFacility(action.facility)
            };

        case types.LOCATION_ADD_SUCCESS: {
            const locationObject = action.locationObject as
                | Ilocation
                | Ibuilding
                | Ifloor
                | Iroom;
            /*
        This looks scary, but its fairly straightforward.
        We are dealing with a tree data structure something like this:
        buildings: [
          building1: {
            floors: [
              floor1: {
                locations: [
                  location1: {
                    rooms: [
                      room1: {},
                      ...
                    ]
                  },
                  ...
                ]
              },
              ...
            ]
          },
          ...
        ]

        This structure is the source of truth that sets the inital
        content of the visible array.
      */
            const facilityID = action.facilityID;
            const originalFacility = state[facilityID] as Ifacility;

            if ('facilityID' in locationObject && originalFacility.buildings) {
                // BUILDING
                return {
                    ...state,
                    [facilityID]: {
                        ...originalFacility,
                        buildings: [
                            ...originalFacility.buildings,
                            locationObject
                        ]
                    }
                };
            } else if (
                'buildingID' in locationObject &&
                originalFacility.buildings
            ) {
                // FLOOR
                const updatedBuildings: Ibuilding[] = map(
                    originalFacility.buildings,
                    building => {
                        if (building.id === locationObject.buildingID) {
                            return {
                                ...building,
                                floors: [...building.floors, locationObject]
                            };
                        } else {
                            return building;
                        }
                    }
                );
                return {
                    ...state,
                    [facilityID]: {
                        ...originalFacility,
                        buildings: updatedBuildings
                    }
                };
            } else if (
                'floorID' in locationObject &&
                originalFacility.buildings
            ) {
                // LOCATION
                const updatedBuildings: Ibuilding[] = map(
                    originalFacility.buildings,
                    building => {
                        const updatedFloors = map(building.floors, floor => {
                            if (floor.id === locationObject.floorID) {
                                return {
                                    ...floor,
                                    locations: [
                                        ...floor.locations,
                                        locationObject
                                    ]
                                };
                            } else {
                                return floor;
                            }
                        });
                        return { ...building, floors: updatedFloors };
                    }
                );
                return {
                    ...state,
                    [facilityID]: {
                        ...originalFacility,
                        buildings: updatedBuildings
                    }
                };
            } else if (
                'locationID' in locationObject &&
                originalFacility.buildings
            ) {
                // ROOM
                const updatedBuildings: Ibuilding[] = map(
                    originalFacility.buildings,
                    building => {
                        const updatedFloors = map(building.floors, floor => {
                            const updatedLocations = map(
                                floor.locations,
                                location => {
                                    if (
                                        location.id ===
                                        locationObject.locationID
                                    ) {
                                        return {
                                            ...location,
                                            rooms: [
                                                ...location.rooms,
                                                locationObject
                                            ]
                                        };
                                    } else {
                                        return location;
                                    }
                                }
                            );
                            return { ...floor, locations: updatedLocations };
                        });
                        return { ...building, floors: updatedFloors };
                    }
                );
                return {
                    ...state,
                    [facilityID]: {
                        ...originalFacility,
                        buildings: updatedBuildings
                    }
                };
            }
            return state;
        }

        case types.LOCATION_UPDATE_SUCCESS: {
            const facilityIDb = action.facilityID;
            const originalFacilityb = state[facilityIDb] as Ifacility;
            const locationObjectb = action.locationObject as
                | Ilocation
                | Ibuilding
                | Ifloor
                | Iroom;

            if (
                action.lType === 'Building' &&
                'facilityID' in locationObjectb &&
                originalFacilityb.buildings
            ) {
                // BUILDING
                return {
                    ...state,
                    [facilityIDb]: {
                        ...originalFacilityb,
                        buildings: map(
                            originalFacilityb.buildings,
                            building => {
                                if (building.id === locationObjectb.id) {
                                    return locationObjectb;
                                } else {
                                    return building;
                                }
                            }
                        )
                    }
                };
            } else if (
                action.lType === 'Floor' &&
                'buildingID' in locationObjectb &&
                originalFacilityb.buildings
            ) {
                // FLOOR
                const updatedBuildings: Ibuilding[] = map(
                    originalFacilityb.buildings,
                    building => {
                        if (building.id === locationObjectb.buildingID) {
                            return {
                                ...building,
                                floors: map(building.floors, floor => {
                                    if (floor.id === locationObjectb.id) {
                                        return locationObjectb;
                                    } else {
                                        return floor;
                                    }
                                })
                            };
                        } else {
                            return building;
                        }
                    }
                );
                return {
                    ...state,
                    [facilityIDb]: {
                        ...originalFacilityb,
                        buildings: updatedBuildings
                    }
                };
            } else if (
                action.lType === 'Location' &&
                'floorID' in locationObjectb &&
                originalFacilityb.buildings
            ) {
                // LOCATION
                const updatedBuildings: Ibuilding[] = map(
                    originalFacilityb.buildings,
                    building => {
                        const updatedFloors = map(building.floors, floor => {
                            if (floor.id === locationObjectb.floorID) {
                                return {
                                    ...floor,
                                    locations: map(
                                        floor.locations,
                                        location => {
                                            if (
                                                location.id ===
                                                locationObjectb.id
                                            ) {
                                                return locationObjectb;
                                            } else {
                                                return location;
                                            }
                                        }
                                    )
                                };
                            } else {
                                return floor;
                            }
                        });
                        return { ...building, floors: updatedFloors };
                    }
                );
                return {
                    ...state,
                    [facilityIDb]: {
                        ...originalFacilityb,
                        buildings: updatedBuildings
                    }
                };
            } else if (
                action.lType === 'Room' &&
                'locationID' in locationObjectb &&
                originalFacilityb.buildings
            ) {
                // ROOM
                const updatedBuildings: Ibuilding[] = map(
                    originalFacilityb.buildings,
                    building => {
                        const updatedFloors = map(building.floors, floor => {
                            const updatedLocations = map(
                                floor.locations,
                                location => {
                                    if (
                                        location.id ===
                                        locationObjectb.locationID
                                    ) {
                                        return {
                                            ...location,
                                            rooms: map(location.rooms, room => {
                                                if (
                                                    room.id ===
                                                    locationObjectb.id
                                                ) {
                                                    return locationObjectb;
                                                } else {
                                                    return room;
                                                }
                                            })
                                        };
                                    } else {
                                        return location;
                                    }
                                }
                            );
                            return { ...floor, locations: updatedLocations };
                        });
                        return { ...building, floors: updatedFloors };
                    }
                );
                return {
                    ...state,
                    [facilityIDb]: {
                        ...originalFacilityb,
                        buildings: updatedBuildings
                    }
                };
            }
            return state;
        }
        case types.LOCATION_DELETE_SUCCESS: {
            const facilityIDc = action.facilityID;
            const originalFacilityc = state[facilityIDc] as Ifacility;
            const locationObjectC = action.locationObject as
                | Ilocation
                | Ibuilding
                | Ifloor
                | Iroom;

            if (
                action.lType === 'Building' &&
                'facilityID' in locationObjectC &&
                originalFacilityc.buildings
            ) {
                // BUILDING
                return {
                    ...state,
                    [facilityIDc]: {
                        ...originalFacilityc,
                        buildings: originalFacilityc.buildings.map(building => {
                            if (building.id === locationObjectC.id) {
                                return { ...building, isDeleted: true };
                            }
                            return building;
                        })
                    }
                };
            } else if (
                action.lType === 'Floor' &&
                'buildingID' in locationObjectC &&
                originalFacilityc.buildings
            ) {
                // FLOOR
                const updatedBuildings: Ibuilding[] = map(
                    originalFacilityc.buildings,
                    building => {
                        if (building.id === locationObjectC.buildingID) {
                            return {
                                ...building,
                                floors: building.floors.map(floor => {
                                    if (floor.id === locationObjectC.id) {
                                        return { ...floor, isDeleted: true };
                                    }
                                    return floor;
                                })
                            };
                        }
                        return building;
                    }
                );
                return {
                    ...state,
                    [facilityIDc]: {
                        ...originalFacilityc,
                        buildings: updatedBuildings
                    }
                };
            } else if (
                action.lType === 'Location' &&
                'floorID' in locationObjectC &&
                originalFacilityc.buildings
            ) {
                // LOCATION
                const updatedBuildings: Ibuilding[] = map(
                    originalFacilityc.buildings,
                    building => {
                        const updatedFloors = map(building.floors, floor => {
                            if (floor.id === locationObjectC.floorID) {
                                return {
                                    ...floor,
                                    locations: floor.locations.map(location => {
                                        if (
                                            location.id === locationObjectC.id
                                        ) {
                                            return {
                                                ...location,
                                                isDeleted: true
                                            };
                                        }
                                        return location;
                                    })
                                };
                            }
                            return floor;
                        });
                        return { ...building, floors: updatedFloors };
                    }
                );
                return {
                    ...state,
                    [facilityIDc]: {
                        ...originalFacilityc,
                        buildings: updatedBuildings
                    }
                };
            } else if (
                action.lType === 'Room' &&
                'locationID' in locationObjectC &&
                originalFacilityc.buildings
            ) {
                // ROOM
                const updatedBuildings: Ibuilding[] = map(
                    originalFacilityc.buildings,
                    building => {
                        const updatedFloors = map(building.floors, floor => {
                            const updatedLocations = map(
                                floor.locations,
                                location => {
                                    if (
                                        location.id ===
                                        locationObjectC.locationID
                                    ) {
                                        return {
                                            ...location,
                                            rooms: location.rooms.map(room => {
                                                if (
                                                    room.id ===
                                                    locationObjectC.id
                                                ) {
                                                    return {
                                                        ...room,
                                                        isDeleted: true
                                                    };
                                                }
                                                return room;
                                            })
                                        };
                                    }
                                    return location;
                                }
                            );
                            return { ...floor, locations: updatedLocations };
                        });
                        return { ...building, floors: updatedFloors };
                    }
                );
                return {
                    ...state,
                    [facilityIDc]: {
                        ...originalFacilityc,
                        buildings: updatedBuildings
                    }
                };
            }
            return state;
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.facilities;

        default:
            return state;
    }
}

// filter and sort the facilities
export const getFacilitiesByCustomerID = (
    state: { [key: string]: Ifacility } | IfacilityWithoutBuildings[],
    customerID: string
): IfacilityWithoutBuildings[] => {
    const filteredFacilities = filter(state, {
        customerID,
        isDeleted: false
    }) as IfacilityWithoutBuildings[];
    return orderBy(filteredFacilities, (facility: IfacilityWithoutBuildings) =>
        facility.name.toLowerCase()
    );
};

export const getFacilities = (state: IinitialState) => state.facilities;
export const getSelectedFacilityID = (state: IinitialState) =>
    state.selectedFacilityID;
const getFacilitiesFiltersProps = (
    state: IinitialState,
    props: { name: string }
) => props.name.toLowerCase();

export const convertFacillitiesToOptionsWithAllProperties = (
    facilities: IfacilityWithoutBuildings[]
): Ioption[] => {
    return facilities.map(f => {
        const facility = generateFacilityNameWithAddess(f);
        return {
            ...facility,
            label: facility.name,
            value: f.id
        };
    });
};

export const convertFacillitiesToOptionsWithCountryID = (
    facilities: IfacilityWithoutBuildings[]
): Ioption[] => {
    return facilities?.map(f => {
        const facility = generateFacilityNameWithAddess(f);
        return {
            countryID: facility.countryID,
            label: facility.name,
            value: f.id
        };
    });
};

const generateFacilityNameWithAddess = (
    facility: IfacilityWithoutBuildings
) => {
    const {
        name,
        address,
        address2,
        city,
        state: geographical_state,
        postalCode,
        sapFacilityNumber
    } = facility;
    let nameWithAddress = `${name} | ${address}${
        address2 ? ' ' + address2 : ''
    } ${city} ${geographical_state} ${postalCode}`;
    if (sapFacilityNumber) {
        nameWithAddress = sapFacilityNumber + ' | ' + nameWithAddress;
    }
    return { ...facility, name: nameWithAddress };
};

export const convertFacilityOptions = (
    facilities: IfacilityWithoutBuildings[]
) => {
    return FormUtil.convertToOptions(
        map(facilities, generateFacilityNameWithAddess)
    );
};

// filter facilities and build options with the facility address added
export const selectFacilityOptionsWithAddress = createSelector(
    [getFacilities],
    (facilities): Array<Ioption> => {
        const filteredFacilities = filter(
            facilities,
            (facility: IfacilityWithoutBuildings) =>
                facility.isDeleted === false
        );
        const orderedFacilities = orderBy(filteredFacilities, facility =>
            facility.name.toLowerCase()
        );

        return convertFacilityOptions(orderedFacilities);
    }
);

export const selectFacilityOptionsFiltered = createSelector(
    [getFacilities, getFacilitiesFiltersProps],
    (facilities, query): Array<Ioption> => {
        const filteredFacilities = filter(
            facilities,
            (facility: IfacilityWithoutBuildings) =>
                facility.name.toLowerCase().includes(query) &&
                facility.isDeleted === false
        );
        const orderedFacilities = orderBy(filteredFacilities, facility =>
            facility.name.toLowerCase()
        );

        return convertFacilityOptions(orderedFacilities);
    }
);

export const selectSelectedFacilityWithBuildings = createSelector(
    [getFacilities, getSelectedFacilityID],
    (facilities, selectedFacilityID): Ifacility => {
        const withBuildings = Object.values(facilities).filter(
            facility => facility.buildings?.length > 0
        );
        const selectedFacility = withBuildings.find(
            facility => facility.id === selectedFacilityID
        );

        return selectedFacility || initialFacility;
    }
);

export const selectSelectedFacility = createSelector(
    [getFacilities, getSelectedFacilityID],
    (facilities, selectedFacilityID): IfacilityWithoutBuildings =>
        facilities[selectedFacilityID]
);

export const selectSelectedFacilityContract = createSelector(
    [getFacilities, getSelectedFacilityID],
    (facilities, selectedFacilityID): IfacilityContract | undefined => {
        console.info({ facilities, selectedFacilityID });
        return facilities[selectedFacilityID].contract;
    }
);

export const selectSelectedFacilityID = createSelector(
    [getSelectedFacilityID],
    (selectedFacilityID): string => selectedFacilityID
);

export const selectFilteredFacilities = (
    state: { [key: string]: Ifacility },
    tableFilters: ItableFiltersParams
) => {
    const filtered = filter(state, item => {
        let shouldInclude = true;
        if (tableFilters.facilityName && tableFilters.facilityName.length) {
            const searchString = tableFilters.facilityName.trim().toLowerCase();
            if (item.name.toLowerCase().indexOf(searchString) === -1) {
                shouldInclude = false;
            }
        }
        if (item.isDeleted === true) {
            shouldInclude = false;
        }

        return shouldInclude;
    });
    return orderBy(filtered, res => res.name, 'asc');
};
