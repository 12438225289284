/**
 * Recursively traverses an object and replaces all empty string values with null.
 */
export const replaceEmptyStringsWithNull = <T>(obj: T): T => {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            replaceEmptyStringsWithNull(obj[key]);
        } else if ((obj[key] as unknown as string) === '') {
            (obj[key] as unknown) = null;
        }
    }
    return obj;
};
