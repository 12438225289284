/*
 * Import Installs
 */

import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup
} from 'react-bootstrap';
import * as React from 'react';
import { importInstall } from '../../actions/manageInventoryActions';
import { constants } from '../../constants/constants';
import { TFunction } from 'i18next';

interface Iprops {
    toggleModal: () => void;
    importInstall: typeof importInstall;
    loading: boolean;
    colorButton: string;
    t: TFunction;
}
interface Istate {
    file?: File;
}

class ManageInstallForm extends React.Component<Iprops, Istate> {
    constructor(props: Iprops) {
        super(props);
        this.state = {
            file: undefined
        };
    }

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        this.props.importInstall(this.state.file);
    };

    addFile = (fileInput: HTMLInputElement) => {
        if (fileInput.files) {
            this.setState({ file: fileInput.files[0] });
        }
    };

    render() {
        const { t } = this.props;
        const selectedFile = this.state.file;

        const formClassName = `beacon-form import-install-form ${this.props.colorButton}`;

        return (
            <div>
                <Col xs={12}>
                    <form
                        method="get"
                        action={require('../../images/MMG Import Template.xlsx')}
                    >
                        <p>{t('uploadInstructions')}</p>
                        <Button
                            bsStyle="link"
                            type="submit"
                            className="left-side"
                        >
                            {t('exampleButton')}
                        </Button>
                    </form>
                    <form
                        method="get"
                        action={require('../../images/MMG SAP Import Template.xlsx')}
                    >
                        <Button
                            bsStyle="link"
                            type="submit"
                            style={{ marginBottom: '20px' }}
                            className="left-side"
                        >
                            {t('sapTemplate')}
                        </Button>
                    </form>
                </Col>

                <form onSubmit={this.handleSubmit} className={formClassName}>
                    <Col xs={12}>
                        <FormGroup>
                            <ControlLabel
                                htmlFor="fileUpload"
                                style={{ cursor: 'pointer' }}
                            >
                                <span className="btn btn-default">
                                    {t('importInstallLabel')}
                                </span>
                                <FormControl
                                    id="fileUpload"
                                    type="file"
                                    accept={
                                        constants.inventoryImportAllowedExtensions
                                    }
                                    name="upload"
                                    onChange={e => {
                                        const input = e.target as HTMLInputElement;
                                        this.addFile(input);
                                    }}
                                    style={{ display: 'none' }}
                                />
                            </ControlLabel>
                        </FormGroup>
                        {selectedFile && <span>{selectedFile.name}</span>}
                    </Col>

                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="link"
                            type="button"
                            className="pull-left left-side"
                            onClick={this.props.toggleModal}
                        >
                            {t('common:cancel')}
                        </Button>

                        <Button
                            bsStyle={this.props.colorButton}
                            type="submit"
                            disabled={this.props.loading}
                        >
                            {t('import')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}
export default ManageInstallForm;
