import * as types from './actionTypes';
import * as localForage from 'localforage';

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import { ItableFiltersParams, ThunkResult } from '../models';

import API from '../constants/apiEndpoints';
import { beginAjaxCall } from './ajaxStatusActions';
import { constants } from '../constants/constants';
import { dateRangeEnum } from '../models-enums';
import { msalFetch } from '../components/auth/Auth-Utils';

const toBase64 = (file: File | Blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });

// prettier-ignore
export const convertDateRangeToInterval = (dateRange: dateRangeEnum): { startDate: string; endDate: string } => {
    switch (dateRange) {
        case dateRangeEnum.lastYear:
            {
                return (
                    {
                        startDate:
                            moment.utc(
                                moment().subtract('year', 1).startOf('year')
                            ).format(constants.momentSQLFormat),
                        endDate:
                            moment.utc(
                                moment().subtract('year', 1).endOf('year')
                            ).format(constants.momentSQLFormat)
                    }
                )
            }
        case dateRangeEnum.lastQuarter:
            {
                return (
                    {
                        startDate:
                            moment.utc(
                                moment().quarter(                                                       // format quarter: number into moment
                                    moment().subtract('quarter', 1).quarter()                           // subtract one quarter-length from the current quarter
                                ).startOf('quarter')                                                  // Grab the date of the beginning of the quarter
                            ).format(constants.momentSQLFormat),                                      // format in sql
                        endDate:
                            moment.utc(
                                moment().quarter(
                                    moment().subtract('quarter', 1).quarter()
                                ).endOf('quarter')
                            ).format(constants.momentSQLFormat)
                    }
                )
            }
        case dateRangeEnum.thisYear:
            {
                return (
                    {
                        startDate:
                            moment.utc(moment().startOf('year')).format(constants.momentSQLFormat),
                        endDate:
                            moment.utc(moment().endOf('year')).format(constants.momentSQLFormat)
                    }
                )
            }
        case dateRangeEnum.thisQuarter:
            {
                return (
                    {
                        startDate:
                            moment.utc(
                                moment().startOf('quarter')
                            ).format(constants.momentSQLFormat),
                        endDate:
                            moment.utc(
                                moment().endOf('quarter')
                            ).format(constants.momentSQLFormat)
                    }
                )
            }
        default:
            return { startDate: '', endDate: '' }  // It expects this return type
    }
};
/*
 * this is used for paging and filtering the photos on the manage Aseet Photos view
 */
export function getPhotos(): ThunkResult<any> {
    return (dispatch, getState) => {
        const { user } = getState();

        if (
            !constants.hasSecurityFunction(user, [
                constants.securityFunctions.ViewInventory.id
            ]) ||
            !constants.hasSecurityFunction(user, [
                constants.securityFunctions.ViewAssetPhotos.id,
                constants.securityFunctions.ManageInventory.id
            ])
        )
            return dispatch({
                type: types.ASSET_PHOTOS_MANAGE_SUCCESS,
                photos: []
            });

        dispatch(beginAjaxCall());
        const {
            page,
            assetSearch,
            jobSearch,
            mainCategory,
            dateRange
        } = getState().manageAssetPhotos.tableFilters;
        const { selectedFacilityID } = getState();
        const { startDate, endDate } = convertDateRangeToInterval(
            dateRange
                ? (dateRange.value as dateRangeEnum)
                : dateRangeEnum.thisQuarter
        );
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                page,
                assetSearch,
                facilityID: selectedFacilityID,
                jobSearch,
                mainCategoryID: mainCategory && mainCategory.value,
                startDate: startDate ? moment.utc(startDate).toISOString() : '',
                endDate: endDate ? moment.utc(endDate).toISOString() : ''
            }
        };

        const url = API.photo.search;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.ASSET_PHOTOS_MANAGE_SUCCESS,
                        photos: resp.data.result
                    });
                    dispatch({
                        type: types.ASSET_PHOTOS_MANAGE_TOTAL_PAGES,
                        pages: resp.data.pages
                    });
                    return resp;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.ASSET_PHOTOS_MANAGE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get photos');
                console.error(error);
            });
    };
}

export function getPhotosByProduct(productID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                productID
            }
        };

        const url = API.photo.getByProduct;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.PHOTOS_BY_PRODUCT_SUCCESS,
                        photos: { [productID]: resp.data.result }
                    });
                    return resp;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.ASSET_PHOTOS_MANAGE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get photos');
                console.error(error);
            });
    };
}

export function deletePhoto(
    photoID: string,
    productID: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };
        const url = API.photo.deletephoto.replace('{id}', photoID);
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                dispatch({
                    type: types.DELETE_PHOTO_SUCCESS,
                    photoID,
                    productID
                });
                dispatch(getPhotosByProduct(productID));
            })
            .catch((error: any) => {
                dispatch({
                    type: types.DELETE_PHOTO_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'delete photo');
                console.error(error);
            });
    };
}

export function deleteMultiplePhotos(
    photoIDs: string[],
    productID: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };
        const params = photoIDs.map(id => `photoIDs=${id}`);
        const url = `${API.photo.deleteMultiplePhotos}?${params.join('&')}`;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                dispatch({
                    type: types.DELETE_PHOTO_SUCCESS,
                    photos: resp.data,
                    productID
                });
                dispatch(getPhotos());
            })
            .catch((error: any) => {
                dispatch({
                    type: types.DELETE_PHOTO_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'delete photo');
                console.error(error);
            });
    };
}

export function uploadProductPhoto(
    photoID: string,
    objectUrl: string,
    productID: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        return localForage.getItem(photoID).then(item => {
            if (item instanceof File || item instanceof Blob) {
                toBase64(item).then(base64Photo => {
                    const photoPayload = {
                        createDate: moment.now(),
                        id: photoID,
                        productID,
                        lightboxUrl: objectUrl,
                        originalUrl: objectUrl,
                        thumbnailUrl: objectUrl
                    };
                    const file = (base64Photo as string).split(',')[1];
                    const axiosOptions: AxiosRequestConfig = {
                        method: 'post',
                        data: {
                            id: photoID,
                            file,
                            productID
                        }
                    };
                    const url = API.POST.photo.add;

                    dispatch({
                        type: types.UPLOAD_PRODUCT_PHOTO_SUCCESS,
                        photo: photoPayload,
                        productID
                    });

                    return msalFetch(url, axiosOptions)
                        .then((resp: AxiosResponse<any>) => {
                            if (!resp.data) {
                                throw new Error('missing data');
                            }
                        })
                        .catch((error: any) => {
                            dispatch({
                                type: types.UPLOAD_PRODUCT_PHOTO_FAILED,
                                error,
                                axiosOptions
                            });
                            constants.handleError(error, 'get photos');
                            console.error(error);
                        });
                });
            } else {
                console.error('not a file or blob');
            }
        });
    };
}

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_ASSET_PHOTOS,
    filters
});
