/*
 * Analytics Detail Container
 */

import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { getDashboardAnalytics } from '../../actions/dashboardAnalyticsActions';
import SmartlinkDetails from './SmartLinkDetails';
import {
    selectAnalyticsDetailsSmartlinkForInstall,
    selectDataFormatPreference
} from '../../reducers/dashboardAnalyticsReducer';
import {
    setSelectedProductID,
    toggleInstallContactModal
} from '../../actions/manageInventoryActions';

const mapStateToProps = (
    state: IinitialState,
    ownProps: WithTranslation & RouteComponentProps<{ installID: string }>
) => {
    const query = queryString.parse(ownProps.location.search);
    const isDemoMode = query.demo === 'true';
    const smartlink = selectAnalyticsDetailsSmartlinkForInstall(state, {
        installBaseID: ownProps.match.params.installID,
        isDemoMode
    });
    const dataFormatPreference = selectDataFormatPreference(state);
    const installBase =
        state.manageInventory.installBasesByID[smartlink.installBaseID];

    return {
        smartlink,
        installBase,
        loading: selectIsLoading(state),
        isDemoMode,
        dataFormatPreference
    };
};

export const AnalyticsDetailContainer = withRouter(
    withTranslation('manageTae')(
        connect(mapStateToProps, {
            getDashboardAnalytics,
            toggleInstallContactModal,
            setSelectedProductID
        })(SmartlinkDetails)
    )
);
