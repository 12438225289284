import * as types from '../actions/actionTypes';

import {
    IdashboardAnalyticsData,
    IdashboardAnalyticsReducer,
    IanalyticsTAE,
    Ioption,
    IanalyticsSmartlink,
    IdataFormatPreference
} from '../models';

import initialState, {
    initialAnalyticsSmartlink,
    initialAnalyticsTAE
} from './initialState';
import { find, pickBy } from 'lodash';
import { createSelector } from 'reselect';
import { IinitialState } from '.';
import { dataFormatTemperatureEnum } from '../models-enums';
import { constants } from '../constants/constants';

const cleanDashboardAnalyticsData = (
    dashboardAnalyticsData: any
): IdashboardAnalyticsData => {
    return {
        ...initialState.dashboardAnalytics.data,
        ...pickBy(dashboardAnalyticsData, property => property !== null)
    } as IdashboardAnalyticsData;
};

/** ** SELECTORS */

const getCurrentUser = (state: IinitialState) => state.user;
const getDashboardAnalyticsData = (state: IinitialState) =>
    state.dashboardAnalytics.data;
const getIsDemoModeProp = (
    state: IinitialState,
    props: { isDemoMode: boolean }
) => props.isDemoMode;

const getInstallBaseIdProp = (
    state: IinitialState,
    props: { installBaseID: string }
) => props.installBaseID;

export const selectDashboardAnalytics = createSelector(
    [getDashboardAnalyticsData, getCurrentUser, getIsDemoModeProp],
    (dashboardAnalyticsData, user, isDemoMode): IdashboardAnalyticsData => {
        const updatedDashboardAnalyticsData: IdashboardAnalyticsData = dashboardAnalyticsData;

        return {
            ...updatedDashboardAnalyticsData,
            // the value for gas type comes back with surrounding spaces, cleaning up before handing off to the component
            tae: dashboardAnalyticsData.tae.map(taeItem => ({
                ...taeItem,
                gasType: taeItem.gasType?.replace(/^\s([a-zA-Z0-9]+)\s$/g, '$1')
            }))
        };
    }
);

// export const selectAnalyticsDetailsForInstall = createSelector(
//     [selectDashboardAnalytics, getInstallBaseIdProp],
//     (
//         dashboardAnalytics,
//         installBaseID
//     ): IanalyticsTAE | IanalyticsSmartlink => {
//         const allSmartAnalytics = [
//             ...dashboardAnalytics.smartlinks,
//             ...dashboardAnalytics.tae
//         ];
//         return (
//             find(allSmartAnalytics, { installBaseID }) ||
//             initialAnalyticsSmartlink
//         ); // it is safe to assume there will only be 1
//     }
// );

export const selectAnalyticsDetailsSmartlinkForInstall = createSelector(
    [selectDashboardAnalytics, getInstallBaseIdProp],
    (dashboardAnalytics, installBaseID) => {
        return (
            find(dashboardAnalytics.smartlinks, { installBaseID }) ||
            initialAnalyticsSmartlink
        ); // it is safe to assume there will only be 1
    }
);

export const selectAnalyticsDetailsTaeForInstall = createSelector(
    [selectDashboardAnalytics, getInstallBaseIdProp],
    (dashboardAnalytics, installBaseID): IanalyticsTAE => {
        return (
            find(dashboardAnalytics.tae, { installBaseID }) ||
            initialAnalyticsTAE
        ); // it is safe to assume there will only be 1
    }
);

export const selectDataFormatPreference = createSelector(
    [getCurrentUser],
    (user): IdataFormatPreference => {
        const isUSA = constants.usCountryID === user.countryID;
        return {
            temperature: isUSA
                ? dataFormatTemperatureEnum.fahrenheit
                : dataFormatTemperatureEnum.celcius
        };
    }
);

/** **  REDUCERS */

function dashboardData(
    state: IdashboardAnalyticsData = initialState.dashboardAnalytics.data,
    action: any
): IdashboardAnalyticsData {
    switch (action.type) {
        case types.GET_DASHBOARD_ANALYTICS_SUCCESS:
            return cleanDashboardAnalyticsData(action.payload);
        case types.USER_LOGOUT_SUCCESS:
            return initialState.dashboardAnalytics.data;
        default:
            return state;
    }
}

export default function dashboardAnalyticsReducer(
    state: IdashboardAnalyticsReducer = initialState.dashboardAnalytics,
    action: any
) {
    return {
        data: dashboardData(state.data, action)

        // tableFilters: createTableFiltersWithName(
        //   state.tableFilters,
        //   action,
        //   'MANAGE_JOB'
        // )
    };
}
/*
 * SELECTORS
 */

// build the install base options from the TAE's
export const getTaeInstallBaseOptions = (state: IdashboardAnalyticsReducer) => {
    const analyticsTae = state.data.tae;
    let installBaseOptions: Ioption[] = [];
    if (analyticsTae.length) {
        analyticsTae.forEach(item => {
            installBaseOptions = [
                ...installBaseOptions,
                { value: item.installBaseID, label: item.installBaseName }
            ];
        });
    }
    return installBaseOptions;
};

export const getTaeInstallBasesFrom = (state: IdashboardAnalyticsReducer) => {
    const analyticsTae = state.data.tae;
    let installBases: Partial<IanalyticsTAE>[] = [];
    if (analyticsTae.length) {
        analyticsTae.forEach(item => {
            installBases = [
                ...installBases,
                {
                    installBaseID: item.installBaseID,
                    installBaseLocation: item.installBaseLocation,
                    installBaseName: item.installBaseName
                }
            ];
        });
    }
    return installBases;
};
export const getSmartlinkInstallBaseOptions = (
    state: IdashboardAnalyticsReducer
) => {
    const analytics = state.data.smartlinks;
    let installBaseOptions: Ioption[] = [];
    if (analytics.length) {
        analytics.forEach(item => {
            installBaseOptions = [
                ...installBaseOptions,
                {
                    value: item.installBaseID,
                    label: `${item.installBaseName}: ${item.installBaseSerialNumber}`
                }
            ];
        });
    }
    return installBaseOptions;
};

export const getSmartlinkInstallBases = (state: IdashboardAnalyticsReducer) => {
    const analytics = state.data.smartlinks;
    let installBases: Partial<IanalyticsSmartlink>[] = [];
    if (analytics.length) {
        analytics.forEach(item => {
            installBases = [
                ...installBases,
                {
                    installBaseID: item.installBaseID,
                    installBaseLocation: item.installBaseLocation,
                    installBaseName: item.installBaseName
                }
            ];
        });
    }
    return installBases;
};
