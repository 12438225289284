import { WithTranslation, withTranslation } from 'react-i18next';
import {
    deleteFacilityFromContact,
    updateSelectedContact
} from '../../actions/manageFacilityActions';

import { AddContactsTable } from './AddContactsTable';
import { IfacilityContact } from '../../models';
import { connect } from 'react-redux';
import { selectFacilityContactsForTable } from '../../reducers/manageCustomerAndFacilityReducer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
    facilityID: string;
    addContactLead?: (contact: IfacilityContact) => void;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const { selectedContact } = state.manageFacility;
    const tableData = selectFacilityContactsForTable(state);

    return {
        loading: selectIsLoading(state),
        tableData,
        facilityID: ownProps.facilityID,
        selectedContactID: selectedContact.id
    };
};

export const AddContactsTableContainer = withTranslation('facility')(
    connect(mapStateToProps, {
        deleteFacilityFromContact,
        updateSelectedContact
    })(AddContactsTable)
);
