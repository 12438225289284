/*
 * EditMeasurementPointListForm
 * Edit measurement point lists
 */

import { Col, Button, Row } from 'react-bootstrap';
import {
    FormGenerator,
    AbstractControl,
    FieldConfig,
    GroupProps,
    FormGroup
} from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';

import * as React from 'react';

import { FormUtil } from '../common/FormUtil';
import {
    ImeasurementPointList,
    ImeasurementPoint,
    Iuser,
    ImeasurementPointListTab,
    IproductInfo,
    Ioption
} from '../../models';
import {
    toggleEditMeasurementPointModal,
    addGlobalMeasurementPointList,
    setSelectedTabID,
    updateMeasurementPoint,
    saveMeasurementPointToMeasurementPointList,
    toggleEditMeasurementPointTabModal,
    setSelectedMeasurementPointList,
    toggleEditMeasurementPointListTestProceduresModal,
    updateMeasurementPointListTab
} from '../../actions/manageMeasurementPointListsActions';
import { constants, mainCategories } from '../../constants/constants';
import {
    initialMeasurementPoint,
    initialMeasurementPointTab,
    initialMeasurementPointList
} from '../../reducers/initialState';
import { MeasurementPointList } from './MeasurementPointList';
import { TFunction } from 'i18next';
import {
    measurementPointListTypeEnum,
    measurementPointListTypeForJobEnum
} from '../../models-enums';
const uuidv4 = require('uuid/v4');

interface Iprops extends React.Props<EditMeasurementPointListForm> {
    user: Iuser;
    selectedMeasurementPointList: ImeasurementPointList;
    productInfo: IproductInfo;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    forJob: boolean;
    toggleModal: () => void;
    toggleEditMeasurementPointModal: typeof toggleEditMeasurementPointModal;
    addGlobalMeasurementPointList: typeof addGlobalMeasurementPointList;
    updateMeasurementPointList: (
        mpl: ImeasurementPointList,
        persistToAPI: boolean,
        isCustomer: boolean
    ) => Promise<void>;
    selectedTab: ImeasurementPointListTab;
    setSelectedTabID: typeof setSelectedTabID;
    updateMeasurementPoint: typeof updateMeasurementPoint;
    saveMeasurementPointToMeasurementPointList: typeof saveMeasurementPointToMeasurementPointList;
    toggleEditMeasurementPointTabModal: typeof toggleEditMeasurementPointTabModal;
    setSelectedMeasurementPointList: typeof setSelectedMeasurementPointList;
    toggleEditMeasurementPointListTestProceduresModal: typeof toggleEditMeasurementPointListTestProceduresModal;

    updateMeasurementPointListTab: typeof updateMeasurementPointListTab;
    canEditGlobal: boolean;
    isCustomerView: boolean;
    listTabs: ImeasurementPointListTab[];
}

interface Istate {
    fieldConfig: FieldConfig;
    isCopying: boolean;
    preCopyValues?: {
        mainCategoryID: Ioption;
        type: Ioption;
        standardID: Ioption;
    };
}

class EditMeasurementPointListForm extends React.Component<Iprops, Istate> {
    public formGroup: FormGroup | any;
    private subscription: any;
    // private persistTimeout: any; // all inputs are selects so we don't care about a debounce
    constructor(props: Iprops) {
        super(props);
        this.state = {
            fieldConfig: FormUtil.translateForm(
                this.buildFieldConfig(false),
                this.props.t
            ),
            isCopying: false
        };
    }
    componentDidMount() {
        const { listTabs } = this.props;
        if (listTabs.length) {
            this.props.setSelectedTabID(listTabs[0].id);
        }
    }

    componentDidUpdate(prevProps: Iprops) {
        if (!this.props.selectedMeasurementPointList) {
            console.error('missing selected measurement point');
            return;
        }
        if (
            JSON.stringify(prevProps.selectedTab) !==
                JSON.stringify(this.props.selectedTab) ||
            JSON.stringify(prevProps.selectedMeasurementPointList) !==
                JSON.stringify(this.props.selectedMeasurementPointList)
        ) {
            this.setState({
                fieldConfig: FormUtil.translateForm(
                    this.buildFieldConfig(this.state.isCopying),
                    this.props.t
                )
            });
        }
    }
    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    buildFieldConfig = (isCopying: boolean) => {
        const { selectedMeasurementPointList, productInfo } = this.props;
        const {
            type,
            mainCategoryID,
            standardID
        } = selectedMeasurementPointList;

        const {
            // eslint-disable-next-line no-shadow
            mainCategories,
            standards,
            mainCategoryOptions,
            standardOptions
        } = productInfo;

        const tabOptions = FormUtil.convertToOptions(
            this.props.listTabs.filter(tab => tab.isDeleted !== true)
        );
        // const disabled = this.props.isCustomerView;

        let selectedType = null;
        let selectedMainCategory = null;
        let selectedStandard = null;
        let selectedTab = null;
        if (type !== 0) {
            selectedType = {
                label: this.props.t(
                    measurementPointListTypeEnum[
                        selectedMeasurementPointList.type
                    ]
                ),
                value: selectedMeasurementPointList.type
            };
        }
        if (mainCategoryID.length) {
            selectedMainCategory = FormUtil.convertToSingleOption(
                mainCategories[mainCategoryID]
            );
        }
        if (standardID.length) {
            selectedStandard = FormUtil.convertToSingleOption(
                standards[standardID]
            );
        }

        const foundSelectedTab = tabOptions.find(
            tab => tab.value === this.props.selectedTab.id
        );
        if (this.props.selectedTab.id.length && foundSelectedTab) {
            selectedTab = foundSelectedTab;
        } else {
            // select the first one if none are selected
            selectedTab = tabOptions[0];
        }

        let fieldConfigControls = {};

        // If the measurement point list is not for a job, then allow the user to select the main category
        if (
            selectedMeasurementPointList.forJob === undefined ||
            selectedMeasurementPointList.forJob === false
        ) {
            fieldConfigControls = {
                mainCategoryID: {
                    render: FormUtil.Select,
                    meta: {
                        options: mainCategoryOptions.filter(
                            (option: Ioption) => option.label !== 'Job'
                        ),
                        label: 'manageMeasurementPointLists:equipmentType',
                        colWidth: 12,
                        placeholder:
                            'manageMeasurementPointLists:equipmentTypePlaceholder'
                    },
                    options: {
                        validators: [FormUtil.validators.requiredWithTrim]
                    },
                    formState: {
                        value: selectedMainCategory,
                        disabled:
                            !isCopying &&
                            !this.props.selectedMeasurementPointList.temporary // do not allow users to change since it wil likely cause confusing issues
                    }
                }
            };
        }

        // Field config to configure form
        fieldConfigControls = {
            ...fieldConfigControls,
            type: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        this.props.forJob
                            ? measurementPointListTypeForJobEnum
                            : measurementPointListTypeEnum
                    ),
                    label: 'manageMeasurementPointLists:type',
                    colWidth: 6,
                    placeholder: 'manageMeasurementPointLists:typePlaceholder',
                    shouldTranslate: true
                },
                options: { validators: [FormUtil.validators.requiredWithTrim] },
                formState: {
                    value: selectedType,
                    disabled:
                        !isCopying &&
                        !this.props.selectedMeasurementPointList.temporary // do not allow users to change since it wil likely cause confusing issues
                }
            },
            standardID: {
                render: FormUtil.Select,
                meta: {
                    options: standardOptions,
                    label: 'manageMeasurementPointLists:standard',
                    colWidth: 6,
                    placeholder:
                        'manageMeasurementPointLists:standardPlaceholder'
                },
                options: { validators: [FormUtil.validators.requiredWithTrim] },
                formState: {
                    value: selectedStandard,
                    disabled:
                        !isCopying &&
                        !this.props.selectedMeasurementPointList.temporary // do not allow users to change since it wil likely cause confusing issues
                }
            },
            selectedTab: {
                render: this.props.isCustomerView
                    ? FormUtil.Select
                    : FormUtil.CreatableSelectWithButton,
                meta: {
                    options: tabOptions,
                    label: 'manageMeasurementPointLists:selectTabLabel',
                    subLabel: this.props.t(' - Type to Create a Tab'),
                    colWidth: 12,
                    placeholder:
                        'manageMeasurementPointLists:selectTabPlaceholder',
                    isMulti: false,
                    handleCreate: this.handleCreateTab,
                    buttonName: this.props.t('edit'),
                    buttonAction: this.props.toggleEditMeasurementPointTabModal
                },
                options: { validators: [FormUtil.validators.requiredWithTrim] },
                formState: { value: selectedTab, disabled: false }
            }
        } as { [key: string]: GroupProps };
        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };
        return fieldConfig as FieldConfig;
    };

    /*
  Handle creating a new tab
  */
    handleCreateTab = (name: string) => {
        const newTab: ImeasurementPointListTab = {
            ...initialMeasurementPointTab,
            id: uuidv4(),
            name,
            order: this.props.listTabs.length + 1
        };

        this.props
            .updateMeasurementPointList(
                {
                    ...this.props.selectedMeasurementPointList,
                    measurementPointTabs: [...this.props.listTabs, newTab]
                },
                false,
                false
            )
            .then(() => {
                this.props.setSelectedTabID(newTab.id);
            })
            .catch((error: any) => console.error(error));
    };

    subscribeToValueChanges = () => {
        const controls = [
            'selectedTab',
            'type',
            'mainCategoryID',
            'standardID'
        ];
        controls.forEach((key: string) => {
            this.subscription = this.formGroup
                .get(key)
                ?.valueChanges.subscribe((value: Ioption | null) => {
                    this.handleValueChange(key, value);
                });
        });
    };

    handleValueChange = (key: string, value: null | Ioption) => {
        // clearTimeout(this.persistTimeout);
        // this.persistTimeout = setTimeout(() => {
        switch (key) {
            case 'selectedTab':
                if (value && value.value) {
                    this.props.setSelectedTabID(value.value);
                }
                break;
            default:
                if (value && value.value) {
                    this.props
                        .updateMeasurementPointList(
                            {
                                ...this.props.selectedMeasurementPointList,
                                [key]: value.value
                            },
                            false,
                            false
                        )
                        .catch((error: any) => console.error(error));
                }
                break;
        }
        // }, 200);
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        this.subscribeToValueChanges();
    };

    /*
  set a single measurement point
  */
    setSelectedMeasurementPoint = (measurementPoint: ImeasurementPoint) => {
        this.props.updateMeasurementPoint(
            measurementPoint,
            this.props.selectedTab.id
        );
        this.props.toggleEditMeasurementPointModal();
    };

    deleteMeasurementPoint = (measurementPoint: ImeasurementPoint) => {
        const toastrConfirmOptions = {
            onOk: () => {
                this.props.saveMeasurementPointToMeasurementPointList(
                    this.props.selectedMeasurementPointList.id,
                    this.props.selectedTab.id,
                    { ...measurementPoint, isDeleted: true }
                );
                console.info('deleted', measurementPoint);
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('deleteMeasurementPointOk'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deleteConfirmMP'), toastrConfirmOptions);
    };

    createNewMeasurementPoint = (type: number): ImeasurementPoint => {
        return {
            ...initialMeasurementPoint,
            type,
            order: Object.keys(this.props.selectedTab.measurementPoints).length
        };
    };

    validInitialTab = () => {
        if (this.props.selectedTab.id.length) {
            return true;
        } else {
            toastr.warning(
                'Warning',
                'Must add a tab first.',
                constants.toastrWarning
            );
            return false;
        }
    };

    // create an empty guid

    /*
     * We will allways have a selectedMeasurmentPointList because either a temporary one was created, or we are editing one that we received from the API.
     * we are only updating the mainCategory, standard, and type on this form.
     */
    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        let { type, mainCategoryID, standardID } = this.formGroup.value;

        let mainCategoryToSubmit;
        if (!mainCategoryID && this.props.selectedMeasurementPointList.forJob) {
            mainCategoryToSubmit = mainCategories.job;
        } else {
            mainCategoryToSubmit = mainCategoryID
                ? mainCategoryID.value
                : '00000000-0000-0000-0000-000000000000';
        }

        const baseMpl = {
            ...this.props.selectedMeasurementPointList,
            mainCategoryID: mainCategoryToSubmit,
            standardID: standardID ? standardID.value : '',
            type: type ? type.value : ''
        };

        const mpl = this.state.isCopying
            ? {
                  ...baseMpl,
                  temporary: true,
                  createDate: undefined,
                  creatorID: undefined,
                  mainCategories: undefined,
                  standard: undefined,
                  updateDate: undefined,
                  updaterID: undefined,
                  mainCategory: undefined
              }
            : baseMpl;

        if (this.state.isCopying) {
            const mplID = uuidv4();

            mpl.id = mplID;
            mpl.measurementPointTabs = (mpl.measurementPointTabs as any).map(
                (mpt: any) => {
                    const tabID = uuidv4();

                    return {
                        ...mpt,
                        createDate: undefined,
                        creatorID: undefined,
                        updateDate: undefined,
                        updaterID: undefined,
                        id: tabID,
                        measurementPointListID: mplID,
                        measurementPoints: Object.values(
                            mpt.measurementPoints
                        ).map((mp: any) => ({
                            ...mp,
                            createDate: undefined,
                            creatorID: undefined,
                            updateDate: undefined,
                            updaterID: undefined,
                            id: uuidv4(),
                            measurementPointListTabID: tabID
                        }))
                    };
                }
            );
        }

        if (mpl.temporary !== true) {
            this.props
                .updateMeasurementPointList(
                    mpl,
                    true,
                    this.props.isCustomerView
                )
                .catch((error: any) => console.error(error));
        } else {
            this.props.addGlobalMeasurementPointList({
                ...mpl,
                temporary: false
            });
        }
    };

    handleAddQuestion = () => {
        if (!this.validInitialTab()) {
            return;
        }
        this.setSelectedMeasurementPoint(
            this.createNewMeasurementPoint(
                constants.measurementPointTypes.MEASUREMENT_POINT_PASSFAIL
            )
        );
    };
    handleAddGroup = () => {
        if (!this.validInitialTab()) {
            return;
        }
        this.setSelectedMeasurementPoint(
            this.createNewMeasurementPoint(
                constants.measurementPointTypes.GROUP
            )
        );
    };
    handleCancel = () => {
        this.props.toggleModal();
        this.props.setSelectedTabID('');
        this.props.setSelectedMeasurementPointList(initialMeasurementPointList);
    };

    canEditGlobal = () => {
        return constants.hasSecurityFunction(this.props.user, [
            constants.securityFunctions.ManageAllMeasurementPoints.id
        ]);
    };

    handleCopyClick = () => {
        this.setState(
            prev => ({
                isCopying: !prev.isCopying,
                fieldConfig: FormUtil.translateForm(
                    this.buildFieldConfig(!prev.isCopying),
                    this.props.t
                )
            }),
            () => {
                if (this.state.isCopying) {
                    const {
                        mainCategoryID,
                        type,
                        standardID
                    } = this.formGroup.value;

                    this.setState({
                        preCopyValues: {
                            mainCategoryID,
                            type,
                            standardID
                        }
                    });
                } else if (this.state.preCopyValues) {
                    const {
                        mainCategoryID,
                        type,
                        standardID
                    } = this.state.preCopyValues;

                    this.props.updateMeasurementPointList(
                        {
                            ...this.props.selectedMeasurementPointList,
                            mainCategoryID: mainCategoryID.value,
                            type: parseInt(type.value, 10),
                            standardID: standardID.value
                        },
                        false,
                        false
                    );
                }
            }
        );
    };

    render() {
        const { t } = this.props;
        const formClassName = `clearfix beacon-form ${this.props.colorButton}`;
        return (
            <div>
                <form onSubmit={this.handleSubmit} className={formClassName}>
                    <Row>
                        <Col xs={12}>
                            {!this.props.selectedMeasurementPointList
                                .temporary && (
                                <Button
                                    bsStyle="link"
                                    type="button"
                                    className="pull-right"
                                    onClick={this.handleCopyClick}
                                >
                                    {this.state.isCopying
                                        ? 'Cancel Copy'
                                        : 'Copy'}
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <FormGenerator
                        onMount={this.setForm}
                        fieldConfig={this.state.fieldConfig}
                    />
                    <Row>
                        <Col xs={12}>
                            <Button
                                bsStyle="link"
                                type="button"
                                className=""
                                onClick={
                                    this.props
                                        .toggleEditMeasurementPointListTestProceduresModal
                                }
                            >
                                {t('editProcedureButton')}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="">
                            <Button
                                bsStyle="link"
                                type="button"
                                className=""
                                onClick={this.handleAddGroup}
                            >
                                Add Group
                            </Button>

                            <Button
                                bsStyle="link"
                                type="button"
                                className=""
                                onClick={this.handleAddQuestion}
                            >
                                Add Question
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="">
                            <MeasurementPointList
                                selectedTab={this.props.selectedTab}
                                setSelectedMeasurementPoint={
                                    this.setSelectedMeasurementPoint
                                }
                                deleteMeasurementPoint={
                                    this.deleteMeasurementPoint
                                }
                                updateMeasurementPointListTab={
                                    this.props.updateMeasurementPointListTab
                                }
                                canEditGlobal={this.props.canEditGlobal}
                                isCustomerView={this.props.isCustomerView}
                            />
                        </Col>
                    </Row>

                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.handleCancel}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            bsStyle={this.props.colorButton}
                            type="submit"
                            disabled={this.props.loading}
                        >
                            {t('save')}
                        </Button>
                    </Col>
                </form>
            </div>
        );
    }
}
export default EditMeasurementPointListForm;
