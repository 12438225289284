/*
 * Edit Facility Form
 * Add and Edit facilities
 *
 */

import { omit } from 'lodash';
import * as React from 'react';
import { Button, Col } from 'react-bootstrap';
import { WithTranslation } from 'react-i18next';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    GroupProps,
    Validators
} from 'react-reactive-form';
import { toastr } from 'react-redux-toastr';
import { constants } from '../../constants/constants';

import { closeAllModals, deleteFacility } from '../../actions/commonActions';
import { updateFacilityContract } from '../../actions/manageFacilityActions';
import { getFSEUsers } from '../../actions/manageJobActions';
import {
    Ifacility,
    IfacilityContract,
    IfacilityWithoutBuildings,
    Ioption,
    IproductInfo,
    Iuser
} from '../../models';
import { facilitySiteSizeEnum } from '../../models-enums';
import { IeditFacilityFormValues } from '../../modelsForms';
import { FacilityContactControl } from './FacilityContactControl';
import { FacilityContractControl } from './FacilityContractControl';
import { FormUtil } from './FormUtil';

// add the bootstrap form-control class to the react-select select component

interface Iprops {
    handleSubmit: (facility: IfacilityWithoutBuildings) => void;
    handleEdit: (facility: IfacilityWithoutBuildings) => void;
    toggleModal: () => void;
    clearSelectedFacilityID: () => void;
    loading: boolean;
    colorButton: string;
    isFacilityContactModalOpen: boolean;
    isFacilityContractModalOpen: boolean;
    updateFormValue: (formValue: { [key: string]: any }) => void;
    setFormValues: (formValues: IeditFacilityFormValues) => void;
    updateFacilityContract: typeof updateFacilityContract;
    formValues: IeditFacilityFormValues;
    selectedFacility?: Ifacility;
    selectedFacilityContract?: IfacilityContract;
    fseOptions: Ioption[];
    productInfo: IproductInfo;
    getProductInfo: () => void;
    selectedLeadFSE: Iuser;
    deleteFacility: typeof deleteFacility;
    disabled?: boolean;
    fseUsersByID: { [key: string]: Iuser };
    getFSEUsers: typeof getFSEUsers;
    user: Iuser;
    toggleFacilityContractModal: () => void;
    toggleFacilityContactModal: () => void;
    closeAllModals: typeof closeAllModals;
    approveMode: boolean;
    searchForFacility: (facilityID: string) => void;
    showSearchFacilityModal?: boolean;
    refreshTable: () => void;
}

interface IState {
    fieldConfig: FieldConfig;
}

class EditFacilityForm extends React.Component<
    Iprops & WithTranslation,
    IState
> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private debounce: any;
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
        this.formGroup = undefined;
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.clearSelectedFacilityID();
        this.props.setFormValues({});
        this.cleanForm();
    }

    componentDidMount() {
        this.props.getFSEUsers();
        if (this.props.selectedFacility && this.props.selectedFacility.id) {
            this.props.setFormValues(this.itemToFormValues());
            this.setState({
                fieldConfig: this.buildFieldConfig(this.itemToFormValues())
            });
        }
        if (this.props.selectedFacilityContract) {
            this.props.updateFacilityContract(
                this.props.selectedFacilityContract
            );
        }
        // manage users does not retrieve productInfo.  We need the productInfo.standards so we get product info here
        if (this.props.productInfo.standardOptions.length === 0) {
            this.props.getProductInfo();
        }
    }

    componentDidUpdate(prevProps: Iprops) {
        if (
            this.props.productInfo.standardOptions.length !==
            prevProps.productInfo.standardOptions.length
        ) {
            if (this.props.selectedFacility && this.props.selectedFacility.id) {
                this.setState({
                    fieldConfig: this.buildFieldConfig(this.itemToFormValues())
                });
            } else {
                this.setState({
                    fieldConfig: this.buildFieldConfig()
                });
            }
        }

        if (this.props.formValues.contract !== prevProps.formValues.contract) {
            this.setState({
                fieldConfig: this.buildFieldConfig()
            });
        }
    }

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IeditFacilityFormValues => {
        if (!this.props.selectedFacility) {
            throw new Error('missing selected facility');
        }
        const { selectedLeadFSE } = this.props;
        const sanitizedFacility = omit(this.props.selectedFacility, [
            'buildings'
        ]);
        const { productInfo, selectedFacility } = this.props;
        const selectedCountry = constants.countries[selectedFacility.countryID];
        const countryIDOption = selectedCountry || { value: '', label: '' };

        const selectedStandardOption = FormUtil.convertToSingleOption(
            productInfo.standards[selectedFacility.standardID]
        ) || { value: '', label: '' };

        let selectedSiteSize,
            selectedInspectionProvider,
            selectedMaintenanceProvider,
            selectedAGSLEVProvider,
            selectedEnvironmentalTestProvider,
            selectedAuthorizedCompany,
            selectedManagedByID;
        if (selectedFacility.siteSize) {
            selectedSiteSize = {
                label: this.props.t(
                    facilitySiteSizeEnum[selectedFacility.siteSize]
                ),
                value: selectedFacility.siteSize
            };
        }

        if (selectedFacility.inspectionProviderID) {
            selectedInspectionProvider =
                productInfo.brands[selectedFacility.inspectionProviderID];
        }

        if (selectedFacility.maintenanceProviderID) {
            selectedMaintenanceProvider =
                productInfo.brands[selectedFacility.maintenanceProviderID];
        }

        if (selectedFacility.agslevProviderID) {
            selectedAGSLEVProvider =
                productInfo.brands[selectedFacility.agslevProviderID];
        }

        if (selectedFacility.environmentalTestProviderID) {
            selectedEnvironmentalTestProvider =
                productInfo.brands[
                    selectedFacility.environmentalTestProviderID
                ];
        }

        if (selectedFacility.authorizedCompanyID) {
            selectedAuthorizedCompany =
                productInfo.brands[selectedFacility.authorizedCompanyID];
        }
        if (selectedFacility.managedByID) {
            selectedManagedByID =
                productInfo.brands[selectedFacility.managedByID];
        }

        return {
            ...sanitizedFacility,
            countryID: countryIDOption,
            standardID: selectedStandardOption,
            siteSize: selectedSiteSize,
            managedByID: FormUtil.convertToSingleOption(selectedManagedByID),
            leadFSEID: FormUtil.convertToSingleOption(selectedLeadFSE),
            inspectionProviderID: FormUtil.convertToSingleOption(
                selectedInspectionProvider
            ),
            maintenanceProviderID: FormUtil.convertToSingleOption(
                selectedMaintenanceProvider
            ),
            agslevProviderID: FormUtil.convertToSingleOption(
                selectedAGSLEVProvider
            ),
            environmentalTestProviderID: FormUtil.convertToSingleOption(
                selectedEnvironmentalTestProvider
            ),
            authorizedCompanyID: FormUtil.convertToSingleOption(
                selectedAuthorizedCompany
            )
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (): IfacilityWithoutBuildings => {
        const formValues = FormUtil.getValues(this.formGroup.value);

        const sanitizedFacility = omit(this.props.selectedFacility, [
            'buildings'
        ]);
        const cleanedFormValues = omit(formValues, 'contact');

        return {
            ...sanitizedFacility,
            ...cleanedFormValues
        };
    };

    canManageContacts = () => {
        return constants.hasSecurityFunction(this.props.user, [
            constants.securityFunctions.AllowAllCustomers.id
        ]);
    };

    buildFieldConfig = (
        defaultValues: IeditFacilityFormValues = this.props.formValues
    ) => {
        const disabled = this.props.disabled || false;
        const isSAP = this.props.selectedFacility?.sapFacilityNumber
            ? true
            : false;
        const { productInfo } = this.props;

        const countryOptions = constants.countries;

        // State dropdown's disabled state is set by the props by default
        let isStateRequired = false;

        // Postal code is only required for U.S., Canada, UK, and Germany
        let isPostalCodeRequired = false;

        // Set default country
        const country: any = defaultValues.countryID;
        let countryID = '';

        // If no country was passed in, let's use the one assigned to the current user
        if (country === undefined) {
            countryID = this.props.user.countryID;
        } else {
            // Make sure this country object has a property called value, which should be the country ID
            if (country.value) {
                countryID = country.value;
            }
        }

        const selectedCountry = constants.countries[countryID];
        const countryIDOption = selectedCountry || { value: '', label: '' };

        // State drop down is only allowed for U.S., Canada, Malaysia
        if (
            countryID === constants.usCountryID ||
            countryID === constants.canadaCountryID
        ) {
            isStateRequired = true;
        }

        if (
            countryID === constants.usCountryID ||
            countryID === constants.ukCountryID ||
            countryID === constants.canadaCountryID ||
            countryID === constants.germanyCountryID.toLowerCase()
        ) {
            isPostalCodeRequired = true;
        }

        const fieldConfigControls = {
            name: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityForm.nameLabel',
                    colWidth: 12,
                    name: 'fac-name'
                },
                formState: { value: defaultValues.name, disabled: isSAP }
            },
            name2: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityForm.name2Label',
                    colWidth: 12,
                    name: 'fac-name2'
                },
                formState: { value: defaultValues.name2, disabled: isSAP }
            },
            standardID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.standardOptions,
                    label: 'standard',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'standard',
                    required: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: defaultValues.standardID,
                    disabled
                }
            },
            address: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:address',
                    colWidth: 6,
                    type: 'text',
                    name: 'address'
                },
                formState: { value: defaultValues.address, disabled: isSAP }
            },
            address2: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:address2',
                    colWidth: 6,
                    type: 'text',
                    name: 'address2',
                    required: false
                },
                formState: { value: defaultValues.address2, disabled: isSAP }
            },
            city: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:city',
                    colWidth: 5,
                    type: 'text',
                    name: 'city'
                },
                formState: { disabled: isSAP, value: defaultValues.city }
            },
            state: {
                options: {
                    validators: isStateRequired
                        ? [FormUtil.validators.requiredWithTrim]
                        : []
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:state',
                    colWidth: 3,
                    type: 'text',
                    name: 'state',
                    required: isStateRequired
                },
                formState: {
                    value: defaultValues.state,
                    disabled: isSAP
                }
            },
            postalCode: {
                options: {
                    validators: isPostalCodeRequired
                        ? [
                              FormUtil.validators.requiredWithTrim,
                              Validators.pattern(
                                  /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/
                              )
                          ]
                        : [
                              Validators.pattern(
                                  /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/
                              )
                          ]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'user:zip',
                    colWidth: 4,
                    type: 'tel',
                    name: 'postal-code',
                    required: isPostalCodeRequired
                },
                formState: {
                    value: defaultValues.postalCode,
                    disabled: isSAP
                }
            },
            countryID: {
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                render: FormUtil.Select,
                meta: {
                    options: countryOptions,
                    label: 'user:country',
                    colWidth: 12,
                    placeholder: 'manageUserQueue:countrySearchPlaceholder',
                    name: 'country'
                },
                formState: {
                    value: countryIDOption,
                    disabled: isSAP
                }
            },
            sapFacilityNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityForm.sapFacilityNumber',
                    colWidth: 12,
                    type: 'text',
                    name: 'sapFacilityNumber',
                    required: false
                },
                formState: { disabled, value: defaultValues.sapFacilityNumber }
            },
            preventativeMaintenanceEnabled: {
                render: FormUtil.Toggle,
                meta: {
                    label: 'facilityForm.preventativeMaintenanceEnabled',
                    colWidth: 12
                },
                formState: {
                    value: defaultValues.preventativeMaintenanceEnabled,
                    disabled
                }
            },
            $field_0: {
                isStatic: false,
                render: () =>
                    this.props.selectedFacility?.id ? (
                        <FormUtil.TextLabelStatic
                            meta={{
                                label: this.props.t(
                                    'facilityContactForm.manageContactsButton'
                                ),
                                style: {
                                    marginTop: '15px'
                                }
                            }}
                        />
                    ) : null
            },
            contact: {
                render: FacilityContactControl,
                meta: {
                    buttonLabel: this.props.t(
                        'facilityContactForm.manageContactsButton'
                    ),
                    colWidth: 12,
                    colorButton: this.props.colorButton,
                    toggleModal: this.props.toggleFacilityContactModal,
                    remove: !this.props.selectedFacility?.id
                },
                formState: {
                    value: defaultValues.contact,
                    disabled: !this.canManageContacts()
                }
            },
            $field_1: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: this.props.t(
                                'facilityForm.moreDetailsHeading'
                            ),
                            style: { marginTop: '15px' }
                        }}
                    />
                )
            },
            siteSize: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        facilitySiteSizeEnum
                    ),
                    label: 'facilityForm.siteSize',
                    colWidth: 12,
                    name: 'site-size',
                    required: false,
                    isClearable: true,
                    shouldTranslate: true,
                    disableSort: true
                },
                formState: { disabled, value: defaultValues.siteSize }
            },
            managedByID: {
                render: FormUtil.Select,
                meta: {
                    label: 'facilityForm.managedBy',
                    colWidth: 12,
                    isClearable: true,
                    name: 'managed-by',
                    required: false,
                    options: productInfo.brandOptions
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: { value: defaultValues.managedByID, disabled }
            },
            leadFSEID: {
                render: FormUtil.Select,
                meta: {
                    options: this.props.fseOptions,
                    label: 'facilityForm.leadFSE',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'lead-fse',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.leadFSEID,
                    disabled
                }
            },
            type: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityForm.facilityType',
                    colWidth: 12,
                    type: 'text',
                    name: 'type',
                    required: false
                },
                formState: { value: defaultValues.type, disabled }
            },
            mgpsSite: {
                render: FormUtil.Toggle,
                meta: { label: 'facilityForm.mgpsSite', colWidth: 6 },
                formState: { value: defaultValues.mgpsSite, disabled }
            },
            inspectionProviderID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.brandOptions,
                    label: 'facilityForm.inspectionProvider',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'inspection-provider',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.inspectionProviderID,
                    disabled
                }
            },
            maintenanceProviderID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.brandOptions,
                    label: 'facilityForm.maintenanceProvider',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'maintanence-provider',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.maintenanceProviderID,
                    disabled
                }
            },
            agslevProviderID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.brandOptions,
                    label: 'facilityForm.agslevProvider',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'ags-lev-provider',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.agslevProviderID,
                    disabled
                }
            },
            environmentalTestProviderID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.brandOptions,
                    label: 'facilityForm.environmentalTestsProvider',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'environmental-tests-provider',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.environmentalTestProviderID,
                    disabled
                }
            },
            authorizedCompanyID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.brandOptions,
                    label: 'facilityForm.authorizedCompany',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'authorized-company',
                    required: false,
                    isClearable: true
                },
                formState: {
                    value: defaultValues.authorizedCompanyID,
                    disabled
                }
            },
            authorizedPerson: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityForm.authorizedPerson',
                    colWidth: 12,
                    type: 'input',
                    name: 'authorized-person',
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(80)]
                },
                formState: { value: defaultValues.authorizedPerson, disabled }
            },
            website: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityForm.website',
                    colWidth: 12,
                    type: 'input',
                    name: 'website',
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(1000)]
                },
                formState: { value: defaultValues.website, disabled }
            },
            comments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'common:comments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 5,
                    name: 'comments',
                    initialContent: defaultValues.comments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(1000)]
                },
                formState: { value: defaultValues.comments, disabled }
            },
            contract: {
                render: FacilityContractControl,
                meta: {
                    label: 'facilityForm.contracts',
                    buttonLabel: this.props.t(
                        'facilityForm.manageContractsButton'
                    ),
                    colWidth: 12,
                    colorButton: this.props.colorButton,
                    toggleModal: this.props.toggleFacilityContractModal,
                    t: this.props.t
                },
                formState: { value: defaultValues.contract, disabled }
            }
        } as { [key: string]: GroupProps };
        let fieldConfig = {
            controls: { ...fieldConfigControls }
        };

        fieldConfig = {
            controls: { ...fieldConfigControls }
        };

        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'countryID':
                this.onCountryChanges(value);
                break;
            default:
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.props.updateFormValue({ [key]: value });
                }, 200);
                break;
        }
    };

    onCountryChanges = (value: any) => {
        const stateFormControl = this.formGroup.get('state');
        const postalCodeFormControl = this.formGroup.get('postalCode');
        stateFormControl.clearValidators();
        postalCodeFormControl.clearValidators();

        // State
        if (
            value &&
            value.value &&
            (value.value === constants.usCountryID ||
                value.value === constants.canadaCountryID)
        ) {
            stateFormControl.setValidators([
                FormUtil.validators.requiredWithTrim
            ]);
            stateFormControl.meta.required = true;
            stateFormControl.patchValue(null);
            this.props.updateFormValue({ state: '' });
        } else {
            stateFormControl.meta.required = false;
            this.props.updateFormValue({ state: '' });
            stateFormControl.patchValue(null);
        }

        // Postal Code
        if (
            value &&
            value.value &&
            (value.value === constants.usCountryID ||
                value.value === constants.ukCountryID ||
                value.value === constants.canadaCountryID ||
                value.value === constants.germanyCountryID)
        ) {
            postalCodeFormControl.setValidators([
                FormUtil.validators.requiredWithTrim,
                Validators.pattern(
                    /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/
                )
            ]);
            postalCodeFormControl.meta.required = true;
            postalCodeFormControl.patchValue(null);
            this.props.updateFormValue({ postalCode: '' });
        } else {
            postalCodeFormControl.meta.required = false;
            this.props.updateFormValue({ postalCode: '' });
            postalCodeFormControl.patchValue(null);
        }
    };

    canUseSAP = (countryID: string): boolean => {
        if (
            countryID === constants.usCountryID ||
            countryID === constants.canadaCountryID
        ) {
            return true;
        } else {
            return false;
        }
    };

    handleSubmit = (
        e: React.MouseEvent<HTMLFormElement>,
        shouldApprove = false
    ) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        let facilityToUpdate = this.formValuesToItem();

        (Object.keys(
            facilityToUpdate
        ) as (keyof IfacilityWithoutBuildings)[]).forEach(key => {
            if (key.includes('ID') && facilityToUpdate[key] == '') {
                facilityToUpdate = {
                    ...facilityToUpdate,
                    [key]: null
                };
            }
        });

        if (this.props.selectedFacility && this.props.selectedFacility.id) {
            if (shouldApprove) {
                // If approving a facility, SAP facility number is required
                // Right now, only U.S. and Canada is using SAP

                facilityToUpdate.isApproved = true;
            }
            this.props.handleEdit({
                ...facilityToUpdate,
                contract: facilityToUpdate.contract
                    ? {
                          ...facilityToUpdate.contract,
                          facilityID: this.props.selectedFacility?.id ?? ''
                      }
                    : undefined
            });

            this.props.refreshTable();
        } else {
            this.props.handleSubmit(facilityToUpdate);
        }
    };

    handleDelete = () => {
        const toastrConfirmOptions = {
            onOk: () => {
                this.props.deleteFacility();
                this.props.refreshTable();
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: this.props.t('deleteOk'),
            cancelText: this.props.t('common:cancel')
        };
        toastr.confirm(this.props.t('deleteConfirm'), toastrConfirmOptions);
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    cleanForm = () => {
        if (this.formGroup) {
            this.formGroup.reset();
        }
    };

    closeModals = () => {
        this.props.toggleModal();

        if (this.props.isFacilityContactModalOpen) {
            this.props.toggleFacilityContactModal();
        }

        if (this.props.isFacilityContractModalOpen) {
            this.props.toggleFacilityContractModal();
        }
    };

    openSearchFacilityModal = () => {
        this.props.searchForFacility(
            this.props.selectedFacility ? this.props.selectedFacility.id : ''
        );
    };

    render() {
        const { t, showSearchFacilityModal } = this.props;

        const deleteButtonStyle =
            this.props.selectedFacility && this.props.selectedFacility.id
                ? { marginRight: '15px' }
                : { marginRight: '15px', display: 'none' };
        return (
            <>
                {this.props.approveMode && (
                    <div>
                        <Button
                            bsStyle="link"
                            type="button"
                            disabled={this.props.loading}
                            onClick={this.openSearchFacilityModal}
                            style={{ marginBottom: '-5px' }}
                            className="pull-right"
                        >
                            Search for existing Facility
                        </Button>
                    </div>
                )}
                <form
                    onSubmit={this.handleSubmit}
                    className="clearfix beacon-form facility-form"
                >
                    <FormGenerator
                        onUnmount={this.cleanForm}
                        onMount={this.setForm}
                        fieldConfig={this.state.fieldConfig}
                    />
                    <Col xs={12} className="form-buttons text-right">
                        {showSearchFacilityModal !== true && (
                            <Button
                                bsStyle="default"
                                type="button"
                                className="pull-left"
                                onClick={() => {
                                    this.closeModals();
                                    this.props.setFormValues({});
                                }}
                            >
                                {t('cancel')}
                            </Button>
                        )}
                        {this.props.disabled !== true &&
                            this.props.approveMode === false && (
                                <div>
                                    <Button
                                        bsStyle="warning"
                                        style={deleteButtonStyle}
                                        type="button"
                                        className=""
                                        disabled={this.props.loading}
                                        onClick={this.handleDelete}
                                    >
                                        {t('common:delete')}
                                    </Button>
                                    <Button
                                        bsStyle={this.props.colorButton}
                                        type="submit"
                                        disabled={this.props.loading}
                                    >
                                        {t('save')}
                                    </Button>
                                </div>
                            )}
                        {this.props.approveMode === true && (
                            <div>
                                <Button
                                    bsStyle={this.props.colorButton}
                                    type="submit"
                                    disabled={this.props.loading}
                                >
                                    {t('save')}
                                </Button>

                                <Button
                                    bsStyle={this.props.colorButton}
                                    onClick={(evt: any) =>
                                        this.handleSubmit(evt, true)
                                    }
                                    disabled={this.props.loading}
                                    style={{ marginLeft: '10px' }}
                                >
                                    {t('saveAndApprove')}
                                </Button>
                            </div>
                        )}
                    </Col>
                </form>
            </>
        );
    }
}

export default EditFacilityForm;
