import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import {
    clearSelectedWorkOrderID,
    deleteWorkOrder,
    getFSEsWithWorkOrder,
    getWorkOrderCsv,
    getWorkOrders,
    setSelectedWorkOrderID,
    setTableFilter,
    toggleEditWorkOrderModal,
    updateWorkOrderSelection
} from '../../actions/manageWorkOrderActions';
import {
    IcountryBE,
    Ioption,
    ItableFiltersReducer,
    Itile,
    Iuser,
    IWorkOrder
} from '../../models';

import { debounce } from 'lodash';
import { FieldConfig } from 'react-reactive-form';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { RouteComponentProps } from 'react-router';
import { closeAllModals } from '../../actions/commonActions';
import { constants } from '../../constants/constants';
import {
    workOrderDateRangeUnumForFilter,
    workOrderStatusEnum,
    workOrderStatusEnumForFilter,
    workOrderTabEnum,
    workOrderTypesEnum,
    workOrderVendorsEnum
} from '../../models-enums';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { getCountries } from '../../reducers/countriesReducer';
import { emptyTile, initialWorkOrder } from '../../reducers/initialState';
import { selectVisibleWorkOrdersByID } from '../../reducers/manageWorkOrderReducer';
import { Banner } from '../common/Banner';
import { FormUtil } from '../common/FormUtil';
import SearchTableForm from '../common/SearchTableForm';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { ConfirmSelectJobContainer } from './ConfirmSelectJobContainer';
import { EditWorkOrderModal } from './EditWorkOrderModal';
import { WorkOrderBeaconTableContainer } from './WorkOrderBeaconTableContainer';
import { WorkOrderCloseModal } from './WorkOrderCloseModal';
import NewJobModal from './modals/NewJobModal';
import { getAllOpenJobsForWorkOrders } from '../../actions/manageJobActions';
import { getSAPJobMapping } from '../../actions/commonActions';

type Iprops = RouteComponentProps<any>;

interface IdispatchProps {
    tableData: IWorkOrder[];
    totalPages: number;
    showEditWorkOrderModal: boolean;
    getWorkOrders: typeof getWorkOrders;
    toggleEditWorkOrderModal: typeof toggleEditWorkOrderModal;
    deleteWorkOrder: typeof deleteWorkOrder;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    loading: boolean;
    setSelectedWorkOrderID: typeof setSelectedWorkOrderID;
    clearSelectedWorkOrderID: typeof clearSelectedWorkOrderID;
    getWorkOrderCsv: typeof getWorkOrderCsv;
    selectedFacilityID: string;
    selectedWorkOrderID: string;
    selectedWorkOrder: IWorkOrder;
    manageWorkOrderAdmin: boolean;
    manageWorkOrderPMP: boolean;
    manageCustomerWorkOrders: boolean;
    user: Iuser;
    userOptions: Ioption[];
    selection: string[];
    closeAllModals: typeof closeAllModals;
    updateWorkOrderSelection: typeof updateWorkOrderSelection;
    getFSEsWithWorkOrder: typeof getFSEsWithWorkOrder;
    allCountries: IcountryBE[];
    getAllOpenJobsForWorkOrders: typeof getAllOpenJobsForWorkOrders;
    getSAPJobMapping: typeof getSAPJobMapping;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    searchFieldConfig: FieldConfig;
    showCloseNoteModal: boolean;
    showNewJobModal: boolean;
}

class ManageWorkOrder extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    private setTableFilterDebounced: (formValues: {
        [key: string]: any;
    }) => void;
    static defaultProps = {
        selection: []
    };
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            searchFieldConfig: this.buildSearchControls(),
            showCloseNoteModal: false,
            showNewJobModal: false
        };
        this.setTableFilterDebounced = debounce(
            this.props.setTableFilter,
            constants.formDebounceTime
        );
    }

    componentDidMount(): void {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });

        const defaultStatus = FormUtil.convertToSingleOption({
            id: workOrderStatusEnumForFilter.New.toString(),
            label: this.props.t('new')
        });

        const activeCountryOption: Ioption = {
            value: this.props.user.countryID || '',
            label:
                this.props.allCountries?.find(
                    c => c.id === this.props.user.countryID
                )?.name || ''
        };

        this.props.setTableFilter({
            workOrderType: workOrderTypesEnum.repair,
            vendor: workOrderVendorsEnum.beacon,
            tabKey: workOrderTabEnum.beacon,
            page: 1,
            statusFilter: defaultStatus,
            isDeleted: false,
            sapWorkOrder: true,
            countryID: activeCountryOption
        });

        document.addEventListener(
            'updatedWorkOrders',
            this.handleUpdatedData,
            false
        );
        this.props.getFSEsWithWorkOrder();
        this.props.getAllOpenJobsForWorkOrders();
        this.props.getSAPJobMapping();
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditWorkOrderModal !==
                this.props.showEditWorkOrderModal &&
            !this.props.showEditWorkOrderModal
        ) {
            this.setState({ selectedRow: null });
        }

        // check to see if tableFilters are exactly the same, tiggers even when the values are the same
        if (prevProps.tableFilters !== this.props.tableFilters) {
            this.props.getWorkOrders();

            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }

        if (prevProps.userOptions.length !== this.props.userOptions.length) {
            this.setState({ searchFieldConfig: this.buildSearchControls() });
        }
    }

    componentWillUnmount() {
        document.removeEventListener(
            'updatedWorkOrders',
            this.handleUpdatedData,
            false
        );
        this.props.closeAllModals();
        this.props.setTableFilter({
            vendor: undefined,
            page: 1,
            assignedUser: undefined
        });
    }

    handleUpdatedData = () => {
        this.props.getWorkOrders();
        this.setState({ selectedRow: {} });
    };

    /*
     * Note that technicianToDo is not an actual filter.  it is only used to select which To Do List to download
     */
    buildSearchControls = (): FieldConfig => {
        const disabled = false;

        const activeCountryOption: Ioption = {
            value: this.props.user.countryID || '',
            label:
                this.props.allCountries?.find(
                    c => c.id === this.props.user.countryID
                )?.name || ''
        };
        const countriesOptions: Ioption[] = this.props.allCountries.map(c => ({
            label: c.name,
            value: c.id
        }));

        const defaultStatus = FormUtil.convertToSingleOption({
            id: workOrderStatusEnumForFilter.New.toString(),
            label: this.props.t('new')
        });
        const {
            search,
            statusFilter = defaultStatus,
            dateRange,
            tabKey,
            assignedUser
        } = this.props.tableFilters;

        const searchPlaceholder =
            this.state && tabKey === 'beacon'
                ? 'searchNumberPlaceholderBeacon'
                : 'searchNumberPlaceholder';

        const mainSearchControls = {
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:search',
                    colWidth: 3,
                    type: 'text',
                    placeholder: searchPlaceholder,
                    isClearable: true
                },
                formState: { value: search, disabled }
            },
            countryID: {
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                render: FormUtil.Select,
                meta: {
                    options: countriesOptions,
                    label: 'user:primaryCountry',
                    colWidth: 2,
                    placeholder: 'manageUserQueue:countrySearchPlaceholder',
                    name: 'country'
                },
                formState: { value: activeCountryOption, disabled }
            },
            statusFilter: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        workOrderStatusEnumForFilter
                    ),
                    label: 'status',
                    colWidth: 2,
                    isClearable: false,
                    shouldTranslate: true
                },
                formState: { value: statusFilter, disabled }
            },
            dateRange: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        workOrderDateRangeUnumForFilter
                    ),
                    label: 'dateRange',
                    colWidth: 2,
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: { value: dateRange, disabled }
            },
            fseUser: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: this.props.userOptions,
                    label: 'FSENameTableHeader',
                    colWidth: 3,
                    isClearable: true,
                    shouldTranslate: true,
                    dataType: 'fseUser'
                },
                formState: { value: assignedUser || '', disabled }
            },
            sapWorkOrder: {
                render: FormUtil.Toggle,
                meta: {
                    label: 'sapWorkOrder',
                    colWidth: 2,
                    innerStyle: {
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center'
                    }
                },
                formState: {
                    value: true,
                    disabled
                }
            },
            isDeleted: {
                render: FormUtil.Toggle,
                meta: {
                    label: 'showDeleted',
                    colWidth: 2,
                    innerStyle: {
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center'
                    }
                },
                formState: {
                    value: false,
                    disabled
                }
            },
            seperator: {
                render: FormUtil.TextLabel,
                meta: {
                    colWidth: 1
                }
            }
        };

        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    };

    downloadCsv(tech: Ioption | null) {
        const { t } = this.props;
        if (tech && tech.value) {
            this.props.getWorkOrderCsv(tech.value);
        } else {
            toastr.warning(
                t('common:warning'),
                t('Please select a technician.'),
                constants.toastrWarning
            );
        }
    }

    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'search':
                this.setTableFilterDebounced({ search: value, page: 1 });
                break;
            case 'statusFilter':
                this.setTableFilterDebounced({
                    statusFilter: value ? value : null,
                    page: 1
                });
                break;
            case 'countryID':
                this.setTableFilterDebounced({
                    countryID: value ? value : null,
                    page: 1
                });
                break;
            case 'isDeleted':
                this.setTableFilterDebounced({
                    isDeleted: value,
                    page: 1
                });
                break;
            case 'sapWorkOrder':
                this.setTableFilterDebounced({
                    sapWorkOrder: value,
                    page: 1
                });
                break;
            case 'dateRange':
                this.setTableFilterDebounced({
                    dateRange: value ? value : null,
                    page: 1
                });
                break;
            case 'fseUser':
                this.setTableFilterDebounced({
                    assignedUser: value ? value : null,
                    page: 1
                });
                break;
            case 'technicianToDo':
                this.downloadCsv(value);
                this.setState({
                    searchFieldConfig: this.buildSearchControls()
                }); // this clears the select
                // this.setTableFilterDebounced({ technicianToDo: value, page: 1 });
                break;
            default:
                break;
        }
    };

    handleEdit(row: any) {
        this.setState({ selectedRow: row.index });
        this.props.toggleEditWorkOrderModal();
        this.props.setSelectedWorkOrderID(row.original.id);
    }

    /*
     * Show the action button if the work order is new or reopened
     * hide the action button if on the Repair tab and the work order is assigned to Beacon. These should be closed on the beacon tab only
     * we will need to stop hiding the button when not on the Beacon tab once we add more actions.  Might hide the close option from the dropdown in that case.d
     */
    shouldShowActionButton = (workOrder: IWorkOrder) => {
        return (
            (workOrder.status === workOrderStatusEnum.reopened ||
                workOrder.status === workOrderStatusEnum.new) &&
            (this.props.tableFilters.tabKey === workOrderTabEnum.repair &&
                workOrder.vendor === workOrderVendorsEnum.beacon) === false &&
            workOrder.vendor !== workOrderVendorsEnum.beacon // TODO temporarily prevent closing beacon work orders
        );
    };

    // disabled on the PMP tab and hide the delete and save buttons.
    shouldEnableWorkOrderForm = () => {
        return this.props.tableFilters.tabKey === workOrderTabEnum.preventative;
    };

    render() {
        const { t, tableFilters } = this.props;
        const { tabKey = workOrderTabEnum.repair } = tableFilters;
        const colorButton =
            constants.colors[
                `${this.state.currentTile.color}Button` as keyof typeof constants.colors
            ];

        return (
            <div className="manage-work-order" data-tab={tabKey}>
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                >
                    {tabKey !== workOrderTabEnum.beacon && (
                        <SelectFacilityContainer
                            t={this.props.t}
                            classNameOverride={''}
                        />
                    )}
                </Banner>
                <SearchTableForm
                    fieldConfig={this.state.searchFieldConfig}
                    handleSubmit={this.props.getWorkOrders}
                    loading={this.props.loading}
                    colorButton={colorButton}
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                    t={this.props.t}
                    showSearchButton={false}
                />

                <WorkOrderBeaconTableContainer
                    t={t}
                    colorButton={colorButton}
                    currentTile={this.state.currentTile}
                />

                <EditWorkOrderModal
                    colorButton={colorButton}
                    disabled={this.shouldEnableWorkOrderForm()}
                    shouldDisableVendorSelect={
                        this.props.tableFilters.tabKey ===
                        workOrderTabEnum.beacon
                    }
                    shouldHideTechnicianSelect={
                        this.props.tableFilters.tabKey !==
                        workOrderTabEnum.beacon
                    }
                />
                <ConfirmSelectJobContainer
                    history={this.props.history}
                    colorButton={colorButton}
                    showNewJobModal={() =>
                        this.setState({ showNewJobModal: true })
                    }
                />

                <NewJobModal
                    colorButton={colorButton}
                    showModal={this.state.showNewJobModal}
                    secondModal={true}
                    closeModal={(closeAll: boolean = false) => {
                        this.setState({ showNewJobModal: false });
                        if (closeAll) {
                            this.props.closeAllModals();
                        }
                    }}
                    onHide={() => this.setState({ showNewJobModal: false })}
                />

                <WorkOrderCloseModal colorButton={colorButton} />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState) => {
    const tableData = selectVisibleWorkOrdersByID(state);
    const userOptions = state.manageWorkOrder.fsesWithWorkOrders?.map(user => {
        const name = `${user.first} ${user.last}`;
        return { label: name, value: user.id };
    });

    const manageWorkOrderAdmin = constants.hasSecurityFunction(state.user, [
        constants.securityFunctions.ManageWorkOrderAdmin.id
    ]);
    const manageWorkOrderPMP = constants.hasSecurityFunction(state.user, [
        constants.securityFunctions.ManageWorkOrderPMP.id
    ]);
    const manageCustomerWorkOrders = constants.hasSecurityFunction(state.user, [
        constants.securityFunctions.ManageCustomerWorkOrders.id
    ]);

    const selectedWorkOrder =
        state.manageWorkOrder.workOrdersByID[
            state.manageWorkOrder.selectedWorkOrderID
        ] || initialWorkOrder;

    const allCountries = getCountries(state);

    return {
        tableData,
        manageWorkOrderAdmin,
        manageWorkOrderPMP,
        manageCustomerWorkOrders,
        totalPages: state.manageWorkOrder.totalPages,
        showEditWorkOrderModal: state.manageWorkOrder.showEditWorkOrderModal,
        tableFilters: state.manageWorkOrder.tableFilters,
        selectedFacilityID: state.selectedFacilityID,
        selectedWorkOrderID: state.manageWorkOrder.selectedWorkOrderID,
        loading: selectIsLoading(state),
        selectedWorkOrder,
        user: state.user,
        userOptions,
        allCountries
    };
};

export default withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        getWorkOrders,
        toggleEditWorkOrderModal,
        deleteWorkOrder,
        setTableFilter,
        setSelectedWorkOrderID,
        clearSelectedWorkOrderID,
        getWorkOrderCsv,
        closeAllModals,
        updateWorkOrderSelection,
        getFSEsWithWorkOrder,
        getAllOpenJobsForWorkOrders,
        getSAPJobMapping
    })(ManageWorkOrder)
);
