import { ThunkResult, ItableFiltersParams, Iquote, Iuser } from '../models';
import {
    beginAjaxCall,
    manualAjaxEnd,
    manualAjaxStart
} from './ajaxStatusActions';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../constants/apiEndpoints';
import { msalFetch } from '../components/auth/Auth-Utils';
import * as types from './actionTypes';
import { constants } from '../constants/constants';
import { omit } from 'lodash';
import { FormUtil } from '../components/common/FormUtil';
import { initialQuote } from '../reducers/initialState';

/*
 * helper function to correctly render global search component as well as send facility Id
 */
export function getIsManageCustomerQuotesView(user: Iuser) {
    const hasManageCustomerQuotesSF = constants.hasSecurityFunction(user, [
        constants.securityFunctions.ManageCustomerQuotes.id
    ]);
    const salesSFs = [
        constants.securityFunctions.SalesService.id,
        constants.securityFunctions.SalesParts.id,
        constants.securityFunctions.SalesManager.id,
        constants.securityFunctions.SalesInstallation.id,
        constants.securityFunctions.SalesAdmin.id
    ];
    const hasSalesSFs = constants.hasSecurityFunction(user, salesSFs);
    const isManageCustomerQuotesView =
        hasManageCustomerQuotesSF && !hasSalesSFs ? true : false;
    return isManageCustomerQuotesView;
}

interface IquotesParams {
    page: number;
    search: string;
    status: string | boolean;
    sort: string;
    facilityID?: string;
}

export function getQuotes(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const {
            page,
            search,
            status
        } = getState().manageCustomerQuotes.tableFilters;

        let params: IquotesParams = {
            page,
            search,
            status: status ? status.value : false,
            sort: `createdate,desc`
        };
        const isManageCustomerQuotesView = getIsManageCustomerQuotesView(
            getState().user
        );

        if (isManageCustomerQuotesView) {
            const { selectedFacilityID: facilityID } = getState();
            params = { ...params, facilityID };
        }
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params
        };
        const url = API.quote.search;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.QUOTE_MANAGE_SUCCESS,
                        payload: resp.data.result
                    });
                    dispatch({
                        type: types.QUOTE_MANAGE_TOTAL_PAGES,
                        pages: resp.data.pages
                    });
                    return resp;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.QUOTE_MANAGE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get quotes');
                console.error(error);
            });
    };
}

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_QUOTES,
    filters
});

export const toggleQuotesModal = (show?: boolean) => ({
    type: types.TOGGLE_MODAL_QUOTES,
    show
});
export const toggleSalesQuotesModal = (show?: boolean) => ({
    type: types.TOGGLE_MODAL_SALES_QUOTES,
    show
});

export const toggleQuotesDetailsModal = (show?: boolean) => ({
    type: types.TOGGLE_MODAL_QUOTES_DETAILS,
    show
});

export const toggleAcceptQuoteModal = (show?: boolean) => ({
    type: types.TOGGLE_MODAL_ACCEPT_QUOTE,
    show
});

export const updateSelectedQuote = (quote = initialQuote) => ({
    type: types.UPDATE_SELECTED_QUOTE,
    payload: quote
});

export function updateQuoteStatus(quote: Iquote): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const cleanedQuote = omit(quote, ['signature', 'poFile']);

        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: cleanedQuote
        };

        const url = `${API.quote.updateStatus}/${cleanedQuote.id}`;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.QUOTE_UPDATE_STATUS_SUCCESS,
                        payload: quote
                    });
                    dispatch({ type: types.TOGGLE_MODAL_QUOTES });
                    dispatch(toggleQuotesDetailsModal(false));
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.QUOTE_UPDATE_STATUS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'update quote status');
                console.error(error);
            });
    };
}

export function saveQuoteFiles(quote: Iquote): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(manualAjaxStart());
        const headers = { 'content-type': 'multipart/form-data' };
        const formData = FormUtil.toFormData({
            quoteID: quote.id,
            POFile: quote.poFile,
            Signature: quote.signature,
            AuthorizedName: quote.authorizedName
        });
        // console.info('formData keys',[...formData.keys()])
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: formData,
            headers
        };

        const url = API.quote.saveQuoteFiles;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch(manualAjaxEnd());
                dispatch(updateQuoteStatus(quote));
            })
            .catch((error: any) => {
                dispatch(manualAjaxEnd());
                constants.handleError(error, 'save quote files');
                console.error(error);
            });
    };
}
