import React, { useEffect, useState } from 'react';
import { setIsMobile } from '../../actions/configActions';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

export default () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const disaptch = useDispatch();

    const handleWindowSizeChange = debounce(() => {
        setWidth(window.innerWidth);
    }, 200);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        disaptch(setIsMobile(width <= 1024));
    }, [width]);

    return null;
};
