import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Ioption,
    Iphoto,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { debounce, filter, keyBy, orderBy, pickBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import moment from 'moment';

import {
    deleteMultiplePhotos,
    getPhotos,
    setTableFilter
} from '../../actions/manageAssetPhotosActions';
import { initInventory } from '../../actions/manageInventoryActions';
import { getFacility } from '../../actions/manageLocationActions';
import { emptyTile } from '../../reducers/initialState';
import { Button } from 'react-bootstrap';

import { Banner } from '../common/Banner';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import { IproductInfo } from '../../models';
import { RouteComponentProps } from 'react-router';
import SearchTableForm from '../common/SearchTableForm';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { IinitialState } from '../../reducers';
import { dateRangeEnum } from '../../models-enums';
import { getJobOptionsFromFilters } from '../../reducers/manageJobReducer';
import { getJobsByID } from '../../actions/manageJobActions';

type Iprops = RouteComponentProps<any>;

interface IdispatchProps {
    getPhotos: typeof getPhotos;
    deleteMultiplePhotos: typeof deleteMultiplePhotos;
    galleryData: Iphoto[];
    t: TFunction;
    jobOptions: Ioption[];
    loading: boolean;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    documentTypeOptions: { [key: string]: any };
    productInfo: IproductInfo;
    initInventory: typeof initInventory;
    getFacility: typeof getFacility;
    selectedFacilityID: string;
    getJobsByID: typeof getJobsByID;
    user: Iuser;
}

interface Istate {
    currentTile: Itile;
    searchFieldConfig: FieldConfig;
    activePhoto: number;
    lightboxIsOpen: boolean;
    selectionEnabled: boolean;
    selectedPhotos: { [key: string]: boolean };
    canManageAssets: boolean;
}

class ManageAssetPhotos extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    private setTableFilterDebounced: (formValues: {
        [key: string]: any;
    }) => void;
    // convert gallery data to `[id]: [selectedState]` list object
    private selectedPhotos = this.props.galleryData.reduce(
        (obj: { [key: string]: boolean }, photo: Iphoto) => (
            (obj[photo.id] = false), obj
        ),
        {}
    );

    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);

        // Everyone with ViewIntentory SF can view this page, but only those with
        // ManageInventory SF can do any updates like Delete
        const canManageAssets = constants.hasSecurityFunction(props.user, [
            constants.securityFunctions.ManageInventory.id
        ]);

        this.state = {
            currentTile: emptyTile,
            // TODO follow manageJobs example
            searchFieldConfig: { controls: this.buildSearchControls() },
            activePhoto: 0,
            lightboxIsOpen: false,
            selectionEnabled: false,
            selectedPhotos: this.selectedPhotos,
            canManageAssets
        };
        this.setTableFilterDebounced = debounce(
            this.props.setTableFilter,
            constants.formDebounceTime
        );
    }

    componentDidMount(): void {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname),
            searchFieldConfig: this.buildSearchControls()
        });
        if (this.props.selectedFacilityID !== '') {
            this.props.getPhotos();
        }
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.selectedFacilityID !== this.props.selectedFacilityID &&
            this.props.selectedFacilityID.length
        ) {
            const { selectedFacilityID } = this.props;
            this.props.getFacility(selectedFacilityID);
            this.props.initInventory(selectedFacilityID, () => {
                return;
            });
            this.props.getPhotos();
        }

        if (
            JSON.stringify(prevProps.tableFilters) !==
                JSON.stringify(this.props.tableFilters) &&
            this.props.selectedFacilityID.length
        ) {
            this.props.getPhotos();
        }
    }

    defaultDateRange = () =>
        FormUtil.convertToSingleOption({
            id: dateRangeEnum.thisQuarter.toString(),
            label: this.props.t(
                `manageLeads:${dateRangeEnum[dateRangeEnum.thisQuarter]}`
            )
        });

    buildSearchControls = (): FieldConfig => {
        const {
            assetSearch,
            mainCategory,
            jobSearch,
            dateRange = this.defaultDateRange()
        } = this.props.tableFilters;

        const mainSearchControls = {
            assetSearch: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'product/asset',
                    colWidth: 3,
                    type: 'text',
                    placeholder: 'common:searchPlaceholder',
                    defaultValue: assetSearch,
                    isClearable: true
                }
            },
            mainCategory: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:category',
                    options: this.props.productInfo.mainCategoryOptions,
                    colWidth: 3,
                    type: 'select',
                    placeholder: 'common:searchPlaceholder',
                    defaultValue: mainCategory,
                    isClearable: true
                }
            },
            jobSearch: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:job',
                    colWidth: 3,
                    type: 'text',
                    placeholder: 'common:searchPlaceholder',
                    defaultValue: jobSearch,
                    isClearable: true
                }
            },
            dateRange: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'common:dateRange',
                    options: FormUtil.convertEnumToOptions(dateRangeEnum),
                    colWidth: 3,
                    colWidthLarge: 2,
                    placeholder: 'common:selectPlaceholderFilter',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: {
                    value: dateRange
                }
            }
        };

        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    };

    onSearchValueChanges = (value: any, key: string) => {
        this.setTableFilterDebounced({ [key]: value, page: 1 });
    };

    selectAssetPhoto = (photo: Iphoto) => {
        this.setState({
            selectedPhotos: {
                ...this.state.selectedPhotos,
                [photo.id]: !this.state.selectedPhotos[photo.id]
            }
        });
    };

    togglePhotoSelection = () => {
        this.setState({
            // when selection is off, unselect all photos
            selectedPhotos: this.selectedPhotos,
            selectionEnabled: !this.state.selectionEnabled
        });
    };

    deleteSelectedPhotos = (): void => {
        const toDelete = Object.keys(pickBy(this.state.selectedPhotos));

        this.props.deleteMultiplePhotos(toDelete, '');
        this.setState({
            // when selection is off, unselect all photos
            selectedPhotos: this.selectedPhotos,
            selectionEnabled: false
        });
    };

    openLightbox = (event: any, { photo, index }: any) => {
        this.props.getJobsByID(photo.jobID);
        this.setState({ activePhoto: index, lightboxIsOpen: true });
    };

    closeLightbox = () => {
        this.setState({ activePhoto: 0, lightboxIsOpen: false });
    };

    render() {
        const { t } = this.props;
        const renderAssetPhoto = ({ index, photo, margin, top, left }: any) => {
            const typedPhoto = photo as Iphoto;
            const imgStyle: React.CSSProperties = {
                position: 'absolute',
                margin,
                left,
                top
            };

            const handleClick = (event: any) => {
                if (this.state.selectionEnabled) {
                    this.selectAssetPhoto(typedPhoto);
                } else {
                    this.openLightbox(event, { photo, index });
                }
            };

            return (
                <div
                    key={index}
                    className={`asset-photo ${
                        this.state.selectedPhotos[typedPhoto.id]
                            ? 'selected'
                            : ''
                    }`}
                    data-selected={this.state.selectedPhotos[typedPhoto.id]}
                    style={imgStyle}
                >
                    <FontAwesomeIcon
                        icon={['far', 'check']}
                        className="check"
                    />
                    <img {...typedPhoto} alt="img" onClick={handleClick} />
                    <p>{typedPhoto.name}</p>
                </div>
            );
        };
        const photoCaption = (
            name: string,
            job: string,
            notes: string,
            date: string
        ) => (
            <div className="photo-caption">
                <p>
                    <strong>{t('installBase')}: </strong>
                    {name}
                </p>
                {job && (
                    <p>
                        <strong>{t('job')}: </strong>
                        {job}
                    </p>
                )}
                {notes && (
                    <p>
                        <strong>{t('notes')}: </strong>
                        {notes}
                    </p>
                )}
                <p>
                    <strong>{t('date')}: </strong>
                    {moment(date).format('DD-MMMM-YY')}
                </p>
            </div>
        );

        return (
            <div className="manage-asset-photos">
                <Banner
                    title={this.props.t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                >
                    <SelectFacilityContainer
                        classNameOverride={''}
                        t={this.props.t}
                    />
                </Banner>
                <SearchTableForm
                    fieldConfig={this.state.searchFieldConfig}
                    handleSubmit={this.props.getPhotos}
                    loading={this.props.loading}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                    t={this.props.t}
                    showSearchButton={false}
                />
                <div className="selection-bar">
                    {this.state.canManageAssets &&
                    this.state.selectionEnabled ? (
                        <>
                            <Button
                                bsStyle="default"
                                className="selection-done"
                                onClick={this.togglePhotoSelection}
                            >
                                {t('common:doneButton')}
                            </Button>
                            <Button
                                bsStyle="danger"
                                className="delete-selected"
                                onClick={this.deleteSelectedPhotos}
                            >
                                {t('deleteSelectedButton')}
                            </Button>
                        </>
                    ) : this.state.canManageAssets ? (
                        <Button
                            bsStyle="default"
                            className="start-selection"
                            onClick={this.togglePhotoSelection}
                        >
                            Select Photos
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
                {!!this.props.galleryData.length && (
                    <Gallery
                        photos={this.props.galleryData}
                        direction={'column'}
                        columns={4}
                        renderImage={renderAssetPhoto}
                        onClick={this.openLightbox}
                    />
                )}
                <ModalGateway>
                    {this.state.lightboxIsOpen && (
                        <Modal
                            onClose={this.closeLightbox}
                            styles={{
                                positioner: (base: any) => ({
                                    ...base,
                                    zIndex: 10000
                                }),
                                blanket: (base: any) => ({
                                    ...base,
                                    zIndex: 10000
                                })
                            }}
                        >
                            <Carousel
                                currentIndex={this.state.activePhoto}
                                views={this.props.galleryData.map(photo => {
                                    // get job name from photo
                                    const options = filter(
                                        this.props.jobOptions,
                                        {
                                            value: photo.jobID
                                        }
                                    );
                                    const job =
                                        options.length > 0
                                            ? options[0].label
                                            : '';
                                    return {
                                        ...photo,
                                        source: photo.lightboxUrl,
                                        caption: photoCaption(
                                            photo.name,
                                            job,
                                            photo.comments,
                                            photo.updateDate || photo.createDate
                                        )
                                    };
                                })}
                            />
                        </Modal>
                    )}
                </ModalGateway>
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: WithTranslation) => {
    const galleryData = orderBy(state.manageAssetPhotos.photos, 'updateDate')
        .filter(
            photo =>
                !!state.manageInventory.installBasesByID[photo.installBaseID]
        )
        .map(photo => {
            const installBase =
                state.manageInventory.installBasesByID[photo.installBaseID];
            const product =
                state.manageInventory.productsByID[installBase.productID];
            let productName = `${product?.name}`;

            // Don't show undefined as an actual product name...
            if (productName === 'undefined') {
                productName = '';
            }

            return {
                ...photo,
                width: 4,
                height: 3,
                src: photo.thumbnailUrl,
                name: productName
            };
        });
    const documentTypeOptions = keyBy(
        filter(state.manageDocumentType.documentTypesByID, type => {
            return true;
        }),
        'id'
    );
    const { selectedFacilityID } = state;
    const jobOptions = getJobOptionsFromFilters(
        state,
        {
            filters: {
                facilityID: selectedFacilityID
            }
        },
        ownProps.t
    );

    return {
        galleryData,
        tableFilters: state.manageAssetPhotos.tableFilters,
        productInfo: state.productInfo,
        documentTypeOptions,
        selectedFacilityID,
        jobOptions,
        user: state.user
    };
};

export default withTranslation('manageAssetPhotos')(
    connect(mapStateToProps, {
        getPhotos,
        deleteMultiplePhotos,
        setTableFilter,
        initInventory,
        getFacility,
        getJobsByID
    })(ManageAssetPhotos)
);
