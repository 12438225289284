import * as types from '../actions/actionTypes';

import { ImanageUserReducer, Iuser } from '../models';
import { createTableFiltersWithName } from './commonReducers';
import initialState, { initialUser } from './initialState';
import { keyBy, map, pickBy, forEach, filter } from 'lodash';

import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { IinitialState } from '.';

import {
    cleanFacilityWithoutBuildings,
    convertFacilityOptions
} from './facilitiesReducer';

export const cleanUser = (user: any = initialUser): Iuser => {
    const cleanedUser = {
        ...initialState.user,
        ...pickBy(user, (property, key) => property !== null),
        brandID: user.brandID || ''
    };
    const securityFunc = map(cleanedUser.securityFunctions, securityF => {
        return securityF.toUpperCase();
    });

    const facilities = map(
        cleanedUser.facilities,
        cleanFacilityWithoutBuildings
    );
    return {
        ...cleanedUser,
        securityFunctions: securityFunc,
        facilities
    };
};

/*
 *********** SELECTORS
 */

const getSecurityUsers = (state: IinitialState) => state.securityUsersByID;
export const getUser = (state: IinitialState) => state.user;
export const getActiveLocalCountry = (state: IinitialState) =>
    state.manageUser.activeLocalCountry;

export const selectSecurityUsers = createSelector(
    [getSecurityUsers],
    securityUsersByID => {
        return keyBy(filter(securityUsersByID, { isDeleted: false }), 'id');
    }
);
export const selectUser = createSelector([getUser], user => user);
export const selectUserFacilities = createSelector(
    [getUser],
    user => user.facilities
);
export const selectUserFacilitiesOptions = createSelector([getUser], user =>
    convertFacilityOptions(user.facilities)
);

// REFACTOR we could to do something like this but we don't currently have a lookup table for the user's customers and facilities
// export const selectUsers = createSelector([getUsers, getFacilities, getCustomers], (users, facilities, customers)=>{
//     const userFacilities = filter(facilities, {})
//     return map(users, user =>{
//         return {...user, facilities}
//     })
// })

/*
 ************ REDUCERS
 */

/*
 * stores the user currently being edited
 */
const selectedUserReducer = (state = initialUser, action: any): Iuser => {
    switch (action.type) {
        case types.UPDATE_SELECTED_USER: {
            return action.payload;
        }
        case types.USER_SAVE_SUCCESS: {
            return action.user;
        }
        default:
            return state;
    }
};

function usersByID(
    state: { [key: string]: Iuser } = initialState.manageUser.usersByID,
    action: any
): { [key: string]: Iuser } {
    switch (action.type) {
        case types.USER_MANAGE_SUCCESS: {
            const newUsers = map(action.users, user => {
                return cleanUser(user);
            });
            return keyBy(newUsers, 'id');
        }
        case types.USER_SAVE_SUCCESS:
            return {
                ...state,
                [action.user.id]: {
                    ...state[action.user.id],
                    ...cleanUser(action.user)
                }
            };
        case types.USER_UPDATE_POSTAL_CODES_SUCCESS: {
            if (action.postalCodes) {
                return {
                    ...state,
                    [action.id]: {
                        ...state[action.id],
                        postalCodes: action.postalCodes
                    }
                };
            } else {
                return state;
            }
        }
        case types.FIND_USERS_OR_CONTACT_BY_EMAIL_SUCCESS: {
            if (action.payload) {
                let newUsersB: Iuser[] = [];
                forEach(action.payload, (item: any) => {
                    if ('user' in item && item.user) {
                        newUsersB = [...newUsersB, cleanUser(item.user)];
                    } else if ('first' in item) {
                        newUsersB = [...newUsersB, cleanUser(item)];
                    }
                });
                return {
                    ...state,
                    ...keyBy(newUsersB, 'id')
                };
            } else {
                return state;
            }
        }

        case types.GET_CONTACTS_BY_FACILITY_SUCCESS: {
            if (action.payload) {
                let newUsersC: Iuser[] = [];
                forEach(action.payload, (item: any) => {
                    if (item.user) {
                        newUsersC = [...newUsersC, cleanUser(item.user)];
                    }
                });
                return {
                    ...state,
                    ...keyBy(newUsersC, 'id')
                };
            } else {
                return state;
            }
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageUser.usersByID;
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.USER_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

function activeLocalCountry(state = '', action: any): string {
    switch (action.type) {
        case types.UPDATE_USER_LOCAL_COUNTRY:
            return action.payload;
        default:
            return state;
    }
}

const userManage = combineReducers<ImanageUserReducer>({
    usersByID,
    selectedUser: selectedUserReducer,
    totalPages,
    activeLocalCountry,
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_USER')
});

export default userManage;
