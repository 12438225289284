/*
 * Preventative Maintenance Table
 */

import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import ReactTable, { FinalState, RowInfo } from 'react-table';
import selectTableHOC, {
    SelectTableAdditionalProps
} from 'react-table/lib/hoc/selectTable';
import {
    getWorkOrders,
    setSelectedWorkOrderID,
    setTableFilter,
    toggleEditWorkOrderModal,
    updateWorkOrderSelection
} from '../../actions/manageWorkOrderActions';
import {
    IWorkOrder,
    IjobWorkOrder,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import {
    jobTypesIdEnumInverse,
    workOrderStatusEnum,
    workOrderTabEnum,
    workOrderTypesEnum,
    workOrderVendorsEnum
} from '../../models-enums';

import { TFunction } from 'i18next';
import { find } from 'lodash';
import moment from 'moment';
import { constants } from '../../constants/constants';
import PartsIcon from '../../images/icons/BM_Parts.svg';
import { TableUtil } from '../common/TableUtil';
import { WorkOrderBulkActionsButtonContainer } from './WorkOrderBulkActionsButtonContainer';

const SelectTable = selectTableHOC(ReactTable);
const columns = (t: TFunction) =>
    TableUtil.translateHeaders(
        [
            {
                Header: 'Activity',
                accessor: 'activityDescription',
                width: 220,
                Cell: (row: RowInfoWorkOrder) => {
                    return (
                        <div className="lbl-activityDescription">
                            {row.original.activityDescription}
                        </div>
                    );
                }
            },
            {
                Header: 'facility',
                accessor: 'facility.name'
            },
            {
                Header: 'productName',
                accessor: 'productName'
            },
            {
                Header: 'FSENameTableHeader',
                id: 'assignedUser',
                accessor: ({ assignedUser }: IWorkOrder) => assignedUser
            },
            {
                Header: 'jobNumberTableHeader',
                id: 'jobNumber',
                accessor: ({ jobNumber }: IWorkOrder) => jobNumber
            },
            {
                Header: 'jobType',
                id: 'jobType',
                accessor: ({ jobWorkOrders }: IWorkOrder) => {
                    if (jobWorkOrders && jobWorkOrders.length > 0) {
                        const jwo = jobWorkOrders[0];
                        if (jwo && jwo.job) {
                            return t(
                                `nsJob:${
                                    jobTypesIdEnumInverse[
                                        jwo.job
                                            .jobTypeID as keyof typeof jobTypesIdEnumInverse
                                    ]
                                }`
                            );
                        }
                    }

                    return '';
                }
            },
            {
                Header: 'woNumber',
                id: 'workOrderNumber',
                Cell: props => {
                    return (
                        <div className="lbl-workOrderNumber">
                            {props.row.workOrderNumber}
                            {props.original?.parts?.length > 0 && (
                                <img
                                    src={PartsIcon}
                                    alt="icon"
                                    style={{ paddingLeft: '8px' }}
                                    height="16"
                                />
                            )}
                        </div>
                    );
                },
                accessor: ({ number }: IWorkOrder) => number
            },
            {
                Header: 'businessIndicator',
                id: 'businessIndicator',
                accessor: ({ sapBusinessIndicator }: IWorkOrder) =>
                    sapBusinessIndicator,
                minWidth: 120
            },
            {
                Header: 'status',
                id: 'status',
                accessor: ({ status }: IWorkOrder) =>
                    t(workOrderStatusEnum[status]),
                width: 60
            },
            {
                Header: 'dueDate',
                id: 'dueDate',
                accessor: ({ dueDate }: IWorkOrder) => {
                    return dueDate
                        ? moment
                              .utc(dueDate)
                              .local(true)
                              .format('DD-MMM-YY LT')
                        : 'n/a';
                },
                width: 120
            }
        ],
        t
    );

interface Iprops {
    tableData: IWorkOrder[];
    t: TFunction;

    setSelectedWorkOrderID: typeof setSelectedWorkOrderID;
    currentTile: Itile;
    loading: boolean;
    totalPages: number;
    tableFilters: ItableFiltersReducer;
    setTableFilter: typeof setTableFilter;
    overrideColumns?: any[];
    user: Iuser;
    updateWorkOrderSelection: typeof updateWorkOrderSelection;
    selection: string[];
    jobWorkOrdersByID: { [key: string]: IjobWorkOrder };
    toggleEditWorkOrderModal: typeof toggleEditWorkOrderModal;
    usersByID: { [key: string]: Iuser };
}
interface Istate {
    selectedRow: any;
    columns: any[];
    selectAll: boolean;
    workOrdersSameSAPType: boolean;
}
interface RowInfoWorkOrder extends RowInfo {
    original: IWorkOrder;
}

export class WorkOrderBeaconTable extends React.PureComponent<
    Iprops & SelectTableAdditionalProps,
    Istate
> {
    private checkboxTable: any;
    constructor(props: Iprops & SelectTableAdditionalProps) {
        super(props);
        this.state = {
            selectedRow: {},
            columns: columns(this.props.t),
            selectAll: false,
            workOrdersSameSAPType: false
        };
    }

    componentDidMount() {
        // TODO do we need this eventLister anymore?
        // It seems yes, this is used to clear the selection when a work order is added to a job
        document.addEventListener(
            'clearSelectedWorkOrders',
            this.handleClearSelectedWorkOrders,
            false
        );
        this.props.updateWorkOrderSelection([]);
        getWorkOrders();
    }

    componentDidUpdate(prevProps: Iprops) {
        // scroll top every time a filter changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }

        if (
            JSON.stringify(prevProps.selection) !==
            JSON.stringify(this.props.selection)
        ) {
            this.verifySAPWorkOrders();
        }
    }

    componentWillUnmount() {
        document.removeEventListener(
            'clearSelectedWorkOrders',
            this.handleClearSelectedWorkOrders,
            false
        );
    }

    verifySAPWorkOrders = () => {
        // You cannot add multiple work orders to a job if they are of different SAP types
        let anyCommissioningWO = false;
        let anyReparWO = false;

        if (this.props.selection.length === 0) {
            this.setState({ workOrdersSameSAPType: false });
        } else if (this.props.selection.length === 1) {
            this.setState({ workOrdersSameSAPType: true });
        } else {
            this.props.selection.forEach((workOrderID: string) => {
                const id = workOrderID.substring(7);
                const wo = this.props.tableData.find(x => x.id === id);
                if (wo) {
                    if (wo.sapBusinessIndicator === 'IC') {
                        anyCommissioningWO = true;
                    } else {
                        anyReparWO = true;
                    }
                }
            });

            if (anyCommissioningWO && anyReparWO) {
                this.setState({ workOrdersSameSAPType: false });
            } else {
                this.setState({ workOrdersSameSAPType: true });
            }
        }
    };

    handleClearSelectedWorkOrders = () => {
        this.props.updateWorkOrderSelection([]);
    };

    /*
     * Show the action button if the work order is new or reopened
     * hide the action button if on the Repair tab and the work order is assigned to Beacon. These should be closed on the beacon tab only
     * we will need to stop hiding the button when not on the Beacon tab once we add more actions.  Might hide the close option from the dropdown in that case.d
     */
    shouldShowActionButton = (workOrder: IWorkOrder) => {
        return (
            (workOrder.status === workOrderStatusEnum.reopened ||
                workOrder.status === workOrderStatusEnum.new) &&
            (this.props.tableFilters.tabKey === workOrderTabEnum.repair &&
                workOrder.vendor === workOrderVendorsEnum.beacon) === false
        );
    };

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    /**
     * Toggle a single checkbox for select table
     */
    toggleSelection = (key: string, shift: boolean, row: any) => {
        // start off with the existing state
        let selection = [...this.props.selection];
        const keyIndex = selection.indexOf(key);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(key);
        }
        // update the state
        this.props.updateWorkOrderSelection(selection);
    };

    /**
     * Toggle all checkboxes for select table
     */
    toggleAll = () => {
        const { keyField = 'id' } = this.props;
        const selectAll = !this.state.selectAll;
        const selection: string[] = [];

        if (selectAll && keyField !== undefined) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords: any[] = wrappedInstance.getResolvedState()
                .sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(`select-${item._original[keyField]}`);
            });
        }
        this.setState({ selectAll });
        this.props.updateWorkOrderSelection(selection);
    };

    /**
     * Whether or not a row is selected for select table
     */
    isSelected = (key: string) => {
        return this.props.selection.includes(`select-${key}`);
    };

    getTrProps = (state: FinalState, rowInfo: RowInfoWorkOrder | undefined) => {
        // const { selection } = this.props;
        if (rowInfo) {
            const hasWorkOrder = find(this.props.jobWorkOrdersByID, {
                workOrderID: rowInfo.original.id
            });
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.props.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : '',
                    color: hasWorkOrder ? constants.colors.blue : 'inherit'
                },
                onClick: () => {
                    this.props.setSelectedWorkOrderID(rowInfo.original.id);
                    if (rowInfo.original.type === workOrderTypesEnum.pmp) {
                        this.setState({
                            selectedRow: {
                                [rowInfo.viewIndex || 0]: !this.state
                                    .selectedRow[rowInfo.viewIndex || 0]
                            }
                        });
                    } else {
                        this.props.toggleEditWorkOrderModal();
                    }
                }
            };
        } else {
            return {};
        }
    };

    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };

    render() {
        const { tableData, t, loading, totalPages } = this.props;
        const colorButton =
            constants.colors[
                `${this.props.currentTile.color}Button` as keyof typeof constants.colors
            ];
        const shouldShowCheckboxes = constants.hasSecurityFunction(
            this.props.user,
            [constants.securityFunctions.ManageJobs.id]
        );
        const tableClassName = shouldShowCheckboxes
            ? `beacon-table -highlight ${this.props.currentTile.color}`
            : `beacon-table -highlight ${this.props.currentTile.color} hide-checkboxes`;

        return (
            <div>
                {this.props.selection.length !== 0 &&
                    this.state.workOrdersSameSAPType && (
                        <Row>
                            <Col md={12}>
                                <WorkOrderBulkActionsButtonContainer
                                    colorButton={colorButton}
                                    t={t}
                                />
                            </Col>
                        </Row>
                    )}
                <SelectTable
                    data={tableData}
                    columns={this.props.overrideColumns || this.state.columns}
                    getTrProps={this.getTrProps}
                    pageSize={tableData.length >= 10 ? tableData.length : 10}
                    manual
                    pages={totalPages}
                    page={this.props.tableFilters.page - 1}
                    showPageSizeOptions={false}
                    className={tableClassName}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    loading={loading}
                    toggleSelection={this.toggleSelection}
                    selectAll={this.state.selectAll}
                    selectType="checkbox"
                    toggleAll={this.toggleAll}
                    isSelected={this.isSelected}
                    ref={ref => (this.checkboxTable = ref)}
                    keyField="id"
                />
            </div>
        );
    }
}
