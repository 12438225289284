import { Iquote, Itile, ItableFiltersReducer, Iuser } from '../../models';
import ReactTable, { RowInfo, Column, FinalState } from 'react-table';
import { RouteComponentProps } from 'react-router-dom';
import React from 'react';
import { constants } from '../../constants/constants';
import { debounce } from 'lodash';
import { emptyTile } from '../../reducers/initialState';
import { Banner } from '../common/Banner';
import { FieldConfig } from 'react-reactive-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    setTableFilter,
    getQuotes,
    toggleQuotesModal,
    getIsManageCustomerQuotesView,
    toggleSalesQuotesModal
} from '../../actions/manageCustomerQuotesActions';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { FormUtil } from '../common/FormUtil';
import { quoteStatusEnum } from '../../models-enums';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import QuoteModal from './QuoteModal';
import QuoteDetailsModal from './QuoteDetailsModal';
import moment from 'moment';

import { addQuery } from '../common/OtherUtils';
import { selectQuotes } from '../../reducers/manageCustomerQuotesReducer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import QuoteSalesModal from './QuoteSalesModal';
import MobileQuoteModal from './mobile/MobileQuoteModal';
import MobileQuoteSalesModal from './mobile/MobileQuoteSalesModal';
import MobileQuoteDetailsModal from './mobile/MobileQuoteDetailsModal';

interface RowInfoQuote extends RowInfo {
    original: Iquote;
}

export enum manageQuoteQueryParamsEnum {
    selectedQuoteID = 'selectedQuoteID'
}
interface Istate {
    selectedRow: any;
    currentTile: Itile;
    searchFieldConfig: FieldConfig;
    filterLength: number;
}

interface IdispatchProps {
    tableData: Iquote[];
    tableFilters: ItableFiltersReducer;
    setTableFilter: typeof setTableFilter;
    getQuotes: typeof getQuotes;
    loading: boolean;
    toggleQuotesModal: typeof toggleQuotesModal;
    queryParams: typeof manageQuoteQueryParamsEnum;
    selectedFacilityID: string;
    totalPages: number;
    user: Iuser;
    toggleSalesQuotesModal: typeof toggleSalesQuotesModal;
    isMobile: boolean;
}

type Iprops = RouteComponentProps<any>;

class ManageQuotes extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    public columns: any[];
    private setTableFilterDebounced: (formValues: {
        [key: string]: any;
    }) => void;

    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            searchFieldConfig: { controls: {} },
            filterLength: 0
        };
        this.setTableFilterDebounced = debounce(
            this.props.setTableFilter,
            constants.formDebounceTime
        );

        if (this.props.isMobile) {
            this.columns = TableUtil.translateHeaders(
                [
                    {
                        id: 'date',
                        Header: 'date',
                        accessor: ({ createDate }: Iquote) => {
                            return moment
                                .utc(createDate)
                                .local(true)
                                .format(constants.momentDisplayFormat);
                        },
                        Cell: props => (
                            <div className="manage-quote-container">
                                <p className="table-mobile-header">Date</p>{' '}
                                <p className="table-value">{props.value}</p>
                            </div>
                        ),
                        minWidth: 90
                    },
                    {
                        id: 'quoteNumber',
                        Header: 'quoteNumber',
                        accessor: 'quoteNumber',
                        Cell: props => (
                            <div className="manage-quote-container">
                                <p className="table-mobile-header">Number</p>{' '}
                                <p className="table-value">{props.value}</p>
                            </div>
                        )
                    },
                    {
                        id: 'facility',
                        Header: 'facility',
                        accessor: 'facility.name',
                        minWidth: 200,
                        Cell: props => (
                            <div className="manage-quote-container">
                                <p className="table-mobile-header">Facility</p>{' '}
                                <p className="table-value">{props.value}</p>
                            </div>
                        )
                    },
                    {
                        id: 'status',
                        Header: 'status',
                        accessor: ({ status }) =>
                            this.props.t(
                                `${
                                    quoteStatusEnum[
                                        status as keyof typeof quoteStatusEnum
                                    ]
                                }`
                            ),
                        minWidth: 130,
                        Cell: props => (
                            <div className="manage-quote-container">
                                <p className="table-mobile-header">Status</p>{' '}
                                <p className="table-value">{props.value}</p>
                            </div>
                        )
                    }
                ],
                this.props.t
            );
        } else {
            this.columns = TableUtil.translateHeaders(
                [
                    {
                        id: 'date',
                        Header: 'date',
                        accessor: ({ createDate }: Iquote) => {
                            return moment
                                .utc(createDate)
                                .local(true)
                                .format(constants.momentDisplayFormat);
                        },
                        minWidth: 90
                    },
                    {
                        id: 'quoteNumber',
                        Header: 'quoteNumber',
                        accessor: 'quoteNumber'
                    },
                    {
                        id: 'facility',
                        Header: 'facility',
                        accessor: 'facility.name',
                        minWidth: 200
                    },
                    {
                        id: 'revision',
                        Header: 'revision',
                        accessor: 'revision',
                        minWidth: 100
                    },
                    {
                        id: 'status',
                        Header: 'status',
                        accessor: ({ status }) =>
                            this.props.t(
                                `${
                                    quoteStatusEnum[
                                        status as keyof typeof quoteStatusEnum
                                    ]
                                }`
                            ),
                        minWidth: 130
                    }
                ],
                this.props.t
            );
        }
    }

    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname),
            searchFieldConfig: this.buildSearchFieldConfig()
        });
        this.props.getQuotes();
        this.shouldShowSelected();
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.getQuotes();

            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
        if (this.props.selectedFacilityID !== prevProps.selectedFacilityID) {
            this.props.getQuotes();
        }
    }

    buildSearchFieldConfig = (): FieldConfig => {
        const { search, status } = this.props.tableFilters;
        const disabled = false;
        const mainSearchControls = {
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:search',
                    colWidth: 4,
                    colWidthLarge: 3,
                    type: 'text',
                    placeholder: 'common:searchPlaceholder',
                    shouldTranslate: true
                },
                formState: {
                    value: search,
                    disabled
                }
            },
            status: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'status',
                    options: FormUtil.convertEnumToOptions(quoteStatusEnum),
                    colWidth: 4,
                    colWidthLarge: 3,
                    placeholder: 'common:selectPlaceholderFilter',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: {
                    value: status,
                    disabled
                }
            }
        };
        this.setState({ filterLength: Object.keys(mainSearchControls).length });

        return { controls: { ...mainSearchControls } };
    };

    onSearchValueChanges = (value: any, key: string) => {
        this.setTableFilterDebounced({ [key]: value, page: 1 });
    };

    isSalesMode = () => {
        return this.props.location.pathname === '/quotes_sales';
    };

    handleSelectQuote = (id: string) => {
        addQuery(
            manageQuoteQueryParamsEnum.selectedQuoteID,
            id,
            this.props.history
        );
        if (this.isSalesMode()) {
            this.props.toggleSalesQuotesModal();
        } else {
            this.props.toggleQuotesModal();
        }
    };

    shouldShowSelected = () => {
        const shouldShow = !!(
            this.props.queryParams.selectedQuoteID &&
            this.props.queryParams.selectedQuoteID.length > 0
        );
        if (this.isSalesMode()) {
            this.props.toggleSalesQuotesModal(shouldShow);
        } else {
            this.props.toggleQuotesModal(shouldShow);
        }
    };

    getTdProps = (
        state: FinalState,
        rowInfo: RowInfoQuote | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column && column.id !== 'actions') {
            return {
                onClick: () => {
                    this.handleSelectQuote(rowInfo.original.id);
                    this.setState({
                        selectedRow: {
                            [rowInfo.viewIndex || 0]: !this.state.selectedRow[
                                rowInfo.viewIndex || 0
                            ]
                        }
                    });
                }
            };
        } else {
            return {};
        }
    };

    render() {
        const isManageCustomerQuotesView = getIsManageCustomerQuotesView(
            this.props.user
        );

        const { t } = this.props;
        const colorButton =
            constants.colors[
                `${this.state.currentTile.color}Button` as keyof typeof constants.colors
            ];
        const tableClassName = this.props.tableFilters.showAdditionalFilters
            ? `beacon-table -highlight ${this.state.currentTile.color} additional-filters`
            : `beacon-table -highlight ${this.state.currentTile.color}`;
        return (
            <div
                className="manage-customer-quotes"
                style={{
                    ['--filter-size' as string]: this.state.filterLength
                }}
            >
                <Banner
                    title={t(`tiles:${this.state.currentTile.title}`)}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                >
                    {isManageCustomerQuotesView && (
                        <SelectFacilityContainer
                            t={this.props.t}
                            classNameOverride={''}
                        />
                    )}
                </Banner>
                <SearchTableForm
                    fieldConfig={this.state.searchFieldConfig}
                    t={this.props.t}
                    loading={this.props.loading}
                    handleSubmit={this.props.getQuotes}
                    onValueChanges={this.onSearchValueChanges}
                    subscribeValueChanges={true}
                    colorButton={colorButton}
                    xs={9}
                    lg={10}
                />
                <ReactTable
                    data={this.props.tableData}
                    columns={this.columns}
                    className={tableClassName}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                    sortable={false}
                    getTdProps={this.getTdProps}
                    pageSize={
                        this.props.tableData.length >= 13
                            ? this.props.tableData.length
                            : 13
                    }
                    manual
                    pages={this.props.totalPages}
                    page={this.props.tableFilters.page - 1}
                    showPageSizeOptions={false}
                />

                {this.props.isMobile && (
                    <>
                        <MobileQuoteModal
                            colorButton={colorButton}
                            queryParams={this.props.queryParams}
                            history={this.props.history}
                        />

                        <MobileQuoteSalesModal
                            colorButton={colorButton}
                            queryParams={this.props.queryParams}
                            history={this.props.history}
                        />

                        <MobileQuoteDetailsModal
                            colorButton={colorButton}
                            queryParams={this.props.queryParams}
                            history={this.props.history}
                            secondModal={true}
                        />
                    </>
                )}

                {this.props.isMobile === false && (
                    <>
                        <QuoteModal
                            colorButton={colorButton}
                            queryParams={this.props.queryParams}
                            history={this.props.history}
                        />
                        <QuoteSalesModal
                            colorButton={colorButton}
                            queryParams={this.props.queryParams}
                            history={this.props.history}
                        />
                        <QuoteDetailsModal
                            colorButton={colorButton}
                            queryParams={this.props.queryParams}
                            history={this.props.history}
                            secondModal={true}
                        />
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const queryParams = queryString.parse(ownProps.location.search);
    const tableData = selectQuotes(state);

    return {
        user: state.user,
        loading: selectIsLoading(state),
        tableData,
        tableFilters: state.manageCustomerQuotes.tableFilters,
        queryParams,
        selectedFacilityID: state.selectedFacilityID,
        totalPages: state.manageCustomerQuotes.totalPages,
        isMobile: state.config.isMobile
    };
};

const mapDispatchToProps = {
    setTableFilter,
    getQuotes,
    toggleQuotesModal,
    toggleSalesQuotesModal
};

export default withTranslation('manageQuotes')(
    connect(mapStateToProps, mapDispatchToProps)(ManageQuotes)
);
