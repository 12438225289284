import { WithTranslation, withTranslation } from 'react-i18next';
import {
    deleteMeasurementPoint,
    saveMeasurementPointToMeasurementPointList,
    toggleEditMeasurementPointListModal,
    toggleEditMeasurementPointModal,
    updateMeasurementPoint
} from '../../actions/manageMeasurementPointListsActions';

import EditMeasurementPointForm from './EditMeasurementPointForm';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { initialMeasurementPointTab } from '../../reducers/initialState';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { IsmartlinkDataPoint } from '../../models';

interface Iprops {
    colorButton: string;
    canEditGlobal: boolean;
    isCustomerView: boolean;
    smartlinkDataPoints: IsmartlinkDataPoint[];
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const {
        selectedMeasurementPoint,
        selectedMeasurementPointList
    } = state.manageMeasurementPointLists;
    const getTitle = () => {
        if (
            selectedMeasurementPoint.type ===
            constants.measurementPointTypes.GROUP
        ) {
            return ownProps.t('GroupModalTitle');
        } else {
            return ownProps.t('measurementPointModalTitle');
        }
    };

    const selectedTab =
        state.manageMeasurementPointLists.selectedMeasurementPointList.measurementPointTabs.find(
            tab => tab.id === state.manageMeasurementPointLists.selectedTabID
        ) || initialMeasurementPointTab;

    const isDailyRounds =
        state.manageMeasurementPointLists.selectedMeasurementPointList.type ===
        constants.measurementPointListTypeEnum.dailyRounds
            ? true
            : false;
    return {
        title: getTitle(),
        className: 'measurements-edit',
        secondModal: true,
        user: state.user,
        userCountryCode: state.user.countryID,
        loading: selectIsLoading(state),
        showEditMeasurementPointListModal:
            state.manageMeasurementPointLists.showEditMeasurementPointListModal,
        show: state.manageMeasurementPointLists.showEditMeasurementPointModal,
        selectedTab,
        selectedMeasurementPointList:
            state.manageMeasurementPointLists.selectedMeasurementPointList,
        selectedMeasurementPoint,
        forJob: selectedMeasurementPointList.forJob,
        isDailyRounds,
        smartlinkDataPoints: ownProps.smartlinkDataPoints,
        reportDefaults: state.manageReport.defaultReportsByID
    };
};

export default withTranslation('manageMeasurementPointLists')(
    connect(mapStateToProps, {
        onHide: toggleEditMeasurementPointModal,
        toggleEditMeasurementPointListModal,
        toggleModal: toggleEditMeasurementPointModal,
        saveMeasurementPointToMeasurementPointList,
        updateMeasurementPoint,
        deleteMeasurementPoint
    })(WithCommonModal(EditMeasurementPointForm))
);
