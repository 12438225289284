/*
 * Edit Alert Modal
 */

import { connect } from 'react-redux';

import {
    saveAlert,
    updateAlert,
    toggleEditAlertModal
} from '../../actions/manageAlertActions';
import EditAlertForm from './EditAlertForm';
import { initialAlert } from '../../reducers/initialState';
import {
    clearSelectedAlertID,
    setAlertFormValues,
    updateAlertFormValue
} from '../../actions/manageAlertActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedAlert =
        state.manageAlert.alertsByID[state.manageAlert.selectedAlertID] ||
        initialAlert;

    let modalTitle;
    if (selectedAlert && selectedAlert.id) {
        modalTitle = ownProps.t(`manageAlert:editAlert`);
    } else {
        modalTitle = ownProps.t(`manageAlert:newAlert`);
    }
    return {
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageAlert.showEditAlertModal,
        selectedAlert,
        formValues: state.manageAlert.alertFormValues,
        standardOptions: state.productInfo.standardOptions,
        title: modalTitle,
        className: 'alert-edit'
    };
};

export default withTranslation('manageAlert')(
    connect(mapStateToProps, {
        toggleModal: toggleEditAlertModal,
        handleCancel: toggleEditAlertModal,
        handleSubmit: saveAlert,
        handleEdit: updateAlert,
        clearSelectedAlertID,
        updateFormValue: updateAlertFormValue,
        setFormValues: setAlertFormValues,
        onHide: toggleEditAlertModal
    })(WithCommonModal(EditAlertForm))
);
