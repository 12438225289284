/*
 * Measurement Point Results List History Modal
 */

import {
    toggleMPResultHistory,
    toggleMPResultNotes
} from '../../../actions/manageInventoryActions';
import {
    toggleWorkOrderCloseModal,
    setSelectedWorkOrderID,
    toggleEditWorkOrderModal,
    getWorkOrderByID
} from '../../../actions/manageWorkOrderActions';
import { connect } from 'react-redux';
import { MPResultHistory } from '../MPResultHistory';
import { setHistoricalResultID } from '../../../actions/measurementPointResultsActions';
import { selectIsLoading } from '../../../reducers/commonReducers';
import { WithCommonMobileModal } from '../../common/mobile/WithCommonMobileModal';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IinitialState } from '../../../reducers';
import { selectInstallBaseHistory } from '../../../reducers/measurementPointResultsReducer';

interface Iprops {
    colorButton: any;
    secondModal: boolean;
    locationString: string;
    selectedInstallBaseID: string;
}

const defaultProps = {
    className: 'mp-history-modal'
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const installBaseHistory = selectInstallBaseHistory(
        state.measurementPointResults
    );

    return {
        ...defaultProps,
        user: state.user,
        isLoading: selectIsLoading(state),
        show: state.manageInventory.showMPResultHistoryModal,
        installBaseHistory,
        title: ownProps.t('MPresultHistoryModalTitle')
    };
};

export const MPResultHistoryMobileModal = withTranslation('manageInventory')(
    connect(mapStateToProps, {
        toggleModal: toggleMPResultHistory,
        toggleMPResultNotes,
        toggleWorkOrderCloseModal,
        setHistoricalResultID,
        setSelectedWorkOrderID,
        toggleEditWorkOrderModal,
        onHide: toggleMPResultHistory,
        getWorkOrderByID
    })(WithCommonMobileModal(MPResultHistory))
);
