import { toastr } from 'react-redux-toastr';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
    ItableFiltersParams,
    Ireport,
    IdefaultReport,
    ThunkResult
} from '../models';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import * as types from './actionTypes';
import { msalFetch } from '../components/auth/Auth-Utils';
import { reportTypeEnum } from '../models-enums';
import moment from 'moment';

export function getDefaultReports(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        const url = API.GET.report.defaults;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.REPORT_MANAGE_GET_DEFAULT_SUCCESS,
                        reports: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.REPORT_MANAGE_GET_DEFAULT_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get default reports');
                console.error(error);
            })
            .finally(() => dispatch(endAjaxCall()));
    };
}

export function updateReport(
    selectedDefaultReport: IdefaultReport,
    formValues: any
): ThunkResult<any> {
    return (dispatch, getState) => {
        // dispatch(beginAjaxCall());
        const { jobID, coverLetter, headerLogoPath = '' } = formValues;
        const report: Ireport = {
            jobID,
            reportType: selectedDefaultReport.reportType,
            coverLetter,
            headerLogoPath
        };
        dispatch({
            type: types.REPORT_UPDATE,
            report
        });
        // return axios
        //   .post(`${API.POST.job.update}`, { job, users })
        //   .then(data => {
        //     if (!data.data) {
        //       throw undefined;
        //     } else {
        // dispatch({
        //   type: types.REPORT_UPDATE_SUCCESS,
        //   job: data.data
        // });

        //       // toastr.success('Success', 'Saved job', constants.toastrSuccess);
        //       return data;
        //     }
        //   })
        //   .catch((error: any) => {
        //     dispatch({ type: types.REPORT_UPDATE_FAILED, error, axiosOptions });
        //     constants.handleError(error, 'update job');
        //     console.error(error);
        //   });
    };
}

export function runDailyRoundsReport(
    formValues: any,
    facilityID: string,
    sendToExternal: boolean
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            params: {
                facilityID: facilityID,
                sendToExternal: sendToExternal,
                startDate: formValues.startDate
                    ? moment.utc(formValues.startDate).toISOString()
                    : '',
                endDate: formValues.endDate
                    ? moment.utc(formValues.endDate).toISOString()
                    : ''
            },
            data: {}
        };

        const url = API.POST.report.runDailyRounds;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.REPORT_ADD_SUCCESS
                });

                dispatch({ type: types.TOGGLE_MODAL_EDIT_REPORT });
                toastr.success(
                    'Success',
                    'Your report is being generated and will be emailed to you as soon as it is ready.',
                    { ...constants.toastrSuccess, timeOut: 5000 }
                );
            })
            .catch((error: any) => {
                dispatch({
                    type: types.REPORT_ADD_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'run report');
                console.error(error);
            });
    };
}

/*
 * run a new report
 */
export function runReport(
    formValues: any,
    reportType: number,
    sendToExternal: boolean
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const reportTypeString = `report${reportTypeEnum[reportType]}`;
        const requestData: AxiosRequestConfig = {
            [reportTypeString]: {
                jobID: formValues.jobID.value,
                coverLetter: formValues.coverLetter,
                headerLogoPath: ''
            }
        };
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: requestData,
            params: {
                jobID: formValues.jobID.value,
                sendToExternal: sendToExternal
            }
        };

        const url = API.POST.report.run;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.REPORT_ADD_SUCCESS
                });

                dispatch({ type: types.TOGGLE_MODAL_EDIT_REPORT });
                toastr.success(
                    'Success',
                    'Your report is being generated and will be emailed to you as soon as it is ready.',
                    { ...constants.toastrSuccess, timeOut: 5000 }
                );
            })
            .catch((error: any) => {
                dispatch({
                    type: types.REPORT_ADD_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'run report');
                console.error(error);
            });
    };
}

export const toggleEditReportModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_REPORT
});
export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_REPORT,
    filters
});

export const setSelectedReport = (report: Ireport) => ({
    type: types.SET_SELECTED_REPORT,
    report
});

export const setSelectedDefaultReport = (defaultReportID: string) => ({
    type: types.SET_SELECTED_DEFAULT_REPORT_ID,
    defaultReportID
});
