import { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
    ImeasurementPointResult,
    ImeasurementPoint,
    // ImeasurementPointList,
    ImeasurementPointAnswer,
    ThunkResult
} from '../models';
import { beginAjaxCall } from './ajaxStatusActions';
import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import * as types from './actionTypes';
import * as moment from 'moment';

import { msalFetch } from '../components/auth/Auth-Utils';

import { Dispatch } from 'redux';
import {
    measurementPointTypes,
    measurementPointResultStatusTypesEnum
} from '../models-enums';
const uuidv4 = require('uuid/v4');

export const getMeasurePointListResultForInstallBase = (
    dispatch: any,
    installBaseId: string
) => {
    const axiosOptions: AxiosRequestConfig = {
        method: 'get'
    };

    const url = `${API.GET.measurementPoint.getMeasurePointListResultForInstallBase}/${installBaseId}`;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                dispatch({
                    type:
                        types.GET_MEASUREMENT_POINT_HISTORY_BY_INSTALLBASE_SUCCESS,
                    results: data.data
                });
                return data.data;
            }
        })
        .catch((error: any) => {
            dispatch({
                type: types.GET_MEASUREMENT_POINT_HISTORY_BY_INSTALLBASE_FAILED,
                error,
                axiosOptions
            });
            constants.handleError(error, 'get inspection results');
            throw error; // intentionally rethrow
        });
};

export function getMeasurementPointResultsHistoryByInstallBase(
    installBaseId: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        return getMeasurePointListResultForInstallBase(
            dispatch,
            installBaseId
        ).catch((error: any) =>
            console.error('error getting measurement point results', error)
        );
    };
}

export const getFacilityMeasurementPointResultsHelper = (
    dispatch: any,
    getState: any,
    facilityID: string
) => {
    const axiosOptions: AxiosRequestConfig = {
        method: 'get'
    };

    const url = `${API.GET.measurementPoint.getFacilityMeasurementPointListResults}/${facilityID}`;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                // console.info(data.data);
                dispatch({
                    type: types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS,
                    results: data.data
                });
                return data.data;
            }
        })
        .catch((error: any) => {
            dispatch({
                type: types.GET_MEASUREMENT_POINT_FACILITY_RESULTS_FAILED,
                error,
                axiosOptions
            });
            constants.handleError(error, 'get inspection results');
            throw error; // intentionally rethrow
        });
};

export function getFacilityMeasurementPointResults(
    facilityID: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        return getFacilityMeasurementPointResultsHelper(
            dispatch,
            getState,
            facilityID
        ).catch((error: any) =>
            console.error('error getting measurement point results', error)
        );
    };
}

export function getMeasurementPointListResult(id: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        const url = `${API.GET.measurementPoint.getMeasurementPointListResultWithAnswers}/${id}`;
        return msalFetch(url, axiosOptions)
            .then((response: AxiosResponse<any>) => {
                if (!response.data) {
                    throw new Error('missing data');
                } else {
                    // console.info(response.data);
                    dispatch({
                        type: types.GET_MEASUREMENT_POINT_RESULT_SUCCESS,
                        payload: response.data
                    });
                    return response.data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_MEASUREMENT_POINT_RESULT_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get inspection result');
                throw error; // intentionally rethrow
            });
    };
}

const updateMPresultHelper = (
    result: ImeasurementPointResult,
    dispatch: Dispatch
) => {
    dispatch({
        type: types.UPDATE_MEASUREMENT_POINT_RESULT,
        result
    });
};

/*
 * get the most recent or the selected historical result for this installBase
 */
export const selectResult = (historicalResultID: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        const {
            measurementPointResultsByID
        } = getState().measurementPointResults;

        const historicalResult =
            measurementPointResultsByID[historicalResultID];
        updateMPresultHelper(historicalResult, dispatch);
    };
};

const updateExistingResultAnswerIDs = (
    resultAnswers: ImeasurementPointAnswer[]
) => {
    return resultAnswers.map(answer => ({ ...answer, id: uuidv4() }));
};

export const createNewResultFromPrevious = (
    previousResult: ImeasurementPointResult,
    jobID: string,
    installBaseID: string
): ImeasurementPointResult => {
    return {
        id: uuidv4(),
        installBaseID,
        measurementPointListID: previousResult.measurementPointListID,
        jobID,
        temporary: false,
        isDeleted: false,
        notes: previousResult.notes ? previousResult.notes : '',
        status: measurementPointResultStatusTypesEnum.resultStatusNotTested,
        previousStatus: previousResult.previousStatus,
        createDate: moment.utc().toISOString(),
        updateDate: moment.utc().toISOString(),
        manualOverride: true,
        compiledNotes: '',
        installBaseHistory: previousResult.installBaseHistory,
        measurementPointAnswers:
            updateExistingResultAnswerIDs(
                previousResult.measurementPointAnswers
            ) || []
    };
};

const resetAnswerToDefault = (
    measurementPoint: ImeasurementPoint,
    answer: ImeasurementPointAnswer
): ImeasurementPointAnswer => {
    if (measurementPoint.type === measurementPointTypes.QUESTION_PASSFAIL) {
        return { ...answer, notes: '', pass: measurementPoint.passFailDefault };
    } else if (
        measurementPoint.type === measurementPointTypes.QUESTION_NUMERIC
    ) {
        return { ...answer, notes: '', numericValue: undefined };
    } else if (measurementPoint.type === measurementPointTypes.QUESTION_TEXT) {
        return { ...answer, notes: '', textValue: undefined };
    } else if (
        measurementPoint.type === measurementPointTypes.QUESTION_SELECT
    ) {
        return {
            ...answer,
            notes: '',
            measurementPointSelectOptionID:
                measurementPoint.selectDefaultOptionID
        };
    } else {
        return { ...answer, notes: '' };
    }
};

/*
 * cleanExistingInstallResultAnswers - loop over answers for this device and clean any answers that are from a different job if rememberBetweenInspections === false
 */

export const cleanExistingInstallResultAnswers = (
    result: ImeasurementPointResult,
    measurementPointsByID: { [key: string]: ImeasurementPoint },
    jobID: string
): ImeasurementPointResult => {
    // if it is temporary then it has already been cleaned
    if (result.temporary) {
        return result;
    }
    const cleanedAnswers = result.measurementPointAnswers.map(answer => {
        const measurementPoint =
            measurementPointsByID[answer.measurementPointID];
        if (!measurementPoint) {
            console.error(
                'missing measurement point',
                measurementPoint,
                answer
            );
        }

        if (
            jobID !== result.jobID &&
            measurementPoint.selectRememberBetweenInspection === false
        ) {
            return resetAnswerToDefault(measurementPoint, answer);
        } else {
            return answer;
        }
    });
    return {
        ...result,
        measurementPointAnswers: cleanedAnswers,
        notes: '',
        status: 0,
        previousStatus: result.status
    };
};

/*
 * Create a measurement point result manually
 * set manualOverride to true and copy the answers fromt he previous result if there is one
 */

export function updateMeasurementPointResult(
    result: ImeasurementPointResult
): ThunkResult<any> {
    return (dispatch, getState) => {
        updateMPresultHelper(result, dispatch);
    };
}

export function setHistoricalResultID(resultID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_HISTORICAL_RESULT_ID,
            resultID
        });
    };
}

export const clearHistoricalResultID = () => ({
    type: types.CLEAR_HISTORICAL_RESULT_ID
});

/*
 * *********  Private Helper functions **********
 */

/*
 * 1) try to find a previous result for this install
 * 2) try to find a previous result for this type of measurement point list (clean the answers)
 * If we find one, return it
 *  - if it is temporary then do Not create a new ID or remove any answers
 */
// const getPreviousResult = (
//   mpResults: ImeasurementPointResult[],
//   installID: string,
//   selectedMPL: ImeasurementPointList
// ): ImeasurementPointResult => {
//   if (mpResults.length) {
//     let previousResult: ImeasurementPointResult = initialMeasurmentPointResult;
//     const installResults = getInstallResults(mpResults, false, installID);
//     if (installResults && installResults.length) {
//       previousResult = getMostRecentResult(installResults);
//     } else {
//       const MPLresults = getMeasurementPointListResults(mpResults, selectedMPL);
//       if (MPLresults.length) {
//         previousResult = getMostRecentResult(MPLresults);
//       }
//     }
//     return previousResult;
//   } else {
//     console.info('did not find any previous results');
//     return initialMeasurmentPointResult;
//   }
// };

/*
 * when no results for the specific install, try to find results for the same measurementPointList
 */
// const getMeasurementPointListResults = (
//   results: ImeasurementPointResult[],
//   selectedMPL: ImeasurementPointList
// ) => {
//   return results.filter(result => {
//     return (
//       result.measurementPointListID === selectedMPL.id && !result.temporary
//     );
//   });
// };

// const getInstallResults = (
//   results: ImeasurementPointResult[],
//   includeTemporary: boolean,
//   installID: string
// ) => {
//   const filteredResults = results.filter(result => {
//     return result.installBaseID === installID;
//   });
//   if (!includeTemporary) {
//     return filteredResults.filter(result => result.temporary !== true);
//   }
//   return filteredResults;
// };

// const getMostRecentResult = (results: ImeasurementPointResult[]) => {
//   return results.reduce((previous, current) => {
//     if (
//       moment.utc(previous.updateDate).isAfter(moment.utc(current.updateDate))
//     ) {
//       return previous;
//     } else {
//       return current;
//     }
//   });
// };

/*
 * Copy a previous result to a new one
 * or create a completely new one
 */
