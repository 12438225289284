/*
 * Edit code Form
 * Add and Edit codes
 *
 */

import * as React from 'react';
import {
    FormGenerator,
    FieldConfig,
    FormGroup,
    FormArray,
    GroupProps
} from 'react-reactive-form';
import { Col, Button } from 'react-bootstrap';
import { WithTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';

import { FormUtil } from '../common/FormUtil';
import {
    clearSelectedCodeID,
    saveCode,
    updateCode
} from '../../actions/manageCodeActions';
import { IdeficiencyCode, IproductInfo } from '../../models';
import { constants } from '../../constants/constants';
import { debounce } from 'lodash';

// add the bootstrap form-control class to the react-select select component

interface Iprops {
    handleSubmit: typeof saveCode;
    handleEdit: typeof updateCode;
    handleCancel: any;
    loading: boolean;
    colorButton: string;
    selectedCode: IdeficiencyCode;
    clearSelectedCodeID: typeof clearSelectedCodeID;
    updateFormValue: (formValue: { [key: string]: any }) => any;
    setFormValues: (formValues: { [key: string]: any }) => any;
    formValues: { [key: string]: any };
    productInfo: IproductInfo;
}

interface State {
    fieldConfig: FieldConfig;
}

class EditCodeForm extends React.Component<Iprops & WithTranslation, State> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValueDebounced: (formValues: {
        [key: string]: any;
    }) => void;
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
        this.updateFormValueDebounced = debounce(
            this.props.updateFormValue,
            constants.formDebounceTime
        );
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.clearSelectedCodeID();
        this.props.setFormValues({});
    }

    buildFieldConfig = () => {
        const disabled = false;
        const { selectedCode, formValues } = this.props;
        let { name, description, standardID } = selectedCode;
        name = formValues.name ? formValues.name : name;
        description = formValues.description
            ? formValues.description
            : description;
        const standardOptions = this.props.productInfo.standardOptions;
        const standard = FormUtil.convertToSingleOption(
            this.props.productInfo.standards[standardID]
        );

        const fieldConfigControls = {
            name: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.TextInput,
                meta: {
                    label: 'codeForm.name',
                    colWidth: 12,
                    autoFocus: true,
                    required: true,
                    name: 'code-name'
                },
                formState: { value: name, disabled }
            },
            standard: {
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                render: FormUtil.Select,
                meta: {
                    options: standardOptions,
                    label: 'common:standard',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'standard',
                    required: true
                },
                formState: { value: standard, disabled }
            },
            description: {
                options: {
                    validators: FormUtil.validators.requiredRichText
                },
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'codeForm.description',
                    colWidth: 12,
                    name: 'code-description',
                    required: true,
                    initialContent: description
                },
                formState: { value: description, disabled }
            }
        } as { [key: string]: GroupProps };
        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        this.updateFormValueDebounced({ [key]: value });
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        const { selectedCode } = this.props;
        const { name, description, standard } = this.formGroup.value;
        const deficiencyCode: Partial<IdeficiencyCode> = {
            name: name || selectedCode.name,
            description: description || selectedCode.description,
            standardID: standard ? standard.value : selectedCode.standardID
        };

        if (this.props.selectedCode.id.length === 0) {
            this.props.handleSubmit(deficiencyCode);
        } else {
            this.props.handleEdit({ ...deficiencyCode, id: selectedCode.id });
        }
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t } = this.props;

        return (
            <form onSubmit={this.handleSubmit} className="clearfix beacon-form">
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.props.handleCancel}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        bsStyle={this.props.colorButton}
                        type="submit"
                        disabled={this.props.loading}
                    >
                        {t('common:save')}
                    </Button>
                </Col>
            </form>
        );
    }
}
export default EditCodeForm;
