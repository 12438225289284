import * as types from './actionTypes';

import { AxiosRequestConfig } from 'axios';
import { orderBy } from 'lodash';
import { IjobPart, Ioption, Ipart, ThunkResult } from '../models';

import { msalFetch } from '../components/auth/Auth-Utils';
import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import { beginAjaxCall } from './ajaxStatusActions';

/*
 * this is used for paging and filtering the jobs on the manage Jobs view
 * NOTE: the server automatically filters by the user's country
 */

/**
 *
 * @param parts
 * @returns partOptions sorted by the part description
 */
export const partsToOptions = (parts: unknown): Ioption[] => {
    if (Array.isArray(parts)) {
        const partOptions = parts.map((item: Ipart) => {
            return {
                value: item.id,
                label: `${item.number} - ${item.description}`,
                order: item.description
            };
        });
        return orderBy(partOptions, 'order');
    } else {
        return [];
    }
};

export const searchPartsForAsyncSelect = (
    search: string
): Promise<Ioption[]> => {
    const url = API.part.search;
    const axiosOptions: AxiosRequestConfig = {
        method: 'get',
        params: { number: search }
    };
    return msalFetch(url, axiosOptions)
        .then(data => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                return partsToOptions(data.data.result);
            }
        })
        .catch((error: any) => {
            constants.handleError(error, 'searchPartsForAsyncSelect');
            console.error(error);
            throw new Error(error);
        });
};

export const getJobParts = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const url = API.part.get;
        const jobID = getState().manageJob.selectedJob.id;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { jobID }
        };
        return msalFetch(url, axiosOptions)
            .then(data => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_JOB_PARTS_SUCCESS,
                        payload: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_JOB_PARTS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'getJobParts');
                console.error(error);
            });
    };
};

/*
 * saveJob
 * persist the job to the Server
 */
export function saveJobParts(jobParts: IjobPart[]): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const url = API.part.save;
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: jobParts
        };

        return msalFetch(url, axiosOptions)
            .then(data => {
                if (!data.data) {
                    throw new Error('missing data');
                }
                dispatch({
                    type: types.SAVE_JOB_PARTS_SUCCESS,
                    payload: jobParts
                });
                return data;
            })
            .catch((error: any) => {
                dispatch({
                    type: types.SAVE_JOB_PARTS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'save job parts');
                console.error(error);
            });
    };
}
