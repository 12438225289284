/*
 * Manage Install Modal - Container
 */

import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';

import { FormUtil } from '../common/FormUtil';
import { Iproduct, IproductInfo, IinstallBase } from '../../models';
import {
    toggleInstallContactModal,
    installContact
} from '../../actions/manageInventoryActions';
import CommonModal from '../common/CommonModal';
import InstallContactForm from './InstallContactForm';
import {
    initialInstallBase,
    initialProduct
} from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    t: TFunction;
    colorButton: string;
    selectedInstall: IinstallBase;
    selectedProduct: Iproduct;
    showModal: boolean;
    loading: boolean;
    toggleInstallContactModal: typeof toggleInstallContactModal;
    installContact: typeof installContact;
    productInfo: IproductInfo;
    selectedInstallBaseId: string;
    facilityId: string;
}

class InstallContactModal extends React.PureComponent<Iprops> {
    render() {
        return (
            <CommonModal
                show={this.props.showModal}
                className="install-edit"
                onHide={this.props.toggleInstallContactModal}
                title={this.props.t('installContactTitle')}
                container={document.getElementById('two-pane-layout')}
            >
                <InstallContactForm
                    {...this.props}
                    productName={this.props.selectedProduct.name}
                />
            </CommonModal>
        );
    }
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: RouteComponentProps<{}> & WithTranslation
) => {
    const {
        showInstallContactModal,
        productsByID,
        selectedProductID,
        selectedInstallBaseID,
        tableFilters,
        installBasesByID
    } = state.manageInventory;

    const selectedProduct = productsByID[selectedProductID] || initialProduct;
    const selectedInstall =
        installBasesByID[selectedInstallBaseID] || initialInstallBase;

    // If no facility is selected, use the first facility in the list
    // This can happen if the user logs in and hasn't changed from the facility they are assigned to
    const facilityID =
        state.manageFacility.selectedFacilityID !== ''
            ? state.manageFacility.selectedFacilityID
            : Object.values(state.user.facilities)[0]?.id;

    return {
        user: state.user,
        loading: selectIsLoading(state),
        facilityOptions: FormUtil.convertToOptions(state.user.facilities),
        showModal: showInstallContactModal,
        productInfo: state.productInfo,
        tableFilters,
        selectedProduct,
        selectedInstall,
        selectedInstallBaseId: selectedInstallBaseID,
        facilityId: facilityID
    };
};

export default withTranslation('manageInventory')(
    withRouter(
        connect(mapStateToProps, {
            toggleInstallContactModal,
            installContact
        })(InstallContactModal)
    )
);
