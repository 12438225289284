import { createSelector } from 'reselect';
import { IinitialState } from '.';
import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { Iconfig } from '../models';

export const getConfig = (state: IinitialState): Iconfig => state.config;

export const getIsMobile = createSelector(
    [getConfig],
    config => config.isMobile
);

export default function configReducer(
    state: Iconfig = initialState.config,
    action: any
): Iconfig {
    switch (action.type) {
        case types.SET_IS_MOBILE:
            return { ...state, isMobile: action.value as boolean };
        default:
            return state;
    }
}
