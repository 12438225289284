import React from 'react';
import { ReactComponent as Admin } from '../images/icons/BM_Admin.svg';
import { ReactComponent as Alerts } from '../images/icons/BM_Alerts.svg';
import { ReactComponent as Brands } from '../images/icons/BM_Brand.svg';
import { ReactComponent as Customers } from '../images/icons/BM_Customers.svg';
import { ReactComponent as Dashboard } from '../images/icons/BM_Dashboard.svg';
import { ReactComponent as Documents } from '../images/icons/BM_Documents.svg';
import { ReactComponent as Facility } from '../images/icons/BM_Facility.svg';
import { ReactComponent as Home } from '../images/icons/BM_Home.svg';
import { ReactComponent as InspectionStatus } from '../images/icons/BM_InspectionStatus.svg';
import { ReactComponent as Inventory } from '../images/icons/BM_Inventory.svg';
import { ReactComponent as JobComments } from '../images/icons/BM_JobComments.svg';
import { ReactComponent as Jobs } from '../images/icons/BM_Jobs.svg';
import { ReactComponent as Leads } from '../images/icons/BM_Leads.svg';
import { ReactComponent as Locations } from '../images/icons/BM_Locations.svg';
import { ReactComponent as StandardText } from '../images/icons/BM_StandardText.svg';
import { ReactComponent as ManageTeam } from '../images/icons/BM_ManageTeam.svg';
import { ReactComponent as ManageUsers } from '../images/icons/BM_ManageUsers.svg';
import { ReactComponent as Measurement } from '../images/icons/BM_Measurement.svg';
import { ReactComponent as NewUserQueue } from '../images/icons/BM_NewUserQueue.svg';
import { ReactComponent as PMP } from '../images/icons/BM_PreventMaint.svg';
import { ReactComponent as ProductQueue } from '../images/icons/BM_ProductQueue.svg';
import { ReactComponent as Quotes } from '../images/icons/BM_Quotes.svg';
import { ReactComponent as Reports } from '../images/icons/BM_Reports.svg';
import { ReactComponent as Sales } from '../images/icons/BM_Sales.svg';
import { ReactComponent as TAE } from '../images/icons/BM_TAE.svg';
import { ReactComponent as Training } from '../images/icons/BM_Training.svg';
import { ReactComponent as TrainingProgress } from '../images/icons/BM_TrainingProgress.svg';
import { ReactComponent as WorkOrders } from '../images/icons/BM_WorkOrders.svg';
import { ReactComponent as ManageProducts } from '../images/icons/BM_ManageProducts.svg';

export const svgIcons = {
    alerts: <Alerts />,
    admin: <Admin />,
    dashboard: <Dashboard />,
    brands: <Brands />,
    customers: <Customers />,
    documents: <Documents />,
    facility: <Facility />,
    home: <Home />,
    leads: <Leads />,
    locations: <Locations />,
    inspectionStatus: <InspectionStatus />,
    inventory: <Inventory />,
    jobs: <Jobs />,
    jobComments: <JobComments />,
    standardText: <StandardText />,
    manageTeam: <ManageTeam />,
    measurement: <Measurement />,
    manageUsers: <ManageUsers />,
    newUserQueue: <NewUserQueue />,
    pmp: <PMP />,
    products: <ManageProducts />,
    productQueue: <ProductQueue />,
    reports: <Reports />,
    sales: <Sales />,
    tae: <TAE />,
    training: <Training />,
    trainingProgress: <TrainingProgress />,
    quotes: <Quotes />,
    workOrders: <WorkOrders />
};
