import * as Highcharts from 'highcharts';
import * as React from 'react';

import { IanalyticsPMP, Ifacility } from '../../models';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HighchartsReact from 'highcharts-react-official';
import { Link, RouteComponentProps } from 'react-router-dom';
import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import { setTableFilter } from '../../actions/manageWorkOrderActions';
import { workOrderStatusEnum } from '../../models-enums';
import { mockAnalyticsPMP } from './mockAnalytics';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import { CountUp } from 'countup.js';

highchartsMore(Highcharts);
solidGauge(Highcharts);
interface Iprops {
    t: TFunction;
    pmp: IanalyticsPMP;
    loading: boolean;
    isDemoMode: boolean;
    setTableFilter: typeof setTableFilter;
    selectedFacility: Ifacility;
}

interface Istate {
    chartOptions: Partial<Highcharts.Options>;
}
export default class PMPAnalytics extends React.PureComponent<
    Iprops & RouteComponentProps<{}>,
    Istate
> {
    private totalRef = React.createRef<HTMLHeadingElement>();

    constructor(props: Iprops & RouteComponentProps<{}>) {
        super(props);
        this.state = {
            chartOptions: this.loadOptions()
        };
    }

    componentDidMount() {
        this.startCountUp();
        this.handleUpdatedData();
    }

    componentDidUpdate(prevProps: Iprops) {
        if (JSON.stringify(prevProps.pmp) !== JSON.stringify(this.props.pmp)) {
            this.startCountUp();
            this.handleUpdatedData();
        }
    }

    startCountUp = () => {
        if (this.totalRef.current) {
            const selectedData = this.getPMPData();
            const options = {
                useEasing: true
            };
            const totalCountUp = new CountUp(
                this.totalRef.current,
                selectedData?.totalWorkOrders,
                options
            );

            setTimeout(() => {
                totalCountUp.start();
            }, 750);
        }
    };

    handleUpdatedData = () => {
        const PMPData = this.getPMPData();

        if (PMPData) {
            this.setState({
                chartOptions: {
                    series: [
                        {
                            name: this.props.t('open'),
                            data: PMPData?.data.open,
                            type: 'solidgauge'
                        },
                        {
                            name: this.props.t('closed'),
                            data: PMPData?.data.closed,
                            type: 'solidgauge'
                        }
                    ]
                }
            });
        }
    };

    getPMPData = () => {
        const { pmp, isDemoMode } = this.props;

        if (isDemoMode) {
            return mockAnalyticsPMP;
        }

        return pmp;
    };

    // for US show one version, for UK show a different version
    getTitle = () => {
        if (
            this.props.selectedFacility.standardID ===
            constants.defaultStandardUS
        ) {
            return this.props.t('PMPAnalyticsTitle');
        } else return this.props.t('PMPAnalyticsTitleUK');
    };

    loadOptions = (): Highcharts.Options => ({
        title: {
            text: undefined
        },
        tooltip: {
            borderWidth: 0,
            backgroundColor: 'none',
            shadow: false,
            style: {
                fontSize: '12px'
            },
            valueSuffix: '%',
            pointFormat:
                '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
            positioner(labelWidth) {
                return {
                    x: (this.chart.chartWidth - labelWidth) / 2,
                    y: this.chart.plotHeight / 2 - 20
                };
            }
        },
        pane: {
            startAngle: 0,
            endAngle: 360,
            background: [
                {
                    // Track for Outer
                    outerRadius: '100%',
                    innerRadius: '70%',
                    backgroundColor: Highcharts.color(
                        constants.colors.lightTeal
                    )
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                },
                {
                    // Track for Inner
                    outerRadius: '70%',
                    innerRadius: '40%',
                    backgroundColor: Highcharts.color(constants.colors.textBlue)
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }
            ]
        },
        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: []
            // title: { text: this.props.t('numberOfPMPs'), align: 'high' },
            // labels: { overflow: 'justify' }
        },
        colors: [constants.colors.textBlue, constants.colors.lightTeal],
        chart: {
            type: 'solidgauge',
            backgroundColor: 'transparent',
            height: 235,
            width: 235
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true
            }
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: this.props.t('open'),
                data: this.getPMPData()?.data.open,
                type: 'solidgauge',
                cursor: 'pointer',
                events: {
                    click: event => {
                        this.props.setTableFilter({
                            status: {
                                value: `${workOrderStatusEnum.open}`,
                                label: this.props.t('open')
                            }
                        });
                        this.props.history.push('/pmp');
                    }
                }
            },
            {
                name: this.props.t('closed'),
                data: this.getPMPData()?.data.closed,
                type: 'solidgauge',
                cursor: 'pointer',
                events: {
                    click: event => {
                        this.props.setTableFilter({
                            status: {
                                value: `${workOrderStatusEnum.complete}`,
                                label: this.props.t('completed')
                            }
                        });
                        this.props.history.push('/pmp');
                    }
                }
            }
        ]
    });

    render() {
        const { t } = this.props;

        return (
            <div className="form-group pmp-analytics analytics-item">
                <div
                    className="analytics-inner"
                    style={{ position: 'relative' }}
                >
                    <div
                        className="analytics-data"
                        style={{ paddingRight: '0', position: 'unset' }}
                    >
                        <div>
                            <div
                                className="analytics-info"
                                style={{ position: 'unset' }}
                            >
                                <Link
                                    to={{
                                        pathname: '/pmp',
                                        state: { readOnly: true }
                                    }}
                                    style={{
                                        fontSize: '1.7em',
                                        marginBottom: 'auto'
                                    }}
                                    title={t('moreInfo')}
                                >
                                    <h4
                                        style={{
                                            color: '#000',
                                            display: 'inline',
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            margin: '0 10px 0 0',
                                            overflowWrap: 'break-word',
                                            textTransform: 'uppercase',
                                            wordWrap: 'break-word'
                                        }}
                                    >
                                        {this.getTitle()}
                                    </h4>
                                </Link>
                                <Link
                                    to={{
                                        pathname: '/pmp',
                                        state: { readOnly: true }
                                    }}
                                    style={{
                                        fontSize: '14px',
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        zIndex: 4
                                    }}
                                    title={t('moreInfo')}
                                    data-type="icon"
                                >
                                    <FontAwesomeIcon
                                        icon={['far', 'info-circle']}
                                        color="#A1ACB3"
                                        style={{
                                            fontSize: '14px',
                                            height: '14px',
                                            width: '14px'
                                        }}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div>
                            <div
                                className="analytics-info"
                                style={{ marginTop: '20px' }}
                            >
                                <span
                                    style={{
                                        color: constants.colors.darkGreyText,
                                        fontSize: '1.4rem',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {t('pmp.totalWorkOrders')}
                                </span>
                                <h2
                                    style={{
                                        fontSize: '3.8em',
                                        fontWeight: 'bold',
                                        marginTop: '5px',
                                        marginBottom: '0'
                                    }}
                                    ref={this.totalRef}
                                ></h2>
                                <span
                                    style={{
                                        color: constants.colors.grey,
                                        fontSize: '1.2rem',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    ({t('pmp.last90Days')})
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                margin: 'auto 0 9px'
                            }}
                        >
                            <div className="graph-key">
                                <h2>Status Key</h2>
                                <ul className="graph-key-list">
                                    <li
                                        className="graph-key-item"
                                        data-type="open"
                                    >
                                        <span />
                                        <p>Open</p>
                                    </li>
                                    <li
                                        className="graph-key-item"
                                        data-type="closed"
                                    >
                                        <span />
                                        <p>Closed</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        className="analytics-graph"
                        style={{
                            marginTop: '-15px',
                            minWidth: '235px',
                            padding: '0'
                        }}
                    >
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.chartOptions}
                            {...this.props}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
