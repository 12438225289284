import {
    IeditFacilityFormValues,
    IfacilityContractFormValues,
    IinstallFormValues,
    IpartFormValues,
    IsearchProductFormValues,
    IworkOrderFormValues
} from './modelsForms';

import { PointOptionsObject } from 'highcharts';
import * as H from 'history';
import { SortingRule } from 'react-table';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { svgIcons } from './constants/svgIcons';
import {
    DocumentGroups,
    dataFormatEnum,
    dataFormatTemperatureEnum,
    dataUnitEnum,
    facilitySiteSizeEnum,
    jobDefaultTypeEnum,
    jobTypesIdEnumInverse,
    measurementSystemEnum,
    productCommunicationMethodEnum,
    widgetsEnum
} from './models-enums';
import { IinitialState } from './reducers';

/* ***** Common between Mobile and Desktop ****** */

export type Ihistory = H.History;

export interface Iuser extends IbaseDataObject {
    countryID: string; // current active country
    country?: Icountry;
    userCountries: string[]; // all possible countries user may work in
    email: string;
    engineerVanID?: string;
    sapEngineerID?: string;
    facilities: IfacilityWithoutBuildings[];
    first: string;
    hasTeamMembers: boolean;
    isActive: boolean;
    last: string;
    lastLoginDate?: string;
    LastLoginDate?: string;
    manager?: any;
    managerID?: string;
    brandID?: string;
    phone: string;
    position: string;
    postalCodes?: IpostalCode[];
    salesManager?: any;
    salesManagerID: string;
    securityFunctions: string[];
    tempAddress?: string;
    tempAddress2?: string;
    tempCity?: string;
    tempCompany?: string;
    tempPassword?: string;
    tempState?: string;
    tempZip?: string;
    type: number;
    widgets: string;
    language?: number;
    tempUnits?: number;
    pressureUnits?: number;
    sapPlant?: string;
    sapNonProductiveWorkOrderNumber?: number;
    sapObjectID?: number;
    userStandards: string[];

    flowUnits?: number;
    vacuumUnits?: number;
    powerUnits?: number;
    phoneExtension?: string;
    phoneCountryCode?: string;
    mobilePhoneCountryCode?: string;
    mobilePhone?: string;
    comments?: string;
    currency?: string;
    optInGDPR: boolean;
}

export interface IsensorData {
    time: Date;
    originator: string;
    eventName: string;
    value: string;
}

export interface IpostalCode extends IbaseDataObject {
    userID: string;
    postalCode: string;
}

export interface IsapJobMapping {
    sapBusinessIndicator: string;
    isVirtual: boolean;
    jobTypeID: string;
    serviceCenter: string;
    sapMaterialNumber: string;
    isDefault: boolean;
}

export interface ItempUser {
    email: string;
    first: string;
    last: string;
    position: string;
    tempAddress: string;
    tempAddress2: string;
    tempCity: string;
    tempState: string;
    tempZip: string;
    countryID: string;
    phoneCountryCode?: string;
}

export interface IsecurityFunction {
    id: string;
    name: string;
    description: string;
}

export interface IbaseDataObject {
    id: string;
    createDate?: string;
    updateDate?: string;
    creatorID?: string;
    updaterID?: string;
    isDeleted: boolean;
}

export interface ImainCategory extends IbaseDataObject {
    name: string;
    id: string;
}

export interface Isubcategory extends IbaseDataObject {
    mainCategoryID: string;
    mainCategory: IbaseDataObject;
    name: string;
}

export interface Istandard extends IbaseDataObject {
    name: string;
}

export interface Ibrand extends IbaseDataObject {
    code: string;
    name: string;
    url?: string;
    comments?: string;
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    phoneNumber?: string;
    logo?: string;
    logoPath?: string;
    reportEmail?: string; // comma delimited list
    sendReportToCustomers?: boolean;
    replyToEmail?: string;
    countryID?: string;
    isManufacturer: boolean;
}

export interface IproductType extends IbaseDataObject {
    code: string;
    mainCategoryIDs: string[];
    name: string;
}

export interface Ipower extends IbaseDataObject {
    name: string;
}

export interface IsystemSize extends IbaseDataObject {
    code: string;
    mainCategoryIDs: string[];
    name: string;
}

export interface IinstallBase extends IbaseDataObject {
    productID: string;
    facilityID: string;
    nickname?: string;
    remarks?: string;
    serialNumber?: string;
    rfid?: string;
    installDate?: string;
    prodDate?: string;
    buildingID?: string;
    floorID?: string;
    locationID?: string;
    roomID?: string;
    quantity?: number;
    position?: string;
    system?: number;
    importedLocation?: string;
    workOrders?: IWorkOrder[];
    measurementPointListResultStatus: number;
    workOrderResultStatus: number;
    visibleStatus: number; // FE only - a combination of measurementPointListResultStatus and workOrderResultStatus
    latestWorkOrderID: string;
    latestWorkOrder?: IWorkOrder;
    latestMeasurementPointListResultID: string;
    latestMeasurementPointListResult?: ImeasurementPointResult;
    lastQuarterlyMaintenanceDate: string;
    lastSixMonthMaintenanceDate: string;
    lastYearlyMaintenanceDate: string;
    lastTwoYearMaintenanceDate: string;
    lastThreeYearMaintenanceDate: string;
    lastFiveYearMaintenanceDate: string;
    fullLocation?: string;
    mostRecentStatus?: number;
    sapEquipmentNumber?: string;
}

export interface IinstallBasePopulated extends IinstallBase {
    product: Iproduct;
    productNameString: string; // front end only
    locationString: string; // FE only
    workOrders: IWorkOrder[];
    latestMeasurementPointListResult: ImeasurementPointResult;
}

export interface IproductLink extends IbaseDataObject {
    productID: string;
    url: string;
    type: number;
    other: string;
}

export interface ITrainingItem {
    certificateUrl: string;
    cmEmail: string;
    cmFirst: string;
    cmLast: string;
    cmUserID: string;
    createDate: string;
    finished: number;
    id: string;
    isDeleted: boolean;
    passed: boolean;
    score: number;
    started: number;
    testID: number;
    testName: string;
    updateDate: string | null;
}

export interface Iproduct extends IbaseDataObject {
    name: string;
    sku: string;
    description: string;
    imagePath: string;
    subcategoryID: string;
    brandID: string;
    productTypeID: string;
    powerID: string;
    systemSizeID: string;
    subcategory?: Isubcategory;
    origin: string;
    notes: string;
    installBases?: IinstallBase[];
    quantity: number;
    isApproved: boolean;
    mergedProductID: string;
    isCompliant: boolean;
    hasSpares: boolean;
    isActive: boolean;
    communicationMethod: productCommunicationMethodEnum;
    installBasesCount?: number; // front end only
    links: IproductLink[];
    sapMaterialNumber?: string;
    isFinalProduct: boolean;
    productStandards: IproductStandard[];
}

export interface IproductStandard extends IbaseDataObject {
    productID: string;
    standardID: string;
}

/*
 * Location Models
 */
export interface Ibuilding extends IbaseDataObject {
    name: string;
    floors: Ifloor[];
    facilityID: string;
}

export interface Ifloor extends IbaseDataObject {
    name: string;
    locations: Ilocation[];
    buildingID: string;
}

export interface Ilocation extends IbaseDataObject {
    name: string;
    rooms: Iroom[];
    floorID: string;
}

export interface Iroom extends IbaseDataObject {
    name: string;
    locationID: string;
}

export interface IproductInfo {
    brands: { [key: string]: Ibrand };
    productTypes: { [key: string]: IproductType };
    mainCategories: { [key: string]: ImainCategory };
    powers: { [key: string]: Ipower };
    standards: { [key: string]: Istandard };
    subcategories: { [key: string]: Isubcategory };
    systemSizes: { [key: string]: IsystemSize };
    brandOptions: Ioption[];
    originOptions: Ioption[];
    productTypeOptions: Ioption[];
    mainCategoryOptions: Ioption[];
    powerOptions: Ioption[];
    standardOptions: Ioption[];
    subcategoryOptions: Ioption[];
    systemSizeOptions: Ioption[];
}

export interface IuserJob extends IbaseDataObject {
    userID: string;
    jobID: string;
    user?: Iuser;
}

export interface IjobWorkOrder extends IbaseDataObject {
    job?: Ijob;
    jobID: string;
    workOrderID: string;
    workOrder?: IWorkOrder;
}

export interface AssignedUser {
    first: string;
    last: string;
    email: string;
    language: number;
    tempUnits: number;
    pressureUnits: number;
    isApproved: boolean;
    isActive: boolean;
    widgets: string;
    tempPassword: string | null;
    tempCompany: string | null;
    tempAddress: string | null;
    tempAddress2: string | null;
    tempCity: string | null;
    tempState: string | null;
    tempZip: string | null;
    type: number;
    countryID: string;
    managerID: string | null;
    salesManagerID: string | null;
    engineerVanID: string;
    sapEngineerID: string;
    position: string;
    phone: string;
    lastLoginDate: string;
    country: string | null;
    securityFunctions: string | null;
    hasTeamMembers: boolean;
    facilities: string | null;
    postalCodes: string | null;
    importErrors: string | null;
    manager: string | null;
    salesManager: string | null;
    id: string;
    isDeleted: boolean;
    createDate: string;
    updateDate: string;
}

export interface Ijob extends IbaseDataObject {
    facilityID: string;
    assignedUserID: string;
    assignedUser?: AssignedUser;
    jobTypeID: string;
    userJobs: IuserJob[];
    startDate: string;
    endDate: string;
    status: string;
    jobNumber: string;
    facility?: Ifacility;
    jobWorkOrders?: IjobWorkOrder[];
    fseNotes: string;
}

export interface Iphoto extends IbaseDataObject {
    src: string;
    width: number;
    height: number;
    name: string;
    comments: string;
    file: string;
    fileType: number;
    id: string;
    installBaseID: string;
    isDeleted: boolean;
    isInReport: boolean;
    jobID: string;
    lightboxUrl: string;
    thumbnailUrl: string;
    createDate: string;
    updateDate: string;
    installBase: IinstallBase;
    product: Iproduct;
    productID: string;
    originalUrl?: string;
    isNew?: boolean; // FE only
}

export interface IleadInstallBase extends IbaseDataObject {
    installBaseID: string;
    leadID: string;
    installBase?: IinstallBase;
}

export interface IleadUser extends IbaseDataObject {
    assignedDueToMissingCoverage: boolean;
    userID: string;
    leadID: string;
    user?: Iuser;
}

export interface IleadUserPopulated extends IleadUser {
    user: Iuser;
}

export interface Ilead extends IbaseDataObject {
    contactID: string;
    contact?: IfacilityContact;
    facility?: Ifacility;
    facilityID: string;
    product?: Iproduct;
    productID: string;
    leadActivities: any;
    leadUsers?: IleadUser[];
    leadInstallBases?: IleadInstallBase[];
    leadNumber?: number;
    ranking: number;
    status: number;
    type: number;
    notes: string;
    quote?: Iquote;
    quoteID: string;
    partHtml: string;
    internalComments: string;
}

export interface IquoteLead extends IbaseDataObject {
    leadID: string;
    quoteID: string;
    lead: Ilead;
}

export interface IleadPopulated extends Ilead {
    leadUsers: IleadUserPopulated[];
}

export interface IleadActivity extends IbaseDataObject {
    type: number;
    activityDate: string;
    otherType: string;
    notes: string;
    followUpNote: string;
    followUpScheduled: string;
    followUpSent: string;
    leadID: string;
    lead?: Ilead;
    userID: string;
    user: Iuser;
}

export interface Iquote extends IbaseDataObject {
    comments: string;
    quoteNumber: string;
    revision: string;
    cloudDocumentID: string;
    cloudDocument?: IcloudDocument;
    poNumber: string;
    signatureUrl: string;
    authorizedName: string;
    status: number;
    leads: IquoteLead[];
    tos?: boolean;
    poCloudDocument?: IcloudDocument;
    signatureCloudDocument?: IcloudDocument;
    facility?: Ifacility; // FE only
    facilityID?: string; // FE only
    poFile?: any; // FE only
    signature?: Blob; // FE only
    salesUsers?: Iuser[]; // FE only
}

export interface IcloudDocument extends IbaseDataObject {
    facilityID: string;
    filename: string;
    fileSize: number;
    mimeType: string;
    url: string;
    container: string;
    key: string;
    version: string;
    name: string;
    cloudDocumentTypeID: string;
    groupType?: DocumentGroups;
    otherGroup?: boolean;
    documentID: string;
    documentDate: string;
    versions?: IcloudDocument[];
    isPrimary?: boolean;
    countryID: string;
    isSystem: boolean;
}

export interface IDocumentType {
    id: string;
    name: string;
}

export interface IAlert extends IbaseDataObject {
    title: string;
    text: string;
    type?: string;
    imageUrl: string;
    standards: { [key: string]: IbaseDataObject };
    expirationDate: string;
    isViewed: boolean;
    isActive: boolean;
}

export interface Itile {
    svgKey?: string;
    title: string;
    src?: string;
    srcBanner?: string;
    color: 'blue' | 'green' | 'dark' | 'orange' | 'purple' | 'grey';
    width: number;
    height: number;
    url: string;
    securityFunctions: string[];
    description: string;
    shouldHideOnDashboard?: boolean;
    shouldHideOnMenu?: boolean;
    key?: string;
    parentKey: number;
    order: number;
}

export interface IparentTile {
    svgKey?: keyof typeof svgIcons;
    imgIconActive?: string;
    title: string;
    url: string;
    key: number;
    tiles?: Itile[];
    order: number;
}

export interface IfacilityWithoutBuildings extends IbaseDataObject {
    name: string;
    name2: string;
    address: string;
    address2: string;
    city: string;
    countryID: string;
    state: string;
    postalCode: string;
    standardID: string;
    sapFacilityNumber: string;
    preventativeMaintenanceEnabled: boolean;
    siteSize?: facilitySiteSizeEnum;
    managedByID: string;
    leadFSEID?: string;
    type?: string;
    mgpsSite: boolean;
    inspectionProviderID?: string;
    maintenanceProviderID?: string;
    agslevProviderID?: string;
    environmentalTestProviderID?: string;
    authorizedPerson: string;
    authorizedCompanyID?: string;
    website?: string;
    comments?: string;
    contract?: IfacilityContract;
    isApproved: boolean;
}

export interface Ifacility extends IfacilityWithoutBuildings {
    buildings: Ibuilding[];
}

export interface Ioption {
    [key: string]: any;
    value: string;
    label: string;
}

export interface IoptionNumberValue {
    value: number; // technically this should be a string, but number works just fine
    label: string;
}

/*
 * Measurement Points
 */
export interface ImeasurementPointSelectOption extends IbaseDataObject {
    value: string;
    label: string;
    isDefault?: boolean;
}

export interface ImeasurementPoint extends IbaseDataObject {
    type: number;
    label: string;
    order: number;
    isRequired: boolean;
    showInReport: boolean;
    guideText?: string;
    helpText?: string;
    allowNotes: boolean;
    passFailDefault?: number;
    numericMinValue?: number;
    numericMaxValue?: number;
    numericAllowDecimals?: boolean;
    selectDefaultOptionID?: string;
    selectRememberBetweenDevice?: boolean;
    selectRememberBetweenInspection?: boolean;
    selectOptions?: ImeasurementPointSelectOption[];
    jobDefaultType?: jobDefaultTypeEnum;
    defaultText: string;
    dedicatedReportPage?: boolean;
    smartlinkID?: string;
    measurementSystem?: measurementSystemEnum;
    generateLead?: boolean;
}

export interface IsmartlinkDataPoint extends IbaseDataObject {
    description: string;
    number: number;
    stringValue: string;
    numericValue?: number;
}

export interface ImeasurementPointListTab extends IbaseDataObject {
    name: string;
    order: number;
    measurementPoints: { [key: string]: ImeasurementPoint };
}

export interface ImeasurementPointList extends IbaseDataObject {
    // measurementPointTabs: {[key: string]: ImeasurementPointListTab};
    measurementPointTabs: ImeasurementPointListTab[];
    mainCategoryID: string;
    testProcedures: string;
    standardID: string;
    type: number;
    temporary?: boolean;
    forJob?: boolean;
}

export interface ImeasurementPointAnswer {
    measurementPointID: string;
    pass?: number;
    numericValue?: number;
    textValue?: string;
    notes?: string;
    measurementPointSelectOptionID?: string;
    dateValue?: string;
}

export interface ImeasurementPointResult extends IbaseDataObject {
    jobID: string;
    status: number;
    notes: string;
    temporary?: boolean;
    measurementPointAnswers: ImeasurementPointAnswer[];
    installBaseID: string;
    measurementPointListID: string;
    compiledNotes: string;
    manualOverride: boolean;
    previousStatus?: number; // FE only
    installBaseHistory: IinstallBaseHistory[];
}

export interface IinstallBaseHistory {
    id: string;
    date: Date;
    notes: string;
    jobType: string;
    status: number;
    workOrderId: string;
}

export interface ImeasurementPointResultWithJobType
    extends ImeasurementPointResult {
    jobTypeID: string;
}

export interface ImeasurementPointResultsReducer {
    measurementPointResultsByID: { [key: string]: ImeasurementPointResult };
    selectedResult: ImeasurementPointResult;
    previousResult: ImeasurementPointResult;
    historicalResultID: string;
    installBaseHistory: IinstallBaseHistory[];
}

export interface IdefaultReport {
    id: string;
    reportType: number;
    defaultCoverLetter: string;
    name: string;
}

export interface Ireport {
    jobID: string;
    reportType: number;
    coverLetter: string;
    headerLogoPath: string;
}

// these are copied from react-redux-toastr beacuse could not figure out any other way to make typescript happy
export type transitionInType = 'bounceIn' | 'bounceInDown' | 'fadeIn';
export type transitionOutType = 'bounceOut' | 'bounceOutUp' | 'fadeOut';

export type ThunkResult<R> = ThunkAction<R, IinitialState, undefined, any>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    IinitialState,
    null,
    Action<string>
>;

/* **********   Desktop Only ***********/

/*
 * TRAINING MODELS
 */
// export interface GFBadge {
//   id: string;
//   name: string;
//   description: boolean;
//   hook: string;
//   count: number;
//   imagePath: string;
// }
export interface GFClass {
    id: string;
    name: string;
    code: string;
    classSize: number;
    createDate: string;
}

export interface GFCourse {
    id: string;
    name: string;
    description: string;
    cost: number;
    onSite: boolean;
}

export interface GFLesson {
    id: string;
    name: string;
    description: string;
    courseID: string;
    imagePath: string;
    order: number;
    primaryVideoPath: string;
    slideshowPath: string;
    courseLessons: Array<{ id: string; courseID: string; order: number }>;
    cost: number;
    score?: number;
    quizName?: string;
    isProtected: boolean;
}

export interface GFQuizAnswer {
    questionID: string;
    answer: string;
    isCorrect: boolean;
}

export interface GFLessons {
    [key: string]: GFLesson;
}

export interface GFClassProgressRaw {
    [key: string]: GFStudentQuizResult[];
}

export interface GFLessonsWithProgress {
    quiz01Results: GFStudentQuizResult[];
    quiz02Results: GFStudentQuizResult[];
    quiz03Results: GFStudentQuizResult[];
    quiz04Results: GFStudentQuizResult[];
    quiz05Results: GFStudentQuizResult[];
    lessonHasProgress: boolean;
}

export interface GFStudentWithProgress {
    quiz01Results: GFStudentQuizResult[];
    quiz02Results: GFStudentQuizResult[];
    quiz03Results: GFStudentQuizResult[];
    quiz04Results: GFStudentQuizResult[];
    quiz05Results: GFStudentQuizResult[];
}

export interface GFQuizQuestion {
    id: string;
    text: string;
    type: string;
    options: any[];
    correctAnswer: string;
    correctText: string;
    wrongText: string;
    order: number;
    userAnswer?: any;
    userCorrect?: boolean;
}

export interface GFQuizItem {
    id: string;
    name: string;
    imagePath: string;
    isComplete: boolean;
    videoPath: string;
    instructions: string;
    lessonID: string;
    questions: GFQuizQuestion[];
    isTimed: boolean;
    createDate?: any;
    updateDate?: any;
    studentCanAccess?: any;
    score?: number;
}

export interface GFStudentQuizResult {
    id?: string;
    studentID: string;
    lessonID: string;
    quizID: string;
    quiz: GFQuizItem;
    answers: any[];
    score: number;
    createDate: string;
    className: string;
}

export interface LessonProgress {
    id?: string;
    lessonID: string;
    userID: string;
    currentTime: number; // the current time of the video as reported from vimeo
    percentageComplete: number; // the current percent complete as reported  by vimeo
    totalTime: number; // the total time (or duration) in second of the video, from vimeo
    timeSpent: number;
    isComplete: boolean;
}

export interface GFQuizViewReducer {
    quizComplete: boolean;
    inProgressQuizID: string;
    startTime: string;
}

export interface GFQuizLinkReducer {
    link: string;
    failedToLoad?: boolean;
}

export interface ItrainingReducer {
    coursesByID: { [key: string]: GFCourse };
    lessonsByID: GFLessons;
    lesson: GFLesson;
    quizzesByID: { [key: string]: GFQuizItem };
    quiz: GFQuizItem;
    lessonProgress: { [key: string]: LessonProgress };
    cart: IshoppingCart;
    showShoppingCartModal: boolean;
    purchasedTraining: string[];
    quizAnswers: GFQuizAnswer[];
    quizView: GFQuizViewReducer;
    quizLink: GFQuizLinkReducer;
}

export interface Icountry {
    value: string;
    RegionID: string;
    label: string;
    Currency: string;
}

// this is the model that the backend uses for countries
export interface IcountryBE {
    sapCountryCode?: string;
    regionID: string;
    currency: string;
    region?: string;
    name: string;
    id: string;
    isDeleted: boolean;
    createDate: string;
    updateDate: string;
}

export interface ItableFiltersReducer {
    search: string;
    page: number;
    company?: Ioption;
    country?: Ioption;
    type?: Ioption;
    startDate?: string;
    endDate?: string;
    dueDate?: string;
    dateRange?: Ioption;
    facility?: Ioption;
    mainCategory?: Ioption;
    subCategory?: Ioption;
    brand?: Ioption;
    origin?: Ioption;
    title?: Ioption;
    sorted?: SortingRule[];
    standard?: Ioption;
    facilityID?: string;
    buildingID?: string;
    floorID?: string;
    locationID?: string;
    name?: string;
    number?: string;
    ranking?: Ioption;
    rows?: number;
    status?: Ioption;
    assignedUser?: Ioption;
    vendor?: number;
    technicianToDo?: Ioption;
    workOrderType?: number;
    tabKey?: any;
    userID?: Ioption;
    createDate?: string;
    jobID?: Ioption;
    commentType?: Ioption;
    isResolved?: Ioption;
    installBaseID?: Ioption;
    facilityName?: string;
    showCompleted?: Ioption;
    showAdditionalFilters?: boolean;
    countryID?: Ioption;
    jobTypeID?: string;
    jobType?: Ioption;
    measurementBasedOnly?: boolean;
    open?: boolean;
    assetSearch?: string;
    jobSearch?: string;
    mainCategoryID?: string;
    partSearch?: string;
    productSearch?: string;
    productID?: string;
    isSystem?: Ioption;
    userType?: Ioption;
    statusFilter?: Ioption;
    isDeleted?: boolean;
    sapWorkOrder?: boolean;
    courseID?: string;
    isCourseCompleted?: boolean;
    hideVirtualToggle?: boolean;
}

export interface ItableFiltersParams {
    search?: string;
    page?: number;
    name?: string;
    number?: string;
    company?: Ioption;
    type?: Ioption;
    startDate?: string;
    endDate?: string;
    dueDate?: string;
    dateRange?: Ioption;
    facility?: Ioption;
    mainCategory?: Ioption;
    subCategory?: Ioption;
    brand?: Ioption;
    title?: Ioption;
    sorted?: SortingRule[];
    standard?: Ioption;
    facilityID?: string;
    buildingID?: string;
    floorID?: string;
    locationID?: string;
    rows?: number;
    typeID?: string;
    status?: Ioption;
    assignedUser?: Ioption;
    vendor?: number;
    technicianToDo?: Ioption;
    workOrderType?: number;
    tabKey?: any;
    userID?: Ioption;
    createDate?: string;
    jobID?: Ioption;
    commentType?: Ioption;
    isResolved?: Ioption;
    installBaseID?: Ioption;
    facilityName?: string;
    showCompleted?: Ioption;
    showAdditionalFilters?: boolean;
    countryID?: Ioption;
    jobTypeID?: string;
    jobType?: Ioption;
    measurementBasedOnly?: boolean;
    open?: boolean;
    reportType?: number;
    assetSearch?: string;
    jobSearch?: string;
    mainCategoryID?: string;
    partSearch?: string;
    productSearch?: string;
    productID?: string;
    origin?: Ioption;
    isSystem?: Ioption;
    userType?: Ioption;
    statusFilter?: Ioption;
    isDeleted?: boolean;
    sapWorkOrder?: boolean;
    courseID?: string;
    isCourseCompleted?: boolean;
    country?: Ioption;
    hideVirtualToggle?: boolean;
}

export interface IuserQueue {
    id: string;
    user: Iuser;
}

export interface IshoppingCartProduct {
    name: string;
    quantity: number;
    id: string;
    cost: number;
}

export interface IquoteItem {
    productID: string;
    quantity: number;
}

export interface ImanageUserQueueReducer {
    userQueuesByID: { [key: string]: IuserQueue };
    totalPages: number;
    tableFilters: ItableFiltersReducer;
    selectedUserQueue: IuserQueue;
}

export interface ImanageUserReducer {
    usersByID: { [key: string]: Iuser };
    totalPages: number;
    tableFilters: ItableFiltersReducer;
    selectedUser: Iuser;
    activeLocalCountry: string;
}

export interface ImanageLocationReducer {
    visibleLocations: Array<Ibuilding | Ifloor | Ilocation | Iroom>;
    totalPages: number;
    showEditLocationModal: boolean;
    tableFilters: ItableFiltersReducer;
}

export interface ImanageBrandReducer {
    manageBrandsByID: { [key: string]: Ibrand };
    totalPages: number;
    tableFilters: ItableFiltersReducer;
    selectedBrand: Ibrand;
}

export interface ImanageDocumentTypeReducer {
    documentTypesByID: { [key: string]: IDocumentType };
    selectedDocumentTypeID: string;
}

export interface ImanageManageDocumentReducer {
    documentsByID: { [key: string]: IcloudDocument };
    totalPages: number;
    showEditDocumentModal: boolean;
    showEditVersionModal: boolean;
    tableFilters: ItableFiltersReducer;
    selectedParentID: string;
    selectedDocumentID: string;
    documentFormValues: { [key: string]: any };
    versionFormValues: { [key: string]: any };
}

export interface ImanageAlertReducer {
    alertsByID: { [key: string]: IAlert };
    totalPages: number;
    showEditAlertModal: boolean;
    tableFilters: ItableFiltersReducer;
    selectedAlertID: string;
    alertFormValues: { [key: string]: any };
}

// MANAGE WORK ORDERS

export interface Ipart extends IbaseDataObject {
    description: string;
    number: string;
    installationTime: number;
    isActive: boolean;
    measurement: string;
    origin: string;
}

export interface IjobPart extends IbaseDataObject {
    jobID: string;
    partID: string;
    estimated: number;
    used: number;
    lot: string;
}

export interface IjobPartPopulated extends IjobPart {
    part: Ipart;
}

export enum WorkOrderSource {
    MyMedGas = 0,
    SAP = 1
}
export interface IWorkOrder extends IbaseDataObject {
    number?: string;
    vendor: number;
    technician?: string;
    contactInfo?: string;
    dueDate?: string;
    priority: number;
    status: number;
    type: number;
    productName: string;
    installBaseLocation?: string;
    activityDescription?: string;
    partsDescription?: string;
    closingNotes?: string;
    notes?: string;
    installBaseID: string;
    file1?: any;
    file2?: any;
    file3?: any;
    preventativeMaintenanceChecklist?: IpreventativeMaintenanceChecklist;
    lastServicedDate?: string; // set by the backend
    attachmentUrl1?: string;
    attachmentUrl2?: string;
    attachmentUrl3?: string;
    jobWorkOrders?: IjobWorkOrder[];
    jobNumber?: string;
    assignedUser?: string;
    userID: string;
    facility?: Ifacility;
    parts: Ipart[];
    sapBusinessIndicator: string | undefined;
    source: WorkOrderSource;
    suggestedJobTypeID?: keyof typeof jobTypesIdEnumInverse;
    sapPlant?: string;
    product?: Iproduct;
}

export interface IpreventativeMaintenanceChecklist extends IbaseDataObject {
    preventativeMaintenancePlanID: string;
    scheduleInterval: number;
    name: string;
    preventativeMaintenancePoints: IpreventativeMaintenancePoint[];
}

export interface IpreventativeMaintenancePoint extends IbaseDataObject {
    preventativeMaintenanceChecklistID: string;
    answerType: number;
    order: number;
    label: string;
    trueFalseDefault: 1;
    isRequired: boolean;
    helpText: string;
}

export interface ImanageWorkOrderReducer {
    workOrdersByID: { [key: string]: IWorkOrder };

    visibleWorkOrdersByID: { [key: string]: IWorkOrder };
    technicians: string[];
    totalPages: number;
    showEditWorkOrderModal: boolean;
    showEditPartModalForm: boolean;
    showWorkOrderCloseModal: boolean;
    tableFilters: ItableFiltersReducer;
    selectedWorkOrderID: string;
    selectedPartID: string;
    workOrderFormValues: IworkOrderFormValues;
    preventativeMaintenanceChecklistsByID: {
        [key: string]: IpreventativeMaintenanceChecklist;
    };
    showConfirmSelectJobModal: boolean;
    selection: string[];
    partFormValues: IpartFormValues;
    selectedFacilityIDForConfirmSelectJob: string;
    fsesWithWorkOrders: Iuser[];
}

export interface ImanageCodeReducer {
    deficiencyCodesByID: { [key: string]: IdeficiencyCode };
    totalPages: number;
    showEditCodeModal: boolean;
    tableFilters: ItableFiltersReducer;
    selectedCodeID: string;
    codeFormValues: { [key: string]: any };
}

export interface ImanageUserAlertReducer {
    data: { [key: string]: IAlert };
    showUserAlertModal: boolean;
}

export interface ImanageJobReducer {
    jobsByID: { [key: string]: Ijob };
    openJobsForWorkOrdersByID: { [key: string]: Ijob };
    jobWorkOrdersByID: { [key: string]: IjobWorkOrder };
    fseUsersByID: { [key: string]: Iuser };
    totalPages: number;
    nextJobNumber: string;
    tableFilters: ItableFiltersReducer;
    selectedJob: Ijob;
}

export interface ImanageLeadsReducer {
    leadsByID: { [key: string]: Ilead };
    leadUsersByID: { [key: string]: IleadUser };
    totalPages: number;
    showEditLeadModal: boolean;
    showLeadActivityModal: boolean;
    showEditLeadActivityModal: boolean;
    tableFilters: ItableFiltersReducer;
    leadInstallBasesByID: { [key: string]: IleadInstallBase };
    selection: string[];
    selectedLead: Ilead;
    selectedLeadActivity: IleadActivity;
    leadActivitiesByID: { [key: string]: IleadActivity };
}

export interface ImanageReportReducer {
    defaultReportsByID: { [key: string]: IdefaultReport };
    totalPages: number;
    showEditReportModal: boolean;
    selectedReport: Ireport;
    selectedDefaultReportID: string;
    tableFilters: ItableFiltersReducer;
}

export interface ImanageFacilityReducer {
    contacts: { [key: string]: IfacilityContact };
    selectedContactID: string;
    // usersMatchEmail: Array<Iuser | IfacilityContact>;
    foundUsersOrContactsByID: { [key: string]: Iuser | IfacilityContact };
    facilityFormValues: IeditFacilityFormValues;
    selectedContact: IfacilityContact;
    tableFilters: ItableFiltersReducer;
    selectedFacilityID: string;
    facilityContractFormValues: IfacilityContractFormValues;
    showFacilityContractModal: boolean;
}

export interface ImanageQuotesReducer {
    quotesByID: { [key: string]: Iquote };
    tableFilters: ItableFiltersReducer;
    selectedQuote: Iquote;
    totalPages: number;
    showEditQuoteModal: boolean;
    showQuoteDetailsModal: boolean;
    showSalesQuoteModal: boolean;
}

export interface IsideMenuReducer {
    selectedMenuItem: string;
    currentMenuItem: string;
}

export interface ImanageTeamReducer {
    teamMembersByID: { [key: string]: Iuser };
    totalPages: number;
    showEditTeamModal: boolean;
    editUserFormValues: { [key: string]: any };
    tableFilters: ItableFiltersReducer;
    selectedUserID: string;
}

export interface IshoppingCart {
    addedIDs: string[];
    productsByID: { [key: string]: IshoppingCartProduct };
}

export interface IsearchNewProductInstallBatchMode {
    installBaseIDs: string[];
    isBatchMode: boolean;
}

export interface ImanageInventoryReducer {
    productsByID: { [key: string]: Iproduct };
    installBasesByID: { [key: string]: IinstallBase };
    totalPages: number;
    cart: IshoppingCart;
    showEditProductModal: boolean;
    showEditInstallModal: boolean;
    showShoppingCartModal: boolean;
    showInstallContactModal: boolean;
    showSearchNewProductsModal: boolean;
    showImportInstallModal: boolean;
    tableFilters: ItableFiltersReducer;
    searchNewProductsSelectedProduct: Iproduct;
    newProducts: { [key: string]: Iproduct };
    showMPResultModal: boolean;
    showMPResultHistoryModal: boolean;
    showMPResultNotesModal: boolean;
    selectedProductID: string;
    selectedInstallBaseID: string;
    productSearchFormValues: Partial<IsearchProductFormValues>;
    installFormValues: IinstallFormValues;
    searchNewProductInstallBatchMode: IsearchNewProductInstallBatchMode;
}

export interface IsyncStatusReducer {
    fseUsersUpdated: number;
    allContactsUpdated: number;
}

export interface IproductQueueObject extends IbaseDataObject {
    productID: string;
    creator: Iuser;
    product: Iproduct;
}

export interface ImanageProductQueueReducer {
    productQueueByID: { [key: string]: IproductQueueObject };
    mergeableProductsByID: { [key: string]: IproductQueueObject };
    totalPages: number;
    showApproveProductModal: boolean;
    tableFilters: ItableFiltersReducer;
}

export interface ImanageTrainingProgress {
    userName: string;
    courseName: string;
    progress: string;
    results: string;
    userID?: string;
    courseID?: string;
}

export interface ImanageTrainingReducer {
    trainingProgress: { [key: string]: ImanageTrainingProgress };
    totalPages: number;
    tableFilters: ItableFiltersReducer;
}

export interface Iredirect {
    redirectToReferrer: boolean;
    pathname: string;
}

export interface ImanageMeasurementPointListsReducer {
    measurementPointListsByID: { [key: string]: ImeasurementPointList };
    measurementPointsByID: { [key: string]: ImeasurementPoint };
    totalPages: number;
    selectedMeasurementPointList: ImeasurementPointList;
    showEditMeasurementPointListModal: boolean;
    showEditMeasurementPointModal: boolean;
    showEditMeasurementPointTabModal: boolean;
    showEditMeasurementPointListTestProceduresModal: boolean;
    // selectedTab: ImeasurementPointListTab;
    tableFilters: ItableFiltersReducer;
    selectedTabID: string;
    selectedMeasurementPoint: ImeasurementPoint;
}

export interface IdataFormatPreference {
    temperature: dataFormatTemperatureEnum;
}

export interface IanalyticsSmartlinkLatestValue {
    date: string;
    pointNumber: string;
    unit: dataUnitEnum;
    label: string;
    value: string;
    format: dataFormatEnum;
}

export interface ItaeManifold {
    guage01?: IchartGuage;
    guage02?: IchartGuage;
    guage03?: IchartGuage;
    guage04?: IchartGuage;
    bar01?: IchartBar;
    gasType?: string;
    isServiceDue?: boolean;
    runningHours?: number;
    loadedHours?: number;
    alarms?: string;
}

export interface IchartGuage {
    data: Array<number | [number | string, number | null] | null>;
    label: string;
    unit: dataUnitEnum;
    format: dataFormatEnum;
    max?: number;
    min?: number;
    medium?: number;
    high?: number;
}

export interface IchartBar {
    data: (number | string)[][];
    label: string;
    unit: dataUnitEnum;
    format: dataFormatEnum;
    max?: number;
    min?: number;
    medium?: number;
    high?: number;
}

export interface IlineChartData {
    data: number[];
    name: string;
}

export interface IchartLine {
    data: IlineChartData | number[];
    label: string;
    unit: dataUnitEnum;
    format: dataFormatEnum;
}

// TODO finish this IsmartlinkAirSystem interface based on the mockAnalyticssmartlink
export interface IsmartlinkAirSystem {
    guage01?: IchartGuage;
    guage02?: IchartGuage;
    guage03?: IchartGuage;
    bar01?: IchartBar;
    line01?: IchartLine;
    nextService?: number;
    runningHours?: number;
    loadedHours?: number;
    alarms?: any;
}

export interface IdashboardAnalyticsReducer {
    data: IdashboardAnalyticsData;
}

export type Iwidgets = Partial<typeof widgetsEnum>;

export interface IjobComment extends IbaseDataObject {
    jobID: string;
    userID: string;
    isDeficiency: boolean;
    text: string;
    codeID: string;
    isResolved: boolean;
}

export interface IdeficiencyCode extends IbaseDataObject {
    description: string;
    name: string;
    standardID: string;
}

export interface ImanageJobCommentsReducer {
    jobCommentsByID: { [key: string]: IjobComment };
    selectedJobComment: IjobComment;
    showEditJobCommentModal: boolean;
    filteredJobComments: IjobComment[];
    tableFilters: ItableFiltersReducer;
}

// Manage TAE
export interface Itae extends IbaseDataObject {
    installBaseID: string;
    serialNumber: string;
    date: string;
    systemHours: number;
    unit1RunningHours: number;
    unit2RunningHours: number;
    lagAlarm: boolean;
    dewpointAlarm: boolean;
    coAlarm: boolean;
    dryer1Mode: string;
    dryer2Mode: string;
    dewpointValue: number;
    coValue: number;
    receiverPressure: number;
    leftBankPressureBar: number;
    rightBankPressureBar: number;
    deliveryPressureBar: number;
    reservePressureBar: number;
    leftBankPressurePSI: number;
    rightBankPressurePSI: number;
    deliveryPressurePSI: number;
    reservePressurePSI: number;
    leftBankPressureKPA: number;
    rightBankPressureKPA: number;
    deliveryPressureKPA: number;
    reservePressureKPA: number;
    leftBankEmpty: number;
    rightBankEmpty: number;
    leftBankSelectLatch: boolean;
    reserveInUseAlarm: boolean;
    reserveLowAlarm: boolean;
    secondaryLowAlarm: boolean;
    changeoverAlarm: boolean;
    gasType: string;
    timeOnCurrentBank: number;
    timeOnPreviousBank: number;
}

export interface ImanageTaeReducer {
    taeByID: { [key: string]: Itae };
    selectedItemID: string;
    showEditModal: boolean;
    // filteredJobComments: IjobComment[];
    tableFilters: ItableFiltersReducer;
}

export interface IcontactFacility {
    facilityID: string;
    contactID?: string;
}

export interface IfacilityContact extends IbaseDataObject {
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    phone: string;
    mobile: string;
    enableNotifications: boolean;
    smartlinkNotifications: boolean;
    contactFacilities: IcontactFacility[];
    userID?: string;
    user?: Iuser;
    source: number;
}

export interface IsuggestedPart extends IbaseDataObject {
    productID: string;
    partID: string;
    part: Ipart;
    product: Iproduct;
    productQuantity: number;
    adminComments: string;
    installBaseID: string;
    installBaseQuantity: number;
}

export interface IfacilityContract extends IbaseDataObject {
    facilityID: string;
    myMedGasContract?: number; // enum
    myMedGasContractStart?: string;
    myMedGasContractEnd?: string;
    myMedGasContractComments?: string;
    inspectionContract?: number; // enum
    inspectionContractStart?: string;
    inspectionContractEnd?: string;
    inspectionContractComments?: string;
    maintenanceContract?: number;
    maintenanceContractStart?: string;
    maintenanceContractEnd?: string;
    maintenanceContractComments?: string;
    agslevContract?: number;
    agslevContractStart?: string;
    agslevContractEnd?: string;
    agslevContractComments?: string;
    environmentalContract?: number;
    environmentalContractStart?: string;
    environmentalContractEnd?: string;
    environmentalContractComments?: string;
    boomContract?: number;
    boomContractStart?: string;
    boomContractEnd?: string;
    boomContractComments?: string;
    leakTestingContract?: number;
    leakTestingContractStart?: string;
    leakTestingContractEnd?: string;
    leakTestingContractComments?: string;
    traceGasAnalysisContract?: number;
    traceGasAnalysisContractStart?: string;
    traceGasAnalysisContractEnd?: string;
    traceGasAnalysisContractComments?: string;
    otherContract?: number;
    otherContractStart?: string;
    otherContractEnd?: string;
    otherContractComments?: string;
}

export interface ItutorialReducer {
    viewedTours: string[];
    run: boolean;
}

export interface ImanageProductReducer {
    productsByID: { [key: string]: Iproduct };
    showEditProductModal: boolean;
    tableFilters: ItableFiltersReducer;
    totalPages: number;
}

// export interface GroupPropsEdited extends Omit<GroupProps, 'render'>{
//     render?: (
//         control: FormArray | FormControl | FormGroup | JsxElement
//       ) => React.ReactElement<any> | React.ReactElement<any>[];
// }

export interface ImanageAssetPhotosReducer {
    photos: { [key: string]: Iphoto };
    photosByProduct: { [key: string]: Iphoto[] };
    totalPages: number;
    tableFilters: ItableFiltersReducer;
}

export interface ImanagePartReducer {
    jobParts: { [key: string]: IjobPartPopulated };
}

export interface ImanageSuggestedPartsReducer {
    suggestedParts: { [key: string]: IsuggestedPart };
    totalPages: number;
    showEditSuggestedPartModal: boolean;
    tableFilters: ItableFiltersReducer;
    selectedSuggestedPart: IsuggestedPart;
}

// Dashboard
export interface IanalyticsInspection {
    mainCategoryID: string;
    mainCategoryName: string;
    jobDate: string;
    total: number;
    data: any[];
}

export interface IanalyticsPMP {
    totalWorkOrders: number;
    data: {
        open: PointOptionsObject[];
        closed: PointOptionsObject[];
    };
}

export interface IanalyticsTAE {
    photoUrl: string;
    mainCategoryID: string;
    mainCategoryName: string;
    installBaseID: string;
    installBaseName: string;
    installBaseLocation: string;
    installBaseSerialNumber: string;
    systemRunHours: number;
    unit1Hours: number;
    unit2Hours: number;
    dewpoint: number;
    dryer1Mode: string;
    dryer2Mode: string;
    coLevel: number;
    receiverPressure?: number;
    dewpointAlarm: boolean;
    coAlarm: boolean;
    lagAlarm: boolean;
    leftBankPressureBar: number;
    rightBankPressureBar: number;
    deliveryPressureBar: number;
    reservePressureBar: number;
    reservePressurePSI: number;
    leftBankPressureKPA: number;
    rightBankPressureKPA: number;
    deliveryPressureKPA: number;
    reservePressureKPA: number;
    leftBankEmpty: number;
    rightBankEmpty: number;
    timeOnCurrentBank: number;
    timeOnPreviousBank: number;
    leftBankPressurePSI: number;
    rightBankPressurePSI: number;
    deliveryPressurePSI: number;
    leftBankSelectLatch: boolean;
    reserveInUseAlarm: boolean;
    reserveLowAlarm: boolean;
    secondaryLowAlarm: boolean;
    changeoverAlarm: boolean;
    gasType: string;
    date: string;
    device: ItaeManifold;
}

export interface MaintenanceInterval {
    item: string;
    due: string;
}

export interface BarChartData {
    item1: string;
    item2: number;
}

export interface TAEDashboardData {
    data: number[];
    barChartData: BarChartData[] | null;
    label: string;
    pointNumber: string;
    unit: number;
    format: number;
    max: number;
    min: number;
    medium: number;
    high: number;
    graphType: number;
    graphSize: number;
    pumpRunStatus: number | null;
    compressorRunStatus: number | null;
    operatingMode: number | null;
    systemMaintenanceIntervals: MaintenanceInterval[] | null;
    maintenanceIntervals: MaintenanceInterval[] | null;
}

export interface IanalyticsTAEDashboard {
    photoUrl: string;
    mainCategoryID: string;
    mainCategoryName: string;
    updateDate: string;
    installBaseID: string;
    installBaseName: string;
    installBaseLocation: string;
    installBaseSerialNumber: string;
    productID: string;
    device: null;
    primaryData: TAEDashboardData[];
    secondaryData: TAEDashboardData[];
    alarmCount: number;
    alarms: string[];
}

export interface IanalyticsSmartlink {
    photoUrl: string;
    mainCategoryID: string;
    mainCategoryName: string;
    installBaseID: string;
    installBaseName: string;
    installBaseLocation: string;
    installBaseSerialNumber: string;
    latestValues: IanalyticsSmartlinkLatestValue[]; // TODO remove this and unitGraphs below when finishing dashboard redesign
    unit1Graph: Array<number | [number | string, number | null] | null>;
    unit2Graph: Array<number | [number | string, number | null] | null>;
    updateDate: string;
    device: IsmartlinkAirSystem;
}

export interface IanalyticsQuote {
    total: number;
    new: number;
    approved: number;
    inProgress: number;
    rejected: number;
    unquotedLeads: number;
}

export interface IdashboardAnalyticsData {
    inspection: IanalyticsInspection[];
    pmp: IanalyticsPMP;
    tae: IanalyticsTAE[];
    smartlinks: IanalyticsTAEDashboard[];
    quote: IanalyticsQuote;
}

export interface Iconfig {
    isMobile: boolean;
}
