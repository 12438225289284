import * as types from '../actions/actionTypes';

import {
    ImanageMeasurementPointListsReducer,
    ImeasurementPoint,
    ImeasurementPointList,
    ImeasurementPointListTab
} from '../models';
import {
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import { forEach, keyBy, map, omit, filter } from 'lodash';

import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { initialMeasurementPoint } from './initialState';
import { initialMeasurementPointList } from './initialState';
import { IinitialState } from '.';

/*
 * Selector functions
 */

// const selectMeasurementPointLists = (state: IinitialState) => state.manageMeasurementPointLists.measurementPointListsByID;
const selectSelectedMeasurementPointList = (state: IinitialState) =>
    state.manageMeasurementPointLists.selectedMeasurementPointList;
export const selectMeasurementPointListTabs = createSelector(
    [selectSelectedMeasurementPointList],
    selectedList => {
        return filter(selectedList.measurementPointTabs, { isDeleted: false });
    }
);

/*
 ***** Reducer Fuctions ****
 */

/*
 * data - keyed by the measurementPointListID
 */

const keyMeasurementPoints = (
    measurementPointTabs: ImeasurementPointListTab[]
) => {
    return map(measurementPointTabs, (tab: ImeasurementPointListTab) => {
        const measurementPointsKeyed = keyBy(
            tab.measurementPoints,
            (item: ImeasurementPoint) => item.id
        );
        return { ...tab, measurementPoints: measurementPointsKeyed };
    });
};

function measurementPointListsByID(
    state: { [key: string]: ImeasurementPointList } = {},
    action: any
): { [key: string]: ImeasurementPointList } {
    switch (action.type) {
        case types.MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS: {
            // return initialState.measurementPointLists.data;
            const measurementPointLists = action.measurementPointLists.map(
                (measurementPointList: ImeasurementPointList) => {
                    const measurementPointTabs = keyMeasurementPoints(
                        measurementPointList.measurementPointTabs
                    );
                    return { ...measurementPointList, measurementPointTabs };
                }
            );
            return keyBy(measurementPointLists, 'id');
        }
        case types.MANAGE_MEASUREMENT_POINT_LIST_ADD:
            return {
                ...state,
                [action.measurementPointList.id]: action.measurementPointList
            };
        case types.MANAGE_MEASUREMENT_POINT_LIST_UPDATE:
            if (action.persistToAPI === false) {
                return state;
            }
            return {
                ...state,
                [action.measurementPointList.id]: action.measurementPointList
            };
        case types.MEASUREMENT_POINT_LIST_SUCCESS: {
            const tabs: ImeasurementPointListTab[] =
                action.list.measurementPointTabs;
            return {
                ...state,
                [action.list.id]: {
                    ...action.list,
                    measurementPointTabs: keyMeasurementPoints(tabs)
                }
            };
        }
        case types.MANAGE_MEASUREMENT_POINT_LIST_DELETE_SUCCESS:
            return omit(state, action.MPlistID);
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

const measurementPointsByID = (
    state: { [key: string]: ImeasurementPoint } = {},
    action: any
): { [key: string]: ImeasurementPoint } => {
    switch (action.type) {
        case types.MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS: {
            // const measurmentPointLists = initialState.measurementPointLists.data;
            const measurmentPointLists = action.allLists;
            let measurementPoints = {};
            forEach(measurmentPointLists, (list: ImeasurementPointList) => {
                forEach(
                    list.measurementPointTabs,
                    (tab: ImeasurementPointListTab) => {
                        measurementPoints = {
                            ...measurementPoints,
                            ...keyBy(
                                tab.measurementPoints,
                                (mp: ImeasurementPoint) => mp.id
                            )
                        };
                    }
                );
            });
            return { ...state, ...measurementPoints };
        }
        case types.MEASUREMENT_POINT_LIST_SUCCESS: {
            let measurementPointsB = {};
            forEach(
                action.list.measurementPointTabs,
                (tab: ImeasurementPointListTab) => {
                    measurementPointsB = {
                        ...measurementPointsB,
                        ...keyBy(
                            tab.measurementPoints,
                            (mp: ImeasurementPoint) => mp.id
                        )
                    };
                }
            );
            return { ...state, ...measurementPointsB };
        }
        case types.DELETE_MEASUREMENT_POINT: {
            return omit(state, action.measurementPointID);
        }
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
};

function selectedMeasurementPointList(
    state: ImeasurementPointList = initialMeasurementPointList,
    action: any
): ImeasurementPointList {
    switch (action.type) {
        case types.SELECT_MEASUREMENT_POINT_LIST:
            return action.measurementPointList;
        case types.MANAGE_MEASUREMENT_POINT_SAVE_TO_LIST: {
            const selectedTab = state.measurementPointTabs.find(
                tab => tab.id === action.selectedTabID
            );
            if (selectedTab) {
                const newTabs = state.measurementPointTabs.map(tab => {
                    if (tab.id === selectedTab.id) {
                        return {
                            ...tab,
                            measurementPoints: {
                                ...tab.measurementPoints,
                                [action.measurementPoint.id]:
                                    action.measurementPoint
                            }
                        };
                    } else {
                        return tab;
                    }
                });
                return { ...state, measurementPointTabs: newTabs };
            } else {
                console.error(
                    'unable to update measurement point, missing tab' +
                        action.selectedTabID
                );
                return state;
            }
        }
        case types.MANAGE_MEASUREMENT_POINT_TAB_UPDATE: {
            const currentTab = state.measurementPointTabs.find(
                tab => tab.id === action.tab.id
            );
            if (currentTab) {
                const newTabs = state.measurementPointTabs.map(tab => {
                    if (tab.id === currentTab.id) {
                        return {
                            ...action.tab
                        };
                    } else {
                        return tab;
                    }
                });
                return { ...state, measurementPointTabs: newTabs };
            } else {
                console.error(
                    'unable to update measurement point list tab, missing tab' +
                        action.tab.id
                );
                return state;
            }
        }
        case types.REMOVE_MEASUREMENT_POINT_FROM_TAB:
            // omit the measurementPoint from the tab whose ID matches the selectedTabID
            const tabs: ImeasurementPointListTab[] = action.measurementPointList.measurementPointTabs.map(
                (tab: ImeasurementPointListTab) => {
                    if (tab.id === action.selectedTabID) {
                        return {
                            ...tab,
                            measurementPoints: omit(
                                tab.measurementPoints,
                                action.measurementPointID
                            )
                        };
                    }
                    return tab;
                }
            );

            return {
                ...action.measurementPointList,
                measurementPointTabs: tabs
            };
        case types.MANAGE_MEASUREMENT_POINT_LIST_UPDATE:
            return action.measurementPointList;
        case types.USER_LOGOUT_SUCCESS:
            return initialMeasurementPointList;
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.MANAGE_MEASUREMENT_POINT_LISTS_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}
const selectedTabID = (state = '', action: any): string => {
    switch (action.type) {
        case types.MANAGE_MEASUREMENT_POINT_SET_SELECTED_TAB:
            return action.selectedTabID;
        case types.USER_LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
};

const selectedMeasurementPoint = (
    state: ImeasurementPoint = initialMeasurementPoint,
    action: any
): ImeasurementPoint => {
    switch (action.type) {
        case types.MANAGE_MEASUREMENT_POINT_UPDATE:
            return action.measurementPoint;
        case types.USER_LOGOUT_SUCCESS:
            return initialMeasurementPoint;
        default:
            return state;
    }
};

const manageMeasurementPointLists = combineReducers<
    ImanageMeasurementPointListsReducer
>({
    measurementPointListsByID,
    measurementPointsByID,
    totalPages,
    selectedTabID,
    selectedMeasurementPointList,
    selectedMeasurementPoint,
    showEditMeasurementPointListModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_MEASUREMENT_POINT_LISTS'),
    showEditMeasurementPointModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_MEASUREMENT_POINT'),
    showEditMeasurementPointTabModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_MEASUREMENT_POINT_TAB'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(
            state,
            action,
            'MANAGE_MEASUREMENT_POINT_LISTS'
        ),
    showEditMeasurementPointListTestProceduresModal: (state, action) =>
        modalToggleWithName(
            state,
            action,
            'EDIT_MEASUREMENT_POINT_LIST_TEST_PROCEDURES'
        )
});

export default manageMeasurementPointLists;
