import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import CommonModal from '../common/CommonModal';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPurchasedTraining,
    loadCourses
} from '../../actions/trainingActions';
import { IinitialState } from '../../reducers';
import { Ioption, Iuser } from '../../models';
import { GFCourse } from '../../models';
import { AbstractControl, FormGenerator } from 'react-reactive-form';
import { useTranslation } from 'react-i18next';
import { FormUtil } from '../common/FormUtil';
import { AxiosRequestConfig } from 'axios';
import API from '../../constants/apiEndpoints';
import { msalFetch } from '../auth/Auth-Utils';
import { beginAjaxCall, endAjaxCall } from '../../actions/ajaxStatusActions';
import { _FAILED, _SUCCESS } from '../../actions/actionTypes';
import { constants } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';

interface Iprops {
    show: boolean;
    selectedUser: Iuser | undefined;
    onHide: () => void;
}

const EditUserTrainingForm = (props: Iprops) => {
    const { show, selectedUser, onHide } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { coursesByID, purchasedTraining } = useSelector(
        ({ training: { coursesByID, purchasedTraining } }: IinitialState) => ({
            coursesByID,
            purchasedTraining
        })
    );

    const [filteredCourses, setFilteredCourses] = useState<GFCourse[]>([]);
    const [form, setForm] = useState<AbstractControl>();

    useEffect(() => {
        if (selectedUser?.id) {
            dispatch(getPurchasedTraining(selectedUser.id));
            dispatch(loadCourses(selectedUser));
        }
    }, [selectedUser]);

    useEffect(() => {
        setFilteredCourses(
            Object.values(coursesByID).filter(c =>
                purchasedTraining.includes(c.id)
            )
        );
    }, [coursesByID, purchasedTraining]);

    const fieldConfig = useMemo(() => {
        const courseOptions: Ioption[] = Object.values(coursesByID)
            .filter(c => !purchasedTraining.includes(c.id))
            .map(c => ({ value: c.id, label: c.name }));

        const formControls = {
            courseID: {
                render: FormUtil.Select,
                meta: {
                    options: courseOptions,
                    label: t('user:availableCourses'),
                    colWidth: 12,
                    isMulti: false,
                    name: 'courseID',
                    isClearable: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                }
            },
            purchaseOrderNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: t('user:poNumber'),
                    colWidth: 6,
                    type: 'text',
                    name: 'purchaseOrderNumber'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                }
            }
        };

        return FormUtil.translateForm(
            {
                controls: { ...formControls }
            },
            t
        );
    }, [selectedUser, filteredCourses, purchasedTraining, coursesByID]);

    const onMount = (form: AbstractControl) => {
        setForm(form);
    };

    const purchaseTraining = async (
        event: React.MouseEvent<HTMLFormElement>
    ) => {
        event.preventDefault();

        if (form && selectedUser) {
            if (form.status === 'VALID') {
                const {
                    courseID: { value: courseID, label: courseName },
                    purchaseOrderNumber: PONumber
                } = form.value;
                const { id: userID } = selectedUser;

                const axiosOptions: AxiosRequestConfig = {
                    method: 'post',
                    data: { userID, PONumber, courseID }
                };

                const url = API.POST.user.saveTrainingPO;

                try {
                    dispatch(beginAjaxCall());
                    await msalFetch(url, axiosOptions);
                    dispatch(getPurchasedTraining(selectedUser.id));
                    form.reset();
                    dispatch(endAjaxCall());
                    toastr.success(
                        'Course Purchased',
                        `${courseName} purchased successfully`
                    );
                } catch (err) {
                    dispatch(endAjaxCall());
                    constants.handleError(err, 'Purchase Training');
                }
            } else toastr.error(t('user:validationError'));
        }
    };

    return (
        <CommonModal
            show={show}
            title={t('user:editUserTrainingModalTitle')}
            onHide={onHide}
            className=""
            wideModal={false}
            secondModal={true}
        >
            <div>
                <Col xs={12} style={{ marginBottom: '20px' }}>
                    <form onSubmit={purchaseTraining}>
                        <h4>{t('user:purchaseAdditionalCourses')}</h4>
                        <Row>
                            <FormGenerator
                                onMount={onMount}
                                fieldConfig={fieldConfig}
                            />
                        </Row>
                        <Button type="submit" disabled={false}>
                            {t('user:purchaseCourse')}
                        </Button>
                    </form>
                </Col>
                <Col xs={12}>
                    <h4>{t('user:ownedCourses')}</h4>
                    <ListGroup className="beacon-list-group">
                        {filteredCourses && filteredCourses.length ? (
                            filteredCourses.map((c, index) => (
                                <li
                                    className="list-group-item new-product-item"
                                    style={{
                                        backgroundColor:
                                            index % 2 === 0
                                                ? '#007bc30d'
                                                : '#fff'
                                    }}
                                >
                                    <h4>{c.name}</h4>
                                </li>
                            ))
                        ) : (
                            <p>{t('user:noCoursesPurchased')}</p>
                        )}
                    </ListGroup>
                </Col>
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={onHide}
                    >
                        {t('user:cancel')}
                    </Button>
                </Col>
            </div>
        </CommonModal>
    );
};

export default EditUserTrainingForm;
