import { connect } from 'react-redux';
import { IinitialState } from '../../reducers';
import { withRouter, RouteComponentProps } from 'react-router';

import Tutorial from './Tutorial';
import {
    addView,
    setIsTutorialOpen,
    toggleTutorial
} from '../../actions/tutorialActions';

import { constants } from '../../constants/constants';
import { WithTranslation, withTranslation } from 'react-i18next';

const mapStateToProps = (
    state: IinitialState,
    ownProps: RouteComponentProps<{}> & WithTranslation
) => {
    const { tutorial } = constants;
    let selectedSteps = tutorial.inventory.steps; // Just initially setting it up

    const { pathname } = ownProps.location;
    const modifiedPathname = pathname.slice(1); // Removes slash

    if (tutorial.hasOwnProperty(modifiedPathname)) {
        selectedSteps =
            tutorial[modifiedPathname as keyof typeof tutorial].steps;
    }
    return {
        viewedTours: state.tutorial.viewedTours,
        run: state.tutorial.run,
        steps: selectedSteps
    };
};

export default withRouter(
    withTranslation('nsTutorial')(
        connect(mapStateToProps, {
            setIsTutorialOpen,
            toggleTutorial,
            addView
        })(Tutorial)
    )
);
