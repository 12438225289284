import * as React from 'react';

interface Iprops {
    label: string;
    value: string;
    unitMeasurement: string;
    slim?: boolean;
}

const HourCard = ({ label, value, unitMeasurement, slim = false }: Iprops) => (
    <div className="hour-graph" data-slim={`${slim}`}>
        <div className="hour-graph-inner" data-size="small">
            <p>{label}</p>
            <p data-type="label">{unitMeasurement}</p>
        </div>
        <div className="hour-graph-inner" data-size="large">
            <p>{Number(value).toLocaleString('en-US')}</p>
            <p data-type="label">{unitMeasurement}</p>
        </div>
    </div>
);

export default HourCard;
