/*
 * EditJobForm
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    GroupProps,
    Validators
} from 'react-reactive-form';
import { debounce } from 'lodash';
import {
    myMedGasContractEnum,
    inspectionContractEnum
} from '../../models-enums';

import { FormUtil } from '../common/FormUtil';
import { IfacilityContractFormValues } from '../../modelsForms';
import { IfacilityContract, Ibrand } from '../../models';
import { constants } from '../../constants/constants';
import { initialJob } from '../../reducers/initialState';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { updateFacilityContract } from '../../actions/manageFacilityActions';
import { WithTranslation } from 'react-i18next';
const uuidv4 = require('uuid/v4');

interface Iprops {
    // reusable
    loading: boolean;
    selectedItem: IfacilityContract;
    show: boolean;
    handleSubmit: typeof updateFacilityContract;
    updateFormValue: (formValue: { [key: string]: any }) => void;
    setFormValues: (formValues: IfacilityContractFormValues) => void;
    formValues: IfacilityContractFormValues;
    toggleModal: () => void;
    colorButton: string;
    // other
    brands: { [key: string]: Ibrand };
    facilityID: string;
}

interface Istate {
    fieldConfig: FieldConfig;
}

class FacilityContractForm extends React.Component<
    Iprops & WithTranslation,
    Istate
> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValueDebounced: any;
    static defaultProps = {
        selectedItem: initialJob
    };
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.updateFormValueDebounced = debounce(
            this.props.updateFormValue,
            constants.formDebounceTime
        );
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }
    componentDidMount() {
        this.props.setFormValues(this.itemToFormValues());
        this.setState({
            fieldConfig: this.buildFieldConfig(this.itemToFormValues())
        });
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IfacilityContractFormValues => {
        const { selectedItem, t } = this.props;
        let myMedGasContract,
            inspectionContract,
            maintenanceContract,
            agslevContract,
            environmentalContract,
            boomContract,
            leakTestingContract,
            traceGasAnalysisContract,
            otherContract;

        if (selectedItem.myMedGasContract) {
            myMedGasContract = {
                value: selectedItem.myMedGasContract,
                label: t(myMedGasContractEnum[selectedItem.myMedGasContract])
            };
        }
        if (selectedItem.inspectionContract) {
            inspectionContract = {
                value: selectedItem.inspectionContract,
                label: t(
                    inspectionContractEnum[selectedItem.inspectionContract]
                )
            };
        }
        if (selectedItem.maintenanceContract) {
            maintenanceContract = {
                value: selectedItem.maintenanceContract,
                label: t(
                    inspectionContractEnum[selectedItem.maintenanceContract]
                )
            };
        }
        if (selectedItem.agslevContract) {
            agslevContract = {
                value: selectedItem.agslevContract,
                label: t(inspectionContractEnum[selectedItem.agslevContract])
            };
        }
        if (selectedItem.environmentalContract) {
            environmentalContract = {
                value: selectedItem.environmentalContract,
                label: t(
                    inspectionContractEnum[selectedItem.environmentalContract]
                )
            };
        }
        if (selectedItem.boomContract) {
            boomContract = {
                value: selectedItem.boomContract,
                label: t(inspectionContractEnum[selectedItem.boomContract])
            };
        }
        if (selectedItem.leakTestingContract) {
            leakTestingContract = {
                value: selectedItem.leakTestingContract,
                label: t(
                    inspectionContractEnum[selectedItem.leakTestingContract]
                )
            };
        }
        if (selectedItem.traceGasAnalysisContract) {
            traceGasAnalysisContract = {
                value: selectedItem.traceGasAnalysisContract,
                label: t(
                    inspectionContractEnum[
                        selectedItem.traceGasAnalysisContract
                    ]
                )
            };
        }
        if (selectedItem.otherContract) {
            otherContract = {
                value: selectedItem.otherContract,
                label: t(inspectionContractEnum[selectedItem.otherContract])
            };
        }
        return {
            ...selectedItem,
            myMedGasContract,
            inspectionContract,
            maintenanceContract,
            agslevContract,
            environmentalContract,
            boomContract,
            leakTestingContract,
            traceGasAnalysisContract,
            otherContract
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedItem
     */
    formValuesToItem = (): IfacilityContract => {
        return {
            ...this.props.selectedItem,
            ...FormUtil.getValues(this.formGroup.value),
            facilityID: this.props.facilityID
        };
    };

    buildFieldConfig = (
        defaultValues: IfacilityContractFormValues = this.props.formValues
    ) => {
        const disabled = false;

        // Field config to configure form
        const fieldConfigControls = {
            myMedGasContract: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        myMedGasContractEnum
                    ),
                    label: 'facilityContractForm.myMedGasContract',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'myMedGasContract',
                    required: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.myMedGasContract,
                    disabled
                }
            },
            myMedGasContractStart: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.myMedGasContractStart',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    name: 'myMedGasContractStart',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.myMedGasContractStart,
                    disabled
                }
            },
            myMedGasContractEnd: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.myMedGasContractEnd',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    alignRight: true,
                    name: 'myMedGasContractEnd',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.myMedGasContractEnd,
                    disabled
                }
            },
            myMedGasContractComments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityContractForm.myMedGasContractComments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 2,
                    style: { marginBottom: '25px' },
                    name: 'myMedGasContractComments',
                    initialContent: defaultValues.myMedGasContractComments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.myMedGasContractComments,
                    disabled
                }
            },
            inspectionContract: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        inspectionContractEnum
                    ),
                    label: 'facilityContractForm.inspectionContract',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'inspectionContract',
                    required: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.inspectionContract,
                    disabled
                }
            },
            inspectionContractStart: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.inspectionContractStart',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    name: 'inspectionContractStart',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.inspectionContractStart,
                    disabled
                }
            },
            inspectionContractEnd: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.inspectionContractEnd',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    alignRight: true,
                    name: 'inspectionContractEnd',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.inspectionContractEnd,
                    disabled
                }
            },
            inspectionContractComments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityContractForm.inspectionContractComments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 2,
                    style: { marginBottom: '25px' },
                    name: 'inspectionContractComments',
                    initialContent: defaultValues.inspectionContractComments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.inspectionContractComments,
                    disabled
                }
            },
            maintenanceContract: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        inspectionContractEnum
                    ),
                    label: 'facilityContractForm.maintenanceContract',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'maintenanceContract',
                    required: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.maintenanceContract,
                    disabled
                }
            },
            maintenanceContractStart: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.maintenanceContractStart',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    name: 'maintenanceContractStart',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.maintenanceContractStart,
                    disabled
                }
            },
            maintenanceContractEnd: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.maintenanceContractEnd',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    alignRight: true,
                    name: 'maintenanceContractEnd',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.maintenanceContractEnd,
                    disabled
                }
            },
            maintenanceContractComments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityContractForm.maintenanceContractComments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 2,
                    style: { marginBottom: '25px' },
                    name: 'maintenanceContractComments',
                    initialContent: defaultValues.maintenanceContractComments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.maintenanceContractComments,
                    disabled
                }
            },
            agslevContract: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        inspectionContractEnum
                    ),
                    label: 'facilityContractForm.AGSLEVContract',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'AGSLEVContract',
                    required: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.agslevContract,
                    disabled
                }
            },
            agslevContractStart: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.AGSLEVContractStart',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    name: 'AGSLEVContractStart',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.agslevContractStart,
                    disabled
                }
            },
            agslevContractEnd: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.AGSLEVContractEnd',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    alignRight: true,
                    name: 'AGSLEVContractEnd',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.agslevContractEnd,
                    disabled
                }
            },
            agslevContractComments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityContractForm.AGSLEVContractComments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 2,
                    name: 'AGSLEVContractComments',
                    style: { marginBottom: '25px' },
                    initialContent: defaultValues.agslevContractComments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.agslevContractComments,
                    disabled
                }
            },
            environmentalContract: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        inspectionContractEnum
                    ),
                    label: 'facilityContractForm.environmentalContract',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'environmentalContract',
                    required: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.environmentalContract,
                    disabled
                }
            },
            environmentalContractStart: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.environmentalContractStart',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    name: 'environmentalContractStart',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.environmentalContractStart,
                    disabled
                }
            },
            environmentalContractEnd: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.environmentalContractEnd',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    alignRight: true,
                    name: 'environmentalContractEnd',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.environmentalContractEnd,
                    disabled
                }
            },
            environmentalContractComments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityContractForm.environmentalContractComments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 2,
                    style: { marginBottom: '25px' },
                    name: 'environmentalContractComments',
                    initialContent: defaultValues.environmentalContractComments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.environmentalContractComments,
                    disabled
                }
            },
            boomContract: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        inspectionContractEnum
                    ),
                    label: 'facilityContractForm.boomContract',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'boomContract',
                    required: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.boomContract,
                    disabled
                }
            },
            boomContractStart: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.boomContractStart',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    name: 'boomContractStart',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.boomContractStart,
                    disabled
                }
            },
            boomContractEnd: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.boomContractEnd',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    alignRight: true,
                    name: 'boomContractEnd',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.boomContractEnd,
                    disabled
                }
            },
            boomContractComments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityContractForm.boomContractComments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 2,
                    style: { marginBottom: '25px' },
                    name: 'boomContractComments',
                    initialContent: defaultValues.boomContractComments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.boomContractComments,
                    disabled
                }
            },
            leakTestingContract: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        inspectionContractEnum
                    ),
                    label: 'facilityContractForm.leakTestingContract',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'leakTestingContract',
                    required: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.leakTestingContract,
                    disabled
                }
            },
            leakTestingContractStart: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.leakTestingContractStart',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    name: 'leakTestingContractStart',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.leakTestingContractStart,
                    disabled
                }
            },
            leakTestingContractEnd: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.leakTestingContractEnd',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    alignRight: true,
                    name: 'leakTestingContractEnd',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.leakTestingContractEnd,
                    disabled
                }
            },
            leakTestingContractComments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityContractForm.leakTestingContractComments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 2,
                    style: { marginBottom: '25px' },
                    name: 'leakTestingContractComments',
                    initialContent: defaultValues.leakTestingContractComments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.leakTestingContractComments,
                    disabled
                }
            },
            traceGasAnalysisContract: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        inspectionContractEnum
                    ),
                    label: 'facilityContractForm.traceGasAnalysisContract',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'traceGasAnalysisContract',
                    required: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.traceGasAnalysisContract,
                    disabled
                }
            },
            traceGasAnalysisContractStart: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.traceGasAnalysisContractStart',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    name: 'traceGasAnalysisContractStart',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.traceGasAnalysisContractStart,
                    disabled
                }
            },
            traceGasAnalysisContractEnd: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.traceGasAnalysisContractEnd',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    alignRight: true,
                    name: 'traceGasAnalysisContractEnd',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.traceGasAnalysisContractEnd,
                    disabled
                }
            },
            traceGasAnalysisContractComments: {
                render: FormUtil.TextInput,
                meta: {
                    label:
                        'facilityContractForm.traceGasAnalysisContractComments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 2,
                    style: { marginBottom: '25px' },
                    name: 'traceGasAnalysisContractComments',
                    initialContent:
                        defaultValues.traceGasAnalysisContractComments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.traceGasAnalysisContractComments,
                    disabled
                }
            },
            otherContract: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        inspectionContractEnum
                    ),
                    label: 'facilityContractForm.otherContract',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    name: 'otherContract',
                    required: false,
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.otherContract,
                    disabled
                }
            },
            otherContractStart: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.otherContractStart',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    name: 'otherContractStart',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.otherContractStart,
                    disabled
                }
            },
            otherContractEnd: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'facilityContractForm.otherContractEnd',
                    colWidth: 6,
                    showTime: false,
                    required: false,
                    alignRight: true,
                    name: 'otherContractEnd',
                    placeholder: 'dd-mmm-yy'
                },
                options: {
                    validators: [FormUtil.validators.isValidMoment]
                },
                formState: {
                    value: defaultValues.otherContractEnd,
                    disabled
                }
            },
            otherContractComments: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'facilityContractForm.otherContractComments',
                    colWidth: 12,
                    componentClass: 'textarea',
                    rows: 2,
                    style: { marginBottom: '25px' },
                    name: 'otherContractComments',
                    initialContent: defaultValues.otherContractComments,
                    required: false
                },
                options: {
                    validators: [Validators.maxLength(250)]
                },
                formState: {
                    value: defaultValues.otherContractComments,
                    disabled
                }
            }
        } as { [key: string]: GroupProps };
        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                if (moment.isMoment(value)) {
                    value = value.format(constants.momentSQLFormat);
                }
                this.updateFormValueDebounced({ [key]: value });
                break;
        }
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        console.info(this.formGroup.value);

        if (this.props.selectedItem.id.length) {
            this.props.handleSubmit(this.formValuesToItem());
        } else {
            this.props.handleSubmit({
                ...this.formValuesToItem(),
                id: uuidv4()
            });
        }
        this.props.toggleModal();
    };
    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;

        return (
            <form onSubmit={this.handleSubmit} className={formClassName}>
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={() => {
                            this.props.toggleModal();
                            this.props.setFormValues({});
                        }}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        bsStyle={this.props.colorButton}
                        type="submit"
                        disabled={this.props.loading}
                    >
                        {t('save')}
                    </Button>
                </Col>
            </form>
        );
    }
}
export default FacilityContractForm;
