/*
 * The HeaderMenu only displays if we have an authenticated user.
 * It is responsible for displaying the welcome message and the dropdown menu for logged in users
 * spinner is from : http://tobiasahlin.com/spinkit/
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import {
    getFacilitiesByCountry,
    searchFacilitiesForAsyncSelect,
    toggleEditFacilityModal
} from '../../actions/commonActions';
import {
    getSecurityUsers,
    saveUser,
    toggleSecurityFunctionsModal,
    updateSelectedUser
} from '../../actions/manageUserActions';

import EditUserForm from './EditUserForm';
import { FormUtil } from '../common/FormUtil';
import { connect } from 'react-redux';

import { selectSecurityUsers } from '../../reducers/manageUserReducer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { manageUserQueryParamsEnum } from './ManageUserChild';
import { IfacilityWithoutBuildings, Ihistory } from '../../models';
import { constants } from '../../constants/constants';
import { hasQuery, removeQuery } from '../common/OtherUtils';
import { selectFacilityOptionsWithAddress } from '../../reducers/facilitiesReducer';
import { WithCommonModal } from '../common/WithCommonModal';
import { initialUser } from '../../reducers/initialState';

interface Iprops {
    colorButton: string;
    queryParams: typeof manageUserQueryParamsEnum;
    history: Ihistory;
    queryKey: string;
    facilitiesByID: { [key: string]: IfacilityWithoutBuildings };
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedUser =
        state.manageUser.usersByID[ownProps.queryParams.selectedUserID] ||
        initialUser;
    // define the closeModal function here in mapState so that we can pass it to both the main component and
    // to the onHide which is a WithCommonModal HOC prop
    // yes it is unusual to pass a function in mapState, but it is ok since it does not need dispatch
    const closeModal = () => {
        removeQuery(manageUserQueryParamsEnum.selectedUserID, ownProps.history);
    };

    return {
        user: state.user,
        loading: selectIsLoading(state),
        // these customers are filtered by country; request for it is made when modal is opened
        securityOptions: FormUtil.convertToOptions(constants.securityFunctions),
        salesManagerOptions: FormUtil.convertToOptions(
            selectSecurityUsers(state)
        ),
        show: hasQuery(
            manageUserQueryParamsEnum.selectedUserID,
            ownProps.history
        ),
        showEditFacilityModal: state.showEditFacilityModal,
        selectedUser,
        title: ownProps.t('editUserModalTitle'),
        className: 'user-edit',
        facilitiesByID: ownProps.facilitiesByID,
        facilityOptionsWithAddress: selectFacilityOptionsWithAddress(state),
        closeModal,
        onHide: closeModal,
        productInfo: state.productInfo
    };
};

export default withTranslation('user')(
    connect(mapStateToProps, {
        getFacilitiesByCountry,
        toggleEditFacilityModal,
        toggleSecurityFunctionsModal,
        getSecurityUsers,
        onChange: updateSelectedUser,
        onSubmit: saveUser,
        searchFacilitiesForAsyncSelect
    })(WithCommonModal(EditUserForm))
);
