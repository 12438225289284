import { combineReducers } from 'redux';
import { ImanageProductReducer, Iproduct } from '../models';
import * as types from '../actions/actionTypes';
import initialState, { initialProduct } from './initialState';
import {
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import { keyBy, map, pickBy } from 'lodash';

const cleanAndFilterProductObject = (product: Iproduct): Iproduct => {
    return {
        ...initialProduct,
        ...(pickBy(product, (property, key) => property !== null) as Iproduct)
    };
};
function productsByIDReducer(
    state: { [key: string]: Iproduct } = initialState.manageProduct
        .productsByID,
    action: any
): { [key: string]: Iproduct } {
    switch (action.type) {
        case types.GET_MANAGE_PRODUCT_SUCCESS: {
            const newProducts = keyBy(
                map(action.products, (product: Iproduct) => {
                    return cleanAndFilterProductObject(product);
                }),
                'id'
            );
            return newProducts;
        }
        case types.PRODUCT_UPDATE_SUCCESS:
            return {
                ...state,
                [action.product.id]: cleanAndFilterProductObject(action.product)
            };
        case types.PRODUCT_ADD_SUCCESS:
            return {
                ...state,
                [action.product.id]: cleanAndFilterProductObject(action.product)
            };
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageInventory.productsByID;
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.PRODUCT_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

const ManageProduct = combineReducers<ImanageProductReducer>({
    productsByID: productsByIDReducer,
    showEditProductModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_PRODUCT'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_PRODUCT'),
    totalPages
});

export default ManageProduct;
