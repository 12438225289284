import * as React from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateFromHTML } from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';

interface Iprops {
    initialContent: any;
    onChange: any;
    readOnly: boolean;
    toolbarConfig?: object;
    charLimit?: number;
}

interface Istate {
    editorState: EditorState;
    characterLimit: number;
}

const defaultCharacterLimit: number = 1000;
class RichTextEditor extends React.Component<Iprops, Istate> {
    constructor(props: Iprops) {
        super(props);

        if (
            this.props.initialContent !== 'undefined' &&
            this.props.initialContent !== null &&
            this.props.initialContent !== ''
        ) {
            this.setContent(this.props.initialContent, this.props.charLimit);
        } else {
            this.state = {
                editorState: EditorState.createEmpty(),
                characterLimit: defaultCharacterLimit
            };
        }
    }

    componentDidUpdate(prevProps: Iprops) {
        if (
            this.props.charLimit &&
            prevProps.charLimit !== this.props.charLimit
        ) {
            this.state = {
                ...this.state,
                characterLimit: this.props.charLimit
            };
        }
    }

    customInlineFn = (element: any, { Style }: any) => {
        if (element.tagName === 'SPAN') {
            let styles = '';
            if (element.style.cssText.includes('font-size')) {
                const size = element.style.fontSize.substring(
                    0,
                    element.style.fontSize.length - 2
                );
                styles += 'fontsize-' + size;
            }

            return Style(styles);
        }
    };

    setContent = (initialContent: any, maxCharacters: number | undefined) => {
        const content = stateFromHTML(initialContent, {
            customInlineFn: this.customInlineFn
        });
        const newEditState = EditorState.createWithContent(content);

        this.state = {
            ...this.state,
            editorState: newEditState,
            characterLimit: maxCharacters
                ? maxCharacters
                : defaultCharacterLimit
        };
    };

    onChange = (editorState: EditorState) => {
        this.setState({ editorState });

        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const plainText = rawContentState.blocks
            .map(block => (!block.text.trim() && '\n') || block.text)
            .join('\n');
        if (plainText.length <= this.state.characterLimit) {
            const html = draftToHtml(rawContentState);
            this.props.onChange(html);
        } else {
            const newEditorState = EditorState.undo(this.state.editorState);
            this.state = { ...this.state, editorState: newEditorState };
        }
    };

    render() {
        return (
            <Editor
                editorState={this.state.editorState}
                onEditorStateChange={this.onChange}
                toolbar={
                    this.props.toolbarConfig
                        ? this.props.toolbarConfig
                        : {
                              options: ['link', 'inline', 'list'],
                              inline: {
                                  options: ['bold', 'italic', 'underline']
                              },
                              list: { options: ['unordered', 'ordered'] }
                          }
                }
                readOnly={this.props.readOnly}
            />
        );
    }
}

export default RichTextEditor;
