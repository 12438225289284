/*
 * Manage Inventory Form
 * Edit inventory items
 */

import * as React from 'react';

import {
    AbstractControl,
    FieldConfig,
    FormGenerator,
    FormGroup
} from 'react-reactive-form';
import { Button, Col, ListGroup, Row, Well } from 'react-bootstrap';
import {
    Ioption,
    Iproduct,
    IproductInfo,
    IproductQueueObject,
    IsearchNewProductInstallBatchMode,
    Iuser
} from '../../models';
import { debounce, filter, isEmpty, map } from 'lodash';
import {
    getProducts,
    resetNewProducts,
    setInstallBatchMode,
    setProductSearchFormValues,
    toggleEditProductModal,
    updateProductSearchFormValues
} from '../../actions/manageInventoryActions';

import { FormUtil } from '../common/FormUtil';
import { IsearchProductFormValues, ProductAttributes } from '../../modelsForms';
import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';
import { getMergeableProducts } from '../../actions/manageProductQueueActions';

interface Iprops {
    toggleModal: () => void;
    toggleEditProductModal: typeof toggleEditProductModal;
    selectedItem: Iproduct;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    productInfo: IproductInfo;
    formValues: Partial<IsearchProductFormValues>;
    getProducts: typeof getProducts;
    getMergeableProducts: typeof getMergeableProducts;
    newProducts: (Iproduct | IproductQueueObject)[];
    resetNewProducts: typeof resetNewProducts;
    handleProductSelect?: (
        product: Iproduct,
        installBatchMode: IsearchNewProductInstallBatchMode
    ) => void;
    handleMergeProduct: (product: Iproduct) => void;
    isApproved?: boolean;
    setProductSearchFormValues: typeof setProductSearchFormValues;
    updateProductSearchFormValues: typeof updateProductSearchFormValues;
    enableMergeProductMode: boolean;
    disableCreateNew: boolean;
    user: Iuser;
    isProductQueue?: boolean;
    installBatchMode: IsearchNewProductInstallBatchMode;
    setInstallBatchMode: typeof setInstallBatchMode;
}

interface Istate {
    fieldConfig: FieldConfig;
}

class SearchNewProductsForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private updateFormValuesDebounced: (formValues: {
        [key: string]: any;
    }) => void;
    private handleSubmitDebounced: () => void;

    constructor(props: Iprops) {
        super(props);
        this.updateFormValuesDebounced = debounce(
            this.props.updateProductSearchFormValues,
            constants.formDebounceTime
        );
        this.handleSubmitDebounced = debounce(
            this.handleSubmit,
            constants.tableSearchDebounceTime
        );
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        if (this.props.enableMergeProductMode) {
            const selectedFormValues: Partial<IsearchProductFormValues> = this.buildFormValuesFromSelected();
            const productAttributes = this.getProductAttributes(
                selectedFormValues
            );

            this.setState({
                fieldConfig: this.buildFieldConfig(selectedFormValues)
            });
            this.props.setProductSearchFormValues(selectedFormValues);

            const selectedProductStandard = this.props.productInfo.standardOptions
                .filter((value: Ioption) => {
                    return this.props.user.userStandards.includes(value.value);
                })
                .map((value: Ioption) => value.value);

            if (this.props.isProductQueue)
                this.props.getMergeableProducts({
                    page: 1,
                    search: this.formGroup.value.search || '',
                    mainCategoryID: productAttributes.mainCategoryID || '',
                    subCategoryID: productAttributes.subcategoryID || '',
                    brandID: productAttributes.brandID || '',
                    origin: productAttributes.origin || '',
                    isFinalProduct: this.formGroup.value.isFinalProduct,
                    standards: selectedProductStandard || []
                });
            else
                this.props.getProducts(
                    1,
                    '',
                    true,
                    productAttributes,
                    selectedProductStandard,
                    this.props.isApproved
                );
        } else {
            this.props.resetNewProducts();
            this.props.setProductSearchFormValues({});
        }
    }
    componentWillUnmount() {
        this.props.setProductSearchFormValues({ isFinalProduct: true });
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.setInstallBatchMode({
            installBaseIDs: [],
            isBatchMode: false
        });
    }
    componentDidUpdate(prevProps: Iprops) {
        if (
            this.props.formValues.mainCategoryID !==
            prevProps.formValues.mainCategoryID
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }
    buildFormValuesFromSelected = (): Partial<IsearchProductFormValues> => {
        if (this.props.selectedItem.id.length) {
            const { selectedItem, productInfo } = this.props;

            const { subcategoryID } = selectedItem;
            const subcategory = productInfo.subcategories[subcategoryID];
            const mainCategory =
                productInfo.mainCategories[subcategory.mainCategoryID];
            const subcategoryOption =
                FormUtil.convertToSingleOption(subcategory) || undefined;
            const mainCategoryOption =
                FormUtil.convertToSingleOption(mainCategory) || undefined;
            return {
                subcategoryID: subcategoryOption,
                mainCategoryID: mainCategoryOption,
                search: ''
            };
        } else {
            return {
                subcategoryID: undefined,
                mainCategoryID: undefined,
                search: undefined,
                isFinalProduct: true
            };
        }
    };
    buildFieldConfig = (
        defaultFormValues: Partial<IsearchProductFormValues> = this.props
            .formValues
    ) => {
        const disabled = false;
        const { selectedItem } = this.props;
        const {
            subcategories,
            mainCategoryOptions,
            brandOptions,
            brands,
            standardOptions
        } = this.props.productInfo;
        const {
            search,
            brandID,
            isFinalProduct,
            standardID
        } = defaultFormValues;

        let filteredSubCategoryOptions: Ioption[] = [];
        let selectedSubCategory = null;
        let selectedBrand = null;
        let selectedProductStandard = null;
        let subcategoryID: string | undefined = undefined;
        let mainCategoryID: string | undefined = undefined;

        // If selectedItem is set, that means we are updating an existing InstallBase's product, main category will be locked.
        if (selectedItem.id.length > 0) {
            subcategoryID = selectedItem.subcategoryID;
            mainCategoryID = subcategories[subcategoryID]?.mainCategoryID;
        } else {
            subcategoryID = defaultFormValues?.subcategoryID?.value;
            mainCategoryID = defaultFormValues?.mainCategoryID?.value;
        }

        if (mainCategoryID) {
            filteredSubCategoryOptions = FormUtil.convertToOptions(
                filter(
                    subcategories,
                    sub => sub.mainCategoryID === mainCategoryID
                )
            );
            if (subcategoryID) {
                const subcategory = subcategories[subcategoryID];
                if (subcategory.mainCategoryID === mainCategoryID) {
                    selectedSubCategory = subcategoryID;
                }
            }
        } else if (this.props.formValues.mainCategoryID) {
            mainCategoryID = this.props.formValues.mainCategoryID.value;
        }

        if (brandID) {
            const brand = brands[brandID.value];
            selectedBrand = FormUtil.convertToSingleOption(brand);
        }
        if (standardID) {
            const ProductStandard = standardOptions.filter(
                Obj => Obj.value === standardID.value
            );
            selectedProductStandard = FormUtil.convertToSingleOption(
                ProductStandard
            );
        } else {
            selectedProductStandard = standardOptions.filter(
                (value: Ioption) => {
                    return this.props.user.userStandards.includes(value.value);
                }
            );
        }

        const selectedMainCategoryOption = mainCategoryOptions.find(
            category => category.value === mainCategoryID
        );

        const fieldConfigControls = {
            mainCategoryID: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: mainCategoryOptions,
                    label: 'common:mainCategory',
                    colWidth: 6,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'product-group'
                },
                formState: {
                    value: selectedMainCategoryOption || undefined,
                    disabled:
                        this.props.enableMergeProductMode ||
                        this.props.installBatchMode?.isBatchMode
                }
            },
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'search',
                    colWidth: 6,
                    type: 'input',
                    placeholder: 'searchByName',
                    name: 'product-search'
                },
                formState: { value: search, disabled }
            },
            subcategoryID: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: filteredSubCategoryOptions,
                    label: 'common:subCategory',
                    colWidth: 6,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'subcategory',
                    isClearable: true
                },
                formState: {
                    value: selectedSubCategory,
                    disabled: false
                }
            },
            brandID: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: brandOptions,
                    label: 'common:manufacturer',
                    colWidth: 6,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'manufacturer',
                    isClearable: true
                },
                formState: {
                    value: selectedBrand,
                    disabled: false
                }
            },
            standardID: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: standardOptions,
                    label: 'productForm.standards',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: true,
                    name: 'productStandard',
                    isClearable: true
                },
                formState: {
                    value: selectedProductStandard,
                    disabled
                }
            },
            isFinalProduct: {
                render: FormUtil.Toggle,
                meta: {
                    label: 'productForm.EndProduct',
                    colWidth: 6,
                    style: { marginTop: '8px' }
                },
                formState: { value: isFinalProduct || true, disabled }
            }
        };
        return FormUtil.translateForm(
            {
                controls: { ...fieldConfigControls }
            },
            this.props.t
        );
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'mainCategoryID':
                this.updateFormValuesDebounced({
                    [key]: value,
                    subcategoryID: null
                });
                this.handleSubmitDebounced();
                break;
            default:
                this.updateFormValuesDebounced({ [key]: value });
                this.handleSubmitDebounced();
                break;
        }
    };
    handleSubmit = () => {
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        const productAttributes = this.getProductAttributes(
            this.formGroup.value
        );

        const standardID = this.formGroup.value.standardID;
        let selectedStandards = [];
        if (standardID && standardID.length > 0) {
            selectedStandards = standardID.map(
                (standard: Ioption) => standard.value
            );
        }

        if (this.props.isProductQueue)
            this.props.getMergeableProducts({
                page: 1,
                search: this.formGroup.value.search || '',
                mainCategoryID: productAttributes.mainCategoryID || '',
                subCategoryID: productAttributes.subcategoryID || '',
                brandID: productAttributes.brandID || '',
                origin: productAttributes.origin || '',
                isFinalProduct: this.formGroup.value.isFinalProduct,
                standards: selectedStandards || []
            });
        else
            this.props.getProducts(
                1,
                this.formGroup.value.search,
                this.formGroup.value.isFinalProduct,
                productAttributes,
                selectedStandards,
                this.props.isApproved
            );
    };

    getValue = (object?: Ioption | string): string => {
        return typeof object === 'string' ? object : object?.value ?? '';
    };

    getProductAttributes = (
        selectedFormValues: Partial<IsearchProductFormValues>
    ): ProductAttributes => {
        const keys: (keyof ProductAttributes)[] = [
            'mainCategoryID',
            'subcategoryID',
            'brandID',
            'origin',
            'powerID',
            'productTypeID',
            'systemSizeID'
        ];

        return keys.reduce((attributes: ProductAttributes, key) => {
            attributes[key as keyof ProductAttributes] = this.getValue(
                selectedFormValues[key]
            );
            return attributes;
        }, {} as ProductAttributes);
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const {
            t,
            colorButton,
            loading,
            newProducts,
            selectedItem,
            enableMergeProductMode,
            disableCreateNew,
            handleProductSelect,
            handleMergeProduct,
            toggleModal
        } = this.props;

        const formClassName = `clearfix beacon-form search-products-form ${colorButton}`;

        let searchActive = false;
        if (
            !loading &&
            this.formGroup &&
            this.formGroup.value &&
            (this.formGroup.value.search || this.formGroup.value.mainCategoryID)
        ) {
            searchActive = true;
        }

        const ProductListItem = ({
            product,
            sItem
        }: {
            product: Iproduct;
            sItem?: Iproduct;
        }) => {
            const className =
                sItem && sItem.id === product.id
                    ? 'list-group-item new-product-item selected'
                    : 'list-group-item new-product-item';
            return (
                <li
                    className={className}
                    onClick={() => {
                        if (
                            enableMergeProductMode === false &&
                            handleProductSelect
                        ) {
                            handleProductSelect(
                                product,
                                this.props.installBatchMode
                            );
                        } else {
                            handleMergeProduct(product);
                        }
                    }}
                >
                    <h4> {product.name} </h4>
                </li>
            );
        };

        return (
            <div>
                <p
                    style={{
                        marginLeft: '15px',
                        marginRight: '15px',
                        lineHeight: '1.5rem'
                    }}
                >
                    {t('searchNewProductInstructions')}
                </p>

                <form
                    onSubmit={(e: React.MouseEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        this.handleSubmit();
                    }}
                    className={formClassName}
                    style={{ display: 'block' }}
                >
                    <Row>
                        <Col xs={12}>
                            <FormGenerator
                                onMount={this.setForm}
                                fieldConfig={this.state.fieldConfig}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <ListGroup className="beacon-list-group">
                                {map(newProducts, (product, index) => (
                                    <ProductListItem
                                        product={
                                            'name' in product
                                                ? product
                                                : product.product
                                        }
                                        key={product.id}
                                        sItem={selectedItem}
                                    />
                                ))}
                            </ListGroup>
                            {isEmpty(newProducts) && searchActive && (
                                <Col xs={12}>
                                    <Well className="text-center">
                                        {t('noProductsFound')}
                                    </Well>
                                </Col>
                            )}
                        </Col>
                    </Row>
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={toggleModal}
                        >
                            {t('common:cancel')}
                        </Button>
                        {!disableCreateNew && enableMergeProductMode === false && (
                            <Button
                                bsStyle="link"
                                type="button"
                                disabled={loading}
                                className="right-side"
                                onClick={() => {
                                    this.props.toggleEditProductModal();
                                }}
                            >
                                {t('addNewProductButton')}
                            </Button>
                        )}

                        <button type="submit" style={{ display: 'none' }} />
                    </Col>
                </form>
            </div>
        );
    }
}
export default SearchNewProductsForm;
