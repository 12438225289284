import * as localForage from 'localforage';

import {
    AnyAction,
    Middleware,
    Reducer,
    Store,
    StoreEnhancer,
    applyMiddleware,
    compose,
    createStore
} from 'redux';
import { AxiosError, AxiosRequestConfig } from 'axios';
import {
    PersistConfig,
    Transform,
    createMigrate,
    createTransform,
    persistReducer,
    persistStore
} from 'redux-persist';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import TrackJSLogger from './TrackJSLogger';
import { constants } from '../constants/constants';
import { createOffline } from '@redux-offline/redux-offline';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import initialState from '../reducers/initialState';
import { migrations } from './migrations';
import { msalFetch } from '../components/auth/Auth-Utils';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index';
import rootReducer, { IinitialState } from '../reducers';

/*
 * handle persisisting date objects
 */
const replacer = (key: string, value: any) =>
    value instanceof Date ? value.toISOString() : value;

const reviver = (key: string, value: any) =>
    // typeof value === 'string' &&
    // value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
    //   ? new Date(value)
    //   : value;
    value; // return the string because we do not want to create a date object here because it adds the timezone offset.

export const encode = (toDeshydrate: Transform<IinitialState, string>) =>
    JSON.stringify(toDeshydrate, replacer);

export const decode = (toRehydrate: string) => JSON.parse(toRehydrate, reviver);

const effect = (
    {
        axiosOptions,
        message
    }: { axiosOptions: AxiosRequestConfig; message: string },
    action: any
) => {
    const url = axiosOptions.url || ''; // if this is an empty string, it will generate a 404 response and be discarded.

    return msalFetch(url, axiosOptions).catch(err => {
        console.error(message, err);
        constants.handleError(err, message);
        throw err;
    });
};

// if discard returns true, then it will try again
const discard = (error: AxiosError, action: any, retries: number) => {
    const { request, response } = error;
    if (!request) {
        throw error;
    } // There was an error creating the request
    if (!response) {
        return false;
    } // There was no response
    return response.status >= 400 && response.status <= 500; // if it is a 400 error then discard, TODO temporarily set this to include 500 exactly because I am not sure how to handle this better...
    //  if retryScheduled true and online true, we chould show a banner with a button to discard the request.
};

const persistConfig: PersistConfig<IinitialState> = {
    key: 'state-core-care-web',
    throttle: 100,
    // throttle: 5000,
    storage: localForage,
    version: parseInt(
        (process.env.REACT_APP_VERSION || '0.0.0').replace(/\./g, ''),
        10
    ),
    migrate: createMigrate(migrations, { debug: false }),
    blacklist: [
        'showEditProfileModal',
        'ajaxCallsInProgress',
        'showSideMenu',
        'showEditFacilityModal',
        'showFacilityContactModal',
        'showFacilityContractModal',
        'showLeadsModal',
        'showSecurityFunctionsModal',
        'toastr',
        'showAlertModal'
    ],
    stateReconciler: hardSet,
    transforms: [createTransform(encode, decode)]
};
// see https://github.com/diegoddox/react-redux-toastr/issues/249 about blacklisting toastr

const offlineEnhancer = createOffline({
    ...offlineConfig,
    persist: false,
    effect,
    discard
});

const persistedReducer: Reducer<IinitialState> = persistReducer(
    persistConfig,
    offlineEnhancer.enhanceReducer(rootReducer)
);

export default function configureStore() {
    // if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-constant-condition
    if (false) {
        const composeEnhancers = require('redux-devtools-extension').composeWithDevTools(
            {
                autoPause: true,
                // shouldStartLocked: true,
                latency: 3000,
                // shouldRecordChanges: false,
                maxAge: 30,
                shouldHotReload: false,
                trace: true,
                stateSanitizer: (state: IinitialState) => ({
                    ...state,
                    manageUser: '<<LONG_BLOB>>',
                    manageInventory: '<<LONG_BLOB>>',
                    facilities: '<<LONG_BLOB>>',
                    measurementPointResults: '<<LONG_BLOB>>',
                    manageFacility: '<<LONG_BLOB>>',
                    productInfo: '<<LONG_BLOB>>',
                    manageTraining: '<<LONG_BLOB>>',
                    manageJob: '<<LONG_BLOB>>',
                    manageAlert: '<<LONG_BLOB>>',
                    manageCode: '<<LONG_BLOB>>',
                    manageUserQueue: '<<LONG_BLOB>>',
                    training: '<<LONG_BLOB>>',
                    manageUserAlert: '<<LONG_BLOB>>',
                    manageBrand: '<<LONG_BLOB>>',
                    dashboardAnalytics: '<<LONG_BLOB>>',
                    manageDocument: '<<LONG_BLOB>>',
                    manageDocumentType: '<<LONG_BLOB>>',
                    manageJobComment: '<<LONG_BLOB>>',
                    manageLocation: '<<LONG_BLOB>>'
                })
            }
        );
        const store: Store<IinitialState, AnyAction> = createStore(
            persistedReducer,
            initialState,
            composeEnhancers(
                offlineEnhancer.enhanceStore,
                applyMiddleware(
                    thunk as ThunkMiddleware<IinitialState, AnyAction>,
                    require('redux-immutable-state-invariant').default(),
                    offlineEnhancer.middleware as Middleware<
                        any,
                        IinitialState,
                        any
                    >
                )
            )
        );
        const persistor = persistStore(store);
        return { persistor, store };
    } else {
        const store: Store<IinitialState, AnyAction> = createStore(
            persistedReducer,
            initialState,
            compose(
                offlineEnhancer.enhanceStore as StoreEnhancer,
                applyMiddleware(
                    thunk as ThunkMiddleware<IinitialState, AnyAction>,
                    offlineEnhancer.middleware as Middleware<
                        any,
                        IinitialState,
                        any
                    >,
                    TrackJSLogger
                )
            )
        );
        const persistor = persistStore(store);
        return { persistor, store };
    }
}
