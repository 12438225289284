import * as types from '../actions/actionTypes';

import {
    Ilead,
    IleadInstallBase,
    ImanageLeadsReducer,
    IleadActivity,
    IleadUser,
    IleadUserPopulated
} from '../models';
import {
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import {
    filter,
    forEach,
    keyBy,
    map,
    pickBy,
    omit,
    orderBy,
    find
} from 'lodash';
import initialState, {
    initialLead,
    initialLeadInstallBase,
    initialInstallBase,
    initialLeadActivity,
    initialContact,
    initialUser,
    initialLeadUser
} from './initialState';

import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import moment from 'moment';
import { IinitialState } from '.';

const selectLeadsByID = (state: IinitialState) => state.manageLeads.leadsByID;
const selectLeadInstallBasesByID = (state: IinitialState) =>
    state.manageLeads.leadInstallBasesByID;
const selectInstallBasesByID = (state: IinitialState) =>
    state.manageInventory.installBasesByID;
const getLeadActivities = (state: IinitialState) =>
    state.manageLeads.leadActivitiesByID;
const getSelectedLead = (state: IinitialState) =>
    state.manageLeads.selectedLead;
const getRawUsers = (state: IinitialState) => state.rawUsersByID;
const getLeadUsers = (state: IinitialState) => state.manageLeads.leadUsersByID;
const getLeadIdProp = (state: IinitialState, props: { leadID: string }) =>
    props.leadID;

// a reselect selector to grab the leads with the populated leadInstallBases
export const selectLeadInstallBasesForTable = createSelector(
    [selectLeadInstallBasesByID, selectInstallBasesByID],
    (leadInstallBasesByID, installBasesByID) => {
        return map(leadInstallBasesByID, leadInstall => {
            return {
                ...leadInstall,
                installBase:
                    installBasesByID[leadInstall.installBaseID] ||
                    initialInstallBase
            };
        });
    }
);

export const selectPopulatedLeadUsers = createSelector(
    [getRawUsers, getLeadUsers],
    (rawUsersByID, leadUsersByID): IleadUserPopulated[] => {
        const filteredLeadUsers = filter(leadUsersByID, { isDeleted: false });
        return map(filteredLeadUsers, lUser => {
            const user = rawUsersByID[lUser.userID];
            const salesManager = user
                ? rawUsersByID[user.salesManagerID]
                : initialUser;
            return {
                ...lUser,
                user: { ...user, salesManager }
            };
        });
    }
);

export const selectSelectedLead = createSelector(
    [selectLeadsByID, getLeadIdProp, selectPopulatedLeadUsers],
    (leadsByID, leadID, leadUsersPopulated) => {
        const filteredLeadUsersPopulated = filter(leadUsersPopulated, {
            isDeleted: false,
            leadID
        });
        return { ...leadsByID[leadID], leadUsers: filteredLeadUsersPopulated };
    }
);
// a reselect selector to populate the leadInstallBases on the leads
export const selectLeadsForTable = createSelector(
    [selectLeadsByID, selectLeadInstallBasesForTable, selectPopulatedLeadUsers],
    (leadsByID, leadInstallBases, leadUsersPopulated) => {
        return map(leadsByID, lead => {
            const filteredLeadInstallBases = filter(leadInstallBases, {
                leadID: lead.id,
                isDeleted: false
            });
            const filteredLeadUsers = filter(leadUsersPopulated, {
                isDeleted: false,
                leadID: lead.id
            });

            let contact = initialContact;
            if (lead.contact) {
                contact = {
                    ...lead.contact,
                    firstName: lead.contact.user
                        ? lead.contact.user.first
                        : lead.contact.firstName,
                    lastName: lead.contact.user
                        ? lead.contact.user.last
                        : lead.contact.lastName
                };
            }
            return {
                ...lead,
                leadInstallBases: filteredLeadInstallBases,
                leadUsers: filteredLeadUsers,
                contact
            };
        });
    }
);

export const selectLeadActivityForLead = createSelector(
    [getLeadActivities, getSelectedLead],
    (leadActivitiesByID, lead) => {
        const filtered = filter(leadActivitiesByID, {
            leadID: lead.id,
            isDeleted: false
        });
        return orderBy(
            filtered,
            res => moment.utc(res.activityDate).unix(),
            'desc'
        );
    }
);

// clean and filter out deleted and remove the nested objects
const cleanLeads = (leads: any): Ilead[] => {
    const filtered = filter(leads, { isDeleted: false });
    return filtered.map((item: any) => ({
        ...initialLead,
        ...(pickBy(item, property => property !== null) as Ilead),
        facility: undefined,
        leadInstallBases: undefined,
        leadUsers: undefined
    }));
};

const cleanLeadUser = (leadUser: any): IleadUser => {
    return {
        ...initialLeadUser,
        ...pickBy(leadUser, property => property !== null)
    };
};

// clean and filter out deleted and remove the nested objects
const cleanLeadInstallBases = (leadInstallBases: any): IleadInstallBase[] => {
    const filtered = filter(leadInstallBases, { isDeleted: false });
    return filtered.map((item: any) => ({
        ...initialLeadInstallBase,
        ...(pickBy(item, property => property !== null) as IleadInstallBase),
        installBase: undefined
    }));
};

// clean and filter out deleted and remove the nested objects
const cleanLeadActivities = (leadActivities: any): IleadActivity[] => {
    const filtered = filter(leadActivities, { isDeleted: false });
    return filtered.map((item: any) => ({
        ...initialLeadActivity,
        ...(pickBy(item, property => property !== null) as IleadActivity)
    }));
};

/*
 * REDUCERS
 */

function leadUsersByIDReducer(
    state: { [key: string]: IleadUser } = initialState.manageLeads
        .leadUsersByID,
    action: any
): { [key: string]: IleadUser } {
    switch (action.type) {
        case types.LEADS_MANAGE_SUCCESS: {
            let newLeadUsers: { [key: string]: IleadUser } = {};
            forEach(action.leads, (rawLead: any) => {
                if (rawLead.leadUsers && rawLead.leadUsers.length) {
                    forEach(rawLead.leadUsers, (lu: IleadUser) => {
                        newLeadUsers = {
                            ...newLeadUsers,
                            [lu.id]: cleanLeadUser(lu)
                        };
                    });
                }
            });
            return { ...state, ...newLeadUsers };
        }
        case types.LEAD_UPDATE_SUCCESS: {
            let updatedLeadUsers: { [key: string]: IleadUser } = {};
            const originalLeadUsers = filter(state, {
                leadID: action.payload.id
            });
            if (action.payload.leadUsers && action.payload.leadUsers.length) {
                forEach(originalLeadUsers, (lu: IleadUser) => {
                    const isDeleted =
                        find(action.payload.leadUsers, { id: lu.id }) ===
                        undefined;
                    updatedLeadUsers = {
                        ...updatedLeadUsers,
                        [lu.id]: { ...lu, isDeleted }
                    };
                });
                forEach(action.payload.leadUsers, (lu: IleadUser) => {
                    updatedLeadUsers = { ...updatedLeadUsers, [lu.id]: lu };
                });
            }
            return { ...state, ...updatedLeadUsers };
        }
        default:
            return state;
    }
}
function leadsByIDReducer(
    state: { [key: string]: Ilead } = initialState.manageLeads.leadsByID,
    action: any
): { [key: string]: Ilead } {
    switch (action.type) {
        case types.LEADS_MANAGE_SUCCESS: {
            return keyBy(cleanLeads(action.leads), 'id');
        }
        case types.LEAD_UPDATE_SUCCESS:
            return { ...state, [action.payload.id]: action.payload };
        case types.LEADS_BULK_UPDATE_STATUS_SUCCESS: {
            if (action.leadIDs && action.leadIDs.length) {
                const updatedLeads = map(state, lead => {
                    if (action.leadIDs.indexOf(lead.id) !== -1) {
                        return { ...lead, status: action.leadStatus };
                    } else {
                        return lead;
                    }
                });
                return keyBy(updatedLeads, 'id');
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}

function leadActivitiesByIDReducer(
    state: { [key: string]: IleadActivity } = initialState.manageLeads
        .leadActivitiesByID,
    action: any
): { [key: string]: IleadActivity } {
    switch (action.type) {
        case types.LEAD_ACTIVITIES_MANAGE_SUCCESS: {
            if (action.leadActivities) {
                const cleanedActivities = cleanLeadActivities(
                    action.leadActivities
                );
                return { ...state, ...keyBy(cleanedActivities, 'id') };
            } else {
                return state;
            }
        }
        case types.LEAD_ACTIVITY_ADD_SUCCESS: {
            return { ...state, [action.leadActivity.id]: action.leadActivity };
        }
        case types.LEAD_ACTIVITY_UPDATE_SUCCESS: {
            return { ...state, [action.leadActivity.id]: action.leadActivity };
        }
        case types.LEAD_ACTIVITY_DELETE_SUCCESS: {
            return omit(state, action.payload);
        }

        default:
            return state;
    }
}

function leadInstallBasesByIDReducer(
    state: { [key: string]: IleadInstallBase } = initialState.manageLeads
        .leadInstallBasesByID,
    action: any
): { [key: string]: IleadInstallBase } {
    switch (action.type) {
        case types.LEADS_MANAGE_SUCCESS: {
            if (action.leads) {
                let newLeadInstallBases: IleadInstallBase[] = [];
                forEach(action.leads, lead => {
                    newLeadInstallBases = [
                        ...newLeadInstallBases,
                        ...cleanLeadInstallBases(lead.leadInstallBases)
                    ];
                });
                return { ...state, ...keyBy(newLeadInstallBases, 'id') };
            } else {
                return state;
            }
        }

        case types.LEAD_INSTALL_UPDATE_SUCCESS:
            return { ...state, [action.payload.id]: action.payload };
        case types.LEAD_INSTALL_DELETE_SUCCESS:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    isDeleted: true
                }
            };
        default:
            return state;
    }
}

function totalPages(
    state: number = initialState.manageLeads.totalPages,
    action: any
): number {
    switch (action.type) {
        case types.LEADS_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageLeads.totalPages;
        default:
            return state;
    }
}

function selection(
    state: string[] = initialState.manageLeads.selection,
    action: any
): string[] {
    switch (action.type) {
        case types.LEAD_UPDATE_SELECTION:
            return action.payload;
        case types.LEADS_BULK_UPDATE_STATUS_SUCCESS:
            return [];
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageLeads.selection;
        default:
            return state;
    }
}

function selectedLeadReducer(state: Ilead = initialLead, action: any): Ilead {
    switch (action.type) {
        case types.SET_SELECTED_LEAD:
            return action.payload;
        case types.UPDATE_SELECTED_LEAD:
            return action.payload;
        default:
            return state;
    }
}

/*
 * stores the lead activity currently being edited
 */
const selectedLeadActivityReducer = (
    state = initialLeadActivity,
    action: any
): IleadActivity => {
    switch (action.type) {
        case types.UPDATE_SELECTED_LEAD_ACTIVITY: {
            return action.leadActivity;
        }
        case types.SET_SELECTED_LEAD_ACTIVITY: {
            return action.leadActivity;
        }
        case types.LEAD_ACTIVITY_DELETE_SUCCESS: {
            if (state.id === action.payload) {
                return initialLeadActivity;
            } else {
                return state;
            }
        }
        case types.LEAD_ACTIVITY_ADD_SUCCESS: {
            return initialLeadActivity;
        }
        default:
            return state;
    }
};

const leadsManage = combineReducers<ImanageLeadsReducer>({
    leadsByID: leadsByIDReducer,
    leadUsersByID: leadUsersByIDReducer,
    leadInstallBasesByID: leadInstallBasesByIDReducer,
    leadActivitiesByID: leadActivitiesByIDReducer,
    totalPages,
    showLeadActivityModal: (state, action) =>
        modalToggleWithName(state, action, 'LEAD_ACTIVITIES'),
    showEditLeadActivityModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_LEAD_ACTIVITY'),
    showEditLeadModal: (state, action) =>
        modalToggleWithName(state, action, 'LEADS'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_LEADS'),
    selection,
    selectedLead: selectedLeadReducer,
    selectedLeadActivity: selectedLeadActivityReducer
});

export default leadsManage;
