import React, { CSSProperties } from 'react';
import { productPlaceholderImages } from '../../constants/constants';

interface Iprops {
    style?: CSSProperties;
    mainCategoryID: string;
    imagePath: string;
}

function AssetImage(props: Iprops) {
    // If no valid URL was set, use a placeholder image
    const placeholder = productPlaceholderImages.find(
        img => img.categoryId.toLocaleLowerCase() === props.mainCategoryID
    ) || { src: 'Azure.png' };

    let imgPath = require(`../../images${
        placeholder.src === 'Azure.png' ? '' : '/products'
    }/${placeholder.src}`);

    if (
        props.imagePath !== '' &&
        props.imagePath !== null &&
        props.imagePath !== undefined
    ) {
        imgPath = props.imagePath;
    }
    return (
        <img
            style={
                props.style
                    ? props.style
                    : { marginRight: 15, background: 'white' }
            }
            src={imgPath}
            alt="icon"
        />
    );
}

export default AssetImage;
