import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { map, omit, unionBy } from 'lodash';
import {
    Ijob,
    IjobPart,
    IjobWorkOrder,
    ItableFiltersParams,
    ThunkResult
} from '../models';

import { TFunction } from 'i18next';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { msalFetch } from '../components/auth/Auth-Utils';
import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import { initialJob } from '../reducers/initialState';
import { selectJobWorkOrdersForJobID } from '../reducers/manageJobReducer';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { saveJobParts } from './managePartActions';
import { getWorkOrders } from './manageWorkOrderActions';

const uuidv4 = require('uuid/v4');

/*
 * this is used for paging and filtering the jobs on the manage Jobs view
 * NOTE: the server automatically filters by the user's country
 */
export function getJobs(activeCountryID?: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const {
            page,
            search,
            type,
            startDate,
            endDate,
            country,
            statusFilter,
            assignedUser,
            isDeleted
        } = getState().manageJob.tableFilters;

        const statusValue = statusFilter
            ? Number.parseInt(statusFilter.value)
            : 1;

        let activeCountryIDValue: string | null = null;
        if (activeCountryID) {
            activeCountryIDValue = activeCountryID;
        } else if (country) {
            activeCountryIDValue = country.value;
        }

        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: {
                search,
                jobTypeID: type && type.value,
                startDate: startDate
                    ? moment.utc(startDate).toISOString()
                    : null,
                endDate: endDate ? moment.utc(endDate).toISOString() : null,
                activeCountryID: activeCountryIDValue
                    ? activeCountryIDValue
                    : null,
                status: statusValue,
                FSEID: assignedUser ? assignedUser.value : null,
                isDeleted: isDeleted ? isDeleted : false
            }
        };

        const url = `${API.job.search}?page=${page}`;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.JOB_MANAGE_SUCCESS,
                        jobs: resp.data.result
                    });
                    dispatch({
                        type: types.JOB_MANAGE_TOTAL_PAGES,
                        pages: resp.data.pages
                    });
                    return resp;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.JOB_MANAGE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get jobs');
                console.error(error);
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };
}

export function getJobsByID(id: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        const url = `${API.job.single}/${id}`;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({ type: types.JOB_GET_SUCCESS, job: resp.data });
                }
            })
            .catch(error => {
                dispatch({ type: types.JOB_GET_FAILED, axiosOptions });
                constants.handleError(error, 'get job');
                console.error(error, axiosOptions);
            });
    };
}

export const getNextJobNumber = (): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const url = API.job.getNextAutoNumber;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        return msalFetch(url, axiosOptions)
            .then(data => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_NEXT_JOB_NUMBER_SUCCESS,
                        payload: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_NEXT_JOB_NUMBER_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'getNextJobNumber');
                console.error(error);
            });
    };
};

/*
 * getJobsByFacility is used to populate the job select on the manageReports view
 */
export function getJobsByFacility(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const { selectedFacilityID } = getState();
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                facilityID: selectedFacilityID
            }
        };
        const url = API.job.getByFacility;

        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.JOBS_BY_FACILITY_SUCCESS,
                        jobs: resp.data
                    }); // TODO make a new unique action for this
                    dispatch({
                        type: types.JOB_MANAGE_TOTAL_PAGES,
                        pages: resp.data.pages
                    });
                    return resp;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.JOBS_BY_FACILITY_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get all jobs');
                console.error(error);
            });
    };
}

/*
 * getAllOpenJobsForWorkOrders is used to populate the job select for SAP WOs
 */
export function getAllOpenJobsForWorkOrders(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };
        const url = API.job.getAllOpenJobsForWorkOrders;

        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<Ijob[]>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    const jobs = resp.data;
                    const facilities = jobs
                        .map(job => job.facility)
                        .filter(Boolean);
                    dispatch({
                        type: types.JOBS_REPAIR_MAINTENANCE_SUCCESS,
                        jobs
                    });
                    dispatch({
                        type: types.ADD_JOB_FACILITY_SUCCESS,
                        facilities
                    });
                    return resp;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.JOBS_REPAIR_MAINTENANCE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get all jobs');
                console.error(error);
            });
    };
}

export function getFSEUsers(preferServer = false): ThunkResult<any> {
    return (dispatch, getState) => {
        const now = moment();
        const diff = now.diff(
            moment(getState().syncStatus.fseUsersUpdated, 'X'),
            'minutes'
        );
        const shouldUpdate = diff > constants.cacheFseUsersMinutes;

        if (shouldUpdate === false && preferServer === false) {
            return Promise.resolve();
        }

        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {}
        };

        const url = API.GET.user.getfseusers;
        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_FSE_SUCCESS,

                        users: resp.data,
                        updateDate: moment().unix()
                    });
                    return resp;
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.GET_FSE_FAILED, error, axiosOptions });
                constants.handleError(error, 'get fse users');
                console.error(error);
            })
            .finally(() => {
                dispatch(endAjaxCall());
            });
    };
}

// API will not accept dates in dd-MM-yy format
function formatDatesForAPI(input: string): string {
    const regexYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
    const regexDDMMMYY = /^\d{1,2}-[A-Za-z]{3}-\d{2}$/;

    let dateFormat: string | null = null;

    if (regexYYYYMMDD.test(input)) {
        dateFormat = 'yyyy-MM-dd';
    } else if (regexDDMMMYY.test(input)) {
        dateFormat = 'dd-MMM-yy';
    }

    if (dateFormat !== null) {
        // Parse the date string
        const parsedDate = new Date(input);

        // Format the parsed date to a format acceptable by C#
        return parsedDate.toISOString().substring(0, 10);
    } else {
        return input;
    }
}

/*
 * saveJob
 * persist the job to the Server
 */
export function saveJob(
    job: Ijob,
    jobParts: IjobPart[],
    isEditMode: boolean,
    activeCountryID: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        let url = '';
        let method: Method = 'put';

        if (isEditMode) {
            url = `${API.job.single}/${job.id}`;
            method = 'put';
        } else {
            url = API.POST.job.create;
            method = 'post';
        }
        let jobForAPI = omit(job, ['userJobs', 'createDate', 'updateDate']);
        const userIDsForAPI = map(job.userJobs, uj => uj.userID);
        jobForAPI = {
            ...jobForAPI,
            startDate: formatDatesForAPI(jobForAPI.startDate),
            endDate: formatDatesForAPI(jobForAPI.endDate)
        };

        const axiosOptions: AxiosRequestConfig = {
            method,
            data: { job: jobForAPI, users: userIDsForAPI }
        };

        return msalFetch(url, axiosOptions)
            .then((resp: AxiosResponse<any>) => {
                if (!resp.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.JOB_SAVE_SUCCESS,
                        job
                    });
                    // the save might effect the back end filtering so lets filter!
                    dispatch(getJobs(activeCountryID));

                    if (jobParts.length) {
                        dispatch(saveJobParts(jobParts));
                    }
                    return resp.data;
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.JOB_SAVE_FAILED, error, axiosOptions });
                constants.handleError(error, 'create job');
                console.error(error);
            });
    };
}

/*
 * updateSelectedJob
 * update the selected job
 */
export const updateSelectedJob = (job = initialJob) => ({
    type: types.UPDATE_SELECTED_JOB,
    payload: job
});

export function deleteJob(jobID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };
        const url = `${API.job.delete}/${jobID}`;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.JOB_DELETE_SUCCESS,
                    payload: jobID
                });
            })
            .catch((error: any) => {
                dispatch({
                    type: types.JOB_DELETE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'delete job');
                console.error(error);
            });
    };
}

export const addWorkOrdersToJob = (
    selection: string[],
    jobID: string,
    t: TFunction,
    refreshWorkOrders: boolean = false
): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const originalJobWorkOrders = selectJobWorkOrdersForJobID(getState(), {
            jobID
        });

        const cleanedJobWorkOrders = originalJobWorkOrders.map(item => {
            return omit(item, ['job', 'workOrder']);
        });

        const workOrderIDs = selection.map(item => {
            return item.split('select-')[1];
        });
        const newJobWorkOrders: IjobWorkOrder[] = workOrderIDs.map(item => {
            return { jobID, workOrderID: item, isDeleted: false, id: uuidv4() };
        });
        const jobWorkOrders = unionBy(
            newJobWorkOrders,
            cleanedJobWorkOrders,
            'workOrderID'
        );
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: { id: jobID, jobWorkOrders }
        };
        const url = API.job.addWorkOrders;

        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.JOB_UPDATE_WORKORDERS_SUCCESS,
                    payload: { jobWorkOrders, jobID }
                });
                document.dispatchEvent(
                    new CustomEvent('clearSelectedWorkOrders')
                );
                toastr.success(
                    t('toastMessage:success'),
                    t('toastMessage:addedWorkOrderToJob'),
                    constants.toastrError
                );

                if (refreshWorkOrders) {
                    dispatch(getWorkOrders());
                    dispatch(getAllOpenJobsForWorkOrders());
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.JOB_UPDATE_WORKORDERS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'add work orders to job');
            });
    };
};

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_JOB,
    filters
});
