/*
 * PMP Analytics Container
 */

import PMPAnalytics from './PMPAnalytics';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import { setTableFilter } from '../../actions/manageWorkOrderActions';
import { initialFacility } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { IanalyticsPMP } from '../../models';

interface Iprops {
    t: TFunction;
    pmp: IanalyticsPMP;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & RouteComponentProps<{}>
) => {
    const query = queryString.parse(ownProps.location.search);
    const selectedFacility =
        state.facilities[state.selectedFacilityID] || initialFacility;
    return {
        loading: selectIsLoading(state),
        isDemoMode: query.demo === 'true',
        selectedFacility
    };
};

export const PMPAnalyticsContainer = withRouter(
    connect(mapStateToProps, { setTableFilter })(PMPAnalytics)
);
