/*
 * Edit Product Form
 * approve and merge products in the Product Queue
 * create and edit products
 */

import * as localForage from 'localforage';
import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import Carousel, { Modal, ModalGateway } from 'react-images';
import {
    AbstractControl,
    FieldConfig,
    FormGenerator,
    // GroupProps,
    Observable,
    Validators
} from 'react-reactive-form';
import {
    deletePhoto,
    getPhotosByProduct,
    uploadProductPhoto
} from '../../actions/manageAssetPhotosActions';
import {
    createProductName,
    saveProduct,
    toggleSearchNewProductsModal
} from '../../actions/manageInventoryActions';
import {
    // GroupPropsEdited,
    Ioption,
    Iphoto,
    Iproduct,
    IproductInfo,
    IproductLink,
    IproductQueueObject,
    Isubcategory,
    ItableFiltersReducer,
    Iuser
} from '../../models';
import {
    initialPhoto,
    initialProduct,
    initialProductQueueObject
} from '../../reducers/initialState';

import { TFunction } from 'i18next';
import { filter } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { RouteComponentProps } from 'react-router-dom';
import { updateQueueProduct } from '../../actions/manageProductQueueActions';
import { constants } from '../../constants/constants';
import {
    productCommunicationMethodEnum,
    productLinkTypeEnum
} from '../../models-enums';
import { AddMultiControl, ImultiControlMeta } from '../common/AddMultiControl';
import { FormUtil } from '../common/FormUtil';

const uuidv4 = require('uuid/v4');

interface IstateChanges extends Observable<any> {
    next: () => void;
}

interface AbstractControlEdited extends AbstractControl {
    stateChanges: IstateChanges;
}

interface Iprops extends RouteComponentProps {
    toggleModal: () => void;
    selectedItem: Iproduct;
    selectedQueueObject: IproductQueueObject;
    loading: boolean;
    colorButton: string;
    t: TFunction;
    productInfo: IproductInfo;
    tableFilters: ItableFiltersReducer;
    saveProduct: typeof saveProduct;
    updateQueueProduct: typeof updateQueueProduct;
    getPhotosByProduct: typeof getPhotosByProduct;
    uploadProductPhoto: typeof uploadProductPhoto;
    deletePhoto: typeof deletePhoto;
    user: Iuser;
    toggleSearchNewProductsModal: typeof toggleSearchNewProductsModal;
    selectedFacilityID: string;
    canEditProducts: boolean;
    canCreateProducts: boolean;
    photosByProduct: Iphoto[];
    isEditMode: boolean;
}

interface Istate {
    selectedLink?: IselectedLinkWithOption;
    fieldConfig: FieldConfig;
    selectedPhotos: Iphoto[];
    photoIdsToDelete: string[];
    activePhoto: number;
    lightboxIsOpen: boolean;
}

interface IselectedLinkWithOption {
    productID?: string;
    url?: string;
    type?: Ioption;
    other?: string;
}

class EditProductForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    static defaultProps = {
        selectedItem: initialProduct,
        selectedQueueObject: initialProductQueueObject
    };

    constructor(props: Iprops) {
        super(props);
        this.state = {
            selectedLink: undefined,
            fieldConfig: { controls: {} },
            selectedPhotos: [],
            activePhoto: 0,
            lightboxIsOpen: false,
            photoIdsToDelete: []
        };
    }

    componentDidMount() {
        this.setState(
            {
                fieldConfig: this.buildFieldConfig(
                    this.props.productInfo,
                    this.shouldEnableForm() === false,
                    this.onFileChange
                ),
                selectedPhotos: this.props.photosByProduct
            },
            () => {
                this.updateAddImageButton();
            }
        );

        if (
            this.props.selectedItem.id &&
            this.props.photosByProduct.length === 0
        ) {
            // an existing product and the photos in the productphotos reducer are not for this product
            this.props.getPhotosByProduct(this.props.selectedItem.id);
        }
    }
    componentDidUpdate(prevProps: Iprops) {
        if (
            JSON.stringify(prevProps.photosByProduct) !==
            JSON.stringify(this.props.photosByProduct)
        ) {
            this.setState(
                { selectedPhotos: this.props.photosByProduct },
                () => {
                    this.updateAddImageButton();
                }
            );
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    handleConfirmCompliant = (value: boolean) => {
        if (value === false) {
            const toastrConfirmOptions = {
                onOk: () => {
                    console.info('user says it is ok');
                },
                onCancel: () => {
                    FormUtil.patchControl(this.formGroup, 'isCompliant', true);
                },
                okText: this.props.t('common:ok'),
                cancelText: this.props.t('common:cancel')
            };
            toastr.confirm(
                this.props.t('toastMessage:confirmProductNotCompliant'),
                toastrConfirmOptions
            );
        }
    };

    handleConfirmSpares = (value: boolean) => {
        if (value === false) {
            const toastrConfirmOptions = {
                onOk: () => {
                    console.info('user says it is ok');
                },
                onCancel: () => {
                    FormUtil.patchControl(this.formGroup, 'hasSpares', true);
                },
                okText: this.props.t('common:ok'),
                cancelText: this.props.t('common:cancel')
            };
            toastr.confirm(
                this.props.t('toastMessage:confirmProductHasNoSpares'),
                toastrConfirmOptions
            );
        }
    };

    /*
     * (reusable)
     * set the values to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'mainCategoryID': {
                FormUtil.patchControl(
                    this.formGroup,
                    'subcategoryID',
                    null,
                    {
                        options: FormUtil.convertToOptions(
                            this.filterSubcategories(value.value)
                        )
                    },
                    undefined,
                    true
                );
                FormUtil.patchControl(
                    this.formGroup,
                    'productTypeID',
                    null,
                    {
                        options: FormUtil.convertToOptions(
                            this.filterProductTypes(value.value)
                        )
                    },
                    undefined,
                    true
                );
                FormUtil.patchControl(
                    this.formGroup,
                    'systemSizeID',
                    null,
                    {
                        options: FormUtil.convertToOptions(
                            this.filterSystemSizes(value.value)
                        )
                    },
                    undefined,
                    true
                );
            }
            case 'isCompliant': {
                this.handleConfirmCompliant(value);
                break;
            }
            case 'hasSpares': {
                this.handleConfirmSpares(value);
                break;
            }
            default:
                break;
        }
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    onMultiControlChanges = (
        item: IselectedLinkWithOption,
        multiControlFormGroup?: any
    ) => {
        if (
            (!this.state.selectedLink && item) ||
            (this.state.selectedLink &&
                JSON.stringify(item) !==
                    JSON.stringify(this.state.selectedLink))
        ) {
            this.setState({ selectedLink: item }, () => {
                if (multiControlFormGroup) {
                    let meta = this.buildMultiControlConfig().controls.other
                        .meta;
                    // since we are rebuilding the config, we need to re-translate
                    meta = {
                        ...meta,
                        label: this.props.t(meta.label),
                        name: this.props.t(meta.name)
                    };
                    // patch the multi control config
                    FormUtil.patchControl(
                        multiControlFormGroup,
                        'other',
                        '',
                        meta
                    );
                    const otherControl = multiControlFormGroup.get('other');
                    // disable the input so that it is not "required" when it is hidden
                    if (otherControl) {
                        if (meta.className === 'hidden') {
                            otherControl.disable();
                        } else {
                            otherControl.enable();
                        }
                    }
                }
            });
        }
    };

    /*
     * buildMultiControlConfig
     * the goal here is to conditionally render the "other" input whenever the "other" type of product link is selected
     * We update the config here and in onMultiControlChanges we subscribe to changes and patch the "other" control with the "hidden" className
     */
    buildMultiControlConfig = () => {
        const itemEditFieldConfig = {
            controls: {
                type: {
                    render: FormUtil.Select,
                    meta: {
                        label: 'productForm.linkType',
                        colWidth: 12,
                        required: true,
                        isMulti: false,
                        options: FormUtil.convertEnumToOptions(
                            productLinkTypeEnum
                        ),
                        shouldTranslate: true,
                        name: 'productForm.linkType'
                    },
                    options: {
                        validators: FormUtil.validators.requiredWithTrim
                    }
                },
                other: {
                    render: FormUtil.TextInput,
                    meta: {
                        label: 'productForm.linkLabel',
                        colWidth: 12,
                        required: true,
                        name: 'productForm.linkLabel',
                        shouldTranslate: true,
                        className: ''
                    },
                    options: {
                        validators: [FormUtil.validators.requiredWithTrim]
                    },
                    formState: { value: '', disabled: false }
                },
                url: {
                    render: FormUtil.TextInput,
                    meta: {
                        label: 'productForm.linkUrl',
                        colWidth: 12,
                        required: true,
                        placeholder: 'productForm.linkPlaceholder',
                        name: 'productForm.linkUrl'
                    },
                    options: {
                        validators: [
                            FormUtil.validators.requiredWithTrim,
                            Validators.pattern(constants.urlValidatorPattern),
                            Validators.maxLength(1000)
                        ]
                    }
                }
            }
        };

        if (
            !this.state.selectedLink ||
            (this.state.selectedLink &&
                this.state.selectedLink.type &&
                parseInt(this.state.selectedLink.type.value) !==
                    productLinkTypeEnum.other) ||
            (this.state.selectedLink && !this.state.selectedLink.type)
        ) {
            const newMeta = {
                ...itemEditFieldConfig.controls.other.meta,
                className: 'hidden'
            };
            const newOtherControl = {
                ...itemEditFieldConfig.controls.other,
                formState: { value: '', disabled: true },
                meta: newMeta
            };
            itemEditFieldConfig.controls.other = newOtherControl;
        }
        return itemEditFieldConfig;
    };

    onFileChange = (key: string, file: File) => {
        const id = uuidv4();
        const objectUrl = window.URL.createObjectURL(file);

        localForage.setItem(id, file).then(value => {
            this.setState(
                {
                    selectedPhotos: [
                        {
                            ...initialPhoto,
                            id,
                            thumbnailUrl: objectUrl,
                            lightboxUrl: objectUrl,
                            src: objectUrl,
                            productID: this.props.selectedItem.id,
                            isNew: true
                        }
                    ],
                    photoIdsToDelete: this.state.selectedPhotos?.length
                        ? [
                              ...this.state.photoIdsToDelete,
                              this.state.selectedPhotos[0]?.id
                          ]
                        : []
                },
                () => {
                    this.updateAddImageButton();
                }
            );
        });
    };

    getAddImageLabel = (): string => {
        return this.state.selectedPhotos.length > 0
            ? this.props.t('manageProduct:productPhotoUpload')
            : this.props.t('manageProduct:productNoPhotoUpload');
    };

    updateAddImageButton = () => {
        FormUtil.patchControlMeta(this.formGroup, 'file', {
            label: this.getAddImageLabel()
        });
    };

    buildFieldConfig = (
        productInfo: IproductInfo,
        disabled: boolean,
        onFileChange: any
    ) => {
        const {
            sku,
            description,
            brandID,
            subcategoryID,
            productTypeID,
            powerID,
            systemSizeID,
            isActive,
            isCompliant,
            isFinalProduct,
            hasSpares,
            communicationMethod,
            links,
            origin,
            notes,
            sapMaterialNumber,
            productStandards
        } = this.props.selectedItem;
        const { creator } = this.props.selectedQueueObject;
        let filteredSubcategoryOptions: Ioption[] = [];
        let productTypeOptions = productInfo.productTypeOptions;
        let systemSizeOptions = productInfo.systemSizeOptions;
        // let selectedSubcategoryID = null;
        let selectedMainCategory: any = null;
        const selectedBrand = brandID ? productInfo.brands[brandID] : null;
        const selectedSubcategory = subcategoryID
            ? productInfo.subcategories[subcategoryID]
            : null;
        const selectedProductType = productTypeID
            ? productInfo.productTypes[productTypeID]
            : null;
        const selectedPower = powerID ? productInfo.powers[powerID] : null;
        const selectedSystemSize = systemSizeID
            ? productInfo.systemSizes[systemSizeID]
            : null;
        const selectedCommunicationMethod = {
            value: communicationMethod,
            label: this.props.t(
                productCommunicationMethodEnum[communicationMethod]
            )
        };

        const originOptions: Ioption[] = [
            { value: 'USN', label: 'USN' },
            { value: 'GBN', label: 'GBN' },
            { value: 'CAN', label: 'CAN' }
        ];

        const selectedOrigin = originOptions.find(x => x.value === origin);

        const standard_ids = new Set(
            productStandards.map(standard => standard['standardID'])
        );
        const selectedProductStandards: Ioption[] = productInfo.standardOptions.filter(
            option => standard_ids.has(option['value'])
        );

        // turn link type into iOptions
        const selectedLinks = links.map(link => {
            return {
                ...link,
                type: {
                    value: link.type,
                    label: productLinkTypeEnum[link.type]
                }
            };
        });
        if (subcategoryID) {
            // TODO for now grabbing the mainCategoryID.  when we actually support having multiple mainCategories related to a single subCategory, we will need to store the mainCategoryID on the product object
            const subCategory = productInfo.subcategories[subcategoryID];
            if (subCategory) {
                const mainCategoryID =
                    productInfo.subcategories[subcategoryID].mainCategoryID;
                if (mainCategoryID) {
                    selectedMainCategory =
                        productInfo.mainCategories[mainCategoryID];
                    filteredSubcategoryOptions = FormUtil.convertToOptions(
                        this.filterSubcategories(mainCategoryID)
                    );
                    productTypeOptions = FormUtil.convertToOptions(
                        this.filterProductTypes(mainCategoryID)
                    );
                    systemSizeOptions = FormUtil.convertToOptions(
                        this.filterSystemSizes(mainCategoryID)
                    );
                }
            }
        }
        const itemEditFieldConfig = this.buildMultiControlConfig();

        const fieldConfigControls = {
            file: {
                render: this.shouldEnableForm() ? FormUtil.FileInput : null,
                meta: {
                    type: 'file',
                    label: this.getAddImageLabel(),
                    name: 'product-image',
                    icon: 'plus',
                    colWidth: 12,
                    accept: 'image/png, image/jpeg',
                    required: false,
                    onChange: onFileChange,
                    uploadButton: true,
                    style: { textAlign: 'right' }
                }
            },
            $field_0: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <Col
                        xs={12}
                        style={{
                            marginTop: '10px',
                            display: this.props.selectedQueueObject.id
                                ? ''
                                : 'none'
                        }}
                    >
                        <FormGroup bsSize="sm">
                            <ControlLabel>
                                {this.props.t('productForm.createdBy')}
                            </ControlLabel>
                            <h5 className="queue-form-label">
                                {creator.first} {creator.last}
                            </h5>
                        </FormGroup>
                    </Col>
                )
            },
            sapMaterialNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'productForm.partNumber',
                    colWidth: 12,
                    type: 'input',
                    name: 'sapMaterialNumber',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(250)
                },
                formState: {
                    value: sapMaterialNumber,
                    disabled: true
                }
            },
            sku: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'productForm.sku',
                    colWidth: 12,
                    type: 'input',
                    name: 'sku',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(250)
                },
                formState: {
                    value: sku,
                    disabled: sapMaterialNumber ? true : false
                }
            },
            description: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'productForm.description',
                    colWidth: 12,
                    componentClass: 'textarea',
                    name: 'description',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(1000)
                },
                formState: {
                    value: description,
                    disabled: sapMaterialNumber ? true : false
                }
            },
            notes: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'productForm.notes',
                    colWidth: 12,
                    componentClass: 'textarea',
                    name: 'notes',
                    required: false
                },
                options: {
                    validators: Validators.maxLength(1000)
                },
                formState: { value: notes, disabled: false }
            },
            // origin: {
            //     render: FormUtil.Select,
            //     meta: {
            //         label: 'productForm.origin',
            //         colWidth: 12,
            //         componentClass: 'input',
            //         name: 'origin',
            //         options: originOptions,
            //         required: false
            //     },
            //     formState: {
            //         value: selectedOrigin,
            //         disabled: sapMaterialNumber ? true : false
            //     }
            // },
            brandID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.brandOptions,
                    label: 'productForm.brand',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'brand'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedBrand),
                    disabled: sapMaterialNumber ? true : false
                }
            },
            mainCategoryID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.mainCategoryOptions,
                    label: 'mainCategory',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'main-category'
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedMainCategory),
                    disabled: sapMaterialNumber ? true : false
                }
            },
            subcategoryID: {
                render: FormUtil.Select,
                meta: {
                    options: selectedMainCategory
                        ? filteredSubcategoryOptions
                        : [],
                    label: 'productForm.subcategory',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'subcategory'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedSubcategory),
                    disabled: sapMaterialNumber ? true : false
                }
            },
            productTypeID: {
                render: FormUtil.Select,
                meta: {
                    options: productTypeOptions,
                    label: 'productForm.productType',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'gas-type',
                    isClearable: true,
                    required: false
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedProductType),
                    disabled: sapMaterialNumber ? true : false
                }
            },
            powerID: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.powerOptions,
                    label: 'productForm.power',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'power',
                    isClearable: true,
                    required: false
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedPower),
                    disabled: sapMaterialNumber ? true : false
                }
            },
            // model
            systemSizeID: {
                render: FormUtil.Select,
                meta: {
                    options: systemSizeOptions,
                    label: 'productForm.systemSize',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'system-size',
                    isClearable: true,
                    required: false
                },
                formState: {
                    value: FormUtil.convertToSingleOption(selectedSystemSize),
                    disabled: sapMaterialNumber ? true : false
                }
            },
            communicationMethod: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        productCommunicationMethodEnum
                    ),
                    label: 'productForm.communicationMethod',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: false,
                    name: 'communicationMethod',
                    isClearable: false,
                    required: true,
                    shouldTranslate: true
                },
                formState: {
                    value: selectedCommunicationMethod,
                    disabled: sapMaterialNumber ? true : false
                }
            },
            productStandards: {
                render: FormUtil.Select,
                meta: {
                    options: productInfo.standardOptions,
                    label: 'productForm.standards',
                    colWidth: 12,
                    placeholder: 'common:searchPlaceholder',
                    isMulti: true,
                    name: 'product-standards'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: selectedProductStandards,
                    disabled
                }
            },
            links: {
                render: AddMultiControl,
                meta: {
                    label: 'productForm.links',
                    buttonLabel: this.props.t('productForm.addLink'),
                    colWidth: 12,
                    colorButton: this.props.colorButton,
                    t: this.props.t,
                    modalTitle: 'productForm.linkModalTitle',
                    getItemTitle: (item: IselectedLinkWithOption) =>
                        item.type
                            ? `${
                                  parseInt(item.type.value) ===
                                  productLinkTypeEnum.other
                                      ? item.other
                                      : this.props.t(
                                            productLinkTypeEnum[
                                                parseInt(item.type.value)
                                            ]
                                        )
                              }`
                            : '',
                    onValueChanges: this.onMultiControlChanges,
                    disableDelete:
                        constants.hasSecurityFunction(this.props.user, [
                            constants.securityFunctions.ManageProducts.id
                        ]) === false,
                    disableEdit:
                        constants.hasSecurityFunction(this.props.user, [
                            constants.securityFunctions.ManageProducts.id
                        ]) === false,
                    actions: [
                        {
                            label: 'productForm.viewLinkButton',
                            onClick: item => {
                                const pLink = item as IproductLink;
                                window.open(pLink.url, '_blank');
                            },
                            getTitle: (item: IproductLink) => item.url
                        }
                    ],
                    itemEditFieldConfig
                } as ImultiControlMeta,
                formState: { value: selectedLinks, disabled }
            },
            $field_spacing: {
                isStatic: false, // ensures a key is added
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: this.props.t('productForm.other'),
                            colWidth: 12
                        }}
                    />
                )
            },
            isActive: {
                render: FormUtil.Toggle,
                meta: { label: 'productForm.active', colWidth: 6 },
                formState: { value: isActive, disabled }
            },
            isCompliant: {
                render: FormUtil.Toggle,
                meta: { label: 'productForm.compliant', colWidth: 6 },
                formState: { value: isCompliant, disabled }
            },
            hasSpares: {
                render: FormUtil.Toggle,
                meta: { label: 'productForm.spares', colWidth: 6 },
                formState: { value: hasSpares, disabled }
            },
            isFinalProduct: {
                render: FormUtil.Toggle,
                meta: { label: 'productForm.isFinalProduct', colWidth: 6 },
                formState: { value: isFinalProduct, disabled }
            }
        };

        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    filterSubcategories = (mainCategoryID: string) => {
        return filter(
            this.props.productInfo.subcategories,
            (sub: Isubcategory) => {
                return sub.mainCategoryID === mainCategoryID;
            }
        );
    };

    filterProductTypes = (mainCategoryID: string) => {
        return filter(this.props.productInfo.productTypes, type => {
            return type.mainCategoryIDs.indexOf(mainCategoryID) !== -1;
        });
    };

    filterSystemSizes = (mainCategoryID: string) => {
        return filter(this.props.productInfo.systemSizes, systemSize => {
            return systemSize.mainCategoryIDs.indexOf(mainCategoryID) !== -1;
        });
    };

    handleSubmit = (
        e: React.MouseEvent<HTMLFormElement>,
        shouldApprove = false
    ) => {
        e.preventDefault();

        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        const formValues = FormUtil.getValues(this.formGroup.value);

        // when mainCategoryID is disabled it is not included in the form value
        let mainCategoryID = this.formGroup.value.mainCategoryID;
        if (!mainCategoryID) {
            const subCategory = this.props.productInfo.subcategories[
                this.props.selectedItem.subcategoryID
            ];
            mainCategoryID = FormUtil.convertToSingleOption(
                this.props.productInfo.mainCategories[
                    subCategory.mainCategoryID
                ]
            );
        }

        let productStandards = this.formGroup.value.productStandards;
        productStandards = productStandards.map((item: any) => ({
            StandardID: item.value
        }));

        const productName = createProductName({
            ...this.formGroup.value,
            mainCategoryID
        });
        let linksWithProductID = [];

        // convert the link value to the enum
        if (this.formGroup.value.links) {
            linksWithProductID = this.formGroup.value.links.map(
                (link: IselectedLinkWithOption) => ({
                    ...link,
                    productID: this.props.selectedItem.id,
                    type: link.type ? link.type.value : 1
                })
            );
        }

        let updatedProduct: any = {
            ...this.props.selectedItem,
            ...formValues,
            name: productName,
            links: linksWithProductID,
            id: this.props.selectedItem.id || uuidv4(),
            productStandards
        };

        updatedProduct.mergedProductID =
            updatedProduct.mergedProductID &&
            updatedProduct.mergedProductID !== ''
                ? updatedProduct.mergedProductID
                : undefined;
        updatedProduct.powerID =
            updatedProduct.powerID && updatedProduct.powerID !== ''
                ? updatedProduct.powerID
                : undefined;
        updatedProduct.systemSizeID =
            updatedProduct.systemSizeID && updatedProduct.systemSizeID !== ''
                ? updatedProduct.systemSizeID
                : undefined;
        updatedProduct.productTypeID =
            updatedProduct.productTypeID && updatedProduct.productTypeID !== ''
                ? updatedProduct.productTypeID
                : undefined;

        if (
            this.props.selectedItem.id.length &&
            this.props.selectedQueueObject.id.length
        ) {
            // updating a queue object, no need for a facilityID
            this.props.updateQueueProduct(
                updatedProduct,
                shouldApprove,
                this.props.selectedQueueObject.id
            );
        } else {
            const photosToUpload: Iphoto[] =
                this.state.selectedPhotos?.filter(photo => photo.isNew) || [];

            this.props.saveProduct(
                updatedProduct,
                this.props.isEditMode,
                photosToUpload,
                this.state.photoIdsToDelete,
                true
            );
        }
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    shouldEnableForm = () => {
        const isEditing = this.props.selectedItem.id.length > 0;
        if (isEditing) {
            return this.props.canEditProducts;
        } else {
            return this.props.canCreateProducts;
        }
    };

    openLightbox = (index: number) => {
        this.setState({ activePhoto: index, lightboxIsOpen: true });
    };

    closeLightbox = () => {
        this.setState({ activePhoto: 0, lightboxIsOpen: false });
    };

    deletePhoto = (photoID: string, productID: string) => (evt: any) => {
        evt.stopPropagation();
        this.setState(
            {
                selectedPhotos: [],
                photoIdsToDelete: [...this.state.photoIdsToDelete, photoID]
            },
            () => {
                this.updateAddImageButton();
            }
        );
    };

    renderPhotos = (photos?: Iphoto[]) => {
        if (!photos?.length) {
            return null;
        }

        return (
            <Col xs={12}>
                <div className="h-scroll">
                    {photos.map(({ id, thumbnailUrl, productID }, index) => (
                        <div
                            key={index}
                            className="item"
                            onClick={() => this.openLightbox(index)}
                        >
                            {this.shouldEnableForm() && (
                                <div onClick={this.deletePhoto(id, productID)}>
                                    <FontAwesomeIcon icon={['far', 'times']} />
                                </div>
                            )}
                            <img src={thumbnailUrl} alt="" />
                        </div>
                    ))}
                </div>
            </Col>
        );
    };

    render() {
        const { user, t } = this.props;
        const hasManageSuggestedParts = constants.hasSecurityFunction(user, [
            constants.securityFunctions.ManageSuggestedParts.id
        ]);

        return (
            <div className={this.props.colorButton}>
                {/* We don't want the Search For Existing Product on Products Screen */}
                {!!this.props.selectedItem.id.length && (
                    <Button
                        bsStyle="link"
                        type="button"
                        disabled={this.props.loading}
                        onClick={this.props.toggleSearchNewProductsModal}
                        style={{ marginBottom: '-5px' }}
                        className="pull-right"
                    >
                        {t('manageInventory:mergeProduct')}
                    </Button>
                )}

                {!(this.props.selectedItem && this.props.selectedItem.id) && (
                    <Col xs={12}>
                        <p style={{ lineHeight: '1.4rem' }}>
                            {t('newProductInstructions')}
                        </p>
                    </Col>
                )}
                {this.props.selectedItem && this.props.selectedItem.id && (
                    <Col xs={12}>
                        <h5 style={{ lineHeight: '1.4rem' }}>
                            {this.props.selectedItem.name}
                        </h5>
                    </Col>
                )}
                {hasManageSuggestedParts &&
                    !this.props.selectedQueueObject?.id &&
                    !!(
                        this.props.selectedItem && this.props.selectedItem.id
                    ) && (
                        <Button
                            bsStyle="default"
                            type="button"
                            disabled={this.props.loading}
                            onClick={() =>
                                this.props.history.push(
                                    `/suggested_parts?productID=${this.props.selectedItem.id}`
                                )
                            }
                            style={{
                                marginBottom: '15px',
                                marginRight: '15px'
                            }}
                            className="pull-right"
                        >
                            {t('manageInventory:manageSuggestedParts')}
                        </Button>
                    )}

                {this.renderPhotos(this.state.selectedPhotos)}

                <ModalGateway>
                    {this.state.lightboxIsOpen && (
                        <Modal
                            onClose={this.closeLightbox}
                            styles={{
                                positioner: (base: any) => ({
                                    ...base,
                                    zIndex: 10000
                                }),
                                blanket: (base: any) => ({
                                    ...base,
                                    zIndex: 10000
                                })
                            }}
                        >
                            <Carousel
                                currentIndex={this.state.activePhoto}
                                views={this.props.photosByProduct.map(
                                    photo => ({
                                        ...photo,
                                        source: photo.lightboxUrl,
                                        caption: photo.name
                                    })
                                )}
                            />
                        </Modal>
                    )}
                </ModalGateway>

                <form
                    onSubmit={this.handleSubmit}
                    className="clearfix beacon-form"
                >
                    <FormGenerator
                        onMount={this.setForm}
                        fieldConfig={this.state.fieldConfig}
                    />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.props.toggleModal}
                        >
                            {t('common:cancel')}
                        </Button>
                        {this.shouldEnableForm() && (
                            <Button
                                bsStyle={this.props.colorButton}
                                type="submit"
                                disabled={this.props.loading}
                                style={{ marginRight: '20px' }}
                            >
                                {t('common:save')}
                            </Button>
                        )}
                        {!!this.props.selectedQueueObject.id.length && (
                            <Button
                                bsStyle={this.props.colorButton}
                                type="button"
                                disabled={this.props.loading}
                                onClick={(evt: any) =>
                                    this.handleSubmit(evt, true)
                                }
                            >
                                {t('manageInventory:saveApprove')}
                            </Button>
                        )}
                    </Col>
                </form>
            </div>
        );
    }
}
export default EditProductForm;
