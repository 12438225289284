import { TFunction } from 'i18next';
import { connect } from 'react-redux';

import {
    updateWorkOrder,
    toggleWorkOrderCloseModal
} from '../../actions/manageWorkOrderActions';
import { clearSelectedProductID } from '../../actions/manageInventoryActions';
import WorkOrderCloseForm from './WorkOrderCloseForm';
import { initialWorkOrder } from '../../reducers/initialState';
import { initialProduct } from '../../reducers/initialState';

import {
    clearSelectedWorkOrderID,
    setWorkOrderFormValues,
    updateWorkOrderFormValue,
    addWorkOrder
} from '../../actions/manageWorkOrderActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
    t: TFunction;
    selectedInstallBaseID?: string;
    locationString?: string;
    enableRepairMode?: boolean;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const { productsByID, selectedProductID } = state.manageInventory;
    const selectedProduct = selectedProductID
        ? productsByID[selectedProductID]
        : initialProduct;
    const selectedWorkOrder =
        state.manageWorkOrder.workOrdersByID[
            state.manageWorkOrder.selectedWorkOrderID
        ] || initialWorkOrder;
    let title =
        selectedWorkOrder.status === 1
            ? ownProps.t('AddCloseNote')
            : ownProps.t('ViewCloseNote');
    if (ownProps.enableRepairMode) {
        title = ownProps.t('repairAssetTitle');
    }
    return {
        title,
        className: 'work-order-close',
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageWorkOrder.showWorkOrderCloseModal,
        selectedWorkOrder,
        formValues: state.manageWorkOrder.workOrderFormValues,
        standardOptions: state.productInfo.standardOptions,
        selectedProduct
    };
};

export const WorkOrderCloseModal = withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        onHide: toggleWorkOrderCloseModal,
        toggleModal: toggleWorkOrderCloseModal,
        updateWorkOrder,
        clearSelectedWorkOrderID,
        clearSelectedProductID,
        updateFormValue: updateWorkOrderFormValue,
        setFormValues: setWorkOrderFormValues,
        addWorkOrder
    })(WithCommonModal(WorkOrderCloseForm))
);
