import * as React from 'react';
import * as types from './actionTypes';

import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import {
    IinstallBase,
    Ijob,
    Ioption,
    Iphoto,
    Iproduct,
    IproductLink,
    IsearchNewProductInstallBatchMode,
    ItableFiltersParams,
    ThunkResult
} from '../models';
import { filter, map, values } from 'lodash';

import API from '../constants/apiEndpoints';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import {
    deleteMultiplePhotos,
    uploadProductPhoto
} from './manageAssetPhotosActions';
import { ProductAttributes } from '../modelsForms';
import { constants } from '../constants/constants';
import { msalFetch } from '../components/auth/Auth-Utils';
import { toastr } from 'react-redux-toastr';
import { beaconContactTypeEnum } from '../models-enums';
import { IinitialState } from '../reducers';
import { replaceEmptyStringsWithNull } from '../helpers/cleanObjEmptyStrings';

const uuidv4 = require('uuid/v4');

// TODO move this to the common actions file
export function getProductInfo(): ThunkResult<any> {
    return (dispatch, getState) => {
        const { user } = getState();

        if (
            !constants.hasSecurityFunction(
                user,
                [constants.securityFunctions.ViewInventory.id],
                { matchAll: true }
            )
        )
            return dispatch({
                type: types.GET_PRODUCT_INFO_SUCCESS,
                data: {}
            });

        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        const url = API.GET.inventory.getproductinfo;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_PRODUCT_INFO_SUCCESS,
                        data: data.data
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_PRODUCT_INFO_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get product info');
                console.error(error);
            });
    };
}

export function getProducts(
    page: number,
    search: string,
    isFinalProduct: boolean,
    attributes: ProductAttributes,
    standards: string[],
    isApproved?: boolean
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        let params: {
            search: string;
            mainCategoryID?: string | null;
            subcategoryID?: string | null;
            brandID?: string | null;
            origin?: string;
            powerID?: string | null;
            productTypeID?: string | null;
            systemSizeID?: string | null;
            StandardID?: string;
            isApproved?: boolean;
            isFinalProduct?: boolean;
            standards: string[];
        } = {
            search,
            isFinalProduct,
            ...attributes,
            standards
        };
        if (isApproved === true) {
            // we only want to filter out unaproved products when merging
            // and we never want to filter out approved products here.
            params = { ...params, isApproved };
        }

        const cleanedData = replaceEmptyStringsWithNull(params);

        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: cleanedData
        };

        const url = API.GET.inventory.products;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_PRODUCTS_SUCCESS,
                        products: data.data.result
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_PRODUCTS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get products');
                console.error(error);
            });
    };
}

/*
 *  get inventory with the result status and measurement point results
 */

const getInventoryHelper = (
    facilityID: string,
    pagingDataCallback: any,
    dispatch: any,
    getState: () => IinitialState,
    page?: number
) => {
    const { user } = getState();
    const { ViewInventory } = constants.securityFunctions;

    if (!constants.hasSecurityFunction(user, [ViewInventory.id]))
        return dispatch({
            type: types.GET_CONTACTS_BY_FACILITY_SUCCESS,
            payload: []
        });

    dispatch(beginAjaxCall());
    const { selectedFacilityID } = getState();
    const axiosOptions: AxiosRequestConfig = {
        method: 'get',
        params: {
            facilityID:
                facilityID && facilityID !== ''
                    ? facilityID
                    : selectedFacilityID
        }
    };

    const url = page
        ? `${API.GET.inventory.getByFacility}?page=${page}`
        : API.GET.inventory.getByFacility;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                // Send the paging data back to the manageInventory component, everyone else will be ignoring this
                pagingDataCallback(data.data.count, data.data.pages);
                dispatch({
                    type: types.GET_INVENTORY_SUCCESS,
                    inventory: data.data.result
                });
            }
        })
        .catch((error: any) => {
            dispatch({ type: types.GET_INVENTORY_FAILED, error, axiosOptions });
            constants.handleError(error, 'get inventory');
            console.error(error);
        })
        .finally(() => dispatch(endAjaxCall()));
};
export function initInventory(
    facilityID: string,
    pagingDataCallback: any,
    page?: number
): ThunkResult<any> {
    return (dispatch, getState) => {
        return getInventoryHelper(
            facilityID,
            pagingDataCallback,
            dispatch,
            getState,
            page
        );
    };
}

/*
 * save or update a product
 */
export function saveProduct(
    product: Iproduct,
    isEditMode: boolean,
    photosToUpload?: Iphoto[],
    photoIdsToDelete?: string[],
    newProductSelect = false
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        let url = '';
        let method: Method = 'post';
        if (isEditMode) {
            url = API.POST.inventory.updateproduct;
            method = 'post';
        } else {
            url = API.POST.inventory.addproduct;
            method = 'post';
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let { installBases, ...productForAPI } = product;
        if (product.links) {
            const productLinks = product.links.map(
                (productLink: IproductLink) => {
                    return { ...productLink, productID: product.id };
                }
            );
            productForAPI = { ...product, links: productLinks };
        }

        let cleanedProduct: any = productForAPI;
        cleanedProduct.mergedProductID =
            productForAPI.mergedProductID === ''
                ? null
                : productForAPI.mergedProductID;
        cleanedProduct.powerID =
            productForAPI.powerID === '' ? null : productForAPI.powerID;

        const axiosOptions: AxiosRequestConfig = {
            method,
            data: cleanedProduct
        };
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.PRODUCT_ADD_SUCCESS,
                        product: data.data
                    });

                    if (photosToUpload && photosToUpload.length) {
                        photosToUpload.forEach(photo => {
                            dispatch(
                                uploadProductPhoto(
                                    photo.id,
                                    photo.src,
                                    product.id
                                )
                            );
                        });
                    }
                    if (photoIdsToDelete?.length) {
                        dispatch(
                            deleteMultiplePhotos(photoIdsToDelete, product.id)
                        );
                    }
                    dispatch({ type: types.TOGGLE_MODAL_EDIT_PRODUCT });

                    if (newProductSelect) {
                        dispatch(setSearchNewProductsSelectedProduct(product));
                        dispatch(toggleEditInstallModal());
                    }
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.PRODUCT_ADD_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'save product');
                console.error(error);
            });
    };
}

const cleanQuantity = (quantity: any): number => {
    // Make sure quantity is a number
    if (quantity === undefined || quantity === null || Number.isNaN(quantity)) {
        return 1;
    }

    if (typeof quantity === 'string') {
        return parseInt(quantity);
    }

    return quantity;
};

export function updateInstall(
    install: IinstallBase,
    productID: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        // Make sure the quantity is a valid number
        install.quantity = cleanQuantity(install.quantity);

        dispatch(beginAjaxCall());
        dispatch({ type: types.TOGGLE_MODAL_EDIT_INSTALL });
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: install
        };

        const url = API.POST.inventory.updateinstall;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.INSTALL_UPDATE_SUCCESS,
                        payload: install
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.INSTALL_UPDATE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'update asset');
                console.error(error);
            });
    };
}

/*
 * save (add) an install (an install might have a quantity greater than 1 so we might be adding multiple installs)
 * If this is a new product, then also add the product
 */
export function saveInstall(
    install: IinstallBase,
    product: Iproduct,
    job?: Ijob
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        dispatch({ type: types.CLOSE_ALL_MODALS });
        // if quantity is greater than 1, we are creating multiple installs
        const newID = uuidv4();
        let newInstalls = {};

        if (install.quantity && install.quantity > 1) {
            for (let i = install.quantity - 1; i >= 0; i--) {
                const newIDb = uuidv4();
                newInstalls = {
                    ...newInstalls,
                    [newIDb]: { ...install, id: newIDb, quantity: 1 }
                };
            }
        } else {
            newInstalls = { [newID]: { ...install, id: newID } };
        }

        const cleanedInstalls = map(newInstalls, (newInstall: any) => {
            newInstall.lastFiveYearMaintenanceDate =
                newInstall.lastFiveYearMaintenanceDate === ''
                    ? null
                    : newInstall.lastFiveYearMaintenanceDate;
            newInstall.lastThreeYearMaintenanceDate =
                newInstall.lastThreeYearMaintenanceDate === ''
                    ? null
                    : newInstall.lastThreeYearMaintenanceDate;
            newInstall.lastTwoYearMaintenanceDate =
                newInstall.lastTwoYearMaintenanceDate === ''
                    ? null
                    : newInstall.lastTwoYearMaintenanceDate;
            newInstall.lastYearlyMaintenanceDate =
                newInstall.lastYearlyMaintenanceDate === ''
                    ? null
                    : newInstall.lastYearlyMaintenanceDate;
            newInstall.lastSixMonthMaintenanceDate =
                newInstall.lastSixMonthMaintenanceDate === ''
                    ? null
                    : newInstall.lastSixMonthMaintenanceDate;
            newInstall.lastQuarterlyMaintenanceDate =
                newInstall.lastQuarterlyMaintenanceDate === ''
                    ? null
                    : newInstall.lastQuarterlyMaintenanceDate;

            newInstall.latestAGSMeasurementPointListResultID =
                newInstall.latestAGSMeasurementPointListResultID === ''
                    ? null
                    : newInstall.latestAGSMeasurementPointListResultID;
            newInstall.latestAuditMeasurementPointListResultID =
                newInstall.latestAuditMeasurementPointListResultID === ''
                    ? null
                    : newInstall.latestAuditMeasurementPointListResultID;
            newInstall.latestCommissioningMeasurementPointListResultID =
                newInstall.latestCommissioningMeasurementPointListResultID ===
                ''
                    ? null
                    : newInstall.latestCommissioningMeasurementPointListResultID;
            newInstall.latestMeasurementPointListResultID =
                newInstall.latestMeasurementPointListResultID === ''
                    ? null
                    : newInstall.latestMeasurementPointListResultID;
            newInstall.latestVerificationMeasurementPointListResultID =
                newInstall.latestVerificationMeasurementPointListResultID === ''
                    ? null
                    : newInstall.latestVerificationMeasurementPointListResultID;

            newInstall.latestWorkOrderID =
                newInstall.latestWorkOrderID === ''
                    ? null
                    : newInstall.latestWorkOrderID;

            newInstall.installDate =
                newInstall.installDate === '' ? null : newInstall.installDate;

            // Make sure quantity is a number
            if (
                newInstall.quantity === undefined ||
                newInstall.quantity === null ||
                Number.isNaN(newInstall.quantity)
            ) {
                newInstall.quantity = 1;
            }

            if (typeof newInstall.quantity === 'string') {
                newInstall.quantity = parseInt(newInstall.quantity);
            }

            return newInstall;
        });

        const payload = { InstallBases: values(cleanedInstalls) };
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: payload
        };

        const url = API.POST.inventory.addinstall;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.INSTALL_ADD_SUCCESS,
                        installs: values(newInstalls),
                        product
                    });
                    toastr.success(
                        'Success',
                        'Saved asset',
                        constants.toastrSuccess
                    );
                    // get updated inventory
                    getInventoryHelper(
                        '',
                        () => {
                            return;
                        },
                        dispatch,
                        getState
                    );
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.INSTALL_ADD_FAILED, error });
                constants.handleError(error, 'save install');
                console.error(error);
            });
    };
}
export function deleteInstall(installBaseID: string): ThunkResult<any> {
    return (dispatch, getState) => {
        const originalInstallBase = getState().manageInventory.installBasesByID[
            installBaseID
        ];
        dispatch({ type: types.CLOSE_ALL_MODALS });
        const axiosOptions = {
            url: API.POST.inventory.deleteInstall,
            method: 'post',
            data: { id: installBaseID }
        };
        dispatch({
            type: types.INSTALL_DELETE_SUCCESS,
            payload: { id: installBaseID },
            meta: {
                offline: {
                    effect: { axiosOptions, message: 'delete install' },
                    rollback: {
                        type: types.INSTALL_UPDATE_SUCCESS,
                        payload: {
                            [installBaseID]: {
                                ...originalInstallBase,
                                isDeleted: false
                            }
                        }
                    }
                }
            }
        });
        document.dispatchEvent(new CustomEvent('updatedInstallBases'));
    };
}

export const moveFacilitiesAPI = async (
    newFacilityID: string,
    installBaseIDs: string[]
) => {
    beginAjaxCall();
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: {
            newFacilityID,
            installBaseIDs
        }
    };
    const url = API.POST.inventory.moveFacility;
    return msalFetch(url, axiosOptions)
        .then(data => {
            endAjaxCall();

            if (!data) {
                throw new Error('missing data');
            } else {
                return true;
            }
        })
        .catch((error: any) => {
            console.error(error);
            endAjaxCall();
            return false;
        });
};

export function installContact(
    installBaseID: string,
    facilityID: string,
    message: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        dispatch({ type: types.TOGGLE_MODAL_INSTALL_CONTACT });
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: {
                facilityID,
                installBaseID,
                message,
                beaconContactType: beaconContactTypeEnum.fse
            }
        };

        const url = API.POST.inventory.installContact;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.INSTALL_CONTACT_SUCCESS
                });
                toastr.success(
                    'Contacted Beacon',
                    'We will respond within 24 hours.',
                    constants.toastrSuccess
                );
            })
            .catch((error: AxiosError) => {
                dispatch({
                    type: types.INSTALL_CONTACT_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'contact support');
                console.error(error);
            });
    };
}

export function importInstall(file: any): ThunkResult<any> {
    return (dispatch, getState) => {
        if (!file) {
            toastr.error(
                'Error',
                'Please select a file.',
                constants.toastrError
            );
            return;
        }
        dispatch(beginAjaxCall());
        const { selectedFacilityID } = getState();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', 'file');
        // formData.append('filename', 'testfilename.xlsx');
        formData.append('facilityID', selectedFacilityID);

        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const url = API.POST.inventory.importInstall;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.IMPORT_INSTALL_SUCCESS
                });
                dispatch({ type: types.TOGGLE_MODAL_IMPORT_INSTALL });
                getInventoryHelper(
                    '',
                    () => {
                        return;
                    },
                    dispatch,
                    getState
                );
                const customMessage = (
                    <div dangerouslySetInnerHTML={{ __html: data.data }} />
                );
                if (data.status === 200) {
                    toastr.success(
                        'Upload Successful',
                        'If you do not see your assets, please refresh the page after a few moments.',
                        {
                            ...constants.toastrSuccess,
                            component: customMessage,
                            timeOut: 8000
                        }
                    );
                    // try to re-download inventory ( this does not completely work if they imported a lot)
                    setTimeout(() => {
                        getInventoryHelper(
                            '',
                            () => {
                                return;
                            },
                            dispatch,
                            getState
                        );
                    }, 10000);
                } else {
                    // status === 206
                    toastr.warning('Import Errors', '', {
                        ...constants.toastrSuccess,
                        component: customMessage,
                        timeOut: 0
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.IMPORT_INSTALL_FAILED,
                    error,
                    axiosOptions
                });
                // constants.handleError(error, 'importing');
                toastr.error(
                    'Error',
                    'Please check your email for details on the failed import.',
                    constants.toastrError
                );
                console.error(error);
            });
    };
}

export const requestQuote = ({
    message,
    facilityID
}: {
    message: string;
    facilityID: string;
}): ThunkResult<any> => {
    return (dispatch, getState) => {
        const QuoteItems = map(
            getState().manageInventory.cart.productsByID,
            (product, key) => {
                return { productID: key, quantity: product.quantity };
            }
        );
        dispatch(beginAjaxCall());
        dispatch({ type: types.TOGGLE_MODAL_SHOPPING_CART_INVENTORY });
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: { QuoteItems, facilityID, message }
        };

        const url = API.POST.inventory.quote;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.CHECKOUT_INVENTORY_SUCCESS
                });
                toastr.success(
                    'Success',
                    'requested quote',
                    constants.toastrSuccess
                );
            })
            .catch((error: any) => {
                dispatch({
                    type: types.CHECKOUT_INVENTORY_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'requesting quote');
                console.error(error);
            });
    };
};

export function mergeProduct(
    sourceProductID: string,
    targetProductID: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        dispatch({ type: types.CLOSE_ALL_MODALS });
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: { sourceProductID, targetProductID }
        };

        const url = API.POST.inventory.mergeProduct;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.PRODUCT_MERGE_SUCCESS
                });
                toastr.success(
                    'Success',
                    'merged product',
                    constants.toastrSuccess
                );
            })
            .catch((error: any) => {
                dispatch({
                    type: types.PRODUCT_MERGE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'merge product');
                Promise.reject(error);
            });
    };
}
export const updateInstallFormValues = (formValues: {
    [key: string]: any;
}) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_INVENTORY_INSTALL,
    formValues
});
export const setInstallFormValues = (formValues: { [key: string]: any }) => ({
    type: types.SET_FORM_VALUES_MANAGE_INVENTORY_INSTALL,
    formValues
});
export const toggleEditProductModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_PRODUCT
});

export const toggleEditInstallModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_INSTALL
});
export const toggleInstallContactModal = () => ({
    type: types.TOGGLE_MODAL_INSTALL_CONTACT
});
export const toggleMPResultModal = () => ({
    type: types.TOGGLE_MODAL_MP_RESULT
});

export const toggleMPResultHistory = () => ({
    type: types.TOGGLE_MODAL_MP_RESULT_HISTORY
});
export const toggleMPResultNotes = () => ({
    type: types.TOGGLE_MODAL_MP_RESULT_NOTES
});
export const toggleSearchNewProductsModal = () => ({
    type: types.TOGGLE_MODAL_SEARCH_NEW_PRODUCTS
});
export const setInstallBatchMode = (
    payload: IsearchNewProductInstallBatchMode
) => ({
    type: types.SET_INSTALL_BATCH_MODE,
    payload
});
export const toggleImportInstallModal = () => ({
    type: types.TOGGLE_MODAL_IMPORT_INSTALL
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_INVENTORY,
    filters
});

export const setProductTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_PRODUCT_TABLE_FILTER_MANAGE_INVENTORY,
    filters
});

export const updateProductSearchFormValues = (formValues: {
    [key: string]: any;
}) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH,
    formValues
});
export const setProductSearchFormValues = (formValues: {
    [key: string]: any;
}) => ({
    type: types.SET_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH,
    formValues
});

export const setSearchNewProductsSelectedProduct = (product?: Iproduct) => ({
    type: types.SET_SELECTED_PRODUCT,
    product
});
export const resetNewProducts = () => ({
    type: types.NEW_PRODUCTS_RESET
});

export const setSelectedProductID = (id: string) => ({
    type: types.SET_SELECTED_MANAGE_INVENTORY_PRODUCT_ID,
    id
});
export const clearSelectedProductID = () => ({
    type: types.CLEAR_SELECTED_MANAGE_INVENTORY_PRODUCT_ID
});
export const setSelectedInstallBaseID = (id: string) => ({
    type: types.SET_SELECTED_MANAGE_INVENTORY_INSTALL_BASE_ID,
    id
});
export const clearSelectedInstallBaseID = () => ({
    type: types.CLEAR_SELECTED_MANAGE_INVENTORY_INSTALL_BASE_ID
});

/*
 * product names are generated by: brand, category, subcategory, type, power, model, standard, brand, SKU
 */
export const createProductName = ({
    brandID,
    mainCategoryID,
    subcategoryID,
    productTypeID,
    powerID,
    systemSizeID,
    sku
}: {
    brandID: Ioption | undefined;
    mainCategoryID: Ioption | undefined;
    subcategoryID: Ioption | undefined;
    productTypeID: Ioption | undefined;
    powerID: Ioption | undefined;
    systemSizeID: Ioption | undefined;
    sku: string | undefined;
}) => {
    const category =
        mainCategoryID && mainCategoryID.label !== 'N/A'
            ? `${mainCategoryID.label}`
            : '';
    const subcategory =
        subcategoryID && subcategoryID.label !== 'N/A'
            ? `: ${subcategoryID.label}`
            : '';
    const productType =
        productTypeID && productTypeID.label !== 'N/A'
            ? `: ${productTypeID.label}`
            : '';
    const power =
        powerID && powerID.label !== 'N/A' ? `: ${powerID.label}` : '';
    const systemSize =
        systemSizeID && systemSizeID.label !== 'N/A'
            ? `: ${systemSizeID.label}`
            : '';
    const skuString = sku && sku.length ? `: ${sku}` : '';
    const brand =
        brandID && brandID.label !== 'N/A' ? `: ${brandID.label}` : '';
    const name = `${category}${subcategory}${productType}${power}${systemSize}${brand}${skuString}`;
    if (name.length > 250) {
        return name.substr(0, 250);
    } else {
        return name;
    }
};

export function bulkUpdateInstallProducts(
    targetProduct: Iproduct,
    selectedInstallIds: string[]
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch({ type: types.CLOSE_ALL_MODALS });
        const { installBasesByID } = getState().manageInventory;
        const selectedFacilityID = getState().manageFacility.selectedFacilityID;

        const installBasesToUpdate = filter(installBasesByID, install => {
            let shouldInclude = true;
            if (selectedInstallIds.indexOf(install.id) === -1) {
                shouldInclude = false;
            }
            if (install.isDeleted === true) {
                shouldInclude = false;
            }
            return shouldInclude;
        }).map(install => ({
            ...install,
            productID: targetProduct.id,
            lastQuarterlyMaintenanceDate: null,
            lastSixMonthMaintenanceDate: null,
            lastYearlyMaintenanceDate: null,
            lastTwoYearMaintenanceDate: null,
            lastThreeYearMaintenanceDate: null,
            lastFiveYearMaintenanceDate: null,
            latestWorkOrderID: install.latestWorkOrderID || null,
            latestMeasurementPointListResultID:
                install.latestMeasurementPointListResultID || null
        }));

        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: installBasesToUpdate
        };

        return msalFetch(API.PUT.inventory.updateInstalls, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (data.status === 200) {
                    toastr.success(
                        'Success',
                        'Assets have been updated',
                        constants.toastrSuccess
                    );

                    // Now update the inventory for this facility
                    getInventoryHelper(
                        selectedFacilityID,
                        () => {},
                        dispatch,
                        getState,
                        1
                    );
                } else {
                    toastr.error(
                        'Error',
                        'There was an error updating the assets',
                        constants.toastrError
                    );
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.PRODUCT_MERGE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'merge product');
                Promise.reject(error);
            });
    };
}
