/*
 * Job Work Order Expander Container
 */

import { Ijob } from '../../models';

import { RowInfo } from 'react-table';
import { connect } from 'react-redux';
import { JobWorkOrderExpander } from './JobWorkOrderExpander';
import { TFunction } from 'i18next';
import { selectWorkOrdersForJobID } from '../../reducers/manageJobReducer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops extends RowInfo {
    original: Ijob;
    t: TFunction;
    handleSelectJob: (id: string) => void;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const workOrders = selectWorkOrdersForJobID(state, {
        jobID: ownProps.original.id
    });
    return {
        loading: selectIsLoading(state),
        workOrders
    };
};

export const JobWorkOrderExpanderContainer = connect(
    mapStateToProps,
    {}
)(JobWorkOrderExpander);
