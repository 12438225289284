import { map, omit, keyBy } from 'lodash';

import { IuserQueue, ImanageUserQueueReducer } from '../models';
import { createTableFiltersWithName } from './commonReducers';
import initialState, { initialUserQueue } from './initialState';
import * as types from '../actions/actionTypes';
import { cleanUser } from './manageUserReducer';
import { combineReducers } from 'redux';

/*
 *********** SELECTORS
 */

/*
 ************ REDUCERS
 */

/*
 * stores the user currently being edited
 */
const selectedUserQueuReducer = (
    state = initialUserQueue,
    action: any
): IuserQueue => {
    switch (action.type) {
        case types.USER_QUEUE_UPDATE_SELECTED: {
            return action.payload;
        }
        case types.USER_QUEUE_SAVE_SUCCESS: {
            return initialUserQueue;
        }
        default:
            return state;
    }
};

function userQueuesByID(
    state: { [key: string]: IuserQueue } = initialState.manageUserQueue
        .userQueuesByID,
    action: any
): { [key: string]: IuserQueue } {
    switch (action.type) {
        case types.USER_QUEUE_SUCCESS: {
            const newQueue = map(action.queue, queueObject => {
                return {
                    id: queueObject.id,
                    user: cleanUser(queueObject.user)
                };
            });
            return keyBy(newQueue, 'id');
        }
        case types.USER_QUEUE_SAVE_SUCCESS: {
            const newQueueObject = {
                id: action.queueID,
                user: cleanUser(action.user)
            };

            return { ...state, [action.queueID]: newQueueObject };
        }
        case types.USER_APPROVE_SUCCESS:
            return omit(state, action.userQueueID);
        case types.USER_REJECT_SUCCESS:
            return omit(state, action.userQueueID);

        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageUserQueue.userQueuesByID;
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.USER_QUEUE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

const userQueue = combineReducers<ImanageUserQueueReducer>({
    userQueuesByID,
    totalPages,
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_USER_QUEUE'),
    selectedUserQueue: selectedUserQueuReducer
});

export default userQueue;
