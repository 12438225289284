/*
 * EditJobForm
 */

import * as React from 'react';

import { debounce, filter, find, map, omit } from 'lodash';
import { Button, Col } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    Validators
} from 'react-reactive-form';
import {
    jobTypesIdEnum,
    jobTypesIdEnumForSelect,
    jobTypesIdEnumInverse
} from '../../models-enums';

import moment from 'moment';
import { WithTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import {
    FacilitiesSearchParams,
    searchFacilitiesForAsyncSelect
} from '../../actions/commonActions';
import {
    getAllOpenJobsForWorkOrders,
    //getAllOpenRepairAndMaintenanceJobs,
    getNextJobNumber,
    updateSelectedJob
} from '../../actions/manageJobActions';
import { searchPartsForAsyncSelect } from '../../actions/managePartActions';
import { constants } from '../../constants/constants';
import {
    IfacilityWithoutBuildings,
    Ihistory,
    Ijob,
    IjobPart,
    IjobPartPopulated,
    Ioption,
    Iuser,
    IuserJob
} from '../../models';
import { IgenericFormValues } from '../../modelsForms';
import { convertFacilityOptions } from '../../reducers/facilitiesReducer';
import { initialJob, initialJobPart } from '../../reducers/initialState';
import { AddMultiControl, ImultiControlMeta } from '../common/AddMultiControl';
import { FormUtil } from '../common/FormUtil';
import { manageJobQueryParamsEnum } from './ManageJob';
const uuidv4 = require('uuid/v4');

interface Iprops {
    // reusable
    loading: boolean;
    showCondensedFields: boolean;
    selectedJob: Ijob;
    originalJob?: Ijob;
    show: boolean;
    nextJobNumber: string;
    onSubmit: (
        job: Ijob,
        jobParts: IjobPart[],
        isEditMode: boolean,
        activeCountryID: string
    ) => Promise<void>;
    delete: (jobID: string) => Promise<void>;
    closeModal: () => void;
    onHide: () => void;
    // other
    facilityOptions: Ioption[];
    fseOptions: Ioption[];
    fseUsersByID: { [key: string]: Iuser };
    getFacilitiesByCountry: (params: FacilitiesSearchParams) => Promise<void>;
    jobParts: IjobPartPopulated[];
    getJobParts: () => void;
    facilities: { [key: string]: IfacilityWithoutBuildings };
    colorButton: string;
    queryParams: typeof manageJobQueryParamsEnum;
    onChange: typeof updateSelectedJob;
    getNextJobNumber: typeof getNextJobNumber;
    history: Ihistory;
    user: Iuser;
    isEditMode: boolean;
    searchFacilitiesForAsyncSelect: typeof searchFacilitiesForAsyncSelect;
    activeLocalCountry: string;
    getAllOpenJobsForWorkOrders: typeof getAllOpenJobsForWorkOrders;
}

interface Istate {
    selectedPart?: any;
    fieldConfig: FieldConfig;
}

class EditJobForm extends React.Component<Iprops & WithTranslation, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private onChangeDebounced: (job?: Ijob) => void;
    static defaultProps = {
        selectedJob: initialJob
    };
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.onChangeDebounced = debounce(
            this.props.onChange,
            constants.formDebounceTime
        );
        this.state = {
            selectedPart: undefined,
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        this.initSelected();
        this.props.getNextJobNumber();
    }

    componentDidUpdate(prevProps: Iprops & WithTranslation) {
        if (
            JSON.stringify(prevProps.facilityOptions) !==
            JSON.stringify(this.props.facilityOptions)
        ) {
            FormUtil.patchControl(this.formGroup, 'facilityID', null, {
                options: this.props.facilityOptions
            });
        }
        if (this.props.selectedJob.id !== prevProps.selectedJob.id) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
        if (
            JSON.stringify(prevProps.jobParts) !==
            JSON.stringify(this.props.jobParts)
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
        if (this.props.nextJobNumber !== prevProps.nextJobNumber) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.onChange();
        this.props.closeModal();
    }

    /*
     * initSelected
     * if there is a query param, check if it matches the selectedItem in redux
     * if there is a job we are editing download any dependencies
     */
    initSelected = () => {
        if (
            this.props.queryParams.selectedJobID &&
            this.props.queryParams.selectedJobID !== this.props.selectedJob.id
        ) {
            const { originalJob, isEditMode } = this.props;
            // is there an existing item from redux
            if (originalJob && isEditMode) {
                this.props.onChange(originalJob);
                this.props.getJobParts();
            } else {
                // the item we are trying to edit has not been loaded.  TODO download this item
                // since we do not support downloading a single item yet, close the modal
                console.info(
                    'item has not been downloaded.  closing modal',
                    this.props.queryParams.selectedJobID
                );
                this.props.closeModal();
            }
        }
    };

    /*
     * itemToFormValues - take the selectedObject and convert it to formValues
     */
    itemToFormValues = (): IgenericFormValues<Ijob> => {
        let {
            facilityID,
            jobTypeID,
            assignedUserID,
            userJobs,
            endDate,
            startDate,
            fseNotes,
            jobNumber
        } = this.props.selectedJob;
        const selectedFacility = FormUtil.convertToSingleOption(
            this.props.facilities[facilityID]
        );
        const loadJobNumber = jobNumber || this.props.nextJobNumber;

        const selectedJobType = jobTypeID
            ? {
                  value: jobTypeID,
                  label: this.props.t(
                      jobTypesIdEnumInverse[
                          jobTypeID as keyof typeof jobTypesIdEnumInverse
                      ]
                  )
              }
            : undefined;

        const selectedAssignedUser = FormUtil.convertToSingleOption(
            this.props.fseUsersByID[assignedUserID]
        );

        const selectedUsers = filter(this.props.fseOptions, (fac: any) => {
            return (
                find(
                    userJobs,
                    userJob =>
                        userJob.userID === fac.value &&
                        userJob.isDeleted === false
                ) !== undefined
            );
        });

        if (!startDate) {
            startDate = moment.utc().format(constants.momentDisplayFormat);
        }

        if (!endDate) {
            endDate = moment.utc().format(constants.momentDisplayFormat);
        }

        return {
            jobNumber: loadJobNumber,
            facilityID: selectedFacility,
            jobTypeID: selectedJobType,
            userJobs: selectedUsers,
            assignedUserID: selectedAssignedUser,
            startDate,
            endDate,
            fseNotes
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedObject
     */
    formValuesToItem = (): Ijob => {
        const formValues = FormUtil.getValues(this.formGroup.value);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { manageParts, ...formValuesWithoutParts } = formValues;
        let userJobs: IuserJob[] = [];
        if (Array.isArray(formValues.userJobs)) {
            userJobs = formValues.userJobs.map((userID: string) => {
                return {
                    id: '', // let the API define the id
                    userID,
                    jobID: this.props.selectedJob.id,
                    isDeleted: false
                };
            });
        }
        const cleanedJob = omit(this.props.selectedJob, ['facility']);
        return {
            ...cleanedJob,
            assignedUserID: cleanedJob?.assignedUserID || (null as any),
            ...formValuesWithoutParts,
            userJobs
        };
    };

    formValuesToChildItems = (): { jobParts: IjobPart[] } => {
        let jobParts: IjobPart[] = [];
        if (Array.isArray(this.formGroup.value.manageParts)) {
            jobParts = this.formGroup.value.manageParts.map(
                ({ id, part, estimated, isDeleted }: any) => {
                    return {
                        ...initialJobPart,
                        id: id || uuidv4(),
                        partID: part.value,
                        estimated: Number(estimated),
                        used: 0,
                        isDeleted,
                        jobID: this.props.selectedJob.id
                    };
                }
            );
        }
        return { jobParts };
    };

    loadPartsOptions = debounce((searchTerm, callback) => {
        searchPartsForAsyncSelect(searchTerm).then(results =>
            callback(results)
        );
    }, 1000);

    onMultiControlChanges = (item: any, multiControlFormGroup?: any) => {
        if (
            (!this.state.selectedPart && item) ||
            (this.state.selectedPart &&
                JSON.stringify(item) !==
                    JSON.stringify(this.state.selectedPart))
        ) {
            this.setState({ selectedPart: item });
        }
    };

    buildMultiControlConfig = () => {
        const itemEditFieldConfig = {
            controls: {
                part: {
                    options: {
                        validators: [Validators.required]
                    },
                    render: FormUtil.AsyncSelect,
                    meta: {
                        label: this.props.t('jobForm.part'),
                        colWidth: 12,
                        rows: 2,
                        loadOptions: this.loadPartsOptions,
                        name: 'part',
                        required: true,
                        isMulti: false
                    }
                },
                estimated: {
                    options: {
                        validators: [
                            Validators.min(1),
                            Validators.max(1000),
                            Validators.pattern('[0-9]+'),
                            FormUtil.validators.requiredWithTrim
                        ]
                    },
                    render: FormUtil.TextInput,
                    meta: {
                        label: 'jobForm.quantity',
                        type: 'number',
                        colWidth: 6,
                        required: true,
                        name: 'jobForm.quantity'
                    }
                }
            }
        };

        return itemEditFieldConfig;
    };

    saveFacilities = (facilities: IfacilityWithoutBuildings[]) => {
        // console.log(facilities)
    };

    loadFacilitiesOptions = debounce((searchTerm, callback) => {
        if (searchTerm.length >= 3) {
            this.props.searchFacilitiesForAsyncSelect(
                searchTerm,
                this.props.user.countryID,
                null,
                callback,
                this.saveFacilities
            );
            return;
        }
        callback(convertFacilityOptions(Object.values(this.props.facilities)));
    }, 100);

    buildFieldConfig = (defaultValues = this.itemToFormValues()) => {
        const { fseOptions, jobParts, t } = this.props;
        const disabled = this.props.selectedJob.isDeleted || false;
        const { startDate, endDate } = defaultValues;
        const itemEditFieldConfig = this.buildMultiControlConfig();
        const selectedParts = map(jobParts, part => {
            return {
                ...part,
                part: {
                    value: part.part.id,
                    label: `${part.part.number} - ${part.part.description}`
                },
                estimated: part.estimated
            };
        });
        // Field config to configure form
        let fieldConfigControls: { [key: string]: any } = {
            facilityID: {
                render: FormUtil.AsyncSelect,
                meta: {
                    label: 'jobForm.facility',
                    loadOptions: (searchTerm: string, callback: any) => {
                        this.loadFacilitiesOptions(searchTerm, callback);
                    },
                    colWidth: 12,
                    placeholder: 'jobForm.facilitySearchPlaceholder',
                    name: 'facility'
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: defaultValues.facilityID,
                    disabled
                }
            },
            jobNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'jobForm.jobNumber',
                    colWidth: 12,
                    type: 'input',
                    name: 'jobnumber',
                    required: false
                },
                formState: { value: defaultValues.jobNumber, disabled }
            },
            jobTypeID: {
                render: FormUtil.Select,
                meta: {
                    options: this.props.isEditMode
                        ? FormUtil.convertEnumToOptions(jobTypesIdEnum)
                        : FormUtil.convertEnumToOptions(
                              jobTypesIdEnumForSelect
                          ),
                    label: 'jobForm.type',
                    colWidth: 12,
                    placeholder: 'jobForm.typeSearchPlaceholder',
                    name: 'job-type',
                    shouldTranslate: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: defaultValues.jobTypeID,
                    disabled: this.props.isEditMode
                }
            },
            startDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'jobForm.startDate',
                    colWidth: 12,
                    showTime: false,
                    name: 'start-date',
                    placeholder: 'jobForm.startDatePlaceholder'
                },
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        FormUtil.validators.isValidMoment
                    ]
                },
                formState: {
                    value: startDate,
                    disabled
                }
            },
            endDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'jobForm.endDate',
                    colWidth: 12,
                    showTime: false,
                    name: 'end-date',
                    placeholder: 'jobForm.endDatePlaceholder'
                },
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        FormUtil.validators.isValidMoment
                    ]
                },
                formState: {
                    value: endDate,
                    disabled
                }
            }
        };
        if (!this.props.showCondensedFields) {
            fieldConfigControls = {
                ...fieldConfigControls,
                assignedUserID: {
                    render: FormUtil.Select,
                    meta: {
                        options: fseOptions,
                        label: 'jobForm.fseLead',
                        colWidth: 12,
                        placeholder: 'jobForm.fseLeadSearchPlaceholder',
                        name: 'assigned-lead-user'
                    },
                    options: {
                        validators: FormUtil.validators.requiredWithTrim
                    },
                    formState: {
                        value: defaultValues.assignedUserID,
                        disabled
                    }
                },
                userJobs: {
                    render: FormUtil.Select,
                    meta: {
                        options: fseOptions,
                        label: 'jobForm.fseMembers',
                        colWidth: 12,
                        placeholder: 'jobForm.fseMembersSearchPlaceholder',
                        isMulti: true,
                        name: 'assigned-user',
                        required: false
                    },
                    formState: {
                        value: defaultValues.userJobs,
                        disabled
                    }
                },
                manageParts: {
                    render: AddMultiControl,
                    meta: {
                        label: 'jobForm.parts',
                        buttonLabel: t('jobForm.manageParts'),
                        colWidth: 12,
                        colorButton: this.props.colorButton,
                        t,
                        modalTitle: 'jobForm.manageParts',
                        getItemTitle: item =>
                            `(${item.estimated}) ${item.part.label}`,
                        onValueChanges: this.onMultiControlChanges,
                        disableDelete:
                            constants.hasSecurityFunction(this.props.user, [
                                constants.securityFunctions.ManageProducts.id
                            ]) === false,
                        disableEdit:
                            constants.hasSecurityFunction(this.props.user, [
                                constants.securityFunctions.ManageProducts.id
                            ]) === false,
                        actions: [],
                        itemEditFieldConfig
                    } as ImultiControlMeta,
                    formState: { value: selectedParts, disabled }
                },
                fseNotes: {
                    render: FormUtil.TextInput,
                    meta: {
                        label: 'fseNotes',
                        colWidth: 12,
                        rows: 9,
                        componentClass: 'textarea',
                        name: 'fse-notes'
                    },
                    formState: {
                        value: defaultValues.fseNotes,
                        disabled
                    }
                }
            };
        }
        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes after a short delay that allows the initial form values to load
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the values to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'startDate': {
                this.checkIfStartDateBeforeEndDate({ startDate: value });
                if (moment.isMoment(value)) {
                    value = value.format(constants.momentSQLFormat);
                }
            }
            case 'endDate': {
                this.checkIfStartDateBeforeEndDate({ endDate: value });
                if (moment.isMoment(value)) {
                    value = value.format(constants.momentSQLFormat);
                }
            }
            case 'facilityID':
                if (value && value.value) {
                    this.handleSelectingFacility(value.value);
                }
            default:
                this.onChangeDebounced(this.formValuesToItem());
                break;
        }
    };

    /*
     * handleSelectingFacility
     * automatically select the lead FSE if one is set on the facility
     */
    handleSelectingFacility = (facilityID: string) => {
        const facility = this.props.facilities[facilityID];
        if (facility && facility.leadFSEID) {
            const leadFSEOption = find(this.props.fseOptions, {
                value: facility.leadFSEID
            });

            if (leadFSEOption && !this.props.selectedJob.assignedUserID) {
                FormUtil.patchControl(
                    this.formGroup,
                    'assignedUserID',
                    leadFSEOption
                );
            }
        }
    };

    /*
     * Check if the date is in the past or if the start is before the end date
     */
    checkIfStartDateBeforeEndDate = ({ startDate, endDate }: any) => {
        if (
            startDate &&
            moment.isMoment(startDate) &&
            this.formGroup.value.endDate
        ) {
            if (startDate.isAfter(this.formGroup.value.endDate)) {
                toastr.warning(
                    this.props.t('jobForm.startDateWarning'),
                    '',
                    constants.toastrWarning
                );
                const startDateControl = this.formGroup.get('startDate');
                startDateControl.setErrors({ beforeStart: true });
            } else if (startDate.isBefore(moment(), 'day')) {
                toastr.warning(
                    this.props.t('common:warning'),
                    this.props.t('jobForm.pastDateWarning'),
                    constants.toastrWarning
                );
            } else {
                const startDateControl = this.formGroup.get('startDate');
                startDateControl.setErrors(null);
                const endDateControl = this.formGroup.get('endDate');
                endDateControl.setErrors(null);
            }
        } else if (endDate && moment.isMoment(endDate)) {
            if (endDate.isBefore(this.formGroup.value.startDate)) {
                toastr.warning(
                    this.props.t('common:warning'),
                    this.props.t('jobForm.startDateWarning'),
                    constants.toastrWarning
                );
                const endDateControl = this.formGroup.get('endDate');
                endDateControl.setErrors({ beforeStart: true });
            } else if (endDate.isBefore(moment(), 'day')) {
                toastr.warning(
                    this.props.t('common:warning'),
                    this.props.t('jobForm.pastDateWarning'),
                    constants.toastrWarning
                );
            } else {
                const endDateControl = this.formGroup.get('endDate');
                endDateControl.setErrors(null);
                const startDateControl = this.formGroup.get('startDate');
                startDateControl.setErrors(null);
            }
        } else {
            console.error('missing start and end date');
        }
    };

    onSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        console.info(this.formGroup.value);

        this.props
            .onSubmit(
                this.formValuesToItem(),
                this.formValuesToChildItems().jobParts,
                this.props.isEditMode,
                this.props.activeLocalCountry
            )
            .then(() => {
                this.props.getAllOpenJobsForWorkOrders();
                this.props.closeModal();
            });
    };

    onDelete = () => {
        const { t } = this.props;
        const toastrConfirmOptions = {
            onOk: () => {
                this.props.delete(this.props.selectedJob.id).then(() => {
                    this.props.closeModal();
                });
            },
            onCancel: () => console.info('CANCEL: clicked'),
            okText: t('jobDeleteOk'),
            cancelText: t('common:cancel')
        };
        toastr.confirm(t('jobDeleteConfirm'), toastrConfirmOptions);
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;
        const deleteButtonStyle = this.props.isEditMode
            ? { marginRight: '15px' }
            : { marginRight: '15px', display: 'none' };

        return (
            <form onSubmit={this.onSubmit} className={formClassName}>
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.props.closeModal}
                    >
                        {t('jobForm.cancel')}
                    </Button>
                    {!this.props.selectedJob.isDeleted && (
                        <Button
                            bsStyle="warning"
                            style={deleteButtonStyle}
                            type="button"
                            className=""
                            disabled={this.props.loading}
                            onClick={this.onDelete}
                        >
                            {t('jobForm.delete')}
                        </Button>
                    )}
                    {!this.props.selectedJob.isDeleted && (
                        <Button
                            bsStyle={this.props.colorButton}
                            type="submit"
                            disabled={this.props.loading}
                        >
                            {t('jobForm.save')}
                        </Button>
                    )}
                </Col>
            </form>
        );
    }
}
export default EditJobForm;
