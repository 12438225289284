/*
 * Quote Analytics Container
 */

import QuoteAnalytics from './QuoteAnalytics';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import { withTranslation, WithTranslation } from 'react-i18next';
import { constants } from '../../constants/constants';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

const mapStateToProps = (
    state: IinitialState,
    ownProps: RouteComponentProps<{}> & WithTranslation
) => {
    const { user } = state;
    const query = queryString.parse(ownProps.location.search);

    const showUnquotedLeads = constants.hasSecurityFunction(user, [
        constants.securityFunctions.SalesAdmin.id,
        constants.securityFunctions.SalesInstallation.id,
        constants.securityFunctions.SalesManager.id,
        constants.securityFunctions.SalesParts.id,
        constants.securityFunctions.SalesService.id
    ]);
    return {
        loading: selectIsLoading(state),
        isDemoMode: query.demo === 'true',
        showUnquotedLeads,
        user: state.user
    };
};

export const QuoteAnalyticsContainer = withTranslation('manageQuotes')(
    withRouter(connect(mapStateToProps, {})(QuoteAnalytics))
);
