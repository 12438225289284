import * as React from 'react';
import { FormGroup, ControlLabel, Button, Col } from 'react-bootstrap';
import { FormUtil } from './FormUtil';

// interface Imeta extends IbaseFormMeta {
//   toggleModal: () => void;
//   t: TFunction;
//   buttonLabel: string;
//   colorButton: string;
// }
interface Iprops {
    meta: any;
    handler: any;
    pristine: boolean;
    errors: any;
    submitted: boolean;
}

export const FacilityContactControl = (props: Iprops) => {
    const addPart = () => {
        props.meta.toggleModal();
    };

    const { pristine, errors, submitted } = props;
    return props.meta.remove ? null : (
        <Col xs={props.meta.colWidth} style={{}}>
            <FormGroup
                validationState={FormUtil.getValidationState(
                    pristine,
                    errors,
                    submitted
                )}
                style={{ textAlign: 'right' }}
            >
                <ControlLabel>{props.meta.label}</ControlLabel>

                <Button
                    onClick={addPart}
                    bsStyle="default"
                    style={{
                        marginTop: props.meta.noMargin
                            ? ''
                            : props.meta.label
                            ? ''
                            : '20px'
                    }}
                    disabled={props.handler().disabled}
                >
                    {props.meta.buttonLabel}
                </Button>
            </FormGroup>
        </Col>
    );
};
