import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { Ibrand, ItableFiltersReducer, Itile } from '../../models';
import ReactTable, { FinalState, RowInfo } from 'react-table';
import {
    clearSelectedBrandID,
    deleteBrand,
    getBrands,
    setSelectedBrandID,
    setTableFilter,
    toggleEditBrandModal,
    updateSelectedBrand
} from '../../actions/manageBrandActions';
import queryString from 'query-string';
import { debounce, orderBy } from 'lodash';

import { Banner } from '../common/Banner';
import { Button } from 'react-bootstrap';
import EditBrandModal from './EditBrandModal';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import { connect } from 'react-redux';
import { addQuery } from '../common/OtherUtils';
import { constants } from '../../constants/constants';
import { emptyTile, initialBrand } from '../../reducers/initialState';
import { closeAllModals } from '../../actions/commonActions';
import { IinitialState } from '../../reducers';

const uuidv4 = require('uuid/v4');

interface RowInfoBrand extends RowInfo {
    original: Ibrand;
}

export enum manageBrandQueryParamsEnum {
    selectedBrandID = 'selectedBrandID'
}

type Iprops = RouteComponentProps<any>;

interface IdispatchProps {
    tableData: Ibrand[];
    totalPages: number;
    showEditBrandModal: boolean;
    getBrands: any;
    toggleEditBrandModal: typeof toggleEditBrandModal;
    deleteBrand: typeof deleteBrand;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    loading: boolean;
    setSelectedBrandID: typeof setSelectedBrandID;
    clearSelectedBrandID: typeof clearSelectedBrandID;
    closeAllModals: typeof closeAllModals;
    queryParams: typeof manageBrandQueryParamsEnum;
    updateSelectedBrand: typeof updateSelectedBrand;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
    searchFieldConfig: FieldConfig;
}

class ManageBrand extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    private setTableFilterDebounced: (formValues: {
        [key: string]: any;
    }) => void;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.setTableFilterDebounced = debounce(
            this.props.setTableFilter,
            constants.formDebounceTime
        );

        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            columns: [],
            searchFieldConfig: this.buildSearchControls()
        };
    }

    componentDidMount(): void {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.setColumns();
        this.props.getBrands();
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditBrandModal !== this.props.showEditBrandModal &&
            !this.props.showEditBrandModal
        ) {
            this.setState({ selectedRow: null });
        }
        // automatically get inventory every time a fitler changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.getBrands();
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
    }
    componentWillUnmount() {
        this.props.setTableFilter({ name: '', page: 1 }); // reset table filters when you leave the page
        this.props.closeAllModals();
    }

    buildSearchControls = (): FieldConfig => {
        const mainSearchControls = {
            name: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:Brand',
                    colWidth: 3,
                    type: 'text',
                    placeholder: 'Search by text',
                    defaultValue: this.props.tableFilters.name,
                    isClearable: true
                },
                formState: {
                    value: this.props.tableFilters.name,
                    disabled: false
                }
            }
        };

        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    };

    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'name':
                this.setTableFilterDebounced({ name: value, page: 1 });
                break;
            default:
                break;
        }
    };

    handleSelectBrand = (id: string) => {
        addQuery(
            manageBrandQueryParamsEnum.selectedBrandID,
            id,
            this.props.history
        );
    };

    /**
     * create an empty brand with a new ID so that the ID can go in the query param which will cause the modal to open
     */
    handleNewBrandClick = () => {
        const id = uuidv4();
        this.props.updateSelectedBrand({ ...initialBrand, id });
        addQuery(
            manageBrandQueryParamsEnum.selectedBrandID,
            id,
            this.props.history
        );
    };

    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };
    /*
     * Set Columns sets columns to state
     */
    setColumns = () => {
        const columns = TableUtil.translateHeaders(
            [
                {
                    Header: 'name',
                    accessor: 'name',
                    minWidth: 300
                }
            ],
            this.props.t
        );
        this.setState({ columns });
    };

    /*
     * Handle user clicking on a location row
     * set the selected location to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfoBrand | undefined) => {
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    this.setState({ selectedRow: rowInfo.index });
                    /*
                    this.props.toggleEditBrandModal();
                    this.props.setSelectedBrandID(rowInfo.original.id);
                    */
                    this.handleSelectBrand(rowInfo.original.id);
                    return;
                },
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };

    render() {
        const { t, tableData = [], totalPages } = this.props;
        return (
            <div className="manage-brand">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                />
                <SearchTableForm
                    fieldConfig={this.state.searchFieldConfig}
                    handleSubmit={this.props.getBrands}
                    loading={this.props.loading}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                    t={this.props.t}
                    showSearchButton={false}
                />
                <div>
                    <Button
                        className="table-add-button"
                        bsStyle="link"
                        onClick={this.handleNewBrandClick}
                    >
                        {t(`manageBrand:newBrand`)}
                    </Button>
                </div>
                <ReactTable
                    data={tableData}
                    columns={this.state.columns}
                    getTrProps={this.getTrProps}
                    pageSize={tableData.length >= 10 ? tableData.length : 10}
                    manual
                    pages={totalPages}
                    page={this.props.tableFilters.page - 1}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    multiSort={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                />

                <EditBrandModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    history={this.props.history}
                    queryParams={this.props.queryParams}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const tableData = orderBy(
        state.manageBrand.manageBrandsByID,
        res => res.name,
        'asc'
    );
    const queryParams = queryString.parse(
        ownProps.location.search
    ) as typeof manageBrandQueryParamsEnum;
    return {
        tableData,
        totalPages: state.manageBrand.totalPages,
        showEditBrandModal: queryParams.selectedBrandID?.length > 0,
        tableFilters: state.manageBrand.tableFilters,
        queryParams
    };
};

export default withTranslation('manageBrand')(
    connect(mapStateToProps, {
        getBrands,
        toggleEditBrandModal,
        deleteBrand,
        setTableFilter,
        setSelectedBrandID,
        clearSelectedBrandID,
        closeAllModals,
        updateSelectedBrand
    })(ManageBrand)
);
