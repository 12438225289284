import * as React from 'react';
import { TFunction } from 'i18next';
import { Banner } from '../../common/Banner';
import { SelectFacilityContainer } from '../../common/SelectFacilityContainer';
import { IcloudDocument, ItableFiltersReducer, Itile } from '../../../models';
import { emptyTile } from '../../../reducers/initialState';
import ReactTable, { Column, FinalState } from 'react-table';
import { TableUtil } from '../../common/TableUtil';
import { setTableFilter } from '../../../actions/manageDocumentActions';
import { Button } from 'react-bootstrap';
import { RowInfoDocument } from '../ManageDocumentLibrary';

interface Iprops {
    t: TFunction;
    currentTile: Itile;
    selectedCloudDocument: IcloudDocument;
    totalPages: number;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    documentTypeOptions: { [key: string]: any };
    goBack: () => void;
    newVersion: (parentDocumentID: string) => void;
    editDoc: (documentID: string) => void;
    downloadDocument: (selectedCloudDocument: IcloudDocument) => void;
}

interface Istate {
    selectedRow: any;
    tableData: any[];
    columns: any[];
}

const ManageDocumentVersions: React.FC<Iprops> = (props: Iprops) => {
    const { t, selectedCloudDocument } = props;

    const mobileColumns = TableUtil.translateHeaders(
        [
            {
                Header: 'name',
                id: 'name',
                accessor: ({ name }: IcloudDocument) => {
                    return (
                        <>
                            <span className="mobile-label">NAME</span>
                            <span
                                style={{ color: '#007BC3' }}
                                className="mobile-description"
                            >
                                {name}
                            </span>
                        </>
                    );
                }
            },
            {
                Header: 'createDate',
                accessor: ({ createDate }: IcloudDocument) => {
                    return (
                        <>
                            <span className="mobile-label">DATE</span>
                            <span className="mobile-description">
                                {createDate}
                            </span>
                        </>
                    );
                },
                id: 'dcreateDateate'
            }
        ],
        props.t
    );

    const [state, setState] = React.useState<Istate>({
        selectedRow: {},
        tableData: selectedCloudDocument.versions
            ? selectedCloudDocument.versions
            : [],
        columns: mobileColumns
    });

    const onPageChange = (page: number) => {
        const newPage = page + 1;
        props.setTableFilter({ page: newPage });
    };

    const getDocumentType = () => {
        const id = props.selectedCloudDocument.cloudDocumentTypeID;

        if (id === undefined || id === null || id === '') {
            return '';
        }

        if (props.documentTypeOptions && props.documentTypeOptions[id]) {
            return props.documentTypeOptions[id].name;
        } else {
            return '';
        }
    };

    const getTdProps = (
        finalState: FinalState,
        rowInfo: RowInfoDocument | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo) {
            return {
                onClick: () => {
                    props.editDoc(rowInfo.original.id);
                }
            };
        }

        return {};
    };

    return (
        <div className="manage-document-versions">
            <Banner
                title={props.t('bannerTitle')}
                img={props.currentTile.srcBanner}
                color={props.currentTile.color}
            >
                <SelectFacilityContainer t={props.t} classNameOverride={''} />
            </Banner>

            <div className="manage-version-container">
                <Button
                    className="close-button"
                    onClick={() => {
                        props.goBack();
                    }}
                >
                    {t(`measurementPoints:close`)}
                </Button>
                <Button
                    className="new-version-button"
                    bsStyle={'primary'}
                    onClick={() => {
                        props.newVersion(props.selectedCloudDocument.id);
                    }}
                >
                    {t(`manageDocument:newVersion`)}
                </Button>
            </div>

            <div className="download-doc-container">
                <Button
                    className="download-version-button"
                    onClick={() => {
                        props.downloadDocument(props.selectedCloudDocument);
                    }}
                >
                    Download
                </Button>
            </div>

            <div className="document-version-container">
                <span className="doc-name">
                    {props.selectedCloudDocument.name}
                </span>
                <span className="doc-date">{getDocumentType()}</span>
            </div>

            <ReactTable
                data={state.tableData}
                columns={state.columns}
                getTdProps={getTdProps}
                pageSize={
                    state.tableData.length >= 10 ? state.tableData.length : 10
                }
                pages={props.totalPages}
                page={props.tableFilters.page - 1}
                showPageSizeOptions={false}
                className={`beacon-table -highlight ${props.currentTile.color}`}
                previousText={t('common:previous')}
                nextText={t('common:next')}
                onPageChange={onPageChange}
                sortable={false}
                multiSort={false}
                noDataText={t('common:noDataText')}
                resizable={false}
            />
        </div>
    );
};

export default ManageDocumentVersions;
