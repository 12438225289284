/*
 * Dashboard Analytics Container
 */

import { DashboardAnalyticsLayout } from './DashboardAnalyticsLayout';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { initialFacility } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { initInventory } from '../../actions/manageInventoryActions';
import { IdashboardAnalyticsData } from '../../models';

interface Iprops extends RouteComponentProps<{}> {
    t: TFunction;
    dashboardAnalytics: IdashboardAnalyticsData;
    showAnalytics?: boolean;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const { selectedFacilityID, user, productInfo, facilities } = state;
    const query = queryString.parse(ownProps.location.search);

    return {
        selectedFacilityID,
        user,
        loading: selectIsLoading(state),
        mainCategories: productInfo.mainCategories,
        mainCategoryOptions: productInfo.mainCategoryOptions,
        selectedFacility: facilities[selectedFacilityID] || initialFacility,
        isDemoMode: query.demo === 'true'
    };
};

export const DashboardAnalyticsContainer = connect(mapStateToProps, {
    initInventory
})(DashboardAnalyticsLayout);
