/*
 *
 */

import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { forEach } from 'lodash';
import {
    addWorkOrdersToJob,
    updateSelectedJob
} from '../../actions/manageJobActions';
import {
    getWorkOrders,
    toggleConfirmSelectJobModal
} from '../../actions/manageWorkOrderActions';
import { Ifacility, Ijob } from '../../models';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import {
    prepJobsOpenRepairAndMaintenanceForOptions,
    selectOpenJobsForWorkOrders
} from '../../reducers/manageJobReducer';
import { WithCommonModal } from '../common/WithCommonModal';
import { ConfirmSelectJobForm } from './ConfirmSelectJobForm';
import { getJobTypeOptionsForWorkOrder } from '../../helpers/sapManager';

interface Iprops {
    colorButton: string;
    history: any;
    showNewJobModal: () => void;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const filteredJobs = selectOpenJobsForWorkOrders(state);
    const workOrders = Object.values(state.manageWorkOrder.workOrdersByID);
    let filteredJobTypes: string[] = [];
    let facility: Ifacility | undefined = undefined;
    const sapJobMappings = state.sapJobMappings.sapJobMappingsByID;

    forEach(state.manageWorkOrder.selection, (id: string) => {
        const workOrderId = id.substring(7); // remove the select- prefix
        const wo = workOrders.find(wo => wo.id === workOrderId);
        if (wo) {
            if (wo.facility) {
                facility = wo.facility;
            }

            const jobTypeOptions = getJobTypeOptionsForWorkOrder(
                wo,
                sapJobMappings,
                ownProps.t
            );

            if (jobTypeOptions.jobTypes.length > 0) {
                filteredJobTypes = jobTypeOptions.jobTypes.map(j => j.value);
            }
        }
    });

    let filteredJobsBySAPBusinessIndicator: Ijob[] = [];

    // Filter open jobs by the selected job types
    filteredJobsBySAPBusinessIndicator = filteredJobs.filter(job =>
        filteredJobTypes.includes(job.jobTypeID)
    );

    // We should have a facility from the selected work orders (and it should be the same for all selected work orders)
    if (facility) {
        filteredJobsBySAPBusinessIndicator = filteredJobsBySAPBusinessIndicator.filter(
            job => job.facilityID.toLowerCase() === facility?.id.toLowerCase()
        );
    }

    const jobOptions = prepJobsOpenRepairAndMaintenanceForOptions(
        state,
        filteredJobsBySAPBusinessIndicator,
        ownProps.t
    );

    return {
        title: ownProps.t('selectJob'),
        className: 'confirm-select-modal',
        loading: selectIsLoading(state),
        show: state.manageWorkOrder.showConfirmSelectJobModal,
        jobOptions,
        selection: state.manageWorkOrder.selection,
        showNewJobModal: ownProps.showNewJobModal
    };
};

export const ConfirmSelectJobContainer = withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        onHide: toggleConfirmSelectJobModal,
        toggleModal: toggleConfirmSelectJobModal,
        addWorkOrdersToJob,
        getWorkOrders,
        updateSelectedJob
    })(WithCommonModal(ConfirmSelectJobForm))
);
