/*
 * Edit Job Redux Connector
 */

import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    addWorkOrdersToJob,
    getNextJobNumber,
    saveJob
} from '../../../actions/manageJobActions';
import { IinitialState } from '../../../reducers';
import { selectIsLoading } from '../../../reducers/commonReducers';
import {
    selectActiveFseUsersByID,
    selectNextJobNumber
} from '../../../reducers/manageJobReducer';

import NewJobForm from './NewJobForm';
import { initialFacility } from '../../../reducers/initialState';
import { forEach } from 'lodash';
import {
    IjobTypeOptionsForWorkOrder,
    getJobTypeOptionsForWorkOrder
} from '../../../helpers/sapManager';
import { WithCommonModal } from '../../common/WithCommonModal';
import { IWorkOrder } from '../../../models';

interface Iprops {
    colorButton: string;
    showModal: boolean;
    closeModal: (closeAll: boolean) => void;
    onHide: () => void;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const nextJobNumber = selectNextJobNumber(state);
    let modalTitle = ownProps.t('nsJob:saveModalTitle');
    let facility = initialFacility;
    const workOrders = Object.values(state.manageWorkOrder.workOrdersByID);
    let jobTypeOptions: IjobTypeOptionsForWorkOrder = {
        jobTypes: [],
        defaultSAPJobType: undefined
    };
    let workOrdersForNewJob: IWorkOrder[] = [];
    const sapJobMappings = state.sapJobMappings.sapJobMappingsByID;

    forEach(state.manageWorkOrder.selection, (id: string) => {
        const workOrderId = id.substring(7); // remove the select- prefix
        const wo = workOrders.find(wo => wo.id === workOrderId);
        if (wo) {
            if (wo.facility && wo.facility.id && wo.facility.id.length > 0) {
                facility = wo.facility;
            }

            workOrdersForNewJob.push(wo);

            jobTypeOptions = getJobTypeOptionsForWorkOrder(
                wo,
                sapJobMappings,
                ownProps.t
            );
        }
    });

    return {
        // reusable
        user: state.user,
        loading: selectIsLoading(state),
        show: ownProps.showModal,
        className: 'job-edit',
        title: modalTitle,
        nextJobNumber,
        closeModal: ownProps.closeModal,
        facility: facility,
        jobTypeOptions,
        workOrdersForNewJob,
        fseUsersByID: selectActiveFseUsersByID(state)
    };
};

export default withTranslation('nsJob')(
    connect(mapStateToProps, {
        // reusable
        onSubmit: saveJob,
        getNextJobNumber,
        addWorkOrdersToJob
    })(WithCommonModal(NewJobForm))
);
