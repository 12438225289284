import { omit, map, keyBy } from 'lodash';

import { ImanageTeamReducer, Iuser } from '../models';
import {
    createTableFiltersWithName,
    modalToggleWithName,
    createFormValuesWithName,
    createSelectedIDWithName
} from './commonReducers';
import initialState from './initialState';
import * as types from '../actions/actionTypes';
import { cleanUser } from './manageUserReducer';
import { combineReducers } from 'redux';

function teamMembersByID(
    state: { [key: string]: Iuser } = initialState.manageTeam.teamMembersByID,
    action: any
): { [key: string]: Iuser } {
    switch (action.type) {
        case types.TEAM_MANAGE_SUCCESS: {
            const newUsers = map(action.team, user => {
                return cleanUser(user);
            });
            return keyBy(newUsers, 'id');
        }
        case types.TEAM_UPDATE_SUCCESS:
            return { ...state, [action.user.id]: cleanUser(action.user) };

        case types.TEAM_SAVE_SUCCESS:
            // const securityFunct = map(action.user.securityFunctions, securityF => {
            //   return securityF.toUpperCase();
            // });
            // const updatedTeamMember = {
            //   ...pickBy(action.user, (property, key) => property !== null),
            //   securityFunctions: securityFunct
            // };

            // return [...state, updatedTeamMember] as Iuser[];

            // TODO not adding them for now until after they are approved
            return state;
        case types.TEAM_DELETE_SUCCESS:
            return omit(state, action.memberID);
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageTeam.teamMembersByID;
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.TEAM_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        default:
            return state;
    }
}

const userManage = combineReducers<ImanageTeamReducer>({
    teamMembersByID,
    totalPages,
    showEditTeamModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_TEAM'),
    editUserFormValues: (state, action) =>
        createFormValuesWithName(state, action, 'MANAGE_TEAM_MEMBER'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_TEAM'),
    selectedUserID: (state, action) =>
        createSelectedIDWithName(state, action, 'MANAGE_TEAM')
});

export default userManage;
