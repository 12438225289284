/*
 * Install Base Analytics Container
 */

import InstallBaseAnalytics from './InstallBaseAnalytics';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import {
    getProductInfo,
    setTableFilter
} from '../../actions/manageInventoryActions';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router';
import { initialFacility } from '../../reducers/initialState';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { IanalyticsInspection } from '../../models';

interface Iprops {
    t: TFunction;
    inspection: IanalyticsInspection[];
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & RouteComponentProps<{}>
) => {
    const { selectedFacilityID, user, productInfo } = state;
    const { securityFunctions } = user;
    const query = queryString.parse(ownProps.location.search);
    const selectedFacility =
        state.facilities[selectedFacilityID] || initialFacility;
    return {
        selectedFacility,
        securityFunctions,
        loading: selectIsLoading(state),
        mainCategories: productInfo.mainCategories,
        mainCategoryOptions: productInfo.mainCategoryOptions,
        isDemoMode: query.demo === 'true',
        user
    };
};

export const InstallBaseAnalyticsContainer = withRouter(
    connect(mapStateToProps, { getProductInfo, setTableFilter })(
        InstallBaseAnalytics
    )
);
