/*
 * Facility Form Container
 */
import {
    getFacilitiesByCountry,
    searchFacilitiesForAsyncSelect,
    setSelectedFacilityID
} from '../../actions/commonActions';
import { initialFacility } from '../../reducers/initialState';

import SelectFacilityForm from './SelectFacilityForm';
import { connect } from 'react-redux';
import {
    getFacilities,
    selectSelectedFacility,
    selectSelectedFacilityID
} from '../../reducers/facilitiesReducer';
import { TFunction } from 'i18next';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    classNameOverride?: string;
    t: TFunction;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const selectedFacilityID = selectSelectedFacilityID(state);
    let selectedFacility = selectSelectedFacility(state) || initialFacility;
    const facilities = Object.values(getFacilities(state));

    if (selectedFacility.id === '') {
        const tempFacility = facilities.find(x => x.id === selectedFacilityID);
        if (tempFacility !== undefined) {
            selectedFacility = tempFacility;
        }
    }

    return {
        selectedFacility,
        selectedFacilityID,
        loading: selectIsLoading(state),
        user: state.user,
        facilities,
        activeLocalCountry: state.manageUser.activeLocalCountry
    };
};

export const SelectFacilityContainer = connect(mapStateToProps, {
    setSelectedFacilityID,
    getFacilitiesByCountry,
    searchFacilitiesForAsyncSelect
})(SelectFacilityForm);
