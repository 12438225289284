import * as React from 'react';
import { TFunction } from 'i18next';
import ReactTable, { Column, FinalState, RowInfo } from 'react-table';
import { Ifacility, IinstallBase, Iproduct } from '../../../models';
import { TableUtil } from '../../common/TableUtil';
import { orderBy } from 'lodash';
import { measurementPointResultStatusTypesEnum } from '../../../models-enums';
import { InstallBaseActionButtonContainer } from '../../dashboard/InstallBaseActionButtonContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setSelectedTaeId } from '../../../actions/manageTaeActions';
import {
    setSelectedInstallBaseID,
    setSelectedProductID,
    toggleEditInstallModal
} from '../../../actions/manageInventoryActions';

interface Iprops {
    t: TFunction;
    facility: Ifacility;
    colorButton: string;
    openSearchFacilityModal: (ids: string[]) => void;
    selectedProduct: Iproduct;
    goBack: () => void;
    setSelectedInstallBaseID: typeof setSelectedInstallBaseID;
    setSelectedProductID: typeof setSelectedProductID;
    toggleEditInstallModal: typeof toggleEditInstallModal;
}

const ManageInventoryDetails: React.FC<Iprops> = (props: Iprops) => {
    const installBasesWithLocationString = props.selectedProduct.installBases?.map(
        (install: IinstallBase) => {
            return {
                ...install,
                locationString: TableUtil.buildLocation(install, props.facility)
            };
        }
    );
    const sortedInstallBases = orderBy(
        installBasesWithLocationString,
        'installString'
    );

    const expanderColumns = TableUtil.translateHeaders(
        [
            {
                id: 'serialNumber',
                Header: 'serialNumber',
                // accessor: 'serialNumber',
                accessor: ({ serialNumber }: IinstallBase) => {
                    return (
                        <>
                            <span className="mobile-label">SERIAL #</span>
                            <span className="mobile-description">
                                {serialNumber}
                            </span>
                        </>
                    );
                },
                minWidth: 100
            },
            {
                Header: 'Location',
                id: 'location',
                accessor: ({ fullLocation }: IinstallBase) => {
                    return (
                        <>
                            <span className="mobile-label">LOCATION </span>
                            <span className="mobile-description">
                                {fullLocation}
                            </span>
                        </>
                    );
                }
            },
            {
                Header: 'status',
                id: 'status',
                accessor: ({ visibleStatus }: IinstallBase) => {
                    const statusString =
                        measurementPointResultStatusTypesEnum[visibleStatus];

                    return (
                        <>
                            <span className="mobile-label">STATUS </span>
                            <span
                                className={`mobile-description status ${statusString}`}
                            >{`${props.t(
                                'manageMeasurementPointLists:' + statusString
                            )}`}</span>
                        </>
                    );
                },
                minWidth: 100
            },
            {
                Header: '',
                id: 'actions',
                Cell: rowInfo => (
                    <InstallBaseActionButtonContainer
                        t={props.t}
                        rowInfo={rowInfo}
                        colorButton={props.colorButton}
                        openSearchFacilityModal={props.openSearchFacilityModal}
                        forMobile={true}
                    />
                ),
                className: 'mobile-action-container'
            }
        ],
        props.t
    ) as Column[];

    const getTdProps = (
        fState: FinalState,
        rowInfo: RowInfo | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column) {
            if (column.id && column.id === 'actions') {
                return {};
            } else {
                return {
                    onClick: () => {
                        props.setSelectedInstallBaseID(rowInfo.original.id);
                        props.setSelectedProductID(rowInfo.original.productID);
                        props.toggleEditInstallModal();
                    }
                };
            }
        } else {
            return {};
        }
    };

    const goBack = () => {
        props.goBack();
    };

    let pagination = false;

    if (
        props.selectedProduct.installBases !== undefined &&
        props.selectedProduct.installBases?.length >= 10
    ) {
        pagination = true;
    }

    return (
        <div>
            <div className="mobile-product-container">
                <p onClick={goBack} className="name">
                    <FontAwesomeIcon icon="chevron-left" />{' '}
                    {props.selectedProduct.name}
                </p>
                <p className="count">
                    {props.selectedProduct.installBasesCount}
                </p>
            </div>
            <ReactTable
                className={'attempts-expander beacon-table -highlight orange'}
                data={sortedInstallBases}
                sortable={false}
                columns={expanderColumns}
                minRows={0}
                showPageSizeOptions={false}
                rowsText="installs"
                key={props.selectedProduct.installBases?.length}
                getTdProps={getTdProps}
                noDataText={props.t('noDataText')}
                resizable={false}
                //toggleSelection={toggleSelection}
                //selectType="checkbox"
                //toggleAll={toggleAll}
                //isSelected={isSelected}
                //ref={r => (state.checkboxTable = r)}
                //keyField="id"
                showPagination={pagination}
            />
        </div>
    );
};
export default ManageInventoryDetails;
