/*
 * Dashboard Analytics
 * Loop over the user's security functions and find which analytics modules they have access to
 */

import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import { IdashboardAnalyticsData, Ifacility, Iuser } from '../../models';
import { InstallBaseAnalyticsContainer } from './InstallBaseAnalyticsContainer';
import { PMPAnalyticsContainer } from './PMPAnalyticsContainer';
import { QuoteAnalyticsContainer } from './QuoteAnalyticsContainer';
import { constants } from '../../constants/constants';
import { initInventory } from '../../actions/manageInventoryActions';
import { AnalyticsCardContainer } from './Analytics/AnalyticsCardContainer';

interface Iprops {
    dashboardAnalytics: IdashboardAnalyticsData;
    selectedFacilityID: string;
    selectedFacility: Ifacility;
    user: Iuser;
    initInventory: typeof initInventory;
}

const DashboardAnalyticsFunc = (props: Iprops & WithTranslation) => {
    const prevProps = React.useRef<(Iprops & WithTranslation) | undefined>();
    const { dashboardAnalytics, selectedFacilityID, t, user } = props;

    React.useEffect(() => {
        // assign the ref's current value to the props object
        prevProps.current = props;
    }, [props]);

    const shouldShowInstallBaseAnalytics = () => {
        return constants.hasSecurityFunction(user, [
            constants.securityFunctions.DashboardInspection.id
        ]);
    };

    const shouldShowPMPAnalytics = () => {
        return constants.hasSecurityFunction(user, [
            constants.securityFunctions.DashboardPMP.id
        ]);
    };

    const shouldShowTAEAnalytics = () => {
        const isUsFacility =
            props.selectedFacility.standardID === constants.defaultStandardUS;
        const hasDashboardTAESecurity = constants.hasSecurityFunction(user, [
            constants.securityFunctions.DashboardTAE.id
        ]);

        return isUsFacility && hasDashboardTAESecurity;
    };

    const shouldShowQuoteAnalytics = () => {
        return constants.hasSecurityFunction(user, [
            constants.securityFunctions.DashboardQuote.id
        ]);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const shouldShowsmartlinksAnalytics = () => {
        const hasDashboardsmartlinkSecurity = constants.hasSecurityFunction(
            user,
            [constants.securityFunctions.DashboardSmartlinks.id]
        );

        return hasDashboardsmartlinkSecurity;
    };

    const shouldShowDashboardAnalytics = () => {
        return (
            shouldShowInstallBaseAnalytics() ||
            shouldShowPMPAnalytics() ||
            shouldShowTAEAnalytics() ||
            shouldShowQuoteAnalytics()
        );
    };

    React.useEffect(() => {
        if (
            prevProps?.current?.selectedFacilityID !== selectedFacilityID &&
            selectedFacilityID &&
            shouldShowDashboardAnalytics()
        ) {
            // Needed for TAEDetail
            props.initInventory(selectedFacilityID, () => {
                return;
            });
        }
    }, [selectedFacilityID]);

    if (shouldShowDashboardAnalytics() === false) {
        return null;
    }

    return (
        <>
            <section
                className="dashboard-analytics-container"
                data-type="graphs"
            >
                {shouldShowInstallBaseAnalytics() && (
                    <InstallBaseAnalyticsContainer
                        t={t}
                        inspection={dashboardAnalytics.inspection}
                    />
                )}
                {shouldShowPMPAnalytics() && (
                    <PMPAnalyticsContainer t={t} pmp={dashboardAnalytics.pmp} />
                )}
                {shouldShowQuoteAnalytics() && (
                    <QuoteAnalyticsContainer quote={dashboardAnalytics.quote} />
                )}
            </section>
            <section
                className="dashboard-analytics-container"
                data-type="cards"
            >
                {dashboardAnalytics?.smartlinks?.map((smartlink, index) => (
                    <AnalyticsCardContainer
                        key={`${smartlink.installBaseID}-${index}`}
                        data={smartlink}
                    />
                ))}
            </section>
        </>
    );
};

export const DashboardAnalyticsLayout = withTranslation('dashboardAnalytics')(
    DashboardAnalyticsFunc
);
