import { ImanageAssetPhotosReducer, Iphoto } from '../models';
import { createTableFiltersWithName } from './commonReducers';
import initialState, { initialPhoto } from './initialState';
import * as types from '../actions/actionTypes';
import { combineReducers } from 'redux';
import { pickBy } from 'lodash';

export const cleanPhotoObject = (photo: any): Iphoto => {
    return {
        ...initialPhoto,
        ...pickBy(photo, (property, key) => property !== null)
    };
};

function photos(
    state: { [key: string]: Iphoto } = initialState.manageAssetPhotos.photos,
    action: any
): { [key: string]: Iphoto } {
    switch (action.type) {
        case types.ASSET_PHOTOS_MANAGE_SUCCESS:
            return action.photos;
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function photosByProduct(
    state: { [key: string]: Iphoto[] } = initialState.manageAssetPhotos
        .photosByProduct,
    action: any
): { [key: string]: Iphoto[] } {
    switch (action.type) {
        case types.PHOTOS_BY_PRODUCT_SUCCESS:
            return action.photos;
        case types.DELETE_PHOTO_SUCCESS: {
            return Object.keys(state).reduce((newState, productID) => {
                if (productID !== action.productID) {
                    return { ...newState, [productID]: state[productID] };
                }
                return newState;
            }, {});
        }
        case types.UPLOAD_PRODUCT_PHOTO_SUCCESS: {
            const newPhoto = cleanPhotoObject(action.photo);
            return {
                [newPhoto.productID]: [...state[newPhoto.productID], newPhoto]
            };
        }
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.ASSET_PHOTOS_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

const manageAssetPhotos = combineReducers<ImanageAssetPhotosReducer>({
    photos,
    photosByProduct,
    totalPages,
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_ASSET_PHOTOS')
});

export default manageAssetPhotos;
