/*
 * smartlink Analytics Container
 */

import Smartlink from './SmartLink';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { addUserAlert } from '../../actions/manageUserAlertActions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setSelectedFacilityID } from '../../actions/commonActions';
import { getDashboardAnalytics } from '../../actions/dashboardAnalyticsActions';
import { selectDataFormatPreference } from '../../reducers/dashboardAnalyticsReducer';

const mapStateToProps = (
    state: IinitialState,
    ownProps: WithTranslation & RouteComponentProps
) => {
    const { user } = state;
    const { securityFunctions, id } = user;
    const dataFormatPreference = selectDataFormatPreference(state);
    return {
        securityFunctions,
        loading: selectIsLoading(state),
        userID: id,
        online: state.offline.online,
        dataFormatPreference,
        user
    };
};

export const SmartlinkContainer = withRouter(
    withTranslation('manageTae')(
        connect(mapStateToProps, {
            addUserAlert,
            setSelectedFacilityID,
            getDashboardAnalytics
        })(Smartlink)
    )
);
