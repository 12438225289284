/*
 * EditJobForm
 */

import * as React from 'react';

import { debounce } from 'lodash';
import { Button, Col } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup
} from 'react-reactive-form';

import moment from 'moment';
import { WithTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { getNextJobNumber } from '../../actions/manageJobActions';
import { constants } from '../../../constants/constants';
import {
    IWorkOrder,
    Ifacility,
    IfacilityWithoutBuildings,
    Ijob,
    IjobPart,
    Ioption,
    Iuser
} from '../../../models';
import { IgenericFormValues } from '../../../modelsForms';
import { convertFacilityOptions } from '../../../reducers/facilitiesReducer';
import { initialJob } from '../../../reducers/initialState';
import { FormUtil } from '../../common/FormUtil';
import { IjobTypeOptionsForWorkOrder } from '../../../helpers/sapManager';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../../../constants/apiEndpoints';
import { msalFetch } from '../../auth/Auth-Utils';
import { addWorkOrdersToJob } from '../../../actions/manageJobActions';
const uuidv4 = require('uuid/v4');

interface Iprops {
    // reusable
    loading: boolean;
    show: boolean;
    nextJobNumber: string;
    onSubmit: (
        job: Ijob,
        jobParts: IjobPart[],
        isEditMode: boolean,
        activeCountryID: string
    ) => Promise<any>;
    user: Iuser;
    closeModal: (closeAll: boolean) => void;
    facility: Ifacility;
    colorButton: string;
    getNextJobNumber: typeof getNextJobNumber;
    jobTypeOptions: IjobTypeOptionsForWorkOrder;
    workOrdersForNewJob: IWorkOrder[];
    fseUsersByID: { [key: string]: Iuser };
    addWorkOrdersToJob: typeof addWorkOrdersToJob;
}

interface Istate {
    fieldConfig: FieldConfig;
}

class NewJobForm extends React.Component<Iprops & WithTranslation, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;

    constructor(props: Iprops & WithTranslation) {
        super(props);

        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        this.props.getNextJobNumber();
    }

    componentDidUpdate(prevProps: Iprops & WithTranslation) {
        if (this.props.nextJobNumber !== prevProps.nextJobNumber) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.closeModal(false);
    }

    /*
     * itemToFormValues - take the selectedObject and convert it to formValues
     */
    itemToFormValues = (): IgenericFormValues<Ijob> => {
        const selectedFacility = FormUtil.convertToSingleOption(
            this.props.facility
        );
        const loadJobNumber = this.props.nextJobNumber;
        const startDate = moment.utc().format(constants.momentDisplayFormat);
        const endDate = moment.utc().format(constants.momentDisplayFormat);

        return {
            jobNumber: loadJobNumber,
            facilityID: selectedFacility,
            jobTypeID: undefined,
            startDate,
            endDate
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedObject
     */
    formValuesToItem = (): Ijob => {
        const formValues = FormUtil.getValues(this.formGroup.value);
        const { facilityID: formFacilityID } = formValues;
        const facilityID = (Array.isArray(formFacilityID)
            ? formFacilityID[0]
            : formFacilityID) as string;

        return {
            ...initialJob,
            ...formValues,
            id: uuidv4(),
            facilityID,
            userJobs: []
        };
    };

    loadOptions = debounce(async (search, callback) => {
        if (search && search.length >= 3) {
            const page = 1;
            const axiosOptions: AxiosRequestConfig = {
                method: 'get',
                params: {
                    page,
                    search,
                    countryID: this.props.facility.countryID
                }
            };

            const url = API.GET.user.getusersearch;
            await msalFetch(url, axiosOptions)
                .then((response: AxiosResponse<any>) => {
                    const users: Iuser[] = response?.data.result;
                    const userOptions: Ioption[] = users.map(user => ({
                        label: `${user.first} ${user.last}`,
                        value: user.id
                    }));
                    callback(userOptions);
                })
                .catch(err => {
                    console.error(err);
                    constants.handleError(err, 'get users');
                });
            return;
        }

        callback();
    }, 200);

    buildFieldConfig = (defaultValues = this.itemToFormValues()) => {
        const { t, facility, jobTypeOptions } = this.props;
        const disabled = false;
        const { startDate, endDate } = defaultValues;
        const facilityOption = convertFacilityOptions([
            facility as IfacilityWithoutBuildings
        ]);

        // Field config to configure form
        let fieldConfigControls: { [key: string]: any } = {
            facilityID: {
                render: FormUtil.Select,
                meta: {
                    label: 'jobForm.facility',
                    colWidth: 12,
                    placeholder: 'jobForm.facilitySearchPlaceholder',
                    name: 'facility',
                    options: facilityOption
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: facilityOption,
                    disabled: true
                }
            },
            jobNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'jobForm.jobNumber',
                    colWidth: 12,
                    type: 'input',
                    name: 'jobnumber',
                    required: false
                },
                formState: { value: defaultValues.jobNumber, disabled }
            },
            jobTypeID: {
                render: FormUtil.Select,
                meta: {
                    options: jobTypeOptions.jobTypes,
                    label: 'jobForm.type',
                    colWidth: 12,
                    placeholder: 'jobForm.typeSearchPlaceholder',
                    name: 'job-type',
                    shouldTranslate: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: jobTypeOptions.defaultSAPJobType
                        ? jobTypeOptions.defaultSAPJobType
                        : undefined,
                    disabled
                }
            },
            assignedUserID: {
                render: FormUtil.AsyncSelect,
                meta: {
                    loadOptions: (search: string, callback: any) => {
                        this.loadOptions(search, callback);
                    },
                    label: 'jobForm.fseLead',
                    colWidth: 12,
                    placeholder: 'jobForm.fseLeadSearchPlaceholder',
                    name: 'assigned-lead-user',
                    type: 'select'
                },

                formState: {
                    value: defaultValues.assignedUserID,
                    disabled
                }
            },
            startDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'jobForm.startDate',
                    colWidth: 12,
                    showTime: false,
                    name: 'start-date',
                    placeholder: 'jobForm.startDatePlaceholder'
                },
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        FormUtil.validators.isValidMoment
                    ]
                },
                formState: {
                    value: startDate,
                    disabled
                }
            },
            endDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'jobForm.endDate',
                    colWidth: 12,
                    showTime: false,
                    name: 'end-date',
                    placeholder: 'jobForm.endDatePlaceholder'
                },
                options: {
                    validators: [
                        FormUtil.validators.requiredWithTrim,
                        FormUtil.validators.isValidMoment
                    ]
                },
                formState: {
                    value: endDate,
                    disabled
                }
            }
        };

        const fieldConfig = {
            controls: { ...fieldConfigControls }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes after a short delay that allows the initial form values to load
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the values to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'startDate': {
                this.checkIfStartDateBeforeEndDate({ startDate: value });
                if (moment.isMoment(value)) {
                    value = value.format(constants.momentSQLFormat);
                }
            }
            case 'endDate': {
                this.checkIfStartDateBeforeEndDate({ endDate: value });
                if (moment.isMoment(value)) {
                    value = value.format(constants.momentSQLFormat);
                }
            }
            default:
                //this.onChangeDebounced(this.formValuesToItem());
                break;
        }
    };

    /*
     * Check if the date is in the past or if the start is before the end date
     */
    checkIfStartDateBeforeEndDate = ({ startDate, endDate }: any) => {
        if (
            startDate &&
            moment.isMoment(startDate) &&
            this.formGroup.value.endDate
        ) {
            if (startDate.isAfter(this.formGroup.value.endDate)) {
                toastr.warning(
                    this.props.t('jobForm.startDateWarning'),
                    '',
                    constants.toastrWarning
                );
                const startDateControl = this.formGroup.get('startDate');
                startDateControl.setErrors({ beforeStart: true });
            } else if (startDate.isBefore(moment(), 'day')) {
                toastr.warning(
                    this.props.t('common:warning'),
                    this.props.t('jobForm.pastDateWarning'),
                    constants.toastrWarning
                );
            } else {
                const startDateControl = this.formGroup.get('startDate');
                startDateControl.setErrors(null);
                const endDateControl = this.formGroup.get('endDate');
                endDateControl.setErrors(null);
            }
        } else if (endDate && moment.isMoment(endDate)) {
            if (endDate.isBefore(this.formGroup.value.startDate)) {
                toastr.warning(
                    this.props.t('common:warning'),
                    this.props.t('jobForm.startDateWarning'),
                    constants.toastrWarning
                );
                const endDateControl = this.formGroup.get('endDate');
                endDateControl.setErrors({ beforeStart: true });
            } else if (endDate.isBefore(moment(), 'day')) {
                toastr.warning(
                    this.props.t('common:warning'),
                    this.props.t('jobForm.pastDateWarning'),
                    constants.toastrWarning
                );
            } else {
                const endDateControl = this.formGroup.get('endDate');
                endDateControl.setErrors(null);
                const startDateControl = this.formGroup.get('startDate');
                startDateControl.setErrors(null);
            }
        } else {
            console.error('missing start and end date');
        }
    };

    onSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        this.props
            .onSubmit(this.formValuesToItem(), [], false, '')
            .then(response => {
                if (response?.value) {
                    const {
                        closeModal,
                        workOrdersForNewJob,
                        t,
                        addWorkOrdersToJob
                    } = this.props;
                    const { id } = response.value;
                    const selection = workOrdersForNewJob.map(
                        wo => `select-${wo.id}`
                    );

                    // Job has been created, now add the work orders to the job
                    addWorkOrdersToJob(selection, id, t, true);

                    // Now that we're done, tell the parent component to close the 1st modal as well
                    closeModal(true);
                }
            });
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix job-form beacon-form ${this.props.colorButton}`;

        return (
            <form onSubmit={this.onSubmit} className={formClassName}>
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={() => this.props.closeModal(false)}
                    >
                        {t('jobForm.cancel')}
                    </Button>

                    <Button
                        bsStyle={this.props.colorButton}
                        type="submit"
                        disabled={this.props.loading}
                    >
                        {t('jobForm.save')}
                    </Button>
                </Col>
            </form>
        );
    }
}
export default NewJobForm;
