import { connect } from 'react-redux';

import { Ihistory } from '../../models';

import LeadActivityList from './LeadActivityList';

import {
    toggleLeadActivityModal,
    toggleEditLeadActivityModal,
    setSelectedLeadActivityFromID,
    updateSelectedLeadActivity
} from '../../actions/manageLeadsActions';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectLeadActivityForLead } from '../../reducers/manageLeadsReducers';
import { manageLeadQueryParamsEnum } from './ManageLeads';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
    handleSelectLeadActivity: (id: string) => void;
    queryParams: typeof manageLeadQueryParamsEnum;
    history: Ihistory;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const leadActivities = selectLeadActivityForLead(state);

    return {
        className: 'lead-activity',
        loading: selectIsLoading(state),
        show: state.manageLeads.showLeadActivityModal,
        leadActivities,
        title: ownProps.t('leadActivityListModalTitle')
    };
};

export default withTranslation('manageLeads')(
    connect(mapStateToProps, {
        onCancel: toggleLeadActivityModal,
        toggleModal: toggleLeadActivityModal,
        toggleEditLeadActivityModal,
        setSelectedLeadActivityFromID,
        onHide: toggleLeadActivityModal,
        updateSelectedLeadActivity
    })(WithCommonModal(LeadActivityList))
);
