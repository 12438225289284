import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { Iproduct, ItableFiltersParams, ThunkResult } from '../models';
import { beginAjaxCall } from './ajaxStatusActions';
import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import * as types from './actionTypes';
import { msalFetch } from '../components/auth/Auth-Utils';

export function getProductQueue(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const {
            page,
            search,
            mainCategory,
            subCategory,
            brand,
            origin
        } = getState().manageProductQueue.tableFilters;
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: {
                page: page || 1,
                search: search || '',
                mainCategoryID: mainCategory ? mainCategory.value : null,
                subCategoryID: subCategory ? subCategory.value : null,
                brandID: brand ? brand.value : null,
                origin: origin ? origin.value : null,
                standards: []
            }
        };

        const url = API.GET.inventory.getproductqueue;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.PRODUCT_QUEUE_SUCCESS,
                        payload: { productQueueObjects: data.data.result }
                    });
                    dispatch({
                        type: types.PRODUCT_QUEUE_TOTAL_PAGES,
                        pages: data.data.pages
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.PRODUCT_QUEUE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get product queue');
                console.error(error);
            });
    };
}

export function getMergeableProducts({
    page,
    search,
    mainCategoryID,
    subCategoryID,
    brandID,
    origin,
    isFinalProduct,
    standards
}: {
    page: number;
    search: string | null;
    mainCategoryID: string | null;
    subCategoryID: string | null;
    brandID: string | null;
    origin: string | null;
    isFinalProduct: boolean;
    standards: string[];
}): ThunkResult<any> {
    return (dispatch, getState) => {
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: {
                page: page || 1,
                search: search || '',
                mainCategoryID: mainCategoryID || null,
                subCategoryID: subCategoryID || null,
                brandID: brandID || null,
                origin: origin || null,
                isFinalProduct: isFinalProduct || false,
                standards: standards || []
            }
        };
        const url = API.GET.inventory.getproductqueue;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.MERGEABLE_PRODUCTS_SUCCESS,
                        payload: { productQueueObjects: data.data.result }
                    });
                }
                return data;
            })
            .catch((error: any) => {
                dispatch({
                    type: types.MERGEABLE_PRODUCTS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get mergeable product queue');
                console.error(error);
            });
    };
}

export function approveProduct(queueID: string, dispatch: any) {
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: { id: queueID }
    };

    const url = API.POST.inventory.approveproduct;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            if (!data.data) {
                throw new Error('missing data');
            } else {
                dispatch({
                    type: types.PRODUCT_APPROVE_SUCCESS,
                    payload: { queueID }
                });
            }
        })
        .catch((error: any) => {
            dispatch({
                type: types.PRODUCT_APPROVE_FAILED,
                error,
                axiosOptions
            });
            constants.handleError(error, 'approve product');
            console.error(error);
        });
}

export function updateQueueProduct(
    product: Iproduct,
    shouldApprove?: boolean,
    queueID?: string
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        dispatch({ type: types.TOGGLE_MODAL_EDIT_PRODUCT });
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: product
        };

        const url = API.POST.inventory.updateproduct;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.PRODUCT_QUEUE_PRODUCT_UPDATE_SUCCESS,
                        payload: { product: data.data, queueID }
                    });
                    // toastr.success('Success', 'Saved product', constants.toastrSuccess);
                    if (shouldApprove && queueID) {
                        dispatch(beginAjaxCall());
                        approveProduct(queueID, dispatch); // don't return this because if we do, we will see two errors
                    }
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.PRODUCT_QUEUE_PRODUCT_UPDATE_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'update queue product');
                console.error(error);
            });
    };
}

export const toggleApproveProductModal = () => ({
    type: types.TOGGLE_MODAL_APPROVE_PRODUCT
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_PRODUCT_QUEUE,
    filters
});
