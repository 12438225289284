import React from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import API from '../../constants/apiEndpoints';
import { IAlert, Iuser } from '../../models';
import { addUserAlert } from '../../actions/manageUserAlertActions';
import { setSelectedFacilityID } from '../../actions/commonActions';
import { getDashboardAnalytics } from '../../actions/dashboardAnalyticsActions';
import { RouteComponentProps } from 'react-router-dom';
import { debounce } from 'lodash';

interface Istate {
    connections: HubConnection[];
}

interface Iprops extends RouteComponentProps {
    userID: string;
    securityFunctions: any[];
    online: boolean;
    loading: boolean;
    user: Iuser;
    addUserAlert: typeof addUserAlert;
    setSelectedFacilityID: typeof setSelectedFacilityID;
    getDashboardAnalytics: typeof getDashboardAnalytics;
}

export default class Smartlink extends React.Component<Iprops, Istate> {
    debouncedConnect: () => void;
    constructor(props: Iprops) {
        super(props);
        // Cautiously prevent an infinite loop for some unknown reason
        this.debouncedConnect = debounce(this.connect, 10000);
    }

    componentDidMount() {
        this.setConnection();
    }

    componentDidUpdate(prevProps: Iprops) {
        if (this.state.connections && this.props.online) {
            this.debouncedConnect();
        }
    }

    setConnection() {
        const { user } = this.props;
        let connectionsToSave: HubConnection[] = [];

        if (user && user.facilities && user.facilities.length > 0) {
            user.facilities.forEach(facility => {
                // Check if connection already exists and if it's connected
                let existingConnection: HubConnection | undefined = undefined;

                if (
                    this.state &&
                    this.state.connections &&
                    this.state.connections.length > 0
                ) {
                    existingConnection = this.state.connections.find(
                        connection => connection.baseUrl.includes(facility.id)
                    );
                }

                if (
                    existingConnection &&
                    existingConnection.state === 'Connected'
                ) {
                    connectionsToSave.push(existingConnection);
                } else {
                    // Create new connection
                    const newConnection = new HubConnectionBuilder()
                        .withUrl(
                            `${API.smartlink.alert}?facilityID=${facility.id}`
                        )
                        .withAutomaticReconnect()
                        // .configureLogging(LogLevel.Critical)
                        .build();

                    connectionsToSave.push(newConnection);
                }
            });
        }

        this.setState({ connections: connectionsToSave });
    }

    connect() {
        const { connections } = this.state;
        connections.forEach(connection => {
            if (connection.state !== 'Connected') {
                connection
                    .start()
                    .then(_ => {
                        console.info('Connected!');
                        this.listenForMessages();
                    })
                    .catch(err => console.error(err));
            }
        });
    }
    showSmartlinkFlag() {
        if (this.props.location.pathname === '/dashboard') {
            // this.props.getDashboardAnalytics();
        }
    }

    listenForMessages() {
        const { connections } = this.state;

        connections.forEach(connection => {
            connection.on('dashboardSmartLinkAlarm', _ => {
                this.showSmartlinkFlag();
            });

            connection.on('alertMessage', (alert: IAlert) => {
                // Call action dispatch alert to store
                this.props.addUserAlert(alert);
                console.info('alert received!');
            });

            connection.on('clearAlertMessage', (alert: IAlert) => {
                this.props.addUserAlert(alert);
                console.info('clear alert received!');
            });
        });

        console.info('listening for alert message');
    }

    render() {
        return <></>;
    }
}
