import { pickBy, map, keyBy, filter, orderBy } from 'lodash';

import { ImanageTaeReducer, Itae } from '../models';
import {
    createTableFiltersWithName,
    modalToggleWithName,
    createSelectedIDWithName
} from './commonReducers';
import initialState, { initialTae } from './initialState';
import * as types from '../actions/actionTypes';
import { combineReducers } from 'redux';
import moment from 'moment';

export const cleanTaeObject = (item: any) => {
    return {
        ...initialTae,
        ...pickBy(item, (property, key) => property !== null)
    } as Itae;
};

function taeByIDReducer(
    state: { [key: string]: Itae } = initialState.manageTae.taeByID,
    action: any
): { [key: string]: Itae } {
    switch (action.type) {
        case types.MANAGE_TAE_SUCCESS:
            return keyBy(
                map(action.taes, (tae: any) => {
                    return cleanTaeObject(tae);
                }),
                'id'
            );
        // case types.JOB_SAVE_SUCCESS:
        //   return { ...state, [action.job.id]: action.job };
        // case types.JOB_UPDATE_SUCCESS:
        //   return { ...state, [action.job.id]: cleanJobObject(action.job) };
        // case types.JOB_DELETE_SUCCESS:
        //   // TODO switch to FE filtering
        //   // return {...state, [action.payload]: {...state[action.payload], isDeleted: true}}
        //   return omit(state, action.payload);
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageTae.taeByID;
        default:
            return state;
    }
}

// function jobManageTotalPages(state: number = 1, action: any): number {
//   switch (action.type) {
//     case types.JOB_MANAGE_TOTAL_PAGES:
//       if (action.pages && action.pages > 0) {
//         return action.pages;
//       }
//       return state;
//     case types.USER_LOGOUT_SUCCESS:
//       return 1;
//     default:
//       return state;
//   }
// }

const manageTaeReducer = combineReducers<ImanageTaeReducer>({
    taeByID: taeByIDReducer,
    selectedItemID: (state, action) =>
        createSelectedIDWithName(state, action, 'MANAGE_TAE'),
    showEditModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_TAE'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_TAE')
});

export default manageTaeReducer;

/*
 *   SELECTORS
 */
export const selectVisibleTaes = (state: ImanageTaeReducer) => {
    const { taeByID, tableFilters } = state;
    const filteredItems = filter(taeByID, item => {
        let shouldInclude = true;
        if (
            tableFilters.installBaseID &&
            tableFilters.installBaseID.value &&
            item.installBaseID !== tableFilters.installBaseID.value
        ) {
            shouldInclude = false;
        }
        return shouldInclude;
    });

    return orderBy(
        filteredItems,
        res => moment.utc(res.createDate).unix(),
        'desc'
    );
};
