import { TFunction } from "i18next";
import { constants, jobTypeOptions, sapBusinessIndicators } from "../constants/constants";
import { IWorkOrder, Ioption, IsapJobMapping, WorkOrderSource } from "../models";
import { jobTypesIdEnum } from "../models-enums";

export interface IjobTypeOptionsForWorkOrder
{
    jobTypes: Ioption[];
    defaultSAPJobType: Ioption | undefined;
}

export const getJobTypeOptionsForWorkOrder = (workOrder: IWorkOrder, sapJobMappings: { [key: string]: IsapJobMapping }, t: TFunction): IjobTypeOptionsForWorkOrder => {
    let jobTypes: Ioption[] = [];
    let defaultSAPJobType: Ioption | undefined = undefined;
    
    const jobTypeOptionsTranslated = jobTypeOptions.map(option => {
        return {
            ...option,
            label: t(`nsJob:${option.label}`)
        };
    });

    if(workOrder.source === WorkOrderSource.SAP){
        // Check if the selected work order(s) have a suggested job type, if so, then that's the only job type they are allowed to use
        if (workOrder.suggestedJobTypeID !== undefined && workOrder.suggestedJobTypeID !== null) {
            jobTypes = jobTypeOptionsTranslated.filter(t => t.value === workOrder.suggestedJobTypeID);
            if(jobTypes.length > 0){
                defaultSAPJobType = jobTypes[0];
            }
            
        } else {
            // There was no suggested job type set, so we need to check the business indicator on the work order
            const sapBusinessIndicator = workOrder?.sapBusinessIndicator;
            const product = workOrder?.product;
            const sapPlant = workOrder?.sapPlant;
           
            if (
                sapBusinessIndicator !== undefined &&
                sapBusinessIndicator !== null &&
                sapPlant !== undefined &&
                sapPlant !== null &&
                product !== undefined &&
                product !== null
            ) {
                const forVirtualProduct =
                    product && product.subcategoryID === constants.virtualProductSubcategoryID ? true : false;
                let sapJobTypes: IsapJobMapping[] = [];
                const sapMaterialNumberExist =
                    Object.values(sapJobMappings).filter(
                        x =>
                            x.sapBusinessIndicator === sapBusinessIndicator &&
                            x.serviceCenter === sapPlant &&
                            x.isVirtual === forVirtualProduct &&
                            x.sapMaterialNumber === product.sapMaterialNumber
                    ).length > 0;

                if (sapMaterialNumberExist) {
                    sapJobTypes = Object.values(sapJobMappings).filter(
                        x =>
                            x.sapBusinessIndicator === sapBusinessIndicator &&
                            x.serviceCenter === sapPlant &&
                            x.isVirtual === forVirtualProduct &&
                            x.sapMaterialNumber === product.sapMaterialNumber
                    );
                } else {
                    sapJobTypes = Object.values(sapJobMappings).filter(
                        x =>
                            x.sapBusinessIndicator === sapBusinessIndicator &&
                            x.serviceCenter === sapPlant &&
                            x.isVirtual === forVirtualProduct
                    );
                }

                const jobTypeIDs = sapJobTypes.map(x => x.jobTypeID);

                // Filter the job types to show in the drop down
                jobTypes = jobTypeOptionsTranslated.filter(t => jobTypeIDs.includes(t.value));

                // Check if we need to default to a certain job type
                sapJobTypes.forEach(x => {
                    if (x.isDefault) {
                        defaultSAPJobType = jobTypeOptionsTranslated.find(t => t.value === x.jobTypeID);
                    }
                });
            }
        }
    } else {
        // Non SAP
        jobTypes = jobTypeOptionsTranslated.filter(
            t =>
                t.value === jobTypesIdEnum.Maintenance ||
                t.value === jobTypesIdEnum.warrantyBM ||
                t.value === jobTypesIdEnum.Repair
        );
    }

    return {
        jobTypes,
        defaultSAPJobType
    } as IjobTypeOptionsForWorkOrder;
};