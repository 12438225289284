import initialState from './initialState';
import * as types from '../actions/actionTypes';
import { IAlert } from '../models';
import { pickBy, map, keyBy } from 'lodash';

const cleanAlertObject = (alert: IAlert) => {
    return {
        ...pickBy(alert, property => property !== null)
    } as IAlert;
};

export function manageUserAlertReducer(
    state: { [key: string]: IAlert } = initialState.alertsByID,
    action: any
): { [key: string]: IAlert } {
    switch (action.type) {
        case types.ADD_USER_ALERT: {
            const alert = action.payload;
            const cleanedAlert = cleanAlertObject(alert);
            return { ...state, [alert.id]: cleanedAlert };
        }
        case types.LOAD_USER_ALERTS_SUCCESS: {
            const newAlerts = map(action.payload, (alert: IAlert) => {
                return cleanAlertObject(alert);
            });
            return keyBy(newAlerts, 'id');
        }
        case types.READ_USER_ALERT_SUCCESS: {
            const updatedAlert = { ...state[action.payload], isViewed: true };
            return { ...state, [action.payload]: updatedAlert };
        }
        case types.READ_ALL_USER_ALERTS_SUCCESS: {
            const alerts = map(state, (alert: IAlert) => {
                return { ...alert, isViewed: true };
            });
            return keyBy(alerts, 'id');
        }
        case types.REMOVE_ALERT_SUCCESS: {
            const data = { ...state };
            delete data[action.payload.id];
            return data;
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.alertsByID;
        default:
            return state;
    }
}
