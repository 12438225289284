/*
 * TwoPaneLayout verifies that the user has access to this feature, then either shows the component or lets them know they do not have access
 */

import 'react-table/react-table.css';

import * as React from 'react';

import { Col, Grid, Row } from 'react-bootstrap';
import { Iuser } from '../../models';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { InstallBaseAnalyticsTableContainer } from '../dashboard/InstallBaseAnalyticsTableContainer';
import ManageAlert from '../manageAlert/ManageAlert';
import ManageBrand from '../manageBrand/ManageBrand';
import ManageCode from '../manageCode/ManageCode';
import ManageFacility from '../manageFacility/manageFacility';
import ManangeFacilityQueue from '../manageFacilityQueue/manageFacilityQueue';
import ManageDocumentLibrary from '../manageDocumentLibrary/ManageDocumentLibrary';
import ManageAssetPhotos from '../manageAssetPhotos/ManageAssetPhotos';
import { ManageInventoryContainer } from '../manageInventory/ManageInventoryContainer';
import ManageJob from '../manageJob/ManageJob';
import ManageJobComment from '../manageJobComment/ManageJobComment';
import ManageLeads from '../manageLeads/ManageLeads';
import ManageQuotes from '../manageQuotes/manageQuotes';
import ManageLocation from '../manageLocation/ManageLocation';
import ManageMeasurementPointList from '../manageMeasurementPointLists/ManageMeasurementPointLists';
import ManageProduct from '../manageProduct/ManageProduct';
import ManageProductQueue from '../manageProductQueue/ManageProductQueue';
import ManageReport from '../manageReport/ManageReport';
import ManageTeam from '../manageTeam/ManageTeam';
import ManageTraining from '../manageTraining/ManageTraining';
import ManageUser from '../manageUser/ManageUser';
import ManageUserQueue from '../manageUserQueue/ManageUserQueue';
import ManageWorkOrder from '../manageWorkOrder/manageWorkOrder';
import ManageSuggestedParts from '../manageSuggestedParts/ManageSuggestedParts';
import { PMPAnalyticsTableLayout } from '../dashboard/PMPAnalyticsTableLayout';
import SideMenu from '../sideMenu/SideMenu';
import Training from '../training/Training';
import { connect, useSelector } from 'react-redux';
import { constants } from '../../constants/constants';
import { IinitialState } from '../../reducers';
import Mixpanel from '../../helpers/Mixpanel';
import { toggleSideMenu } from '../../actions/commonActions';
import Dashboard from '../dashboard/Dashboard';
import { AnalyticsDetailContainer } from '../dashboard/AnalyticsDetailContainer';
import { AnalyticsDetailTaeContainer } from '../dashboard/AnalyticsDetailTaeContainer';
import { AnalyticsExpandedCardContainer } from '../dashboard/Analytics/AnalyticsExpandedCardContainer';
import { getIsMobile } from '../../reducers/configReducer';
import ManageInventoryDetails from '../manageInventory/mobile/ManageInventoryDetails';

// const testme = () => {
//   return <h3>Your test is a success</h3>;
// };

interface Iprops {
    user: Iuser;
    showSideMenu: boolean;
    toggleSideMenu: typeof toggleSideMenu;
}

const TwoPaneLayout = (props: Iprops & RouteComponentProps<{}>) => {
    const isMobile = useSelector(getIsMobile);
    const tile = constants.getTileByURL(props.location.pathname);
    const hasAccess = constants.hasSecurityFunction(
        props.user,
        tile.securityFunctions
    );
    const menuClass = props.showSideMenu ? 'open' : 'closed';

    Mixpanel.track(`Viewed ${tile.title}`, { hasAccess });
    Mixpanel.identify(props.user.id);
    Mixpanel.people.set({
        $email: props.user.email,
        USER_ID: props.user.id
    });

    if (!isMobile && !hasAccess) {
        return (
            <Grid
                className="two-pane-layout modal-container"
                id="two-pane-layout"
                fluid={true}
            >
                <Row>
                    <Col className={`col-fixed side-menu-wrap ${menuClass}`}>
                        <SideMenu {...props} />
                    </Col>

                    <Col className={`col-fluid side-menu-sibling ${menuClass}`}>
                        <div style={{ padding: '20px' }}>
                            <h4>
                                No access to {tile.title}. Please contact
                                support and request access.
                            </h4>
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }

    return (
        <Grid
            className="two-pane-layout modal-container"
            id="two-pane-layout"
            fluid={true}
        >
            <div id="modal-one" />
            <div id="modal-two" />
            <Row>
                {!isMobile && (
                    <Col className={`col-fixed side-menu-wrap ${menuClass}`}>
                        <SideMenu {...props} />
                    </Col>
                )}

                <Col className={`col-fluid side-menu-sibling ${menuClass}`}>
                    <Switch>
                        <Route
                            exact
                            path="/queue"
                            component={ManageUserQueue}
                        />
                        <Route exact path="/users" component={ManageUser} />
                        <Route exact path="/team" component={ManageTeam} />
                        <Route
                            exact
                            path="/inventory"
                            component={ManageInventoryContainer}
                        />
                        <Route exact path="/quotes" component={ManageQuotes} />
                        <Route
                            exact
                            path="/quotes_sales"
                            component={ManageQuotes}
                        />
                        <Route
                            exact
                            path="/productqueue"
                            component={ManageProductQueue}
                        />
                        <Route
                            exact
                            path="/products"
                            component={ManageProduct}
                        />
                        <Route
                            path="/facilityqueue"
                            component={ManangeFacilityQueue}
                        />
                        <Route
                            path="/dashboard"
                            render={({ match: { url } }) => (
                                <>
                                    <Route
                                        path={`${url}/`}
                                        exact
                                        component={Dashboard}
                                    />
                                    <Route
                                        path={`${url}/analytics-detail/:installID`}
                                        component={
                                            AnalyticsExpandedCardContainer
                                        }
                                    />
                                    <Route
                                        path={`${url}/smartlink-detail/:installID`}
                                        component={AnalyticsDetailContainer}
                                    />
                                    <Route
                                        path={`${url}/tae-detail/:installID`}
                                        component={AnalyticsDetailTaeContainer}
                                    />
                                </>
                            )}
                        />
                        <Route exact path="/managejobs" component={ManageJob} />
                        <Route exact path="/reports" component={ManageReport} />
                        <Route
                            exact
                            path="/locations"
                            component={ManageLocation}
                        />
                        <Route exact path="/leads" component={ManageLeads} />
                        <Route exact path="/brands" component={ManageBrand} />
                        <Route
                            exact
                            path="/workorders"
                            component={ManageWorkOrder}
                        />
                        <Route
                            exact
                            path="/documents"
                            component={ManageDocumentLibrary}
                        />
                        <Route exact path="/alerts" component={ManageAlert} />
                        <Route exact path="/codes" component={ManageCode} />

                        <Route
                            exact
                            path="/facility"
                            component={ManageFacility}
                        />
                        <Route
                            path="/training/:courseID?/:lessonID?/:quizID?"
                            component={Training}
                        />
                        <Route
                            exact
                            path="/manageTraining"
                            component={ManageTraining}
                        />
                        <Route
                            exact
                            path="/measurements"
                            component={ManageMeasurementPointList}
                        />
                        <Route
                            exact
                            path="/customermeasurements"
                            component={ManageMeasurementPointList}
                        />
                        <Route
                            exact
                            path="/job_comments"
                            component={ManageJobComment}
                        />
                        <Route
                            exact
                            path="/assetStatus"
                            // component={InstallBaseAnalyticsTableContainer}
                            render={(assetStatusProps: any) => (
                                <InstallBaseAnalyticsTableContainer
                                    {...assetStatusProps}
                                    colorButton={
                                        constants.colors[
                                            `${tile.color}Button` as keyof typeof constants.colors
                                        ]
                                    }
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/pmp"
                            // component={InstallBaseAnalyticsTableContainer}
                            render={(pmpProps: any) => (
                                <PMPAnalyticsTableLayout
                                    {...pmpProps}
                                    colorButton={
                                        constants.colors[
                                            `${tile.color}Button` as keyof typeof constants.colors
                                        ]
                                    }
                                    currentTile={tile}
                                />
                            )}
                        />
                        {/* new-route: asset_photos */}
                        <Route
                            exact
                            path="/asset_photos"
                            component={ManageAssetPhotos}
                        />
                        <Route
                            exact
                            path="/suggested_parts"
                            component={ManageSuggestedParts}
                        />
                        <Route
                            exact
                            path="/inventory_details"
                            component={ManageInventoryDetails}
                        />
                    </Switch>
                </Col>
            </Row>
        </Grid>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: any) => {
    return {
        user: state.user,
        showSideMenu: state.showSideMenu
    };
};

export default connect(mapStateToProps, { toggleSideMenu })(TwoPaneLayout);
