import { connect } from 'react-redux';

import { Ihistory } from '../../models';

import EditLeadActivityForm from './EditLeadActivityForm';

import {
    toggleEditLeadActivityModal,
    setSelectedLeadActivityFromID,
    saveLeadActivity,
    updateSelectedLeadActivity,
    updateLeadActivity,
    deleteLeadActivity
} from '../../actions/manageLeadsActions';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { manageLeadQueryParamsEnum } from './ManageLeads';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops {
    colorButton: string;
    queryParams: typeof manageLeadQueryParamsEnum;
    history: Ihistory;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedLeadActivity = state.manageLeads.selectedLeadActivity;
    const selectedLead = state.manageLeads.selectedLead;

    let modalTitle;
    if (selectedLeadActivity && selectedLeadActivity.id) {
        modalTitle = ownProps.t('manageLeads:editLeadActivityModalTitle');
    } else {
        modalTitle = ownProps.t('manageLeads:saveLeadActivityModalTitle');
    }

    return {
        className: 'edit-lead-activity',
        loading: selectIsLoading(state),
        show: state.manageLeads.showEditLeadActivityModal,
        selectedLeadActivity,
        selectedLead,
        title: modalTitle,
        secondModal: true
    };
};

export default withTranslation('manageLeads')(
    connect(mapStateToProps, {
        setSelectedLeadActivityFromID,
        updateSelectedLeadActivity,
        create: saveLeadActivity,
        delete: deleteLeadActivity,
        onCancel: toggleEditLeadActivityModal,
        toggleModal: toggleEditLeadActivityModal,
        onHide: toggleEditLeadActivityModal,
        updateLeadActivity
    })(WithCommonModal(EditLeadActivityForm))
);
