import { ImanageTrainingProgress, ImanageTrainingReducer } from '../models';
import { createTableFiltersWithName } from './commonReducers';
import initialState from './initialState';
import * as types from '../actions/actionTypes';
import { combineReducers } from 'redux';

function trainingProgress(
    state: { [key: string]: ImanageTrainingProgress } = initialState
        .manageTraining.trainingProgress,
    action: any
): { [key: string]: ImanageTrainingProgress } {
    switch (action.type) {
        case types.MANAGE_TRAINING_SUCCESS:
            return action.trainingProgress;
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.MANAGE_TRAINING_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

const manageTraining = combineReducers<ImanageTrainingReducer>({
    trainingProgress,
    totalPages,
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_TRAINING')
});

export default manageTraining;
