import { WithTranslation, withTranslation } from 'react-i18next';
import {
    addContactToFacility,
    clearUsersThatMachedEmail,
    deleteFacilityFromContact,
    findUserOrContactByEmail,
    getContactsByFacility,
    toggleFacilityContactModal,
    updateContactFromFacility,
    updateSelectedContact
} from '../../actions/manageFacilityActions';

import FacilityContactForm from './FacilityContactForm';
import { IfacilityContact } from '../../models';
import { WithCommonModal } from './WithCommonModal';
import { connect } from 'react-redux';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { selectSelectedFacilityID } from '../../reducers/facilitiesReducer';

type Iprops = {
    colorButton?: string;
    secondWideModal?: boolean;
    selectContact?: (contactID: string) => void;
    facilityID: string;
    addContactLead?: (contact: IfacilityContact) => void;
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const foundUsersOrContactsByID =
        state.manageFacility.foundUsersOrContactsByID;
    const selectedFacilityID = selectSelectedFacilityID(state);

    return {
        selectedFacilityID,
        loading: selectIsLoading(state),
        show: state.showFacilityContactModal,
        className: 'facility-contact-edit',
        title: ownProps.t('facilityContactForm.formTitle'),
        selectedContact: state.manageFacility.selectedContact,
        foundUsersOrContactsByID,
        usersByID: state.manageUser.usersByID,
        facilityContacts: state.manageFacility.contacts
    };
};

export default withTranslation('facility')(
    connect(mapStateToProps, {
        toggleModal: toggleFacilityContactModal,
        onSubmit: toggleFacilityContactModal,
        onHide: toggleFacilityContactModal,
        onCancel: toggleFacilityContactModal,
        addContactToFacility,
        getContactsByFacility,
        findUserOrContactByEmail,
        updateContactFromFacility,
        clearUsersThatMachedEmail,
        updateSelectedContact,
        deleteFacilityFromContact
    })(WithCommonModal(FacilityContactForm))
);
