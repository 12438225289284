import initialState, { initialDeficiencyCode } from './initialState';
import * as types from '../actions/actionTypes';
import {
    IdeficiencyCode,
    ImanageCodeReducer,
    ItableFiltersParams
} from '../models';
import {
    createSelectedIDWithName,
    createTableFiltersWithName,
    modalToggleWithName,
    createFormValuesWithName
} from './commonReducers';
import { pickBy, map, keyBy, filter, orderBy } from 'lodash';
import { combineReducers } from 'redux';

const cleanCodeObject = (code: any) => {
    return {
        ...initialDeficiencyCode,
        ...pickBy(code, property => property !== null)
    } as IdeficiencyCode;
};

export function deficiencyCodesByID(
    state: { [key: string]: IdeficiencyCode } = initialState.manageCode
        .deficiencyCodesByID,
    action: any
) {
    switch (action.type) {
        case types.LOAD_CODES_SUCCESS: {
            const newCodes = map(action.payload, (code: IdeficiencyCode) => {
                return cleanCodeObject(code);
            });
            return keyBy(newCodes, 'id');
        }
        case types.ADD_CODE_SUCCESS: {
            return { ...state, [action.payload.id]: action.payload };
        }
        case types.EDIT_CODE_SUCCESS:
            return { ...state, [action.payload.id]: action.payload };
        case types.REMOVE_CODE_SUCCESS: {
            const data = { ...state };
            delete data[action.payload.id];
            return data;
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageCode.deficiencyCodesByID;
        default:
            return state;
    }
}

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.CODE_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

const manageCode = combineReducers<ImanageCodeReducer>({
    deficiencyCodesByID,
    totalPages,
    selectedCodeID: (state, action) =>
        createSelectedIDWithName(state, action, 'CODE_ID'),
    showEditCodeModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_CODE'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_CODE'),
    codeFormValues: (state, action) =>
        createFormValuesWithName(state, action, 'MANAGE_CODE')
});

export default manageCode;

/*
 * SELECTORS
 */

export const selectVisibleDeficiencyCodes = (
    state: { [key: string]: IdeficiencyCode },
    tableFilters: ItableFiltersParams
) => {
    const filteredCodes = filter(state, code => {
        let shouldInclude = true;
        if (tableFilters.search) {
            const searchString = tableFilters.search.trim().toLowerCase();
            if (code.name.toLowerCase().indexOf(searchString) === -1) {
                shouldInclude = false;
            }
        }
        if (
            tableFilters.standard &&
            tableFilters.standard.value &&
            code.standardID !== tableFilters.standard.value
        ) {
            shouldInclude = false;
        }
        return shouldInclude;
    });
    return orderBy(filteredCodes, res => res.name, 'asc');
};
