import { combineReducers } from 'redux';
import { ItutorialReducer } from '../models';
import * as types from '../actions/actionTypes';

function viewedTours(state = [] as string[], action: any): string[] {
    switch (action.type) {
        case types.ADD_VIEWED_TOUR:
            if (!state.includes(action.payload)) {
                return [...state, action.payload];
            } else return state;
        case types.RESET_VIEWED:
            return [];
        default:
            return state;
    }
}

function run(state = false, action: any): boolean {
    switch (action.type) {
        case types.SET_IS_TUTORIAL_OPEN:
            return action.payload;
        case types.TOGGLE_TUTORIAL:
            return !state;
        default:
            return state;
    }
}

const tutorialReducer = combineReducers<ItutorialReducer>({
    viewedTours,
    run
});

export default tutorialReducer;
