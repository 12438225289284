/*
 * EditCustomerQuoteForm
 */

import * as React from 'react';

import { Button, Col } from 'react-bootstrap';
import {
    FieldConfig,
    FormArray,
    FormGenerator,
    FormGroup,
    GroupProps
} from 'react-reactive-form';
import { debounce, omit, forEach } from 'lodash';

import { FormUtil } from '../common/FormUtil';
import { IquoteFormValues } from '../../modelsForms';
import { Iquote, Ihistory, Ifacility, IoptionNumberValue } from '../../models';
import { constants } from '../../constants/constants';
import {
    initialQuote,
    initialFacility,
    initialUser
} from '../../reducers/initialState';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import {
    updateSelectedQuote,
    updateQuoteStatus,
    saveQuoteFiles,
    toggleQuotesDetailsModal
} from '../../actions/manageCustomerQuotesActions';
import { WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { manageQuoteQueryParamsEnum } from './manageQuotes';
import { removeQuery } from '../common/OtherUtils';
import { quoteStatusEnum } from '../../models-enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSecurityUsers } from '../../actions/manageUserActions';
import { getCloudDocument } from '../../actions/manageDocumentActions';

interface Iprops {
    // reusable
    loading: boolean;
    selectedQuote: Iquote;
    originalQuote?: Iquote;
    show: boolean;
    toggleModal: () => void;
    onHide: () => void;
    getCloudDocument: typeof getCloudDocument;
    toggleQuotesDetailsModal: typeof toggleQuotesDetailsModal;
    // other
    facility: Ifacility;
    colorButton: string;
    queryParams: typeof manageQuoteQueryParamsEnum;
    onChange: typeof updateSelectedQuote;
    onSubmit: typeof updateQuoteStatus;
    history: Ihistory;
    getSecurityUsers: typeof getSecurityUsers;
    saveQuoteFiles: typeof saveQuoteFiles;
    t: TFunction;
}

interface Istate {
    poFile: any;
    fieldConfig: FieldConfig;
}

class QuoteForm extends React.Component<Iprops & WithTranslation, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private onChangeDebounced: (quote: Iquote) => void;
    static defaultProps = {
        selectedQuote: initialQuote,
        facility: initialFacility,
        salesContact: initialUser
    };
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.onChangeDebounced = debounce(
            this.props.onChange,
            constants.formDebounceTime
        );
        this.state = {
            poFile: '',
            fieldConfig: this.buildFieldConfig()
        };
        // this.handleChecked = this.handleChecked.bind(this);
    }
    componentDidMount() {
        this.props.getSecurityUsers([
            constants.securityFunctions.SalesManager.id
        ]);
        this.initSelected();
    }

    componentDidUpdate(prevProps: Iprops & WithTranslation) {
        if (this.props.selectedQuote.id !== prevProps.selectedQuote.id) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
        if (
            this.props.selectedQuote.status !== prevProps.selectedQuote.status
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }
    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.props.onChange();
        removeQuery(
            manageQuoteQueryParamsEnum.selectedQuoteID,
            this.props.history
        );
    }

    /*
     * initSelected
     * if there is a query param, make sure it matches what is active in redux
     * if no query param and there is a selectedQuote.id, then reset what is in redux
     */
    initSelected = () => {
        if (
            this.props.queryParams.selectedQuoteID &&
            this.props.queryParams.selectedQuoteID !==
                this.props.selectedQuote.id
        ) {
            this.props.onChange(this.props.originalQuote);
        } else if (
            this.props.queryParams.selectedQuoteID === undefined &&
            this.props.selectedQuote.id.length
        ) {
            this.props.onChange();
        }
    };

    /*
     * itemToFormValues - take the selectedQuote and convert it to formValues
     */
    itemToFormValues = (): IquoteFormValues => {
        const { status, createDate } = this.props.selectedQuote;

        const cleanedQuote = omit(this.props.selectedQuote, [
            'leads',
            'signature',
            'salesUsers',
            'facility'
        ]);
        const selectedQuoteStatus = {
            value: status,
            label: this.props.t(quoteStatusEnum[status])
        };
        let contactNames = '';
        forEach(this.props.selectedQuote.salesUsers, (user, index) => {
            contactNames = contactNames += ` ${index > 0 ? '|' : ''} ${
                user.first
            } ${user.last}`;
        });

        return {
            ...cleanedQuote,
            selectedQuoteStatus,
            createDate: moment
                .utc(createDate)
                .local(true)
                .format(constants.momentDisplayFormat),
            contactNames,
            facilityName: this.props.facility.name
        };
    };

    /*
     * formValuesToItem - convert the formValues to the shape of the selectedQuote
     */
    formValuesToItem = (updatedValue?: { [key: string]: any }): Iquote => {
        let formValues = this.formGroup.value;
        if (updatedValue) {
            formValues = { ...formValues, ...updatedValue };
        }
        // const cleanedQuote = omit(this.props.selectedQuote);
        return {
            ...this.props.selectedQuote,
            ...FormUtil.getValues(formValues),
            poFile: this.state.poFile
        };
    };

    buildFieldConfig = (
        defaultValues: IquoteFormValues = this.itemToFormValues()
    ) => {
        const disabled = false;
        const { t } = this.props;

        // Field config to configure form
        const fieldConfigControls = {
            $field_0: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editCustomerQuoteForm.facility'),
                            colWidth: 12,
                            value: defaultValues.facilityName
                        }}
                    />
                )
            },
            $field_1: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editCustomerQuoteForm.createDate'),
                            colWidth: 4,
                            value: defaultValues.createDate
                        }}
                    />
                )
            },
            $field_2: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editCustomerQuoteForm.number'),
                            colWidth: 4,
                            value: defaultValues.quoteNumber
                        }}
                    />
                )
            },
            $field_3: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editCustomerQuoteForm.revision'),
                            colWidth: 4,
                            value: defaultValues.revision
                        }}
                    />
                )
            },
            $field_4: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editCustomerQuoteForm.contact'),
                            colWidth: 6,
                            value: defaultValues.contactNames
                        }}
                    />
                )
            },
            $field_po_number: {
                isStatic: false,
                render: () => (
                    <FormUtil.TextLabelStatic
                        meta={{
                            label: t('editCustomerQuoteForm.poNumber'),
                            colWidth: 6,
                            value: defaultValues.poNumber
                        }}
                    />
                )
            },
            $field_download: {
                isStatic: false,
                render: () => (
                    <Col
                        xs={12}
                        style={{ marginBottom: '10px', marginTop: '10px' }}
                    >
                        <Button
                            onClick={() =>
                                this.props.getCloudDocument(
                                    this.props.t,
                                    {
                                        documentID: this.props.selectedQuote.id,
                                        version: this.props.selectedQuote
                                            .cloudDocumentID
                                    },
                                    true
                                )
                            }
                            bsStyle={this.props.colorButton}
                        >
                            <FontAwesomeIcon
                                icon={['far', 'file-pdf']}
                                size="sm"
                            />{' '}
                            &nbsp;
                            {t('editCustomerQuoteForm.viewQuote')}
                        </Button>
                    </Col>
                )
            },
            $field_notes: {
                isStatic: false,
                render: () => (
                    <Col
                        xs={12}
                        style={{ marginBottom: '10px', marginTop: '10px' }}
                    >
                        <Button
                            bsStyle={this.props.colorButton}
                            type="button"
                            onClick={() => {
                                this.props.toggleQuotesDetailsModal();
                            }}
                        >
                            {t('editCustomerQuoteForm.notes')}
                        </Button>
                    </Col>
                )
            },
            comments: {
                render: FormUtil.RichTextEditor,
                meta: {
                    label: 'common:comments',
                    colWidth: 12,
                    name: 'comments',
                    required: false,
                    initialContent: defaultValues.comments
                },
                formState: { disabled, value: defaultValues.comments }
            },
            status: {
                render: FormUtil.Select,
                meta: {
                    options: FormUtil.convertEnumToOptions(quoteStatusEnum),
                    label: 'common:status',
                    colWidth: 12,
                    placeholder: 'typeSearchPlaceholder',
                    name: 'status',
                    shouldTranslate: true
                },
                formState: {
                    value: defaultValues.selectedQuoteStatus,
                    disabled
                }
            }
        } as { [key: string]: GroupProps };

        const acceptQuoteControls = {
            poNumber: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'editCustomerQuoteForm.enterPONumber',
                    colWidth: 12,
                    type: 'input',
                    name: 'poNumber',
                    required: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: defaultValues.poNumber, disabled }
            },

            poFile: {
                render: FormUtil.FileInput,
                meta: {
                    label: 'editCustomerQuoteForm.poFile',
                    accept: '.pdf',
                    type: 'file',
                    colWidth: 12,
                    name: 'poFile',
                    required: true,
                    onChange: this.onFileChange,
                    imageUrl: defaultValues.poFileUrl,
                    hasPreview: false,
                    hasDownload: true,
                    otherLabels: {
                        labelDownload: this.props.t(
                            'editCustomerQuoteForm.viewPo'
                        )
                    }
                }
                // options: {
                //   validators: FormUtil.validators.requiredWithTrim
                // },
            },
            authorizedName: {
                render: FormUtil.TextInput,
                meta: {
                    label: 'editCustomerQuoteForm.authorizedName',
                    colWidth: 12,
                    type: 'input',
                    name: 'authorizedName',
                    required: true
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: { value: defaultValues.authorizedName, disabled }
            },
            signature: {
                render: FormUtil.Signature,
                meta: {
                    label: 'editCustomerQuoteForm.signature',
                    colWidth: 12,
                    name: 'signature',
                    required: true
                },
                options: {
                    validators: FormUtil.validators.requiredBlob
                }
            },
            tos: {
                render: FormUtil.Checkbox,
                meta: {
                    label: 'editCustomerQuoteForm.tos',
                    colWidth: 12,
                    name: 'tos',
                    required: true
                },
                formState: {
                    value: defaultValues.tos,
                    disabled
                }
            }
        } as { [key: string]: GroupProps };

        let fieldConfig = {
            controls: { ...fieldConfigControls }
        };
        const currentStatus = defaultValues.selectedQuoteStatus as IoptionNumberValue;
        if (currentStatus.value === quoteStatusEnum.accepted) {
            fieldConfig = {
                controls: { ...fieldConfigControls, ...acceptQuoteControls }
            };
        }
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    onFileChange = (key: string, poFile: File) => {
        this.setState({ poFile }, () => {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        });
    };

    /*
     * (reusable)
     * set the values to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            // case 'status':{
            //   this.setState({fieldConfig: this.buildFieldConfig(this.itemToFormValues({status: value})) })
            // }
            default:
                this.onChangeDebounced(this.formValuesToItem({ [key]: value }));
                break;
        }
    };

    onSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        const quote = this.formValuesToItem();
        if (quote.status === quoteStatusEnum.accepted) {
            if (!quote.tos) {
                this.formGroup.markAsSubmitted();
                toastr.error(
                    this.props.t('toastMessage:tosUnchecked'),
                    '',
                    constants.toastrError
                );
                return;
            } else {
                this.props.saveQuoteFiles(this.props.selectedQuote);
            }
        } else {
            this.props.onSubmit(this.formValuesToItem());
        }
    };

    setForm = (form: FormGroup | FormArray) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix Quote-form beacon-form ${this.props.colorButton}`;

        return (
            <form onSubmit={this.onSubmit} className={formClassName}>
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left quote-cancel-btn"
                        onClick={this.props.toggleModal}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        bsStyle={this.props.colorButton}
                        type="submit"
                        disabled={this.props.loading}
                        className="quote-save-btn"
                    >
                        {t('common:save')}
                    </Button>
                </Col>
            </form>
        );
    }
}
export default QuoteForm;
