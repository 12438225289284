import { MigrationManifest } from 'redux-persist';
import initialState from '../reducers/initialState';
import { IinitialState } from '../reducers';

/*
 * Migration 1 runs when upgrading from 0 to 1
 * 0.0.1 = 1  and 0.2.2 = 22
 */

const typedMigrations = {
    180: state => {
        console.info('running migration 180');
        const { productInfo } = initialState;
        return {
            ...state,
            productInfo
        };
    },
    192: state => {
        console.info('running migration 192');
        const { tutorial } = initialState;
        return {
            ...state,
            tutorial
        };
    },
    193: state => {
        console.info('running migration 193');
        const { manageProductQueue } = initialState;
        return {
            ...state,
            manageProductQueue
        };
    },
    211: state => {
        console.info('running migration 211');
        const { manageJob } = initialState;
        return {
            ...state,
            manageJob
        };
    },
    222: state => {
        console.info('running migration 222');
        const { manageUser, manageUserQueue } = initialState;
        return {
            ...state,
            manageUser,
            manageUserQueue
        };
    },
    223: state => {
        console.info('running migration 223');
        const { customersWithFacilities } = initialState;
        return {
            ...state,
            customersWithFacilities
        };
    }
} as { [key: string]: (state: IinitialState) => IinitialState };

const untypedMigrations = typedMigrations as any;
export const migrations = untypedMigrations as MigrationManifest;
