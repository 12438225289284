import * as types from './actionTypes';

import { AxiosRequestConfig } from 'axios';
import {
    IeditFacilityFormValues,
    IfacilityContractFormValues
} from '../modelsForms';
import {
    IfacilityContact,
    IfacilityContract,
    ItableFiltersParams,
    ThunkResult
} from '../models';
import { omit, orderBy, unionBy, map } from 'lodash';

import API from '../constants/apiEndpoints';
import { FormUtil } from '../components/common/FormUtil';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { constants } from '../constants/constants';
import { initialContact } from '../reducers/initialState';
import { msalFetch } from '../components/auth/Auth-Utils';
import { selectUsersAndContactsToOptions } from '../reducers/manageCustomerAndFacilityReducer';
import { TFunction } from 'i18next';
import { toastr } from 'react-redux-toastr';

/*
 * the front end is sorting and filtering so we send pagingType none
 * the only time this is used is when a user searches by facility name
 */

export const updateFacilityFormValue = (formValues: {
    [key: string]: any;
}) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_FACILITY,
    formValues
});
export const setFacilityFormValues = (
    formValues: Partial<IeditFacilityFormValues>
) => ({
    type: types.SET_FORM_VALUES_MANAGE_FACILITY,
    formValues
});

export const updateSelectedContact = (
    contact: IfacilityContact = initialContact
) => ({
    type: types.UPDATE_SELECTED_CONTACT,
    contact
});
export const setSelectedFacilityID = (id: string) => ({
    type: types.SET_SELECTED_FACILITY_ID,
    id
});

export const clearSelectedFacilityID = () => ({
    type: types.CLEAR_SELECTED_FACILITY_ID
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_FACILITY,
    filters
});

export const toggleFacilityContractModal = () => ({
    type: types.TOGGLE_MODAL_FACILITY_CONTRACT
});

export const updateFacilityContract = (
    facilityContract: IfacilityContract
): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(updateFacilityFormValue({ contract: facilityContract }));
    };
};

export const updateFacilityContractFormValue = (formValue: {
    [key: string]: any;
}) => ({
    type: types.UPDATE_FORM_VALUES_MANAGE_FACILITY_CONTRACT,
    formValue
});
export const setFacilityContractFormValues = (
    formValues: IfacilityContractFormValues
) => ({
    type: types.SET_FORM_VALUES_MANAGE_FACILITY_CONTRACT,
    formValues
});

export const toggleFacilityContactModal = () => ({
    type: types.TOGGLE_MODAL_FACILITY_CONTACT
});

export const getContactsByFacility = (facilityID: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const url = API.contact.getByFacility;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { id: facilityID }
        };
        return msalFetch(url, axiosOptions)
            .then(data => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_CONTACTS_BY_FACILITY_SUCCESS,
                        payload: data.data
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_CONTACTS_BY_FACILITY_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'getContactsByFacility');
                console.error(error);
            });
    };
};

export const findUserOrContactByEmail = (search: string): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const url = API.contact.findUserOrContactByEmail;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { search }
        };
        return msalFetch(url, axiosOptions)
            .then(data => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    const contactsWithEmail = map(
                        data.data.contacts,
                        contact => {
                            if (
                                contact.userID &&
                                contact.userID.length &&
                                contact.user
                            ) {
                                const user = contact.user;
                                return {
                                    ...contact,
                                    firstName: user.first,
                                    lastName: user.last,
                                    title: user.position,
                                    email: user.email,
                                    phone: user.phone
                                };
                            } else {
                                return contact;
                            }
                        }
                    );
                    const combined = unionBy(
                        contactsWithEmail,
                        data.data.users,
                        'email'
                    );
                    dispatch({
                        type: types.FIND_USERS_OR_CONTACT_BY_EMAIL_SUCCESS,
                        payload: combined
                    });
                    const options = selectUsersAndContactsToOptions(combined);
                    const sortedOptions = orderBy(options, 'label');
                    return sortedOptions;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.FIND_USERS_OR_CONTACT_BY_EMAIL_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'getContactsByFacility');
                console.error(error);
            });
    };
};

export const clearUsersThatMachedEmail = () => ({
    type: types.CLEAR_USERS_MATCHED_EMAIL
});

export const setSelectedContactID = (id: string): ThunkResult<any> => {
    return dispatch => {
        dispatch(clearUsersThatMachedEmail());
        dispatch({ type: types.SET_SELECTED_CONTACT_ID, id });
    };
};

export const mergeFacilitiesAPI = async (
    primaryFacilityID: string,
    secondaryFacilityIDs: string[]
) => {
    beginAjaxCall();
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: {
            primaryFacilityID,
            secondaryFacilityIDs
        }
    };
    const url = API.POST.facility.merge;
    return msalFetch(url, axiosOptions)
        .then(data => {
            endAjaxCall();

            if (!data.data) {
                throw new Error('missing data');
            } else {
                return true;
            }
        })
        .catch((error: any) => {
            console.error(error);
            endAjaxCall();
            return false;
        });
};

/*
 * addContactToFacility
 */

export const addContactToFacility = (
    contact: IfacilityContact,
    facilityID: string
): ThunkResult<any> => {
    return (dispatch, getState) => {
        if (contact.id.length) {
            dispatch(
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                addContactFacility(contact, facilityID)
            );
            return Promise.resolve(contact.id);
        } else {
            dispatch(beginAjaxCall());
            const cleanedContact = omit(contact, 'id');
            const axiosOptions: AxiosRequestConfig = {
                method: 'post',
                data: cleanedContact
            };
            const url = API.contact.add;
            return msalFetch(url, axiosOptions)
                .then(data => {
                    if (!data.data) {
                        throw new Error('missing data');
                    } else {
                        dispatch({
                            type: types.ADD_CONTACT_SUCCESS,
                            payload: data.data
                        });
                        return data.data as IfacilityContact;
                    }
                })
                .catch((error: any) => {
                    dispatch({
                        type: types.ADD_CONTACT_FAILED,
                        error,
                        axiosOptions
                    });
                    constants.handleError(error, 'addContactToFacility');
                    console.error(error);
                });
        }
    };
};

export const clearSelectedContactID = () => ({
    type: types.CLEAR_SELECTED_CONTACT_ID
});

export const deleteFacilityFromContact = (
    contactID: string,
    facilityID: string,
    t: TFunction
): ThunkResult<any> => {
    return (dispatch, getState) => {
        const onOk = () => {
            dispatch(beginAjaxCall());
            const url = API.contact.deleteFacility.replace('{id}', contactID);
            const axiosOptions: AxiosRequestConfig = {
                method: 'post',
                params: FormUtil.toUrlSearchParams({ facilityId: facilityID })
            };
            return msalFetch(url, axiosOptions)
                .then(data => {
                    dispatch({
                        type: types.DELETE_CONTACT_FACILITY_SUCCESS,
                        payload: contactID
                    });
                })
                .catch((error: any) => {
                    dispatch({
                        type: types.DELETE_CONTACT_FACILITY_FAILED,
                        error,
                        axiosOptions
                    });
                    constants.handleError(error, 'deleteContactFromFacility');
                    console.error(error);
                });
        };
        const toastrConfirmOptions = {
            onOk,
            onCancel: () => console.info('CANCEL clicked'),
            okText: t('facilityContactForm.tableOkButton'),
            cancelText: t('facilityContactForm.tableCancelButton')
        };
        toastr.confirm(
            t('facilityContactForm.deleteContactsConfirmTitle'),
            toastrConfirmOptions
        );
    };
};

export const updateContactFromFacility = (
    contact: IfacilityContact
): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const url = `${API.contact.update}/${contact.id}`;
        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: { ...contact, contactFacilities: [] } // TODO we can remove contactFacilities everywhere since the front end is no longer maintaining these
        };

        return msalFetch(url, axiosOptions)
            .then(data => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.UPDATE_CONTACT_SUCCESS,
                        payload: contact
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.UPDATE_CONTACT_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'updateContactFromFacility');
                console.error(error);
            });
    };
};

export const addContactFacility = (
    contact: IfacilityContact,
    facilityID: string
): ThunkResult<any> => {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const url = API.contact.addFacility.replace('{id}', contact.id);
        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            params: FormUtil.toUrlSearchParams({ facilityId: facilityID })
        };
        return msalFetch(url, axiosOptions)
            .then(data => {
                dispatch({
                    type: types.UPDATE_CONTACT_SUCCESS,
                    payload: contact
                });
            })
            .catch((error: any) => {
                dispatch({
                    type: types.UPDATE_CONTACT_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'updateContactFromFacility');
                console.error(error);
            });
    };
};
