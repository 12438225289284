/*
 * Common Modal displays the modal
 */

import * as React from 'react';
import { IPropsCommonModal } from '../CommonModal';
import CommonMobileModal from './CommonMobileModal';

export const WithCommonMobileModal = <P extends object>(
    WrappedComponent: React.ComponentType<P>
) => {
    const commonModalWrap: React.FC<P & IPropsCommonModal> = props => (
        <CommonMobileModal {...props}>
            <WrappedComponent {...props} />
        </CommonMobileModal>
    );

    return commonModalWrap;
};
