import { connect } from 'react-redux';

import QuoteDetailsForm from '../QuoteDetailsForm';
import { Ihistory } from '../../../models';
import { IinitialState } from '../../../reducers';

import { WithTranslation, withTranslation } from 'react-i18next';
import { getSecurityUsers } from '../../../actions/manageUserActions';
import { initialFacility } from '../../../reducers/initialState';
import { manageQuoteQueryParamsEnum } from '../manageQuotes';
import { selectIsLoading } from '../../../reducers/commonReducers';
import { toggleQuotesDetailsModal } from '../../../actions/manageCustomerQuotesActions';
import { WithCommonMobileModal } from '../../common/mobile/WithCommonMobileModal';

type Iprops = {
    colorButton: string;
    queryParams: typeof manageQuoteQueryParamsEnum;
    history: Ihistory;
    secondModal?: boolean;
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const { selectedQuote } = state.manageCustomerQuotes;
    const facility = selectedQuote.facilityID
        ? state.facilities[selectedQuote.facilityID]
        : initialFacility;

    return {
        show: state.manageCustomerQuotes.showQuoteDetailsModal,
        loading: selectIsLoading(state),
        selectedQuote,
        originalQuote:
            state.manageCustomerQuotes.quotesByID[
                ownProps.queryParams.selectedQuoteID
            ],
        className: 'facility-contact-edit',
        title: ownProps.t('editCustomerQuoteForm.notesTitle'),
        secondModal: ownProps.secondModal ? true : false,
        facility
    };
};

export default withTranslation('manageQuotes')(
    connect(mapStateToProps, {
        onCancel: toggleQuotesDetailsModal,
        onHide: toggleQuotesDetailsModal,
        toggleModal: toggleQuotesDetailsModal,
        getSecurityUsers
    })(WithCommonMobileModal(QuoteDetailsForm))
);
