/*
 * Manage Product Modal
 */
import { connect } from 'react-redux';

import { Iproduct, IproductQueueObject } from '../../models';
import {
    saveProduct,
    toggleEditProductModal,
    toggleSearchNewProductsModal
} from '../../actions/manageInventoryActions';
import {
    getPhotosByProduct,
    uploadProductPhoto,
    deletePhoto
} from '../../actions/manageAssetPhotosActions';
import { updateQueueProduct } from '../../actions/manageProductQueueActions';
import EditProductForm from './EditProductForm';
import {
    getCanEditProducts,
    getCanCreateProducts
} from '../../reducers/userReducer';
import { WithCommonModal } from '../common/WithCommonModal';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { RouteComponentProps, withRouter } from 'react-router';

interface Iprops {
    selectedItem: Iproduct;
    selectedQueueObject: IproductQueueObject;
    colorButton: any;
    secondModal?: boolean;
    secondWideModal?: boolean;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation & RouteComponentProps
) => {
    const { selectedFacilityID } = state;
    const canEditProducts = getCanEditProducts(state.user);
    const canCreateProducts = getCanCreateProducts(state.user);
    let modalTitle;
    if (ownProps.selectedQueueObject && ownProps.selectedQueueObject.id) {
        modalTitle = ownProps.t('manageProductQueue:editModalTitle');
    } else if (ownProps.selectedItem && ownProps.selectedItem.id) {
        modalTitle = ownProps.t('manageInventory:editModalTitle');
    } else {
        modalTitle = ownProps.t('manageInventory:saveModalTitle');
    }

    const isEditMode = ownProps.selectedItem?.id.length > 0;

    return {
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageInventory.showEditProductModal,
        productInfo: state.productInfo,
        tableFilters: state.manageInventory.tableFilters,
        canEditProducts,
        canCreateProducts,
        className: 'edit-product',
        title: modalTitle,
        selectedItem: ownProps.selectedItem,
        selectedFacilityID,
        selectedQueueObject: ownProps.selectedQueueObject,
        secondWideModal: ownProps.secondWideModal,
        photosByProduct:
            state.manageAssetPhotos.photosByProduct[ownProps.selectedItem.id] ??
            [],
        isEditMode
    };
};

export default withTranslation('manageInventory')(
    withRouter(
        connect(mapStateToProps, {
            saveProduct,
            toggleModal: toggleEditProductModal,
            onHide: toggleEditProductModal,
            onCancel: toggleEditProductModal,
            updateQueueProduct,
            toggleSearchNewProductsModal,
            getPhotosByProduct,
            uploadProductPhoto,
            deletePhoto
        })(WithCommonModal(EditProductForm))
    )
);
