import * as types from './actionTypes';

export function setLoginRedirect() {
    return (dispatch: any, getState: any) => {
        dispatch({ type: types.SET_REDIRECT_REFERRER });
        return Promise.resolve(true);
    };
}
export const removeLoginRedirect = () => ({
    type: types.REMOVE_REDIRECT_REFERRER
});

export const setRedirectPathname = (
    pathname: string,
    setRedirect?: boolean
) => ({
    type: types.SET_REDIRECT_PATHNAME,
    pathname,
    setRedirect
});
