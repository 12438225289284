/*
 * UserForm
 * User signs up directly to the platform
 */

import * as React from 'react';
import {
    FormGenerator,
    AbstractControl,
    FieldConfig,
    FormGroup
} from 'react-reactive-form';
import { Col, Button, Row } from 'react-bootstrap';
import { FormUtil } from '../common/FormUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { forEach } from 'lodash';
import { constants } from '../../constants/constants';

interface Iprops extends React.Props<SearchTableForm> {
    fieldConfig: FieldConfig;
    handleSubmit: any;
    loading: boolean;
    showSearchButton?: boolean;
    colorButton: string;
    t: TFunction;
    onValueChanges?: (value: any, key: string) => void;
    subscribeValueChanges?: boolean;
    showAdditionalFiltersButton?: boolean;
    showAdditionalFilters?: boolean;
    hasActiveAdditionalFilters?: boolean;
    xs?: number;
    lg?: number;
}

interface Istate {
    fieldConfig: FieldConfig;
}

export default class SearchTableForm extends React.Component<Iprops, Istate> {
    private formGroup: FormGroup | any;
    private subscription: any;
    private showBtn: boolean;
    // private onValueChangesDebounced?: (value: any, key: string) => void;  // we don't do this because we want some actions to be as fast as possible
    constructor(props: Iprops) {
        super(props);
        this.showBtn =
            typeof this.props.showSearchButton === 'undefined'
                ? false
                : this.props.showSearchButton;
        this.state = {
            fieldConfig: { controls: {} }
        };
    }

    componentDidMount() {
        this.setState(
            {
                fieldConfig: FormUtil.translateForm(
                    this.props.fieldConfig,
                    this.props.t
                )
            },
            () => {
                this.handleUpdatedFieldConfig();
            }
        );
    }
    componentDidUpdate(prevProps: Iprops) {
        if (
            JSON.stringify(prevProps.fieldConfig) !==
            JSON.stringify(this.props.fieldConfig)
        ) {
            // console.info('search field config changed', this.props.fieldConfig);
            this.setState(
                {
                    fieldConfig: FormUtil.translateForm(
                        this.props.fieldConfig,
                        this.props.t
                    )
                },
                () => {
                    this.handleUpdatedFieldConfig();
                }
            );
        }
    }
    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    handleUpdatedFieldConfig = () => {
        forEach(this.state.fieldConfig.controls, (input: any, key) => {
            if (key.indexOf('$field_') !== -1) {
                // skip elements that are display only
                return;
            }

            if (input.meta && input.meta.defaultValue) {
                this.formGroup.patchValue({ [key]: input.meta.defaultValue });
            }

            if (this.props.subscribeValueChanges) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        if (this.props.onValueChanges) {
                            this.props.onValueChanges(value, key);
                        }
                    });
            }
        });
    };

    handleToggleAdditionalFilters = () => {
        if (this.props.onValueChanges) {
            this.props.onValueChanges(
                !this.props.showAdditionalFilters,
                'showAdditionalFilters'
            );
        }
    };

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.handleSubmit(this.formGroup.value);
    };

    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
    };

    render() {
        return (
            <Row className="search-table-form">
                <form onSubmit={this.handleSubmit}>
                    <Col
                        id="filters"
                        xs={this.props.xs || 12}
                        lg={this.props.lg || 12}
                    >
                        <FormGenerator
                            onMount={this.setForm}
                            fieldConfig={this.state.fieldConfig}
                        />
                    </Col>
                    {!!this.props.showAdditionalFiltersButton && (
                        <Col xs={1} lg={1} style={{ marginTop: '15px' }}>
                            <Button
                                bsStyle="link"
                                style={{
                                    fontSize: '2em',
                                    color: this.props.hasActiveAdditionalFilters
                                        ? constants.colors.orange
                                        : ''
                                }}
                                onClick={this.handleToggleAdditionalFilters}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        this.props.showAdditionalFilters
                                            ? ['far', 'angle-double-down']
                                            : ['far', 'angle-double-right']
                                    }
                                />
                            </Button>
                        </Col>
                    )}
                    {this.props.children}
                    {this.showBtn && (
                        <Col xs={1} className="search-form-button">
                            <Button
                                bsStyle={this.props.colorButton}
                                bsSize="sm"
                                type="submit"
                                disabled={this.props.loading}
                            >
                                <FontAwesomeIcon icon="search" />
                            </Button>
                        </Col>
                    )}
                </form>
            </Row>
        );
    }
}
