import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import { uniqBy } from 'lodash';

interface Iprops {
    onChange: (value: any) => void;
    initialContent: string[];
}

const components = {
    DropdownIndicator: null
};

const createOption = (label: string) => ({
    label,
    value: label
});

export default class CreatableMultiTextInput extends Component<Iprops, {}> {
    state = {
        inputValue: '',
        value: []
    };

    componentDidMount() {
        this.setState({ value: this.props.initialContent });
    }
    handleChange = (value: any) => {
        this.setState({ value });
        this.props.onChange(value);
    };
    handleInputChange = (inputValue: string) => {
        this.setState({ inputValue });
    };
    handleKeyDown = (event: React.KeyboardEvent) => {
        const { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab': {
                event.preventDefault();
                const newValue = value
                    ? uniqBy([...value, createOption(inputValue)], 'value')
                    : [createOption(inputValue)];
                this.setState({
                    inputValue: '',
                    value: newValue
                });
                this.props.onChange(newValue);
            }
        }
    };
    render() {
        const { inputValue, value } = this.state;
        return (
            <CreatableSelect
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                placeholder="Type something and press enter..."
                value={value}
            />
        );
    }
}
