import { initialDocumentType } from './initialState';
import * as types from '../actions/actionTypes';
import { IDocumentType, ImanageDocumentTypeReducer } from '../models';
import { pickBy, map, keyBy } from 'lodash';
import {
    createSelectedIDWithName,
    modalToggleWithName
} from './commonReducers';
import { combineReducers } from 'redux';
import { IinitialState } from '.';

export const documentTypeSelector = (state: IinitialState) =>
    state.manageDocumentType.documentTypesByID;

export function documentTypesByID(
    state: { [key: string]: IDocumentType } = {},
    action: any
): { [key: string]: IDocumentType } {
    switch (action.type) {
        case types.LOAD_DOCUMENT_TYPES_SUCCESS: {
            const newDocumentTypes = map(action.payload, type => {
                return {
                    ...initialDocumentType,
                    ...pickBy(type, (property, key) => property !== null)
                };
            }) as IDocumentType[];
            return { ...state, ...keyBy(newDocumentTypes, 'id') };
        }
        default:
            return state;
    }
}

const documentTypeManage = combineReducers<ImanageDocumentTypeReducer>({
    documentTypesByID,
    selectedDocumentTypeID: (state, action) =>
        createSelectedIDWithName(state, action, 'DOCUMENT_TYPE_ID')
});

export default documentTypeManage;
