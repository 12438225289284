/*
 * Manage Report Modal and Container
 */

import { connect } from 'react-redux';
import {
    runDailyRoundsReport,
    runReport,
    toggleEditReportModal,
    updateReport
} from '../../actions/manageReportActions';
import {
    jobTypesIdEnum,
    reportTypeEnum,
    reportTypeIdEnum
} from '../../models-enums';
import {
    initialDefaultReport,
    initialFacility
} from '../../reducers/initialState';
import { getJobOptionsFromFilters } from '../../reducers/manageJobReducer';
import { EditReportForm } from './ManageReportForm';
import { isMeasurementPointBasedReport } from '../../reducers/manageReportReducer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { selectSelectedFacility } from '../../reducers/facilitiesReducer';

interface Iprops {
    colorButton: any;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const { selectedDefaultReportID } = state.manageReport;
    const selectedDefaultReport =
        state.manageReport.defaultReportsByID[selectedDefaultReportID] ||
        initialDefaultReport;

    const isMeasurementBasedOnly: boolean = isMeasurementPointBasedReport(
        selectedDefaultReport
    );
    const isServicePlanReport =
        selectedDefaultReport.id === reportTypeIdEnum.servicePlan;

    const jobFilters: { [key: string]: any } = {
        facilityID: state.selectedFacilityID,
        reportType: isServicePlanReport
            ? undefined
            : selectedDefaultReport.reportType,
        measurementBasedOnly: isMeasurementBasedOnly,
        jobTypeID: isServicePlanReport ? jobTypesIdEnum.servicePlan : undefined
    };

    if (selectedDefaultReport.reportType === reportTypeEnum.annualInspection) {
        jobFilters.jobTypeID = jobTypesIdEnum.Inspection;
    }

    const jobOptions = getJobOptionsFromFilters(
        state,
        { filters: jobFilters },
        ownProps.t
    );

    let selectedFacility = selectSelectedFacility(state) || initialFacility;
    return {
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageReport.showEditReportModal,
        selectedItem: state.manageReport.selectedReport,
        selectedDefaultReport,
        jobOptions,
        className: 'job-edit',
        title: selectedDefaultReport.name,
        selectedFacilityID: selectedFacility.id
    };
};

export default withTranslation('manageReport')(
    connect(mapStateToProps, {
        updateReport,
        runReport,
        runDailyRoundsReport,
        toggleModal: toggleEditReportModal,
        onHide: toggleEditReportModal,
        onCancel: toggleEditReportModal
    })(WithCommonModal(EditReportForm))
);
