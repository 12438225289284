import * as React from 'react';
import { Button, Col } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CommonModal from '../common/CommonModal';
import {
    getFacilities,
    getSelectedFacilityID
} from '../../reducers/facilitiesReducer';
import { constants } from '../../constants/constants';

import { toastr } from 'react-redux-toastr';
import { SelectFacilityContainer } from './SearchFacility/SearchFacilityContainer';
import { initialFacility } from '../../reducers/initialState';

interface Iprops {
    showModal: boolean;
    hideModal: () => void;
    colorButton: string;
    moveFacility: (primary: string) => void;
}

const SearchNewFacilityModal: React.FC<Iprops & WithTranslation> = props => {
    const { t } = props;
    const allFacilities = useSelector(getFacilities);
    const selectedFacilityID = useSelector(getSelectedFacilityID);
    const [selectedFacility, setSelectedFacility] = React.useState(
        initialFacility
    );

    const merge = () => {
        if (selectedFacility.id === selectedFacilityID) {
            toastr.warning(
                t('warning'),
                t('Select a different Facility'),
                constants.toastrWarning
            );
        } else if (
            selectedFacility === undefined ||
            selectedFacility.id === ''
        ) {
            toastr.warning(
                t('warning'),
                t('Select the primary facility'),
                constants.toastrWarning
            );
        } else if (selectedFacilityID === selectedFacility.id) {
            toastr.warning(
                t('warning'),
                t('You must select a new facility'),
                constants.toastrWarning
            );
        } else {
            const toastrConfirmOptions = {
                onOk: () => {
                    props.moveFacility(selectedFacility.id);
                },
                onCancel: () => console.info('CANCEL clicked'),
                okText: t('Ok'),
                cancelText: t('Cancel')
            };
            toastr.confirm(
                `Are you sure you want to move these install bases to ${selectedFacility.name}?`,
                toastrConfirmOptions
            );
        }
    };

    const facilitySelected = (facilityID: string) => {
        console.info('facilitySelected', facilityID, allFacilities[facilityID]);
        setSelectedFacility(allFacilities[facilityID]);
    };

    return (
        <>
            <CommonModal
                show={props.showModal}
                className=""
                onHide={() => props.hideModal}
                title={t('changeFacility')}
                container={document.getElementById('two-pane-layout')}
            >
                <div>
                    <p style={{ marginLeft: '15px' }}>
                        Selecting a new facility here will merge all of the
                        installs from the original facility into the selected
                        facility.
                    </p>
                    <SelectFacilityContainer
                        classNameOverride={'select-facility-override'}
                        t={props.t}
                        facilitySelected={facilitySelected}
                    ></SelectFacilityContainer>

                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={props.hideModal}
                        >
                            {t('close')}
                        </Button>
                        <Button bsStyle={props.colorButton} onClick={merge}>
                            {t('confirm')}
                        </Button>
                    </Col>
                </div>
            </CommonModal>
        </>
    );
};

export default withTranslation('manageInventory')(SearchNewFacilityModal);
