import * as React from 'react';
import * as moment from 'moment';

import { withTranslation, WithTranslation } from 'react-i18next';

import {
    ImanageTeamReducer,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import ReactTable, { FinalState, RowInfo, SortingRule } from 'react-table';
import { emptyTile } from '../../reducers/initialState';
import {
    getUserManage,
    setTableFilter,
    toggleEditTeamUserModal,
    setSelectedTeamUserID
} from '../../actions/manageTeamActions';
import { values } from 'lodash';

import { Banner } from '../common/Banner';
/*
 * hopsital Managers manage their team
 * Note: did minimal renaming from the UserManage component
 */
import { Button } from 'react-bootstrap';
import EditTeamMemberModal from './EditTeamMemberModal';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router-dom';
import SearchTableForm from '../common/SearchTableForm';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { TableUtil } from '../common/TableUtil';
import { closeAllModals } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here

    loading: boolean;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    toggleEditTeamUserModal: typeof toggleEditTeamUserModal;
    getUserManage: typeof getUserManage;
    closeAllModals: typeof closeAllModals;
    userManage: ImanageTeamReducer;
    showEditUserModal: boolean;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    tableData: Iuser[];
    setSelectedTeamUserID: typeof setSelectedTeamUserID;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
}

class TeamManage extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    public columns: any[];
    public searchFieldConfig: FieldConfig;
    public buttonInAction = false;
    private setTableFilterTimeout: any;

    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: null,
            currentTile: emptyTile
        };
        this.columns = TableUtil.translateHeaders(
            [
                {
                    id: 'name',
                    Header: 'user:name',
                    // accessor: "user",
                    Cell: (row: any) => (
                        <span>
                            {row.original.first} {row.original.last}
                        </span>
                    )
                },
                {
                    Header: 'user:email',
                    accessor: 'email'
                },
                {
                    Header: 'manager',
                    accessor: ({ hasTeamMembers }: Iuser) => {
                        return hasTeamMembers ? 'Yes' : 'No';
                    },
                    id: 'manager'
                },
                {
                    Header: 'common:login',
                    accessor: ({ lastLoginDate }: Iuser) => {
                        return lastLoginDate
                            ? moment
                                  .utc(lastLoginDate)
                                  .local(true)
                                  .format('DD-MMM-YY LT')
                            : 'n/a';
                    },
                    id: 'login'
                }
            ],
            this.props.t
        );
        this.searchFieldConfig = {
            controls: {
                search: {
                    render: FormUtil.TextInputWithoutValidation,
                    meta: {
                        label: 'common:search',
                        colWidth: 4,
                        placeholder: 'searchPlaceholder',
                        defaultValue: this.props.tableFilters.search
                    }
                }
            }
        };
    }
    componentDidMount() {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        // refresh the userManage every time the component mounts
        this.props.getUserManage();
    }
    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditUserModal !== this.props.showEditUserModal &&
            !this.props.showEditUserModal
        ) {
            this.setState({ selectedRow: null });
        }
        // automatically get inventory every time a fitler changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.getUserManage();
            this.setState({ selectedRow: null });
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
    }
    componentWillUnmount() {
        this.props.closeAllModals();
    }

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        // console.info("ROWINFO", rowInfo);
        if (rowInfo) {
            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    if (!this.buttonInAction) {
                        this.setState({
                            selectedRow: rowInfo.index
                        });
                        this.props.setSelectedTeamUserID(rowInfo.original.id);
                        this.props.toggleEditTeamUserModal();
                    }
                },
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };
    /*
     * (reusable)
     * get the next or previous page of data.  the table is 0 indexed but the API is not
     */
    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'search':
                clearTimeout(this.setTableFilterTimeout);
                this.setTableFilterTimeout = setTimeout(() => {
                    this.props.setTableFilter({ search: value, page: 1 }); // this causes performance issues so we use a rudamentary debounce
                }, constants.tableSearchDebounceTime);
                break;
            default:
                break;
        }
    };
    /*
     * (reusable)
     * set the sorted changes to redux
     */
    onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        this.props.setTableFilter({ sorted: newSorted });
        this.setState({ selectedRow: {} });
    };

    render() {
        const { t } = this.props;
        return (
            <div className="user-manage">
                <Banner
                    title={t('bannerTitle')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                >
                    <SelectFacilityContainer
                        t={this.props.t}
                        classNameOverride={''}
                    />
                </Banner>
                <SearchTableForm
                    fieldConfig={this.searchFieldConfig}
                    handleSubmit={this.props.getUserManage}
                    loading={this.props.loading}
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                    t={this.props.t}
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                />
                <Button
                    className="table-add-button"
                    bsStyle="link"
                    onClick={() => {
                        this.props.setSelectedTeamUserID('');
                        this.props.toggleEditTeamUserModal();
                    }}
                >
                    {t('manageTeam:newTeamMember')}
                </Button>
                <ReactTable
                    data={this.props.tableData}
                    onSortedChange={this.onSortedChanged}
                    columns={this.columns}
                    getTrProps={this.getTrProps}
                    pageSize={
                        this.props.tableData.length >= 10
                            ? this.props.tableData.length
                            : 10
                    }
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    pages={this.props.userManage.totalPages}
                    page={this.props.tableFilters.page - 1}
                    showPageSizeOptions={false}
                    className={`beacon-table -highlight ${this.state.currentTile.color}`}
                    previousText={t('common:previous')}
                    nextText={t('common:next')}
                    onPageChange={this.onPageChange}
                    sortable={false}
                    noDataText={t('common:noDataText')}
                    resizable={false}
                />
                <EditTeamMemberModal
                    colorButton={
                        constants.colors[
                            `${this.state.currentTile.color}Button` as keyof typeof constants.colors
                        ]
                    }
                />
            </div>
        );
    }
}

/*
 * AddCustomerModal will connect to redux, impliment CommonModal, as well as AddCustomerForm
 */

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        user: state.user,
        userManage: state.manageTeam,
        loading: selectIsLoading(state),
        showEditUserModal: state.manageTeam.showEditTeamModal,
        tableData: values(state.manageTeam.teamMembersByID),
        tableFilters: state.manageTeam.tableFilters
    };
};
export default withTranslation('manageTeam')(
    connect(mapStateToProps, {
        getUserManage,
        toggleEditTeamUserModal,
        closeAllModals,
        setTableFilter,
        setSelectedTeamUserID
    })(TeamManage)
);
