import { debounce, filter, orderBy } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReactTable, {
    Column,
    FinalState,
    RowInfo,
    SortingRule
} from 'react-table';
import {
    getFSEUsers,
    getJobs,
    setTableFilter,
    updateSelectedJob
} from '../../actions/manageJobActions';
import {
    Ifacility,
    Ijob,
    IjobWorkOrder,
    ImanageJobReducer,
    Ioption,
    Itile,
    Iuser
} from '../../models';
import {
    jobStatusEnumForFilter,
    jobTypesIdEnum,
    jobTypesIdEnumInverse
} from '../../models-enums';
import { initialJob, initialUser } from '../../reducers/initialState';

import moment from 'moment';
import queryString from 'query-string';
import { FieldConfig } from 'react-reactive-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { closeAllModals, getSAPJobMapping } from '../../actions/commonActions';
import { loadCountries } from '../../actions/countriesActions';
import { constants } from '../../constants/constants';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { getCountries } from '../../reducers/countriesReducer';
import {
    selectActiveFseUsersByID,
    selectJobTableFilters,
    selectJobWorkOrdersWithWorkOrders
} from '../../reducers/manageJobReducer';
import { getActiveLocalCountry } from '../../reducers/manageUserReducer';
import {
    getUsersActiveCountry,
    getUsersCountries
} from '../../reducers/userReducer';
import { Banner } from '../common/Banner';
import { FormUtil } from '../common/FormUtil';
import { addQuery } from '../common/OtherUtils';
import SearchTableForm from '../common/SearchTableForm';
import { TableUtil } from '../common/TableUtil';
import EditJobModal from './EditJobModal';
import { JobWorkOrderExpanderContainer } from './JobWorkOrderExpanderContainer';

const uuidv4 = require('uuid/v4');

interface RowInfoJob extends RowInfo {
    original: Ijob;
}

export enum manageJobQueryParamsEnum {
    selectedJobID = 'selectedJobID'
}

type Iprops = RouteComponentProps<any>;

interface IdispatchProps {
    // Add your dispatcher properties here
    getJobs: typeof getJobs;
    getFSEUsers: typeof getFSEUsers;
    closeAllModals: typeof closeAllModals;
    jobManage: ImanageJobReducer;
    showEditJobModal: boolean;
    setTableFilter: typeof setTableFilter;
    tableData: Ijob[];
    jobTypes: any[];
    loading: boolean;
    facilities: { [key: string]: Ifacility };
    jobWorkOrdersByID: { [key: string]: IjobWorkOrder };
    queryParams: typeof manageJobQueryParamsEnum;
    updateSelectedJob: typeof updateSelectedJob;
    getSAPJobMapping: typeof getSAPJobMapping;
}

interface Istate {
    selectedRow: any;
}

const ManageJob: FC<Iprops & IdispatchProps & WithTranslation> = props => {
    const { t } = props;
    const currentTile: Itile = constants.getTileByURL(props.location.pathname);
    const [state, setState] = useState<Istate>({
        selectedRow: []
    });
    const fseUsersByID = useSelector(selectActiveFseUsersByID);
    const tableFilters = useSelector(selectJobTableFilters);
    const usersActiveCountry = useSelector(getUsersActiveCountry);
    const usersActiveLocalCountry = useSelector(getActiveLocalCountry);
    const usersCountries = useSelector(getUsersCountries);
    const getActiveCountry = () => {
        if (usersActiveLocalCountry !== '') {
            return usersActiveLocalCountry;
        } else {
            return usersActiveCountry;
        }
    };

    const facilities: { [key: string]: Ifacility } = useMemo(() => {
        const data = props.tableData.reduce(
            (carry: { [key: string]: Ifacility }, job: Ijob) => {
                if (!job.facility) {
                    return carry;
                }

                return {
                    ...carry,
                    [job.facility.id]: job.facility
                };
            },
            {}
        );

        return data;
    }, [props.tableData]);

    const coulumns: any[] = useMemo(() => {
        return TableUtil.translateHeaders(
            [
                {
                    id: 'expander-toggle',
                    expander: true,
                    Expander: TableUtil.expanderToggle,
                    style: {
                        cursor: 'pointer',
                        textAlign: 'left',
                        userSelect: 'none'
                    }
                },
                {
                    id: 'status',
                    Header: 'status',
                    accessor: 'status',
                    width: 100
                },
                {
                    Header: 'type',
                    id: 'type',
                    accessor: ({ jobTypeID }: Ijob) =>
                        props.t(
                            jobTypesIdEnumInverse[
                                jobTypeID as keyof typeof jobTypesIdEnumInverse
                            ]
                        ),
                    width: 115
                },
                {
                    id: 'jobNumber',
                    Header: 'jobNumber',
                    accessor: ({ jobNumber }: Ijob) =>
                        jobNumber ? jobNumber : '',
                    width: 120
                },
                {
                    id: 'facility',
                    Header: 'facility',
                    accessor: (job: Ijob) => {
                        return job?.facility?.name;
                    },
                    minWidth: 200
                },
                {
                    Header: 'fseLead',
                    Cell: (row: RowInfoJob) => {
                        const fseLead =
                            fseUsersByID[row.original.assignedUserID] ||
                            initialUser;

                        return (
                            <span>
                                {fseLead.first} {fseLead.last}
                            </span>
                        );
                    }
                },
                {
                    Header: 'start date',
                    accessor: ({ startDate }: Ijob) => {
                        return startDate
                            ? moment
                                  .utc(startDate)
                                  .local(true)
                                  .format('DD-MMM-YY')
                            : 'n/a';
                    },
                    id: 'startDate',
                    width: 130
                },
                {
                    Header: '#WO',
                    accessor: ({ id }: Ijob) => {
                        const jobWorkOrders = filter(props.jobWorkOrdersByID, {
                            jobID: id,
                            isDeleted: false
                        });
                        if (jobWorkOrders) {
                            return jobWorkOrders.length;
                        } else {
                            return 0;
                        }
                    },
                    id: 'wos',
                    width: 60
                }
            ],
            props.t
        );
    }, [facilities]);

    const dispatch = useDispatch();
    const countries = useSelector(getCountries);

    React.useEffect(() => {
        loadCountries(dispatch, countries);
        props.getSAPJobMapping();
        props.getFSEUsers();
    }, []);

    const userOptions: Ioption[] = useMemo(() => {
        return Object.values(fseUsersByID)?.map(user => {
            const name = `${user.first} ${user.last}`;
            return { label: name, value: user.id };
        });
    }, [fseUsersByID]);

    const searchFieldConfig: FieldConfig = useMemo(() => {
        const countriesOptions: Ioption[] = countries
            .filter(c => usersCountries?.includes(c.id))
            .map(c => ({ label: c.name, value: c.id }));

        const defaultStatus = FormUtil.convertToSingleOption({
            id: jobStatusEnumForFilter.Active.toString(),
            label: props.t('Active')
        });

        const {
            startDate,
            endDate,
            type,
            statusFilter = defaultStatus,
            search,
            showAdditionalFilters,
            country,
            assignedUser,
            isDeleted
        } = tableFilters;
        const disabled = false;

        const mainSearchControls = {
            country: {
                render: FormUtil.Select,
                meta: {
                    options: countriesOptions,
                    label: 'manageFacility:country',
                    colWidth: 4,
                    colWidthMedium: 4,
                    colWidthLarge: 4,
                    placeholder: 'common:searchPlaceholder',
                    name: 'country',
                    defaultValue: country,
                    isClearable: true
                },
                formState: {
                    value: country,
                    disabled
                }
            },
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:search',
                    colWidth: 5,
                    colWidthLarge: 5,
                    type: 'text',
                    placeholder: 'searchPlaceholder'
                },
                formState: {
                    value: search,
                    disabled
                }
            },
            type: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'manageMeasurementPointLists:type',
                    options: FormUtil.convertEnumToOptions(jobTypesIdEnum),
                    colWidth: 4,
                    colWidthLarge: 3,
                    type: 'select',
                    placeholder: 'typePlaceholder',
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: {
                    value: type,
                    disabled
                }
            },
            statusFilter: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    label: 'status',
                    options: FormUtil.convertEnumToOptions(
                        jobStatusEnumForFilter
                    ),
                    colWidth: 4,
                    colWidthLarge: 3,
                    type: 'select',
                    placeholder: 'statusPlaceholder'
                },
                formState: {
                    value: statusFilter,
                    disabled
                }
            },
            assignedUser: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: userOptions,
                    label: 'FSENameTableHeader',
                    colWidth: 3,
                    isClearable: true,
                    shouldTranslate: true,
                    dataType: 'fseUser'
                },
                formState: { value: assignedUser || '', disabled }
            },
            isDeleted: {
                render: FormUtil.Toggle,
                meta: {
                    label: 'showDeleted',
                    colWidth: 2,
                    innerStyle: {
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        height: '48px',
                        paddingTop: '24px'
                    }
                },
                formState: {
                    value: isDeleted || false,
                    disabled
                }
            }
        };
        const additionalSearchControls = {
            startDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'startDate',
                    colWidth: 3,
                    colWidthLarge: 3,
                    showTime: false,
                    placeholder: 'common:datePlaceholder'
                },
                formState: {
                    value: startDate || '',
                    disabled
                },
                options: {
                    validators: FormUtil.validators.isValidMoment
                }
            },
            endDate: {
                render: FormUtil.Datetime,
                meta: {
                    label: 'endDate',
                    colWidth: 3,
                    colWidthLarge: 3,
                    showTime: false,
                    placeholder: 'common:datePlaceholder'
                },
                formState: {
                    value: endDate || '',
                    disabled
                },
                options: {
                    validators: FormUtil.validators.isValidMoment
                }
            }
        };

        if (showAdditionalFilters) {
            return {
                controls: {
                    ...mainSearchControls,
                    ...additionalSearchControls
                }
            };
        } else {
            return { controls: { ...mainSearchControls } };
        }
    }, [
        tableFilters.showAdditionalFilters,
        countries,
        usersCountries,
        usersActiveCountry,
        usersActiveLocalCountry,
        userOptions
    ]);

    useEffect(() => {
        if (
            tableFilters.country === null ||
            tableFilters.country === undefined
        ) {
            const activeCountryID = getActiveCountry();
            const activeCountryOption: Ioption = {
                value: activeCountryID,
                label: countries.find(c => c.id === activeCountryID)?.name || ''
            };

            props.setTableFilter({
                ['country']: activeCountryOption
            });
        }
    }, []);

    useEffect(() => {
        let activeCountryID: string | undefined = undefined;

        if (tableFilters.country) {
            activeCountryID = tableFilters.country.value;
        }

        props.getJobs(activeCountryID);

        // scroll top every time a filter changes
        const tableDiv = document.getElementsByClassName('rt-tbody');
        if (tableDiv && tableDiv.length) {
            tableDiv[0].scrollTop = 0;
        }
    }, [tableFilters]);

    const handleSelectJob = (id: string) => {
        addQuery(manageJobQueryParamsEnum.selectedJobID, id, props.history);
    };

    const onPageChange = (page: number) => {
        const newPage = page + 1;
        props.setTableFilter({ page: newPage });
    };

    const onSearchValueChanges = useCallback(
        (value: any, key: string) => {
            props.setTableFilter({
                [key]: value
            });
        },
        [tableFilters]
    );
    const debouncedSearchValueChanges = useCallback(
        debounce(onSearchValueChanges, 300),
        []
    );
    const onSortedChanged = (
        newSorted: SortingRule[],
        column: any,
        shiftKey: boolean
    ) => {
        props.setTableFilter({ sorted: newSorted });
        setState({ selectedRow: {} });
    };

    const hasActiveAdditionalFilters = () => {
        const { endDate, startDate } = tableFilters;
        if (endDate || startDate) {
            return true;
        } else {
            return false;
        }
    };

    const handleNewJobClick = () => {
        const id = uuidv4();
        props.updateSelectedJob({ ...initialJob, id });
        addQuery(manageJobQueryParamsEnum.selectedJobID, id, props.history);
    };

    const getTdProps = (
        finalState: FinalState,
        rowInfo: RowInfoJob | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo && column) {
            const jobWorkOrders = filter(props.jobWorkOrdersByID, {
                jobID: rowInfo.original.id,
                isDeleted: false
            });

            return {
                onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                    if (column.id && column.id === 'actions') {
                        return;
                    }
                    if (jobWorkOrders.length === 0) {
                        setState({
                            selectedRow: rowInfo.index
                        });
                        handleSelectJob(rowInfo.original.id);
                        return;
                    }

                    setState({
                        selectedRow: {
                            [rowInfo.viewIndex || 0]: !state.selectedRow[
                                rowInfo.viewIndex || 0
                            ]
                        }
                    });
                },
                style: {
                    background: state.selectedRow[rowInfo.viewIndex]
                        ? constants.colors[
                              `${currentTile.color}Tr` as keyof typeof constants.colors
                          ]
                        : '',
                    opacity:
                        jobWorkOrders.length === 0 &&
                        column.id === 'expander-toggle'
                            ? '0'
                            : ''
                }
            };
        } else {
            return {};
        }
    };

    const tableClassName = tableFilters.showAdditionalFilters
        ? `beacon-table -highlight ${currentTile.color} additional-filters`
        : `beacon-table -highlight ${currentTile.color}`;

    return (
        <div className="manage-job">
            <Banner
                title={t('bannerTitle')}
                img={currentTile.srcBanner}
                color={currentTile.color}
            />
            <SearchTableForm
                fieldConfig={searchFieldConfig}
                handleSubmit={props.getJobs}
                loading={props.loading}
                showAdditionalFiltersButton={true}
                showAdditionalFilters={tableFilters.showAdditionalFilters}
                hasActiveAdditionalFilters={hasActiveAdditionalFilters()}
                colorButton={
                    constants.colors[
                        `${currentTile.color}Button` as keyof typeof constants.colors
                    ]
                }
                t={props.t}
                subscribeValueChanges={true}
                onValueChanges={debouncedSearchValueChanges}
                xs={9}
                lg={10}
            >
                <Col xs={2} lg={1}>
                    <Button
                        className="job-add-button"
                        bsStyle="link"
                        onClick={handleNewJobClick}
                        style={{ paddingTop: '29px' }}
                    >
                        {t('newJob')}
                    </Button>
                </Col>
            </SearchTableForm>

            <ReactTable
                data={props.tableData}
                onSortedChange={onSortedChanged}
                columns={coulumns}
                getTdProps={getTdProps}
                pageSize={
                    props.tableData.length >= 10 ? props.tableData.length : 10
                }
                page={tableFilters.page - 1}
                manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                pages={props.jobManage.totalPages}
                showPageSizeOptions={false}
                className={tableClassName}
                previousText={t('common:previous')}
                nextText={t('common:next')}
                onPageChange={onPageChange}
                sortable={false}
                noDataText={t('common:noDataText')}
                resizable={false}
                expanded={state.selectedRow}
                SubComponent={(rowInfo: RowInfo) => {
                    return (
                        <JobWorkOrderExpanderContainer
                            {...rowInfo}
                            t={props.t}
                            fseUsersByID={fseUsersByID}
                            handleSelectJob={handleSelectJob}
                        />
                    );
                }}
            />
            <EditJobModal
                colorButton={
                    constants.colors[
                        `${currentTile.color}Button` as keyof typeof constants.colors
                    ]
                }
                facilities={Object.values(facilities)}
                history={props.history}
                queryParams={props.queryParams}
            />
        </div>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const tableData = orderBy(
        state.manageJob.jobsByID,
        res => moment.utc(res.startDate).unix(),
        'desc'
    );
    const queryParams = queryString.parse(
        ownProps.location.search
    ) as typeof manageJobQueryParamsEnum;
    const jobWorkOrdersByID = selectJobWorkOrdersWithWorkOrders(state);

    return {
        user: state.user,
        jobManage: state.manageJob,
        loading: selectIsLoading(state),
        showEditJobModal: queryParams.selectedJobID?.length > 0,
        tableData,
        jobWorkOrdersByID,
        queryParams
    };
};

export default withTranslation('nsJob')(
    connect(mapStateToProps, {
        getJobs,
        getFSEUsers,
        closeAllModals,
        setTableFilter,
        updateSelectedJob,
        getSAPJobMapping
    })(ManageJob)
);
