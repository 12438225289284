/*
 * Facility Form Container
 */
import { searchFacilitiesForAsyncSelect } from '../../../actions/commonActions';
import SearchFacilityForm from './SearchFacilityForm';
import { connect } from 'react-redux';
import { TFunction } from 'i18next';
import { selectIsLoading } from '../../../reducers/commonReducers';
import { IinitialState } from '../../../reducers';

interface Iprops {
    classNameOverride?: string;
    t: TFunction;
    facilitySelected: (facilityID: string) => void;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        loading: selectIsLoading(state),
        user: state.user
    };
};

export const SelectFacilityContainer = connect(mapStateToProps, {
    searchFacilitiesForAsyncSelect
})(SearchFacilityForm);
