import { pickBy, map, keyBy } from 'lodash';

import { IjobComment, ImanageJobCommentsReducer } from '../models';
import {
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import { initialJobComment } from './initialState';
import * as types from '../actions/actionTypes';
import { combineReducers } from 'redux';

const cleanJobCommentObject = (jobComment: any): IjobComment => {
    return {
        ...initialJobComment,
        ...pickBy(jobComment, (property, key) => property !== null)
    };
};

function jobCommentsByID(
    state: { [key: string]: IjobComment } = {},
    action: any
): { [key: string]: IjobComment } {
    switch (action.type) {
        case types.MANAGE_JOB_COMMENTS_SUCCESS: {
            const newData = map(action.jobComments, item => {
                return cleanJobCommentObject(item);
            });
            return { ...state, ...keyBy(newData, 'id') };
        }
        case types.MANAGE_JOB_COMMENTS_ADD:
            return { ...state, [action.jobComment.id]: action.jobComment };
        case types.MANAGE_JOB_COMMENTS_UPDATE:
            return {
                ...state,
                [action.jobComment.id]: cleanJobCommentObject(action.jobComment)
            }; // clean it here beacuase we receive updated comments from /latest

        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}
// function codesReducer(
//   state: { [key: string]: IdeficiencyCode } = {},
//   action: any
// ): { [key: string]: IdeficiencyCode } {
//   switch (action.type) {
//     case types.MANAGE_JOB_COMMENT_CODES_SUCCESS:
//       const newData = map(action.codes, item => {
//         return cleanJobCommentCodeObject(item);
//       });
//       return { ...state, ...keyBy(newData, 'id') };
//     case types.USER_LOGOUT_SUCCESS:
//       return {};
//     default:
//       return state;
//   }
// }

function selectedJobComment(
    state: IjobComment = initialJobComment,
    action: any
): IjobComment {
    switch (action.type) {
        case types.MANAGE_JOB_COMMENTS_SELECT:
            return action.jobComment;
        case types.MANAGE_JOB_COMMENTS_ADD:
            return action.jobComment;
        case types.MANAGE_JOB_COMMENTS_UPDATE: {
            // this helps update the job /latest
            const foundJobComment = action.jobComment.id === state.id;
            if (foundJobComment) {
                return cleanJobCommentObject(action.jobComment);
            } else {
                return state;
            }
        }
        case types.USER_LOGOUT_SUCCESS:
            return initialJobComment;
        default:
            return state;
    }
}

function filteredJobComments(
    state: IjobComment[] = [],
    action: any
): IjobComment[] {
    switch (action.type) {
        case types.MANAGE_JOB_COMMENTS_FILTER_VISIBLE:
            return action.filteredJobComments;
        case types.USER_LOGOUT_SUCCESS:
            return [];
        default:
            return state;
    }
}

const manageJobCommentsReducer = combineReducers<ImanageJobCommentsReducer>({
    jobCommentsByID,
    selectedJobComment,
    showEditJobCommentModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_JOB_COMMENT'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_JOB_COMMENTS'),
    filteredJobComments
});

export default manageJobCommentsReducer;
