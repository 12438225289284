/*
 * EditTeamMemberForm
 * Edit team members
 */

import * as React from 'react';

import {
    AbstractControl,
    FieldConfig,
    FormGenerator,
    FormGroup
} from 'react-reactive-form';
import { Button, Col } from 'react-bootstrap';
import {
    FormUtil,
    MultiValueLabel,
    MultiValueOption
} from '../common/FormUtil';
import { IfacilityWithoutBuildings, Ioption, Iuser } from '../../models';
import { WithTranslation } from 'react-i18next';
import { debounce, differenceBy, filter, map } from 'lodash';
import {
    deleteTeamUser,
    saveTeamUser,
    updateTeamUser
} from '../../actions/manageTeamActions';

import { IeditTeamMemberFormValues } from '../../modelsForms';
import { constants } from '../../constants/constants';
import { initialUser } from '../../reducers/initialState';
import { toastr } from 'react-redux-toastr';
import { userBaseConfigControls } from '../common/UserBaseConfigControls';
import {
    FacilitiesSearchParams,
    searchFacilitiesForAsyncSelect
} from '../../actions/commonActions';

interface Iprops {
    updateTeamUser: typeof updateTeamUser;
    saveTeamUser: typeof saveTeamUser;
    toggleModal: () => void;
    selectedUser: Iuser;
    loading: boolean;
    colorButton: string;
    getFacilitiesByCountry: (params: FacilitiesSearchParams) => Promise<void>;
    facilityOptionsWithAddress: Ioption[];
    user: Iuser;
    deleteTeamUser: typeof deleteTeamUser;
    updateFormValues: (formValues: { [key: string]: any }) => void;
    setFormValues: (formValues: { [key: string]: any }) => void;
    formValues: IeditTeamMemberFormValues;
    searchFacilitiesForAsyncSelect: typeof searchFacilitiesForAsyncSelect;
}
interface Istate {
    fieldConfig: FieldConfig;
}

class EditTeamMemberForm extends React.Component<
    Iprops & WithTranslation,
    Istate
> {
    public subscription: any;
    private formGroup: FormGroup | any;
    private updateFormValuesDebounced: (formValues: {
        [key: string]: any;
    }) => void;
    static defaultProps = {
        selectedUser: initialUser
    };
    constructor(props: Iprops & WithTranslation) {
        super(props);
        this.updateFormValuesDebounced = debounce(
            this.props.updateFormValues,
            constants.formDebounceTime
        );
        this.state = {
            fieldConfig: this.buildFieldConfig()
        };
    }

    componentDidMount() {
        if (
            this.props.formValues.first === undefined ||
            this.props.formValues.email !== this.props.selectedUser.email
        ) {
            this.props.setFormValues(this.itemToFormValues());
        }
        /*
         * Tricky bit of code here.  When we initially have the facility objects, they do not have the address.
         * We add them without the address initially so that the filtering works.  Then once the full facility objects
         * return, we set the form values again, now with the address.
         */
        let getFacilitiesPromises: Promise<void>[] = [];
        this.props.selectedUser.facilities.forEach(
            facility =>
                (getFacilitiesPromises = [
                    ...getFacilitiesPromises,
                    this.props.getFacilitiesByCountry({})
                ])
        );
        Promise.all(getFacilitiesPromises).then(() => {
            this.props.setFormValues(this.itemToFormValues());
        });
    }

    componentDidUpdate(prevProps: Iprops) {
        if (
            differenceBy(
                prevProps.facilityOptionsWithAddress,
                this.props.facilityOptionsWithAddress,
                'value'
            ).length ||
            prevProps.facilityOptionsWithAddress.length !==
                this.props.facilityOptionsWithAddress.length
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }

        // only update the form if the facilities or customers changed because the cursor looses focus on text input fields
        if (
            this.props.formValues.facilities !== prevProps.formValues.facilities
        ) {
            this.setState({ fieldConfig: this.buildFieldConfig() });
        }
    }
    componentWillUnmount() {
        this.props.setFormValues({});
    }

    /*
     * itemToFormValues - take the selectedItem and convert it to formValues
     */
    itemToFormValues = (): IeditTeamMemberFormValues => {
        const { selectedUser } = this.props;

        const { facilities, countryID } = selectedUser;
        const selectedFacilities = map(
            facilities,
            fac =>
                this.props.facilityOptionsWithAddress.find(
                    fw => fw.value === fac.id
                ) || {
                    value: '',
                    label: ''
                }
        );

        return {
            ...selectedUser,
            facilities: selectedFacilities,
            countryID: constants.countries[countryID]
        };
    };

    buildUserFromFormValues = (): Iuser => {
        const formValues = FormUtil.getValues(this.formGroup.value);
        const facilities = this.formGroup.value.facilities.map(
            (option: { value: string; label: string }) => {
                return { id: option.value, name: option.label };
            }
        );

        return {
            ...this.props.selectedUser,
            ...formValues,
            facilities,
            email: this.props.selectedUser.email // have to add back the email because disabling the input removes it
        };
    };

    saveFacilities = (facilities: IfacilityWithoutBuildings[]) => {
        // console.log(facilities)
    };

    loadOptions = debounce((searchTerm, callback) => {
        if (searchTerm.length >= 3) {
            this.props.searchFacilitiesForAsyncSelect(
                searchTerm,
                null,
                null,
                callback,
                this.saveFacilities
            );
        } else {
            callback([]);
        }
    }, 100);

    /*
     * build the field config with values passed in or the values from redux
     */
    buildFieldConfig = (
        defaultFormValues: IeditTeamMemberFormValues = this.props.formValues
    ) => {
        const disabled = false;
        const { facilities } = defaultFormValues;
        const { facilityOptionsWithAddress } = this.props;

        const filteredSelectedFacilities = filter(
            facilities,
            facilityOption =>
                !!facilityOptionsWithAddress.find(
                    facility => facility.value === facilityOption.value
                )
        );

        // Field config to configure form
        const fieldConfigControls = {
            facilities: {
                render: FormUtil.AsyncSelect,
                meta: {
                    label: 'common:facility',
                    loadOptions: (searchTerm: string, callback: any) => {
                        this.loadOptions(searchTerm, callback);
                    },
                    colWidth: 12,
                    placeholder: 'manageUserQueue:facilitySearchPlaceholder',
                    isMulti: true,
                    multiValueLabel: MultiValueLabel,
                    multiValueOption: MultiValueOption
                },
                options: {
                    validators: FormUtil.validators.requiredWithTrim
                },
                formState: {
                    value: filteredSelectedFacilities,
                    disabled
                }
            }
        };
        const fieldConfig: FieldConfig = {
            controls: {
                ...userBaseConfigControls(defaultFormValues),
                ...fieldConfigControls
            }
        };
        return FormUtil.translateForm(fieldConfig, this.props.t);
    };

    /*
     * (reusable)
     * subscribe to the formGroup changes
     */
    subscribeToChanges = () => {
        for (const key in this.formGroup.controls) {
            if (this.formGroup.controls.hasOwnProperty(key)) {
                this.subscription = this.formGroup
                    .get(key)
                    .valueChanges.subscribe((value: any) => {
                        this.onValueChanges(value, key);
                    });
            }
        }
    };

    /*
     * (reusable)
     * set the table filters to redux on each value change
     */
    onValueChanges = (value: any, key: string) => {
        switch (key) {
            default:
                this.updateFormValuesDebounced({ [key]: value });
                break;
        }
    };

    handleSubmit = (evt: React.MouseEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (this.formGroup.status === 'INVALID') {
            this.formGroup.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }

        if (this.props.selectedUser.id.length) {
            this.props.updateTeamUser(this.buildUserFromFormValues());
        } else {
            this.props.saveTeamUser(this.buildUserFromFormValues());
        }
    };
    handleDelete = () => {
        if (this.props.selectedUser) {
            this.props.deleteTeamUser(this.props.selectedUser.id);
        } else {
            console.error('unable to delete, missing user');
            toastr.error(
                'Error',
                'Unable to delete, missing user',
                constants.toastrError
            );
        }
    };
    setForm = (form: AbstractControl) => {
        this.formGroup = form;
        this.formGroup.meta = {
            loading: this.props.loading
        };
        if (!this.subscription) {
            setTimeout(() => {
                this.subscribeToChanges();
            }, 300);
        }

        if (this.props.selectedUser.id.length) {
            const emailControl = this.formGroup.get('email');
            emailControl.disable();
        }
    };

    render() {
        const { t } = this.props;

        const formClassName = `clearfix beacon-form team-member-form ${this.props.colorButton}`;

        return (
            <form onSubmit={this.handleSubmit} className={formClassName}>
                <FormGenerator
                    onMount={this.setForm}
                    fieldConfig={this.state.fieldConfig}
                />
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={this.props.toggleModal}
                    >
                        {t('common:cancel')}
                    </Button>
                    {!!this.props.selectedUser.id.length && (
                        <Button
                            bsStyle="warning"
                            style={{ marginRight: '15px' }}
                            type="button"
                            className=""
                            disabled={this.props.loading}
                            onClick={this.handleDelete}
                        >
                            {t('common:delete')}
                        </Button>
                    )}

                    <Button
                        bsStyle={this.props.colorButton}
                        type="submit"
                        disabled={this.props.loading}
                    >
                        {t('save')}
                    </Button>
                </Col>
            </form>
        );
    }
}
export default EditTeamMemberForm;
