/*
Work Order Expander
*/

import * as React from 'react';

import { IWorkOrder, IinstallBase } from '../../models';
import ReactTable, { Column, RowInfo } from 'react-table';

import { TableUtil } from '../common/TableUtil';
import { TFunction } from 'i18next';
import moment from 'moment';
import { orderBy } from 'lodash';
import { workOrderStatusEnum } from '../../models-enums';

interface RowInfoInstallBase extends RowInfo {
    original: IinstallBase;
}
interface ExpanderProps extends RowInfo {
    t: TFunction;
    original: IinstallBase;
}

/*
 * The Work Order Expander
 */
export const WorkOrderExpander: React.SFC<ExpanderProps> = (
    props: ExpanderProps
) => {
    const sortedData = orderBy(
        props.original.workOrders,
        res => moment.utc(res.dueDate).unix(),
        'desc'
    );

    const expanderColumns = TableUtil.translateHeaders(
        [
            {
                Header: 'manageWorkOrder:number',
                accessor: 'number',
                minWidth: 100
            },
            {
                Header: 'manageWorkOrder:activity',
                accessor: 'activityDescription',
                minWidth: 220
            },
            {
                Header: 'manageWorkOrder:technician',
                id: 'technician',
                accessor: 'technician',
                minWidth: 150
            },
            {
                Header: 'manageWorkOrder:dueDate',
                id: 'dueDate',
                accessor: ({ dueDate }: IWorkOrder) => {
                    return moment
                        .utc(dueDate)
                        .local(true)
                        .format('DD-MMM-YY');
                }
            },
            {
                Header: 'status',
                id: 'status',
                accessor: ({ status }: IWorkOrder) => {
                    const statusString = workOrderStatusEnum[status];

                    return (
                        <span className={`status ${statusString}`}>{`${props.t(
                            'manageMeasurementPointLists:' + statusString
                        )}`}</span>
                    );
                },
                minWidth: 100
            }
        ],
        props.t
    ) as Column[];

    return (
        <div>
            <ReactTable
                className={'work-order-expander'}
                data={sortedData}
                sortable={false}
                columns={expanderColumns}
                minRows={0}
                showPageSizeOptions={false}
                rowsText="workOrders"
                noDataText={props.t('dashboardAnalytics:noDataText')}
                resizable={false}
                showPagination={
                    props.original.workOrders
                        ? props.original.workOrders.length >= 10
                        : false
                }
            />
        </div>
    );
    // } else {
    // return null;
    // }
};
