import * as React from 'react';
import * as Highcharts from 'highcharts';

import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { Link, match, useHistory } from 'react-router-dom';
import { TFunction } from 'i18next';
import styled from 'styled-components';

import Alert from '../../../images/alert.svg';
import HourCard from './HourCard';
import Placeholder from '../../../images/Photo_Placeholder.png';
import { Banner } from '../../common/Banner';
import {
    BarChartData,
    IanalyticsTAEDashboard,
    IdataFormatPreference,
    IsensorData,
    TAEDashboardData
} from '../../../models';
import {
    constants,
    productPlaceholderImages
} from '../../../constants/constants';
import { dataUnitEnum, GraphType, OperatingMode } from '../../../models-enums';
import { dataThreshold, floatTrim } from './AnalyticsCard';
import {
    getBarChartOptionsDefault,
    getBarChartOptions,
    getGuageChartOptions,
    getLineChartOptions,
    getLineChartOptionsDefault,
    getGuageChartOptionsDefault
} from '../Utilities';
import {
    setSelectedInstallBaseID,
    setSelectedProductID,
    toggleInstallContactModal
} from '../../../actions/manageInventoryActions';
import InstallContactModal from '../../manageInventory/InstallContactModal';
import { isStrFloat } from '../../../helpers/isFloat';
import { AnalyticsToggle } from './AnalyticsToggle';
import { LinkContainer } from 'react-router-bootstrap';
import {
    runReport,
    setSelectedDefaultReport,
    toggleEditReportModal
} from '../../../actions/manageReportActions';
import { msalFetch } from '../../auth/Auth-Utils';
import API from '../../../constants/apiEndpoints';
import { beginAjaxCall, endAjaxCall } from '../../../actions/ajaxStatusActions';
import ReactTable from 'react-table';
import { TableUtil } from '../../common/TableUtil';
import ManageReportModal from '../../manageReport/ManageReportModal';
import { useSelector } from 'react-redux';
import { getUser } from '../../../reducers/manageUserReducer';

const UnitLabelStatus = styled.div`
    background-color: ${(props: { color: string }) => props.color};
    border-radius: 100%;
    height: 10px;
    margin-right: 8px;
    max-height: 10px;
    min-height: 10px;
    width: 10px;
`;

const UnitFooter = styled.div`
    color: #fff;
    background-color: ${(props: { bgColor: string }) => props.bgColor};
    border-radius: 6px;
    margin-top: 8px;
    padding: 0 8px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    flex-direction: row;

    font-size: 12px;
    line-height: 23px;
`;

const UnitFooterMode = styled.div`
    font-weight: bold;
    text-transform: uppercase;
`;

interface Iprops {
    t: TFunction;
    data: IanalyticsTAEDashboard;
    dataFormatPreference: IdataFormatPreference;
    isDemoMode: boolean;
    match: match;
    setSelectedProductID: typeof setSelectedProductID;
    toggleInstallContactModal: typeof toggleInstallContactModal;
    setSelectedInstallBaseID: typeof setSelectedInstallBaseID;
    runReport: typeof runReport;
    beginAjaxCall: typeof beginAjaxCall;
    endAjaxCall: typeof endAjaxCall;
    setSelectedDefaultReport: typeof setSelectedDefaultReport;
    toggleEditReportModal: typeof toggleEditReportModal;
}

interface Istate {
    [key: string]: boolean;
}

export const hoursMinHeight = (length: number) =>
    (91.5 + 16) * (length < 4 ? length : 4) + 16;

const unitsSorted = (a: TAEDashboardData, b: TAEDashboardData) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    switch (true) {
        case labelA < labelB:
            return -1;
        case labelA > labelB:
            return 1;
        default:
            return 0;
    }
};

const AnalyticsCard = (props: Iprops) => {
    const { data, dataFormatPreference, t } = props;
    const history = useHistory();
    const user = useSelector(getUser);
    const hourWrap = React.useRef<HTMLDivElement>(null);
    const [cardHeight, setCardHeight] = React.useState<number | undefined>();
    const [toggleState, setToggleState] = React.useState<Istate>({
        alarms: false,
        runningStatus: false,
        dryerGraph: false,
        systemMaintenance: false,
        temperatureWeek: false,
        elementOutletTemperature: false,
        eventLogs: false
    });

    const [eventLogs, setEventLogs] = React.useState<IsensorData[]>([]);

    const toggleCallback = React.useCallback(
        (section: string) => {
            setToggleState(state => ({
                ...state,
                [section]: !state[section]
            }));
        },
        [toggleState]
    );

    const canRunDailyRoundReport = () => {
        return constants.hasSecurityFunction(user, [
            constants.securityFunctions.RunDailyRoundsReports.id
        ]);
    };

    React.useEffect(() => {
        if (!data) {
            history.push('/dashboard');
        }

        setSelectedProductID(data.productID);
        props.setSelectedInstallBaseID(data.installBaseID);
    }, [data]);

    React.useEffect(() => {
        if (hourWrap.current) {
            setCardHeight(hourWrap.current.clientHeight);
        }
    }, [hourWrap]);

    React.useEffect(() => {
        getEventLogs();
    }, []);

    if (!data) {
        return null;
    }

    const splitEventsWithMultipleOriginators = (
        data: IsensorData[]
    ): IsensorData[] => {
        const newData: IsensorData[] = [];

        data.forEach(row => {
            if (row.originator.includes('\n')) {
                row.originator.split('\n').forEach(originator => {
                    newData.push({
                        ...row,
                        originator
                    });
                });
            } else newData.push(row);
        });

        return newData;
    };

    const getEventLogs = async () => {
        if (!data) {
            console.error('Data has not been initialized yet.');
            return null;
        }

        props.beginAjaxCall();

        try {
            const url = `${API.alert.alertsForInstallBase}?installBaseId=${data.installBaseID}`;
            const response = await msalFetch(url, {
                method: 'get'
            });

            if (!response.data) {
                console.error('Failed to get event logs for install base.');
            } else {
                const newData = splitEventsWithMultipleOriginators(
                    response.data as IsensorData[]
                );
                setEventLogs(newData);
            }
        } catch (error) {
            console.error('[Dashboard - getEventLogs]', error);
            // throw error;
            return null;
        } finally {
            props.endAjaxCall();
        }
    };

    const eventLogColumns = TableUtil.translateHeaders(
        [
            {
                id: 'time',
                Header: 'time',
                accessor: (sensor: IsensorData) => {
                    return moment(sensor.time).format('DD-MMM-YY HH:mm');
                },
                minWidth: 100
            },
            {
                Header: 'eventName',
                accessor: 'eventName',
                minWidth: 150
            },
            {
                Header: 'originator',
                accessor: 'originator',
                minWidth: 150
            },
            {
                Header: 'value',
                accessor: 'value',
                minWidth: 100
            }
        ],
        t
    );

    const runDailyRoundsReport = () => {
        props.setSelectedDefaultReport('0ff13067-d224-4ba7-883b-9cc07788b55a'); // pass in Id for daily rounds report
        props.toggleEditReportModal();
    };
    const colorButton = 'primary';
    const hours = data.secondaryData.filter(
        guage => guage.graphType === GraphType.Numeric
    );
    const units = data.secondaryData
        .filter(unit => unit.graphType === GraphType.UnitSecondary)
        .sort(unitsSorted);
    const maintenance = data.secondaryData
        .filter(main => main.graphType === GraphType.MaintenanceInterval)
        .sort(unitsSorted);
    const elementOutlet = data.secondaryData.filter(
        elem => elem.graphType === GraphType.Bar
    );
    const guages = data.secondaryData.filter(
        guage => guage.graphType === GraphType.Guague
    );
    const temperatures = data.secondaryData.filter(
        temp => temp.graphType === GraphType.Line
    );

    const dryers = data.secondaryData.filter(
        guage => guage.graphType === GraphType.Dryer
    );

    const pumpColor = '#d20404';
    const compressorColor = '#7fc467';
    const placeholder = productPlaceholderImages.find(
        img => img.categoryId.toLocaleLowerCase() === data.mainCategoryID
    ) || { src: 'Azure.png' };
    let imgPath = require(`../../../images/products/${placeholder.src}`);
    if (
        data.photoUrl !== '' &&
        data.photoUrl !== null &&
        data.photoUrl !== undefined
    ) {
        imgPath = data.photoUrl;
    }

    return (
        <div className="dashboard-container">
            <Banner
                title={''}
                img={constants.tiles[0].srcBanner}
                color={'dark'}
            ></Banner>
            <div className="device-container-expanded">
                <div className="device-head">
                    <p>{t('assetDetails')}</p>
                    <Link to={'/dashboard'} className="close-icon">
                        <span className="icon">&times;</span>
                        <span className="sr-only">{t('common:cancel')}</span>
                    </Link>
                </div>
                <div className="device-inner">
                    <div className="device-details">
                        {/* Device Name */}
                        <h4 className="device-title">{data.installBaseName}</h4>
                        {/* Last Updated Label */}
                        <div className="device-meta-wrap">
                            <p className="device-meta">{`${t('updated')}:`}</p>
                            <p className="device-meta" data-dark="true">
                                {`${moment(
                                    data.updateDate || new Date()
                                ).format('hh:mm a, DD-MMM-YY')}`}
                            </p>
                        </div>
                        {/* Device Image */}
                        <img
                            className="device-image"
                            style={{
                                height: 'auto',
                                maxHeight: '305px',
                                width: '100%'
                            }}
                            src={imgPath}
                            alt="devices"
                        />
                        {/* Serial Number */}
                        <div className="device-meta-wrap">
                            <p className="device-meta">{`${t(
                                'serialNumber'
                            )}:`}</p>
                            <p className="device-meta" data-dark="true">
                                {data.installBaseSerialNumber}
                            </p>
                        </div>
                        {/* Location */}
                        {data.installBaseLocation ? (
                            <div className="device-meta-wrap">
                                <p className="device-meta">{`${t(
                                    'location'
                                )}:`}</p>
                                <p className="device-meta" data-dark="true">
                                    {data.installBaseLocation}
                                </p>
                            </div>
                        ) : null}
                        {/* Running Hours - Vertical Scroll List */}
                        <div ref={hourWrap} className="device-hour-wrap">
                            {cardHeight ? (
                                <div
                                    className="device-hour-graphs"
                                    style={{
                                        height: `${cardHeight}px`,
                                        minHeight: `calc((var(--card-height) + 16px) * ${hours.length})`
                                    }}
                                >
                                    {hours.map((hourItem, index) => (
                                        <HourCard
                                            key={hourItem.label}
                                            label={hourItem.label}
                                            value={`${floatTrim(
                                                hourItem.data[0]
                                            )}`}
                                            unitMeasurement={
                                                hourItem.unit === 0
                                                    ? ''
                                                    : dataUnitEnum[
                                                          hourItem.unit
                                                      ]
                                            }
                                        />
                                    ))}
                                </div>
                            ) : null}
                        </div>
                        {/* Request Service Button */}
                        <button
                            type="button"
                            className="request-service"
                            onClick={() => {
                                props.setSelectedProductID(data.productID);
                                props.toggleInstallContactModal();
                            }}
                        >
                            {t('requestService')}
                        </button>
                        {/* Run Daily Rounds Report */}
                        {canRunDailyRoundReport() && (
                            <Button
                                style={{ marginTop: '5px' }}
                                bsStyle="primary"
                                bsSize="medium"
                                onClick={() => {
                                    runDailyRoundsReport();
                                }}
                            >
                                {t('runDailyRoundsReport')}
                            </Button>
                        )}
                        {/* View Reports */}
                        <LinkContainer
                            to={`/documents?installbaseId=${data.installBaseID}`}
                            style={{ marginTop: '5px' }}
                        >
                            <Button bsStyle="primary" bsSize="medium">
                                {t('viewReports')}
                            </Button>
                        </LinkContainer>
                    </div>
                    <div className="device-graphs">
                        <div
                            className="device-graph-container"
                            data-type="runningStatus"
                            data-hidden={units.length === 0}
                            data-open={toggleState.runningStatus}
                        >
                            <div
                                className="label-wrap"
                                data-open={toggleState.runningStatus}
                            >
                                <p className="device-label">
                                    {t('runningStatus')}
                                </p>
                                <AnalyticsToggle
                                    isToggled={toggleState.runningStatus}
                                    onToggle={() =>
                                        toggleCallback('runningStatus')
                                    }
                                />
                            </div>
                            <div
                                className="device-unit-wrap"
                                data-open={toggleState.runningStatus}
                            >
                                {units.map((unitItem, index) => {
                                    return (
                                        <div
                                            key={unitItem.label}
                                            className="device-unit-item"
                                        >
                                            <p className="unit-header">
                                                {unitItem.label}
                                            </p>
                                            <div
                                                className="unit-body"
                                                data-type="hours"
                                            >
                                                <p className="unit-hour">
                                                    {`${floatTrim(
                                                        unitItem.data[0]
                                                    ).toLocaleString('en-US')}`}
                                                </p>
                                                <p className="unit-hour-label">
                                                    {
                                                        dataUnitEnum[
                                                            unitItem.unit
                                                        ]
                                                    }
                                                </p>
                                            </div>
                                            <div
                                                className="unit-body"
                                                data-type="labels"
                                            >
                                                {unitItem.pumpRunStatus !==
                                                    null && (
                                                    <div className="unit-label-wrap">
                                                        <UnitLabelStatus
                                                            color={pumpColor}
                                                        />
                                                        <p className="unit-label">
                                                            {t('pump')}
                                                        </p>
                                                    </div>
                                                )}
                                                {unitItem.compressorRunStatus !==
                                                    null && (
                                                    <div className="unit-label-wrap">
                                                        <UnitLabelStatus
                                                            color={
                                                                compressorColor
                                                            }
                                                        />
                                                        <p className="unit-label">
                                                            {t('compressor')}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <UnitFooter
                                                bgColor={
                                                    unitItem.pumpRunStatus !==
                                                    null
                                                        ? pumpColor
                                                        : compressorColor
                                                }
                                                className="unit-footer"
                                            >
                                                <div>
                                                    {`${t('operatingMode')}:`}
                                                </div>
                                                <UnitFooterMode>
                                                    {
                                                        OperatingMode[
                                                            unitItem.operatingMode ??
                                                                0
                                                        ]
                                                    }
                                                </UnitFooterMode>
                                            </UnitFooter>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div
                            className="device-graph-container"
                            data-type="dryerGraph"
                            data-hidden={dryers.length === 0}
                            data-open={toggleState.dryerGraph}
                        >
                            <div
                                className="label-wrap"
                                data-open={toggleState.dryerGraph}
                            >
                                <p className="device-label">
                                    {t('rotateDryerSchedule')}
                                </p>
                                <AnalyticsToggle
                                    isToggled={toggleState.dryerGraph}
                                    onToggle={() =>
                                        toggleCallback('dryerGraph')
                                    }
                                />
                            </div>
                            <div
                                className="device-unit-wrap"
                                data-open={toggleState.dryerGraph}
                            >
                                {dryers.map((dryerItem, index) => {
                                    const operatingStatus =
                                        dryerItem.operatingMode ===
                                        OperatingMode.Off
                                            ? 'error'
                                            : dryerItem.operatingMode ===
                                              OperatingMode.Manual
                                            ? 'warning'
                                            : 'good';

                                    return (
                                        <div
                                            key={dryerItem.label}
                                            className="dryer-item"
                                            data-status={operatingStatus}
                                        >
                                            <p className="dryer-header">
                                                {dryerItem.label}
                                            </p>
                                            <div className="dryer-body">
                                                <div className="dryer-body-inner">
                                                    <p className="dryer-hour">
                                                        {floatTrim(
                                                            dryerItem.data[0]
                                                        ).toLocaleString(
                                                            'en-US'
                                                        )}
                                                    </p>
                                                    <p className="dryer-hour-label">
                                                        {
                                                            dataUnitEnum[
                                                                dryerItem.unit
                                                            ]
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="dryer-footer"
                                                data-status={operatingStatus}
                                            >
                                                <p className="dryer-footer-mode">
                                                    {`${t('operatingMode')}:`}
                                                </p>
                                                <p className="unit-footer-mode-value">
                                                    {
                                                        OperatingMode[
                                                            dryerItem.operatingMode ??
                                                                0
                                                        ]
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div
                            className="device-graph-container"
                            data-type="eventLogGraph"
                            data-hidden={eventLogs.length === 0}
                            data-open={toggleState.eventLogs}
                        >
                            <div className="eventLogContainer">
                                <p className="device-label">{t('eventLogs')}</p>
                                <ReactTable
                                    data={eventLogs}
                                    columns={eventLogColumns}
                                    pageSize={eventLogs.length}
                                    showPageSizeOptions={false}
                                    className={`beacon-table -highlight blue eventLogTable`}
                                    previousText={t('common:previous')}
                                    nextText={t('common:next')}
                                    sortable={false}
                                    multiSort={false}
                                    noDataText={t('common:noDataText')}
                                    resizable={false}
                                />
                            </div>
                        </div>
                        <div
                            className="device-graph-container"
                            data-type="systemMaintenance"
                            data-hidden={maintenance.length === 0}
                            data-open={toggleState.systemMaintenance}
                        >
                            <div
                                className="label-wrap"
                                data-open={toggleState.systemMaintenance}
                            >
                                <p className="device-label">
                                    {t('systemMaintenance')}
                                </p>
                                <AnalyticsToggle
                                    isToggled={toggleState.systemMaintenance}
                                    onToggle={() =>
                                        toggleCallback('systemMaintenance')
                                    }
                                />
                            </div>
                            <div
                                className="device-unit-wrap"
                                data-open={toggleState.systemMaintenance}
                            >
                                {maintenance.map((maintenanceItem, index) => (
                                    <div
                                        key={maintenanceItem.label}
                                        className="device-system-item"
                                    >
                                        <p className="system-service-header">
                                            {maintenanceItem.label}
                                        </p>
                                        <div className="system-service-subheader">
                                            <p>{t('serviceItem')}</p>
                                            <p>{t('dueIn')}</p>
                                        </div>
                                        <ul className="system-service-list">
                                            {maintenanceItem.maintenanceIntervals?.map(
                                                ({ item, due }) => {
                                                    const dueIn =
                                                        due?.replace(
                                                            /([0-9.]+)\s(.*)/g,
                                                            (
                                                                // eslint-disable-next-line no-shadow
                                                                match,
                                                                number,
                                                                unit
                                                            ) => {
                                                                const ifNumFloat = isStrFloat(
                                                                    number
                                                                );
                                                                const value = ifNumFloat
                                                                    ? floatTrim(
                                                                          parseFloat(
                                                                              number
                                                                          )
                                                                      )
                                                                    : number;

                                                                return `${value} ${unit}`;
                                                            }
                                                        ) ?? '';

                                                    return (
                                                        <li
                                                            key={item}
                                                            className="system-service-item"
                                                        >
                                                            <p>{item}</p>
                                                            <p>{dueIn}</p>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div
                            className="device-graph-container"
                            data-type="temperatureWeek"
                            data-hidden={temperatures.length === 0}
                            data-open={toggleState.temperatureWeek}
                        >
                            {temperatures.map((temperatureData, index) => {
                                return (
                                    <>
                                        <div
                                            className="label-wrap"
                                            data-open={
                                                toggleState.temperatureWeek
                                            }
                                        >
                                            <p
                                                className="device-label"
                                                style={{ paddingLeft: '15px' }}
                                            >
                                                {temperatureData.label ===
                                                    'Dew Point' &&
                                                    'Dew Point (Last 7 Days)'}
                                                {temperatureData.label !==
                                                    'Dew Point' &&
                                                    t('temperatureWeek')}
                                            </p>
                                            <AnalyticsToggle
                                                isToggled={
                                                    toggleState.temperatureWeek
                                                }
                                                onToggle={() =>
                                                    toggleCallback(
                                                        'temperatureWeek'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div
                                            key={temperatureData.label}
                                            className="chart-wrap"
                                            data-slim="true"
                                            data-open={
                                                toggleState.temperatureWeek
                                            }
                                        >
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={{
                                                    ...getLineChartOptionsDefault(
                                                        {
                                                            t
                                                        }
                                                    ),
                                                    ...getLineChartOptions(
                                                        t,
                                                        dataFormatPreference,
                                                        temperatureData
                                                    )
                                                }}
                                                {...props}
                                            />
                                            <p className="temp-label">
                                                {temperatureData.label}
                                            </p>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        <div
                            className="device-graph-container"
                            data-type="elementOutletTemperature"
                            data-guage="true"
                            data-line={Boolean(elementOutlet.length > 0)}
                            data-hidden={
                                elementOutlet.length === 0 &&
                                guages.length === 0
                            }
                            data-flip="false"
                            data-open={toggleState.elementOutletTemperature}
                        >
                            <div
                                className="label-wrap"
                                data-mobile="true"
                                data-open={toggleState.elementOutletTemperature}
                            >
                                <p className="device-label">
                                    {t('systemMaintenance')}
                                </p>
                                <AnalyticsToggle
                                    isToggled={
                                        toggleState.elementOutletTemperature
                                    }
                                    onToggle={() =>
                                        toggleCallback(
                                            'elementOutletTemperature'
                                        )
                                    }
                                />
                            </div>
                            {elementOutlet.length > 0 ? (
                                <div
                                    className="device-graph-inner"
                                    data-open={
                                        toggleState.elementOutletTemperature
                                    }
                                >
                                    {/* use this label instead of chart generated for better styling control */}
                                    <p className="device-label">
                                        {t('elementOutletTemperature')}
                                    </p>
                                    {elementOutlet.map(
                                        (elementOutletData, index) => {
                                            const rawData = elementOutletData.barChartData as BarChartData[];
                                            const barData = rawData.map(
                                                barChartData => [
                                                    barChartData.item1,
                                                    barChartData.item2
                                                ]
                                            );
                                            const categories = rawData.map(
                                                barChartData =>
                                                    barChartData.item1
                                            );

                                            return (
                                                <div
                                                    key={
                                                        elementOutletData.label
                                                    }
                                                    className="chart-wrap"
                                                    data-slim="true"
                                                >
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={{
                                                            ...getBarChartOptionsDefault(
                                                                {
                                                                    t
                                                                }
                                                            ),
                                                            ...getBarChartOptions(
                                                                t,
                                                                dataFormatPreference,
                                                                {
                                                                    ...elementOutletData,
                                                                    data: barData,
                                                                    label: ''
                                                                },
                                                                categories
                                                            )
                                                        }}
                                                        {...props}
                                                    />
                                                    <p className="temp-label">
                                                        {
                                                            elementOutletData.label
                                                        }
                                                    </p>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            ) : null}
                            <div
                                className="device-graph-inner"
                                data-small="true"
                                data-open={toggleState.elementOutletTemperature}
                            >
                                {guages.map(guageData => {
                                    const fill = dataThreshold(
                                        guageData.data[0],
                                        {
                                            min: guageData.min,
                                            medium: guageData.medium,
                                            high: guageData.high
                                        },
                                        guageData.pointNumber
                                    );

                                    return (
                                        <div
                                            key={guageData.label}
                                            className="guage-wrap"
                                        >
                                            {/* use this label instead of chart generated for better styling control */}
                                            <p className="device-label">
                                                {guageData.label}
                                            </p>
                                            <div
                                                className="chart-wrap"
                                                data-type="guage"
                                                data-offset="true"
                                            >
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={{
                                                        ...getGuageChartOptionsDefault(
                                                            {
                                                                t,
                                                                fill
                                                            }
                                                        ),
                                                        ...getGuageChartOptions(
                                                            t,
                                                            dataFormatPreference,
                                                            {
                                                                ...guageData,
                                                                label: ''
                                                            }
                                                        )
                                                    }}
                                                    {...props}
                                                />
                                                <div
                                                    className="guage-label-wrap"
                                                    data-wide="true"
                                                >
                                                    <p className="guage-min">
                                                        {floatTrim(
                                                            guageData.min
                                                        )}
                                                    </p>
                                                    <p className="guage-max">
                                                        {floatTrim(
                                                            guageData.max
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {/* Alarms */}
                    <div
                        className="device-alarms"
                        data-open={toggleState.alarms}
                    >
                        <div
                            className="label-wrap"
                            data-open={toggleState.alarms}
                        >
                            <p className="device-label">
                                {t('activeAlarms')} - {data.alarmCount}
                            </p>
                            {data.alarmCount > 0 && (
                                <img
                                    src={Alert}
                                    alt="alarm"
                                    aria-hidden="true"
                                    className="alarm-icon"
                                    style={{
                                        height: '12px',
                                        marginRight: '4px',
                                        width: '12px',
                                        marginLeft: '3px'
                                    }}
                                />
                            )}
                            <AnalyticsToggle
                                isToggled={toggleState.alarms}
                                onToggle={() => toggleCallback('alarms')}
                            />
                        </div>
                        <div
                            className="alarm-wrap"
                            data-open={toggleState.alarms}
                        >
                            {data.alarmCount === 0 && <p>No alarms</p>}
                        </div>
                        <Link to={'/dashboard'} className="close-btn">
                            <Button className="expand-button">
                                {t('closeDetails')}
                            </Button>
                        </Link>
                    </div>
                    <div className="mobile-close-wrap">
                        <Link to={'/dashboard'} className="close-btn">
                            <Button className="expand-button">
                                {t('common:cancel')}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <InstallContactModal colorButton={colorButton} />

            <ManageReportModal
                {...props}
                colorButton={
                    constants.colors[
                        `Blue Button` as keyof typeof constants.colors
                    ]
                }
            />
        </div>
    );
};

export default AnalyticsCard;
