import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Ifacility } from '../../models';
import CommonModal from '../common/CommonModal';
import { getFacilities } from '../../reducers/facilitiesReducer';
import { constants } from '../../constants/constants';
import { toastr } from 'react-redux-toastr';
import { FormUtil } from '../common/FormUtil';
import { searchFacilitiesForAsyncSelect } from '../../actions/commonActions';
import { debounce } from 'lodash';
import { FormArray, FormGenerator, FormGroup } from 'react-reactive-form';
import { GET_FACILITIES_SUCCESS } from '../../actions/actionTypes';

interface Iprops {
    showModal: boolean;
    hideModal: () => void;
    colorButton: string;
    secondWideModal: boolean;
    mergeFacilities: (primary: string, secondary: string[]) => void;
    selectedFacility: Ifacility;
    countryId: string | undefined;
}

interface IdispatchProps {
    searchFacilitiesForAsyncSelect: typeof searchFacilitiesForAsyncSelect;
}

const SearchFacilityModal: React.FC<Iprops &
    WithTranslation &
    IdispatchProps> = props => {
    const { t } = props;
    const allFacilities = useSelector(getFacilities);
    const [primaryFacilityForm, setPrimaryFacilityForm] = React.useState<
        FormArray | FormGroup
    >();
    const [fieldConfig, setFieldConfig] = React.useState<any>();
    const dispatch = useDispatch();

    const saveFacilities = (facilities: Ifacility[]) => {
        dispatch({
            type: GET_FACILITIES_SUCCESS,
            facilities
        });
    };

    const merge = () => {
        if (!primaryFacilityForm) {
            return;
        }
        const primaryFacilityFromValue: {
            countryId: string;
            label: string;
            value: string;
        } = primaryFacilityForm.value.facilityControl;
        if (!primaryFacilityFromValue || !primaryFacilityFromValue.value) {
            toastr.warning(
                t('warning'),
                t('Select the primary facility'),
                constants.toastrWarning
            );
        } else {
            const primaryFacility =
                allFacilities[primaryFacilityFromValue.value];
            if (!primaryFacility) {
                toastr.warning(
                    t('warning'),
                    t('cannot find facility'),
                    constants.toastrWarning
                );
            }
            if (primaryFacility.id === props.selectedFacility.id) {
                toastr.warning(
                    t('warning'),
                    t('Select a different Facility'),
                    constants.toastrWarning
                );
            } else if (
                primaryFacility.standardID !== props.selectedFacility.standardID
            ) {
                toastr.warning(
                    t('warning'),
                    t('Both facilities must have the same standard'),
                    constants.toastrWarning
                );
            } else {
                const toastrConfirmOptions = {
                    onOk: () => {
                        props.mergeFacilities(primaryFacility.id, [
                            props.selectedFacility.id
                        ]);
                    },
                    onCancel: () => console.info('CANCEL clicked'),
                    okText: t('Ok'),
                    cancelText: t('Cancel')
                };

                toastr.confirm(
                    `Are you sure you want to merge ${props.selectedFacility.name} into ${primaryFacility?.name}?`,
                    toastrConfirmOptions
                );
            }
        }
    };

    const loadOptions = debounce((searchTerm, callback) => {
        if (searchTerm.length >= 3) {
            props.searchFacilitiesForAsyncSelect(
                searchTerm,
                props.countryId
                    ? props.countryId
                    : props.selectedFacility.countryID,
                null,
                callback,
                saveFacilities
            );
            return;
        }
        callback();
    }, 200);

    React.useEffect(() => {
        const facilityControl = {
            render: FormUtil.AsyncSelect,
            options: {
                validators: FormUtil.validators.requiredWithTrim
            },
            meta: {
                label: 'facility',
                loadOptions,
                colWidth: 12,
                colWidthMedium: 12,
                colWidthLarge: 12,
                required: true,
                type: 'select',
                placeholder: 'manageInventory:facilityRequired'
            },
            formState: {
                value: { value: '', label: '' },
                disabled: false
            }
        };
        setFieldConfig({ controls: { facilityControl } });
    }, [props.countryId]);

    return (
        <>
            <CommonModal
                show={props.showModal}
                className="second-wide-modal"
                onHide={() => props.hideModal}
                title={t('mergeFacilities')}
                container={document.getElementById('two-pane-layout')}
                secondModal={props.secondWideModal}
            >
                <Row>
                    <Col xs={12}>
                        <p style={{ marginLeft: '15px' }}>
                            Selecting a new facility here will merge all of the
                            contracts, installs, and contacts from the original
                            facility into the selected facility.
                        </p>
                    </Col>
                </Row>
                <Row style={{ padding: '0px 12px' }}>
                    <Col xs={12}>
                        <FormGenerator
                            onMount={setPrimaryFacilityForm}
                            fieldConfig={fieldConfig}
                        />
                    </Col>
                </Row>
                <Col xs={12} className="form-buttons text-right">
                    <Button
                        bsStyle="default"
                        type="button"
                        className="pull-left"
                        onClick={props.hideModal}
                    >
                        {t('close')}
                    </Button>
                    <Button bsStyle={props.colorButton} onClick={merge}>
                        {t('merge')}
                    </Button>
                </Col>
            </CommonModal>
        </>
    );
};

export default withTranslation('manageFacility')(
    connect(() => {}, {
        searchFacilitiesForAsyncSelect
    })(SearchFacilityModal)
);
