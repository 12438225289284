/*
 * Preventative Maintenance Table
 */

import * as React from 'react';

import { TFunction } from 'i18next';
import { constants } from '../../constants/constants';
import ReactTable, { FinalState, RowInfo } from 'react-table';
import { IWorkOrder, Itile, ItableFiltersReducer } from '../../models';
import { TableUtil } from '../common/TableUtil';
import {
    setSelectedWorkOrderID,
    setTableFilter
} from '../../actions/manageWorkOrderActions';
import { PreventativeMaintenanceExpanderContainer } from './PreventativeMaintenanceExpanderContainer';

const columns = (t: TFunction) =>
    TableUtil.translateHeaders(
        [
            {
                id: 'expander-toggle',
                expander: true,
                Expander: TableUtil.expanderToggle,
                style: {
                    cursor: 'pointer',
                    textAlign: 'left',
                    userSelect: 'none'
                }
            },
            {
                Header: 'Activity',
                accessor: 'activityDescription',
                width: 220
            },
            {
                Header: 'productName',
                accessor: 'productName'
            },
            {
                Header: 'Location',
                accessor: 'installBaseLocation'
            }
        ],
        t
    );

interface Iprops {
    tableData: IWorkOrder[];
    t: TFunction;

    setSelectedWorkOrderID: typeof setSelectedWorkOrderID;
    currentTile: Itile;
    loading: boolean;
    totalPages: number;
    tableFilters: ItableFiltersReducer;
    setTableFilter: typeof setTableFilter;
    overrideColumns?: any[];
}
interface Istate {
    selectedRow: any;
    columns: any[];
}
interface RowInfoWorkOrder extends RowInfo {
    original: IWorkOrder;
}

export class PreventativeMaintenanceTable extends React.PureComponent<
    Iprops,
    Istate
> {
    constructor(props: Iprops) {
        super(props);
        this.state = {
            selectedRow: {},
            columns: columns(this.props.t)
        };
    }
    componentDidUpdate(prevProps: Iprops) {
        // scroll top every time a filter changes
        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
    }

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.props.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                },
                onClick: () => {
                    this.props.setSelectedWorkOrderID(rowInfo.original.id);
                    this.setState({
                        selectedRow: {
                            [rowInfo.viewIndex || 0]: !this.state.selectedRow[
                                rowInfo.viewIndex || 0
                            ]
                        }
                    });
                }
            };
        } else {
            return {};
        }
    };

    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };
    render() {
        const { tableData, t, loading, totalPages } = this.props;
        return (
            <ReactTable
                data={tableData}
                columns={this.props.overrideColumns || this.state.columns}
                getTrProps={this.getTrProps}
                pageSize={tableData.length >= 10 ? tableData.length : 10}
                manual
                pages={totalPages}
                page={this.props.tableFilters.page - 1}
                showPageSizeOptions={false}
                className={`beacon-table -highlight ${this.props.currentTile.color}`}
                previousText={t('common:previous')}
                nextText={t('common:next')}
                onPageChange={this.onPageChange}
                sortable={false}
                multiSort={false}
                noDataText={t('common:noDataText')}
                resizable={false}
                loading={loading}
                SubComponent={(rowInfo: RowInfo) => {
                    return (
                        <PreventativeMaintenanceExpanderContainer
                            {...rowInfo}
                            t={t}
                        />
                    );
                }}
                expanded={this.state.selectedRow}
            />
        );
    }
}

// export const DashboardAnalytics = withTranslation('dashboardAnalytics')(
//   DashboardAnalyticsFunc
// );
