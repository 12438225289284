/*
 * Measurement Point Result Modal - list the answers for the most recent result
 */

import { toggleMPResultModal } from '../../../actions/manageInventoryActions';
import { connect } from 'react-redux';
import { MPResultList } from '../MPResultList';
import { getMeasurementPointList } from '../../../actions/manageMeasurementPointListsActions';
import {
    clearHistoricalResultID,
    getMeasurementPointListResult
} from '../../../actions/measurementPointResultsActions';

import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../../reducers/commonReducers';

import { WithCommonMobileModal } from '../../common/mobile/WithCommonMobileModal';
import { IinitialState } from '../../../reducers';
import { initialMeasurmentPointResult } from '../../../reducers/initialState';

interface Iprops {
    colorButton: any;
    secondModal: boolean;
    locationString: string;
    selectedMeasurementPointResultID: string;
}

const defaultProps = {
    className: 'result-list'
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const selectedMeasurementPointResult =
        state.measurementPointResults.measurementPointResultsByID[
            ownProps.selectedMeasurementPointResultID
        ];
    const selectedItem = selectedMeasurementPointResult || {
        ...initialMeasurmentPointResult,
        id: ownProps.selectedMeasurementPointResultID
    };
    return {
        ...defaultProps,
        user: state.user,
        loading: selectIsLoading(state),
        show: state.manageInventory.showMPResultModal,
        measurementPointsByID:
            state.manageMeasurementPointLists.measurementPointsByID,
        selectedItem,
        title: ownProps.t('MeasurementPointResultModalTitle')
    };
};

export const MPResultListMobileModal = withTranslation('manageInventory')(
    connect(mapStateToProps, {
        toggleModal: toggleMPResultModal,
        getMeasurementPointList,
        clearHistoricalResultID,
        getMeasurementPointListResult,
        onHide: toggleMPResultModal
    })(WithCommonMobileModal(MPResultList))
);
