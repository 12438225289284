import { Ihistory } from '../../models';

export const addQuery = (key: string, value: string, history: Ihistory) => {
    const query = new URLSearchParams(history.location.search);
    query.set(key, value);
    history.replace({ ...history.location, search: query.toString() });
};

export const hasQuery = (key: string, history: Ihistory) => {
    const UrlSearchParams = new URLSearchParams(history.location.search);
    return UrlSearchParams.has(key);
};

export const removeQuery = (key: string, history: Ihistory) => {
    const query = new URLSearchParams(history.location.search);
    query.delete(key);
    history.replace({ ...history.location, search: query.toString() });
};
