import * as types from '../actions/actionTypes';

import initialState from './initialState';
import { IsyncStatusReducer } from '../models';

export default function SyncStatusReducer(
    state: IsyncStatusReducer = initialState.syncStatus,
    action: any
): IsyncStatusReducer {
    switch (action.type) {
        case types.GET_FSE_SUCCESS:
            return { ...state, fseUsersUpdated: action.updateDate };
        case types.USER_LOGOUT_SUCCESS:
            return initialState.syncStatus;
        default:
            return state;
    }
}
