/*
 * Installs Expander Container connects the InstallBasesExpander to redux
 */

import {
    clearSelectedInstallBaseID,
    setInstallBatchMode,
    setSelectedInstallBaseID,
    setSelectedProductID,
    toggleEditInstallModal,
    toggleEditProductModal,
    toggleInstallContactModal,
    toggleMPResultModal,
    toggleSearchNewProductsModal
} from '../../actions/manageInventoryActions';
import {
    getCanCreateAsManager,
    getCanEditInstalls,
    getCanEditProducts,
    getCanRequestQuote
} from '../../reducers/userReducer';
import {
    getFacilityMeasurementPointResults,
    getMeasurementPointResultsHistoryByInstallBase
} from '../../actions/measurementPointResultsActions';

import { InstallBasesExpander } from './InstallBasesExpander';
import { RowInfo } from 'react-table';
import { addToCart } from '../../actions/shoppingCartActions';
import { connect } from 'react-redux';
import { initialFacility } from '../../reducers/initialState';
import {
    toggleEditWorkOrderModal,
    setSelectedWorkOrderID,
    getWorkOrdersByFacility
} from '../../actions/manageWorkOrderActions';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

type Iprops = RowInfo;

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const { selectedFacilityID } = state;

    const facility = state.facilities[selectedFacilityID] || initialFacility;
    const showAddInstallation = getCanEditInstalls(state.user);
    const showEditProduct = getCanEditProducts(state.user);
    const showRequestQuote = getCanRequestQuote(state.user);
    const showMoveFacility = getCanCreateAsManager(state.user);

    return {
        facility,
        loading: selectIsLoading(state),
        showAddInstallation,
        showEditProduct,
        showRequestQuote,
        showMoveFacility
    };
};

export const InstallBasesExpanderContainer = connect(mapStateToProps, {
    addToCart,
    toggleEditWorkOrderModal,
    toggleMPResultModal,
    toggleEditProductModal,
    toggleSearchNewProductsModal,
    setInstallBatchMode,
    setSelectedInstallBaseID,
    clearSelectedInstallBaseID,
    toggleEditInstallModal,
    setSelectedProductID,
    toggleInstallContactModal,
    getFacilityMeasurementPointResults,
    getMeasurementPointResultsHistoryByInstallBase,
    setSelectedWorkOrderID,
    getWorkOrdersByFacility
})(InstallBasesExpander);
