/*
 * The HeaderMenu only displays if we have an authenticated user.
 * It is responsible for displaying the welcome message and the dropdown menu for logged in users
 * spinner is from : http://tobiasahlin.com/spinkit/
 */

import { connect } from 'react-redux';

import {
    getFacilitiesByCountry,
    searchFacilitiesForAsyncSelect,
    toggleEditFacilityModal
} from '../../actions/commonActions';
import {
    saveQueueUser,
    approveUser,
    updateSelectedUserQueue
} from '../../actions/manageUserQueueActions';
import EditQueueUserForm from './EditQueueUserForm';
import { selectFacilityOptionsWithAddress } from '../../reducers/facilitiesReducer';
import { initialUserQueue } from '../../reducers/initialState';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { Ihistory } from '../../models';
import { manageUserQueueQueryParamsEnum } from './ManageUserQueue';
import { hasQuery, removeQuery } from '../common/OtherUtils';

interface Iprops {
    colorButton: string;
    queryParams: typeof manageUserQueueQueryParamsEnum;
    history: Ihistory;
}

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const originalUserQueue =
        state.manageUserQueue.userQueuesByID[
            ownProps.queryParams.selectedUserQueueID
        ] || initialUserQueue;
    // define the closeModal function here in mapState so that we can pass it to both the main component and
    // to the onHide which is a WithCommonModal HOC prop
    // yes it is unusual to pass a function in mapState, but it is ok since it does not need dispatch
    const closeModal = () => {
        removeQuery(
            manageUserQueueQueryParamsEnum.selectedUserQueueID,
            ownProps.history
        );
    };

    const title = ownProps.t('newUserModalTitle');

    return {
        closeModal,
        loading: selectIsLoading(state),
        // these customers are filtered by country; request for it is made when modal is opened
        facilityOptionsWithAddress: selectFacilityOptionsWithAddress(state),
        title,
        show: hasQuery(
            manageUserQueueQueryParamsEnum.selectedUserQueueID,
            ownProps.history
        ),
        showEditFacilityModal: state.showEditFacilityModal,
        facilitiesByID: state.facilities,
        selectedUserQueue: originalUserQueue,
        originalUserQueue,
        className: 'user-edit',
        onHide: closeModal
    };
};

export default withTranslation('manageUserQueue')(
    connect(mapStateToProps, {
        getFacilitiesByCountry,
        toggleEditFacilityModal,
        approveUser,
        onChange: updateSelectedUserQueue,
        onSubmit: saveQueueUser,
        searchFacilitiesForAsyncSelect
    })(WithCommonModal(EditQueueUserForm))
);
