import { isEmpty } from 'lodash';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { userLogout } from '../../../actions/userActions';
import { Iuser } from '../../../models';

import { IinitialState } from '../../../reducers';
import { selectIsLoading } from '../../../reducers/commonReducers';
import DropDownMenu from './DropDownMenu';

interface Iprops extends RouteComponentProps {
    user: Iuser;
    alertsCount: number;
    userLogout: any;
    loading: boolean;
    // toggleTutorial: typeof toggleTutorial;

    // toggleEditProfileModal: any;
    // toggleUserAlertModal: typeof toggleUserAlertModal;
}

interface Istate {
    menuOpen: boolean;
    showOfflineQueueStatus: boolean;
    showOfflineQueueReset: boolean;
    localCartTotal: number;
}

const HeaderMenuMobile: React.FC<Iprops & WithTranslation> = props => {
    const [state, setState] = React.useState<Istate>({
        menuOpen: false,
        showOfflineQueueStatus: false,
        showOfflineQueueReset: false,
        localCartTotal: 0
    });

    const menuClass = state.menuOpen ? 'menu-open' : '';

    const handleMenuSelect = (eventKey: any) => {
        switch (eventKey) {
            case '0':
                window.location.replace('/dashboard');
                break;
            case '1':
                window.location.replace('/inventory');
                break;
            case '2':
                window.location.replace('/training');
                break;
            case '3':
                window.location.replace('/documents');
                break;
            case '4':
                window.location.replace('/quotes');
                break;

            case '5':
                props.userLogout(props.t);
                break;
            default:
                break;
        }
    };

    // If the user is not logged in, don't show the menu
    if (props.user.id.length === 0) {
        return <></>;
    }

    return (
        <>
            <DropDownMenu
                toggle={isOpen => {
                    setState({ ...state, menuOpen: isOpen });
                }}
                handleMenuSelect={handleMenuSelect}
                menuClass={menuClass}
                user={props.user}
                loading={props.loading}
                menuOpen={state.menuOpen}
            />
        </>
    );
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    let alertsCount = 0;

    if (isEmpty(state.alertsByID) === false) {
        for (const key of Object.keys(state.alertsByID)) {
            if (state.alertsByID[key].isViewed === false) {
                alertsCount++;
            }
        }
    }

    return {
        user: state.user,
        loading: selectIsLoading(state),
        alertsCount
    };
};

export default withTranslation('common')(
    connect(mapStateToProps, {
        userLogout
        // toggleEditProfileModal,
        // toggleUserAlertModal,
        // toggleTutorial
    })(HeaderMenuMobile)
);
