// create a react functional component with typescript to use for toggling between two states
import React from 'react';

interface Props {
    isToggled: boolean;
    onToggle: () => void;
}

export const AnalyticsToggle = ({ isToggled, onToggle }: Props) => (
    <button
        type="button"
        className="analytics-card-expander"
        onClick={onToggle}
    >
        <span className="sr-only">Open Modal</span>
        <svg
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
            data-open={isToggled}
        >
            <path d="M0.000888837 12.1625L0.000888385 1.81068C-0.00211109 1.65038 0.0432593 1.49289 0.131084 1.35874C0.218908 1.2246 0.345112 1.12003 0.49324 1.05867C0.641369 0.997311 0.804548 0.982003 0.961503 1.01475C1.11846 1.04749 1.26191 1.12676 1.37315 1.24223L6.55901 6.4281C6.6347 6.50237 6.69482 6.59098 6.73586 6.68875C6.7769 6.78653 6.79804 6.8915 6.79804 6.99754C6.79804 7.10358 6.7769 7.20856 6.73586 7.30633C6.69482 7.40411 6.6347 7.49272 6.55901 7.56699L1.37315 12.7529C1.26066 12.8699 1.11512 12.9499 0.955982 12.9821C0.79684 13.0143 0.631665 12.9972 0.482518 12.933C0.333372 12.8688 0.207352 12.7607 0.121292 12.623C0.0352318 12.4853 -0.00677206 12.3246 0.000888837 12.1625Z" />
        </svg>
    </button>
);
