/*
 * Analytics Card Container
 */

import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import { withTranslation, WithTranslation } from 'react-i18next';
import { selectIsLoading } from '../../../reducers/commonReducers';
import { IinitialState } from '../../../reducers';
import AnalyticsCard from './AnalyticsCard';
import { selectDataFormatPreference } from '../../../reducers/dashboardAnalyticsReducer';

const mapStateToProps = (
    state: IinitialState,
    ownProps: RouteComponentProps<{}> & WithTranslation
) => {
    const { selectedFacilityID, user } = state;
    const { securityFunctions } = user;
    const query = queryString.parse(ownProps.location.search);
    const dataFormatPreference = selectDataFormatPreference(state);

    return {
        selectedFacilityID,
        securityFunctions,
        loading: selectIsLoading(state),
        isDemoMode: query.demo === 'true',
        dataFormatPreference
    };
};

export const AnalyticsCardContainer = withTranslation('dashboardAnalytics')(
    withRouter(connect(mapStateToProps, {})(AnalyticsCard))
);
