import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';
import { IWorkOrder, ItableFiltersReducer, Itile, Ioption } from '../../models';
import { Column, FinalState, RowInfo } from 'react-table';
import {
    clearSelectedWorkOrderID,
    deleteWorkOrder,
    getTechnicians,
    getWorkOrderCsv,
    getWorkOrders,
    setSelectedWorkOrderID,
    setTableFilter,
    toggleWorkOrderCloseModal,
    toggleEditWorkOrderModal
} from '../../actions/manageWorkOrderActions';

import { Banner } from '../common/Banner';
import { EditWorkOrderModal } from '../manageWorkOrder/EditWorkOrderModal';
import { FieldConfig } from 'react-reactive-form';
import { FormUtil } from '../common/FormUtil';
import { RouteComponentProps } from 'react-router';
import SearchTableForm from '../common/SearchTableForm';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { TableUtil } from '../common/TableUtil';
import { connect } from 'react-redux';
import { constants } from '../../constants/constants';
import { emptyTile, initialWorkOrder } from '../../reducers/initialState';
import { getProductInfo } from '../../actions/manageInventoryActions';
import moment from 'moment';
import { debounce, orderBy } from 'lodash';
import {
    workOrderStatusEnum,
    workOrderTypesEnum,
    workOrderDateRangeUnumForFilter
} from '../../models-enums';
import { toastr } from 'react-redux-toastr';
import { PreventativeMaintenanceTableContainer } from '../manageWorkOrder/PreventativeMaintenanceTableContainer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

interface RowInfoWorkOrder extends RowInfo {
    original: IWorkOrder;
}

type Iprops = RouteComponentProps<any>;

interface IdispatchProps {
    tableData: IWorkOrder[];
    totalPages: number;
    showEditWorkOrderModal: boolean;
    getWorkOrders: typeof getWorkOrders;
    toggleEditWorkOrderModal: typeof toggleEditWorkOrderModal;
    toggleWorkOrderCloseModal: typeof toggleWorkOrderCloseModal;
    getTechnicians: typeof getTechnicians;
    deleteWorkOrder: typeof deleteWorkOrder;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    loading: boolean;
    setSelectedWorkOrderID: typeof setSelectedWorkOrderID;
    clearSelectedWorkOrderID: typeof clearSelectedWorkOrderID;
    getProductInfo: typeof getProductInfo;
    isAdmin: boolean;
    technicians: any[];
    getWorkOrderCsv: typeof getWorkOrderCsv;
    selectedFacilityID: string;
    selectedWorkOrderID: string;
    selectedWorkOrder: IWorkOrder;
}

interface Istate {
    selectedRow: any;
    currentTile: Itile;
    columns: any[];
    searchFieldConfig: FieldConfig;
    showCloseNoteModal: boolean;
}

class ManageWorkOrder extends React.Component<
    Iprops & IdispatchProps & WithTranslation,
    Istate
> {
    private setTableFilterDebounced: (formValues: {
        [key: string]: any;
    }) => void;
    constructor(props: Iprops & IdispatchProps & WithTranslation) {
        super(props);
        this.state = {
            selectedRow: {},
            currentTile: emptyTile,
            columns: [],
            searchFieldConfig: this.buildSearchControls(),
            showCloseNoteModal: false
        };
        this.setTableFilterDebounced = debounce(
            this.props.setTableFilter,
            constants.formDebounceTime
        );
    }

    componentDidMount(): void {
        this.setState({
            currentTile: constants.getTileByURL(this.props.location.pathname)
        });
        this.setColumns();

        // set the table filter for type 2
        this.props.setTableFilter({
            workOrderType: workOrderTypesEnum.pmp,
            page: 1
        });
        this.props.getWorkOrders(); // this is necessary for when the setTable does not change anything and fails to retrieve the correct work orders
        this.props.getProductInfo();
        this.props.getTechnicians();
    }

    componentDidUpdate(prevProps: Iprops & IdispatchProps) {
        if (
            prevProps.showEditWorkOrderModal !==
                this.props.showEditWorkOrderModal &&
            !this.props.showEditWorkOrderModal
        ) {
            this.setState({ selectedRow: null });
        }

        if (
            JSON.stringify(prevProps.tableFilters) !==
            JSON.stringify(this.props.tableFilters)
        ) {
            this.props.getWorkOrders();
            this.props.getTechnicians();
            this.setState({ searchFieldConfig: this.buildSearchControls() });
            // scroll top every time a filter changes
            const tableDiv = document.getElementsByClassName('rt-tbody');
            if (tableDiv && tableDiv.length) {
                tableDiv[0].scrollTop = 0;
            }
        }
        if (prevProps.selectedFacilityID !== this.props.selectedFacilityID) {
            this.props.getWorkOrders();
            this.props.getTechnicians();
        }

        if (
            JSON.stringify(prevProps.technicians) !==
            JSON.stringify(this.props.technicians)
        ) {
            this.setState({ searchFieldConfig: this.buildSearchControls() });
        }
    }

    /*
     * Note that technicianToDo is not an actual filter.  it is only used to select which To Do List to download
     */
    buildSearchControls = (): FieldConfig => {
        const disabled = false;

        const defaultStatus = {
            value: workOrderStatusEnum.open,
            label: this.props.t('open')
        };
        const {
            search,
            status = defaultStatus,
            dateRange,
            tabKey
        } = this.props.tableFilters;
        const searchPlaceholder =
            this.state && tabKey === 'beacon'
                ? 'searchNumberPlaceholderBeacon'
                : 'searchNumberPlaceholder';
        const statusOptions = FormUtil.convertEnumToOptions(
            workOrderStatusEnum
        );
        const mainSearchControls = {
            search: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'common:search',
                    colWidth: 3,
                    type: 'text',
                    placeholder: searchPlaceholder,
                    isClearable: true
                },
                formState: { value: search, disabled }
            },
            status: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: statusOptions,
                    label: 'status',
                    colWidth: 2,
                    shouldTranslate: true
                },
                formState: { value: status, disabled }
            },
            dateRange: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: FormUtil.convertEnumToOptions(
                        workOrderDateRangeUnumForFilter
                    ),
                    label: 'dateRange',
                    colWidth: 3,
                    isClearable: true,
                    shouldTranslate: true
                },
                formState: { value: dateRange, disabled }
            },
            seperator: {
                render: FormUtil.TextLabel,
                meta: {
                    colWidth: 1
                }
            },
            technicianToDo: {
                render: FormUtil.SelectWithoutValidation,
                meta: {
                    options: this.props.technicians
                        ? this.props.technicians.map(tech => ({
                              value: tech,
                              label: tech
                          }))
                        : null,
                    label: 'labelToDoList',
                    colWidth: 3,
                    isClearable: true,
                    style: tabKey === 'preventative' ? { display: 'none' } : {}
                },
                formState: { value: null, disabled }
            }
        };

        const searchFieldConfig = {
            controls: { ...mainSearchControls }
        } as FieldConfig;
        return searchFieldConfig;
    };

    downloadCsv(tech: Ioption | null) {
        const { t } = this.props;
        if (tech && tech.value) {
            this.props.getWorkOrderCsv(tech.value);
        } else {
            toastr.warning(
                t('common:warning'),
                t('Please select a technician.'),
                constants.toastrWarning
            );
        }
    }

    onSearchValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'search':
                this.setTableFilterDebounced({ search: value, page: 1 });
                break;
            case 'status':
                this.setTableFilterDebounced({
                    status: value ? value : null,
                    page: 1
                });
                break;
            case 'dateRange':
                this.setTableFilterDebounced({
                    dateRange: value ? value : null,
                    page: 1
                });
                break;
            case 'technicianToDo':
                this.downloadCsv(value);
                this.setState({
                    searchFieldConfig: this.buildSearchControls()
                }); // this clears the select
                // this.setTableFilterDebounced({ technicianToDo: value, page: 1 });
                break;
            default:
                break;
        }
    };

    handleEdit(row: any) {
        this.setState({ selectedRow: row.index });
        this.props.toggleEditWorkOrderModal();
        this.props.setSelectedWorkOrderID(row.original.id);
    }

    onPageChange = (page: number) => {
        const newPage = page + 1;
        this.props.setTableFilter({ page: newPage });
    };
    /*
     * Set Columns sets columns to state
     */

    setColumns = () => {
        const columns: Column[] = [
            {
                id: 'expander-toggle',
                expander: true,
                Expander: TableUtil.expanderToggle,
                style: {
                    cursor: 'pointer',
                    textAlign: 'left',
                    userSelect: 'none'
                }
            },
            {
                Header: 'name',
                accessor: 'name',
                width: 120
            },
            {
                Header: 'lastServiced',
                id: 'interval',
                accessor: (workOrder: IWorkOrder) => {
                    if (
                        workOrder.lastServicedDate &&
                        workOrder.lastServicedDate.length
                    ) {
                        return moment
                            .utc(workOrder.lastServicedDate)
                            .local(true)
                            .format('DD-MMM-YY');
                    } else {
                        return 'Never';
                    }
                },
                width: 110
            },
            {
                Header: 'productName',
                accessor: 'productName'
            },
            {
                Header: 'location',
                accessor: 'installBaseLocation'
            },
            {
                Header: 'dueDate',
                id: 'dueDate',
                accessor: (workOrder: IWorkOrder) => {
                    if (workOrder.dueDate) {
                        return moment
                            .utc(workOrder.dueDate)
                            .local(true)
                            .format('DD-MMM-YY');
                    } else {
                        return 'missing due date';
                    }
                },
                width: 110
            },
            {
                Header: 'Status',
                id: 'status',
                accessor: (workOrder: IWorkOrder) => {
                    return this.props.t(workOrderStatusEnum[workOrder.status]);
                },
                width: 90
            }
        ];

        this.setState({
            columns: TableUtil.translateHeaders(columns, this.props.t)
        });
    };

    /*
     * (reusable)
     * Handle user clicking on a product row
     * set the selected product to state and open the modal
     */
    getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.index === this.state.selectedRow
                            ? constants.colors[
                                  `${this.state.currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };

    getTdProps = (
        fState: FinalState,
        rowInfo: RowInfoWorkOrder | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (
            rowInfo &&
            column &&
            column.id &&
            column.id === 'status-update' &&
            rowInfo.original.status === workOrderStatusEnum.new
        ) {
            return {
                onClick: () => {
                    this.setState({ selectedRow: rowInfo.index });
                    this.props.toggleWorkOrderCloseModal();
                    this.props.setSelectedWorkOrderID(rowInfo.original.id);
                }
            };
        } else if (rowInfo) {
            return {
                onClick: () => {
                    this.setState({
                        selectedRow: rowInfo.index
                    });
                    this.handleEdit(rowInfo);
                }
            };
        } else {
            return {};
        }
    };

    // disabled on the PMP tab and hide the delete and save buttons.
    shouldEnableWorkOrderForm = () => {
        return false;
    };

    render() {
        const { t } = this.props;
        const colorButton =
            constants.colors[
                `${this.state.currentTile.color}Button` as keyof typeof constants.colors
            ];

        return (
            <div className="manage-work-order">
                <Banner
                    title={t('maintenance')}
                    img={this.state.currentTile.srcBanner}
                    color={this.state.currentTile.color}
                >
                    <SelectFacilityContainer
                        t={this.props.t}
                        classNameOverride={''}
                    />
                </Banner>
                <SearchTableForm
                    fieldConfig={this.state.searchFieldConfig}
                    handleSubmit={this.props.getWorkOrders}
                    loading={this.props.loading}
                    colorButton={colorButton}
                    subscribeValueChanges={true}
                    onValueChanges={this.onSearchValueChanges}
                    t={this.props.t}
                    showSearchButton={false}
                />

                <PreventativeMaintenanceTableContainer
                    t={t}
                    colorButton={colorButton}
                    currentTile={this.state.currentTile}
                    overrideColumns={this.state.columns}
                />

                <EditWorkOrderModal
                    colorButton={colorButton}
                    disabled={true}
                    shouldDisableVendorSelect={true}
                    shouldHideDelete={true}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: IinitialState) => {
    const tableData = orderBy(state.manageWorkOrder.workOrdersByID, res => res);
    let isAdmin = constants.hasSecurityFunction(state.user, [
        constants.securityFunctions.ManageWorkOrderAdmin.id
    ]);
    isAdmin = true;

    const selectedWorkOrder =
        state.manageWorkOrder.workOrdersByID[
            state.manageWorkOrder.selectedWorkOrderID
        ] || initialWorkOrder;
    return {
        tableData,
        isAdmin,
        totalPages: state.manageWorkOrder.totalPages,
        showEditWorkOrderModal: state.manageWorkOrder.showEditWorkOrderModal,
        tableFilters: state.manageWorkOrder.tableFilters,
        technicians: state.manageWorkOrder.technicians,
        selectedFacilityID: state.selectedFacilityID,
        selectedWorkOrderID: state.manageWorkOrder.selectedWorkOrderID,
        loading: selectIsLoading(state),
        selectedWorkOrder
    };
};

export const PMPAnalyticsTableLayout = withTranslation('manageWorkOrder')(
    connect(mapStateToProps, {
        getWorkOrders,
        toggleEditWorkOrderModal,
        toggleWorkOrderCloseModal,
        deleteWorkOrder,
        setTableFilter,
        setSelectedWorkOrderID,
        clearSelectedWorkOrderID,
        getTechnicians,
        getProductInfo,
        getWorkOrderCsv
    })(ManageWorkOrder)
);
