import * as types from './actionTypes';

import { AxiosRequestConfig, AxiosResponse } from 'axios';

import API from '../constants/apiEndpoints';
import { beginAjaxCall } from './ajaxStatusActions';
import { constants } from '../constants/constants';
import { msalFetch } from '../components/auth/Auth-Utils';
import { ThunkResult } from '../models';
import { toastr } from 'react-redux-toastr';

/*
 * get the analytics data for the dashboard
 */
export function getDashboardAnalytics(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const { selectedFacilityID } = getState();
        if (selectedFacilityID.length === 0) {
            console.error('[getDashboardAnalytics]:', {
                selectedFacilityID,
                state: getState()
            });
            toastr.warning(
                'Warning',
                'Selected customer has 0 facilities.',
                constants.toastrWarning
            );
        }
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {
                facilityID: selectedFacilityID
            }
        };

        const url = API.update.dashboard;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.GET_DASHBOARD_ANALYTICS_SUCCESS,
                        payload: data.data
                    });
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.GET_DASHBOARD_ANALYTICS_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get dashboard analytics');
                console.error(error);
            });
    };
}
