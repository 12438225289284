import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../constants/apiEndpoints';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { msalFetch } from '../components/auth/Auth-Utils';
import { IcountryBE } from '../models';
import * as types from './actionTypes';
import { constants } from '../constants/constants';

export const loadCountries = (
    dispatch: Function,
    currentCountries?: IcountryBE[]
) => {
    if (currentCountries && currentCountries.length > 0) {
        return;
    }
    dispatch(beginAjaxCall());
    const url = API.country.getAll;
    const axiosOptions: AxiosRequestConfig = {
        method: 'get'
    };
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<IcountryBE[]>) => {
            dispatch(setCountries(data.data));
        })
        .catch(e => {
            constants.handleError(e, 'get Documents');
            console.error(e);
        })
        .finally(() => endAjaxCall());
};

export const setCountries = (countries: IcountryBE[]) => ({
    type: types.SET_COUNTRIES,
    payload: countries
});

export default {}; // Typescript yells and won't compile without this...
