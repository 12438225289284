/*
 * Work Order Close Form
 */

import { Col, Button } from 'react-bootstrap';
import {
    FormGenerator,
    AbstractControl,
    FieldConfig,
    FormGroup
} from 'react-reactive-form';

import * as React from 'react';
import { toastr } from 'react-redux-toastr';

import { constants } from '../../constants/constants';
import { FormUtil } from '../common/FormUtil';
import {
    clearSelectedWorkOrderID,
    addWorkOrder
} from '../../actions/manageWorkOrderActions';
import { IWorkOrder } from '../../models';
import {
    workOrderStatusEnum,
    workOrderTypesEnum,
    workOrderVendorsEnum
} from '../../models-enums';
import moment from 'moment';
import { TFunction } from 'i18next';
import { initialWorkOrder } from '../../reducers/initialState';

interface Iprops {
    toggleModal: () => void;
    loading: boolean;
    selectedWorkOrder: IWorkOrder;
    colorButton: string;
    t: TFunction;
    updateWorkOrder: (workOrder: IWorkOrder) => Promise<any>;
    clearSelectedWorkOrderID: typeof clearSelectedWorkOrderID;
    addWorkOrder: typeof addWorkOrder;
    selectedInstallBaseID?: string;
    enableRepairMode?: boolean;
}

class WorkOrderCloseForm extends React.Component<Iprops, {}> {
    private form: FormGroup | any;
    public fieldConfig: FieldConfig;

    constructor(props: Iprops) {
        super(props);
        this.fieldConfig = FormUtil.translateForm(
            this.buildFieldConfig(),
            this.props.t
        );
    }

    buildFieldConfig = () => {
        const { status, closingNotes, id } = this.props.selectedWorkOrder;
        const shouldHideTechnician = id.length > 0;

        let fieldConfigControls;
        if (status === workOrderStatusEnum.complete) {
            const notes = closingNotes ? closingNotes : '';

            fieldConfigControls = {
                closingNotes: {
                    render: FormUtil.TextLabel,
                    meta: {
                        label: 'closingNote',
                        colWidth: 12,
                        name: 'closing-note'
                    },
                    formState: notes
                }
            };
        } else if (this.props.enableRepairMode === true) {
            fieldConfigControls = {
                closingNotes: {
                    options: {
                        validators: [FormUtil.validators.requiredWithTrim]
                    },
                    render: FormUtil.TextInput,
                    meta: {
                        label: 'manageInventory:labelRepairComment',
                        componentClass: 'textarea',
                        colWidth: 12,
                        rows: 10,
                        type: 'input',
                        autoFocus: true,
                        name: 'closing-note'
                    }
                },
                technician: {
                    render: FormUtil.TextInput,
                    meta: {
                        label: 'manageInventory:labelRepairWho',
                        colWidth: 12,
                        // autoFocus: true,
                        required: false,
                        name: 'tehnician',
                        style: shouldHideTechnician ? { display: 'none' } : {}
                    }
                }
            };
        } else {
            fieldConfigControls = {
                closingNotes: {
                    options: {
                        validators: [FormUtil.validators.requiredWithTrim]
                    },
                    render: FormUtil.TextInput,
                    meta: {
                        label: 'closingNote',
                        componentClass: 'textarea',
                        colWidth: 12,
                        rows: 10,
                        type: 'input',
                        autoFocus: true,
                        name: 'closing-note'
                    }
                },
                technician: {
                    render: FormUtil.TextInput,
                    meta: {
                        label: 'tehnician',
                        colWidth: 12,
                        // autoFocus: true,
                        required: false,
                        name: 'tehnician',
                        style: shouldHideTechnician ? { display: 'none' } : {}
                    }
                }
            };
        }

        return {
            controls: { ...fieldConfigControls }
        };
    };

    handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this.form.status === 'INVALID') {
            this.form.markAsSubmitted();
            toastr.error(
                this.props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        const { closingNotes, technician } = this.form.value;

        // TODO if there is not a work order, then we will create one
        if (this.props.selectedWorkOrder.id.length) {
            this.props.updateWorkOrder({
                ...this.props.selectedWorkOrder,
                closingNotes,
                status: workOrderStatusEnum.complete
            });
        } else {
            // NEW
            if (!this.props.selectedInstallBaseID) {
                toastr.console.error(
                    this.props.t('error'),
                    this.props.t('Unable to save.  Missing asset.'),
                    constants.toastrError
                );
                console.error(
                    'missing install base when tryig to save new work order',
                    this.props
                );
                return;
            }
            const newWorkOrder: IWorkOrder = {
                ...initialWorkOrder,
                installBaseID: this.props.selectedInstallBaseID,
                status: workOrderStatusEnum.complete,
                type: workOrderTypesEnum.repair,
                number: '',
                technician: technician || '',
                contactInfo: '',
                vendor: workOrderVendorsEnum.internal,
                activityDescription: 'repairing asset',
                partsDescription: '',
                notes: '',
                closingNotes,
                dueDate: moment.utc().format(),
                priority: 1,
                isDeleted: false
            };
            this.props.addWorkOrder(newWorkOrder);
        }

        this.props.toggleModal();
        this.props.clearSelectedWorkOrderID();
    };

    toggleModal = () => {
        this.props.toggleModal();
        this.props.clearSelectedWorkOrderID();
    };

    setForm = (form: AbstractControl) => {
        this.form = form;
        this.form.meta = {
            loading: this.props.loading
        };
    };

    render() {
        const { t } = this.props;
        const { status } = this.props.selectedWorkOrder;
        const formClassName = `beacon-form location-form ${this.props.colorButton}`;

        let button;
        if (status !== workOrderStatusEnum.complete) {
            button = (
                <Button
                    bsStyle={this.props.colorButton}
                    type="submit"
                    disabled={this.props.loading}
                >
                    {t('common:save')}
                </Button>
            );
        }

        return (
            <div>
                <form onSubmit={this.handleSubmit} className={formClassName}>
                    <FormGenerator
                        onMount={this.setForm}
                        fieldConfig={this.fieldConfig}
                    />
                    <Col xs={12} className="form-buttons text-right">
                        <Button
                            bsStyle="default"
                            type="button"
                            className="pull-left"
                            onClick={this.toggleModal}
                        >
                            {t('common:cancel')}
                        </Button>
                        {button}
                    </Col>
                </form>
            </div>
        );
    }
}
export default WorkOrderCloseForm;
