import {
    ItableFiltersReducer,
    Iuser,
    IproductInfo,
    ImeasurementPointResult,
    ImeasurementPointResultWithJobType
} from '../models';
import initialState, { initialTableFilters } from './initialState';
import * as types from '../actions/actionTypes';
import { selectMeasurementPointListResultsByInstallBaseID } from './measurementPointResultsReducer';
import { selectCompleteWorkOrdersByInstallBaseID } from './manageWorkOrderReducer';
import { filter, orderBy, forEach, map, keyBy } from 'lodash';
import moment from 'moment';
import { measurementPointResultStatusTypesEnum } from '../models-enums';
import { cleanUser } from './manageUserReducer';
import { createSelector } from 'reselect';
import { IinitialState } from '.';
import { selectJobTypeForJobID } from './manageJobReducer';

/*
 * COMMON SELECTORS
 */

const selectIsOfflineBusy = (state: IinitialState) => state.offline.busy;
const selectAjaxCallsInProgress = (state: IinitialState) =>
    state.ajaxCallsInProgress;
const selectFacilityContactModal = (state: IinitialState) =>
    state.showFacilityContactModal;
const selectFacilityContractModal = (state: IinitialState) =>
    state.showFacilityContractModal;

export const selectIsFacilityContactModalOpen = createSelector(
    [selectFacilityContactModal],
    facilityContactModal => {
        return facilityContactModal;
    }
);

// Product Information

const selectProductInfo = (state: IinitialState) => state.productInfo;
export const selectProductInfoSubCategories = createSelector(
    selectProductInfo,
    (productInfo: IproductInfo) => productInfo?.subcategories
);

export const selectIsFacilityContractModalOpen = createSelector(
    [selectFacilityContractModal],
    facilityContractModal => {
        return facilityContractModal;
    }
);

export const selectIsLoading = createSelector(
    [selectIsOfflineBusy, selectAjaxCallsInProgress],
    (isOfflineBusy, ajaxCallsInProgress) => {
        return isOfflineBusy || ajaxCallsInProgress > 0;
    }
);
/*
 * prepare the array for the install base history
 * gather completed MPLRs and work orders and stuff them into a single array
 * do not show touchpoints which are result type repair and maintain
 */
export const selectInstallBaseHistoryByInstallBaseID = (
    state: IinitialState,
    installBaseID: string
) => {
    const installBaseMeasurementPointListResults = selectMeasurementPointListResultsByInstallBaseID(
        state.measurementPointResults,
        installBaseID
    );
    const installBaseCompletedWorkOrders = selectCompleteWorkOrdersByInstallBaseID(
        state.manageWorkOrder,
        installBaseID
    );
    const resultsWithoutTouchpoints = filter(
        installBaseMeasurementPointListResults,
        result =>
            (result.status ===
                measurementPointResultStatusTypesEnum.resultStatusMaintain ||
                result.status ===
                    measurementPointResultStatusTypesEnum.resultStatusRepaired) ===
            false
    );

    const resultsWithoutTouchpointsWithJobType = resultsWithoutTouchpoints.map(
        (
            result: ImeasurementPointResult
        ): ImeasurementPointResultWithJobType => {
            const jobTypeID = selectJobTypeForJobID(state, {
                jobID: result.jobID
            });
            return { ...result, jobTypeID };
        }
    );

    return orderBy(
        [
            ...resultsWithoutTouchpointsWithJobType,
            ...installBaseCompletedWorkOrders
        ],
        res => moment.utc(res.updateDate).unix(),
        'desc'
    );
};

/*
 * COMMON REDUCER CREATORS
 */
export function createShowModalWithNamedType(modalName = '') {
    return function modalToggle(state = false, action: any): boolean {
        switch (action.type) {
            case `TOGGLE_MODAL_${modalName}`:
                if (typeof action.show === 'boolean') {
                    return action.show;
                }
                return !state;
            case `SHOW_MODAL_${modalName}`:
                return true;
            case `HIDE_MODAL_${modalName}`:
                return false;
            case types.CLOSE_ALL_MODALS:
                return false;
            default:
                return state;
        }
    };
}

export function modalToggleWithName(
    state = false,
    action: any,
    modalName: string
): boolean {
    switch (action.type) {
        case `TOGGLE_MODAL_${modalName}`:
            if (typeof action.show === 'boolean') {
                return action.show;
            }
            return !state;
        case `SHOW_MODAL_${modalName}`:
            return true;
        case `HIDE_MODAL_${modalName}`:
            return false;
        case types.CLOSE_ALL_MODALS:
            return false;
        default:
            return state;
    }
}

export function createTableFiltersWithName(
    state: ItableFiltersReducer = initialTableFilters,
    action: any,
    tableName: string
): ItableFiltersReducer {
    switch (action.type) {
        case `SET_TABLE_FILTER_${tableName}`:
            return { ...state, ...action.filters } as ItableFiltersReducer;
        case types.USER_LOGOUT_SUCCESS:
            return initialTableFilters;
        case `CLEAR_TABLE_FILTERS_${tableName}`:
            return initialTableFilters;
        default:
            return state;
    }
}

export function createFormValuesWithName(
    state: { [key: string]: any } = {},
    action: any,
    tableName: string
): { [key: string]: any } {
    switch (action.type) {
        case `SET_FORM_VALUES_${tableName}`:
            return { ...action.formValues };
        case `UPDATE_FORM_VALUES_${tableName}`:
            return { ...state, ...action.formValues };
        case types.USER_LOGOUT_SUCCESS:
            return {};
        default:
            return state;
    }
}

export function createSelectedIDWithName(
    state = '',
    action: any,
    name: string
): string {
    switch (action.type) {
        case `SET_SELECTED_${name}`:
            return action.id;
        case `CLEAR_SELECTED_${name}`:
            return '';
        case types.USER_LOGOUT_SUCCESS:
            return '';
        default:
            return state;
    }
}

// this works in the reducers/index.tsx file
export function createSelectedIDWithNameRoot(name: string) {
    return (state = '', action: any): string => {
        switch (action.type) {
            case `GLOBAL_SET_SELECTED_${name}`:
                return action.id;
            case `GLOBAL_CLEAR_SELECTED_${name}`:
                return '';
            case types.USER_LOGOUT_SUCCESS:
                return '';
            default:
                return state;
        }
    };
}

/*
 * COMMON REDUCERS
 */

export function rawUsersByID(
    state: { [key: string]: Iuser } = initialState.rawUsersByID,
    action: any
): { [key: string]: Iuser } {
    switch (action.type) {
        case types.LEADS_MANAGE_SUCCESS: {
            let newUsers: { [key: string]: Iuser } = {};
            forEach(action.leads, lead => {
                if (lead.leadUsers) {
                    forEach(lead.leadUsers, leadU => {
                        newUsers = {
                            ...newUsers,
                            [leadU.user.id]: cleanUser(leadU.user)
                        };
                        if (leadU.user.salesManager) {
                            newUsers = {
                                ...newUsers,
                                [leadU.user.salesManager.id]: cleanUser(
                                    leadU.user.salesManager
                                )
                            };
                        }
                    });
                }
            });
            return { ...state, ...newUsers };
        }
        case types.GET_SECURITY_SUCCESS: {
            let newUsers: { [key: string]: Iuser } = {};
            forEach(action.users, user => {
                newUsers = { ...newUsers, [user.id]: cleanUser(user) };
                if (user.salesManager) {
                    newUsers = {
                        ...newUsers,
                        [user.salesManager.id]: cleanUser(user.salesManager)
                    };
                }
            });
            return { ...state, ...newUsers };
        }
        default:
            return state;
    }
}

/*
 *securityUsersByID
 * Users who have a specific set of security functions
 * used for sales manager dropdowns
 */
export function securityUsersByID(
    state: { [key: string]: Iuser } = initialState.securityUsersByID,
    action: any
): { [key: string]: Iuser } {
    switch (action.type) {
        case types.GET_SECURITY_SUCCESS: {
            const newUsers = map(action.users, user => {
                return cleanUser(user);
            });
            return keyBy(newUsers, 'id');
        }
        default:
            return state;
    }
}
