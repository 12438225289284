/*
 * Edit Facility Modal
 * responsible for editing a facility
 * this modal is rendered inside the UserQueueForm component because we pass the selectedCustomer
 */

import { connect } from 'react-redux';

import { Ifacility } from '../../models';
import {
    addFacility,
    toggleEditFacilityModal,
    updateFacility,
    deleteFacility
} from '../../actions/commonActions';
import EditFacilityForm from './EditFacilityForm';
import {
    clearSelectedFacilityID,
    setFacilityFormValues,
    updateFacilityFormValue,
    toggleFacilityContractModal,
    toggleFacilityContactModal,
    updateFacilityContract
} from '../../actions/manageFacilityActions';
import { getProductInfo } from '../../actions/manageInventoryActions';
import { getFSEUsers } from '../../actions/manageJobActions';
import {
    selectActiveFseUsersByID,
    selectFseUserOptions
} from '../../reducers/manageJobReducer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from './WithCommonModal';
import { closeAllModals } from '../../actions/commonActions';
import {
    selectIsFacilityContactModalOpen,
    selectIsFacilityContractModalOpen,
    selectIsLoading
} from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { initialUser } from '../../reducers/initialState';

interface Iprops {
    colorButton: any;
    modalClass?: string;
    selectedFacility?: Ifacility;
    secondModal?: boolean;
    disabled?: boolean;
    approveMode: boolean;
    searchForFacility: (facilityID: string) => void;
    showSearchFacilityModal?: boolean;
    refreshTable: () => void;
}

const defaultProps = {
    className: 'edit-faciity',
    wideModal: true
};

const mapStateToProps = (
    state: IinitialState,
    ownProps: Iprops & WithTranslation
) => {
    const { facilities, productInfo } = state;
    const fseUsersByID = selectActiveFseUsersByID(state);
    const { selectedFacility, t, disabled, showSearchFacilityModal } = ownProps;
    let title = '';
    let selectedLeadFSE = initialUser;
    let selectedFacilityContract;

    if (disabled) {
        title = t('facility:viewFacility');
    } else {
        if (selectedFacility && selectedFacility.name) {
            title = t('facility:editFacility');
            selectedFacilityContract =
                facilities[selectedFacility.id]?.contract;
            if (selectedFacility.leadFSEID) {
                selectedLeadFSE =
                    state.manageJob.fseUsersByID[selectedFacility.leadFSEID];
            }
        } else {
            title = t('facility:newFacility');
        }
    }

    return {
        ...defaultProps,
        user: state.user,
        loading: selectIsLoading(state),
        show: state.showEditFacilityModal,
        formValues: state.manageFacility.facilityFormValues,
        productInfo,
        fseUsersByID,
        fseOptions: selectFseUserOptions(state),
        selectedLeadFSE,
        title,
        isFacilityContactModalOpen: selectIsFacilityContactModalOpen(state),
        isFacilityContractModalOpen: selectIsFacilityContractModalOpen(state),
        showSearchFacilityModal,
        selectedFacilityContract
    };
};

export default withTranslation('facility')(
    connect(mapStateToProps, {
        toggleModal: toggleEditFacilityModal,
        handleSubmit: addFacility,
        handleEdit: updateFacility,
        updateFormValue: updateFacilityFormValue,
        updateFacilityContract,
        setFormValues: setFacilityFormValues,
        clearSelectedFacilityID,
        onHide: toggleEditFacilityModal,
        getProductInfo,
        deleteFacility,
        getFSEUsers,
        toggleFacilityContractModal,
        toggleFacilityContactModal,
        closeAllModals
    })(WithCommonModal(EditFacilityForm))
);
