import * as React from 'react';

import { ButtonToolbar, Dropdown, MenuItem } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { constants } from '../../../constants/constants';
import i18next from 'i18next';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Iuser } from '../../../models';
import { svgIcons } from '../../../constants/svgIcons';

interface Iprops {
    toggle: (isOpen: boolean) => void;
    handleMenuSelect: (eventKey: any) => void;
    menuClass: string;
    menuOpen: boolean;
    // hasJob: boolean;
    // offlineStatusOverride: boolean;
    // online: boolean;
    user: Iuser;
    loading: boolean;
    // startSync: typeof startSync;
    // downloadAppState: typeof downloadAppState;
}

const DropDownMenu: React.FC<Iprops & WithTranslation> = props => {
    return (
        <ButtonToolbar className="header-menu">
            <Dropdown
                onToggle={props.toggle}
                id="header-dropdown"
                onSelect={props.handleMenuSelect}
            >
                <Dropdown.Toggle
                    bsStyle="link"
                    className="header-menu-button"
                    bsSize="sm"
                >
                    <FontAwesomeIcon icon={['far', 'bars']} size="lg" />
                </Dropdown.Toggle>
                <div className="white-rectangle" data-open={props.menuOpen} />
                <Dropdown.Menu className={props.menuClass}>
                    {/* Mobile only container */}
                    <div className="menu-welcome">
                        <div className="menu-wecome-left">
                            <p className="welcome-text-menu">
                                {i18next.t('welcome')}
                            </p>

                            <p className="name-menu">{props.user.first}</p>
                        </div>
                    </div>
                    <MenuItem eventKey="0">
                        <span style={{ width: '17px' }}>
                            {svgIcons['dashboard']}
                        </span>{' '}
                        &nbsp; {i18next.t('tiles:dashboard')}
                    </MenuItem>
                    <MenuItem eventKey="1">
                        <span style={{ width: '17px' }}>
                            {svgIcons['inventory']}
                        </span>{' '}
                        &nbsp; {i18next.t('tiles:inventory')}
                    </MenuItem>
                    <MenuItem eventKey="2">
                        <span style={{ width: '17px' }}>
                            {svgIcons['training']}
                        </span>{' '}
                        &nbsp; {i18next.t('tiles:training')}
                    </MenuItem>
                    <MenuItem eventKey="3">
                        <span style={{ width: '17px' }}>
                            {svgIcons['documents']}
                        </span>{' '}
                        &nbsp; {i18next.t('tiles:Documents')}
                    </MenuItem>
                    <MenuItem eventKey="4">
                        <span style={{ width: '17px' }}>
                            {svgIcons['quotes']}
                        </span>{' '}
                        &nbsp; {i18next.t('tiles:quotes')}
                    </MenuItem>
                    <MenuItem eventKey="5">
                        <FontAwesomeIcon
                            icon={['far', 'sign-out']}
                            fixedWidth
                        />{' '}
                        &nbsp; {i18next.t('common:logout')}
                    </MenuItem>
                    <small
                        style={{
                            color: constants.colors.greyText,
                            padding: '5px 5px 5px 22px'
                        }}
                    >
                        v: {process.env.REACT_APP_VERSION}
                    </small>
                </Dropdown.Menu>
            </Dropdown>
        </ButtonToolbar>
    );
};

export default withTranslation('common')(DropDownMenu);
