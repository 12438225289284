import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FieldConfig } from 'react-reactive-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ReactTable, { Column, FinalState, RowInfo } from 'react-table';
import {
    closeAllModals,
    FacilitiesSearchParams,
    getFacilitiesByCountry,
    searchFacilityQueueForAsyncSelect,
    toggleEditFacilityModal
} from '../../actions/commonActions';
import {
    mergeFacilitiesAPI,
    setSelectedFacilityID,
    setTableFilter
} from '../../actions/manageFacilityActions';
import { getProductInfo } from '../../actions/manageInventoryActions';
import { constants } from '../../constants/constants';
import {
    Ifacility,
    Ioption,
    ItableFiltersReducer,
    Itile,
    Iuser
} from '../../models';
import { IinitialState } from '../../reducers';
import { selectIsLoading } from '../../reducers/commonReducers';
import { initialFacility, emptyTile } from '../../reducers/initialState';
import { selectManageFacilityTableFilters } from '../../reducers/manageCustomerAndFacilityReducer';
import { Banner } from '../common/Banner';
import EditFacilityModal from '../common/EditFacilityModal';
import FacilityContactModal from '../common/FacilityContactModal';
import FacilityContractModal from '../common/FacilityContractModal';
import { FormUtil } from '../common/FormUtil';
import { toastr } from 'react-redux-toastr';
import { debounce } from 'lodash';
import SearchFacilityModal from '../manageFacility/searchFacilityModal';
import { beginAjaxCall, endAjaxCall } from '../../actions/ajaxStatusActions';
import * as types from '../../actions/actionTypes';
import SearchTableForm from '../common/SearchTableForm';
import { getCountries } from '../../reducers/countriesReducer';
import { getUsersCountries } from '../../reducers/userReducer';

interface RowInfoFacility extends RowInfo {
    original: Ifacility;
}

type Iprops = RouteComponentProps<any>;

interface IdispatchProps {
    totalPages: number;
    setTableFilter: typeof setTableFilter;
    tableFilters: ItableFiltersReducer;
    loading: boolean;
    setSelectedFacilityID: typeof setSelectedFacilityID;
    toggleEditFacilityModal: typeof toggleEditFacilityModal;
    getProductInfo: typeof getProductInfo;
    c: number;
    user: Iuser;
    closeAllModals: typeof closeAllModals;
    searchFacilityQueueForAsyncSelect: typeof searchFacilityQueueForAsyncSelect;
    beginAjaxCall: typeof beginAjaxCall;
    endAjaxCall: typeof endAjaxCall;
}

interface Istate {
    selectedRow: any;
    columns: any[];
    searchFieldConfig: FieldConfig;
    tableData: Ifacility[];
    currentTile: Itile;
    filter: FacilitiesSearchParams;
    selectedFacility: Ifacility;
    showSearchFacilityModal: boolean;
}

const ManangeFacilityQueue: React.FC<Iprops &
    IdispatchProps &
    WithTranslation &
    Istate> = props => {
    const { t } = props;
    const dispatch = useDispatch();
    const countries = useSelector(getCountries);
    const usersCountries = useSelector(getUsersCountries);

    const buildSearchControls = (): FieldConfig => {
        const countriesOptions: Ioption[] = countries
            .filter(c => usersCountries?.includes(c.id))
            .map(c => ({ label: c.name, value: c.id }));

        const mainSearchControls = {
            country: {
                render: FormUtil.Select,
                meta: {
                    options: countriesOptions,
                    label: 'manageFacility:Country',
                    colWidth: 4,
                    colWidthMedium: 4,
                    colWidthLarge: 4,
                    placeholder: 'common:searchPlaceholder',
                    name: 'country',
                    defaultValue: '',
                    isClearable: true
                }
            },
            facilityName: {
                render: FormUtil.TextInputWithoutValidation,
                meta: {
                    label: 'manageFacility:facility',
                    colWidth: 4,
                    type: 'text',
                    placeholder: 'manageFacility:facilitySearchPlaceholder',
                    defaultValue: props.tableFilters.facilityName,
                    isClearable: true
                },
                formState: {
                    value: props.tableFilters.facilityName,
                    disabled: false
                }
            },
            hasSAPNumber: {
                render: FormUtil.Checkbox,
                meta: {
                    label: 'hasSAPNumber',
                    colWidth: 4
                },
                formState: {
                    value: false,
                    disabled: false
                }
            }
        };
        return FormUtil.translateForm(
            {
                controls: { ...mainSearchControls }
            },
            props.t
        );
    };

    const columns: any[] = [
        {
            Header: 'name',
            id: 'name',
            accessor: 'name',
            minWidth: 200
        },
        {
            Header: 'sap Facility Number',
            id: 'sapFacilityNumber',
            accessor: 'sapFacilityNumber',
            minWidth: 150
        },
        {
            Header: 'address',
            id: 'address',
            accessor: ({
                address,
                address2,
                city,
                state: facilityState,
                postalCode
            }: Ifacility) => {
                return (
                    `${address ? address : ''}` +
                    ` ${address2 ? address2 : ''},` +
                    ` ${city ? city : ''},` +
                    ` ${facilityState ? facilityState : ''},` +
                    ` ${postalCode ? postalCode : ''}`
                );
            },
            minWidth: 300
        }
    ];

    const [hasSAPNumChecked, setHasSAPNumChecked] = React.useState<boolean>(
        false
    );
    const [
        allFacillitesWithSearchTerm,
        setAllFacilitiesWithSearchTerm
    ] = React.useState<Ifacility[]>([]);

    const [selectedCountry, setSelectedCountry] = React.useState<
        string | undefined
    >(undefined);
    const [facilityNameSearch, setFacilityNameSearch] = React.useState<
        string | undefined
    >(undefined);

    const [state, setState] = React.useState<Istate>({
        selectedRow: {},
        columns,
        tableData: [],
        searchFieldConfig: buildSearchControls(),
        currentTile: emptyTile,
        filter: {
            name: '',
            address: '',
            postalCode: ''
        },
        selectedFacility: initialFacility,
        showSearchFacilityModal: false
    });

    React.useEffect(() => {
        const tableData = filterSAPNumber(
            allFacillitesWithSearchTerm,
            hasSAPNumChecked
        );
        setState(prevState => ({
            ...prevState,
            tableData
        }));
    }, [hasSAPNumChecked, allFacillitesWithSearchTerm]);

    const colorButton =
        constants.colors[
            `${state.currentTile.color}Button` as keyof typeof constants.colors
        ];

    const getTdProps = (
        finalState: FinalState,
        rowInfo: RowInfoFacility | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo) {
            return {
                onClick: () => {
                    props.setSelectedFacilityID(rowInfo.original.id);
                    setState({ ...state, selectedFacility: rowInfo.original });
                    props.toggleEditFacilityModal();
                }
            };
        } else {
            return {};
        }
    };

    const onPageChange = (page: number) => {
        props.setTableFilter({ page });
        setState({ ...state, selectedRow: {} });
    };

    const onPageSizeChange = (rows: number) => {
        props.setTableFilter({ rows, page: 0 });
        setState({ ...state, selectedRow: {} });
    };

    const hideMergeFacilitiesModal = () => {
        setState({ ...state, showSearchFacilityModal: false });
    };

    const mergeFacilities = async (primary: string, secondary: string[]) => {
        const response = await mergeFacilitiesAPI(primary, secondary);
        if (response) {
            // Remove the now merged facilities from redux if they're there
            dispatch({
                type: types.MERGE_FACILITIES_SUCCESS,
                primaryId: primary,
                secondaryIds: secondary
            });

            // Remove the now merged facilities from state
            const updatedTableData = allFacillitesWithSearchTerm.filter(
                tempFacility => {
                    if (secondary.includes(tempFacility.id)) {
                        return false;
                    }

                    return true;
                }
            );

            setAllFacilitiesWithSearchTerm(updatedTableData);
            setState(prevState => ({
                ...prevState,
                showSearchFacilityModal: false
            }));

            props.toggleEditFacilityModal();

            toastr.success(
                t('Success'),
                t('Facilities successfully merged'),
                constants.toastrSuccess
            );
        } else {
            toastr.warning(
                t('Error'),
                t('Failed to merge Facilities'),
                constants.toastrError
            );
        }
    };

    const searchForFacility = (facilityID: string) => {
        setState({ ...state, showSearchFacilityModal: true });
    };

    const saveFacilities = (facilities: Ifacility[]) => {
        const updatedTableData = facilities.filter(tempFacility => {
            if (tempFacility.isApproved) {
                return false;
            }

            return true;
        });
        setAllFacilitiesWithSearchTerm(updatedTableData);
        props.endAjaxCall();
    };

    const filterSAPNumber = (
        allFacilities: Ifacility[],
        hasSAPNumber: boolean
    ) =>
        hasSAPNumber
            ? allFacilities.filter(
                  f =>
                      f.sapFacilityNumber !== '' &&
                      f.sapFacilityNumber !== undefined &&
                      f.sapFacilityNumber !== null
              )
            : allFacilities;

    const debouncedSearch = React.useRef(
        debounce(async (searchTerms, countryId) => {
            props.beginAjaxCall();

            props.searchFacilityQueueForAsyncSelect(
                searchTerms,
                countryId,
                () => {
                    return;
                },
                saveFacilities
            );
        }, 200)
    ).current;

    const handleNameChange = (searchTerms: string) => {
        if (searchTerms.length >= 3) {
            setFacilityNameSearch(searchTerms);
            debouncedSearch(searchTerms, selectedCountry);
            return;
        }

        if (searchTerms.length === 0) {
            setFacilityNameSearch(undefined);
            debouncedSearch(undefined, selectedCountry);
            return;
        }
    };

    const handleSAPNumChange = (value: boolean) => {
        setHasSAPNumChecked(value);
    };

    const handleCountryChange = (countryId: string | undefined) => {
        setSelectedCountry(countryId);
        debouncedSearch(facilityNameSearch, countryId);
    };

    const refreshData = () => {
        debouncedSearch(undefined, undefined);
    };

    const onValueChanges = (value: any, key: string) => {
        switch (key) {
            case 'facilityName':
                handleNameChange(value);
                break;
            case 'hasSAPNumber':
                handleSAPNumChange(value);
            case 'country':
                if (value === null) {
                    handleCountryChange(undefined); // if null, that means the user cleared the value
                } else {
                    handleCountryChange(value.value); // value will be an Ioption
                }
            default:
                break;
        }
    };

    React.useEffect(() => {
        props.beginAjaxCall();
        props.searchFacilityQueueForAsyncSelect(
            undefined,
            undefined,
            () => {
                return;
            },
            saveFacilities
        );
    }, []);

    return (
        <>
            <Banner
                title={t('manageFacility:facilityQueueTitle')}
                img={state.currentTile.srcBanner}
                color={state.currentTile.color}
                subtitle={props.selectedFacility?.name}
            />
            <SearchTableForm
                fieldConfig={state.searchFieldConfig}
                loading={props.loading}
                showSearchButton={false}
                colorButton={colorButton}
                t={props.t}
                onValueChanges={onValueChanges}
                subscribeValueChanges={true}
                showAdditionalFiltersButton={false}
                showAdditionalFilters={false}
                hasActiveAdditionalFilters={false}
                handleSubmit={() => {}}
            ></SearchTableForm>
            <ReactTable
                data={state.tableData}
                columns={state.columns}
                getTdProps={getTdProps}
                defaultPageSize={
                    props.tableFilters.rows || constants.tablePageSizeDefault
                }
                onPageSizeChange={onPageSizeChange}
                showPageSizeOptions={true}
                pageSizeOptions={constants.tablePageSizeOptions}
                className={`beacon-table -highlight ${state.currentTile.color}`}
                previousText={t('common:previous')}
                nextText={t('common:next')}
                onPageChange={onPageChange}
                sortable={false}
                multiSort={false}
                noDataText={t('common:noDataSearch')}
                resizable={false}
                loading={state.tableData.length === 0 && props.loading}
            />

            <EditFacilityModal
                colorButton={colorButton}
                selectedFacility={state.selectedFacility}
                disabled={false}
                approveMode={true}
                searchForFacility={searchForFacility}
                showSearchFacilityModal={state.showSearchFacilityModal}
                refreshTable={refreshData}
            />

            <FacilityContractModal
                colorButton={colorButton}
                secondWideModal={true}
            />
            <FacilityContactModal
                colorButton={colorButton}
                secondWideModal={true}
                facilityID={state.selectedFacility.id}
            />

            <SearchFacilityModal
                showModal={state.showSearchFacilityModal}
                hideModal={hideMergeFacilitiesModal}
                secondWideModal={true}
                selectedFacility={state.selectedFacility}
                colorButton={colorButton}
                mergeFacilities={mergeFacilities}
                countryId={selectedCountry}
            />
        </>
    );
};

const mapStateToProps = (state: IinitialState) => {
    return {
        tableFilters: selectManageFacilityTableFilters(state),
        loading: selectIsLoading(state),
        user: state.user
    };
};

export default withTranslation('manageFacility')(
    connect(mapStateToProps, {
        getFacilitiesByCountry,
        setTableFilter,
        setSelectedFacilityID,
        toggleEditFacilityModal,
        searchFacilityQueueForAsyncSelect,
        beginAjaxCall,
        endAjaxCall
    })(ManangeFacilityQueue)
);
