import initialState, { initialSuggestedPart } from './initialState';
import * as types from '../actions/actionTypes';
import { ImanageSuggestedPartsReducer, IsuggestedPart } from '../models';
import {
    createTableFiltersWithName,
    modalToggleWithName
} from './commonReducers';
import { pickBy, map, keyBy } from 'lodash';
import { combineReducers } from 'redux';

const cleanSuggestedPart = (suggestedPart: any): IsuggestedPart => {
    return {
        ...initialSuggestedPart,
        ...pickBy(suggestedPart, property => property !== null)
    };
};

export function suggestedParts(
    state: { [key: string]: IsuggestedPart } = initialState.manageSuggestedParts
        .suggestedParts,
    action: any
): { [key: string]: IsuggestedPart } {
    switch (action.type) {
        case types.SEARCH_SUGGESTED_PARTS_SUCCESS: {
            if (Array.isArray(action?.payload?.result)) {
                const newParts = map(action.payload.result, cleanSuggestedPart);
                return keyBy(newParts, 'id');
            }
        }
        case types.SAVE_SUGGESTED_PARTS_SUCCESS:
            return {
                ...state,
                [action.part.id]: cleanSuggestedPart(action.part)
            };
        default:
            return state;
    }
}

const selectedSuggestedPartReducer = (
    state = initialSuggestedPart,
    action: any
): IsuggestedPart => {
    switch (action.type) {
        case types.UPDATE_SELECTED_SUGGESTED_PART: {
            return action.payload;
        }
        default:
            return state;
    }
};

function totalPages(state = 1, action: any): number {
    switch (action.type) {
        case types.SUGGESTED_PARTS_MANAGE_TOTAL_PAGES:
            if (action.pages && action.pages > 0) {
                return action.pages;
            }
            return state;
        case types.USER_LOGOUT_SUCCESS:
            return 1;
        default:
            return state;
    }
}

const manageSuggestedParts = combineReducers<ImanageSuggestedPartsReducer>({
    suggestedParts,
    totalPages,
    selectedSuggestedPart: selectedSuggestedPartReducer,
    showEditSuggestedPartModal: (state, action) =>
        modalToggleWithName(state, action, 'EDIT_SUGGESTED_PART'),
    tableFilters: (state, action) =>
        createTableFiltersWithName(state, action, 'MANAGE_SUGGESTED_PARTS')
});

export default manageSuggestedParts;
