import { IinitialState } from '.';
import * as types from '../actions/actionTypes';
import { Icountry, IcountryBE } from '../models';

export const getCountries = (state: IinitialState): IcountryBE[] =>
    state.countries;

export default function countriesReducer(
    state: IcountryBE[] = [],
    action: any
) {
    switch (action.type) {
        case types.SET_COUNTRIES:
            return action.payload;
        default:
            return state;
    }
}
