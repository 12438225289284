import * as React from 'react';
import * as types from '../../actions/actionTypes';
import {
    AbstractControl,
    FieldConfig,
    FormControl,
    FormGenerator
} from 'react-reactive-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FormGroup } from 'react-bootstrap';
import { debounce, forEach } from 'lodash';

import {
    Ifacility,
    IfacilityWithoutBuildings,
    Ioption,
    Iuser
} from '../../models';
import {
    FacilitiesSearchParams,
    searchFacilitiesForAsyncSelect,
    setSelectedFacilityID
} from '../../actions/commonActions';
import { FormUtil } from './FormUtil';
import { constants } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
    getUsersActiveCountry,
    getUsersCountries
} from '../../reducers/userReducer';
import { loadCountries } from '../../actions/countriesActions';
import { getCountries } from '../../reducers/countriesReducer';
import { getUser } from '../../reducers/manageUserReducer';
import { initialFacility } from '../../reducers/initialState';

interface Iprops {
    loading: boolean;
    user: Iuser;
    selectedFacility: IfacilityWithoutBuildings;
    selectedFacilityID: string;
    setSelectedFacilityID: typeof setSelectedFacilityID;
    getFacilitiesByCountry: (params: FacilitiesSearchParams) => any;
    classNameOverride: string;
    searchFacilitiesForAsyncSelect: typeof searchFacilitiesForAsyncSelect;
    facilities: Ifacility[];
    activeLocalCountry: string;
    isRenderedInModal?: boolean;
}

interface Istate {
    fieldConfig: FieldConfig;
}

const SelectFacilityForm: React.FC<Iprops & WithTranslation> = props => {
    const usersCountries = useSelector(getUsersCountries);
    const usersActiveCountry = useSelector(getUsersActiveCountry);
    const user = useSelector(getUser);
    const countries = useSelector(getCountries);
    const dispatch = useDispatch();

    const {
        isRenderedInModal,
        activeLocalCountry: usersLocalActiveCountry
    } = props;

    const saveFacilities = (facilities: Ifacility[]) => {
        dispatch({
            type: types.GET_FACILITIES_SUCCESS,
            facilities
        });
    };

    React.useEffect(() => {
        loadCountries(dispatch, countries);
    }, []);

    const loadOptions = debounce((searchTerm, callback) => {
        if (searchTerm.length >= 3) {
            props.searchFacilitiesForAsyncSelect(
                searchTerm,
                usersLocalActiveCountry || usersActiveCountry, // use local country if it exists, otherwise use the user's active country,
                null,
                callback,
                saveFacilities
            );
            return;
        }

        callback();
    }, 200);
    const [state, setState] = React.useState<Istate>();

    const generateFacilityOption = (
        facility: IfacilityWithoutBuildings
    ): Ioption => {
        let nameWithAddress = `${facility.name} | ${facility.address}${
            facility.address2 ? ' ' + facility.address2 : ''
        } ${facility.city} ${facility.state} ${facility.postalCode}`;

        return {
            value: facility.id,
            label: nameWithAddress
        };
    };

    React.useEffect(() => {
        let facilityClass = 'banner-input';
        if (props.classNameOverride !== '') {
            facilityClass = props.classNameOverride;
        }

        // Default to the user's active country, but if they have a local country set, use that instead
        const countryToUse =
            usersLocalActiveCountry !== ''
                ? usersLocalActiveCountry
                : usersActiveCountry;

        let facililty = props.selectedFacility;

        if (facililty.countryID !== countryToUse || facililty.id === '') {
            const userFacililty = user.facilities.find(
                f => f.countryID === countryToUse
            );

            // If the user somehow has a country, but no facilities from that country, then they must have the AllowAllCountries SF
            if (userFacililty !== undefined) {
                facililty = userFacililty;
            } else {
                facililty = initialFacility;
            }
        }

        const facilityOption = generateFacilityOption(facililty);
        // Set the selected facility ID in the store
        props.setSelectedFacilityID(facilityOption.value);

        const activeCountryOption: Ioption = {
            value: countryToUse,
            label: countries.find(c => c.id === countryToUse)?.name || ''
        };
        const countriesOptions: Ioption[] = countries
            .filter(c => usersCountries?.includes(c.id))
            .map(c => ({ label: c.name, value: c.id }));
        const country = {
            options: {
                validators: FormUtil.validators.requiredWithTrim
            },
            render: FormUtil.Select,
            meta: {
                options: countriesOptions,
                label: 'manageInventory:activeCountry',
                colWidth: !isRenderedInModal ? 2 : 12,
                colWidthMedium: !isRenderedInModal ? 2 : 12,
                colWidthLarge: !isRenderedInModal ? 2 : 12,
                placeholder: 'manageInventory:searchPlaceholder',
                name: 'country',
                defaultValue: activeCountryOption,
                className: 'banner-input'
            },

            formState: { value: activeCountryOption, disabled: false }
        };

        const facility = {
            render: FormUtil.AsyncSelect,
            options: {
                validators: FormUtil.validators.requiredWithTrim
            },
            meta: {
                label: 'manageInventory:facility',
                loadOptions,
                colWidth: !isRenderedInModal ? 4 : 12,
                colWidthMedium: !isRenderedInModal ? 4 : 12,
                colWidthLarge: !isRenderedInModal ? 4 : 12,
                required: true,
                type: 'select',
                placeholder: 'manageInventory:facilityRequired',
                className: facilityClass
            },
            formState: {
                value: facilityOption,
                disabled: false
            }
        };

        const fieldConfig = {
            controls: { country, facility }
        };

        setState({ fieldConfig: FormUtil.translateForm(fieldConfig, props.t) });
    }, [countries, usersLocalActiveCountry]);

    let formGroup: FormGroup | any;
    let subscription: any[];
    let setFacilitySearchTimeout: any;

    const handleSetFacility = (value: any) => {
        // causes performance issues so we use a rudamentary debounce
        if (value && value.value) {
            clearTimeout(setFacilitySearchTimeout);

            setFacilitySearchTimeout = setTimeout(() => {
                dispatch({
                    type: types.GLOBAL_SET_SELECTED_FACILITY_ID,
                    id: value.value
                });
            }, constants.tableSearchDebounceTime);
        }
    };

    const setFacilityFormToDefault = (
        facilityForm: FormControl,
        countryID: string
    ) => {
        const facililty = user.facilities.find(f => f.countryID === countryID);

        if (facililty) {
            facilityForm.setValue(generateFacilityOption(facililty));
        } else {
            facilityForm.setValue('');
            facilityForm.setErrors({ required: true });
            facilityForm.markAsSubmitted();
        }
    };

    const getFacilityForm = (formGroup: any): FormControl => {
        return formGroup.get('facility');
    };

    const handleCountryChange = (value: Ioption) => {
        const countryID = value.value;

        dispatch({ type: types.UPDATE_USER_LOCAL_COUNTRY, payload: countryID });
    };

    const subscribeToValueChanges = () => {
        if (!subscription) {
            subscription = [];
        }
        subscription.push(
            formGroup.get('facility').valueChanges.subscribe((value: any) => {
                handleSetFacility(value);
            })
        );
        subscription.push(
            formGroup.get('country').valueChanges.subscribe((value: any) => {
                handleCountryChange(value);
            })
        );
    };

    const unsubscribe = () => {
        if (subscription && subscription.length) {
            subscription.forEach(s => s?.unsubscribe());
        }
    };

    const handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const setForm = (form: AbstractControl) => {
        formGroup = form;
        formGroup.meta = {
            loading: props.loading
        };
        const facForm = getFacilityForm(formGroup);

        if (facForm.value.value === '') {
            const countryToUse =
                usersLocalActiveCountry !== ''
                    ? usersLocalActiveCountry
                    : usersActiveCountry;

            setFacilityFormToDefault(facForm, countryToUse);
        }
        subscribeToValueChanges();
    };
    const formClassName = `beacon-form select-facility-form`;
    return (
        <div>
            <form onSubmit={handleSubmit} className={formClassName}>
                {state?.fieldConfig && (
                    <FormGenerator
                        onMount={setForm}
                        fieldConfig={state.fieldConfig}
                        onUnmount={unsubscribe}
                    />
                )}
            </form>
        </div>
    );
};
export default withTranslation('manageInventory')(SelectFacilityForm);
