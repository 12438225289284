import * as types from '../actions/actionTypes';

import { IinstallBase, Iproduct } from '../models';
import initialState, { initialProduct } from './initialState';
import { keyBy, map, pickBy } from 'lodash';

/*
 * the product object is returned with all the installBases nested inside of it
 * the filtered installBases are beside it.  We repalce the installBases nested inside becuase those are the install bases we care about
 *  at some point the API can be updated to not send all the installBases since this is not used.
 * for now we are replacing state.  eventually we can change this to update state with the new products.  we just need to filter by facilityID in the views
 */
export const cleanAndFilterProductObject = (product: Iproduct): Iproduct => {
    return {
        ...initialProduct,
        ...(pickBy(product, (property, key) => property !== null) as Iproduct),
        installBasesCount: product.installBases
            ? product.installBases.length
            : 0,
        installBases: undefined
    };
};

export const productsReducer = (
    state: { [key: string]: Iproduct } = initialState.manageInventory
        .productsByID,
    action: any
): { [key: string]: Iproduct } => {
    switch (action.type) {
        case types.GET_INVENTORY_SUCCESS: {
            const newProducts = keyBy(
                map(
                    action.inventory,
                    ({
                        product,
                        installBases
                    }: {
                        product: Iproduct;
                        installBases: IinstallBase[];
                    }) => {
                        return cleanAndFilterProductObject(product);
                    }
                ),
                'id'
            );
            return { ...state, ...newProducts };
            //return newProducts;
        }
        case types.PRODUCT_UPDATE_SUCCESS:
            return {
                ...state,
                [action.product.id]: cleanAndFilterProductObject(action.product)
            };

        case types.PRODUCT_ADD_SUCCESS:
            // TODO not showing added product until we build the queue
            // return map(state, pr => {
            //   if (pr.id === action.product.id){
            //     return pickBy(action.product, (property, key) => property !== null) as Iproduct
            //   } else {
            //     return pr;
            //   }
            // })

            return state;
        case types.DELETE_PHOTO_SUCCESS: {
            if (action.productID && state[action.productID]) {
                const productWithoutPhoto: Iproduct = {
                    ...state[action.productID],
                    imagePath: ''
                };
                return {
                    ...state,
                    [productWithoutPhoto.id]: productWithoutPhoto
                };
            }
        }
        case types.UPLOAD_PRODUCT_PHOTO_SUCCESS: {
            if (action.productID && state[action.productID]) {
                const productWithPhoto: Iproduct = {
                    ...state[action.productID],
                    imagePath: action.photo.lightboxUrl
                };
                return { ...state, [productWithPhoto.id]: productWithPhoto };
            }
        }
        case types.INSTALL_ADD_SUCCESS: {
            // if this is ther first install for this product, we are adding the product as well
            const newInstallsCount = action.installs.length;
            const product = action.product;
            const newCount = product.installBasesCount
                ? product.installBasesCount + newInstallsCount
                : newInstallsCount;
            return {
                ...state,
                [product.id]: { ...product, installBasesCount: newCount }
            };
        }

        // we could handle get_products_success here if we were keeping all the products and doing front end filtering of the manage inventory table
        // case types.GET_PRODUCTS_SUCCESS:
        //   if (action.products) {
        //     const newProducts = map(action.products, (prod: Iproduct) => {
        //       return cleanAndFilterProductObject({
        //         ...prod,
        //         installBases: state[prod.id] ? state[prod.id].installBases : []
        //       });
        //     });
        //     return { ...state, ...keyBy(newProducts, 'id') };
        //   } else {
        //     return state;
        //   }
        case types.USER_LOGOUT_SUCCESS:
            return initialState.manageInventory.productsByID;
        default:
            return state;
    }
};

/*
 * ensure the product at least has the expected attributes, ignore any null attributes
 */
