/*
 * Manage Inventory Container
 */

import {
    clearSelectedInstallBaseID,
    clearSelectedProductID,
    getProductInfo,
    initInventory,
    requestQuote,
    setSearchNewProductsSelectedProduct,
    setSelectedInstallBaseID,
    setSelectedProductID,
    setTableFilter,
    toggleEditInstallModal,
    toggleEditProductModal,
    toggleImportInstallModal,
    toggleInstallContactModal,
    toggleSearchNewProductsModal,
    mergeProduct
} from '../../actions/manageInventoryActions';
import {
    getCanEditInstalls,
    getCanRequestQuote
} from '../../reducers/userReducer';
import {
    initialFacility,
    initialInstallBase,
    initialProduct
} from '../../reducers/initialState';

import ManageInventory from './ManageInventory';
import { closeAllModals } from '../../actions/commonActions';
import { connect } from 'react-redux';
import { getFacility } from '../../actions/manageLocationActions';
import { getTotal } from '../../reducers/cartReducer';
import { toggleShoppingCartModal } from '../../actions/shoppingCartActions';
import { withTranslation, WithTranslation } from 'react-i18next';
import { values } from 'lodash';
import { selectVisibleProductsWithInstallBases } from '../../reducers/manageInventoryReducer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';

const mapStateToProps = (state: IinitialState, ownProps: WithTranslation) => {
    const selectedFacility =
        state.facilities[state.selectedFacilityID] || initialFacility;
    const { measurementPointResultsByID } = state.measurementPointResults;
    const {
        selectedInstallBaseID,
        productsByID,
        selectedProductID,
        searchNewProductsSelectedProduct,
        installBasesByID
    } = state.manageInventory;
    const selectedProduct = productsByID[selectedProductID] || initialProduct;
    const selectedInstallBase =
        installBasesByID[selectedInstallBaseID] || initialInstallBase;
    const canEditInstalls = getCanEditInstalls(state.user);
    const canRequestQuote = getCanRequestQuote(state.user);

    const visibleProducts = selectVisibleProductsWithInstallBases(
        state.manageInventory,
        measurementPointResultsByID,
        selectedFacility,
        state.manageWorkOrder,
        state.productInfo
    );
    return {
        user: state.user,
        loading: selectIsLoading(state),
        productInfo: state.productInfo,
        cartTotal: getTotal(state.manageInventory.cart),
        tableData: values(visibleProducts),
        selectedProduct,
        searchNewProductsSelectedProduct,
        selectedInstallBase,
        selectedFacility,
        canEditInstalls,
        canRequestQuote,
        bannerTitle: 'bannerTitle',
        // columns: columns(ownProps.t),
        manageInventory: state.manageInventory
    };
};

export const ManageInventoryContainer = withTranslation('manageInventory')(
    connect(mapStateToProps, {
        initInventory,
        toggleEditProductModal,
        toggleEditInstallModal,
        toggleShoppingCartModal,
        toggleSearchNewProductsModal,
        closeAllModals,
        getProductInfo,
        getFacility,
        setTableFilter,
        toggleInstallContactModal,
        toggleImportInstallModal,
        requestQuote,
        setSelectedProductID,
        clearSelectedProductID,
        setSelectedInstallBaseID,
        clearSelectedInstallBaseID,
        setSearchNewProductsSelectedProduct,
        mergeProduct
    })(ManageInventory)
);
