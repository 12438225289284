/*
 * Installs Action Button
 */

import { IinstallBase } from '../../models';
import {
    clearSelectedInstallBaseID,
    setSelectedInstallBaseID,
    setSelectedProductID,
    toggleEditInstallModal,
    toggleEditProductModal,
    toggleInstallContactModal,
    toggleMPResultHistory,
    toggleMPResultModal
} from '../../actions/manageInventoryActions';
import {
    getFacilityMeasurementPointResults,
    getMeasurementPointResultsHistoryByInstallBase
} from '../../actions/measurementPointResultsActions';

import { InstallBaseActionButton } from './InstallBaseActionButton';
import { RowInfo } from 'react-table';
import { TFunction } from 'i18next';
import { addToCart } from '../../actions/shoppingCartActions';
import { connect } from 'react-redux';
import { initialFacility } from '../../reducers/initialState';
import {
    toggleEditWorkOrderModal,
    setSelectedWorkOrderID,
    getWorkOrdersByFacility,
    toggleWorkOrderCloseModal
} from '../../actions/manageWorkOrderActions';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { getJobsByFacility } from '../../actions/manageJobActions';
import { getCanCreateAsManager } from '../../reducers/userReducer';
import { clearTableFilters } from '../../actions/manageDocumentActions';

interface RowInfoInstallBase extends RowInfo {
    original: IinstallBase;
}

interface Iprops {
    t: TFunction;
    rowInfo: RowInfoInstallBase;
    colorButton: string;
    forMobile: boolean;
}

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const { selectedFacilityID } = state;
    const selectedInstallBase = ownProps.rowInfo.original;

    const facility = state.facilities[selectedFacilityID] || initialFacility;
    const showMoveFacility = getCanCreateAsManager(state.user);

    return {
        facility,
        loading: selectIsLoading(state),
        selectedInstallBase,
        showMoveFacility
    };
};

export const InstallBaseActionButtonContainer = connect(mapStateToProps, {
    addToCart,
    toggleEditWorkOrderModal,
    toggleMPResultModal,
    toggleMPResultHistory,
    toggleEditProductModal,
    setSelectedInstallBaseID,
    clearSelectedInstallBaseID,
    toggleEditInstallModal,
    setSelectedProductID,
    toggleInstallContactModal,
    getFacilityMeasurementPointResults,
    getMeasurementPointResultsHistoryByInstallBase,
    toggleWorkOrderCloseModal,
    setSelectedWorkOrderID,
    getWorkOrdersByFacility,
    getJobsByFacility,
    clearTableFilters
})(InstallBaseActionButton);
