import * as React from 'react';

import {
    AbstractControl,
    FieldConfig,
    Observable,
    ValidationErrors,
    FormControl as rFormControl
} from 'react-reactive-form';
import {
    Button,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Row,
    ToggleButton,
    ToggleButtonGroup
} from 'react-bootstrap';
import FileIcon, { defaultStyles } from 'react-file-icon';
import Select, { components, createFilter } from 'react-select';
import { filter, forEach, map, mapValues, orderBy } from 'lodash';

import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import CreatableMultiTextInput from './CreatableMultiTextInput';
import CreatableSelect from 'react-select/creatable';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Ioption } from '../../models';
import RichTextEditor from './RichTextEditor';
import { TFunction } from 'i18next';
import Toggle from 'react-toggle';
import { constants } from '../../constants/constants';
import moment from 'moment';
import SignatureControl from './SignatureControl';

interface IstateChanges extends Observable<any> {
    next: () => void;
}

interface AbstractControlEdited extends AbstractControl {
    stateChanges: IstateChanges;
}

// add the bootstrap form-control class to the react-select select component
export const ControlComponent = (props: any) => (
    <div>
        <components.Control {...props} className="form-control" />
    </div>
);

export const FormUtil = {
    // REFACTOR consider sperating out concerns and remove patchingMeta from "patchControl" and rename it to "patchControlValue"
    patchControl: (
        formGroup: AbstractControl,
        key: string,
        value: any,
        meta?: any,
        disabled = false,
        patchMetaAndValue = false
    ) => {
        // const control = formGroup.get(key) as AbstractControlEdited; // can't figure out why this does not define next()
        const control = formGroup.get(key) as any;
        if (!control) {
            console.info('missing control', key);
            return;
        }
        if (meta === undefined || (meta && patchMetaAndValue)) {
            formGroup.patchValue({ [key]: value });
        }
        if (meta) {
            FormUtil.patchControlMeta(formGroup, key, meta);
        }
    },
    patchControlMeta: (formGroup: AbstractControl, key: string, meta: any) => {
        // const control = formGroup.get(key) as AbstractControlEdited; // can't figure out why this does not define next()
        const control = formGroup.get(key) as any;
        if (!control) {
            console.info('missing control', key);
            return;
        }
        control.meta = { ...control.meta, ...meta };
        control.statusChanges.next();
        // TODO jfbloom22 1/19/2020 calling "next" does not trigger a re-render, it will re-render when the user "focuses" the input, which is why this works when updating select options
        // temporary fix:  patch in order to force a re-render
        formGroup.patchValue({ [key]: control.value });
    },
    patchControlValidators: (
        formGroup: AbstractControl,
        key: string,
        validators: Function[]
    ): void => {
        const control = formGroup.get(key) as any;
        if (!control) {
            console.info('missing control', key);
            return;
        }
        if (validators) {
            control.setValidators(validators);
            control.statusChanges.next();
        }
    },
    validators: {
        requiredWithTrim: (meta: any) => {
            if (
                meta &&
                meta.value &&
                typeof meta.value === 'string' &&
                meta.value.trim().length > 0
            ) {
                return null;
            } else if (meta && meta.value && typeof meta.value === 'number') {
                // number
                return null;
            } else if (
                meta &&
                meta.value &&
                typeof meta.value !== 'string' &&
                meta.value.value &&
                typeof meta.value.value === 'string' &&
                meta.value.value.trim().length > 0
            ) {
                // option with string value
                return null;
            } else if (
                meta &&
                meta.value &&
                typeof meta.value !== 'string' &&
                typeof meta.value.value === 'number'
            ) {
                // option with number value
                return null;
            } else if (meta && meta.value && moment.isMoment(meta.value)) {
                // moment date
                return null;
            } else if (Array.isArray(meta.value) && meta.value.length > 0) {
                // multi select
                return null;
            } else {
                return { empty: { message: 'not long enough' } };
            }
        },
        isValidEmail: (meta: any) => {
            if (meta?.value) {
                if (
                    meta.value.match(
                        /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
                    )
                ) {
                    return null;
                } else {
                    return {
                        isValidEmail: {
                            message: 'not a valid email'
                        }
                    };
                }
            } else {
                return null;
            }
        },
        isValidPostalCode: (meta: any) => {
            if (meta && !meta.value) {
                return null;
            } else if (
                // eslint-disable-next-line no-constant-condition
                false
            ) {
                return { empty: { message: 'invalid postalCode length' } };
            } else {
                return null;
            }
        },
        isValidMoment: (meta: any) => {
            if (meta && !meta.value) {
                return null;
            } else if (meta && meta.value && moment.isMoment(meta.value)) {
                return null;
            } else if (
                meta &&
                meta.value &&
                moment.isMoment(meta.value) === false &&
                (moment(meta.value, 'DD-MMM-YY', true).isValid() ||
                    moment(meta.value, 'YYYY-MM-DDTHH:mm:ss', true).isValid() ||
                    moment(meta.value, moment.ISO_8601, true).isValid())
            ) {
                // This validation checks to see if it is one of the 3 expected formats.  1. the format from dateTime plugin, 2.
                //  format we receive from the API, 3. is what we save locally and send to the API
                // TODO this can be improved by using moment().format(constant.momentSQL) everywhere we have toISOString().
                return null;
            } else {
                return { isValidMoment: { message: 'not a valid date' } };
            }
        },
        requiredRichText: (meta: any) => {
            if (meta && meta.value !== '<p><br></p>') {
                return null;
            } else {
                return { empty: { message: 'not long enough' } };
            }
        },
        requiredBlob: (meta: any) => {
            if (meta && meta.value) {
                return null;
            } else return { empty: { message: 'required' } };
        },
        isTwoDigitNumber: (meta: any) => {
            if (meta && meta.value) {
                if (meta.value.match(/^\d{2}$/)) {
                    return null;
                } else {
                    return {
                        isTwoDigitNumber: {
                            message: 'not a valid 2 digit value'
                        }
                    };
                }
            } else {
                return null;
            }
        },
        isFourDigitAlphaNumeric: (meta: any) => {
            if (meta && meta.value) {
                if (meta.value.match(/^[0-9A-Za-z]{4}$/)) {
                    return null;
                } else {
                    return {
                        isFourDigitAlphaNumeric: {
                            message: 'not a valid 4 digit value'
                        }
                    };
                }
            } else {
                return null;
            }
        },
        isNumeric: (meta: any) => {
            if (meta && meta.value) {
                if (/^[0-9]+$/.test(meta.value)) {
                    return null;
                } else {
                    return {
                        isFourDigitAlphaNumeric: {
                            message: 'not a valid numeric value'
                        }
                    };
                }
            } else {
                return null;
            }
        },
        isLessTenDigitAlphaNumeric: (meta: any) => {
            if (meta && meta.value) {
                if (meta.value.match(/^[0-9A-Za-z]{1,9}$/)) {
                    return null;
                } else {
                    return {
                        isLessTenDigitAlphaNumeric: {
                            message: 'not a valid 1-9 digit value'
                        }
                    };
                }
            } else {
                return null;
            }
        }
    },
    convertEnumToOptions: (items: any): Ioption[] => {
        const keyFmt = (key: string) =>
            key.replace('agsRebalancing', 'AGS Re-Balancing');
        const StringIsNumber = (value: string) => isNaN(Number(value)) === true;
        return Object.keys(items)
            .filter(StringIsNumber)
            .map((key: string) => {
                return { label: keyFmt(key), value: items[key] };
            });
    },
    convertToOptions: (items: any, shouldIncludeDeleted = false): Ioption[] => {
        if (!items) {
            console.info('missing option items, returning empty array');
            return [];
        }
        const filteredItems = filter(items, item => {
            if (
                item.isDeleted === true &&
                item.isDeleted !== shouldIncludeDeleted
            ) {
                return false;
            }
            return true;
        });
        return map(filteredItems, (item: any) => {
            const firstLastName =
                item.first && item.last ? item.first + ' ' + item.last : '';
            return {
                value: item.id || item.value,
                label:
                    item.name ||
                    item.code ||
                    item.label ||
                    firstLastName ||
                    item.email ||
                    'unknown'
            };
        });
    },
    convertToSingleOption: (item: any, t?: TFunction): Ioption | undefined => {
        if (item && item.id && item.id.length) {
            const lastOption =
                item.first && item.last
                    ? item.first + ' ' + item.last
                    : 'unknown';
            let label = item.name || item.code || item.label || lastOption;
            if (t) {
                label = t(label);
            }
            return {
                value: item.id,
                label
            };
        } else {
            return undefined;
        }
    },
    getValidationState: (
        pristine: boolean,
        error: ValidationErrors,
        submitted: boolean
    ) => {
        if (!pristine && error) {
            return 'error';
        } else if (!pristine && !error) {
            return 'success';
        } else if (pristine && error && submitted) {
            return 'error';
        } else {
            return null;
        }
    },
    /*
     * utility to help transform the data into multipart/form-data
     */
    toFormData: (formValue: any) => {
        const data = new FormData();
        Object.keys(formValue).forEach(key => {
            const value = formValue[key];
            if (moment.isMoment(value)) {
                data.append(key, value.utc().toISOString());
            } else {
                data.append(key, value && value.value ? value.value : value);
            }
        });
        return data;
    },
    /*
     * utility to help transform JSON to query params
     */
    toUrlSearchParams: (paramCollection: {
        [key: string]: string[] | string;
    }) => {
        const params = new URLSearchParams();
        forEach(paramCollection, (collection, key) => {
            if (Array.isArray(collection)) {
                forEach(collection, item => {
                    params.append(key, item);
                });
            } else {
                params.append(key, collection);
            }
        });
        return params;
    },
    /*
     * getValues
     * a utility function to help convert form values to an object
     */
    getValues: (formValues: { [key: string]: any }) => {
        let values: {
            [key: string]: string | boolean | number | string[];
        } = {};
        forEach(formValues, (value, key) => {
            if (
                typeof value === 'object' &&
                value !== null &&
                'value' in value
            ) {
                values = { ...values, [key]: value.value };
            } else if (Array.isArray(value) && value.length) {
                values = { ...values, [key]: map(value, val => val.value) };
            } else if (moment.isMoment(value)) {
                values = {
                    ...values,
                    [key]: value.format(constants.momentSQLFormat)
                };
            } else {
                if (value === undefined) {
                    return; // don't add it if undefined
                }
                values = { ...values, [key]: value };
            }
        });
        return values;
    },
    Datetime: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted
    }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';

        const defaultValue = handler().value;
        const momentDate =
            !defaultValue || moment.isMoment(defaultValue)
                ? defaultValue
                : moment.utc(defaultValue);

        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                    className={`datetime-select ${meta.alignRight &&
                        'alignRight'}`}
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <Datetime
                        timeFormat={false}
                        utc={true}
                        dateFormat={
                            meta.dateFormat ? meta.dateFormat : 'DD-MMM-YY'
                        }
                        isValidDate={meta.isValidDate}
                        inputProps={{
                            disabled: handler().disabled,
                            placeholder: meta.placeholder
                        }}
                        // {...handler()} // we don't want to override the value prop because we want Datetime to control that and enforce the date format
                        onChange={handler().onChange}
                        defaultValue={momentDate}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    TextInput: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted
    }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
                className={meta.className}
            >
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                    style={meta.style}
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <FormControl
                        placeholder={meta.placeholder}
                        componentClass={meta.componentClass}
                        type={meta.type || 'text'}
                        rows={meta.rows}
                        autoFocus={meta.autoFocus}
                        name={meta.name || ''}
                        {...handler()}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    FileInput: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted,
        value
    }: AbstractControl) => {
        const requiredLabel =
            meta.required === false && !meta.uploadButton ? ' - Optional' : '';
        const extension =
            (meta.fileName && meta.fileName.split('.').pop()) ||
            (meta.imageUrl && meta.imageUrl.split('.').pop());
        const btnClassName = handler().disabled
            ? 'btn btn-default disabled'
            : 'btn btn-default';
        const label =
            meta.imageUrl && meta.otherLabels && meta.otherLabels.labelReplace
                ? meta.otherLabels.labelReplace
                : meta.label;
        const idNum = meta.name.charAt(meta.name.length - 1);
        const id = `fileUpload${idNum}`;
        return (
            <Col xs={meta.colWidth}>
                {meta.hasPreview && meta.imageUrl && (
                    <img
                        alt="Uploaded Img"
                        src={meta.imageUrl}
                        style={{
                            maxWidth: '100%',
                            marginTop: '15px',
                            marginBottom: '15px'
                        }}
                    />
                )}
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                    style={meta.style}
                >
                    <ControlLabel htmlFor={id} style={{ cursor: 'pointer' }}>
                        <span className={btnClassName}>
                            {meta.icon && (
                                <FontAwesomeIcon icon={['far', meta.icon]} />
                            )}
                            {label}
                            <i className="required-label">{requiredLabel}</i>
                        </span>
                        <FormControl
                            accept={meta.accept || 'image/*'}
                            id={id}
                            placeholder={meta.placeholder}
                            componentClass={meta.componentClass}
                            type={meta.type || 'file'}
                            rows={meta.rows}
                            autoFocus={meta.autoFocus}
                            name={meta.name || ''}
                            {...handler()}
                            onChange={(evt: any) => {
                                const fileInput = evt.target;
                                let fileName = '';
                                if (fileInput.files.length > 0) {
                                    meta.onChange(
                                        meta.name,
                                        fileInput.files[0]
                                    );
                                    fileName = fileInput.files[0].name;
                                }
                                evt.target.filename = fileName;
                            }}
                            style={{ display: 'none' }}
                        />
                    </ControlLabel>
                </FormGroup>
                {!meta.hasPreview && !meta.imageUrl && !meta.uploadButton && (
                    <FileIcon
                        extension={extension}
                        {...defaultStyles[extension]}
                        size={64}
                    />
                )}

                {!!meta.hasDownload &&
                    meta.imageUrl &&
                    meta.imageUrl.length !== 0 && (
                        <Row>
                            <Col xs={7}>
                                <Button
                                    bsStyle="default"
                                    href={meta.imageUrl}
                                    target="_blank"
                                >
                                    {meta.otherLabels.labelDownload}
                                </Button>
                            </Col>
                            <Col xs={3}>
                                <FileIcon
                                    extension={extension}
                                    {...defaultStyles[extension]}
                                    size={64}
                                />
                            </Col>
                        </Row>
                    )}
            </Col>
        );
    },
    Toggle: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted,
        value
    }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
                style={{ ...meta.style }}
            >
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                >
                    <label
                        className="control-label"
                        style={{ ...meta.innerStyle }}
                    >
                        <Toggle
                            icons={false}
                            checked={
                                value?.value !== undefined ? value.value : value
                            }
                            {...handler()}
                            className="beacon-toggle"
                            name={meta.name || ''}
                            value=""
                        />
                        <span className="react-toggle-label">
                            {meta.label}
                            <i className="required-label">{requiredLabel}</i>
                        </span>
                    </label>
                </FormGroup>
            </Col>
        );
    },
    Checkbox: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted,
        value
    }: AbstractControl) => {
        // const requiredLabel = meta.required === false ? ' - Optional' : '';
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
                className={meta.className || ''}
            >
                <FormGroup
                    style={{ display: 'flex', marginTop: '20px' }}
                    bsSize="sm"
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                >
                    <ControlLabel
                        style={{ margin: '5px 10px 0px 0px' }}
                        className="react-checkbox-label"
                    >
                        {meta.label}
                    </ControlLabel>
                    <FormControl
                        placeholder={meta.placeholder}
                        type="checkbox"
                        className="beacon-checkbox"
                        name={meta.name || ''}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    TextInputWithoutValidation: ({ handler, meta }: AbstractControl) => (
        <Col
            xs={meta.colWidth}
            md={meta.colWidthMedium}
            lg={meta.colWidthLarge}
            className={meta.className || ''}
        >
            <FormGroup bsSize="sm">
                <ControlLabel>{meta.label}</ControlLabel>
                <FormControl
                    placeholder={meta.placeholder}
                    componentClass={meta.componentClass}
                    name={meta.name || ''}
                    {...handler()}
                />
            </FormGroup>
        </Col>
    ),
    Select: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted,
        value
    }: AbstractControl) => {
        const selectClassName = meta.isMulti
            ? 'is-multi beacon-select'
            : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());

        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
                style={meta.style}
                className={meta.className || ''}
            >
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                    data-last={`${Boolean(meta.last)}`}
                >
                    <ControlLabel className={meta.className}>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <Select
                        options={selectOptions}
                        className={`${selectClassName} ${selectValidationClass}`}
                        components={{
                            Control: ControlComponent,
                            MultiValueLabel:
                                meta.multiValueLabel ||
                                components.MultiValueLabel,
                            Option: meta.multiValueOption || components.Option
                        }}
                        placeholder={meta.placeholder}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        name={meta.name || ''}
                        isClearable={
                            typeof meta.isClearable !== 'undefined'
                                ? meta.isClearable
                                : false
                        }
                        isDisabled={handler().disabled}
                        filterOption={createFilter({ ignoreAccents: false })}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    CreatableSelect: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted,
        value
    }: AbstractControl) => {
        const selectClassName = meta.isMulti
            ? 'is-multi beacon-select'
            : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <CreatableSelect
                        options={selectOptions}
                        className={`${selectClassName} ${selectValidationClass}`}
                        components={{
                            Control: ControlComponent,
                            DropdownIndicator: meta.dropdownIndicator
                        }}
                        placeholder={meta.placeholder}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        menuIsOpen={meta.menuIsOpen}
                        onKeyDown={meta.onKeyDown}
                        onCreateOption={meta.handleCreate}
                        isClearable={
                            typeof meta.isClearable !== 'undefined'
                                ? meta.isClearable
                                : false
                        }
                        name={meta.name || ''}
                        isDisabled={handler().disabled}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    AsyncSelect: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted,
        value
    }: AbstractControl) => {
        const selectClassName = meta.isMulti
            ? 'is-multi beacon-select'
            : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - Optional' : '';

        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
                className={meta.className || ''}
            >
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <AsyncSelect
                        placeholder={meta.placeholder}
                        loadOptions={meta.loadOptions}
                        className={`${selectClassName} ${selectValidationClass}`}
                        components={{ Control: ControlComponent }}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        defaultOptions
                        cacheOptions
                        name={meta.name || ''}
                        isDisabled={handler().disabled}
                        noOptionsMessage={() =>
                            "Enter at least 3 characters to find the facility you're looking for."
                        }
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    AsyncCreatableSelect: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted,
        value
    }: AbstractControl) => {
        const selectClassName = meta.isMulti
            ? 'is-multi beacon-select'
            : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - Optional' : '';

        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <AsyncCreatableSelect
                        loadOptions={meta.loadOptions}
                        className={`${selectClassName} ${selectValidationClass}`}
                        components={{ Control: ControlComponent }}
                        placeholder={meta.placeholder}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        onCreateOption={meta.handleCreate}
                        isValidNewOption={meta.isValidNewOption}
                        isClearable={
                            typeof meta.isClearable !== 'undefined'
                                ? meta.isClearable
                                : false
                        }
                        name={meta.name || ''}
                        isDisabled={handler().disabled}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    SelectWithoutValidation: ({ handler, meta }: AbstractControl) => {
        // console.info('rendering select', meta.options, value, defaultValue)
        const selectClassName = meta.isMulti
            ? 'is-multi beacon-select'
            : 'beacon-select';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
                className={meta.className || ''}
                data-type={meta.dataType || ''}
            >
                <FormGroup bsSize="sm" style={meta.style}>
                    <ControlLabel>{meta.label}</ControlLabel>
                    <Select
                        options={selectOptions}
                        className={selectClassName}
                        components={{
                            Control: ControlComponent,
                            MultiValueLabel:
                                meta.multiValueLabel ||
                                components.MultiValueLabel,
                            SingleValue:
                                meta.singleValueLabel || components.SingleValue,

                            Option: meta.multiValueOption || components.Option
                        }}
                        placeholder={meta.placeholder}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        isClearable={
                            typeof meta.isClearable !== 'undefined'
                                ? meta.isClearable
                                : false
                        }
                        name={meta.name || ''}
                        isDisabled={handler().disabled}
                        filterOption={createFilter({ ignoreAccents: false })}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    SelectWithoutValidationLeftLabel: ({ handler, meta }: AbstractControl) => {
        // TODO get rid of this because default values do not work for some unknwon reason.  we patch the values instead
        // console.info('rendering select', meta.options, value, defaultValue)
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        const selectClassName = meta.isMulti
            ? 'is-multi beacon-select'
            : 'beacon-select';
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
                className={meta.className || ''}
            >
                <FormGroup bsSize="sm">
                    <Col xs={3} lg={2}>
                        <ControlLabel>{meta.label}</ControlLabel>
                    </Col>
                    <Col xs={9} lg={10}>
                        <Select
                            options={selectOptions}
                            className={selectClassName}
                            components={{ Control: ControlComponent }}
                            placeholder={meta.placeholder}
                            isMulti={meta.isMulti}
                            classNamePrefix="react-select"
                            isClearable={
                                typeof meta.isClearable !== 'undefined'
                                    ? meta.isClearable
                                    : false
                            }
                            name={meta.name || ''}
                            isDisabled={handler().disabled}
                            filterOption={createFilter({
                                ignoreAccents: false
                            })}
                            {...handler()}
                        />
                    </Col>
                </FormGroup>
            </Col>
        );
    },
    SelectWithButton: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted,
        value
    }: AbstractControl) => {
        // console.info('rendering select', meta.options, value, defaultValue)
        const selectClassName = meta.isMulti
            ? 'is-multi beacon-select'
            : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                >
                    <ControlLabel style={{ paddingTop: '10px' }}>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <Button
                        bsStyle="link"
                        className="pull-right right-side"
                        onClick={meta.buttonAction}
                    >
                        {meta.buttonName}
                    </Button>
                    <Select
                        options={selectOptions}
                        className={`${selectClassName} ${selectValidationClass}`}
                        components={{
                            Control: ControlComponent,
                            MultiValueLabel:
                                meta.multiValueLabel ||
                                components.MultiValueLabel,
                            Option: meta.multiValueOption || components.Option
                        }}
                        placeholder={meta.placeholder}
                        isMulti={meta.isMulti}
                        classNamePrefix="react-select"
                        name={meta.name || ''}
                        isDisabled={handler().disabled}
                        filterOption={createFilter({ ignoreAccents: false })}
                        {...handler()}
                    />
                </FormGroup>
            </Col>
        );
    },
    Button: ({ handler, meta }: AbstractControl) => {
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <Button bsStyle="link" className="" onClick={meta.buttonAction}>
                    {meta.buttonName}
                </Button>
            </Col>
        );
    },
    translateForm: (config: FieldConfig, t: TFunction) => {
        const newControls = mapValues(
            config.controls,
            (field: AbstractControl) => {
                if (field.meta) {
                    let newMeta: any = { ...field.meta };
                    if (newMeta.label) {
                        newMeta = { ...newMeta, label: t(newMeta.label) };
                    }
                    if (newMeta.otherLabels) {
                        let translatedOtherLabels: {
                            [key: string]: string;
                        } = {};
                        forEach(newMeta.otherLabels, (value, key) => {
                            translatedOtherLabels = {
                                ...translatedOtherLabels,
                                [key]: t(value)
                            };
                        });
                        newMeta = {
                            ...newMeta,
                            otherLabels: translatedOtherLabels
                        };
                    }

                    if (newMeta.buttonName) {
                        newMeta = {
                            ...newMeta,
                            buttonName: t(newMeta.buttonName)
                        };
                    }
                    if (newMeta.placeholder) {
                        newMeta = {
                            ...newMeta,
                            placeholder: t(newMeta.placeholder)
                        };
                    }
                    // we need this to translate the options for the security functions
                    if (
                        newMeta.shouldTranslate &&
                        newMeta.options &&
                        newMeta.options.length
                    ) {
                        const newOptions = map(newMeta.options, option => ({
                            value: option.value,
                            label: t(option.label)
                        }));
                        newMeta = { ...newMeta, options: newOptions };
                    }
                    return { ...field, meta: newMeta };
                }
                return field;
            }
        );
        return { controls: newControls };
    },
    TextLabel: ({ handler, meta }: any) => {
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup bsSize="sm" style={{ marginTop: '15px' }}>
                    <ControlLabel
                        style={{
                            fontWeight: 'bold'
                            // borderBottom: meta.value ? '' : '1px solid #333'
                        }}
                    >
                        {meta.label}
                    </ControlLabel>
                    <h5 className="queue-form-label">{handler().value}</h5>
                </FormGroup>
            </Col>
        );
    },
    TextLabelStatic: ({ meta }: any) => {
        return (
            <Col
                xs={meta.colWidth || 12}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup bsSize="sm" style={meta.style}>
                    <ControlLabel
                        style={{
                            fontWeight: meta.fontWeight || 'bold'
                            // borderBottom: meta.value ? '' : '1px solid #333'
                        }}
                    >
                        {meta.label}
                    </ControlLabel>
                    <span
                        style={{
                            display: meta.icon ? 'flex' : ''
                            // justifyContent: 'space-between'
                        }}
                    >
                        <h5
                            className="queue-form-label"
                            style={{
                                display: meta.value !== undefined ? '' : 'none',
                                marginBottom: meta.icon ? '0px' : ''
                            }}
                        >
                            {meta.value}
                        </h5>
                        {meta.buttonName ||
                            (meta.icon && meta.buttonAction && (
                                <Button
                                    bsStyle="default"
                                    className=""
                                    bsSize="sm"
                                    style={{
                                        height: '30px',
                                        marginTop: '-20px',
                                        marginLeft: '10px',
                                        display: 'flex',
                                        textAlign: 'center',
                                        padding: '7px 16px 6px 16px'
                                    }}
                                    onClick={meta.buttonAction}
                                >
                                    {meta.buttonName
                                        ? meta.name
                                        : meta.icon
                                        ? meta.icon
                                        : null}
                                </Button>
                            ))}
                    </span>
                </FormGroup>
            </Col>
        );
    },
    /*
     * DESKTOP ONLY
     */
    CreatableSelectWithButton: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted,
        value
    }: AbstractControl) => {
        const selectClassName = meta.isMulti
            ? 'is-multi beacon-select'
            : 'beacon-select';
        const selectValidationClass = value && !pristine ? 'has-success' : '';
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        const selectOptions = meta.disableSort
            ? meta.options
            : orderBy(meta.options, option => option.label.toLowerCase());
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <Row>
                    <Col xs={9}>
                        <FormGroup
                            validationState={FormUtil.getValidationState(
                                pristine,
                                errors,
                                submitted
                            )}
                            bsSize="sm"
                        >
                            <ControlLabel>
                                {meta.label}
                                <i className="required-label">
                                    {requiredLabel}
                                    {meta.subLabel}
                                </i>
                            </ControlLabel>
                            <CreatableSelect
                                options={selectOptions}
                                className={`${selectClassName} ${selectValidationClass}`}
                                components={{ Control: ControlComponent }}
                                placeholder={meta.placeholder}
                                isMulti={meta.isMulti}
                                classNamePrefix="react-select"
                                onKeyDown={meta.onKeyDown}
                                onCreateOption={meta.handleCreate}
                                isClearable={
                                    typeof meta.isClearable !== 'undefined'
                                        ? meta.isClearable
                                        : false
                                }
                                name={meta.name || ''}
                                isDisabled={handler().disabled}
                                {...handler()}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={3} style={{ paddingTop: '20px' }}>
                        <Button
                            className="pull-right"
                            onClick={meta.buttonAction}
                        >
                            {meta.buttonName}
                        </Button>
                    </Col>
                </Row>
            </Col>
        );
    },
    RichTextEditor: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted
    }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup
                    bsSize="sm"
                    style={meta.style}
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <RichTextEditor
                        toolbarConfig={meta.toolbarConfig}
                        onChange={handler().onChange}
                        initialContent={meta.initialContent}
                        readOnly={handler().disabled}
                        charLimit={meta.charLimit}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    Signature: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted
    }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup
                    bsSize="sm"
                    style={meta.style}
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <SignatureControl onChange={handler().onChange} />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    CreatableMultiTextInput: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted
    }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';

        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup
                    bsSize="sm"
                    style={meta.style}
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <CreatableMultiTextInput
                        onChange={handler().onChange}
                        initialContent={meta.initialContent}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    PassFail: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted
    }: AbstractControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                    style={meta.style}
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <ToggleButtonGroup
                        name="pass-fail"
                        {...handler()}
                        type="radio"
                    >
                        <ToggleButton value={1}>Pass</ToggleButton>
                        <ToggleButton value={2}>Fail</ToggleButton>
                        <ToggleButton value={3}>N/A</ToggleButton>
                    </ToggleButtonGroup>
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    NumericInput: ({
        handler,
        touched,
        meta,
        pristine,
        errors,
        submitted
    }: rFormControl) => {
        const requiredLabel = meta.required === false ? ' - Optional' : '';
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup
                    validationState={FormUtil.getValidationState(
                        pristine,
                        errors,
                        submitted
                    )}
                    bsSize="sm"
                    style={meta.style}
                >
                    <ControlLabel>
                        {meta.label}
                        <i className="required-label">{requiredLabel}</i>
                    </ControlLabel>
                    <FormControl
                        placeholder={meta.placeholder}
                        componentClass={meta.componentClass}
                        type="number"
                        rows={meta.rows}
                        autoFocus={meta.autoFocus}
                        name={meta.name || ''}
                        {...handler()}
                    />
                    <FormControl.Feedback />
                </FormGroup>
            </Col>
        );
    },
    fontAwesomeIcon: ({ handler, meta }: rFormControl) => {
        return (
            <Col
                xs={meta.colWidth}
                md={meta.colWidthMedium}
                lg={meta.colWidthLarge}
            >
                <FormGroup>
                    <ControlLabel style={{ visibility: meta.showLabel }}>
                        {meta.label}
                    </ControlLabel>
                    <div>
                        <Button
                            bsStyle="link"
                            style={{
                                backgroundColor: 'transparent',
                                padding: 0
                            }}
                            onClick={meta.buttonAction}
                        >
                            <FontAwesomeIcon icon={meta.icon} />
                        </Button>
                    </div>
                </FormGroup>
            </Col>
        );
    },
    Divider: () => (
        <div className="form-divider">
            <span></span>
        </div>
    )
};

export const MultiValueLabel = (props: {
    children: string;
    innerProps: any;
    data: any;
    selectProps: any;
}) => {
    const label = props.children.split('<br/>')[0];
    const address = props.children.split('<br/>')[1];
    const children = () => {
        return (
            <span>
                {label}
                <small style={{ paddingLeft: '0px', display: 'block' }}>
                    {address}
                </small>
            </span>
        );
    };
    const newProps = { ...props, children: children() };
    return <components.MultiValueLabel {...newProps} />;
};
export const MultiValueOption = (props: any) => {
    const label = props.children.split('<br/>')[0];
    const address = props.children.split('<br/>')[1];
    const newProps = { ...props, children: label };
    return (
        <span className="react-select__option">
            <components.Option {...newProps}>
                <p style={{ fontWeight: 400 }}>{label}</p>
                <small style={{ display: 'block' }}>{address}</small>
            </components.Option>
        </span>
    );
};
export const SingleValueLabel = (props: {
    children: string;
    innerProps: any;
    data: any;
    selectProps: any;
}) => {
    const label = props.children.split('<br/>')[0];
    const address = props.children.split('<br/>')[1];
    const newProps = { ...props, children: label };
    return (
        <span>
            <components.MultiValueLabel {...newProps} />
            <small style={{ display: 'block' }}>{address}</small>
        </span>
    );
};
